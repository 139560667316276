<template>
  <div class="">
    <div class="">
      <i v-if="enableAdd" class="fa fa-plus-square float-right show-hand" style="font-size:1.5em;" @click="addItem()"></i>
      <h4 v-if="title" class="mt-1 mb-0">{{title}}</h4>
<!--      <h4 v-if="title" class="mt-1 mb-0"><i v-if="enableAdd" class="fa fa-plus-square mr-2 show-hand" style="font-size:1.5em;" @click="addItem()"></i>{{title}}</h4>-->
<!--      <i v-if="!title && enableAdd" class="fa fa-plus-square float-right show-hand" style="font-size:1.5em;" @click="addItem()"></i>-->
    </div>

    <div class="">
      <div v-if="tableData.length>0" >
        <b-table responsive small hover head-variant="dark"  stacked="md" ref="tableDataList" :items="tableData" :fields="tableDataFields" >
          <template v-slot:table-colgroup="scope">
            <col
              v-for="field in scope.fields"
              :key="field.key"
              :style="{ width: field.key === 'value' ? '80%' : field.key=== 'actions' ? '50px' : '100px' }"
            >
          </template>
          <template v-slot:cell(actions)="row">
            <div>
              <i class="fa fa-trash show-hand pr-2" v-b-popover.hover.top="'Remove this item'" @click.stop="removeItem(row.index)"></i>
              <i class="fa fa-clone show-hand" v-b-popover.hover.top="'Clone this item'" @click.stop="cloneItem(row.index)"></i>
            </div>
          </template>
           <template v-slot:cell(tableId)="row" >
            <b-input :id="makeId()" :labelCols="0" style="width:10em;" v-model="row.item.tableId" @input="change()"></b-input>
          </template>
          <template v-slot:cell(key)="row" >
            <b-input :id="makeId()" :labelCols="0" style="width:10em;" v-model="row.item.key" @input="change()"></b-input>
          </template>
          <template v-slot:cell(value)="row">
            <b-input :id="makeId()" :labelCols="0" style="width:100%;" v-model="row.item.value"  @input="change()"></b-input>
          </template>
        </b-table>
      </div>
    </div>
    <div class="" v-if="$slots.footer">
      <hr>
      <slot name="footer"></slot>
    </div>
  </div>
</template>
<script>

  import SfInput        from "/src/components/UIComponents/Inputs/SFormInput.vue";

  let idCount = 0;

  export default {
    name: 'OptionManagementTable',
    components: {
      SfInput
    },
    props: {
      title: { type: String, default: "" },
      tableData: { type: Array, default: () => [] },
      tableOptions: { type: Object, default: () => {}},
      enableAdd: { type: Boolean, default: true },
      addHoverText: { type: String, default: "Add a new item" },
      tableDataFields: { type: Array, default: () => [
        {key: "actions", label: "", sortable: false },
        {key: "tableId", label: "Table ID", sortable: true},
        {key: "key",     label: "Key", sortable: true},
        {key: "value",   label: "Value", sortable: true}
      ]}
    },
    data() {
      return {
      }
    },
    methods: {
      makeId() {
        idCount++;
        return "inputId-" + idCount.toString();
      },
      addItem() {
        this.tableData.push( { tableId: "" , key: "new", value: "" } );
        this.$emit( "add" );
        this.change();
      },
      change( ) {
        console.log( "change item"  );
        this.$emit( "change" );
      },
      editItem( e ) {
        console.log( "edit item" );
        this.$emit( "click", e );
        this.change();
      },
      removeItem( index ) {
        console.log( "remove Item" , index );
        this.$emit( "delete" , this.tableData[index] );
        this.tableData.splice(index,1);
        this.change();
      },
      cloneItem( index ) {
        console.log( "clone Item" , index );
        this.$emit( "clone" , this.tableData[index] );
        let t = Object.assign({},this.tableData[index]);
        this.tableData.push(t);
        this.change();
      }
    }
  }
</script>
<style>

</style>
