<template>
  <div class="main-background">
    <div class="main-logo">
      <a href="http://www.newcare.international">
        <img src="@/assets/img/icare-cafe-logo-300.png" />
      </a>
      <div class="slogan">Caring Today For a Better Tomorrow</div>
    </div>
    <div class="login">
      <h2>Welcome to iCare</h2>
      <b-link class="btn btn-fill btn-login login-button" :to="{path: '/login'}">Log In</b-link>
    </div>
    <div style="position:absolute;bottom:40px;right:30px;">
      <a href="http://www.newcare.international">
        <img style="width:128px;" src="@/assets/img/newcare-logo-350-white.png" />
      </a>
    </div>
    <div style="display:inline;position:absolute;bottom:0;right:0;font-size:1em;color:white;">
      Find us online: <a style="cursor:pointer;padding-right:1em;" href="http://newcare.international">Newcare International</a>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        title: "Main Menu"
      };
    }
  };

</script>
<style lang="scss" scoped>

  .main-background {
    background: url('../assets/img/sun-background.jpg') center center;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    min-width: 50%;
    min-height: 50%;
    background-color: peru;

    /*background-size: cover;*/
    /*min-height:100%;*/
    /*height:1024px;*/
    /*background-position: 100% 100%;*/

    /*padding-top: 520px;*/
    /*padding-bottom: 320px;*/

  }

  .main-logo {
    padding-top:2em;
    margin:auto;
    padding-bottom:3em;
    text-align:center;

    /*background-color:peru;*/
    // background:rgba(166, 55, 0,.1);

    img {
          padding:1em;
          /*border:1px solid white;*/
          /*border-radius:1em;*/
      width: 45vh;
      max-width:500px;
    }

    p {
      padding:0;
      margin:0;
      text-align:center;
      color:white;
      font-weight:400;
      font-size:12px;
    }

    .slogan {
      border-top:1px solid white;
      border-bottom:1px solid white;
      font-size:2.5vh;
      margin-top:.5em;
      text-align:center;
      font-weight:300;
      font-style:italic;
      color:white;
    }

  }

  .login {
    /*top:-150px;*/
    margin: 5em auto 0 auto;
    color: white;
    // border:1px gray solid;
     background:rgba(167, 66, 15, 0.1);
    border-radius:1em;
    text-align:center;
    width: 45vh;
    height:14vh;
    opacity: 1;
    font-size: 1.5em;
    h2 {
      font-weight:900;
      font-size:4.5vh;
      text-shadow: -2px 2px 4px black;
    }
  }

  .login-button {
    border-radius: .75em;
    background:rgba(167, 66, 15, 0.5);
    border:0;
  }

  .login-button:hover {
    background-color:rgba(167, 66, 15, 1);
    color:white;
  }


</style>
