<template>
  <div class="content">
    <!-- The Current Page -->
    <div class="row" v-if="!calendar">
      <div class="col-md-12 ml-auto mr-auto  pt-2">
        <!-- <template slot="header"> -->
          <button class="float-right mt-0 mr-3 btn btn-default btn-sm btn-fill" style="border-radius:.75em;" v-b-popover.hover.top="'Get ALL Calendars, Even Expired Ones'" @click="getOrderCalendars('all')">
            <span v-show="loading" :class="isLoading"></span>Show Expired Calendars
          </button>
          <h3 class="card-title">
            <span v-show="loading" :class="isLoading"></span>Order-Calendars Management
            <!-- <button class="float-left mr-3 btn btn-sm btn-success btn-fill" @click="$router.push('/app');">
              <span v-show="loading" :class="isLoading"></span>Done
            </button> -->
          </h3>
          <hr>
        <!-- </template> -->
        <div class="row">
          <div class="col-md-12">
            <button class="btn btn-default btn-sm btn-fill btn-warning float-right" @click="addOrderCalendar()"><i class="fa fa-plus"></i> Add New Calendar</button>
            <h4 class="mt-0">Select the calendar to edit</h4>
            <b-table ref="mytable" v-if="orderCalendars.length>0" striped hover class="show-hand" :items="orderCalendars" :fields="fields" @row-clicked="getOrderCalendar">
              <template v-slot:cell(title)="data">{{data.item.title}}</template>
              <template v-slot:cell(action)="data">
                <i class="fa fa-trash show-hand" v-b-popover.hover.top="'Remove This Calendar'" @click="removeOrderCalendar(data)"></i>
              </template>
              <template v-slot:cell(active)="data">{{(data.item.active===true) ? 'Yes' : 'No'}}</template>
              <template v-slot:cell(activeDate)="data">{{(data.item.activeDate) ?
                moment(data.item.activeDate).format("MMM Do yy") : 'Not Set'}}
              </template>
              <template v-slot:cell(calendarStartDate)="data">{{(data.item.calendarStartDate) ?
                moment(data.item.calendarStartDate).format("MMM Do yy") : 'Not Set'}}
              </template>
              <template v-slot:cell(calendarEndDate)="data">{{ (data.item.calendarEndDate) ?
                moment(data.item.calendarEndDate).format( "MMM Do yy") : 'Not Set'}}
              </template>
              <template v-slot:cell(updatedAt)="data">{{moment(data.item.updatedAt).tz($root.TZ).fromNow()}}
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-if="calendar && !isPreview && !showMonthlyCalendar && !showMonthlyRotationsCalendar">
      <div style="border-radius:1em;" class="col-md-4 pt-2 mr-2 shadow">
        <button class="float-left mt-0 mr-2 btn btn-sm btn-success btn-fill" v-if="!item" @click="updateOrderCalendar(calendar)">
          <span v-show="loading" :class="isLoading"></span>Done
        </button>

<!--        <button class="btn btn-sm btn-success btn-fill float-right" v-if="!item" @click="previewOrderList(calendar)">-->
<!--          <span v-show="loading" :class="isLoading"></span>List-->
<!--        </button>-->

<!--        <button class="btn btn-sm btn-success btn-fill float-right" v-if="!item" @click="previewMenuCalendar(calendar)">-->
<!--          <span v-show="loading" :class="isLoading"></span><i style="font-size:1.5em;" class="fa fa-calendar"></i>-->
<!--        </button>-->

        <b-dropdown v-if="!item" class="float-right" size="sm" style="background:#87cb16;border-radius:.5em;" >
          <b-dropdown-header>Calendar Options</b-dropdown-header>
          <b-dropdown-item-button @click="previewMenuCalendar(calendar)"><i style="margin-right:1em;" class="fa fa-calendar"></i>Show Calendar</b-dropdown-item-button>
          <b-dropdown-item-button @click="previewRotationsCalendar(calendar)"><i style="margin-right:1em;" class="fa fa-calendar"></i>Show Rotations</b-dropdown-item-button>
          <b-dropdown-item-button @click="previewOrderList(calendar)"><i style="margin-right:1em;" class="fa fa-calendar-o"></i>Show Production</b-dropdown-item-button>
        </b-dropdown>


        <div v-if="!item" class="pb-4">
          <h3 class="mt-33 pb-2" style="text-align:center">Meal Coverage</h3>
          <sweet-calendar :eventCategories="eventCategories"
                          :events="events"
                          :initialDate="calendar.calendarStartDate"
                          :selectedDate="selectedDay"
                          :beginDate="calendar.calendarStartDate"
                          :endDate="calendar.calendarEndDate"
                          cursor="pointer"
                          :enableControls="enableCalendarControls"
                          eventCursor="crosshair"
                          @click="selectionCalendarClick($event)"
                          ref="calendar" />

          <div style="text-align:center">Select a day to edit</div>

          <div style="text-align:center">
            <span style="height:32px;width:84px;border:1px;display:inline-block;padding:.25em;border-radius:.75em;vertical-align:middle;text-align:center;background:#39a6c2;color:white;">no items</span>
            <span style="height:32px;width:84px;border:1px;display:inline-block;padding:.25em;border-radius:.75em;vertical-align:middle;text-align:center;background:#a0e14f;color:white;">< 2 </span>
            <span style="height:32px;width:84px;border:1px;display:inline-block;padding:.25em;border-radius:.75em;vertical-align:middle;text-align:center;background:#7fb847;color:white;">2 items</span>
            <span style="height:32px;width:84px;border:1px;display:inline-block;padding:.25em;border-radius:.75em;vertical-align:middle;text-align:center;background:#ff813a;color:white;"> > 2</span>
          </div>
        </div>

        <div v-else>
          <h4 class="card-title " style="text-align:center">{{ menu.title }}</h4>
          <hr>
          <p>Select a Menu Item from the list under Item Listing to add to your calendar.
            It will update as you select and press DONE when you are happy with your choice.
            <span style="color:red;font-weight:600;">If you check the Override option, you can edit the item Title and Description,
            but remember, the underlying item at the POS is still the same.</span></p>
          <p>To filter the Item Listing, type your search in the search bar.  Clear the search to show the entire list again.</p>
        </div>

      </div>

      <div  v-if="!item" style="border-radius:1em; background: #dddddd;" class="col-md-7 ml-2 shadow">
        <b-form-checkbox class="float-right mt-0" isButtonMode v-model="calendar.active" type="checkbox" label="Is Active" @change="calendarActiveChanged()">
          Active
        </b-form-checkbox>
        <h4 class="card-title">
          Calendar Basics <span v-if="isCalendarChanged"><small style="color:#ff0202">(changed)</small></span>
          <span style="font-size:.7em;" :style="(calendar.allowedCategories.length===0) ? 'color:red;font-style:bold;' : ''">{{calendarBasicsHeader()}}</span>
          <span style="float:right;padding-right:2em;font-size:.7em;font-weight:800;">{{orderCount}} Orders</span>
        </h4>
        <span v-if="!canEditOverhead" v-b-toggle.calendar-overhead  style="color:red;"><i class="fa fa-angle-down"></i>(Calendar Active) Toggle to Edit</span>
        <b-collapse v-model="canEditOverhead" id="calendar-overhead">
          <form v-on:submit.prevent @change="setCalendarChanged()" style="border-radius:1em;padding:1em;background:lightgray;" :style="(!calendar.active) ? 'background:rgba(255,0,0,.1);' : ''">
            <sf-input v-model="calendar.title" label="Title"></sf-input>
            <sf-input v-model="calendar.description" type="textarea" label="Description (PUBLIC)" helpText="This displays on the public calendar"></sf-input>
            <div :style="(moment(calendar.calendarStartDate) > moment(calendar.calendarEndDate)) ? 'padding:.5em;border-radius:1em;background:rgba(192,32,32,.5);' : ''">
              <sf-input v-model="calendar.calendarStartDate" timeOffset="08:00" cstyle="width:14em;" type="date" label="Start Date" @change="setCalendarEndDate(calendar.calendarStartDate)"></sf-input>
              <sf-input v-model="calendar.calendarEndDate" timeOffset="16:00" cstyle="width:14em;" type="date" label="End Date"></sf-input>
              <div v-if="moment(calendar.calendarStartDate) > moment(calendar.calendarEndDate)"  style="font-size:1.2em;border-radius:1em;color:white;background:red;text-align:center;">
                Start Date cannot be after end date
              </div>
            </div>
            <sf-input v-model="calendar.activeDate" timeOffset="08:00" :cstyle="(calendar.active) ? 'width:14em;' : 'width:14em;color:white;background:red;'" type="date" label="Start Showing On" :helpText="(calendar.active) ?'When this calendar starts showing' : 'This calendar is NOT ACTIVE so will NEVER show'"></sf-input>
            <sf-input v-model="calendar.tags" type="text" label="Tags" helpText="add 'cafe' (or your menu board name) to include this calendar on the Menu Board"></sf-input>
            <!-- <label for="calendarTags">Tags</label>
            <b-form-tags input-id="calendarTags" v-model="calendar.tags"></b-form-tags>
            <p>Add 'cafe' to add this to the menu board (or whatever our board name is)</p> -->
          </form>
        </b-collapse>
        <div>
          <div class="show-hand float-right" style="display:flex;" v-b-toggle.calendarSettings>
            <i v-if="!calendarSettingsVisible" class="fa fa-chevron-down"></i>
            <i v-if="calendarSettingsVisible" class="fa fa-chevron-up"></i>
            <span style="font-size:.7em;">Settings</span>
          </div>
          <b-collapse id="calendarSettings" v-model="calendarSettingsVisible" class="mt-2">
            <b-row>

              <b-col class="col-md-6 col-sm-12">
                <h4>Build Calendar Options</h4>
                <p style="margin:0 .5em .5em .5em;font-size:.8em;font-style:italic;color:gray;line-height:14px;">Select the categories to include <strong>in your list</strong> when building a calendar day.  This is for the Calendar Creation process and these item categories show in your selection list</p>
                <!--Selected: {{calendar.allowedCategories}}-->
                <b-form-group label="Select Items From These Categories" class="ml-2"  >
                  <b-form-checkbox-group
                                        stacked
                                         @change="setAllowedCategoriesChanged(calendar)"
                                         id="cat-group-1"
                                         v-model="calendar.allowedCategories"
                                         :options="categoryOptions()"
                                         name="cat-1"></b-form-checkbox-group>
                </b-form-group>

              </b-col>
              <b-col class="col-md-6 col-sm-12">
                <h4>Calendar Visibility</h4>
                <p style="font-size:.8em;font-style:italic;color:gray;line-height:14px;">Select the Revenue Centers where this calendar should be visible/available</p>
                <!--Selected: {{calendar.allowedRevenueCenters}}-->
                <b-form-group label="Allowed Revenue Centers">
                  <b-form-checkbox-group
                    stacked
                    @change="setCalendarChanged()"
                    id="rc-group-1"
                    v-model="calendar.allowedRevenueCenters"
                    :options="revenueCenterOptions()"
                    name="rc-1"></b-form-checkbox-group>
                </b-form-group>
              </b-col>

            </b-row>

          </b-collapse>
        </div>

      </div>
    </div>

    <div class="row" v-if="item">
      <b-card class="col-md-7 mt-4 shadow" style="border-radius:1em;" @onChange="setCalendarChanged()">
        <!--        <div style="float:right;">-->
        <template slot="header">
          <div class="mt-0" style="position:relative; ">
            <button class="float-left mt-0 mr-2 btn btn-sm btn-success btn-fill" @click="itemEditDone()">
              <span v-show="loading" :class="isLoading"></span>Done
            </button>
            <b-form-checkbox class="mt-0" style="position:relative;top:6px;left:100px;width:150px;" isButtonMode v-model="item.isOverride" type="checkbox">
              Override
            </b-form-checkbox>

<!--            <div  class="float-right" :style="(itemSearchString.length>0) ? 'background:#FF813A' : 'background:gray'" style="display:flex;padding:.25em .25em .25em .75em;color:white;border-radius:.5em;position:relative;top:-22px;">-->
<!--              <i class="fa fa-search show-hand"></i>-->
            <div style="position:absolute;right:0;top:0;width:200px;">
              <i class="fa fa-search show-hand" style="font-size:1.5em;float:left;margin-right:.5em;margin-top:5px;"></i>
              <b-form-input type="search" style="background:#eee;width:155px;border-radius:.5em;" v-model="itemSearchString" />
            </div>
<!--              <i class="fa fa-times show-hand" @click="itemSearchString=''"></i>-->
<!--            </div>-->
          </div>
          <!--          <i class="fa fa-trash" v-b-popover.hover.top="'Remove This Item'" @click="removeItem(item)"></i>-->
        </template>


        <!--          <button class="btn btn-sm btn-default btn-fill" style="border-radius:1em;position:relative; margin:0;padding:.5em;top:0;left:-140px;" @click="itemLookup()">-->
        <!--            <span v-show="loading" :class="isLoading"></span><i class="fa fa-search"></i>   <form><input type="text" name="itemSearch" v-model="itemSearchString"></input></form> Search-->
        <!--          </button>-->

        <!--        </div>-->

        <h4 class="mt-1">POS Item: {{item.itemNumber}} <span :style="(item.isOverride) ? 'border-radius:.5em;font-weight:600;background:#ee2222;color:white;padding:.25em .5em .25em .5em;' : 'border-radius:.5em;background:#ddd;padding:.25em .5em .25em .5em;'">{{item.POSName}}</span> <span v-if="item.POSName2.length>0" style="border-radius:.5em;background:#ddd;padding:.25em .5em .25em .5em;">{{item.POSName2}}</span></h4>
        <form v-on:submit.prevent @change="setCalendarChanged()">
          <div>
            <!-- POS: {{ item.itemNumber }} : {{item.POSName }} {{item.POSName2}} -->
            <!-- <sf-input v-model="item.itemNumber" readonly cstyle="width:10em;" type="number" label="POS Item #"></sf-input> -->
            <!-- <sf-input v-model="item.POSName" readonly cstyle="width:10em;" type="text" label="POS Name"></sf-input> -->
          </div>
          <sf-input v-model="item.title" :readonly="!item.isOverride"  type="text" label="Title"></sf-input>
          <sf-input v-model="item.description" :readonly="!item.isOverride" type="textarea" label="Description"></sf-input>
          <sf-input v-model="item.price[0]" readonly cstyle="width:10em;" type="number" :label="priceName(item,0)"></sf-input>
          <sf-input v-model="item.price[1]" readonly cstyle="width:10em;" type="number" :label="priceName(item,2)"></sf-input>
          <sf-input v-model="item.maxChoice" cstyle="width:5em;" type="number" label="Max Choices"></sf-input>
        </form>
      </b-card>
      <b-card v-if="!item.isOverride" class="col-md-4 mt-4 shadow" style="height:500px;border-radius:1em;overflow-x:auto">
        <h4>Item Listing</h4>
        <ul style=" list-style-type: none;">
          <template v-for="(menuItem,index) in menuItemListSearch" >
            <li class="show-hand" style="" :key="index" @click="selectMenuItem( menuItem )" >{{menuItem.itemNumber}} :: {{menuItem.title}}</li>
          </template>
        </ul>
      </b-card>
      <b-card v-else class="col-md-4 shadow" style="height:500px;border-radius:1em;overflow-x:auto">
        <h4>Item Override</h4>
        <p>
          Item Override provides a simple way to add descriptive details to the daily menu choice to <span style="font-weight:600;">ENHANCE</span>
          the description.  If this is a permanent change, you sould consider changing the Item Master.
        </p>
        <p>
          Remember, when you <span style="font-weight:600;">override</span> an item in iCare, the POS ITEM is not changing.  So override Chicken to Roasted Chicken, for example and not "Tacos".
          Be careful to only add descriptive information and NOT change the true nature of the item.
        </p>
        <p style="color:red;font-weight:600;">
          NOTE: It is important to NOT change the TYPE of item.  Example; do not change Chicken to be Shrimp because the
          POS still rings up Chicken despite what you override here.
        </p>
        </b-card>
    </div>


    <div class="row" v-if="menu && !item && !isPreview && !showMonthlyCalendar && !showMonthlyRotationsCalendar">
      <card class="col-md-11 shadow mt-4" style="border-radius:1em;" @onChange="setCalendarChanged()">

        <b-checkbox v-model="showDescription">Show Full Descriptions</b-checkbox>
        <i class="fa fa-trash float-right" v-b-popover.hover.top="'Remove This Day'" @click="removeDay(menu.menuDate)"></i>
        <h4 class="mt-1">{{moment(menu.menuDate).format( "dddd, MMMM Do")}} Configuration <span style="font-size:.7em;margin-left:1em;">({{dailyOrderCount}} active orders for this day)</span></h4>
        <form v-on:submit.prevent @change="setCalendarChanged()">
          <sf-input v-model="menu.rotation"  label="Rotation"  type="list"  :options="rotationOptions" cstyle="width:14em;" id="rotation1"/>
          <sf-input v-model="menu.title" type="text" label="Title" id="title1"></sf-input>
          <sf-input v-model="menu.description" type="textarea" label="Description" id="description1"></sf-input>
        </form>
        <b-button class="btn btn-success btn-sm btn-fill" @click="addItem()" >Add Item</b-button>

        <div style="width:100%;overflow:auto;">
        <table class="table table-hover mt-2" >
          <thead class="thead-dark">
          <tr>
            <th>#</th>
            <th>x</th>
            <th>Override</th>
            <th>Item #</th>
            <th>Max #</th>
            <th>Title</th>
            <th>Description</th>
            <th>Price 1</th> <!-- FIXME - try to find the real name -->
            <th>Price 2</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(choice,index) in menu.choices" class="show-hand" @click="editChoice(index)" :key="index" :style="choice.itemNumber===0? 'background:#ff0202;color:white;' : ''" :class="(choice.isOverride) ? 'table-warning' : ''">
            <td>{{index+1}}</td>
            <td>
              <i class="fa fa-trash" v-b-popover.hover.top="'Remove This Item'" @click.prevent="removeItem(choice)"></i>
            </td>
            <td>{{(choice.isOverride) ? 'Y' : 'N' }}</td>
            <td>{{choice.itemNumber}}</td>
            <td>{{choice.maxChoice}}</td>
            <td>{{choice.title}}</td>
            <td style="width:240px;white-space:pre-line;overflow-y:auto;"><span v-if="showDescription">{{choice.description}}</span><span v-else style="white-space:nowrap;text-overflow:ellipsis;">{{choice.description.substring(0,15)+'...'}}</span></td>
            <td>{{currency(choice.price[0])}}</td>
            <td>{{currency(choice.price[1])}}</td>
          </tr>
          </tbody>
        </table>
        </div>
      </card>
    </div>

    <div v-if="showMonthlyCalendar" class="monthly-calendar">
      <div class="noprint">
        <b-button class="btn btn-success btn-sm btn-fill" @click="showMonthlyCalendar=false">Done</b-button>
      </div>
      <monthly-order-calendar
        :calendarItems="calendar"
        :config="monthlyCalendarConfig"
        @click="monthlyCalendarDayClick($event)"
      />
    </div>

    <div v-if="showMonthlyRotationsCalendar" class="monthly-calendar">
      <div class="noprint">
        <b-button class="btn btn-success btn-sm btn-fill" @click="showMonthlyRotationsCalendar=false">Done</b-button>
      </div>
      <monthly-order-calendar
        :calendarItems="calendar"
        title = "Rotation Schedule"
        :isShowRotation="true"
        :rotationName="rotationName"
        :isShowItems="false"
        :config="monthlyCalendarRotationsConfig"
        @click="monthlyCalendarDayClick($event)"
      />
    </div>

    <div v-if="isPreview">
      <card class="col-md-12">
        <b-button class="btn btn-success btn-sm btn-fill" @click="isPreview=false">Done</b-button>
        <h2>Production Preview</h2>
        <ul style="list-style:none;" v-for="(iMenu,index) in calendar.menu" :key="index">
          <li :id="'id-'+iMenu._id" style="border-radius:.75em;padding:2px;background:#7fb847;">
            <h4 class="show-hand" style="font-weight:500;margin-left:.75em;padding-bottom:0;" @click="menu=iMenu;isPreview=false;">
              {{iMenu.title}}
              <small style="color:white;"><i> ({{moment(iMenu.menuDate).tz($root.TZ).format( "dddd, MMMM, Do")}})</i></small>
            </h4>
            <br>
            <table class="table table-hover sm" style="background:white;border-radius:1em;">
              <tbody>
              <tr class="show-hand" v-for="(choice,choiceIndex) in iMenu.choices" :key="choiceIndex" @click="menu=iMenu;item=choice;isPreview=false;">
                <td style="width:64px;background:#7fb847;text-align:center;">
                  <small>Choice</small>
                  <span style="font-size:2em;">{{choiceIndex+1}}</span>
                </td>
                <td>
                  <div class="mb-0 mt-0" style="font-weight:900;font-size:1.25em;">{{choice.title}}
                    <small><i>({{choice.itemNumber}}) <span v-if="choice.isOverride" style="color:#ff0202;"> Item Override</span></i>
                    </small>
                  </div>
                  <div class="mt-0 mt-0" style="white-space:pre-line;margin-left:20px;padding:0;">
                    {{choice.description}}
                  </div>
                </td>
              </tr>
              </tbody>
            </table>

          </li>
        </ul>

      </card>
    </div>

  </div>
</template>
<script>
  import MenuItemService from "/src/Services/MenuItemService";
  import OrderService from "/src/Services/OrderService";
  import RevenueCenterService    from "/src/Services/RevenueCenterService";
  import RotationService    from "/src/Services/RotationService";
  import Moment from "moment";
  import Currency from "currency.js";
  import Card           from "/src/components/UIComponents/Cards/Card.vue";
  import MenuItemDetail from "/src/components/UIComponents/Cards/MenuItemDetailEdit.vue";
  import SfInput        from "/src/components/UIComponents/Inputs/SFormInput.vue";
  import { Calendar } from 'vue-sweet-calendar';
  import MonthlyOrderCalendar from "/src/components/UIComponents/orderMonthlyCalendar";
  import 'vue-sweet-calendar/dist/SweetCalendar.css';

  export default {
    components: {
      Card,
      MenuItemDetail,
      SfInput,
      MonthlyOrderCalendar,
      SweetCalendar: Calendar
    },
    data() {
      return {
        calendarSettingsVisible     : false,
        showMonthlyCalendar         : false,
        showMonthlyRotationsCalendar: false,
        canEditOverhead             : false,
        monthlyCalendarConfig       : {
          isShowMenuDescription: true,
          isShowItemDescription: true,
          isShowItemPrice      : false,
          enablePrintCalendar  : true,
          enableNextButton     : true,
          enablePrevButton     : true,
          isShowCalenderTitle  : true
        },
        monthlyCalendarRotationsConfig: {
          isShowMenuDescription: true,
          isShowItemDescription: false,
          isShowItemPrice      : false,
          enablePrintCalendar  : true,
          enableNextButton     : true,
          enablePrevButton     : true,
          isShowCalenderTitle  : true
        },
        isLiveEdit            : false,
        isAddItem             : false,
        justDeleted           : false,
        tabName               : "",
        fields                : [
          {key: "action", label: "Action", sortable: false},
          {key: "active", label: "Active", sortable: false},
          {key: "title", label: "Item Name", sortable: true},
          {key: "activeDate", label: "Show Begin", sortable: true},
          {key: "calendarStartDate", label: "Start Date", sortable: true},
          {key: "calendarEndDate", label: "End Date", sortable: true},
          {key: "updatedAt", label: "Last Update", sortable: true},
          {key: "lastUpdateBy", label: "By", sortable: true}
        ],
        rotations: null,
        rotationOptions: null,
        showDescription: true,
        calendarMessage       : "",
        orderCount: 0,
        dailyOrderCount:0,
        currentItemCategories       : null,
        menuItemCategories    : [],
        menuItems: [],
        revenueCenters: [],
        itemSearchString      : "",
        enableCalendarControls: false,
        orderCalendars        : [],         // the list of calendars
        isCalendarChanged     : false,
        isPreview: false,
        selectedDay: new Date(),
        calendar              : null,       // the month (one calendar)
        menu                  : null,       // the day (one day)
        item                  : null,
        loading               : false,
        isLoading             : "fa fa-spinner  fa-refresh-animate mr-3",
        notificationType      : [ "", "info", "success", "warning", "danger" ],
        notifications         : {
          topCenter: false
        },
        eventCategories       : [
          {
            id             : 1,
            title          : "No Items",
            textColor      : "white",
            backgroundColor: "#39a6c2"
          },
          {
            id             : 2,
            title          : "One Item",
            textColor      : "white",
            backgroundColor: "#a0e14f"
          },
          {
            id             : 3,
            title          : 'Two Items',
            textColor      : 'white',
            backgroundColor: '#7fb847'
          },
          {
            id             : 4,
            title          : 'More Than 2',
            textColor      : 'white',
            backgroundColor: '#ff813a'
          },

          {
            id             : 5,
            title          : 'Unassigned',
            textColor      : 'white',
            backgroundColor: '#555555'
          }
        ],
        events                : [
          // {
          //   title     : 'Week 1',
          //   start     : '2019-08-05 00:00 cst',
          //   end       : '2019-08-09 00:00 cst',
          //   repeat    : 'never',
          //   categoryId: 4
          // }
        ]

      }
    },
    watch: {
      calendar: {
        // Will fire as soon as the component is created
        //immediate: true,
        handler(cal) {
          let self = this;
        }
      },
      events: {
        // Will fire as soon as the component is created
        //immediate: true,
        handler(cal) {
        }
      }
    },
    computed  : {

      menuItemListSearch() {
        var t = this;
        return t.menuItems.filter(function(item) {

          if(t.itemSearchString === "") {
            return true;
          } else {
            return item.title.toLowerCase().includes(t.itemSearchString.toLowerCase());
          }

        })
      }
    },
    async created() {
      // this.getPendingAccounts();getMenu
      await Promise.all([
                          this.getOrderCalendars(""),
                          this.getMenuItemCategories(),
                          this.getRevenueCenters(),
                          this.getRotations()
//                          this.getMenuItems("meal,alacarte")
                        ]);

    },
    async beforeDestroy() {
      if(this.isCalendarChanged) {
        await this.updateOrderCalendar(this.calendar);
      }
    },
    methods: {
      currency(c) {
        return Currency(c);
      },
      moment(d) {
        return Moment(d);
      },
      priceName( item , priceNumber ) {
        return "myPrice" + priceNumber.toString();
      },
      addItem() {
        let item = { itemNumber: 0, isOverride: false, title: "New Item", description: "", POSName: "", POSName2: "", price: [3.25, 3.75, 0, 0, 0, 0, 0, 0, 0, 0], maxChoice: 3, minChoice: 0 };
        this.item = item; // this.menu.choices[this.menu.choices.length-1];  //.item
        this.menu.choices.push(this.item);

        this.setCalendarChanged();
        this.saveOrderCalendarOnly();
      },
      tabChanged() {
        alert("tab Changed");
      },
      setCalendarEndDate(d) {
        this.calendar.calendarEndDate = Moment.tz(d, this.$root.TZ).endOf("month").subtract(6,"hours").format("yyyy-MM-DDTHH:mm").toString();
        this.calendar.activeDate = Moment.tz(d, this.$root.TZ).subtract(15,"days").format("yyyy-MM-DDTHH:mm").toString();
      },
      rotationName( value ) {
        let r = _.find( this.rotations , {rotationId: value });
        if( r ) {
          return r.title;
        }
        if( typeof value == 'undefined' ) {
          return '';
        }
        return "**" + value;
      },
      monthlyCalendarDayClick(e) {
        let d = `${e.year}-${e.month}-${e.day}`;
        this.showMonthlyCalendar = false;
        this.showMonthlyRotationsCalendar = false;
        this.selectDay(this.moment(d).tz(this.$root.TZ).startOf("day").toDate());
      },
      selectionCalendarClick(e) {
        // let d = this.moment(e).tz(this.$root.TZ).toDate();
        // let d = Moment(e).format("YYYY-MM-DD");
        // console.log( "selectionCalendarClick; incoming, fixed" , e );
        this.selectDay(e);
      },
      calendarBasicsHeader() {
        let header = "";

        try {
          if (this.calendar.allowedRevenueCenters.length === 1) {
            header += _.find(this.revenueCenters, { revenueCenterId: this.calendar.allowedRevenueCenters[0] }).title;
          } else {
            header += `  Revenue Centers: ${this.calendar.allowedRevenueCenters.length}`;
          }
        } catch (error) {
          header += " missing Revenue Center " + this.calendar.allowedRevenueCenters[0].toString();
        }

        header += ` :: Categories: ${this.calendar.allowedCategories.length}`;
        return header;
      },
      clickDetails() {
        let d      = new Date();
        let result = d.isInRange('1-1-2019', '12-31-2019', 'month');
        alert("detials");
      },
      currentItemChanged() {
        // if(this.isLiveEdit) {
        //   this.saveMenuItem(this.currentItem);
        // }
      },
      revenueCenterOptions() {
        let rc = this.revenueCenters.map((r) => { return { text: r.title, value: r.revenueCenterId } });
        return rc;
      },
      categoryOptions() {
        let cats = this.menuItemCategories.map((c) => { return { text: c.title, value: c.category } });
        return cats;
      },
      setCalendarChanged() {
        this.isCalendarChanged = true;
        this.buildEvents(this.calendar);
      },
      async calendarActiveChanged() {
        if( !this.calendar.active ) {
          if( this.orderCount > 0 ) {
            if( await this.$root.confirm( `** WARNING ** There are ${this.orderCount} current orders.  This action will block more orders, but also will keep this calendar off your production page.  This is not a good idea!  If you MUST do this,go run the production reports first.` , "warning" )) {
              this.setCalendarChanged();
            } else {
              this.calendar.active = true;
              return
            }
          }
        }
        this.setCalendarChanged();
      },
      async itemEditDone() {
        await this.saveOrderCalendarOnly();
        this.buildEvents(this.calendar);
        this.item = null;
      },
      async previewMenuCalendar(calendar) {
        await this.saveOrderCalendar();
        this.showMonthlyCalendar = true;
      },
      async previewRotationsCalendar( calendar ) {
        await this.saveOrderCalendar();
        this.showMonthlyRotationsCalendar = true;
      },
      previewOrderList(calendar) {
        this.isPreview = true;
        if(this.menu) {
          setTimeout(() => { location.href = "#id-" + this.menu._id }, 1);
        }
      },
      async selectDay(d) {
        let t = this;
        this.selectedDay = d;
        this.saveOrderCalendarOnly();       // only saves if changed.

        let testDay = this.moment(d).tz(this.$root.TZ).format("MMDD");

        // console.log( "Select Day:" , d,testDay );

        this.menu = _.find(this.calendar.menu, function(item) {
          if( testDay == t.moment(item.menuDate).tz(t.$root.TZ).format("MMDD")) {
            return true;
          }
          return false;
        });

// console.log( "found menu" , d, this.menu );

        if(!this.menu) {

          if(this.moment(d).tz(this.$root.TZ).format("YYYY-MM-DD") < this.moment(this.calendar.calendarStartDate).tz(this.$root.TZ).format("YYYY-MM-DD") ||
             this.moment(d).tz(this.$root.TZ).format("YYYY-MM-DD") > this.moment(this.calendar.calendarEndDate).tz(this.$root.TZ).format("YYYY-MM-DD")) {
            return;
          }
          const menuDate = this.moment(d).tz(this.$root.TZ).startOf('day').toDate();
          const newTitle = ""; // this.moment(d).format("dddd [the] Do of MMMM, YYYY");
          const newDescription = ""; // this.moment(d).format("DDDo [Day Of The Year], wo [Week of The Year], Qo [Quarter]");
          this.menu            = {title: newTitle, description: newDescription, rotation: "default", rotation: 'default', isAvailable: false, menuDate: menuDate, choices: []};

          this.calendar.menu.push(this.menu);
          let sorted = _.sortBy(this.calendar.menu, m => this.moment(m.menuDate).format("YYYY-MM-DD").toString() );   // wjs --
          this.calendar.menu = sorted;

          this.setCalendarChanged();
          this.buildEvents(this.calendar);

        }

        // console.log( "Select UTC, Day,menu:" , d.toUTCString(),  d, this.menu );
        await this.getOrderCountByDate( this.calendar.calendarNumber, Moment(d).format("YYYY-MM-DD") );
        // 16-Apr await this.getOrderCountByDate( this.calendar.calendarNumber, Moment(d).tz(this.$root.TZ).format("YYYY-MM-DD") );
      },
      selectMenuItem(menuItem) {
        this.item.itemNumber  = menuItem.itemNumber;
        this.item.title       = menuItem.title;
        this.item.description = menuItem.description;
        this.item.price       = menuItem.price;
        this.item.category    = menuItem.category;
        this.item.POSName     = menuItem.POSName;
        this.item.POSName2    = menuItem.POSName2;
        this.item.maxChoice   = menuItem.maxChoice;
        this.setCalendarChanged()
      },
      goToday() {
        this.$refs.calendar.goToday();
      },
      setCurrentItem(item) {
        //console.log("clicked a row", JSON.stringify(item, null, 2));
      },
      async editChoice(index) {
        if( this.dailyOrderCount > 0 ) {
          await this.$root.alert( `There are ${this.dailyOrderCount} current orders for this day.  Changing this item may orphan existing items on order - check the production summary` , "warning" );
        }
        if(!this.justDeleted) {
          this.item = this.menu.choices[ index ];
        }
        this.justDeleted = false;
      },
      itemSearch() {
        alert("Searching For " + this.itemSearchString);
      },
      async removeItem(item) {
        var t = this;

        if(! await this.$root.confirm("Remove " + item.title + "?")) {
          return;
        }

        if(!item._id) {
          _.remove(this.menu.choices, {itemNumber: item.itemNumber});
        } else {
          _.remove(this.menu.choices, {_id: item._id});
        }

        this.item        = null;
        this.justDeleted = true;
        this.setCalendarChanged();
        this.saveOrderCalendarOnly();
        this.buildEvents(this.calendar);

        setTimeout(() => {t.justDeleted = false;}, 25);
      },
      async removeDay(menuDate) {
        if(! await this.$root.confirm("Remove " + this.moment(menuDate).format("dddd, MMMM [the] Do[?]").toString())) {
          return;
        }

        _.remove(this.events, {start: menuDate, end: menuDate});
        _.remove(this.calendar.menu, {menuDate: menuDate});

        this.events.push({});   // to make vue see the changes and update the calendar
        this.events.pop();

        this.menu = null;
        this.buildEvents(this.calendar);
        this.setCalendarChanged()
        this.saveOrderCalendar();

      },
      buildEvents(calendar) {
        this.events = [];

        let tz = this.$root.TZ;

        const NoItem  = 1
        const OneItem = 2;
        const TwoItem = 3
        const More    = 4

        for(let m of calendar.menu){
          var cat;
          if(m.choices.length > 0) {      // complete?
            if(m.choices.length > 3) {
              cat = More;
            } else {
              cat = m.choices.length + 1;
            }
          } else {
            if (m.title.length > 0 || m.description.length > 0 ) {
              cat = 1;
            } else {
              cat = 0;
            }
          }


          let event = {
            title     : m.title,
            // start     : this.moment(m.menuDate).tz(this.$root.TZ),   //m.menuDate,
            // end       : this.moment(m.menuDate).tz(this.$root.TZ),   // m.menuDate,
            // start     : this.moment(m.menuDate).tz(this.$root.TZ).add(12, "hours").toDate(),   //m.menuDate,
            // end       : this.moment(m.menuDate).tz(this.$root.TZ).add(12, "hours").toDate(),   // m.menuDate,

            // start     : m.menuDate.split("T")[0] + " 00:00",
            // end       : m.menuDate.split("T")[0] + " 00:00",

            // start     : this.moment(m.menuDate).tz(this.$root.TZ).startOf('day').toDate(),   //m.menuDate,
            // end       : this.moment(m.menuDate).tz(this.$root.TZ).startOf('day').toDate(),   // m.menuDate,

            start     : this.moment(m.menuDate).tz(this.$root.TZ).add(8,'hours').toDate(),   //m.menuDate,
            end       : this.moment(m.menuDate).tz(this.$root.TZ).add(8,'hours').toDate(),   // m.menuDate,

            repeat    : "never",
            categoryId: cat
          };

          // console.log( "menu and event" , m , event );
          this.events.push(event);
        }
      },
      async getRotations() {
        try {
          let response = await RotationService.getRotations();
          this.rotations = response.data;
          this.rotationOptions  = this.rotations.map((d) => { return { value: d.rotationId , text: d.title  } })
          // console.log( "rotations" , this.rotations );
        } catch (error) {
          console.log(error.message);
        }
      },
      async getMenuItemCategories() {
        var response;
        var t = this;

        t.loading = true;

        try {

          response                = await MenuItemService.getMenuItemCategories();
          this.menuItemCategories = response.data;
          // if(this.menuItemCategories.length > 0) {
          //   this.getMenuItems(this.menuItemCategories[ 0 ].category);
          // }


          t.loading = false;
        } catch(err) {
          if(err) {
            console.error(err);
            t.$root.errorMessage("Oops", err.response.data.message);
          }
          t.loading = false;
        }
      },
      async getMenuItems(category) {
        var response;
        var t = this;

        if (this.currentItemCategories === category) {
          return;
        }

        t.loading            = true;
        this.currentItemCategories = category;

        try {
          response         = await MenuItemService.getMenuItems(category);
          this.menuItems   = response.data;
          this.currentItem = null; // this.menuItems[ 0 ];
          t.loading        = false;
        } catch(err) {
          if(err) {
            console.error(err);
            t.$root.errorMessage("Oops", err.response.data.message);
          }
          t.loading = false;
        }
      },
      hydrateOrderCalendar(calendar) {
        //this.events = [];

        // {
        //   title     : 'Week 1',
        //   start     : '2019-08-05 00:00 cst',
        //   end       : '2019-08-09 00:00 cst',
        //   repeat    : 'never',
        //   categoryId: 4
        // }

        this.isCalendarChanged      = false;
        this.calendar               = calendar;
        this.enableCalendarControls = false;

        try {
          // for(let m of calendar.menu){
          //   var cat;
          //   if(m.choices.length > 0) {      // complete?
          //     if(m.choices.length !== 2) {
          //       cat = needsAttention;
          //     } else {
          //       cat = allGood;
          //     }
          //   } else {
          //     cat = hasErrors;
          //   }
          //
          //   let event = {title: m.title, start: m.menuDate, end: m.menuDate, repeat: "never", categoryId: cat};
          //   this.events.push(event);
          // }

          this.buildEvents(calendar);

          // let event = { title: "All Month", start:"2019-08-01 00:00 cst", end:"2019-08-30 00:00 cst", repeat:"never" , categoryId:1 };
          // this.events.push( event );

          if(new Date(calendar.calendarStartDate).getMonth() !== new Date(calendar.calendarEndDate).getMonth()) {
            this.enableCalendarControls = true;
          }

        } catch(error) {
          // todo: tell the user something about this issue.
          console.error(error);
        }
      },
      async getOrderCalendars(all) {
        var response;
        var t = this;

        t.loading = true;

        try {
          response            = await OrderService.getOrderCalendars(all);
          this.orderCalendars = response.data;
          t.loading           = false;
        } catch(err) {
          if(err) {
            console.error(err);
            t.$root.errorMessage("Oops", err.response.data.message);
          }
          t.loading = false;
        }
      },
      async getOrderCount( calendarNumber ) {
        try {
          let results = await OrderService.getOrderCount( calendarNumber );
          this.orderCount = results.data.orders;
        } catch( error ) {
          console.log( error.message );
          this.orderCount = 0;
        }
      },
      async getOrderCountByDate( calendarNumber, orderDate ) {
        try {
          let results = await OrderService.getOrderCount( calendarNumber,orderDate );
          this.dailyOrderCount = results.data.numberOrders;
        } catch( error ) {
          console.log( error.message );
          this.dailyOrderCount = 0;
        }
      },
      async getOrderCalendar(calendarRow) {

        let id = calendarRow._id;

        // todo: check if we've updated the existing and save it if we have ...

        try {
          let response = await OrderService.getOrderCalendar(id);
          this.selectedDay = new Date();
          this.hydrateOrderCalendar(response.data);

          this.getOrderCount( this.calendar.calendarNumber );

          if (response.data.allowedCategories.length > 0) {
            let cats = response.data.allowedCategories.join(",");
            await this.getMenuItems(cats)
            this.calendarSettingsVisible = false;
          } else {
            this.calendarSettingsVisible = true;
          }

          this.canEditOverhead = Moment(this.calendar.activeDate) > Moment();

        } catch(error) {
          console.error(error);
          // todo: tell the user about this.
        }
      },
      async setAllowedCategoriesChanged(calendar) {
        let t = this;
        setTimeout(async () => {
          if (calendar.allowedCategories.length > 0) {
            let cats = calendar.allowedCategories.join(",");
            await t.getMenuItems(cats)
          } else {
            t.menuItems = [];
          }
          t.setCalendarChanged();

        }, 1)

      },
      async addOrderCalendar() {

        if(! await this.$root.confirm("Add New Menu Calendar? ")) { return; }

        try {

          let response = await OrderService.addOrderCalendar();

          this.orderCalendars.push(response.data);
          this.setCalendarChanged();

        } catch(error) {
          console.error(error);

          // todo: tell the user what happened...
        }

      },
      async saveOrderCalendar() {
        await this.saveOrderCalendarOnly();       // only saves if changed.
      },
      async saveOrderCalendarOnly() {
        if (this.isCalendarChanged) {
          this.isCalendarChanged = false
          try {

            delete this.calendar.updatedAt;

            let response = await OrderService.updateOrderCalendar(this.calendar)


            //this.calendar = response.data;
            //this.selectDay(this.selectedDay);  // select the day we were on.

            //if (this.selectedDate) {
            //  this.menu = _.find(this.calendar.menu, function (item) {
            //    let td = t.moment(item.menuDate).add(12, "hours").toDate(); // new Date(item.menuDate); // add(12,"hours").toDate();
            //    if (td.getDate() === this.selectedDate.getDate()) {
            //      if (td.getMonth() === this.selectedDate.getMonth()) {
            //        return true;
            //      }
            //    }
            //    return false;
            //  });
            //}

          } catch (error) {

            console.log("ERROR: " , error);

            alert(error.message);

            //if(error.response.data) {   // todo: not sure how to stop this cascade.
            //  alert(error.response.data.message + "  This operation cannot be completed.  It appears someone changed the same record while you were editing.");
            //  if(confirm(
            //    "Are you sure your edits are the most current?  If so, press OK and we will overwrite the the other changes with your copy.  We recommend checking this situation before saying OK")) {
            //    try {
            //      delete this.calendar.updatedAt;
            //      let response           = await OrderService.updateOrderCalendar(this.calendar)
            //      this.isCalendarChanged = false;
            //    } catch(err) {
            //      alert("Something still went wrong.  Aborting");
            //    }
            //  }
            //}


          }
        }
      },
      async updateOrderCalendar(calendar) {

        try {

          if (this.isCalendarChanged) {
            delete calendar.updatedAt;   // fixme: this is leaking somewhere...
            await OrderService.updateOrderCalendar(calendar)
          }

          this.getOrderCalendars("");

          this.calendar          = null;     // if we get here, the save was successful -- otherwise, we'll be in catch.
          this.menu              = null;
          this.isCalendarChanged = false;

        } catch(error) {

          console.error(error);

          alert(error.message);

          //if(error.response.data) {
          //  alert(error.response.data.message + "  This operation cannot be completed.  It appears someone changed the same record while you were editing.");
          //  if(confirm(
          //    "Are you sure your edits are the most current?  If so, press OK and we will overwrite the previous changes.  We recommend checking this situation before saying OK")) {
          //    try {
          //      delete calendar.updatedAt;
          //      await OrderService.updateOrderCalendar(calendar)
          //      this.getOrderCalendars("");
          //      this.calendar          = null;     // if we get here, the save was successful -- otherwise, we'll be in catch.
          //      this.menu              = null;
          //      this.isCalendarChanged = false;
          //    } catch(err) {
          //      alert("Something still went wrong.  Aborting");
          //    }
          //  }
          //}

        }

      },
      async removeOrderCalendar(item) {

        if(! await this.$root.confirm("Remove " + item.item.title + "/" + item.item.calendarNumber + "?")) {
          return;
        }

        try {

          let response = await OrderService.removeOrderCalendar(item.item._id);
          this.orderCalendars.splice(item.index, 1);
          this.calendar = null;
        } catch(error) {
          // todo: tell the user about this.
          console.error(error);
        }

      },
      async getRevenueCenters() {
        try {
          let results = await RevenueCenterService.getRevenueCenters();
          this.revenueCenters = results.data;
        } catch (error) {
          console.log(error.message);
        }
      },
      notifyVue(verticalAlign, horizontalAlign) {
        const notification = {
          template: `<span>Welcome to <b>Light Bootstrap Dashboard</b> - a beautiful freebie for every web developer.</span>`
        };

        const color = Math.floor(Math.random() * 4 + 1);
        this.$notifications.notify({
                                     component      : notification,
                                     icon           : "nc-icon nc-app",
                                     horizontalAlign: horizontalAlign,
                                     verticalAlign  : verticalAlign,
                                     type           : this.notificationType[ color ]
                                   });
      }
    }
  }
</script>
<style lang="scss">

  .wolfie {
    inProcess: #ff813a;
    error: #ff0202;
    complete: #7fb847;
  }

  .table-warning {
    background: salmon;
  }

  .monthly-calendar {
    padding:.5em;
  }

  @media print {

    @page {
      size: letter portrait;
    }

    body {
      -webkit-print-color-adjust: exact !important;
      width:100%;
    }

    .logo {
      width: 300px !important;
      max-width: 300px !important;
    }

    .noprint {
      display: none;
    }

    .container {
      width: 100%;
      max-width: 100%;
    }
  }


</style>
