///-------------------------------------------------------------------------------------------------
// summary:	User service class
///-------------------------------------------------------------------------------------------------

import Api from '@/Services/Api';

export default {

  getUsers() {
    const response = Api().get("/api/1/users");
    return response;
  },

  saveUser( userData ) {

    var userId = userData._id;

    delete userData.history;            // just a little housekeeping to keep the data to a minimum; this
    delete userData.notes;              // gets done by the webservice but if we're mobile, data costs in transport so trim it here.
    delete userData.preferences;
    delete userData.active;
    delete userData.lastIp;
    delete userData.lastLogon;
    delete userData.nextPWChange;
    delete userData.mustChangePW;
    delete userData.confirmedSms;
    delete userData.confirmedUser;
    delete userData.userStatus;
    delete userData.accountType;
    delete userData.isOnline;
    delete userData.lockedOut;
    delete userData.logonCount;
    delete userData.failedLogonCount;

    const response = Api().patch(`/api/1/user/${userId}`, userData);
    return response;
  },

  saveTenantUser( tenantUserData ) {

    var tenantUserId = tenantUserData._id;

    delete tenantUserData.user;
    const response = Api().patch( `/api/1/tenantUser/${tenantUserId}` , tenantUserData );

    return response;
  },

  removeTenantUser( tenantUserId ) {
    const response = Api().delete( `/api/1/tenantUser/${tenantUserId}`);
    return response;
  },

  changePassword( userId , oldPassword , newPassword ) {
    const data = { oldPassword: oldPassword, newPassword: newPassword, userId: userId };
    const response  = Api().post( `/api/1/userChangePassword` , data );
    return response;
  },

  changePasswordEx( userId , newPassword ) {
    const data = { oldPassword: "**********" , newPassword: newPassword, userId: userId };
    const response  = Api().post( `/api/1/userChangePassword` , data );
    return response;
  },

  changePasswordWithCode( email, resetCode, newPassword ) {
    const response  = Api().post( `/api/1/changePasswordWithCode/${email}/${resetCode}` , { newPassword: newPassword });
    return response;
  },

  sendPasswordReset( email ) {
    const response  = Api().post( `/api/1/sendPasswordReset/${email}` );
    return response;
  },

  verifyResetCode( email , resetCode ) {
    const response  = Api().get( `/api/1/verifyResetCode/${email}/${resetCode}` );
    return response;
  },

  getUser(id) {
    const response = Api().get(`/api/1/user/${id}`);
    return response;
  },

  getUserAccess(id) {
    const response = Api().get(`/api/1/userAccess/${id}`);
    return response;
  },

  saveUserAccess( id , data ) {
    const response = Api().patch(`/api/1/userAccess/${id}`, data);
    return response;
  },

  getTenantUsers( accountType = "" ) {
    const response = Api().get(`/api/1/tenantUsers/${accountType}`)
    return response;
  },
  getTicketUsers( skill = "" ) {
    const response = Api().get(`/api/1/tenantUsers/1`,  {params: { inRole: 'tickets' , skill: skill }})
    return response;
  },
  getTenantUser(id) {
    const response = Api().get(`/api/1/tenantUser/${id}`)
    return response;
  },

  addTenantUser( userInfo ) {
    const response = Api().post("/api/1/tenantUser" , userInfo )
    return response;
  },

  registerTenantUser( userInfo ) {
    console.log( "registerTenantUser" , userInfo );
    const response = Api().post("/api/1/tenantUserRegister", userInfo )
    return response;
  },

  uploadPhoto(userPhoto) {
    const response = Api().post("/api/1/userPhoto", userPhoto)
    return response;
  },
  uploadPhotoFromText(data) {
    const response = Api().post("/api/1/userPhotoFromText", data)
    return response;
  },
  getTicketTypes() {
    const response = Api().get("/api/1/tenantUserTicketTypes");
    return response;
  },
  setUserOption( optionModule , optionKey , value ) {
    const response = Api().post(`/api/1/tenantUserOption/${optionModule}/${optionKey}`, value );
    return response;
  },
  getUserOption( optionModule , optionKey = "" ) {
    const response = Api().get(`/api/1/tenantUserOption/${optionModule}/${optionKey}` );
    return response;
  },
  getTransactionConfig( id ) {
    return Api().get( `/api/1/transactionConfig/${id}` );
  },
  updateTransactionConfig( id , data ) {
    return Api().patch( `/api/1/transactionConfig/${id}` , data );
  }

}
