<template>
  <div >
    <div v-if="enableExport" style="width:100%;">
      <div class="show-hand" style="text-align:right;font-size:1.5em;padding-right:1em;"><i class="fa fa-file-excel-o "  v-b-popover.hover.top="'Export this list as CSV'" @click="csvExport()"></i></div>
    </div>

    <div class="show-hand" v-if="showCheckbox"  >
      <b-link @click="selectAll()">Select All</b-link>  |
      <b-link @click="unSelectAll()">Un-Select All</b-link>  |
      <b-link @click="toggleAll()">Toggle All</b-link>  |
      <span v-if="numberChecked() > 0"><b-link @click="doAction()">{{actionTitle}} </b-link> | </span>
      <span style="color:gray;font-size:.8em;" > NOTE: Billable tickets will NOT auto-select </span>
   </div>
    <hr>
    <div style="height:fit-content;">
      <b-table  striped hover  head-variant="dark" :sticky-header="maxHeight" :items="items" :fields="fields" @row-clicked="onClick">

      <template v-slot:cell(ticketNumber)="data">
        <div id="no-nav" style="display:flex;min-width:100px;">
          <!-- {{data.item}} -->
          <b-checkbox v-if="showCheckbox" v-model="data.item.checked"></b-checkbox>
          <span style="text-align:left;margin-right:.5em;font-size:.8em;" @click.prevent="">{{data.value}}</span>
          <span v-if="showMissingResolution(data.item)" @click.prevent="" v-b-popover.hover.right="'No resolution entered'" style="width:32px;text-align:center;margin-left:auto;background:#FFBFB3;color:white;border-radius:50%;padding:.25em;"><i  class="fa fa-ban"/></span>
          <span v-if="data.item.projectId && data.item.projectId!='000000000000000000000000'" style="position:relative;top:-4px;color:#7F0099;"><i class="fa fa-product-hunt"></i></span>
          <span v-if="data.item.numberChangeOrders>0" style="position:relative;top:-4px;color:#05b4b8;"><i class="fa fa-adjust" v-b-popover.hover.top="'Change Orders: '+data.item.numberChangeOrders"></i></span>
          <span v-if="data.item.numberTasks>0" style="position:relative;top:-4px;color:#0919ba;"><i class="fa fa-tasks" v-b-popover.hover.top="'Tasks: '+ data.item.numberTasks "></i></span>
          <span v-if="data.item.billable" @click.prevent="" v-b-popover.hover.right="'Billable and currently ' + data.item.ticketTotal.toFixed(2)" :style="data.item.ticketTotal !== 0 ? 'color:green;' : 'color:orange;'" style="font-size:.8em;font-weight:900;position:relative;top:-1px;padding:0;" ><i  class="fa fa-dollar"/></span>
          <i v-if="data.item.updatedAt>$root.user.lastLogon" class="fa fa-bell" style="color:#CC2200;" v-b-popover.hover.right="'New Updates'"></i>
        </div>
      </template>

      <template v-slot:cell(status)="data">
        <div class="ticket-status d-flex ">
          <span class="ticket-status-name" :style="statusStyle(data.item.status)">{{data.item.closed ? "CLOSED" : data.item.status}}</span>
        </div>
      </template>

      <template v-slot:cell(priority)="data">
        <div class="ticket-status d-flex">
          <span class="ticket-status-name" :style="priorityStyle(data.value)">{{data.value}}</span>
        </div>
      </template>

      <template v-slot:cell(dueDate)="data">
        <div class="ticket-status d-flex">
           <span class="ticket-status-name" style="min-width:7em;font-size:.75em;" :style="ticketTimeColor(data.item)" v-if="!data.item.closed">{{(data.item.dueDate) ? moment(data.item.dueDate).endOf('day').subtract(8,'hours').fromNow():"-"}}</span>
        </div>
      </template>

      <template v-slot:cell(updatedAt)="data">
        <div class="d-flex">
          <div class="mr-4">{{moment(data.value).fromNow()}}</div>
          <!--        <span><div class="btn round-btn" :class="isStatusOpenSolved(data.item.status) ? 'btn-danger' : 'btn-secondary'"></div></span>-->
          <!-- todo: would be nice to have the above dot right-justified -->
        </div>
      </template>

      <template v-slot:cell(createdAt)="data">
        <div class="d-flex">
          <div class="mr-4" v-b-popover.hover.top="moment(data.value).format('LLLL')+' by '+data.item.createdBy||''">{{moment(data.value).fromNow()}}</div>
        </div>
      </template>

      <template v-slot:cell(startDate)="data">
        <div class="d-flex">
          <div class="mr-4" v-b-popover.hover.top="moment(data.value).format('LLLL')+' by '+data.item.createdBy ||''">{{moment(data.value).format("MM-DD-YY")}}</div>
        </div>
      </template>

      <template v-slot:cell(completeDate)="data">
        <div class="d-flex">
          <div class="mr-4" v-b-popover.hover.top="(data.value) ? moment(data.value).format('LLLL')+' by '+data.item.completeBy||'' : ''">{{data.value ? moment(data.value).format("MM-DD-YY") : ''}}</div>
        </div>
      </template>

      <template v-slot:cell(title)="data">
        <div class="subject">{{data.value}}</div>
      </template>


    </b-table>
    </div>
    </div>
</template>

<script>
import Moment from "moment";
import exportLib from "@/Lib/dataExportLib";

export default {
  name: "tablelist",
  props: {
    items            : { type: Array, default: () => [] },
    stickyHeader: { type: Boolean, default: false },
    maxHeight        : { type: String, default: "100%"},
    enableMultiSelect: { type: Boolean, default: false },
    showCheckbox     : { type: Boolean, default: false },
    actionTitle      : { type: String, default: "Action" },
    enableExport     : { type: Boolean, default: false },
  },
  data() {
    return {
      selections: [],
      fields: [
        {
          key: "ticketNumber",
          label: "#",
          sortable: true
        },
        {
          key     : "status",
          label   : "Status",
          sortable: true
        },
        {
          key: "priority",
          label: "Priority",
          sortable: true
        },
         {
          key: "dueDate",
          label: "Desired By",
          sortable: true
        },
        {
          key     : "client.clientName",
          label   : "Client",
          sortable: true
        },
        {
          key     : "title",
          label   : "Case Summary",
          sortable: true
        },
        {
          key     : "startDate",
          label   : "Date",
          sortable: true
        },
        // {
        //   key     : "createdAt",
        //   label   : "Created",
        //   sortable: true
        // },
        {
          key     : "completeDate",
          label   : "Completed",
          sortable: true
        },
        {
          key     : "category",
          label   : "Category",
          sortable: true
        },
        {
          key     : "assignedTo",
          label   : "Assignee",
          sortable: true
        },
        {
          key     : "serviceType",
          label   : "Service Type",
          sortable: true
        },
        {
          key     : "updatedAt",
          label   : "Last Updated",
          sortable: true
        }
      ]
    };
  },
  methods: {
    moment(d) {
      return Moment(d);
    },
    csvExport() {
      exportLib.csvExportAskFilename( this.items, this.fields);
    },
    toggleCheckbox( cb ) {
    },
    doAction() {
      this.$emit( "action" )
    },
    showMissingResolution( item ) {
      if( typeof item.resolution == "undefined" ) {
        return false;
      }
      if( item.resolution.length==0 && item.status == 'complete') {
        return true;
      }
      return false;
    },
    numberChecked() {
      let total = 0;
      this.items.map((t) => {
        if( t.checked ) {
          total++;
        }
      });
      return total;
    },
    selectAll() {
      this.items.forEach((t) => {
        if( !t.billable ) {
          t.checked = true;
        }
      })
    },
    unSelectAll() {
      this.items.forEach((t) => { t.checked = false; })
    },
    toggleAll() {
      this.items.forEach((t) => {
        if( !t.billable ) {
          t.checked = !t.checked;
        }
      })
    },
    onClick(item, index, event) {

      if( event.srcElement && event.srcElement.parentElement && event.srcElement.parentElement.id && event.srcElement.parentElement.id === "no-nav" ) {
        return;
      }

      let list = [];
      this.items.forEach( function( item ) {
        if( item.selected ) {
          list.push( item._id );
        }
      })

      if( list.length > 0 ) {
        this.$emit( "listClick" , list );
      } else {
        this.$emit("click", item._id || item.ticketNumber);
      }
    },
    priorityStyle( priority ) {
      let c = "";
      switch (priority) {
        case "low":
          c = "background:gray;color:white;";
          break;

        case "normal":
          c = "background:green;color:white;";
          break;

        case "high":
          c = "background:#FA9600;color:white;";
          break;

        case "critical":
          c = "background:#FF2D00;color:white;";
          break;

        default:
          c = "background:black;color:white;";
      }

      return "text-align:center;" + c;
    },
    statusStyle( status ) {
      let c;
      switch (status) {
        case "new":
          c = "background:orange;color:white;";
          break;

        case "open":
          c = "background:green;color:white;";
          break;

        case "in-process":
          c = "background:#9C27B0;color:white;";
          break;

        case "complete":
          c = "background:#333;color:white;";
          break;

        case "stuck":
          c = "background:#d25a53;color:white;";
          break;

        case "parked":
          c = "background:#1B76FF;color:white;";
          break;

        default:
          c = "background:#8B63A6;color:white;";
      }

      return "" + c;
    },
    ticketTimeColor( ticket ) {
      let t = this;
      let color, days;

      if (!ticket.dueDate) {
        return "color:black;";
      }

      if (ticket.status === 'parked') {
        return "background:#1B76FF;color:white;";
      }

      if (ticket.status === 'complete') {
        return "background-color:#fff;color:#77cc77;";
      }

      days = Moment().endOf('day').subtract(6, 'hours').diff(ticket.dueDate, 'days');

      if (days < 0)
        return "";

      if (days >= 5) {
        color = "#B01E1E" // red
      } else {
        color = "#E0A52F" // yellow
      }

      return "background-color:" + color + ";color:white;";
    },
    isStatusOpenSolved(status) {
      if (status === "open") {
        return true;
      } else if (status === "solved") {
        return true;
      } else {
        return false;
      }
    }
  }

};
</script>

<style lang="scss" >
.table {

  cursor:pointer;

  thead {
    white-space: nowrap;
    color: #7d7b79;
  }
  th {
  }
  td {
    vertical-align: middle;
    white-space: nowrap;
  }

  .ticket-status {
    text-align: center;
    box-sizing: border-box;

    .ticket-status-name {
      border-radius:.75em;
      min-width: 65px;
      font-size: 0.75em;
      font-weight: 400;
      padding: .25em .5em .25em .5em;
    }
  }

  .ticket-priority {
    text-align: center;
    border-radius: .75em;
    min-width: 65px;
    font-size: 0.75em;
    font-weight: 400;
    padding: .25em .5em .25em .5em;
  }

  .btn {
    &.round-btn {
      display:flex;
      height: 15px;
      width: 15px;
      border-radius: 50%;
      margin-top:4px;
      padding: 4px;
    }
  }

  .subject {
    // display:flex;
    // text-align:left;
    width:350px;
    white-space:nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: initial;
    font-weight: 600;
  }

  .ticket-square {
    vertical-align: top;
    display: inline-block;
    height: 35px;
    width: 35px;
    color: gold;
    font-size: 24pt;
    vertical-align: middle;
    text-align: center;
    // cursor: pointer;
    background-size: 52%;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
  }

  &.ticket-star-light {
      background-image: url("../../../assets/svg/star.svg"); // &:hover
    //background-image: url('../../../assets/svg/star-dark.svg');
  }

  &.ticket-star-dark {
    background-image: url("../../../assets/svg/star-dark.svg");
  }

}

  .table.b-table > thead > tr > [aria-sort]:not(.b-table-sort-icon-left),
  .table.b-table > tfoot > tr > [aria-sort]:not(.b-table-sort-icon-left) {
  outline: none;
  }
</style>
