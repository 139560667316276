<template>
  <vue-simple-suggest
    :debounce          = "debounce"
    :display-attribute = "displayField"
    :list              = "searchMethod"
    :min-length        = "minSearchLength"
    :mode              = "searchMode"
    :v-model           = "searchItem"
    :maxSuggestions    = "maxItems"
    :value-attribute   = "valueField"
      @select          = "select($event)"
  >
    <input v-model="text" :placeholder="placeHolder" type="search" />

    <div slot="suggestion-item" slot-scope="{ suggestion }" >

      <slot name="list-item" v-bind="suggestion">
        <template>
          <div>
            {{ suggestion.name }}
          </div>
        </template>
      </slot>

    </div>
  </vue-simple-suggest>
</template>
<script>
import VueSimpleSuggest from "vue-simple-suggest";
import "vue-simple-suggest/dist/styles.css"; // Optional CSS

export default {
  name      : "live-search",
  components: {
    VueSimpleSuggest
  },
  props     : {
    debounce       : {type: Number, default: 200},
    searchField    : {type: String, default: ""},
    displayField   : {type: String},
    minSearchLength: {type: Number, default: 2},
    searchMethod   : {type: Function, default: () => {}},
    searchMode     : {type: String, default: "select"},
    valueField     : {type: String},
    maxItems       : {type: Number, default: 10},
    placeHolder    : {type: String, default: "Account Search..."}
  },
  data() {
    return {
      searchItem: "",
      text      : ""
    }
  },
  computed: {},
  mounted() {
    this.searchItem = this.searchField;
    this.text = this.searchField;
  },
  methods: {
    select(e) {
      if(!e || e === null) {
        return;
      }
      // console.log("LiveSearch.select() fired...", e);
      this.$emit("select", e);
    },
  }
};
</script>
<style scoped></style>
