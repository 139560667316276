import { render, staticRenderFns } from "./CertificationsSetup.vue?vue&type=template&id=31cce852&scoped=true"
import script from "./CertificationsSetup.vue?vue&type=script&lang=js"
export * from "./CertificationsSetup.vue?vue&type=script&lang=js"
import style0 from "./CertificationsSetup.vue?vue&type=style&index=0&id=31cce852&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31cce852",
  null
  
)

export default component.exports