<template>
  <card>
    <h4 slot="header" class="card-title ">Interfaces<i class="fa fa-plus-square show-hand float-right" @click="makeNewInterface()"></i></h4>
    <div>
      <hr>

      <b-table striped hover head-variant="dark" stacked="md" sort-by="title" :items="tenant.POSinterfaces" :fields="columns">

        <template v-slot:cell(actions)="row">
          <i class="nc-icon nc-scissors show-hand float-left" @click="removeInterface(row.item._id)"></i>
        </template>

        <template v-slot:cell(title)="row">
          <div class="show-hand" :class="row.item.note ? 'font-weight-bold' : ''" @click="editInterface(row.item._id)" v-b-tooltip.hover placement="righttop" :title="row.item.note">
            {{row.item.title}}
          </div>
        </template>

        <template v-slot:cell(interfaceType)="row">
          <div class="show-hand" @click="editInterface(row.item._id)">{{row.item.interfaceType}}</div>
        </template>

        <template v-slot:cell(userId)="row">
          <div class="show-hand" @click="editInterface(row.item._id)" v-b-tooltip.hover placement="righttop" :title="row.item.password">{{row.item.userId}}</div>
        </template>

      </b-table>
      <b-modal v-model="isAdding"
               :title="modalTitle"
               size="lg"
               header-bg-variant="info"
               header-text-variant="light"
               footer-bg-variant="info"
               footer-text-variant="light">
        <b-container fluid>
          <b-form horizontal breakpoint="lg" autocomplete="random1" label="Interface Information" label-size="lg" label-class="font-weight-bold pt-0" class="mb-0">
            <b-form-group horizontal label-cols-sm="4" label-cols-lg="3" label-class="text-sm-right" label="Title" label-for="title">
              <b-form-input id="title" type="text" v-model="myInterface.title" required placeholder="Interface Title"></b-form-input>
            </b-form-group>
            <b-form-group horizontal label-cols-sm="4" label-cols-lg="3" label-class="text-sm-right" label="Interface Type" label-for="interfaceType">
              <b-form-select id="interfaceType" type="text" v-model="myInterface.interfaceType" :options="interfaceTypes" placeholder="Interface Type"></b-form-select>
            </b-form-group>
            <b-form-group horizontal label-cols-sm="4" label-cols-lg="3" label-class="text-sm-right" label="API Key" label-for="apiKeyLink">
              <!-- Making this a password field causes "autofill" to populate this entry, messing it up - so until I can figure out how to disable that, it's text -->
              <b-form-input autocomplete="random2" id="apiKeyLink" type="text" v-model="myInterface.apiKeyLink" required placeholder="API Key"></b-form-input>
              <!-- <b-form-input v-if="!showChars" autocomplete="random3" id="apiKeyLink" type="text" v-model="myInterface.apiKeyLink" required placeholder="API Key"></b-form-input> -->
              <!-- <b-checkbox v-model="showChars">Show API Key</b-checkbox> -->
            </b-form-group>
            <b-form-group horizontal label-cols-sm="4" label-cols-lg="3" label-class="text-sm-right" label="IP Address" label-for="ipAddress">
              <b-form-input id="ipAddress" type="text" v-model="myInterface.ipAddress" placeholder="All IP Addresses"></b-form-input>
            </b-form-group>
            <b-form-group horizontal label-cols-sm="4" label-cols-lg="3" label-class="text-sm-right" label="User ID" label-for="userId">
              <b-form-select id="userId" required type="text" v-model="myInterface.userId" :options="interfaceUsers" autocomplete="new-password" placeholder="User ID"></b-form-select>
            </b-form-group>
            <b-form-group horizontal label-cols-sm="4" label-cols-lg="3" label-class="text-sm-right" label="Password" label-for="userPassword">
              <b-form-input id="userPassword" required type="text" style="font-family:symbol;" autocomplete="new-password" v-model="myInterface.password" placeholder="User Password"></b-form-input>
            </b-form-group>
            <b-form-group horizontal label-cols-sm="4" label-cols-lg="3" label-class="text-sm-right" :rows="3" label="Notes" label-for="interfaceNote">
              <b-form-textarea id="interfaceNote" v-model="myInterface.note" placeholder="Notes" :rows="3" :max-rows="3"></b-form-textarea>
            </b-form-group>
          </b-form>
        </b-container>
        <div slot="modal-footer" class="w-100 mt-3">
          <b-btn v-if="!isEditing" size="sm" class="float-left btn-fill" variant="info" @click="addInterface">
            Clear Form
          </b-btn>
          <b-btn v-if="!isEditing" size="sm" class="float-right btn-fill" variant="success" @click="addInterface();">
            Add Interface
          </b-btn>
          <b-btn v-if="isEditing" size="sm" class="float-right btn-fill" variant="success" @click="addInterface();">
            Update
          </b-btn>
          <b-btn size="sm" class="float-right btn-fill mr-2" variant="danger" @click="isAdding=false">
            Cancel
          </b-btn>
        </div>
      </b-modal>

      <hr>
      <b-button class="btn btn-success btn-fill float-right"  @click="$router.go(-1)">
        Done
      </b-button>
      <!--<b-button class="btn btn-danger btn-fill float-right" @click="$router.go(-1)">
        Cancel
      </b-button>-->
    </div>
    <div class="clearfix"></div>
    <br/>
  </card>
</template>

<script>

  //
  //  The interface information is stored in the tenant record
  //

  import TenantService from "/src/Services/TenantService";
  import UserService   from "/src/Services/UserService";
  import Card          from "/src/components/UIComponents/Cards/Card.vue";
  import LTable        from "/src/components/UIComponents/Table.vue";

  export default {
    components        : {
      LTable,
      Card
    },
    data() {
      return {
        saveDisabled  : true,
        changeCount   : 0,
        modalTitle    : "Adding New Interface",
        showChars     : false,
        isAdding      : false,
        isEditing     : false,
        small         : true,
        interfaceUsers: [],
        interfaceTypes: ["undefined"],
        myInterface   : {title: "", interfaceType: "undefined", apiKeyLink: "", ipAddress: "", userId: "", password: "", note: ""},
        columns       : [
          "actions",
          {key        : "title", sortable: true},
          "interfaceType",
          {key        : "ipAddress", sortable: true},
          {key        : "userId", sortable: true}
        ],
        tenant        : {
          interfaces  : []
        }
      };
    },
    watch     : {
      tenant: {
        deep: true,
        handler(old) {
          this.changeCount++; // fixme: there are some internal things going on in the watcher that I prob need to fix.
          if(this.changeCount < 2) {
            return;
          }
          this.saveDisabled = false;
        }
      }
    },
    methods   : {
      makeNewInterface() {

        var number = Math.random()                  // 0.9394456857981651
        var id = number.toString(36).substr(2, 8);  // 'xtis06h6'

        this.myInterface = {
          title     : "New Interface",
          ipAddress : "*",
          apiKeyLink: "NEWCARE:" + id,
          userId    : "",
          password  : "",
          note      : ""
        };     // interface was a reserved word -- dang.

        this.showChars = true;   // so we can see the api key on add
        this.isAdding = true;
        this.isEditing = false;
        this.modalTitle = "Adding New Interface";

      },
      async addInterface() {
        if(!this.isEditing) {
          this.tenant.POSinterfaces.push(this.myInterface);
        }

        await this.saveInterfaces()

        this.isAdding = false;
        this.isEditing = false;
      },
      editInterface(d) {

        if(!d) {
          alert("This Interface Has Not Yet Been Saved - Recommend To SAVE or Cancel First");
          return;
        }

        var t = this;
        t.showChars = false;
        t.tenant.POSinterfaces.forEach(function(item, index) {
          if(item._id.toString() === d.toString()) {
            t.myInterface = item;
          }
        });

        this.modalTitle = "Editing Interface";
        this.isAdding = true;
        this.isEditing = true;

      },
      async removeInterface(d) {
        let t = this;

        if(!d) {
          alert("That Interface Has Not Yet Been Saved - Recommend To SAVE or Cancel First");
          return;
        }

        if(! await this.$root.confirmYesNo("Remove This Interface?<br>This is permanent!")) {
          return;
        };

        let p = _.findIndex( this.tenant.POSinterfaces, function(o) { return o._id.toString() === d.toString(); });

        if( p ) {
          this.tenant.POSinterfaces.splice(p, 1);
          await this.saveInterfaces();
        } else {
          await this.$root.alert( "Cannot find that id - be sure to save first" );
        }

      },
      async getInterfaceUsers() {
        let t = this;
        try {
          let results = await UserService.getTenantUsers("3");
          //this.interfaceUsers = results.data;

          t.interfaceUsers = [];
          results.data.forEach(function (u) {
            t.interfaceUsers.push(u.user.local.email);
          });
// console.log(JSON.stringify(this.interfaceUsers , null, 2));

        } catch (error) {
          // todo: inform the user about this situation...
          console.log(error);
        }
      },
      async getTenant() {
        var t = this;
        var tenant;
        var interfaceTypes;
        try {
          [ tenant, interfaceTypes ] = await Promise.all([
                                                           TenantService.getTenant(),
                                                           TenantService.getInterfaceTypes()
                                                         ]);

          this.interfaceTypes = interfaceTypes.data;
          this.tenant = tenant.data;

        } catch(err) {
          console.log("getTenant error: ", err);
          if(err.response) {
            if(err.response.data) {
              t.$root.errorMessage("Oops", err.response.data.message);
            }
          } else {
            t.$root.errorMessage("Oops", "Had an error getting the tenant");
          }
          return;
        }
      },
      async saveInterfaces() {
        var t = this;
        var response;
        try {

          // fixme: build up an interface record
          const tenantInterfaces = {_id: this.tenant._id, POSinterfaces: this.tenant.POSinterfaces};

          await TenantService.saveTenant(tenantInterfaces);
          t.$root.successMessage("SUCCESS", "Interfaces Updated");

        } catch(err) {
          if(err.response) {
            console.log("error response ", err.response);
            t.$root.errorMessage("Oops", err.response.data.message);
          } else if(err.request) {
            console.log("Error Request ", err.request);
          } else {
            console.log("message only ", err.message);
          }
          t.$root.errorMessage("Oops", "Unable to Save Interface Data");
        }
      }
    },
    mounted: async function () {
      await Promise.all([
        this.getInterfaceUsers(),
        this.getTenant()
      ])

    }
  };
</script>

<style lang="scss">
</style>
