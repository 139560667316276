<template>
  <card>
    <div>
      <h4 slot="header" class="card-title">Edit Profile</h4>
    </div>
    <div>
    <hr>
      <b-tabs style="min-height:450px;" class="tab-hover" active-nav-item-class="selected-tab">
        <b-tab title="General" active>
          <div class="mt-3 ml-5">
            <b-form @input="dataChanged()">
              <b-form-group horizontal  breakpoint="lg" label="User Information" label-size="lg" label-class="font-weight-bold pt-0" class="mb-0">
                <b-form-group horizontal label-cols-sm="4" label-cols-lg="3" label="Alias" label-class="text-sm-right" label-for="alias">
                  <b-form-input id="alias" v-model="user.alias"></b-form-input>
                </b-form-group>
                <b-form-group horizontal label-cols-sm="4" label-cols-lg="3" label="eMail" label-class="text-sm-right" label-for="email">
                  <b-form-input id="email" v-model="user.email"></b-form-input>
                </b-form-group>
                <b-form-group horizontal label-cols-sm="4" label-cols-lg="3" label="Last Name" label-class="text-sm-right" label-for="lastName">
                  <b-form-input id="lastName" v-model="user.lastName"></b-form-input>
                </b-form-group>
                <b-form-group horizontal label-cols-sm="4" label-cols-lg="3" label="First Name" label-class="text-sm-right" label-for="firstName">
                  <b-form-input id="firstName" v-model="user.firstName"></b-form-input>
                </b-form-group>
                <b-form-group horizontal label-cols-sm="4" label-cols-lg="3" label="My Timezone" label-class="text-sm-right" label-for="timezone">
                  <b-form-select id="timezone" v-model="user.TZ" :options="timezones"></b-form-select>
                </b-form-group>
              </b-form-group>
            </b-form>
          </div>
        </b-tab>

        <b-tab title="Preferences">
          <div v-if="tenantUser" style="padding:.5em 1em;">
            <div style="display:inline;">
              <h4 style="margin-top:0;">Your Dashboards</h4>
            </div>
            <b-form style="margin-left:10em;"  @input="dashboardChanged()">
              <b-checkbox-group v-model="tenantUser.dashboardOptions.dashboards"  stacked  size="sm" switches :options="dashboardOptions" ></b-checkbox-group>

            </b-form>
          </div>
        </b-tab>

        <b-tab title="Skills" >
          <div class="mt-4 ml-5">
          <h4>Skill Listing</h4>
          <p class="small">List the languages and technologies that you feel you know and for which you would like to be considered</p>
          <hr>
          <b-row>
            <b-col sm="2" class="float-right">
              <!-- <i class="nc-icon nc-simple-add show-hand float-left" @click="addSkill"></i>  -->
              <b-button @click="addSkill" class="btn btn-sm btn-fill btn-warning">Add</b-button>
            </b-col>
            <b-col sm="6">
              <b-input class="float-right" id="skill" v-model="skill" @keyup.enter.native="addSkill()"></b-input>
            </b-col>
            </b-row>
            <hr>
            <ol>
              <li v-for="(s) in user.skills" :key="s">
                <i class="nc-icon nc-scissors show-hand float-left" @click="skill=s;removeSkill(s)"></i>
                &nbsp;&nbsp;{{s}}
              </li>
            </ol>
          </div>
        </b-tab>
        <b-tab title="Change Password">
          <div class="mt-3 ml-5">

            <b-form>
              <b-form-group horizontal breakpoint="lg" label="Password Information" label-size="lg" label-class="font-weight-bold pt-0" class="mb-0">
                <div>
                  <p style="margin-top:1em;font-size:.8em;padding-left:13em;">NOTE: New password cannot be the same as your OLD password. Also, we check against common passwords and they are not disallowed.<br>The CHANGE PASSWORD button will appear when these conditions are met.</p>
                </div>
                <b-form-group horizontal label-cols-sm="4" label-cols-lg="3" label="Old Password" label-class="text-sm-right" label-for="oldPassword">
                  <b-form-input id="oldPassword" type="password"  autocomplete="new-password" :state="oldPassword.length>0" v-model="oldPassword"></b-form-input>
                  <b-form-invalid-feedback id="input-live-feedback1">
                    Please enter your ORIGINAL password
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group horizontal label-cols-sm="4" label-cols-lg="3" label="New Password" label-class="text-sm-right" label-for="newPassword">
                  <b-form-input id="newPassword" type="password"  autocomplete="new-password" :state="newPasswordOk()" v-model="newPassword"></b-form-input>
                  <b-form-invalid-feedback id="input-live-feedback2">
                    <span v-if="commonPW">{{commonPW}}</span>
                    <span v-else>Enter at least 6 letters and NOT the same as your old password</span>
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group horizontal label-cols-sm="4" label-cols-lg="3" label="Confirm Password" label-class="text-sm-right" label-for="newPasswordConfirm">
                  <b-form-input id="newPasswordConfirm" type="password"  autocomplete="new-password" :state="newPassword===newPasswordConfirm" v-model="newPasswordConfirm"></b-form-input>
                  <b-form-invalid-feedback id="input-live-feedback3">
                    Passwords do not match
                  </b-form-invalid-feedback>
                </b-form-group>
                <div class="float-right">
                <b-button class="btn btn-danger btn-fill btn-sm mr-3" @click="oldPassword=newPassword=newPasswordConfirm=''" v-if="oldPassword.length>0">Nope, Changed My Mind</b-button>
                <b-button class="btn btn-success btn-fill btn-sm " @click="changePassword" v-if="(oldPassword.length>0) && (newPassword.length>0) && (oldPassword!==newPassword) && (newPassword === newPasswordConfirm) && isPasswordOk">Yep, Change It</b-button>
                </div>
              </b-form-group>
            </b-form>
          </div>
        </b-tab>

      </b-tabs>

      <div class="text-center" v-if="!oldPassword || oldPassword.length===0">
        <b-button class="btn btn-success btn-fill float-right btn-sm" :disabled="!isChanged" @click="updateProfile()">
          Update Profile
        </b-button>
        <b-button class="btn btn-danger btn-fill float-right btn-sm mr-2" @click="cancel">
          Cancel
        </b-button>
      </div>
      <div class="clearfix"></div>
    </div>
  </card>
</template>
<script>

import UserService from "/src/Services/UserService";
import moment from "moment-timezone";
import Card from "/src/components/UIComponents/Cards/Card.vue";

  let commonPW = "";

export default {
  components: {
    Card
  },
  data() {
    return {
      isChanged         : false,
      isDashboardChanged: false,
      changeCount       : 0,
      oldPassword       : "",
      newPassword       : "",
      newPasswordConfirm: "",
      skill             : "",
      dashboardOptions: [
        { text: "Occupancy", value: {dashboard: "occupancy" }},
        { text: "Simple Sales $", value: {dashboard: "daily-sales" }},
        { text: "Hourly Sales", value: {dashboard: "hourly-sales" }},
        { text: "Monthly Sales Summary", value: {dashboard: "monthly-sales-summary" }},
        { text: "Helpdesk: New Tickets", value: {dashboard: "new-tickets" }},
        { text: "Helpdesk: Closed Tickets", value: {dashboard: "closed-tickets" }},
        { text: "Helpdesk: Oldest Ticket", value: {dashboard: "oldest-ticket" }},
        { text: "Helpdesk: Ticket Standings", value: {dashboard: "ticket-standings" }},
        { text: "Helpdesk: Ticket Summary", value: {dashboard: "ticket-summary" }},
        { text: "Storage Used", value: {dashboard: "storage-used" }},
        { text: "Housing Model Availability", value: {dashboard: "housing-model-available" }},
        { text: "Housing Model Bookable", value: {dashboard: "housing-model-bookable" }},
        { text: "Housing Model Summary", value: {dashboard: "housing-model-summary" }}
      ],
      timezones         : [
        // todo: get this list from the API (or even maybe from the TZ server)
        { text: "Please select a Timezone", value: null },
        { text: "America/New York (EDT/EST)", value: "America/New_York" },
        { text: "America/Chicago (CST/CDT)", value: "America/Chicago" },
        { text: "America/Denver (MST)", value: "America/Denver" },
        { text: "America/Los_Angeles (PST/PDT)", value: "America/Los_Angeles" },
        { text: "America/Anchorage (AKST/AKDT)", value: "America/Anchorage" },
        { text: "America/Phoenix (MST)", value: "America/Phoenix" },
        { text: "America/Fort_Nelson (PST/PDT/MST)",value: "America/Fort_Nelson" },
        { text: "Pacific/Honolulu (HST)", value: "Pacific/Honolulu" },
        { text: "Europe/London ", value: "Europe/London" },
        { text: "Europe/Paris (CET/CEST)", value: "Europe/Paris" },
        { text: "Philippine Standard Time PHST (UTC+8)", value: "Asia/Manila" }
      ],
      user: {
        alias    : "",
        email    : "",
        firstName: "",
        lastName : "",
        skills   : []
      },
      tenantUser  : null,
      isPasswordOk: false,
      badPasswords: [
        '000000',
        '0000000',
        '00000000',
        '01234567',
        '111111',
        '1111111',
        '121212',
        '123123',
        '123456',
        '1234567',
        '12345678',
        '123456789',
        '1234567890',
        '1234pass',
        '1q2w3e4r5t',
        '1qaz2wsx',
        '222222',
        '2222222',
        '333333',
        '3rjs1la7qe',
        '3edc4rfv',
        '444444',
        '555555',
        '654321',
        '666666',
        '696969',
        '777777',
        '888888',
        '987654',
        '987654321',
        '0987654321',
        '9876543210',
        '999999',
        'abc123',
        'abcdef',
        'abcdefg',
        'abcdefgh',
        'access',
        'access1',
        'admin0',
        'admin1',
        'admin123',
        'admin1234',
        'admin2',
        'admin3',
        'admin4',
        'admin5',
        'admin6',
        'admin7',
        'admin8',
        'admin9',
        'adminadmin',
        'adobe123',
        'ashley',
        'bailey',
        'baseball',
        'dragon',
        'football',
        'fuck123',
        'fuck1234',
        'fuckabc',
        'fuckme',
        'fuckyou',
        'google',
        'iloveyou',
        'jesus0',
        'jesus1',
        'jesus2',
        'jesus3',
        'jesus4',
        'jesus5',
        'jesus6',
        'jesus7',
        'jesus8',
        'jesus9',
        'jesuschrist',
        'letmein',
        'master',
        'monkey',
        'mustang',
        'mypass',
        'nothing',
        'p@$$word',
        'p@$$word1',
        'p@$$w0rd',
        'p@ssw0rd',
        'p@ssword',
        'pass1234',
        'passw0rd',
        'password',
        'password0',
        'password00',
        'password1',
        'password1234',
        'password2',
        'password3',
        'password4',
        'password5',
        'password6',
        'password7',
        'password8',
        'password9',
        'password99',
        'photoshop',
        'princess',
        'qazwsx',
        'qwerty',
        'qwerty123',
        'qwerty1234',
        'qwertyuiop',
        'shadow',
        'starwars',
        'sunshine',
        'superman',
        'superwoman',
        'trustno1',
        'welcome',
        'welcome1',
        'zxcvbnm'
      ]
    };
  },
  mounted() {
    this.getUser();
  },
  methods: {
    dataChanged() {
      this.isChanged = true;
    },
    dashboardChanged() {
      this.isDashboardChanged = true;
      this.dataChanged();
    },
    async cancel() {
      if( this.isChanged ) {
        if( ! await this.$root.confirmYesNo( "Changes Made - Exit Anyway?" )) {
          return;
        }
      }
      this.$router.go(-1);
    },
    newPasswordOk() {
      this.commonPW = "";
      if (this.newPassword.length > 5) {
        if (this.newPassword === this.oldPassword) {
          this.isPasswordOk = false;
          return false;
        }
        if (this.badPasswords.indexOf(this.newPassword.toLowerCase()) >= 0) {
          this.commonPW     = "Seriously? Your choice is way too common. It is in our 'common' list. Get a little more creative!";
          this.isPasswordOk = false;
          return false;
        }
        this.isPasswordOk = true;
        return true;
      }
      this.isPasswordOk = false;
      return false;
    },
    formatDate(d, e) {
      return moment(d).tz(this.$root.user.TZ).format("DD-MMM-YY hh:mm a");
    },
    addSkill() {
      var t = this;
      if (t.skill.length > 0) {
        if (t.user.skills.indexOf(t.skill) < 0) {
          t.user.skills.push(t.skill);
          t.dataChanged();
        }
      }
      t.skill = "";
    },
    removeSkill(s) {
      var i = this.user.skills.indexOf(s);
      if (i >= 0) {
        this.user.skills.splice(i, 1);
        this.dataChanged();
      }
    },
    async getUser() {
      var t = this;
      var response;
      try {
        this.oldPassword = "";
        response = await UserService.getTenantUser("");
        this.user       = response.data.user;  // extract just the USER info
        this.tenantUser = response.data;
      } catch (err) {
        console.log("getUser error: ", err);
        if (err.response) {
          if (err.response.data) {
            t.$root.errorMessage("Oops", err.response.data.message);
          }
        } else {
          t.$root.errorMessage("Oops", "Had an error getting the tenant user");
        }
      }
    },
    async changePassword() {
      var t = this;
      var response;
      try {
        response = await UserService.changePassword( t.user._id , t.oldPassword, t.newPassword );
        t.$root.setToken(response.data['x-access-token']);
        t.oldPassword = t.newPassword = t.newPasswordConfirm = "";
        await t.$root.alert( response.data.message );
      } catch (err) {
        console.log("getUser error: ", err);
        if (err.response) {
          if (err.response.data) {
            t.$root.errorMessage("Oops", err.response.data.message);
          }
        } else {
          t.$root.errorMessage("Oops", "Had an error getting the tenant user");
        }
      }
    },
    async updateDashboardPreferences() {
      try {

        this.tenantUser.dashboardOptions.title = "My Dashboard Title";

        let data = {
          _id: this.tenantUser._id,
          dashboardOptions: this.tenantUser.dashboardOptions
        }
        await UserService.saveTenantUser( data );
        this.isDashboardChanged    = false;
        this.user.dashboardOptions = this.tenantUser.dashboardOptions;
        this.$root.successMessage("SUCCESS", "Dashboard Updated");
      } catch( error ) {
        console.log( error.message );
      }
    },
    async updateProfile() {
      var t = this;
      try {

        if( this.isDashboardChanged ) {
          await this.updateDashboardPreferences();
        }

        await UserService.saveUser(this.user);    // this call returns NO data.

        delete this.user.pictureUrl;      // we don't want to save this if we changed it on the other panel

        t.$root.TZ                    = this.user.TZ;
        t.$root.user.alias            = this.user.alias; // todo: might want to use the result from the server as there could be other details returned fromother updates.
        t.$root.user.TZ               = this.user.TZ;
        t.$root.user.email            = this.user.email;
        t.$root.user.alias            = this.user.alias;
        t.$root.user.dashboardOptions = this.tenantUser.dashboardOptions;
        t.$root.successMessage("SUCCESS", "Profile Updated");
        t.$router.go(-1);     // todo: make sure we're not going back to login -- if so, let's go home.
      } catch (err) {
        console.log(err);
      }
    }
  }
};
</script>
<style>
</style>
