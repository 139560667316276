<template>
  <b-form-group :label-cols-md="labelCols" class="sf-input" :style="labelStyle" :class="inputClass" :label-class="labelclass" :label="label" :label-for="id">
    <!--<i class="fa fa-info help-class" style="float:left;"></i>-->

    <template v-if="type==='textarea'">
      <b-form-textarea :id="id"
                       :value="value"
                       :rows="parseInt(rows)"
                       :max-rows="parseInt(maxrows)"
                       @input.native="$emit('input', $event.target.value)"
                       @change="fieldChanged"
                       @focus="onFocus"
                       @blur="onBlur"
                       :readonly="readonly"
                       :style="cstyle"
                       :ref="iref"
                       v-bind="$attrs"
                       :state="validation"
                       :required="required" />
    </template>
    <template v-if="type==='list'">
      <b-form-select :id="id"
                     :type="type"
                     :value="value"
                     :disabled="disabled"
                     @input.native="$emit('input', $event.target.value)"
                     @change="fieldChanged"
                     @focus="onFocus"
                     @blur="onBlur"
                     :readonly="readonly"
                     :style="cstyle"
                     :ref="iref"
                     v-bind="$attrs"
                     :state="validation"
                     :options="options"
                     :required="required" />
    </template>
    <template v-if="type==='tz-date'">
      <!--      :value="moment(value).tz(TZ).format('YYYY-MM-DD').toString()"-->
      <!--      @input.native="inputNativeDate($event.target.value)"-->
      <b-form-input class="form-control"
                    :id="id"
                    type="date"
                    :disabled="disabled"
                    :value="moment(value).tz(TZ).format(dateFormat)"
                    @input.native="inputNativeDate($event.target.value)"
                    @change="fieldChanged"
                    @focus="onFocus"
                    @blur="onBlur"
                    :readonly="readonly"
                    :style="cstyle"
                    :ref="iref"
                    v-bind="$attrs"
                    :state="validation"
                    :noTZ="noTZ"
                    :required="required" />
    </template>
    <template v-if="type==='date' || type==='date-local'">
      <b-form-input class="form-control"
                    :id="id"
                    type="date"
                    :disabled="disabled"
                    :value="dateToYYYYMMDD(value)"
                    @input.native="inputNativeDate($event.target.value)"
                    @change="fieldChanged"
                    @focus="onFocus"
                    @blur="onBlur"
                    :maxDate="maxDate"
                    :readonly="readonly"
                    :style="cstyle"
                    :ref="iref"
                    v-bind="$attrs"
                    :state="validation"
                    :noTZ="noTZ"
                    :required="required" />

    </template>
    <template v-if="type==='time'">
      <b-form-select class=""
                     :id="id"
                     type="list"
                     :disabled="disabled"
                     :value="value"
                     @input.native="$emit('input' ,$event.target.value)"
                     @change="fieldChanged"
                     @focus="onFocus"
                     @blur="onBlur"
                     :readonly="readonly"
                     style="transition: ease-in-out 0s !important;"
                     :options="timeListing()"
                     :style="cstyle"
                     :ref="iref"
                     v-bind="$attrs"
                     :state="validation"
                     :noTZ="noTZ"
                     :required="required" />

    </template>
    <template v-if="type==='date-time'">
      <!--      <p><br>value: [{{ value }}] <br>dateValue [{{ dateValue }}] <br>timeValue [{{ timeValue }}]</p>-->

      <div style="display:flex;" :style="cstyle">
        <b-input class=""
                 :id="id"
                 type="date"
                 :disabled="disabled"
                 :readonly="readonly"
                 v-model="dateValue"
                 @input="makeDateTime(true)"
                 @change="fieldChangedDateTime"
                 @blur="onBlurDateTime"
                 @focus="onFocus"
                 :maxDate="maxDate"
                 style="width:12em;"
                 :ref="iref+'-date'"
                 :state="validation"
                 :noTZ="noTZ"
                 :required="required" />

        <template v-if="!noTime">
          <b-form-select v-if="timeLookup"
                         :id="id+'-time'"
                         :disabled="disabled"
                         :readonly="readonly"
                         v-model="timeValue"
                         @input="makeDateTime(true)"
                         @change="fieldChangedDateTime"
                         @blur="onBlurDateTime"
                         @focus="onFocus"
                         :options="timeListing()"
                         style="width:25em;margin-left:.5em;"
                         :ref="iref+'-time'"
                         :state="validation"
                         :required="required">
          </b-form-select>

          <!-- todo: make the time input a little better -->
          <b-input v-if="!timeLookup" :id="id+'-time'"
                   :disabled="disabled"
                   :readonly="readonly"
                   v-model="timeValue"
                   @input="makeDateTime(true)"
                   @change="fieldChangedDateTime"
                   @blur="onBlurDateTime"
                   @focus="onFocus"
                   :options="timeListing()"
                   style="width:5em;margin-left:.5em;"
                   :ref="iref+'-time'"
                   :state="validation"
                   :required="required">
          </b-input>
        </template>

        <b-form-select
            v-if="selectTimeZone"
            class=""
            :id="id"
            type="list"
            :disabled="disabled"
            v-model="timezone"
            @change="updateTypes(true)"
            style="width:15em;margin-left:.5em;transition: ease-in-out 1s !important;"
            :options="timeZoneList"
            :ref="iref+'-timezone'"
            v-bind="$attrs">
        </b-form-select>
      </div>

    </template>
    <template v-if="type==='date-text'">
      <b-form-input class=""
                    :id="id"
                    type="date"
                    :value="value"
                    @input.native="$emit('input', $event.target.value)"
                    @change="fieldChanged"
                    @focus="onFocus"
                    @blur="onBlur"
                    :readonly="readonly"
                    :style="cstyle"
                    :ref="iref"
                    v-bind="$attrs"
                    :state="validation"
                    :required="required" />
    </template>
    <template v-if="type==='comma'">
      <b-form-input class="form-control"
                    :id="id"
                    type="text"
                    :disabled="disabled"
                    :value="currencyValue"
                    :formatter="sFormatComma"
                    :lazy-formatter="lazy"
                    :ref="iref"
                    :maxlength="(maxlength)?maxlength:null"
                    @input.native="$emit('input', currency($event.target.value).toString())"
                    @change="fieldChanged"
                    @focus="onFocus"
                    @blur="onBlur"
                    :readonly="readonly"
                    :style="cstyle"
                    v-bind="$attrs"
                    :state="validation"
                    :required="required"
                    :placeholder="placeholder" />
    </template>
    <template v-if="type==='currency'">
      <b-form-input class="form-control"
                    :id="id"
                    type="text"
                    :value="currencyValue"
                    :formatter="sFormatMoney"
                    :ref="iref"
                    :lazy-formatter="lazy"
                    :disabled="disabled"
                    :maxlength="(maxlength)?maxlength:null"
                    @change="fieldChanged"
                    @input.native="$emit('input',currency($event.target.value)).toString()"
                    @focus="onFocus"
                    @blur="onBlur"
                    :readonly="readonly"
                    :style="cstyle"
                    v-bind="$attrs"
                    :state="validation"
                    :required="required"
                    :placeholder="placeholder" />
    </template>
    <template v-if="type==='number'">
      <b-form-input class="form-control"
                    :id="id"
                    type="number"
                    :value="value"
                    :formatter="outputFormat"
                    :ref="iref"
                    :lazy-formatter="lazy"
                    :disabled="disabled"
                    :maxlength="(maxlength)?maxlength:null"
                    @change="fieldChanged"
                    @input.native="$emit('input', $event.target.value)"
                    @focus="onFocus"
                    @blur="onBlur"
                    :readonly="readonly"
                    :style="cstyle"
                    :autocomplete="autocomplete"
                    v-bind="$attrs"
                    :state="validation"
                    :required="required"
                    :placeholder="placeholder" />
    </template>
    <template v-if="type==='combo'">
      <b-input-group :style="cstyle">
        <b-input-group-prepend :is-text="isText" v-if="prependIcon">
          <i :class="prependIcon" @click="prependClick" />
        </b-input-group-prepend>
        <b-form-input class="form-control sf-text-control"
                      :id="id"
                      type="text"
                      :value="value"
                      :formatter="outputFormat"
                      :ref="iref"
                      :lazy-formatter="lazy"
                      :disabled="disabled"
                      :maxlength="(maxlength)?maxlength:null"
                      v-on:keyup.enter.prevent="selectListItemByIndex(selectedIndex)"
                      v-on:keydown.down.prevent="selectNextItem()"
                      v-on:keydown.up.prevent="selectPreviousItem()"
                      v-on:keydown.tab="comboHandleTab($event)"
                      v-on:keyup.8="handleBackspace()"
                      @change="fieldChangedCombo"
                      @input.native="comboInputEvent($event)"
                      @blur="onBlurCombo"
                      @focus="onFocusCombo"
                      :readonly="readonly"
                      :autocomplete="autocomplete"
                      v-bind="$attrs"
                      :options="options"
                      :state="validation"
                      :required="required"
                      :placeholder="placeholder" />
        <b-input-group-append :is-text="isText" v-if="appendIcon" :style="appendIconStyle" @click="appendClick">
          <i :class="appendIcon" />
        </b-input-group-append>
        <div v-if="isShowDropdown">
          <ul class="sf-input-selection-list">
            <li class="sf-input-selection-list-item" v-for="(item,selectItemIndex) in comboOptions" :style="comboStyle" :class="selectedIndex==selectItemIndex ? 'sf-input-selection-list-item-selected' : '' " @click="selectListItem(item)">
              <template v-if="fetchValueField">
                {{ item[ fetchValueField ] }}
              </template>
              <template v-else>
                {{ item }}
              </template>
            </li>
          </ul>
        </div>
      </b-input-group>
    </template>
    <template v-if="type==='icon-text'">
      <b-input-group :style="cstyle">
        <b-input-group-prepend :is-text="isText" v-if="prependIcon">
          <i :class="prependIcon" @click="prependClick" />
        </b-input-group-prepend>
        <b-form-input class="form-control sf-text-control"
                      :id="id"
                      type="text"
                      :value="value"
                      :formatter="outputFormat"
                      :ref="iref"
                      :lazy-formatter="lazy"
                      :disabled="disabled"
                      :maxlength="(maxlength)?maxlength:null"
                      @change="fieldChanged"
                      @input.native="$emit('input', $event.target.value)"
                      @blur="onBlur"
                      :readonly="readonly"
                      :autocomplete="autocomplete"
                      v-bind="$attrs"
                      :state="validation"
                      :required="required"
                      :placeholder="placeholder" />
        <b-input-group-append :is-text="isText" v-if="appendIcon" @click="appendClick">
          <slot name="appendSlot">
            <i :class="appendIcon" :style="appendIconStyle" />
          </slot>
        </b-input-group-append>
      </b-input-group>
    </template>
    <template v-if="((type!=='date') && (type!=='combo') && (type!=='password') && (type!=='tz-date') && (type!=='date-time') && (type!=='date-local') && (type!=='date-text') && (type!=='icon-text') && (type!=='number') && (type!=='time')  && (type!=='currency') && (type!=='comma') && (type!=='list') && (type!=='checkbox') && ( type !=='textarea') )">
      <!-- <template v-if="type==='text'"> -->
      <b-form-input class="form-control sf-text-control"
                    :id="id"
                    :type="type"
                    :value="value"
                    :formatter="outputFormat"
                    :ref="iref"
                    :lazy-formatter="lazy"
                    :disabled="disabled"
                    :maxlength="(maxlength)?maxlength:null"
                    @change="fieldChanged"
                    @input.native="$emit('input', $event.target.value)"
                    @focus="onFocus"
                    @blur="onBlur"
                    :readonly="readonly"
                    :autocomplete="autocomplete"
                    v-bind="$attrs"
                    :style="cstyle"
                    :state="validation"
                    :required="required"
                    :placeholder="placeholder" />
    </template>
    <template v-if="(type =='password' )">
      <!-- <template v-if="type==='text'"> -->
      <b-input-group :style="cstyle">
        <b-form-input
            class="form-control sf-text-control"
            :id="id"
            :type="type"
            :value="value"
            :formatter="outputFormat"
            :ref="iref"
            :lazy-formatter="lazy"
            :disabled="disabled"
            :maxlength="(maxlength)?maxlength:null"
            @change="fieldChanged"
            @input.native="$emit('input', $event.target.value)"
            @focus="onFocusPassword"
            @blur="onBlurPassword"
            :readonly="readonly"
            :autocomplete="(autocomplete)?autocomplete:'new-password'"
            v-bind="$attrs"
            :style="cstyle"
            :state="validation"
            :required="required"
            :placeholder="placeholder" />
        <b-input-group-prepend :is-text="isText" v-if="allowShowPassword" @click="showPassword">
          <i class="fa fa-eye" />
        </b-input-group-prepend>
      </b-input-group>
    </template>
    <b-form-text v-if="helpText" :style="helpTextStyle"><i :class="helpTextIcon"></i>{{ helpText }}</b-form-text>
    <b-form-invalid-feedback :state="validation">{{ invalidText }}</b-form-invalid-feedback>
    <b-form-valid-feedback :state="validation">{{ validText }}</b-form-valid-feedback>
  </b-form-group>

</template>

<script>

/*
 *  Generic Input Routine
 *
 *  capable of managing most data types except checkboxes for some reason.
 *
 *  NOTE:  Be sure to have your data loaded before calling this control when using 'comma' or 'currency' data types as they
 *         fire special events and at this time, not monitoring the data change to reformat the 'value' inbound; thus it
 *         may appear that your data is not present when using these data types.
 *
 * */

import Currency from "currency.js";
import Moment   from "moment-timezone";
import sNumeral from "numeral";

function randomId(title) {
  let v = (Math.ceil(Math.random() * 100000000)).toString();
  return title + v;
}

// TYPES = ['text', 'combo', 'password', '**email', 'number', 'list', '**url', 'tel', 'search', '**range', '**color', 'date', 'time', 'datetime', 'datetime-local', '**month', '**week']; // @vue/component ** Not yet
export default {
  inheritAttrs: false,
  name        : 'sf-input',
  props       : {
    allowShowPassword: {type: Boolean, default: true},
    appendIcon       : {type: String, default: null},
    appendIconStyle  : {type: String, default: ""},
    autocomplete     : {type: String},
    boe              : {type: String, default: "begin"},                                             // begin or end
    checkboxLabel    : {type: String, default: ""},
    comboStyle       : {type: String, default: ""},
    cstyle           : {type: String, default: ""},
    disabled         : {type: Boolean, default: false},
    dateFormat       : {type: String, default: "YYYY-MM-DD"},
    dateTimeFormat   : {type: String, default: "YYYY-MM-DDTHH:mm"},
    fetchFunction    : {type: Function, default: null},               // if defined, the function used to fetch a list of selection options
    fetchKeyField    : {type: String, default: "_id"},                // if defined, the field name to use as the key for the fetch results and populate the options
    fetchValueField  : {type: String, default: "accountTitle"},       // if defined, the field name to use as the display for the fetch results and populate the options
    fetchDelayMs     : {type: Number, default: 250},                  // if defined, the delay in milliseconds to wait before fetching the options after input changes
    fetchMinChars    : {type: Number, default: 2},                    // if defined, the minimum number of characters to wait for before fetching the options
    helpText         : {type: String, default: null},
    helpTextIcon     : {type: String, default: "fa fa-arrow-up"},
    helpTextStyle    : {type: String, default: "padding-left:1em;"},
    hoverText        : {type: String, default: null},
    id               : {type: String, default: () => randomId('sfInput-')},
    inputClass       : {type: String, default: "mb-1 mt-0"},
    invalidText      : {type: String, default: ""},
    iref             : {type: String, default: ""},
    isCurrency       : {type: Boolean, default: false},
    isText           : {type: Boolean, default: true},
    label            : {type: String, default: ""},
    labelCols        : {type: Number, default: 4},
    labelStyle       : {type: String, default: ""},
    labelclass       : {type: String, default: "text-sm-right"},
    lazy             : {type: Boolean, default: true},
    maxDate          : {type: Date, default: null},
    maxlength        : {type: Number, required: false},
    maxrows          : {type: Number, default: null},
    noTZ             : {type: [ Boolean, String ], default: false},
    noTime           : {type: Boolean, default: false},
    options          : {type: Array, default: () => []},
    outputFormat     : {type: Function},
    placeholder      : {type: String, default: ""},
    prependIcon      : {type: String, default: null},
    readonly         : {type: Boolean, default: false},
    required         : {type: Boolean, default: false},
    rows             : {type: Number, default: 3},
    selectTimeZone   : {type: Boolean, default: false},
    mustExist        : {type: Boolean, default: false},   // used by the combo to ensure a value DOES exist in the options
    startTime        : {type: String, default: null},
    timeOffset       : {type: String, default: ""},
    timeLookup       : {type: Boolean, default: true},
    type             : {type: String, default: "text"},
    TZ               : {type: String, default: Intl.DateTimeFormat().resolvedOptions().timeZone},
    timeZoneList     : {
      type: Array, default: () => {
        return [];
      }
    },
    uppercase        : {type: Boolean, default: false},
    validText        : {type: String, default: ""},
    validator        : {type: Function, default: null},
    value            : {type: [ String, Number, Boolean, Date, Object ], default: () => ""}

  },
  components  : {
    Currency
  },
  computed    : {
    validation() {
      if(this.validator) {
        return this.validator();
      }
      return null;
    },
    comboOptions() {
      return this.makeComboOptions();
    }
  },
  created() {
    //  this.updateTypes();
  },
  mounted() {
    this.updateTypes(false);
    this.resetDataChanged();
  },
  data() {
    return {
      mData         : "",
      currencyValue : "0.00",
      comboValue    : "",
      dateValue     : "",
      timeValue     : "",
      timeValue2    : "",
      timezone      : "",
      searchOptions : [],
      isEditing     : false,
      isDataChanged : false,
      isShowDropdown: false,
      selectedIndex : 0,

      fetchOptions: {
        lastFetch       : null,
        lastFetchKey    : null,
        lastFetchResults: null
      }
    }
  },
  watch  : {
    value() {
      if(!this.isEditing) {
        this.updateTypes(true);
      }
    }
  },
  methods: {
    currency(c) {
      return Currency(c);
    },
    moment(d) {
      return Moment(d);
    },
    dataChanged() {
      this.isDataChanged = true;
    },
    resetDataChanged() {
      this.isDataChanged = false;
    },
    sFormatMoney(value, event) {
      return this.currency(value);
    },
    fieldChanged() {
      this.dataChanged();
      this.$emit('change', this.value);
    },
    fieldChangedCombo() {
      this.dataChanged();
      this.$emit('change', this.comboValue);
    },
    fieldChangedDateTime() {
      this.dataChanged();
      this.$emit('change');
    },
    sFormatComma(value, event) {
      //return this.currency(value, { seperator: ',' });
      if(this.isCurrency) {
        return sNumeral(value).format("0,0.00");
      } else {
        return sNumeral(value).format("0,0");
      }
    },
    timeChanged(e) {
      // console.log("timeChanged() ", e, this.timeValue, this.timeValue2);
    },
    // formatAsMoney(v) {
    //   this.currencyValue = this.currency(this.currencyValue).toString();
    // },
    // formatAsComma(v) {
    //   // this.currencyValue = this.currency(this.currencyValue, { seperator: ',' }).toString();
    //   if( this.isCurrency ) {
    //     this.currencyValue = sNumeral(this.currencyValue).format("0,0.00").toString();
    //   } else {
    //     this.currencyValue = sNumeral(this.currencyValue).format("0,0").toString();
    //   }
    // },
    makeComboOptions() {
      if(!this.isShowDropdown) {      // take the entire list if we're not showing the list
        if(this.fetchFunction) {
          return this.searchOptions;
        } else {
          return this.options;
        }
      }

      let results;

      if(_.isEmpty(this.comboValue)) {     // empty search, show all the options.
        if(this.fetchFunction) {
          results = this.searchOptions;
        } else {
          results = this.options;
        }
        // results = this.options;
      } else {
        if(!this.fetchFunction) {
          results = this.options.filter((c) => {      // we have something to filter - todo: use the fetchKeyField here at some point.
            return c.text.toLowerCase().indexOf(this.comboValue.toLowerCase()) > -1;
          });
        } else {
          results = this.searchOptions;
        }
      }

      if(!results || results.length == 0) {         // nothing in the list, hide the dropdown.
        this.isShowDropdown = false;
      }
      return results;
    },
    selectListItem(item) {
      if(this.fetchValueField) {
        this.comboValue = item[ this.fetchValueField ];
      } else {
        this.comboValue = item;
      }

      this.$emit("listSelect", item);
      this.$emit('input', this.comboValue);

      this.resetDataChanged();
      this.isShowDropdown = false;
    },
    selectListItemByIndex(index) {
      if(this.isShowDropdown) {
        // eslint-disable-next-line vue/no-mutating-props
        if(this.fetchValueField) {
          this.comboValue = this.comboOptions[ index ][ this.fetchValueField ];
        } else {
          this.comboValue = this.comboOptions[ index ]
        }
        if(this.isDataChanged) {
          this.$emit("listSelect", this.comboOptions[ index ]);
        }
      }

      this.$emit('input', this.comboValue);
      this.resetDataChanged();

      this.isShowDropdown = false;
    },
    comboHandleTab(e) {
      if(!this.isShowDropdown) {
        // how do I stuff a tab in?
        return;
      }
      e.preventDefault();
      if(e.shiftKey) {
        this.selectPreviousItem();
      } else {
        this.selectNextItem();
      }
    },
    comboInputEvent(e) {

      let t = this;
      t.dataChanged();

      if(e.target.value.length >= t.fetchMinChars) {
        if(t.fetchFunction) {
          _.debounce(() => {
            t.fetchFunction(e.target.value).then((results) => {
              // eslint-disable-next-line vue/no-mutating-props
              t.searchOptions = results; // results.map( (r) => { return {value: r[t.fetchKeyField], text: r[ t.fetchValueField]} } );
              // this.selectedIndex = _.findIndex(this.comboOptions, (o) => { return o.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1 });
              t.isShowDropdown = true;
            });
          }, t.fetchDelayMs)();

          // t.fetchFunction( e.target.value ).then( (results) => {
          //   console.log("fetchFunction results", results);
          //   t.options = results; // results.map( (r) => { return {value: r[t.fetchKeyField], text: r[ t.fetchValueField]} } );
          // console.log("fetchFunction options", t.options);
          // this.selectedIndex = _.findIndex(this.comboOptions, (o) => { return o.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1 });
          // t.isShowDropdown = true;
          // });
        } else {
          this.selectedIndex = _.findIndex(this.comboOptions, (o) => { return o.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1 });
        }
      }

      t.$emit('input', e.target.value)
    },

    handleBackspace() {
      this.isShowDropdown = true;
    },
    selectNextItem() {
      if(this.isShowDropdown) {
        if(this.selectedIndex < this.comboOptions.length) {
          this.selectedIndex++;
        } else {
          this.selectedIndex = 0;
        }
      } else {
        this.isShowDropdown = true;
      }
    },
    selectPreviousItem() {
      if(this.isShowDropdown) {
        if(this.selectedIndex > 0) {
          this.selectedIndex--;
        } else {
          this.selectedIndex = this.comboOptions.length;
        }
      } else {
        this.isShowDropdown = true;
      }
    },
    timeListing(startTime = null) {
      if(startTime === null) {
        if(this.startTime) {
          startTime = this.startTime;
        } else {
          startTime = "06:00 AM"; // Moment().format("hh:00 a");
        }
      }
      const timeList      = [];
      const [ hh, mm, a ] = startTime.split(/:|\s/); // split start time string into parts

      let hour   = parseInt(hh);
      let minute = parseInt(mm);
      let period = (a) ? a.toUpperCase() : 'AM';

      if(period === 'PM' && hour < 12) {
        hour += 12; // convert to 24-hour time
      }

      for(let i = 0; i < 48; i++){ // generate 48 30-minute increments

        if(hour < 12) {
          period = 'AM';
        } else if(hour >= 12) {
          period = 'PM';
        } else {
          period = 'AM';
        }

        let x = {
          text : `${((hour % 12) == 0 ? 12 : hour % 12).toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')} ${period}`,
          value: `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`
        };

        timeList.push(x);

        minute += 30;
        if(minute >= 60) {
          hour += 1;
          if(hour > 23) {      // gota loop it back around to handle "startTime" wrapping
            hour = 0;
          }
          minute -= 60;
        }
      }

      return timeList;
    },
    showPassword() {
      this.type = "text";
    },
    prependClick() {
      this.$emit('prependClick', this.value);
    },
    appendClick(e) {
      this.$emit('appendClick', this.value);
    },
    onFocus(e) {
      this.isEditing = true;
      this.resetDataChanged();
      this.$emit("focus", e);
    },
    onFocusCombo(e) {
      this.isEditing = true;
      this.resetDataChanged();

      if(this.comboValue) {
        let len = this.comboValue.length;
        if(len >= this.fetchMinChars) {
          if(this.isDataChanged) {
            this.isShowDropdown = true;
          }
          // if( this.comboOptions.indexOf(this.value) < 0 ) {
          //   this.isShowDropdown = true;
          // }
        }
      }

      this.$emit("focus", e);
    },
    onBlurCombo(e) {

      if(this.isShowDropdown) {
        e.preventDefault();
        this.$refs[ this.iref ].focus();
        return;
      }

      this.$nextTick(() => {
        this.isEditing      = false;
        this.isShowDropdown = false;
      });

      if(this.isDataChanged) {
        // this.$emit("listSelect", this.comboValue);
        this.$emit("listSelect", e.target.value);
        this.resetDataChanged();
      }

      this.$emit("blur", e);
    },
    onBlurDateTime(e) {
      // this.updateTypes(true);
      this.isEditing = false;
      this.$emit("blur", e);
    },
    onBlur(e) {
      this.updateTypes(true);
      this.isEditing = false;
      this.$emit("blur", e);
    },
    onFocusPassword(e) {
      this.isEditing = true;
      // this.removeAttribute('readonly');
      this.$emit("focus", e);
    },
    onBlurPassword(e) {
      this.updateTypes(true);
      this.isEditing = false;
      // this.setAttribute('readonly','');
      this.$emit("blur", e);
    },
    updateTypes(isSave = false) {
      if(this.type === 'currency') {
        //this.currencyValue = this.currency(this.value).toString();
        this.currencyValue = sNumeral(this.value.toString()).format('0.00').toString();
      } else if(this.type === 'comma') {
        if(this.isCurrency) {
          this.currencyValue = sNumeral(this.value).format('0,0.00').toString();
        } else {
          this.currencyValue = sNumeral(this.value).format('0,0').toString();
        }
      } else if(this.type === 'combo') {
        this.comboValue = this.value;
      } else if(this.type === 'date-time') {
        if(isSave) {
          // this.value = this.makeDateTime();
          // don't update the timezone on save, just use it.
          // this.dateValue = Moment(this.value).tz(this.timezone).format("yyyy-MM-DD").toString();
          // this.timeValue = Moment(this.value).tz(this.timezone).format("HH:mm").toString();

          this.timezone  = this.TZ;
          this.dateValue = Moment.tz(this.value,this.timezone).format("yyyy-MM-DD").toString();
          this.timeValue = Moment.tz(this.value,this.timezone).format("HH:mm").toString();
        } else {
          this.timezone  = this.TZ;
          this.dateValue = Moment.tz(this.value,this.timezone).format("yyyy-MM-DD").toString();
          this.timeValue = Moment.tz(this.value,this.timezone).format("HH:mm").toString();
        }
      }
    },
    makeDateTime(shouldEmit = false) {
      let newDT = this.dateValue + " " + this.timeValue;
      let d     = Moment.tz(newDT, this.timezone).toDate(); // NOTE: DO NOT TOUCH THIS - it does the right thing with the timezone.
      // let x = Moment(d).tz(this.TZ).toDate();

      if(d && shouldEmit) {
        // this.value = d;
        this.$emit('input', d);
      }

      return d;
    },
    inputNativeDate(v) {
      let result;

      if(typeof v === "string") {
        if(this.timeOffset > "") {
          result = v + "T" + this.timeOffset;
        } else {
          result = v;
        }
      } else {
        result = v;
      }

      if(this.type === 'tz-date') {
        let d;
        if(!v) {
          this.$emit('input', null);
          return;
        }
        if(this.boe === "begin") {
          d = Moment.tz(result,this.TZ).startOf("day");// .format(this.dateTimeFormat).toString();
        } else {
          d = Moment.tz(result,this.TZ).endOf("day"); // .format(this.dateTimeFormat).toString();
        }
        this.$emit('input', d);
      } else {
        this.$emit('input', result);
      }
    },
    inputNativeCheckbox(v) {
      this.$emit('input', v);
    },
    dateToYYYYMMDD(d) {
      if(this.noTZ) { return d }
      // if( this.noTZ ) {
      //   return Moment(d).format("YYYY-MM-DD").toString();
      // }

      // return Moment(d).tz(this.$root.TZ).format("YYYY-MM-DD").toString();

      if(typeof d === "string") {         // assume here if we're string with no time, we want just the SHOWN date
        if(d.indexOf("T") > 0) {
          return d.split("T")[ 0 ];
        }

        if(d.indexOf("/") > 0) {
          if(d.indexOf("/") < 3) {
            let nd = d.split("/");
            d      = nd[ 2 ].padStart(4, "0") + "-" + nd[ 0 ].padStart(2, "0") + "-" + nd[ 1 ].padStart(2, "0");
            return d;
          }
        }

        if(d.indexOf("-") > 0) {
          if(d.indexOf("-") < 3) {
            let nd = d.split("-");
            d      = nd[ 2 ].padStart(4, "0") + "-" + nd[ 0 ].padStart(2, "0") + "-" + nd[ 1 ].padStart(2, "0");
            return d;
          }
        }
      }

      return d;
    },
    selectAll: function(event) {
      // Workaround for Safari bug
      // https://stackoverflow.com/q/1269722/1850609
      setTimeout(function() {
        event.target.select()
      }, 0)
    }
  }
}
</script>
<style lang="scss">

/*
  seems the time control would scroll those choices what seems to be randomly
  so we need to disable that
*/
.disable-transition {
  transition: ease-in-out 0 !important;
  // -webkit-transition: none !important;
  // -moz-transition: none !important;
  // -o-transition: none !important;
  // transition: none !important;
  // transition-timing-function: none !important;
}

.form-control:focus {
  border: 2px solid #257517; /* green; */
  /*border-radius:.5em;*/
  border-radius: 4px 15px 4px 4px;
  background: #eee;
  -webkit-box-shadow: -7px 2px 11px -4px rgba(0, 0, 0, 0.5);
  box-shadow: -7px 2px 11px -4px rgba(0, 0, 0, 0.5);
}

.sf-input-selection-list {
  z-index: 200;
  position: absolute;
  top: 35px;
  left: 0;
  width: 85%;
  min-height: 100%;
  overflow-x: auto;
  overflow-y: auto;
  margin-top: 0.5rem;
  //background: white;
  //background: pink;
  background: #ddd;
  border: 3px solid rgba(0, 0, 0, 0.08);
  border-radius: .5em;
  padding: .5em;
  //overflow-y: scroll;
  //-webkit-overflow-scrolling: touch;
}

.sf-input-selection-list-item {
  margin: 0;
  list-style-type: none;
  padding: 0.5rem;
  font-size: 14px;

  display: block;

  text-align: left;
  cursor: pointer;

}

.sf-input-selection-list-item:hover {
  opacity: 0.5;
}

.sf-input-selection-list-item-selected {
  //opacity: 0.5;
  background: rgba(0, 0, 0, 0.1);
}


/* .sf-input {

  .help-class {
    background: blue;
    color:white;
    border-radius:50%;
    padding:.25em;
    position:relative;
    float:right;
    top:8px;
    z-index:9999;
  }



} */


</style>
