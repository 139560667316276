<template>
  <div class="file-list" >
    <h3><span v-if="(enableUpload || enableMultipleFileUpload) && !readonly" class="file-list-upload" @click="showUpload()"><i class="fa fa-cloud-upload"  v-b-popover.hover.right="uploadHelpText"></i></span>{{ title }}<span class="file-list-size">(Bytes Used: {{ LIB.formatFileSize(totalSize) }})</span></h3>

    <div v-if="isShowUpload" >
      <file-upload
        :title="uploadTitle"
        :uploadFolder="folder"
        :allowMultipleFiles="enableMultipleFileUpload"
        :filePrefix="filePrefix"
        @done="done()"
      />
    </div>
    <div style="overflow:auto;" >
      <b-table outlined striped hover head-variant="dark" @row-clicked="openLinkInNewWindow( $event )" :fields="fileColumns" :items="files">
        <template v-slot:cell(actions)="row">
          <i v-if="enableDelete && !readonly" style="width:2em;" @click.stop="removeFile(row.item.name, enableExtraConfirm )" class="fa fa-trash show-hand"></i>
        </template>
        <template v-slot:cell(icon)="row">
          <div v-if="row.item.contentSettings.contentType.indexOf('image')>-1">
            <img :src="row.item.uri" :height='iconSize'>
          </div>
          <div v-else-if="row.item.contentSettings.contentType.indexOf('zip')>-1">
            <img :src="'/img/zip.png'" :height='iconSize'>
          </div>
          <div v-else-if="row.item.contentSettings.contentType.indexOf('.document')>-1">
            <img :src="'/img/word.png'" :height='iconSize'>
          </div>
          <div v-else-if="row.item.contentSettings.contentType.indexOf('.sheet')>-1">
            <img :src="'/img/excel.png'" :height='iconSize'>
          </div>
          <div v-else-if="row.item.contentSettings.contentType.indexOf('.presentation')>-1">
            <img :src="'/img/powerpoint.png'" :height='iconSize'>
          </div>
          <div v-else-if="row.item.contentSettings.contentType.indexOf('pdf')>-1">
            <img :src="'/img/pdf.png'" :height='iconSize'>
          </div>
          <div v-else-if="row.item.contentSettings.contentType.indexOf('video')>-1">
            <img :src="'/img/video.png'" :height='iconSize'>
          </div>
          <div v-else-if="row.item.contentSettings.contentType.indexOf('mp4')>-1">
            <img :src="'/img/mp4.png'" :height='iconSize'>
          </div>
          <div v-else-if="row.item.contentSettings.contentType.indexOf('mp3')>-1">
            <img :src="'/img/mp3.png'" :height='iconSize'>
          </div>
          <div v-else-if="row.item.contentSettings.contentType.indexOf('text')>-1">
            <img :src="'/img/txt.png'" :height='iconSize'>
          </div>
          <div v-else-if="row.item.contentSettings.contentType.indexOf('csv')>-1">
            <img :src="'/img/csv.png'" :height='iconSize'>
          </div>
           <div v-else-if="row.item.contentSettings.contentType.indexOf('octet-stream')>-1">
            <img :src="'/img/generic.png'" :height='iconSize'>
          </div>
          <div v-else>
            <img :src="'/img/document.png'" :height='iconSize'>
          </div>
        </template> 
        <template v-slot:cell(name)="row">
          <span style="font-weight:bold;">{{ row.item.name.substring(row.item.name.lastIndexOf('/') + 1, 255) }}</span>
        </template>
        <template v-slot:cell(lastModified)="row">
          {{ moment(row.item.lastModified).format(dateFormat) }}
        </template>
        <template v-slot:cell(contentLength)="row">
          <div style="text-align:right;">
            {{ LIB.formatFileSize(row.item.contentLength) }}
          </div>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import Card from './Card.vue'
import FileService from  "/src/Services/FileService";
import FileUpload from "./FileUpload.vue";
import LIB          from "/src/Lib/rootLib.js";
import moment from "moment/moment";
export default {
  name: 'file-list',
  components: {
    Card,
    FileUpload
  },
  props: {
    enableUpload            : { type: Boolean, default: false },
    readonly                : { type: Boolean,default: false },
    enableMultipleFileUpload: { type: Boolean, default: false },
    enableOpenFile          : { type: Boolean, default: true },
    enableDelete            : { type: Boolean, default: false },
    enableExtraConfirm      : { type: Boolean, default: false },
    enableShowTotalSize     : { type: Boolean, default: true },
    extraConfirmPrompt      : { type: String, default: "Are you really sure?" },
    confirmDeletePrompt     : { type: String, default: "Remove " },
    title                   : { type: String, default: "File Listing"},
    uploadTitle             : { type: String, default: "Upload Files" },
    uploadHelpText          : { type: String, default: "Upload a File" },
    files                   : { type: Array, default: () => [] },
    folder                  : { type: String, default: "" },
    filePrefix              : { type: String, default: "" },                       // for uploading
    dateFormat              : { type: String, default: "L hh:MMa" },
    iconSize                : { type: Number, default: 32 },
    fileColumns             : { type: Array, default: () => [
                                                "actions",
                                                {key: "icon", label: "", sortable: true},
                                                {key: "name", label: "Filename", sortable: true},
                                                {key: "contentLength", label: "Size", sortable: true},
                                                {key: "lastModified", label: "Date Added", sortable: true}
                                              ]}
  },
  computed: {
    totalSize() {
      if(this.files) {
        let size = this.files.reduce((a, b) => { return parseInt(a) + parseInt(b[ "contentLength" ]) }, 0)
        return size;
      }
      return 0;
    }
  },
  data () {
    return {
      LIB,   // tie in our lib routines so we can use them in the template above.
      moment,
      isShowUpload: false
    }
  },
  mounted() {
  },
  methods: {
    done() {
      this.isShowUpload = false;
      this.getFiles();
    },
    showUpload() {
      if( this.isShowUpload ) {
        this.isShowUpload = false;
      } else {
        this.isShowUpload = true;
      }
    },
    getFiles() {
      this.$emit("getFiles");
    },
    async openLinkInNewWindow(file) {
      let uri;
      if(typeof file === 'object') {
        if(file.hasOwnProperty("uri")) {
          uri = file.uri;
        } else if(file.hasOwnProperty("url")) {
          uri = file.url;
        }
      } else {
        uri = file;     // we can pass n a url too.
      }
      if(!uri) {   // didn't find one in the passed object.
        return;
      }
      let win = window.open(uri, "_blank");
      win.focus();
    },
    async removeFile(filename, extraConfirm = false) {
      if(!this.enableDelete) {
        return;
      }
      let n = filename.substring(filename.lastIndexOf('/') + 1, 255);
      if(!await this.$root.confirm(`${this.confirmDeletePrompt} ${n}?`)) {
        return;
      }
      if(extraConfirm) {
        if(!await this.$root.confirm(this.extraConfirmPrompt)) {
          return;
        }
      }
      try {
        await FileService.deleteFile(null, filename);
        this.$root.successMessage("Success", "Deleted Successfully");
        this.$emit("deleted", filename);
        this.getFiles();
      } catch(error) {
        console.log(error);
        this.$root.errorMessage("Oops", error.message);
      }
    },
  }
}

</script>

<style lang="scss">

  .file-list {
    width: 100%;
    height: 100%;
  }

  .file-list-size {
    float:right;
    padding-top:.5em;
    padding-right:.5em;
    font-size:.7em;
    font-style:italic;
  }

  .file-list-upload {
    cursor:pointer;
    position:relative;
    line-height:60px;
    font-size:22px;
    top:-2px;
    color: #bf5e38;
    padding-right:.5em;
  }

</style>
