<template>
  <div class="col-xl-3 col-md-6 show-hand" @click="cardClicked" >
    <stats-card class="stats-card-shadow" :style="cardStyle" style="border-radius:.75em;">
      <div slot="header" class="icon-info" >
        <i :class="iconImage" style="color:white;" :style="iconStyle"></i>
      </div>
      <div slot="content">
        <p class="card-category" style="color:black" >{{ title }}</p>
        <h4 class="card-title" style="color:black" >{{subTitle}}</h4>
        <h5 v-if="detail1" class="card-details" style="color:black" >{{detail1}}</h5>
        <h5 v-if="detail2" class="card-details" style="color:black" >{{detail2}}</h5>
      </div>
      <div slot="footer" style="color:black" >
        <i :class="footerIcon"></i>
        {{footer}}
      </div>
    </stats-card>
  </div>
</template>
<script>
  // import Card from './Card.vue'
  import StatsCard from '/src/components/UIComponents/Cards/StatsCard.vue'
  export default {
    name: 'simple-stats-card',
    components: {
      StatsCard
    },
    props: {
      title: { type: String, default: "" },
      subTitle: { type: String, default: "" },
      detail1: { type: String, default: "" },
      detail2: { type: String, default: "" },
      footer: {type: String, default: "" },
      footerIcon: { type: String, default: "" },
      iconImage: { type: String, default: "fa fa-card" },
      iconStyle: { type: String, default: "" },
      cardStyle: { type: String, default: "background:#ffcc00;" }
    },
    methods: {
      cardClicked() {
        this.$emit( "click" , this.title + " " + this.subTitle );
      }
    }
  }

</script>
<style>

  .card-details {
    margin-top: 0px;
    margin-bottom: 0px;
  }

</style>
