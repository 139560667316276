<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-8 shadow p-3 mb-5 bg-white rounded">
          <assets-form
            ref="assets"
            @assetTypeChanged="assetTypeChanged($event)"
          />
        </div>
        <div class="col-md-4 shadow p-3 mb-5 bg-white rounded">
         <assets-summary
           ref="assetsummary"
           :assetType="assetType"
           :assetTypes="assetTypes"
           :assetSummary="assetSummary"
           @categoryClick="categoryClick($event)"
         />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AssetsForm from "./AssetsForm.vue";
import AssetsSummary from "./AssetsSummary.vue";
import AssetService from "/src/Services/AssetService";

export default {
  components: {
    AssetsForm,
    AssetsSummary
  },
  data() {
    return {
      searchString: "",
      assetType: "",
      assetConfig: null,
      assetTypes: null,
      assetSummary: null
    }
  },
  async mounted() {
    await this.getAssetConfig();
  },
  methods: {
    async getSummary(assetType ) {
      var t = this;
      var response;
      this.assetType = assetType;
      try {
        response = await AssetService.getSummary( assetType );
        t.assetSummary = response.data;
      } catch (err) {
        console.error("getAsset error: ", err);
      }
    },
    async getAssetConfig() {
      try {
        if(this.$root.tenant.assetTypes) {
          this.assetConfig = this.$root.tenant.assetConfig;
          this.assetTypes = this.assetConfig.assetTypes;
          return;
        }
        let response     = await AssetService.getConfig();
        this.assetConfig = response.data;
        this.assetTypes  = this.assetConfig.assetTypes;

        if( this.$root.tenant.assetType ) {
          this.assetType = this.$root.tenant.assetType;
          if( this.assetType ) {
            this.getSummary(this.assetType);
          }
        }

        // this.$root.tenant.assetTypes = this.assetTypes;
        this.$root.tenant.assetConfig = this.assetConfig
      } catch(error) {
        console.log(error);
      }
    },
    categoryClick( category ) {
      let t = this;
      t.$refs.assets.searchAssets( category  ,  this.$root.tenant.assetType , true );
    },
    async assetTypeChanged(assetType,assetTypeTitle) {
      let t = this;
      this.$root.tenant.assetType = assetType;
      await this.getSummary( assetType );
    }
  }
};
</script>

<style>
</style>
