///-------------------------------------------------------------------------------------------------
// summary:	Kiosk service class
///-------------------------------------------------------------------------------------------------

import Api from '@/Services/Api';


export default {

  getKiosksStatus() {
    const response = Api().get('/api/1/kiosks/status');
    return response;
  },

  setActivationCode(data) {
    const response = Api().post('/api/1/deviceSetAuthCode', data );
    return response;
  },

  getWorkstationConfigs() {
    const response = Api().get('/api/1/workstationConfigs');
    return response;
  },

  getWorkstationConfigById( id ) {
    const response = Api().get(`/api/1/workstationConfigById/${id}` );
    return response;
  },

  addWorkstationConfig( data  ) {
    let id = data._id;
    const response = Api().post(`/api/1/workstationConfig` ,data );
    return response;
  },

  updateWorkstationConfig( data  ) {
    const response = Api().patch(`/api/1/workstationConfig` ,data );
    return response;
  },

  deleteWorkstationConfig( id ) {
    const response = Api().delete(`/api/1/workstationConfig/${id}` );
    return response;
  },



}

