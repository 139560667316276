<template>
   <chart-card
    :chart-data         = "chartData"
    :chart-options      = "options"
    :responsive-options = "responsiveOptions"
    :chartType          = "chartType"
      @initialized      = "init"
      class             = "shadow"
      style             = "border-radius: 1em; margin: 0; padding: 0"
  >
    <template slot="header" v-if="!isPlain">
      <h4 class="card-title">
        {{title}}<span v-if="isShowTotal" class="float-right" >{{promptTotal}} {{ chartTotal }}</span>
      </h4>
      <p class="card-category">{{subTitle}}</p>
    </template>
    <template slot="footer" v-if="!isPlain">
      <div class="legend" style="display: inline-block">
        <template v-for="(tag, tagIndex) in seriesLabels">
          <span style="display: inline-block; padding-right: 1em" :key="tagIndex" >
            <i class="fa fa-circle" :style="['color:#12d9e3;','color:#d63820','color:#e69720','color:#7640ff','color:#7ad948',][tagIndex]"></i>
            {{ tag }}
          </span>
        </template>
      </div>
      <hr>
      <div class="stats">
        <i class="fa fa-history"></i>{{ Moment(lastUpdate).fromNow() }}
      </div>
    </template>
  </chart-card>
</template>

<script>

import ChartCard from "/src/components/UIComponents/Cards/ChartCard.vue";
import currency from "currency.js";
import Moment from "moment-timezone";
import iLIB from "/src/Lib/ioihanLib.js";

export default {
  components: {
    ChartCard
  },
  props: {
    chartType      : { type: String,default: 'Line' },        // Line | Pie | Bar},
    stackBars      : { type: Boolean,default: false },
    title          : { type: String, default: "" },
    subTitle       : { type: String, default: "" },
    labelType      : { type: String, default: "month" },
    labels         : { type: Array, default: () => null },
    series         : { type: Array, default: () => [] },
    seriesLabels   : { type: Array, default: () => [] },
    height         : { type: String, default: "300px" },
    currencyFormat : { type: Object, default: () => null },
    promptTotal    : { type: String, default: "Total: "},
    isShowTotal    : { type: Boolean, default: false },
    isShowArea     : { type: Boolean, default: false },
    isShowGrid     : { type: Boolean, default: false },
    isFullWidth    : { type: Boolean, default: false },
    isPlain        : { type: Boolean, default: false },
    chartMultiplier: { type: Number, default: 1 }
  },
  data() {
    return {
      Moment,
      currency,
      chart: null,
      lastUpdate: new Date(),
      chartTotal: 0,
      
      _labels   : [],
      chartData : {
        labels      : this.labels,
        series      : this.series,
        seriesLabels: this.seriesLabels
      },
      options: {
        low     : 0,
        high    : 1000,
        showArea: this.isShowArea,
        height  : this.height, //"300px",
        axisX   : {
          showGrid: this.isShowGrid,
        },
        stackBars: this.stackBars,
        lineSmooth  : true,
        showLine    : true,
        showPoint   : true,
        fullWidth   : this.isFullWidth,
        chartPadding: {
          right: 20,
        },
      },
      responsiveOptions: [
          [
            "screen and (max-width: 640px)",
            {
              axisX: {
                labelInterpolationFnc(value) {
                  return value[0];
                },
              },
            },
          ],
        ],
    }
  },
  mounted() {
    this.initLabels();
    this.initData();
  },
  methods: {
    init(chart){
      this.chart  = chart;
      this.$emit( "initialized", this.chart );
    },
    initLabels() {
      if( !this.labels) {
        if( this.labelType == "month" ) {
          let numDays = (this.series.length>0) ? this.series[0].length : 1; //iLIB.daysInMonth();
          this.chartData.labels = Array.from({length: numDays}, (v, k) => (k + 1).toString())
        } 
      } 
    },
    hover(event) {
      console.log( "Chart Hover" , event);
      this.$emit( "hover", event );
    },
    initData() {
      let maxTotal = 0;
      let total    = 0;
      for( var i=0; i<this.series.length; i++ ) {
        for( var j=0; j<this.series[i].length; j++ ) {
          // total = currency(total).add(this.series[i][j]);
          total += this.series[i][j];
          if( this.series[i][j] > maxTotal ) {
            maxTotal = this.series[i][j];
          }
        }
      }

      this.chartTotal   = currency(total, this.currencyFormat).format();

      if( this.options.stackBars ) {              // if we're stacking, need to adjust the "high" limit
        for( i=0; i<this.series[0].length; i++ ) {
          let total = 0;
          for( j=0; j<this.series.length; j++ ) {
            total += this.series[j][i];
          }
          if( total > maxTotal ){
            maxTotal = total;
          }
        }
      } 

      this.options.high = maxTotal*this.chartMultiplier;

      if( this.chart ) {
        this.chart.update();
      }
    },
    cardClicked() {
      this.$emit( "click" , this.id );
    }
  }
}


</script>

<style lang="scss" scoped>
</style>
