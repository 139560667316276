<template>
  <!-- <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3 unit-card" :style="dynamicUnitCardStyle()" > -->
  <div class="card unit-card shadow mb-3 mr-3" :class="(enableSelect) ? 'show-hand' : ''" :style="dynamicUnitCardStyle()" @click="cardSelect()">
    
    <h4 v-if="unit.title" class="unit-card-title" >{{unit.title}} :: {{model.text}}</h4>
    <h4 v-else class="unit-card-title" >{{model.text}}</h4>
   
   <!-- <div class="unit-menu more-light"><img height=48 src="../../../assets/svg/more.svg"></div> -->
    <div v-if="enableControls" class="unit-menu more-light" :id="'actionRequestElement' + index.toString()" @click.prevent.stop="showContextMenu($event, unit)">
      <context-menu 
      :elementId="'unitOptionsMenu' + (index).toString()"
      :options="buildActionOptions( unit.booking )"
      :ref="refId"
      @option-clicked="optionClicked"
    />
    </div>

    

    <div><i class="fa fa-action"></i></div>
    <div v-if="enableUnitStatus">
      <div v-if="unit.booking.underContract" class="unit-card-contract-pending">Contract Pending</div>
      <div v-if="unit.booking.underRenovation" class="unit-card-renovation">Renovation</div>
    </div>
    <div v-if="model.prices">
      <div  class="unit-card-price">{{parseFloat(model.prices[0].price).toLocaleString()}}</div>
      <div  class="unit-card-monthly"><span>Monthly: </span>{{parseFloat(model.prices[0].monthlyMaintenance).toLocaleString()}}</div>
      <div v-if="enableShowBreakdown">
        <div  class="unit-card-3year"><span>3-Year: </span>{{(parseFloat(model.prices[0].monthlyMaintenance)*36 + parseFloat(model.prices[0].price)).toLocaleString() }}</div>
        <div  class="unit-card-6year"><span>6-Year: </span>{{(parseFloat(model.prices[0].monthlyMaintenance)*72 + parseFloat(model.prices[0].price)).toLocaleString() }}</div>
        <div  class="unit-card-10year"><span>10-Year: </span>{{(parseFloat(model.prices[0].monthlyMaintenance)*120 + parseFloat(model.prices[0].price)).toLocaleString() }}</div>
      </div>
    </div>
  </div>
</template>
<script>

  import 'vue-simple-context-menu/dist/vue-simple-context-menu.css'
  import ContextMenu from 'vue-simple-context-menu'

  export default {
    name: 'unit-card',
    components: {
        ContextMenu
    },
    props: {
      title              : { type: String, default: "" },
      unit               : { type: Object, default: () => {}},
      model              : { type: Object, default: () => {}},
      enableSelect       : { type: Boolean, default: false },
      enableControls     : { type: Boolean, default: false },
      controlsEventName  : { type: String, default: null },   // when controls are enabled, this allows an override to single event if desired
      enableUnitStatus   : { type: Boolean, default: true },
      enableShowBreakdown: { type: Boolean, default: false},
      index              : { type: Number, default: "" },
      unitOptions        : { type: Array, default: null }
      
    },
    data() {
      return {
        refId        : "unitContextmenu" + this.index.toString(),
        actionOptions: []
      }
    },
    computed: {
    },
    methods: {
      cardSelect() {
        if( this.enableSelect ) {
          console.log( "selected" );
          this.$emit( "click" , this.model , this.unit );
        }
      },
       async optionClicked( event ) {
        console.log( "unitcard - action clicked" , event);
         let data = {
            action: this.option,
            model : this.model,
            unit  : this.unit
          };
        if( this.controlsEventName ) {
          this.$emit( this.controlsEventName , data );
        } else {
          console.log( "no single event, emitting option-specific" , event.option.slug );
          this.$emit( event.option.slug , data  );
        }
      },
      buildActionOptions( booking ) {

        if( this.unitOptions ) {
          return this.unitOptions;
        }

        let options =  [
          { name: "Send Information" , slug: "send-information" } ,
          { name: "Schedule Tour" , slug: "schedule-tour" },
          { type: 'divider'},
          { name: "Bind Contract (Soft Close)" , slug: "bind-contract" } ,
          { name: "Sell Unit (Close)" , slug: "sell-unit" } ,
          { type: 'divider'}
        ];

        if( booking.underRenovation && this.$root.isAdmin()) {
          options.push({ name: "Renovation Complete" , slug: "renovation-remove" } );
        } else {
          options.push({ name: "Begin Renovation" , slug: "renovation-set" } );
        }

        if( booking.underContract && this.$root.isAdmin()) {
          options.push({ name: "Remove Under-Contract" , slug: "contract-remove" } );
        } else {
          options.push({ name: "Set Under-Contract " , slug: "contract-set" } );
        }

        return options;
      },
      async showContextMenu( event, u ) {
        
        Object.defineProperty(event, 'pageX', {
            value: event.offsetX, //event.pageX - (leftarea.clientWidth+15) , // - 235,
            writable: true
        });
        Object.defineProperty(event, 'pageY', {
            value: event.offsetY, // event.pageY -192,
            writable: true
        });

        this.$refs[this.refId].showMenu(event, u)
      },
      dynamicUnitCardStyle() {
        // let coverPhoto = "housingModels/" + this.unit.roomModel + "cover-photo.jpeg";
        let c = `background-size:cover;background-repeat:no-repeat;background-image: url('${this.model.coverPhoto}');`;
        return c;
      }
    }

  }
</script>
<style lang="scss" >
@import url("https://fonts.googleapis.com/css?family=Muli:400,700");
  .vue-simple-context-menu {
    width: 250px;
    border-radius:1em;
    // background:red;
    &--active {
      // background:orange;
    }
  
    &__item {
      // background:green;
      margin: 0 .5em 0 .5em;
      &:hover {
        border-radius:.75em;
        background: rgba(32,32,128,.5);
      }
    }
  
    &__divider {}
  }


  .unit-card {
    border: 3px solid white;
    border-radius:2em;
    color:white;
    background:rgba(192,0,0,.3);
    height: 275px;
    width: 275px;

    .unit-card-title {
      width:96%;
      font-size:1em;
      font-weight:600;
      text-align:center;
      border-radius:1em;
      background:rgba(0,64,0,.5);
      padding:.5em 1em .5em .5em;
      margin: 10px 5px 5px 5px;
      white-space:nowrap;
      overflow:hidden;
      text-overflow:ellipsis;
    }

    .unit-card-price {
      position:absolute;
      bottom:0;
      left:0;
      margin:0 0 1em 1em;
      font-size:1.25em;
      padding: 0 .5em 0 .5em;
      border-radius:1em;
      background: rgba(0,64,0,.7);
    }

    .unit-card-monthly{
      position:absolute;
      bottom:0;
      right:0;
      margin:0 1em 1em 0;
      font-size:1.25em;
      padding: 0 .5em 0 .5em;
      border-radius:1em;
      background: rgba(0,64,0,.7);
      span {
        font-size:.6em;
        font-weight:100;
      }
    }

    .unit-card-3year{
      position:absolute;
      bottom:0;
      left:0;
      width:60%;
      text-align:center;
      margin:0 1em 7.75em 1em;
      font-size:.9em;
      padding: 0 .5em 0 .5em;
      border-radius:1em;
      background: rgba(0,64,0,.8);
      span {
        font-size:.6em;
        font-weight:100;
      }
    }

    .unit-card-6year{
      position:absolute;
      bottom:0;
      left:0;
      width:60%;
      text-align:center;
      margin:0 1em 6em 1em;
      font-size:.9em;
      padding: 0 .5em 0 .5em;
      border-radius:1em;
      background: rgba(0,64,0,.8);
      span {
        font-size:.6em;
        font-weight:100;
      }
    }

    .unit-card-10year{
      position:absolute;
      bottom:0;
      left:0;
      width:60%;
      text-align:center;
      margin:0 1em 4.25em 1em;
      font-size:.9em;
      padding: 0 .5em 0 .5em;
      border-radius:1em;
      background: rgba(0,64,0,.8);
      span {
        font-size:.6em;
        font-weight:100;
      }
    }

    .unit-card-contract-pending {
      position:absolute;
      bottom:0;
      left:0;
      margin: 0 0 3.5em 1em;
      font-size:1em;
      padding: 0 .75em 0 .75em;
      border-radius:1em;
      color:white;
      background: rgba(255,0,0,.6);
    }

    .unit-card-renovation{
      position:absolute;
      top:0;
      left:0;
      margin:3.5em 0 0 1em;
      // font-size:1em;
      // padding: 0 .75em 0 .75em;
      // border-radius:1em;
      // color:white;
      // background: rgba(255,128,0,.7);

      z-index: 1;
      font-family: Arial,sans-serif;
      -webkit-transform: rotate(-30deg); /* Safari */
      -moz-transform: rotate(-30deg); /* Firefox */
      -ms-transform: rotate(-30deg); /* IE */
      -o-transform: rotate(-30deg); /* Opera */
      transform: rotate(-30deg);
      font-size: 2em;
      color: #c00;
      background: #fff;
      border: solid 2px #c00;
      padding: 0 .5em 0 .5em;
      border-radius: 1em;
      zoom: 1;
      filter: alpha(opacity=20);
      opacity: 0.4;
      -webkit-text-shadow: 0 0 2px #c00;
      text-shadow: 0 0 2px #c00;
      box-shadow: inset 0px 0px 0px 10px #c00;

    }
  }

  .unit-menu {
    position:absolute;
    right:0;
    margin: 4em 1em 0 0;
    height: 32px;
    width: 32px;
    border-radius:50%;
    background: rgba(255,255,255,.1);
    background-size:100%;
  }

  .more-light {
    cursor:pointer;
    background-image: url('../../../assets/svg/more.svg');

    &:hover {
      // transform: rotate(90deg);

      background-image: url('../../../assets/svg/more-dark.svg');
      // background:rgba(255,255,255,.2);
      // -webkit-mask-image: url('../../../assets/svg/more.svg');
      // mask-image: url('../../../assets/svg/more.svg');
      // background:red;

      // background:rgba(128,128,128,.8);
      // background-image: url('../../../assets/svg/more-dark.svg');
    }

  }




</style>
