<template>
  <div class="item" >
    <i v-if="$root.isAdmin()" class="fa fa-trash item-icon" v-b-popover.hover="'Delete this Change Order'" @click.stop="deleteItem(keyIndex)"/>
    <div class="item-number show-hand"  @click="editItem()" v-b-popover.hover.bottom="'Edit this change order'">{{changeOrder.orderNumber}}  <span v-if="changeOrder.hours!=0" class="item-hours" > Hours: ({{changeOrder.hours}}) </span><span v-if="changeOrder.total!=0" class="item-price"> Total: ({{changeOrder.total | comma2d }}) </span></div>
    <div v-if="changeOrder.step && changeOrder.step.id" class="item-step show-hand" v-b-popover.hover="editHelpPrompt" @click="editTask()" >{{stepPrompt}} {{changeOrder.step.title}}</div>
    <div class="item-title">{{changeOrder.title}}</div>
    <hr>
    <div v-if="showDetails" class="item-description">{{changeOrder.description}}</div>
    <div>Order Date: <span class="item-bold" >{{changeOrder.orderDate | toLongDate }}</span> </div>
    <div>Approved Date: <span class="item-bold" >{{changeOrder.approvedDate | toLongDate }}</span> </div>
    <div>Last Update: <span class="item-bold" v-b-popover.hover.top="moment(changeOrder.updatedAt).format('LLLL')">{{changeOrder.updatedAt | fromNow }}</span></div>
  </div>
</template> 

<script>

  import Moment from "moment-timezone";

  export default {
    props     : {
      changeOrder   : {type: Object, default: () => {}},
      showDetails   : {type: Boolean, default: true},
      keyIndex      : {type: Number, default: 0 },
      stepPrompt    : {type: String, default: "For Step"},
      enableEditTask: {type: Boolean, default: false}
    },
    components: {
    },
    filters: {
    },
    computed: {
      editHelpPrompt() {
        if( ! this.enableEditTask ) {
          return "";
        }
        return "Edit this task";
      }

    },
    methods   : {
      moment(d) {
        return Moment(d);
      },
      editItem( changeOrder = this.changeOrder ) {
        this.$emit( "edit" , changeOrder );
      },
      editTask() {
        if( !this.enableEditTask) {
          return;
        }
        this.$emit( "editTask" , this.changeOrder.step.id );
      },
      deleteItem( index ) {
        this.$emit( "delete" , index );
      }
    }
  };
</script>
<style lang="scss" scoped>


  .item {
    display:inline-block;
    width:500px;
    padding:1em 1em 1em 4em;
    background:rgba(33,55,33,.1);
    border-radius:1em;
    // margin-top:.5em;
    margin:1em;
  }

  .item-number {
    cursor: pointer;
    text-align:left;
    font-size:1.5em;
    font-weight:1000;
    margin-left:-2em;
  }

  .item-title {
    cursor: pointer;
    text-align:left;
    font-size: 1.5em;
    font-weight: bold;
  }

  .item-step {
    font-size:1em;
    font-weight:200;

  }

  .item-price {
    font-weight:200;
    font-size:.9em;
    text-decoration:italic;
  }

  .item-hours {
    font-weight:200;
    font-size:.9em;
    text-decoration:italic;
  }


  .item-details {
    margin-top: 2px;
    margin-right:10px;
    float:right;
  }

  .item-icon {
    font-size:1.5em;
    float:right;
    margin-top:2px;
    margin-right:10px;
    cursor:pointer;
  }

  .item-description {
    white-space:pre-line;
    font-size:.9em;
    min-height:60px;
  }

  .item-bold {
    font-weight:bold;
  }

</style>
