<template>
  <div class="contact-us full-screen">
    <center>
      <img style="margin-top:3em;"src="/static/img/newcare-logo-350.png"/>
    </center>
    <nav class="navbar navbar-ct-default" role="navigation">
      <div class="container">
        <div class="navbar-header">
          <router-link :to="{path:'/'}" ><h3>iCare Administration</h3></router-link>
        </div>
      </div>
    </nav>
    <div class="wrapper wrapper-full-page section content">
      <div class="">
        <div class="container">
          <div class="row">
            <div class="col-md-8 col-md-offset-2">
              <h1>404 Page Not Found</h1>
              <h2 class="title text-danger">Yikes! Seems that page is on vacation</h2>
              <hr>
              <h4>What Now?</h4>
              <p style="margin-left:1em;">
                If you typed the address of this page manually or used a saved book-mark, please check 
                the link carefully.  IF you were using the app and this happened, please let us know
                at <a href="mailto:support@newcare.international">support@newcare.international</a>
              </p>
              <b-button class="btn btn-sm btn-fill" style="float:right;border:0;color:white;font-size:1em;padding-left:2em;padding-right:2em;border-radius:2em;" variant="success" @click="$router.push('/login')">
                Take Me Back To Login
              </b-button>
            </div>
            <!--<div>
            <h2>Other Options</h2>
            <ul>
              <li><router-link :to="{path: '/'}">Home</router-link></li>
              <li><router-link :to="{path: '/login'}">Log In</router-link></li>
              <li><router-link :to="{path: '/register'}">Register</router-link></li>
            </ul>
            </div>-->
          </div>
        </div>
      </div>
    </div>
    <footer class="footer">
      <div class="container">
        <div class="copyright pull-right">
          &copy; Copyright 2018-{{ new Date().getFullYear() }}, Newcare International, LLC (an IOIHAN Company)
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {};
</script>
