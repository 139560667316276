<template>
    <div>

      <div>
        <b-button v-if="!isChanged" class="btn btn-sm btn-fill btn-success" style="float:left;margin-right:1em;" @click="done()">Done</b-button>
        <b-button v-if="isChanged" class="btn btn-sm btn-fill btn-success" style="float:left;margin-right:1em;" @click="save()">Save</b-button>
        <b-button v-if="isChanged" class="btn btn-sm btn-fill btn-warning" style="float:left;margin-right:1em;" @click="cancel()">Cancel</b-button>
        <h4 slot="header" class="card-title ">Integrations<i class="fa fa-plus-square show-hand float-right show-hand" v-b-popover.hover.top="'Add new integration'" @click="addNew()"></i></h4>
      </div>
      <hr>

      <div v-if="integrations && !integration">
        <b-table striped hover head-variant="dark" stacked="md" sort-by="title" :items="integrations" :fields="integrationFields" @row-clicked="editIntegration($event._id)">
          <template #cell(actions)="data">
            <div>
            <i class="fa fa-trash-o show-hand" @click.stop="deleteIntegration(data.item)" v-b-popover.hover.right="'Remove integration'" ></i>
            <i class="fa fa-copy ml-2" @click.stop="cloneIntegration(data.item)" v-b-popover.hover.right="'Clone integration'"></i>
            <span style="padding-left:1em;">
              <i v-if="data.item.active" class="fa fa-check"  style="color:green;"></i>
              <i v-else class="fa fa-times" style="color:red;"></i>
              <i v-if="moment(data.item.expireDate) < moment()" class="fa fa-info-circle"  style="color:orange;"></i>
            </span>
            </div>
          </template>
        </b-table>
      </div>

      <div v-if="integration" class="ml-5 mr-5">
        <h3>{{ integration.title }}</h3>
        <b-tabs  lazy class="tab-hover" content-class="mt-2 ml-3 mr-3" active-nav-item-class="selected-tab" >
          <b-tab title="General" >
            <b-row style="padding:1em;">

              <b-col class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                <b-form @input="dataChanged()" >
                  <div style="margin-left:25%;padding:.5em 1em .5em 0;">
                    <b-checkbox style="" v-model="integration.active">Active</b-checkbox>
                  </div>
                  <sf-input v-model="integration.title" :labelCols="3" cstyle="" label="Title"  type="text" helpText="This field is PUBLIC" />
                  <sf-input v-model="integration.TZ" :labelCols="3" cstyle="" label="Timezone" type="list" :options="timezones" />
                  <sf-input v-model="integration.activeDate" :labelCols="3" cstyle="width:15em;" :TZ="$root.TZ" boe="begin" label="Active Date"  type="tz-date" />
                  <sf-input v-model="integration.expireDate" :labelCols="3" cstyle="width:15em;" :TZ="$root.TZ" boe="end" label="Expire Date"  type="tz-date" />
                  <sf-input v-model="integration.integrationType" :labelCols="3" cstyle="width:15em;" label="Integration Type"  :options="integrationTypes" type="list" />

                  <sf-input v-model="integration.description" :labelCols="3" :rows="10" label="Notes"  type="textarea" helpText="This information is NOT set to the client" />
                  <hr />

                  <i v-if="!isEditIntegrationKey && $root.isAdmin()" class="fa fa-pencil-square-o show-hand float-right" @click.stop="isEditIntegrationKey=true;" v-b-popover.hover.right="'Edit this integration key - CAUTION!'"></i>
                  <sf-input v-model="integration.integrationKey" :disabled="!isEditIntegrationKey" :labelCols="3" cstyle="width:12em;" label="Integration ID" helpText="This is the generated ID of this integration and how internally we link to this integration.  Changing this may disassociate linked items" type="text" />
                  <i class="fa fa-key" style="cursor:pointer;float:right;position:relative;top:12px;" @click="generateApiKey()" v-b-popover.hover.right="'Generate API Key'" ></i>
                  <sf-input v-model="integration.privateSettings.apiKey" :disabled="!isEditIntegrationKey" :labelCols="3" cstyle="" label="API Key"  type="text" helpText="Used to validate API requests and retrieve this Integration Config Record. This value is NOT a secret and is provided to sites implementing this integration" />
                </b-form>
              </b-col>
              <b-col class="col-sm-12 col-md-12 col-lg-6 col-xl-6" style="padding:2em;">
                <div>
                  <h4 class="sub-header">API Related</h4>
                  <b-form @change="dataChanged()">
                    <b-checkbox style="margin-left:23%;" v-model="integration.options.hasPaymentProvider" >Has Payment Provider</b-checkbox>
                    <b-checkbox style="margin-left:23%;" v-model="integration.options.allowSignups" >Allow Signups</b-checkbox>
                  </b-form>
                </div>
                <h4 class="sub-header">Help Information</h4>
                <p>
                  The Integration ID should not be changed once your integration has been established.  It is "changeable" to help with initial development
                  or if you need to transfer an integration to a new tenant without any overhead of configuring the integration again.
                </p>
                <p>
                  Account/User registrations against an integration are linked by the Integration ID, thus, if you have multiple inegrations that you wish to share
                  login records with, assign them the same integration ID.
                </p>
                <p>

                </p>
              </b-col>
              <div style="float:right;margin-top:2em;font-size:.8em;color:lightgray;text-align:right;">
                <div>
                  Created: {{moment(integration.createAt).tz($root.user.userTZ).format("LLL")}}
                </div>
                <div>
                  Last Updated: {{moment(integration.updateAt).tz($root.user.userTZ).format("LLL")}} By: {{integration.lastUpdateBy}}
                </div>
              </div>
            </b-row>
          </b-tab>

          <b-tab title="Layout" v-if="isShowLayout()" >
            <p>Layout options are those specifically AT the client</p>
            <b-tabs  lazy class="tab-hover" content-class="mt-2 ml-3 mr-3" active-nav-item-class="selected-tab">
                <b-tab title="General">
                  <b-row>
              <b-col class="col-md-12 col-lg-6">
              <div style="padding:1em;">
                <h4 style="color:red;margin:0;">** PUBLIC INFORMATION **</h4>
                <p class="help-text">Layout information is to allow central management of any hosted components of an interface.
                  These fields are returned with the interfaceConfiguration data request.</p>
                <b-form @input="dataChanged()" style="padding-right:1em;" title="form title">
                  <sf-input v-model="integration.layout.title"    :labelCols="3" cstyle="" label="Title"      type="text" />
                  <sf-input v-model="integration.layout.subTitle" :labelCols="3" cstyle="" label="Sub Title"  type="text" />
                  <sf-input v-model="integration.layout.pageDescription" :labelCols="3" :rows="2" cstyle="" label="Page Description"  type="textarea" />
                  <sf-input v-model="integration.layout.pageFooter" :labelCols="3" :rows="2" cstyle="" label="Page Footer"  type="textarea" />
                  <br>
                  <p class="help-text" style="margin-left:25%;">Images can be uploaded or linked to external media. Remember, external links may change.</p>
                  <sf-input v-model="integration.layout.color" :labelCols="3" cstyle="" label="Primary Color"  type="text" />
                  <sf-input v-model="integration.layout.background" :labelCols="3" cstyle="" label="Background Color"  type="text" />
                  <br>
                  <sf-input v-model="integration.layout.logo" :labelCols="3" cstyle="" label="Logo"  type="text" />
                  <sf-input v-model="integration.layout.logoTargetUrl" :labelCols="3" cstyle="" label="Target URL"  type="text" helpText="Where the user is directed when clicking the logo"/>
                  <sf-input v-model="integration.layout.thumbnail" :labelCols="3" cstyle="" label="Thumbnail"  type="text" />
                  <sf-input v-model="integration.layout.icon" :labelCols="3" cstyle="" label="Icon"  type="text" />
                  <sf-input v-model="integration.layout.url" :labelCols="3" cstyle="" label="Host URL"  type="text" />
                  <sf-input v-model="integration.layout.authorizationEndPoint" :labelCols="3" cstyle="" label="Authorization End Point"  type="text" />
                </b-form>
              </div>
              </b-col>
              <b-col class="col-md-12 col-lg-6">
                <h4 class="sub-header">Custom PAGE CSS Styles</h4>
                <p class="help-text">This is standard CSS to be applied to the target display page so make it valid CSS.</p>
                <b-form @input="dataChanged()" style="min-height:500px;height:500px;overflow:scroll;" >
                  <!-- <sf-input v-model="integration.layout.customCss" :labelCols="0" :rows="20" cstyle="" label=""  type="textarea" /> -->
                  <code-editor  v-model="integration.layout.customCss" ></code-editor>
                </b-form>
              </b-col>
            </b-row>
                </b-tab>
                <b-tab title="Portal Features" v-if="integration.integrationType=='portal'">
                  <b-form-checkbox-group  style="padding-right:1em;" title="Features" v-model="integration.layout.features" @input="dataChanged()">
                    <b-form-checkbox v-for="(f,findex) in portalFeatures" class="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-4" :id="'pf'+f.value" :value="f.value" :key="findex">{{ f.text}}</b-form-checkbox>
                  </b-form-checkbox-group>
                </b-tab>
            </b-tabs>

          </b-tab>

          <b-tab title="Portal Options" v-if="integration.integrationType=='portal'" @click="getNewsSites()" >
            <b-row>
              <b-col class="col-md-12 col-lg-6">
                <div style="padding:1em;">
                  <h4 class="sub-header">Portal Actions</h4>
                  <p>This section governs what is built into the primary package that is sent to the portal app.</p>
                  <b-form @input="dataChanged()" style="padding-right:1em;" title="form title">
                    <b-checkbox style="" v-model="integration.features.options.isEnforceAccountActive">Account Must Be Active</b-checkbox>
                    <b-checkbox style="" v-model="integration.features.options.isVerifyLastName">Verify Last Name</b-checkbox>
                  </b-form>
                </div>
              </b-col>
              <b-col class="col-md-12 col-lg-6">
                <h4 class="sub-header">Allowed Account Profiles</h4>
                <b-form-checkbox-group id="accountclasses" name="accountprofiles" v-model="integration.features.accountClassId" @input="dataChanged()">
                  <b-form-checkbox v-for="(a,index) in accountClasses" class="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-4" :id="'ac'+a.accountClassId" :value="a.accountClassId" :key="index">{{a.title}}</b-form-checkbox>
                </b-form-checkbox-group>
              </b-col>
              <b-col class="col-md-12 col-lg-6" v-if="newsSites && newsSites.length>0">
                <h4 class="sub-header">News Locations</h4>
                <b-form-checkbox-group id="newsSites" name="newsSites" v-model="integration.features.newsLocations" @input="dataChanged()">
                  <b-form-checkbox v-for="(s,sIndex) in newsSites" class="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-4" :id="'ns'+s._id" :value="s.siteId" :key="sIndex">{{s.title}}</b-form-checkbox>
                </b-form-checkbox-group>
                <!-- <option-management-table :tableData="integration.features.newsLocations" :keysLowercase="true" @change="dataChanged()" >
                  <div slot="header">
                    <p class="help-text">The News Locations this integration uses</p>
                  </div>
                </option-management-table> -->
              </b-col>
            </b-row>
          </b-tab>


          <b-tab title="Private Settings" >
            <b-row>
              <b-col>
                <div style="padding:1em;border-right:4px solid lightgray;">
                  <b-form @input="dataChanged()" style="max-width:540px;">

                    <h4 class="sub-header">Settings</h4>
                    <p class="help-text">These settings are used by any supporting interface and are a central location to store settings and are sent to the client when requesting integration config.</p>
                    <sf-input v-model="integration.privateSettings.accountNumber" :labelCols="3" cstyle="" label="Account Number"  type="text" />
                    <sf-input v-model="integration.privateSettings.reference" :labelCols="3" cstyle="" label="Reference"  type="text" />
                    <sf-input v-model="integration.privateSettings.clientId" :labelCols="3" cstyle="" label="Client Id"  type="text" />

                  </b-form>
                </div>
              </b-col>
              <b-col>
                <div style="padding:1em;">
                  <b-form @input="dataChanged()" style="max-width:540px;">
                    <h4 class="sub-header">Access Related</h4>
                    <!-- <i class="fa fa-key" style="cursor:pointer;float:right;position:relative;top:12px;" @click="generateApiKey()" v-b-popover.hover.right="'Generate API Key'" ></i> -->
                    <!-- <sf-input v-model="integration.payment.merchantActive" :labelCols="3" cstyle="" label="Merchant Active"  type="text" helpText="Used to validate API requests and retrieve this Integration Config Record" /> -->
                    <div style="margin-left:25%;padding:.5em 1em .5em 0;">
                      <b-checkbox style="" v-model="integration.privateSettings.option.isAllowBadgeLookup" helpText="If badgeNumber passed in query?">Allow Badge Lookup</b-checkbox>
                      <b-checkbox style="" v-model="integration.privateSettings.option.isCreateToken">Generate Token</b-checkbox>
                      <b-checkbox style="" v-model="integration.privateSettings.option.isAutoCreatePortalRegistration" helpText="If registration not found?">Auto Create Portal Regisration</b-checkbox>

                    </div>
                    <sf-input v-model="integration.privateSettings.uid" :labelCols="3" cstyle="" label="User Id"   type="text" />
                    <sf-input v-model="integration.privateSettings.pw" :labelCols="3" cstyle="" label="User Password" type="password" />
                    <sf-input v-model="integration.privateSettings.clientSecret" :labelCols="3" cstyle="" label="Client Secret"  type="text" />
                    <sf-input v-model="integration.privateSettings.clientSecretEncrypted" :labelCols="3" cstyle="" label="Client Secret Encypted"  type="text" />

                  </b-form>
                   <b-form @input="dataChanged()" style="max-width:540px;">
                    <h4 class="sub-header">Merchant Related</h4>
                    <!-- <i class="fa fa-key" style="cursor:pointer;float:right;position:relative;top:12px;" @click="generateApiKey()" v-b-popover.hover.right="'Generate API Key'" ></i> -->
                    <!-- <sf-input v-model="integration.payment.merchantActive" :labelCols="3" cstyle="" label="Merchant Active"  type="text" helpText="Used to validate API requests and retrieve this Integration Config Record" /> -->
                    <sf-input v-model="integration.privateSettings.merchantId" :labelCols="3" cstyle="" label="Merchant Id"  type="text" />
                    <sf-input v-model="integration.privateSettings.token" :labelCols="3" cstyle="" label="Token"  type="text" />
                    <sf-input v-model="integration.privateSettings.secret" :labelCols="3" cstyle="" label="Secret"  type="text" />
                  </b-form>
                </div>
              </b-col>
            </b-row>
          </b-tab>

          <b-tab v-if="integration.options.allowSignups" title="Signup Details">
            <!-- <div style="height:650px;padding-right:1em;overflow-y:auto;overflow-x:hidden;"> -->
              <b-row>
                <b-col>
                  <b-form @input="dataChanged()" >
                    <h4 class="sub-header">Account Details</h4>
                    <b-checkbox v-model="integration.options.enablePortal" style="margin-left:25%;">Set "Enable Portal" flag for new accounts</b-checkbox>
                    <sf-input v-model="integration.signupSettings.signupCode" :labelCols="3" cstyle="" label="Signup Code"  type="text" helpText="Leave blank to allow not require" />
                    <sf-input v-model="integration.signupSettings.accountPrefix" :labelCols="3" cstyle="" label="Account Prefix"  type="text" helpText="New account prefix" />
                    <sf-input v-model="integration.signupSettings.accountClassId" :labelCols="3" cstyle="" label="Account Class Id"  type="number" />
                    <sf-input v-model="integration.signupSettings.totalClassId" :labelCols="3" cstyle="" label="Total Class Id"  type="number" />
                    <sf-input v-model="integration.signupSettings.arType" :labelCols="3" cstyle="" label="AR Type"  type="text" />
                    <sf-input v-model="integration.signupSettings.glAccount" :labelCols="3" cstyle="" label="GL Account"  type="text" />
                  </b-form>
                </b-col>
                <b-col>
                  <b-tabs lazy class="tab-hover mt-4" content-class="mt-2 ml-3 mr-3" active-nav-item-class="selected-tab" >
                    <b-tab title="Welcome">
                      <b-form @input="dataChanged()">
                        <h4 class="sub-header">Welcome Email</h4>
                        <p>The welcome email is sent when a new user signs up.  This has NOTHING to do with a transaction.</p>
                        <sf-input v-model="integration.signupSettings.signupEmail.subject" :labelCols="3" cstyle="" label="Welcome Subject"  type="text" />
                        <!-- <sf-input v-model="integration.signupSettings.welcomeEmail" :labelCols="3" :rows="12" cstyle="" label="Welcome Email"  type="textarea" /> -->
                        <div style="margin-top:1em;">
                          <!-- <quill-editor toolbar="" v-model="integration.signupSettings.welcomeEmail" output="html" /> -->
                          <!-- <wysiwyg class="" v-model="integration.signupSettings.welcomeEmail" /> -->
                          <vue-editor v-model="integration.signupSettings.signupEmail.template" />
                          <!-- <tiny-mce id="mce-editor" v-model="integration.signupSettings.welcomeEmail" /> -->
                          <!-- <vue-mce v-model="integration.signupSettings.welcomeEmail" /> -->
                        </div>
                      </b-form>
                    </b-tab>
                    <b-tab title="Auto Signup">
                      <b-form @input="dataChanged()">
                        <h4 class="sub-header">Auto-Signup Email</h4>
                        <p>The auto-signup email is sent when the system auto creates an account for a donor and this usually means they just donated and we created an account.</p>
                        <sf-input v-model="integration.signupSettings.autoSignupEmail.subject" :labelCols="3" cstyle="" label="Welcome Subject"  type="text" />
                        <!-- <sf-input v-model="integration.signupSettings.welcomeEmail" :labelCols="3" :rows="12" cstyle="" label="Welcome Email"  type="textarea" /> -->
                        <div style="margin-top:1em;">
                          <!-- <quill-editor toolbar="" v-model="integration.signupSettings.welcomeEmail" output="html" /> -->
                          <!-- <wysiwyg class="" v-model="integration.signupSettings.welcomeEmail" /> -->
                          <vue-editor v-model="integration.signupSettings.autoSignupEmail.template" />
                          <!-- <tiny-mce id="mce-editor" v-model="integration.signupSettings.welcomeEmail" /> -->
                          <!-- <vue-mce v-model="integration.signupSettings.welcomeEmail" /> -->
                        </div>
                      </b-form>
                    </b-tab>
                  </b-tabs>
                </b-col>
              </b-row>
            <!-- </div> -->
          </b-tab>

          <b-tab title="Payment Provider"  v-if="integration.options.hasPaymentProvider">
            <b-form style="margin-top:2em;" @input="dataChanged()">
                <i v-if="isPaymentActive(integration.privateSettings.paymentProviderId)" class="fa fa-check" style="float:right;position:relative;top:12px;color:green;"  v-b-popover.hover.right="'Activated'" ></i>
                <sf-input v-model="integration.privateSettings.paymentProviderId" :labelCols="3" cstyle="width:24em" label="Payment Provider"  type="list" :options="paymentProviderSelectionList" />
                <sf-input v-model="integration.privateSettings.notes" :labelCols="3" :rows="8" cstyle="" label="Private Notes"  type="textarea"  />
              <!-- <b-form-checkbox id="merchantId" v-model="integration.private.paymentProvider" @change="dataChanged()">
                Enable Merchant Account
              </b-form-checkbox> -->
            </b-form>
            <!-- <template v-if="integration.payment.merchantActive">
              <template v-if="integration.payment.ccAccess.isAuthorized">
                <b-form-group label="Merchant Setup Information" label-size="lg" label-class="font-weight-bold pt-0" class="mb-0">
                  <b-tabs content-class="mt-3" active-nav-item-class="font-weight-bold text-uppercase text-normal">
                    <b-tab title="Merchant Info">

                      <b-button class="btn btn-fill btn-warning btn-sm float-right mt-1" @click="deAuthorizeMerchant()">DE-Activate</b-button>
                      <div class="col-md-10 mr-2" >
                      <b-form-group label="Merchant Processor" label-for="merchantProcessor" horizontal label-cols-sm="4" label-cols-lg="3" label-class="text-sm-right">
                        <b-form-input id="merchantProcessor" type="text" v-model="integration.payment.merchantProcessor" readonly placeholder="Merchant Processor" @input="dataChanged()"></b-form-input>
                      </b-form-group>
                      <b-form-group label="Merchant Account" label-for="merchantAccount" horizontal label-cols-sm="4" label-cols-lg="3" label-class="text-sm-right">
                        <b-form-input id="merchantAccount" type="text" v-model="integration.payment.merchant.name" readonly placeholder="Merchant Account Name" @input="dataChanged()"></b-form-input>
                      </b-form-group>
                      <b-form-group label="Serial #" label-for="serialNumber" horizontal label-cols-sm="4" label-cols-lg="3" label-class="text-sm-right">
                        <b-form-input id="serialNumber" type="text" v-model="integration.payment.merchant.serialNumber" readonly placeholder="Serial #" @input="dataChanged()"></b-form-input>
                      </b-form-group>
                      <b-form-group label="Revision" label-for="revision" horizontal label-cols-sm="4" label-cols-lg="3" label-class="text-sm-right">
                        <b-form-input id="revision" type="text" v-model="integration.payment.merchant.revision" readonly placeholder="Revision" @input="dataChanged()"></b-form-input>
                      </b-form-group>
                      </div>
                    </b-tab>
                    <b-tab title="Auth Info">
                      <b-form-group horizontal>
                        <table class="table striped">
                          <tr>
                            <td>Authorization Date</td>
                            <td>{{moment(integration.payment.ccAccess.authorizationDate).format( "dddd DD-MMM-YY hh:mm:ssa")}}</td>
                          </tr>
                          <tr>
                            <td>Interface Name</td>
                            <td>{{integration.payment.ccAccess.interfaceName}}</td>
                          </tr>
                          <tr>
                            <td>Interface Version</td>
                            <td>{{integration.payment.ccAccess.interfaceVersion}}</td>
                          </tr>
                          <tr>
                            <td>Authorization Source</td>
                            <td>{{integration.payment.ccAccess.authorizationSource}}</td>
                          </tr>
                          <tr>
                            <td>Authorization Server</td>
                            <td>{{integration.payment.ccAccess.authorizationServer}}</td>
                          </tr>
                        </table>
                      </b-form-group>
                    </b-tab>
                    <b-tab title="Accepted Cards">
                      <b-form-group horizontal>
                        <b-table striped hover responsive :items="integration.payment.merchant.cardTypes" :fields="cardTypeFields"></b-table>
                      </b-form-group>
                    </b-tab>
                  </b-tabs>
                  <hr>
                  <label class="float-right">Merchant Account:
                    <b style="color:green;">ACTIVE</b>
                  </label>
                </b-form-group>
                <br>

              </template>
              <template v-else>
                <b style="color:red;">NOT ACTIVE</b>
                <br>
                <sf-input label="Auth Token" :labelCols="2" cstyle="width:25em;" v-model="authToken"></sf-input>
                <p class="mt-3 description">Please contact support if you have questions on ths step</p>
                <b-button :disabled="(authToken.length <10)" class="btn btn-fill btn-success mt-3" @click="authorizeMerchant()">
                  <span v-show="isAuthorizing" :class="animatedSpinner"></span> Authorize This Merchant Account
                </b-button>
              </template>
            </template> -->
          </b-tab>

          <b-tab title="Settings">
            <div style="height:650px;padding-right:1em;overflow-y:auto;overflow-x:hidden;">
              <h4 style="color:red;margin:0;">** PUBLIC INFORMATION **</h4>
              <option-management-table :tableData="integration.settings" @change="dataChanged()" >
              <div slot="header">
                <p class="help-text">Key/Values allow settings to be centrally defined and used by the integration.</p>
              </div>
              </option-management-table>
            </div>
          </b-tab>

          <b-tab title="Posting">
            <b-row>
              <b-col>
                <b-form style="margin-top:2em;" @input="dataChanged()">
                  <!-- active       : { type: Boolean, default: true }, -->
                  <!-- isPayment    : { type: Boolean, default: true }, -->
                  <sf-input v-model="integration.posting.title" :labelCols="3" cstyle="" label="Title" type="text" />
                  <sf-input v-model="integration.posting.reference" :labelCols="3" cstyle="" label="Reference" type="text" helpText="Shows on customers Credit Card Reference" />
                  <sf-input v-model="integration.posting.glAccount" :labelCols="3" cstyle="" label="GL Account" type="text" />
                  <sf-input v-model="integration.posting.arType" :labelCols="3" cstyle="" label="AR Type" type="text" />

                  <sf-input v-model="integration.posting.totalClassId" :labelCols="3" cstyle="" label="Total Class ID" type="number" />
                  <sf-input v-model="integration.posting.revenueCenter" :labelCols="3" cstyle="" label="Revenue Center" type="number" />
                  <sf-input v-model="integration.posting.tenderNumber" :labelCols="3" cstyle="" label="Tender Number" type="number" />
                  <sf-input v-model="integration.posting.employeeId" :labelCols="3" cstyle="" label="Employee #" type="number" />
                  <sf-input v-model="integration.posting.employeeName" :labelCols="3" cstyle="" label="Employee Name" type="text" />
                  <hr />
                  <h5>Options</h5>
                  <div style="margin-left:25%;padding:.5em 1em .5em 0;">
                    <b-checkbox style="" v-model="integration.posting.active">Active</b-checkbox>
                    <b-checkbox style="" v-model="integration.posting.options.isAutoAddAccount">Auto Add Account</b-checkbox>
                    <b-checkbox style="" v-model="integration.posting.options.isUpdateAccountTotals">Update Account Totals</b-checkbox>
                    <b-checkbox style="" v-model="integration.posting.options.isUpdateFundTotals">Update FUND Totals</b-checkbox>
                  </div>
                </b-form>
              </b-col>
              <b-col>
                <h4 class="sub-header">Email Templates</h4>
                <b-tabs lazy class="tab-hover" style="border-left:4px solid gray;padding-left:1em;" content-class="mt-2 ml-3 mr-3" active-nav-item-class="selected-tab" >
                  <b-tab title="Receipt">
                    <b-form @input="dataChanged()">
                      <!-- <h4 style="margin:1em 0 1em 0;padding:0;">Receipt Email</h4> -->
                      <p style="font-size:.8em;color:gray;text-decoration:italic;">This email template is the primary transaction receipt email.  You may add "mail-merge"
                        fields inside this email to customize for the user.
                      </p>
                      <sf-input v-model="integration.posting.receiptEmail.subject" :labelCols="2" cstyle="" label="Subject" type="text" />
                      <div style="margin-top:1em;">
                        <vue-editor v-model="integration.posting.receiptEmail.template" />
                      </div>
                    </b-form>
                  </b-tab>
                  <b-tab title="Failed Transaction">
                    <b-form @input="dataChanged()">
                      <!-- <h4 style="margin:1em 0 1em 0;padding:0;">Failed Transaction Email</h4> -->
                      <p style="font-size:.8em;color:gray;text-decoration:italic;">This form is sent when a transaction fails.  e.g.,
                        denied by the CC processor or something of the sort.
                      </p>
                      <sf-input v-model="integration.posting.failedEmail.subject" :labelCols="2" cstyle="" label="Subject" type="text" />
                      <div style="margin-top:1em;">
                        <vue-editor v-model="integration.posting.failedEmail.template" />
                      </div>
                    </b-form>
                  </b-tab>
                </b-tabs>
              </b-col>
            </b-row>
          </b-tab>

          <b-tab title="Methods" v-if="$root.isSA()">
            <div style="height:650px;padding-right:1em;overflow-y:auto;overflow-x:hidden;">
              <h4 style="color:red;margin:0;">** PUBLIC INFORMATION **</h4>
              <option-management-table :tableData="integration.methods" @change="dataChanged()" :isMultiLine="true">
              <div slot="header">
                <p class="help-text">Defined methods that the integration is capable of calling and/or redirected too.</p>
              </div>
              </option-management-table>
            </div>
          </b-tab>

          <b-tab title="Prompts">
            <div style="height:650px;padding-right:1em;overflow-y:auto;overflow-x:hidden;">
              <h4 style="color:red;margin:0;">** PUBLIC INFORMATION **</h4>
              <option-management-table :tableData="integration.prompts" :keysLowercase="false" @change="dataChanged()" >
              <div slot="header">
                <p class="help-text">Defined prompts that the integration may use</p>
              </div>
              </option-management-table>
            </div>
          </b-tab>
        </b-tabs>
      </div>

    </div>
</template>

<script>
  import TenantService          from "/src/Services/TenantService";
  import AccountService         from "/src/Services/AccountService";
  import Moment                 from "moment-timezone";
  import Card                   from "/src/components/UIComponents/Cards/Card.vue";
  import OptionManagementTable  from "/src/components/UIComponents/Cards/OptionManagementTable.vue";
  import SfInput                from "/src/components/UIComponents/Inputs/SFormInput.vue";
  import CodeEditor             from "/src/components/UIComponents/Inputs/CodeEditor.vue";
  import LTable                 from "/src/components/UIComponents/Table.vue";
  import iLib                   from "/src/Lib/ioihanLib.js";
  import PageEditHeaderCard     from '/src/components/UIComponents/Cards/PageEditHeaderCard.vue';
  import PaymentProvidersService from '/src/Services/PaymentProvidersService.js';
  import ListCard               from '/src/components/UIComponents/Cards/ListCard.vue';

  import {VueEditor} from "vue2-editor";
//  <quill-editor toolbar="" v-model="news.description" output="html" @text-change="dataChanged()"/>
  // import 'quill/dist/quill.core.css'
  // import 'quill/dist/quill.snow.css'
  // import 'quill/dist/quill.bubble.css'
  // import { quillEditor } from 'vue-quill-editor'


  export default {
  components: {
    LTable,
    Card,
    CodeEditor,
    SfInput,
    OptionManagementTable,
    PageEditHeaderCard,
    ListCard,
    VueEditor
    // VueMce
    // quillEditor
  },
  // modules: {
  //   clipboard: {
  //     matchVisual: false
  //   },
  // },
  data() {
    return {
      saveDisabled        : true,
      isChanged           : false,
      isAdding            : false,
      isEditing           : false,
      isAuthorizing       : false,
      isEditIntegrationKey: false,
      authToken           : "",
      animatedSpinner     : "fa fa-spinner  fa-refresh-animate mr-3",
      accountClasses      : null,
      newsSites           : null,
      integrations        : [],
      integration         : null,
      paymentProviders    : [],
      integrationFields   : [
        "actions",
        { key: "title", label:"Title" , sortable: true},
        { key: "integrationType", label:"Type", sortable: true, formatter: (x) => {return this.integrationTypeName(x)}},
        { key: "updatedAt", label:"Last Update" , sortable: true, formatter: (x) => {return this.moment(x).fromNow()}}
      ],
      integrationTypes: [
        { value: "api", text: "API Integration" },
        { value: "custom", text: "Custom Integration" },
        { value: "donations-express", text: "Donations Express" },
        { value: "myschool", text: "mySchool Cafe" },
        { value: "icare-teams", text: "iCare Teams" },
        { value: "extension", text: "iCare Extension" },
        { value: "payment", text: "Payment Integration" },
        { value: "portal", text: "Portal Integration" },
        { value: "resource", text: "System Resource" },
        { value: "teams", text: "Microsoft TEAMS" },
      ],
      portalFeatures: [
        { value: "show-balance-blocks", text: "Show Balance Blocks" },
        { value: "access-log", text: "Allow Access Log" },
        { value: "news", text: "Allow News" },
        { value: "transactions", text: "Allow Transaction Listing" },
        { value: "tickets", text: "Allow Helpdesk Ticket Display" },
      ],
      timezones        : [
        // todo: get this list from the API (or even maybe from the TZ server)
        {text: "Please select a Timezone", value: null},
        {text: "America/New York (EDT/EST)", value: "America/New_York"},
        {text: "America/Chicago (CST CDT)", value: "America/Chicago"},
        {text: "America/Denver (MST)", value: "America/Denver"},
        {text: "America/Los_Angeles (PST PDT)", value: "America/Los_Angeles"},
        {text: "America/Anchorage (AKST AKDT)", value: "America/Anchorage"},
        {text: "America/Phoenix (MST)", value: "America/Phoenix"},
        {text: "America/Fort_Nelson (PST PDT MST)", value: "America/Fort_Nelson"},
        {text: "Pacific/Honolulu (HST)", value: "Pacific/Honolulu"},
        {text: "Europe/Paris (CET/CEST)", value: "Europe/Paris"}
      ]
    }
  },
  watch: {
  },
  mounted: async function() {
    await Promise.all([
      this.getAccountClasses(),
      this.getIntegrations(),
      this.getPaymentProviders()
    ]);
  },
  async beforeRouteLeave( to , from, next ) {
    if( !this.isChanged ) {
      return next();
    }
    if( await this.$root.confirm('You have unsaved changes, continue leaving without saving?', "warning")) {
      return next();
    }
    next(false)
  },
  computed: {
    paymentProviderSelectionList() {
      return this.paymentProviders.map( (x) => {
        return { value: x._id, text: x.title }
      })
    }

  },
  methods: {
    done() {
      this.isEditIntegrationKey = false;
      if( this.integration ) {
        this.integration = null;

      } else {
        this.$router.go(-1);
      }
    },
    isPaymentActive( id ) {
      let pp = this.paymentProviders.find( (x) => { return x._id == id });
       if( pp && pp.active && pp.merchantActive ) {
         return true;
       }
       return false;
    },
    isShowLayout() {
      let offList = ['payment','api']
      return offList.indexOf( this.integration.integrationType ) < 0;
    },
    async alert(e) {
      await this.$root.alert( e );
    },
    async save() {
      this.saveIntegration( this.integration );
      this.done();
    },
    async cancel() {
      if( this.isChanged ) {
        if( ! await this.$root.confirmYesNo( "You have unsaved changes, continue to leave?" )) {
          return;
        }
      }
      this.isChanged = false;
      if( this.integration ) {
        this.integration = null;
        return;
      }
      this.$router.go(-1);
    },
    integrationTypeName( id ) {
      let x = this.integrationTypes.find( (x) => { return x.value == id });
      if(x) {
        return x.text;
      }
      return "Undefined";
    },
    async editIntegration( id) {
      await this.getIntegration( id );
    },
    async cloneIntegration(data) {
      if( ! await this.$root.confirm( "Clone this integration?" )) {
        return;
      }

      try {
        let integration = Object.assign({},data); //_.cloneDeep( data );

        integration.active         = false;
        integration.title          = integration.title + " (Clone)";
        integration.integrationKey = null;

        await this.addIntegration( integration );

      } catch(e) {
        await this.$root.alert( e.message, "error" );
      }

    },
    moment(d) {
      return Moment(d);
    },
    dataChanged() {
      this.isChanged = true;
    },
    async generateApiKey() {
      let message = "Generate a new API Key?";
      if( this.integration.privateSettings.apiKey && this.integration.privateSettings.apiKey.length > 0 ) {
        message += "<br><br>Generating a new key may disable existing interfaces using the current key.";
      }
      if( ! await this.$root.confirmYesNo( message  , "warning")) {
        return;
      }
      if( this.integration.privateSettings.apiKey && this.integration.privateSettings.apiKey.length > 0 ) {
        if(!await this.$root.confirmYesNo("Are you SURE you want to generate a new API Key?<br><br>Generating a new key may disable existing interfaces using the current key.","warning")) {
          return;
        }
      }
      let key = iLib.generateUUID();
      this.integration.privateSettings.apiKey = key;
      this.dataChanged();
    },
    async addNew() {
      if( ! await this.$root.confirm("Are you sure you want to add a new integration?")) {
        return;
      }

      let integration = {
        active   : false,
        title    : "New Integration",
        createAt: Moment().tz(this.$root.user.userTZ).format("YYYY-MM-DD HH:mm:ss"),
        createBy: this.$root.user.alias
      };


      await this.addIntegration( integration );
    },
    async getPaymentProviders() {
      try {
        let response          = await PaymentProvidersService.getPaymentProviders();
        this.paymentProviders = response.data;
      } catch(e) {
        await this.$root.alert(e.message, "error");
      }
    },
    async getNewsSites() {
      try {
        let response  = await TenantService.getSites('news');
        this.newsSites = response.data;
      } catch(e) {
        await this.$root.alert(e.message, "error");
      }
    },
    async getAccountClasses() {
      let response = await AccountService.getAccountClasses();
      this.accountClasses = response.data;
    },
    async getIntegrations() {
      try {
        let response          = await TenantService.getIntegrations();
            this.integrations = response.data;
            this.isChanged    = false;
       } catch(e) {
        await this.$root.alert(e.message, "error");
      }
    },
    async getIntegration( id ) {
      try {
        let response         = await TenantService.getIntegration( id );
            this.integration = response.data;
            this.isChanged   = false;
            if( !this.integration.posting || !this.integration.posting.receiptEmail ) {    // added this and some records can't be edited without this node.
              this.integration.posting = {
                active: true,
                title: "new posting",
                options      : {
                  isAutoAddAccount     : true,
                  isUpdateAccountTotals: true,
                  isUpdateFundTotals   : true
                },
                features: [],
                receiptEmail: {
                  subject : "",
                  template: "",
                },
                failedEmail: {
                  subject : "",
                  template: "",
                },
              }
            }

      } catch(e) {
        await this.$root.alert(e.message, "error");
      }
    },
    async addIntegration( integration ) {
      try {
        let response  = await TenantService.addIntegration( integration );
        this.integration = null;

        // console.log( "Just added" , response.data );

        await this.getIntegration( response.data._id );
        this.isChanged = true;
      } catch(e) {
        await this.$root.alert(e.message, "error");
      }
    },
    async saveIntegration( data ) {
      try {
        data.updateAt = Moment().tz(this.$root.user.userTZ).format("YYYY-MM-DD HH:mm:ss")
        data.updateBy = this.$root.user.alias
        let response = await TenantService.saveIntegration( data );
        // console.log("response" , response.data);
        let index = _.findIndex( this.integrations, {_id :data._id });

        if( index >= 0 ) {
          this.integrations[index] = response.data;
          // console.log("index" , index, response.data , this.integrations);
          this.integrations.push({});
          this.integrations.pop();
        } else {
          this.integrations.push( response.data );
        }
        this.isChanged = false;
      } catch(e) {
        await this.$root.alert(e.message, "error");
      }
    },
    async deleteIntegration( data) {
      let id = data._id;

      if( !id ) {
        return;
      }

      if( ! await this.$root.confirm( `Delete<br>[${data.title}]?` )) {
        return;
      }

      if( ! await this.$root.confirm( `If there are signups or other connections, they will be orphaned!<br>Continue to delete?`, "warning" )) {
        return;
      }

      try {
        let response          = await TenantService.deleteIntegration( id );
        this.integration  = null;
        this.isChanged    = false;
        this.getIntegrations();
      } catch(e) {
        await this.$root.alert(e.message, "error");
      }
    },



  }
};
</script>

<style lang="scss" scoped>


  .help-text {
    font-size:.8em;
    color:gray;
    text-align:left;
    max-width:500px;
  }

  .sub-header {
    width:100%;
    background: lightgray;
    margin:1em 0 1em 0;
    padding: 3px;
  }

</style>
