<template>
  <div class="content">
    <div class="row">
      <div class="col-md-12 shadow p-3 mb-5 bg-white rounded">
        <users-form>
        </users-form>
      </div>
    </div>
  </div>
</template>

<script>
import UsersForm from "./UsersForm.vue";

export default {
  components: {
    UsersForm
  }
};
</script>

<style>
</style>
