import { render, staticRenderFns } from "./TaskList.vue?vue&type=template&id=2d5f5ac8&scoped=true"
import script from "./TaskList.vue?vue&type=script&lang=js"
export * from "./TaskList.vue?vue&type=script&lang=js"
import style0 from "./TaskList.vue?vue&type=style&index=0&id=2d5f5ac8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d5f5ac8",
  null
  
)

export default component.exports