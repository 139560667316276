///-------------------------------------------------------------------------------------------------
// summary:	Log service class
///-------------------------------------------------------------------------------------------------

import Api from '@/Services/Api';


export default {

  getEventIds() {
    const response = Api().get('/api/1/log/eventIds');
    return response;
  },

  getEvent(eventId) {
    const response = Api().get('/api/1/log/' + eventId);
    return response;
  },

  getLast( numRecords , eventId ) {
    let query = '/api/1/log/getLast';

    if( numRecords ) {
      query = query + "/" + numRecords;
    }

    if( eventId !== "all" ) {
      query = query + "/" + eventId;
    }

    const  response = Api().get(query);
    return response;
  }

}
