///-------------------------------------------------------------------------------------------------
// summary:	Total service class
///-------------------------------------------------------------------------------------------------

import Api from '@/Services/Api';

export default {

  getTotals() {
    const response = Api().get(`/api/1/totalClasses`);
    return response;
  },
  getTotalClasses() {
    const response = Api().get(`/api/1/totalClasses`);
    return response;
  },
  getTotal( totalClassId ) {
    const response = Api().get(`/api/1/totalClass/${totalClassId}`);
    return response;
  },
  addTotal( total ) {
    const response = Api().post(`/api/1/totalClass`, total );
    return response;
  },
  updateTotal( total ) {
    const response = Api().patch(`/api/1/totalClass/${total._id}`, total );
    return response;
  },
  removeTotal(totalObjectId) {
    const response = Api().delete(`/api/1/totalClass/${totalObjectId}`);
    return response;
  }
}
