<template>
  <b-container fluid>
    <b-form @input="dataChanged()">
      <!--<div class="no-show-md step-image" >-->
<!--        <b-img class="" :src="formData.imageUrl" />-->
    <!--    <b-button class="btn btn-sm btn-fill" style="cursor:pointer;" @click="editImage()">Edit Image</b-button>-->
      <!--</div>-->
      <!--<b-form-group label-cols-lg="2" label-size="lg" label-class="font-weight-bold " class="mt-0 mb-0" label="Edit Step">-->
        <b-form-group class="fg-input " label-cols="2" label="Active?">
          <b-form-checkbox switch class="cb-input" v-model="formData.active"/>
        </b-form-group>
        <b-form-group class="fg-input" label-cols="2" label="Title">
          <b-form-input v-model="formData.title" type="text"/>
        </b-form-group>
        <b-form-group class="fg-input" label-cols="2" label="Description">
          <b-form-textarea rows=5 v-model="formData.description" />
        </b-form-group>
        <b-form-group class="fg-input" label-cols="2" label="Icon">
          <b-form-select v-model="formData.icon" :options="iconOptions"></b-form-select>
        </b-form-group>
      <!--</b-form-group>-->
    </b-form>
  </b-container>
</template>

<script>
export default {
  props: {
    formData: { type: Object, default: () => {} },
    iconOptions: { type: Array, default: () => [] },
    itemName: { type: String, default: "Checklist" }
  },
  data() {
    return {
      isDataChanged: false
    }
  },
  mounted() {
  },
  methods: {
    dataChanged() {
      this.isDataChanged = true;
      this.$emit( "dataChanged"  , true );
    },
    resetDataChanged() {
      this.isDataChanged = false;
    },
    editImage() {
      this.dataChanged();
      this.$emit( "editImage" , this.formData.imageUrl ); // won't likely use it, but pass it up anyway
    }
  }
};
</script>

<style lang="scss" scoped>

  .fg-input {
    margin:0;
    padding: 0 .25em .25em 0;
    text-align:right;
  }

  .fg-input-right {
    position:absolute;
    right:0;
    margin-left:2em;
  }

  .cb-input {
    text-align:left;
    top:5px;
  }

  .cb-input-right {
    text-align:right;
    top:5px;
    z-index:2;
  }

  .step-image {
    float:left;
    position:relative;
    top:70px;
    left:120px;
    z-index:2;
    img {
      height:128px;
      border-radius:10%;
    }
    button {
      margin-top:.5em;
      display:block;
      border-radius:1em;
      font-size:.6em;
      margin-left:auto;
      margin-right:auto;
    }
  }

  @media only screen and (max-width: 1024px) {
    .no-show-md {
      display:none;
    }
  }

</style>>
