import "regenerator-runtime/runtime";

import Vue       from "vue";
import VueRouter from "vue-router";
// import "./registerServiceWorker";

import VueAnalytics from 'vue-analytics';
import moment       from 'moment';

import BootstrapVue   from 'bootstrap-vue';
import LightBootstrap from "./light-bootstrap-main"; // our theme (creative Tim - have developer license for PRO; purchased 22-Nov-18)

// import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import BootstrapTable from "vue-bootstrap4-table";
import AppView        from "./App.vue";

// Communications & Services
import axios   from "axios";
import Api     from "/src/Services/Api";
import routes  from "./routes";
import myBuild from "./../buildDefinition.json";
import _       from "lodash";
import JsonCSV from 'vue-json-csv'    // fixme: remove this module when I get this update complete

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import VueTimeline from "@growthbunker/vuetimeline";

Vue.use(VueTimeline);

// "vue-wysiwyg": "^1.7.2",  (from the package.json file)
import wysiwyg from "vue-wysiwyg";

Vue.use(wysiwyg, {forcePlainTextOnPaste: true}); // config is optional. more below
import "vue-wysiwyg/dist/vueWysiwyg.css";

import './registerServiceWorker'

Vue.component('downloadCsv', JsonCSV);    // download-csv

Vue.filter('toCurrency', function(value) {
  if(typeof value !== "number") {
    value = parseFloat(value);
  }
  var formatter = new Intl.NumberFormat('en-US', {    // fixme: store this value in the user's profile
    style                : 'currency',
    currency             : 'USD',
    minimumFractionDigits: 2
  });
  return formatter.format(value);
});

Vue.filter('comma2d', function(value) {
  if(typeof value !== "number") {
    value = parseFloat(value);
  }
  var formatter = new Intl.NumberFormat('en-US', {    // fixme: store this value in the user's profile
    style                : 'decimal',
    currency             : 'USD',
    minimumFractionDigits: 2
  });
  return formatter.format(value);
});

Vue.filter('toLongDate', function(value) {
  return moment(value).format('dddd, LL');
});

Vue.filter('fromNow', function(value) {
  return moment(value).fromNow();
});

const {version, versionReleaseDate} = require('../package.json');

Vue.prototype._ = _;

// plugin setup
Vue.use(LightBootstrap);
Vue.use(VueRouter);
Vue.use(BootstrapVue);
Vue.use(BootstrapTable);
Vue.use(VueSweetalert2);
// Vue.use(VueFormWizard)

// configure router
const router = new VueRouter(
  {
    mode           : "history",
    routes,
    linkActiveClass: "nav-item active",
    scrollBehavior : function(to, from, savedPosition) {
      if(savedPosition) {
        return savedPosition;
      }
      if(to.hash) {
        return {selector: to.hash};
      }
    }
  });

Vue.use(VueAnalytics, {
  id          : "UA-128966409-1",   // Newcare Admin APP  -- Old Id was 'UA-9964551-21',
  router,
  autoTracking: {
    pageviewTemplate(route) {
      let o = {};
      if(typeof route.matched != 'undefined') {       // wjs: 03-Nov-17 :: Figure if matched is present, it's an array with at least 1 element
        o.title = route.matched[ 0 ].name;            //   seems to be the same as route.name, but for consistency used the matched name
        if(route.matched[ 0 ].path != '*') {          // let's capture the 404's so this is the "catch-all" we will replace.
          o.path     = route.matched[ 0 ].path;
          o.location = route.matched[ 0 ].path;       // The matched route, which is the raw route definition, as our location
        } else {
          o.path     = route.path;                    // we hit *, which would mean a 404, so use the original url so we capture it.
          o.location = window.location.href;
        }
      } else {
        o.title    = route.name;
        o.path     = route.path;
        o.location = window.location.href;
      }
      return o;
    }
  }
})

// Some middleware to help us ensure the user is authenticated.
router.beforeEach(async (to, from, next) => {

  //if (to.meta !== undefined) {
  //  if (to.meta.requiresAuth !== undefined) {
  //    if (to.meta.requiresAuth === true) {
  //      if (router.app.$options.data().isAuthenticated !== true) {
  //        next({ path: "/login" });
  //      }
  //    }
  //  }
  //}

  //self.localStorage.lastRouteName = from.name;          // fixme: this is a patch using local stroage -- need to figure out how to put it into our data store.

  if(to.matched.some(record => record.meta.requiresAuth) && (!router.app.accessToken || router.app.accessToken === "null")) {

    if(to.name === "Login") {    // if we're headed to login, just go...
      return next();
    }
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    // window.console.log("Not authenticated");

    next({
           path : "/login",
           query: {
             redirect: to.fullPath
           }
         });
  } else {

    // if( to.matched.some(record => record.meta.requiresAdmin) &&  !parent.$root.isAdmin()) {
    //   await parent.$root.alert( "You must be an administrator to access this page." );
    //   return;
    // }

    next();
  }
});

Vue.config.productionTip = false
Vue.config.lang          = 'en';

/* eslint-disable no-new */
new Vue({
          el    : "#app",
          render: app => app(AppView),
          router,
          store : {
            state: {}
          },
          data  : function() {
            return {
              appLocation       : "main.js",
              appTitle          : "iCare Cafe",
              versionOnly       : version,
              appVersion        : versionReleaseDate + " V" + version + "." + myBuild.buildNumber.toString(),   //  >>>> Don't forget index.html  <<<<
              theme             : {background: "default"},
              isShowSettingsMenu: false,
              authenticated     : false,
              desiredRoute      : "",
              timeoutTimer      : {},
              currentTime       : moment(),
              nextEventTimer    : 300,
              tenant            : {
                tenantName: "",
                portal    : {
                  active: false
                }
              },
              myRootData        : "This is root data",
              TZ                : "America/Chicago",     // default our timezone -- this comes in with the user (or should)
              user              : {},                    // currently logged in user.
              users             : [],
              tenantFeatures    : [],
              tenantList        : [],
              timezones         : [
                // todo: get this list from the API (or even maybe from the TZ server)
                {text: "Please select a Timezone", value: null},
                {text: "America/New York (EDT/EST)", value: "America/New_York"},
                {text: "America/Chicago (CST CDT)", value: "America/Chicago"},
                {text: "America/Denver (MST)", value: "America/Denver"},
                {text: "America/Los_Angeles (PST PDT)", value: "America/Los_Angeles"},
                {text: "America/Anchorage (AKST AKDT)", value: "America/Anchorage"},
                {text: "America/Phoenix (MST)", value: "America/Phoenix"},
                {text: "America/Fort_Nelson (PST PDT MST)", value: "America/Fort_Nelson"},
                {text: "Pacific/Honolulu (HST)", value: "Pacific/Honolulu"},
                {text: "Europe/Paris (CET/CEST)", value: "Europe/Paris"}
              ],
              accountTypeCounts : null,
              accessToken       : "",
              oneLine           : false,                 // our selections are one liners?  (no idea what this means)
              appExtraMenu      : []
            }
          },
          mounted() {
            // console.log("main.js->mounted()");
          },
          created() {
            // console.log( "main.js->created()");
          },
          beforeDestroy() {
          },
          // timers: {     // note: module not installed yet.
          //   tick: { time: 1000, autostart: true, repeat: true }
          // },
          methods: {
            // One second timer called from timers.
            tick() {
              console.log("inside tick");
            },
            /*
             "status"      : 200,
             "alias"       : "daWolf",
             "tenantActive": true,
             "tenantName"  : "POS Testing Company",
             "logo"        : "http://ioihan.com/wp-content/uploads/2015/01/IOIHANlogo300x502.png",
             "theme"       : "blue",
             "portalActive": true,
             "tenantUser"  : "5be27416ce1e153c60e8b90e",
             "userId"      : "5acd6f2b97ea88237472c579",
             "roles"       : [],
             "isAdmin"     : true,
             "TZ"          : "America/Chicago",
             "accountType" : 1,
             "logonCount"  : 1708,
             "lastLogon"   : "2019-06-02T18:24:05.038Z",
             "lastIP"      : "::1",
             "pictureUrl"  : "https://devicenet.blob.core.windows.net/system/users/57d0a585aa0d65bc753f0547.JPG",
             "features"    : [
             "icare",
             "help",
             "support"
             ],
             "domain"        : "ioihan",
             "tokenAge"      : "30d",
             "currentIP"     : "::1",
             "apiBaseURL"    : "sandbox.ioihan.com:9040",
             "apiAppURL"     : "/api/1",
             "apiAdminURL"   : "/admin",
             "apiVersion"    : "0.0.51",
             "serverCompany" : "onLocal",
             "x-access-token":
             */
            clearGlobals() {
              this.tenant        = {tenantName: "", portal: {active: false}};
              this.user          = {};
              this.users         = [];
              this.searchString  = "";
              this.authenticated = false;
              this.user          = {};
              this.accessToken   = "";
              Api.token          = "";

              axios.defaults.headers.common[ 'x-access-token' ] = '';

              sessionStorage.clear();
            },
            setToken(token) {
              this.accessToken = token;
              axios.defaults.headers.common[ 'x-access-token' ] = token;
            },
            updateLocalStorage(data) {
              let rememberMe = localStorage.getItem('rememberMe');

              localStorage.removeItem("housingConfig"); // always remove this

              if(rememberMe !== "true") {
                localStorage.removeItem(data.email);    // not remember, so ensure it's removed
                localStorage.removeItem('lastEmail');
                this.tenantList = [];
                return;
              }

              let storageKey = data.loginId;

              // console.log( "Storage key = ", storageKey, data );

              let block            = JSON.parse(localStorage.getItem(storageKey)) || {};
              block[ data.domain ] = data;
              let tenants          = block[ 'tenants' ] || [];
              if(tenants.indexOf(data.domain) === -1) {
                tenants.push(data.domain);
              }
              block[ 'tenants' ]       = tenants;
              block[ 'lastDomain' ]    = data.domain;  // where we JUST logged into
              block[ 'lastLoginDate' ] = moment().format().toString();

              this.tenantList = [];
              tenants.forEach(t => { this.tenantList.push({domain: block[ t ].domain, tenantName: block[ t ].tenantName}) });

              localStorage.setItem('lastEmail', storageKey); // have to remember our last guy so we can auto-login
              localStorage.setItem(storageKey, JSON.stringify(block));

            },
            async logIn(data) {
              let t = this;

              sessionStorage.clear();
              this.updateLocalStorage(data);

              this.setToken(data[ "x-access-token" ]);
              this.user                 = data;
              this.user.pictureUrl += '?rnd=' + (new Date().valueOf());   // force reload
              this.tenant.tenantName    = data.tenantName;
              this.tenant.branding      = data.branding;
              this.tenant.portal.active = data.portalActive || false;
              this.TZ                   = data.TZ || "America/Chicago";  // this is the TENANT TZ
              //this.user.lastLogon = moment( this.user.lastLogon ).tz( this.TZ ).toString();
              this.setTheme(data.theme);
              this.tenantFeatures = this.user.tenantFeatures;
              delete this.user.tenantFeatures;

              if(this.accessToken !== "") {
                this.authenticated = true;
                // if( ! this.user.defaultRoute ) {
                //   router.push("app/overview");     // fixme: this needs to come from the user "default" page
                // } else {
                //   router.push( this.user.defaultRoute );
                // }
              }

            },
            logOut() {
              localStorage.removeItem(this.user.email);
              this.clearGlobals();
              router.push("/");
            },
            isAdmin() {
              if(this.isSA()) {
                return true;
              }
              return this.user.isAdmin || false;
            },
            isSA() {
              return this.user.isSA || false;
            },
            isInRole(role) {
              if(this.isAdmin()) {
                return true;
              }
              return this.user.roles.indexOf(role) >= 0;
            },
            myTimezone() {
              return this.TZ;
            },
            lastOn() {
              return this.user.lastLogon || new Date();
            },
            isPortal() {
              return this.tenant.portal.active || false;
            },
            isTenantFeature(feature) {
              return this.tenantFeatures.indexOf(feature) >= 0;
            },
            isFeature(feature) { // tests tenant for HAVE, and the user for ALLOW
              // console.log( "feature" , feature, this.tenantFeatures );
              let features = feature.split(",");
              for(var i = 0; i < features.length; i++){
                if(this.tenantFeatures.indexOf(features[ i ]) >= 0) {
                  return this.user.features.indexOf(features[ i ]) >= 0;
                }
              }
              return false;
            },
            setTheme(value) {
              this.theme.background = value;
            },
            hideSettingsMenu() {
              this.isShowSettingsMenu = false;
            },
            lastRouteName() {
              // return self.localStorage.lastRouteName;     // fixme: This method is good, but refactor to NOT use local storage.
            },
            async confirmYesNo(title, icon = "question", cancelButtonText = "No", confirmButtonText = "Yes") {
              let result = await this.$swal.fire({
                                                   icon             : icon,
                                                   title            : title,
                                                   showCancelButton : true,
                                                   cancelButtonText : cancelButtonText,
                                                   confirmButtonText: confirmButtonText,
                                                   allowEscapeKey   : true,
                                                   allowEnterKey    : true
                                                 });

              if(result.dismiss) {
                return false;
              }

              return true;
            },
            async confirm(title, icon = "question") {
              let result = await this.$swal.fire({
                                                   icon            : icon,
                                                   title           : title,
                                                   showCancelButton: true,
                                                   allowEscapeKey  : true,
                                                   allowEnterKey   : true
                                                 });

              if(result.dismiss) {
                return false;
              }

              return true;
            },
            async alert(title, icon = 'success') {
              let result = await this.$swal.fire({
                                                   icon          : icon,
                                                   title         : title,
                                                   allowEscapeKey: true
                                                 });
            },
            systemMessage(status, message) {
              let t=this;
              const notification = {
                template: `<span><b>${status}</b>  ${message}</span>`
              };

              t.$notify(
                {
                  component      : notification,
                  icon           : 'nc-icon nc-tap-01',
                  horizontalAlign: "center",
                  verticalAlign  : "top",
                  type           : "info"
                })
            },
            errorMessage(status, message) {
              let t = this;
              t.$notify({
                          title          : status,
                          type           : "danger",
                          horizontalAlign: "center",
                          message        : message,
                          timeout        : 3000,
                          duration       : 3000
                        })
            },
            warningMessage(status, message) {
              let t = this;
              t.$notify({
                          icon           : "fa fa-exclamation-triangle",
                          title          : status,
                          message        : message,
                          horizontalAlign: "center",
                          type           : "warning",
                          duration       : 3000
                        })
            },
            successMessage(status, message) {
              let t = this;
              t.$notify({
                             message        : message,
                             icon           : "nc-icon nc-satisfied",
                             title          : status,
                             horizontalAlign: "center",
                             type           : 'success'
                           })

            },
            warningToast(message, title = null, delay = 5000, append = false) {
              let toastOptions =
                    {
                      autoHideDelay: delay,
                      appendToast  : append,
                      variant      : 'warning',
                      title        : title
                    }
              this.$bvToast.toast(message, toastOptions)
            },
            errorToast(message, title = null, delay = 5000, append = false) {
              let toastOptions =
                    {
                      autoHideDelay: delay,
                      appendToast  : append,
                      variant      : 'danger',
                      title        : title
                    }
              this.$bvToast.toast(message, toastOptions)
            },
            tenantName() {
              return this.user.tenantName || "";
            },
            tenantDomain() {
              return this.user.domain || "";
            }
          }

        });
