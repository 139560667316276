<template>
  <div>

    <div>
      <b-button v-if="!isChanged" class="btn btn-sm btn-fill btn-success" style="float:left;margin-right:1em;" @click="done()">Done</b-button>
      <b-button v-if="isChanged" class="btn btn-sm btn-fill btn-success" style="float:left;margin-right:1em;" @click="save()">Save</b-button>
      <b-button v-if="isChanged" class="btn btn-sm btn-fill btn-warning" style="float:left;margin-right:1em;" @click="cancel()">Cancel</b-button>
      <h4 slot="header" class="card-title ">Certifications<i class="fa fa-plus-square show-hand float-right show-hand" v-b-popover.hover.top="'Add new'" @click="addNew()"></i></h4>
    </div>
    <hr>

    <div v-if="certifications">

      <b-tabs lazy class="tab-hover" content-class="mt-2 ml-3 mr-3" active-nav-item-class="selected-tab">

        <b-tab title="General">
          <h4>Certification Configuration</h4>
          <p>See each tab above to configure the Certification Tracking</p>
          <p></p>
          <hr>
          <b-form style="margin-left:5em;" @change="dataChanged()">
            <h4>System Options</h4>
            <b-checkbox v-model="certifications.options.isDashboard">Enable Dashboard Processing</b-checkbox>
            <b-checkbox v-model="certifications.options.enableFiles">Enable File Management</b-checkbox>
          </b-form>
        </b-tab>

        <b-tab title="Types">
          <b-row style="padding:0;">
            <b-col class="col-sm-8 col-md-8 col-lg-8 col-xl-8" style="border-right:3px solid grey;">
              <h4><i class="fa fa-plus-square show-hand mr-2" @click="addCertificationType()" v-b-popover.hover.right="'Add New'"></i>Certification Types</h4>
              <b-tabs vertical v-model="certificationsTypesTab" class="tab-hover-left" content-class="mt-2 ml-3 mr-3" active-nav-item-class="selected-tab-left">
                <template v-for="(t,tIndex) in certifications.types">
                  <b-tab :key="'ctype-'+tIndex.toString()"  style="border-left:2px solid black;padding-left:2em;">
                    <template v-slot:title>
                      <div style="max-width:12em;width:12em;text-align:right;">
                        <div style="margin:0;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;">{{ t.title || 'none' }}</div>
                      </div>
                    </template>
                    <i class="fa fa-trash" style="float:right;font-size:1.5em;" @click="removeCertificationType(tIndex)" v-b-popover.hover.top="'Remove this item'"></i>
                    <b-form @change="dataChanged();" style="width:600px;">
                      <sf-input label="DB Key" :readonly="t.title.length>0" :labelCols="2" type="text" v-model="t.recordType"></sf-input>
                      <sf-input label="Title" :labelCols="2" type="text" v-model="t.title"></sf-input>
                      <sf-input label="Sequence" :labelCols="2" cstyle="width:8em;" type="number" v-model="t.sequence"></sf-input>
                      <div style="margin-top:1em;height:650px;padding-right:1em;overflow-y:auto;overflow-x:hidden;">
                        <option-management-table
                            title="Categories"
                            subTitle="The KEY is the database value, the TEXT is human-readable part."
                            :keysLowercase="true"
                            :keysNoSpaces="true"
                            :tableData="t.categories"
                        >
                          <!--                      <div slot="header">-->
                          <!--                        <h4>Categories</h4>-->
                          <!--                        <p class="help-text">The KEY is the database value, the TEXT is human-readable part.</p>-->
                          <!--                      </div>-->
                        </option-management-table>
                      </div>
                    </b-form>
                  </b-tab>
                </template>
              </b-tabs>

              <!--            <b-row style="padding:0;">-->
              <!--              <b-col class="col-sm-8 col-md-8 col-lg-8 col-xl-8" >-->
              <!--                <div style="margin-top:1em;height:650px;padding-right:1em;overflow-y:auto;overflow-x:hidden;">-->
              <!--                  <option-management-table :keysLowercase="true" :keysNoSpaces="true" :tableData="certifications.certificationTypes" @change="dataChanged()" >-->
              <!--                  <div slot="header">-->
              <!--                    <h4>Certification Types</h4>-->
              <!--                    <p class="help-text">The KEY is the database value, the TEXT is human-readable part.</p>-->
              <!--                  </div>-->
              <!--                  </option-management-table>-->
              <!--                </div>-->
              <!--              </b-col>-->
              <!--               <b-col>-->
              <!--                <h4 class="sub-header">Help Information</h4>-->
              <!--                <p>-->
              <!--                  Certification Types are used to define the type of certification.  For example, "Certification", "Required Certification", or "Training"-->
              <!--                </p>-->
              <!--                <p>-->
              <!--                  These are overall classification of the TYPE of certification.  For grouping, see the categories and section settings.-->
              <!--                </p>-->
              <!--              </b-col>-->
              <!--            </b-row>-->

            </b-col>
            <b-col>
              <h4 class="sub-header">Help Information</h4>
              <p>Types are available on the Overview and Matrix pages as additional filter criteria.</p>
            </b-col>
          </b-row>
        </b-tab>

        <!--          <b-tab title="Categories" >-->
        <!--            <b-row style="padding:0;">-->
        <!--              <b-col class="col-sm-8 col-md-8 col-lg-8 col-xl-8" >-->
        <!--                <div style="margin-top:1em;height:650px;padding-right:1em;overflow-y:auto;overflow-x:hidden;">-->
        <!--                  <option-management-table :keysLowercase="true" :keysNoSpaces="true" :tableData="certifications.categories" @change="dataChanged()" >-->
        <!--                  <div slot="header">-->
        <!--                    <h4>Certification Categories</h4>-->
        <!--                    <p class="help-text">The KEY is the database value, the TEXT is human-readable part.</p>-->
        <!--                  </div>-->
        <!--                  </option-management-table>-->
        <!--                </div>-->
        <!--              </b-col>-->
        <!--               <b-col>-->
        <!--                <h4 class="sub-header">Help Information</h4>-->
        <!--                <p>-->
        <!--                  Certification categories allow further classification into groups.  For example, departments, or types of certifications.-->
        <!--                </p>-->
        <!--              </b-col>-->
        <!--            </b-row>-->
        <!--          </b-tab>-->

        <b-tab title="Sections">
          <b-row style="padding:0;">
            <b-col class="col-sm-8 col-md-8 col-lg-8 col-xl-8" style="border-right:3px solid grey;">
              <div style="margin-top:1em;height:650px;padding-right:1em;overflow-y:auto;overflow-x:hidden;">
                <option-management-table
                    title="Certification Sections"
                    subTitle="The KEY is the database value, the TEXT is human-readable part."
                    :keysLowercase="true"
                    :keysNoSpaces="true"
                    :tableData="certifications.sections"
                    @change="dataChanged()"
                >
                  <!--                  <div slot="header">-->
                  <!--                    <h4>Certification Sections</h4>-->
                  <!--                    <p class="help-text">The KEY is the database value, the TEXT is human-readable part.</p>-->
                  <!--                  </div>-->
                </option-management-table>
              </div>
            </b-col>
            <b-col>
              <h4 class="sub-header">Help Information</h4>
              <p>Sections are available on the Overview and Matrix pages as additional filter criteria.</p>
            </b-col>
          </b-row>
        </b-tab>

        <b-tab title="Classifications">
          <b-row style="padding:0;">
            <b-col class="col-sm-8 col-md-8 col-lg-8 col-xl-8" style="border-right:3px solid grey;">
              <div style="margin-top:1em;height:650px;padding-right:1em;overflow-y:auto;overflow-x:hidden;">
                <option-management-table
                    title="Certification Classifications"
                    subTitle="The KEY is the database value, the TEXT is human-readable part."
                    :keysLowercase="true"
                    :keysNoSpaces="true"
                    :tableData="certifications.classifications"
                    @change="dataChanged()"
                >
                  <!--                  <div slot="header">-->
                  <!--                    <h4>Certification Classifications</h4>-->
                  <!--                    <p class="help-text">The KEY is the database value, the TEXT is human-readable part.</p>-->
                  <!--                  </div>-->
                </option-management-table>
              </div>
            </b-col>
            <b-col>
              <h4 class="sub-header">Help Information</h4>
              <p>
                Optional additional classification.
              </p>
            </b-col>
          </b-row>
        </b-tab>

        <b-tab title="Account Profiles">
          <b-row style="padding:0;">
            <b-col class="col-sm-8 col-md-8 col-lg-8 col-xl-8" style="border-right:3px solid grey;">
              <div style="margin-top:1em;height:650px;padding-right:1em;overflow-y:auto;overflow-x:hidden;">
                <option-management-table
                    title="Account Profiles"
                    subTitle="The KEY is the database value, the TEXT is human-readable part."
                    :keysLowercase="true"
                    :keysNoSpaces="true"
                    :tableData="certifications.accountClass"
                    @change="dataChanged()"
                >
<!--                  <div slot="header">-->
<!--                    <h4>Account Profiles</h4>-->
<!--                    <p class="help-text">The KEY is the database value, the TEXT is human-readable part.</p>-->
<!--                  </div>-->
                </option-management-table>
              </div>
            </b-col>
            <b-col>
              <h4 class="sub-header">Help Information</h4>
              <p>
                This section identifies which Account Profiles are tracked and the name you wish to call them.  e.g., "Hourly Employee", "Salary Employee".
              </p>
              <p>The number (key) should match the Profile ID in the Account Profiles Setup.</p>
            </b-col>
          </b-row>
        </b-tab>

<!--        <b-tab title="Email Templates">-->
<!--          <b-row style="padding:0;">-->
<!--            <b-col class="col-sm-8 col-md-8 col-lg-8 col-xl-8">-->
<!--              <div style="margin-top:1em;height:650px;padding-right:1em;overflow-y:auto;overflow-x:hidden;">-->
<!--                <b-tabs lazy class="tab-hover" content-class="mt-2 ml-3 mr-3" active-nav-item-class="selected-tab">-->
<!--                  <b-tab title="Expiration"></b-tab>-->
<!--                  <b-tab title="Assignment"></b-tab>-->
<!--                </b-tabs>-->
<!--              </div>-->
<!--            </b-col>-->
<!--            <b-col>-->
<!--              <h4 class="sub-header">Help Information</h4>-->
<!--              <p>-->
<!--                These are your email templates-->
<!--              </p>-->
<!--            </b-col>-->
<!--          </b-row>-->
<!--        </b-tab>-->


      </b-tabs>
    </div>

  </div>
</template>

<script>
import TenantService           from "/src/Services/TenantService";
import CertificationsService   from "/src/Services/CertificationsService";
import Moment                  from "moment-timezone";
import Card                    from "/src/components/UIComponents/Cards/Card.vue";
import OptionManagementTable   from "/src/components/UIComponents/Cards/OptionManagementTable.vue";
import SfInput                 from "/src/components/UIComponents/Inputs/SFormInput.vue";
import CodeEditor              from "/src/components/UIComponents/Inputs/CodeEditor.vue";
import LTable                  from "/src/components/UIComponents/Table.vue";
import iLib                    from "/src/Lib/ioihanLib.js";
import PageEditHeaderCard      from '/src/components/UIComponents/Cards/PageEditHeaderCard.vue';
import PaymentProvidersService from '/src/Services/PaymentProvidersService.js';
import ListCard                from '/src/components/UIComponents/Cards/ListCard.vue';

import { VueEditor } from "vue2-editor";

export default {
  components: {
    LTable,
    Card,
    CodeEditor,
    SfInput,
    OptionManagementTable,
    PageEditHeaderCard,
    ListCard,
    VueEditor
  },
  data() {
    return {
      saveDisabled          : true,
      isChanged             : false,
      isAdding              : false,
      isEditing             : false,
      certificationsTypesTab: 0,
      authToken             : "",
      animatedSpinner       : "fa fa-spinner  fa-refresh-animate mr-3",
      certifications        : null,

      timezones: [
        // todo: get this list from the API (or even maybe from the TZ server)
        {text: "Please select a Timezone", value: null},
        {text: "America/New York (EDT/EST)", value: "America/New_York"},
        {text: "America/Chicago (CST CDT)", value: "America/Chicago"},
        {text: "America/Denver (MST)", value: "America/Denver"},
        {text: "America/Los_Angeles (PST PDT)", value: "America/Los_Angeles"},
        {text: "America/Anchorage (AKST AKDT)", value: "America/Anchorage"},
        {text: "America/Phoenix (MST)", value: "America/Phoenix"},
        {text: "America/Fort_Nelson (PST PDT MST)", value: "America/Fort_Nelson"},
        {text: "Pacific/Honolulu (HST)", value: "Pacific/Honolulu"},
        {text: "Europe/Paris (CET/CEST)", value: "Europe/Paris"}
      ],
    }
  },
  watch  : {},
  mounted: async function() {
    await Promise.all([
                        this.getCertificationsConfig()
                      ]);
  },
  async beforeRouteLeave(to, from, next) {
    if(!this.isChanged) {
      return next();
    }
    if(await this.$root.confirm('You have unsaved changes, continue leaving without saving?', "warning")) {
      return next();
    }
    next(false)
  },
  computed: {},
  methods : {
    done() {
      this.$router.go(-1);
    },
    async alert(e) {
      await this.$root.alert(e);
    },
    async save() {
      if(this.isChanged) {
        await this.updateCertificationsConfig();
      }
      this.done();
    },
    async cancel() {
      if(this.isChanged) {
        if(!await this.$root.confirmYesNo("You have unsaved changes, continue to leave?")) {
          return;
        }
      }
      this.isChanged = false;
      this.$router.go(-1);
    },
    async removeCertificationType( index ) {
      if( ! await this.$root.confirmYesNo( "Remove this Certification Type?" ) ) {
        return;
      }
      this.certifications.types.splice( index, 1 );
      this.$root.successMessage( "Certification Type Removed" );
      this.dataChanged();
    },
    async addCertificationType() {
      if(!await this.$root.confirmYesNo("Add new Certification Type?")) {
        return;
      }
      if(!this.certifications.types) {
        this.certifications.types = [];
      }
      this.certifications.types.push({
                                       title     : "New Type",
                                       categories: []
                                     });

      console.log("results", this.certifications);
    },
    tagValidator(tag) {
      return tag === tag.toLowerCase() && tag.length > 1 && tag.length < 20
    },
    // certificationTypeName(id) {
    //   let x = this.certificationTypes.find((x) => { return x.value == id });
    //   if(x) {
    //     return x.text;
    //   }
    //   return id;
    // },
    moment(d) {
      return Moment(d);
    },
    dataChanged() {
      this.isChanged = true;
    },
    async getCertificationsConfig() {
      try {
        let response        = await CertificationsService.getCertificationsConfig();
        this.certifications = response.data;
        this.certifications.types = _.sortBy(this.certifications.types, (x) => { return x.sequence});
        console.log("Config", this.certifications);
        this.isChanged = false;
      } catch(e) {
        await this.$root.alert(e.message, "error");
      }
    },
    async updateCertificationsConfig() {
      try {
        let data = this.certifications;

        if( data.types ) {
          data.types = _.sortBy(data.types, (x) => { return x.sequence});
        }

        data.updateAt = Moment().tz(this.$root.user.userTZ).format("YYYY-MM-DD HH:mm:ss")
        data.updateBy = this.$root.user.alias

        let response        = await CertificationsService.updateCertificationsConfig(data);
        this.certifications = response.data;
        this.isChanged      = false;

      } catch(e) {
        await this.$root.alert(e.message, "error");
      }
    }

  }
};
</script>

<style lang="scss" scoped>


.help-text {
  font-size: .8em;
  color: gray;
  text-align: left;
  max-width: 500px;
}

.sub-header {
  width: 100%;
  background: lightgray;
  margin: 1em 0 1em 0;
  padding: 3px;
}

</style>
