<template>
  <b-container class="content container-fluid col-12" style="padding-top:.5em;min-height:2000px;overflow:auto;">

    <div v-if="ticket && showTicket" >
      <ticket-display-page
        :ticket="ticket"
        :logo="$root.user.logo"
        :config="_.find(this.ticketConfig.ticketType, { key: ticket.ticketType })"
        @cancel="doTicketPrintCancel"
        @print="doTicketPrint"
      />
    </div>

    <div v-if="ticket && showInvoice" >
      <ticket-invoice
        :ticket="ticket"
        :logo="$root.user.logo"
        :config="_.find(this.ticketConfig.ticketType, { key: ticket.ticketType })"
        @cancel="doTicketPrintCancel"
        @print="doTicketPrint"
      />
    </div>

    <!-------------------------------------------------------------------------------[ Ticket Editing Begins Below ]-->
    <div class="row" v-if="ticket && !showTicket && !showInvoice">
    <!-- <div class="row" v-if="isTicket===true && !showTicket"> -->
      <div style="width:100%;">
        <i class="noprint fa show-hand" :class="(isShowRightPanel) ? 'fa-compress' : 'fa-expand'" style="float:right;" @click="toggleShowRightPanel()"/>
      </div>
      <div class="col-sm-12 col-md-12" :class="(isShowRightPanel) ? 'col-xl-8' : 'col-xl-12'" style="border-radius:1em;">

        <div style="width:50%;float:right;border-radius:1em;">
          <span>
            <!-- <sf-input :disabled="isReadOnly() || (! $root.isAdmin())" type="list" label="" cstyle="border:2px solid green;border-radius:1em;font-weight:800;text-align:center;" :options="ticketTypesList()" v-model="ticket.ticketType" @input="ticketSetChanged()" @change="getAssignToUsers( ticket.ticketType )" /> -->
            <sf-input :disabled="isReadOnly()" type="list" label="" cstyle="border:2px solid green;border-radius:1em;font-weight:800;text-align:center;" :options="ticketTypesList()" v-model="ticket.ticketType" @input="ticketSetChanged()" @change="getAssignToUsers( ticket.ticketType )" />
          </span>
        </div>

        <button v-if="!isTicketChanged" class="float-left mr-3 btn btn-fill btn-sm btn-success" style="margin-top:-4px;" @click="done()">
          <span v-show="loading" :class="isLoading"></span>
          Done
        </button>
        <button v-if="isTicketChanged" class="float-left mr-3 btn btn-fill btn-sm btn-success" style="margin-top:-4px;" @click="saveTicket( ticket )">
          <span v-show="loading" :class="isLoading"></span>
          {{(task) ? 'Save TASK' : 'Save Ticket'}}
        </button>
        <button v-if="isTicketChanged && !task" class="float-left mr-3 btn btn-fill btn-sm btn-warning" style="margin-top:-4px;" @click="cancelEdit">
          Cancel
        </button>

        <h4 class="card-title mb-3 mt-5">
          <span><i class="fa fa-print show-hand" style="font-size:.8em;margin-right:.3em;" v-b-popover.hover.top="'Print Workorder'" @click="printTicket"></i></span>
          <span v-if="ticket.billable"><i class="fa fa-file-text-o show-hand" style="font-size:.8em;margin-right:.3em;" v-b-popover.hover.top="'Print Invoice'" @click="printInvoice"></i></span>
          <i v-if="$root.user.isAdmin && ticket.status==='other'" class="fa fa-trash show-hand" style="float:right;font-size:.6em;" v-b-popover.hover.top="'Delete This Ticket'" @click="deleteTicket(ticket._id)"></i>
          <span>Ticket {{ticket.ticketNumber}}</span><span v-if="ticket.closed" style="margin-left:1em;border-radius:1em;background:#ee4422;color:white;padding: .1em .5em .1em .5em;">Closed</span>
          <span style="margin-left:1em;font-size:.7em;color:gray;">({{ticket.title}})</span>
        </h4>

        <div v-if="ticket.closed" class="page-stamp">
          CLOSED
        </div>

        <!-- ------------------------------------------------------------------------------------------[ Begin Comment Modal ]---->

        <b-modal class="modal-scoped fade" id="commentModal" v-model="commentModalShow" title="Add Case Comment">
          <form>
            <div class="form-group">
              <label for="comment-description" class="col-form-label">Comment <span v-if="comment.replyTo"> Reply To {{comment.replyTo.actionUserAlias}}</span></label>
              <!-- <sf-input type="number" label="hours" cstyle="width:6em;float:right;" :labelCols="9" v-model="comment.hours" /> -->
              <textarea class="form-control" rows=10 id="comment-description" v-model="comment.comment"></textarea>
            </div>
          </form>
          <template slot="modal-footer" slot-scope="{ cancel }">
            <b-button class="btn-fill" size="sm" variant="danger" @click="cancel()">
              Cancel
            </b-button>
            <b-button class="btn-fill" size="sm" variant="success" @click="addComment()">
              OK
            </b-button>
          </template>
        </b-modal>

        <!-- ------------------------------------------------------------------------------------------[ Vendor Lookup Modal ]---->

        <b-modal class="modal-scoped fade" id="taskVendorLookupModal" v-model="taskVendorLookupModalShow" title="Find Vendor">

          <div style="">
            <live-search
              style="width:100%;"
              :searchItem="vendorSearchField"
              :searchMethod="vendorSearch"
              valueField="_id"
              displayField="name"
              @select="assignVendorToTask($event)"
              >
              <template v-slot:list-item="suggestion" >
                <div>
                  {{suggestion.name}} <span v-if="suggestion.primary.city">/ {{suggestion.primary.city }}</span><span v-if="suggestion.primary.state">, {{suggestion.primary.state}}</span>
                </div>
              </template>
            </live-search>
          </div>

          <template slot="modal-footer" slot-scope="{ cancel }">
            <b-button class="btn-fill" size="sm" variant="danger" @click="taskVendorLookupModalShow=false">
              Cancel
            </b-button>
            <b-button class="btn-fill" size="sm" variant="info" @click="removeVendorFromTask()">
              Remove Vendor
            </b-button>
          </template>
        </b-modal>

        <!-- -----------------------------------------------------------------------------[ Change Order Modal ]---->

        <b-modal class=" fade" centered  content-class="shadow"  size="xl" header-bg-variant="dark" header-text-variant="light" id="changeOrderModal" v-model="isEditChangeOrder" >

          <template #modal-header="{ close }">
            <h4 style="margin-top:0;" v-if="changeOrder">Change Order <span style="font-size:.85em;">: {{changeOrder.orderNumber}}</span></h4>
          </template>

          <change-order-edit
            :changeOrder="changeOrder"
          >
          </change-order-edit>
          <template slot="modal-footer" slot-scope="{ cancel }">
            <b-button class="btn-fill" size="sm" variant="success" @click="saveChangeOrder()">
              Done
            </b-button>
          </template>

        </b-modal>


        <!-- -----------------------------------------------------------------------------[ Attach Project Modal ]---->

        <b-modal class=" fade" centered  content-class="shadow"  size="xl" header-bg-variant="dark" header-text-variant="light" id="attachProjectModal" v-model="isEditAttachProject" >

          <template #modal-header="{ close }">
            <h4 style="margin-top:0;" >Attach Project</h4>
          </template>

          <sf-input type="list" label="Project" :options="masterBudgetProjectList" v-model="attachProjectId" @change="attachProject(attachProjectId)" />

          <template slot="modal-footer" slot-scope="{ cancel }">
            <b-button class="btn-fill" size="sm" variant="success" @click="attachProject(attachProjectId)">
              Attach This Project
            </b-button>
            <b-button class="btn-fill" size="sm" variant="danger" @click="isEditAttachProject=false">
              Cancel
            </b-button>
          </template>

        </b-modal>




        <!----------------------------------------------------------------------------------------------[ Begin Client Search Modal ]-->

        <b-modal class="modal-scoped fade" size="lg"  id="clientSearchModal" v-model="showSearch" :title="'Account Search'">

          <vue-simple-suggest v-model="user" style="" display-attribute="badgeTitle" value-attribute="badgeId" :list="doSubmitSearch" :debounce=200 :min-length=2 mode='select' @select="assignClient">

            <input placeholder="Type search here..." type="search">

            <div slot="suggestion-item" slot-scope="{suggestion}" style="padding:.5em 0 0 .5em;">
              <template v-if="suggestion.pictureUrl">
                <div style="line-height:64px;">
                  <img width="64" style="border-radius:2em;vertical-align:middle" :src="suggestion.pictureUrl">
<!--                  <span style="font-weight:700;">{{ (suggestion.badgeAlias.length>0) ? suggestion.badgeAlias: suggestion.badgeTitle }}</span>-->
<!--                  <span v-if="suggestion.roomNumber" style="font-size:.9em;font-weight:300;">/ Room #: {{ suggestion.roomNumber}}</span>-->
<!--                  <span v-else style="font-size:.9em;font-weight:300;"> / {{suggestion.accountTitle}}</span>-->
<!--                  <span v-if="searchMultipleClasses && suggestion.accountType" style="font-size:.9em;font-weight:300;">-->
<!--                    ({{suggestion.accountType || ""}} / {{suggestion.accountNumber}})</span>-->
                  <span style="margin-left:6px;font-weight:700;">{{ (suggestion.badgeAlias.length>0) ? suggestion.badgeAlias + ', ' + suggestion.accountTitle : suggestion.badgeTitle }}</span>
                  <span v-if="suggestion.roomNumber" style="font-size:.9em;font-weight:300;">/ Room #: {{ suggestion.roomNumber}}</span>
                  <span v-else style="font-size:.9em;font-weight:300;"> / {{suggestion.accountTitle}}</span>
                  <span v-if="searchMultipleClasses && suggestion.accountType" style="font-size:.9em;font-weight:300;">
                    ({{suggestion.accountType || ""}} / {{suggestion.accountNumber}})</span>
                </div>
              </template>

              <template v-if="!suggestion.pictureUrl">
                <div style="line-height:32px;">
<!--                  <img height="16" style="float:left;border-radius:2em;margin-right:3em;" src="">-->
<!--                  {{ suggestion }}-->
                  <span style="margin-left:70px;font-weight:700;">{{ (suggestion.badgeAlias.length>0) ? suggestion.badgeAlias + ', ' + suggestion.accountTitle : suggestion.badgeTitle  }}</span>
                  <span v-if="suggestion.roomNumber" style="font-size:.9em;font-weight:300;">/ Room #: {{ suggestion.roomNumber}}</span>
                  <span v-else style="font-size:.9em;font-weight:300;"> / {{suggestion.accountTitle}}</span>
                  <span v-if="searchMultipleClasses && suggestion.accountType" style="font-size:.9em;font-weight:300;">
                    ({{suggestion.accountType || ""}} / {{suggestion.accountNumber}})</span>
                </div>
              </template>
            </div>

          </vue-simple-suggest>

          <template slot="modal-footer" slot-scope="{ cancel }">
            <b-button class="btn-fill" size="sm" variant="danger" @click="showSearch=false">
              Cancel
            </b-button>
          </template>
        </b-modal>


      <!----------------------------------------------------------------------------------------------[ Begin Unit Search Modal ]-->

        <b-modal class="modal-scoped fade" size="lg" id="unitSearchModal" v-model="showUnitSearch" :title="'Unit Search'">
          <vue-simple-suggest v-model="unit" display-attribute="title" value-attribute="roomId" :list="doSubmitUnitSearch" :debounce=200 :min-length=1 mode='select' @select="assignUnit">

            <input placeholder="Unit search..." type="search">
            <div slot="suggestion-item" slot-scope="{suggestion}" style="">
              <template>
                <div style="line-height:32px;">
                  <img height="16" style="float:left;border-radius:2em;margin-right:3em;" src="">
                  <span style="font-weight:700;">{{ suggestion.title || "" }}</span>
                  <span v-if="suggestion.roomId" style="font-size:.9em;font-weight:300;">/ {{ suggestion.building.title}} {{roomDetails(suggestion)}}</span>
                  <span v-else style="font-size:.9em;font-weight:300;">/ {{suggestion.building.title}}</span>
                  <span v-if="suggestion.building.address" style="font-size:.9em;font-weight:300;">/ {{ suggestion.building.section}} ({{suggestion.building.category}}) [{{suggestion.building.address}}]</span>
                  <!-- <span style="font-size:.8em;font-weight:300;"> ({{suggestion.account.length }} Residents )</span> -->
                </div>
              </template>
            </div>

          </vue-simple-suggest>

          <template slot="modal-footer" slot-scope="{ cancel }">
            <b-button class="btn-fill" size="sm" variant="danger" @click="showUnitSearch=false">
              Cancel
            </b-button>
          </template>
        </b-modal>

        <!----------------------------------------------------------------------------------------------[ Begin Building Search Modal ]-->

        <b-modal class="modal-scoped fade" size="lg" id="buildingSearchModal" v-model="showBuildingSearch" :title="'Building Search'">
          <vue-simple-suggest v-model="building" display-attribute="title" value-attribute="building.building" :list="doSubmitBuildingSearch" :debounce=200 :min-length=1 mode='select' @select="assignBuilding">

            <input placeholder="Building search..." type="search">
            <div slot="suggestion-item" slot-scope="{suggestion}" style="">
              <template>
                <div style="line-height:32px;">
                  <img height="16" style="float:left;border-radius:2em;margin-right:3em;" src="">
                  <span style="font-weight:700;">{{ suggestion.title || "" }}</span>
                  <span v-if="suggestion.building.address" style="font-size:.9em;font-weight:300;">/ {{ suggestion.building.section}} ({{suggestion.building.category}}) [{{suggestion.building.address}}]</span>
                  <span v-else style="font-size:.9em;font-weight:300;">/ {{ suggestion.building.section}} ({{suggestion.building.category}})</span>
                  <!-- <span v-else style="font-size:.9em;font-weight:300;">/ {{suggestion.building.title}}</span> -->
                  <!-- <span style="font-size:.8em;font-weight:300;"> ({{suggestion.account.length }} Residents )</span> -->
                </div>
              </template>
            </div>

          </vue-simple-suggest>

          <template slot="modal-footer" slot-scope="{ cancel }">
            <b-button class="btn-fill" size="sm" variant="danger" @click="showBuildingSearch=false">
              Cancel
            </b-button>
          </template>
        </b-modal>


        <!--[ end Modal ]------------------------------------------------------------------------------------------- -->

        <div >
          <table class="" style="width:100%;border-style:solid;border:0;color:white;">
            <thead>
              <tr style="background-color:gray;">
                <td><span style="padding-left:5px;"><i style="font-size:12pt;color:white;" class="fa fa-info-circle show-hand" @click="toggleStatusHelp()"> </i> Status</span></td>
                <td v-if="ticketHasPriority()"><span style="padding-left:5px;">Priority</span></td>
                <td><span style="padding-left:5px;">Assigned</span></td>
                <td v-if="ticketHasServiceType()"><span style="padding-left:5px;">Service Type</span></td>
                <td><i v-if="ticketAllowsClone()" class="float-right mr-2 fa fa-clone show-hand" v-b-popover.hover.bottom="'Clone this ticket.'" @click="cloneTicket(ticket)"></i></td>
              </tr>
            </thead>
            <tr style="background-color:gray;">
              <td colspan=5></td>
            </tr>
            <tr style="background-color:lightgray;border:0;">
              <td style="">
                <span style="float:left;">
                  <b-dropdown :disabled="isReadOnly()" style="" size="sm" :style="statusStyle" class="m-1 " variant=" text-white" :text="ticket.status" v-model="ticket.status">
                    <b-dropdown-group header="Status Selection">
                      <b-dropdown-item @click="setStatus(status)" v-for="(status,index) in ticketStatuses" :key="index">{{status}}</b-dropdown-item>
                    </b-dropdown-group>
                  </b-dropdown>
                </span>
              </td>
              <td v-if="ticketHasPriority()">
                <span style="display:inline-block;float:left;">
                  <b-dropdown :disabled="isReadOnly()" style="" size="sm" :style="priorityStyle" class="m-1" variant=" text-white"
                    :text="ticket.priority" v-model="ticket.priority">
                    <b-dropdown-group header="Priority Selection">
                      <b-dropdown-item @click="setPriority(priority)" v-for="(priority,index) in ticketPriorities" :key="index">
                        {{priority}}
                      </b-dropdown-item>
                    </b-dropdown-group>
                  </b-dropdown>
                </span>
              </td>
              <td>
                <span style="display:inline-block;float:left;">
                  <b-dropdown :disabled="isReadOnly()" style="border-radius:.75em;background:gray;" size="sm" class="m-1" variant=" text-white" v-model="ticket.assignedTo" :text="ticket.assignedTo || '------'">
                    <template v-for="(user,index) in availableUsers()">
                      <b-dropdown-item v-if="canUserBeAssigned(user)" style="width:100%;" @click="assignTicket(user)" :key="index">
                        <!--                    <div><b-avatar variant="default" :src="getImage(user)" style="margin-right:1em;"></b-avatar><span style="font-size:1em;font-weight:700;">{{user.user.alias.trim()}} </span> <span style="font-size:.8em;font-weight:300;">{{user.user.email.trim()}}</span></div>-->
                        <div class="mt-0 mb-0">
                          <b-avatar variant="default" size="2rem" :src="getAccountImage(user)" style="margin-right:1em;"></b-avatar>
                          <span style="font-weight:600;">({{user.alias}}) {{user.lastName + ', ' + user.firstName }} </span>
                          <span v-if="user.email" style="font-size:.8em;font-weight:300;">({{(typeof user.notificationEmail !== 'undefined' && user.notificationEmail.trim().length > 0 ) ? user.notificationEmail : user.email }})</span>
                        </div>
                      </b-dropdown-item>
                    </template>
                  </b-dropdown>
                </span>
              </td>
              <td v-if="ticketHasServiceType()">
                <span style="display:inline-block;float:left;">
                  <b-dropdown :disabled="isReadOnly()" style="border-radius:.75em;" size="sm" class="m-1 bg-info" variant=" text-white" v-model="ticket.serviceType" :text="ticket.serviceType">
                    <b-dropdown-item @click="setServiceType(stype)" v-for="(stype,index) in ticketServiceTypes()" :key="index">
                      <span style="text-transform:capitalize;">{{ stype }}</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </span>
              </td>
              <td>
                <div v-if="ticket.status==='complete' && ticket.closed === false && ticket.resolution.length===0" class="shadow"
                  style="text-align:center;border:2px white solid;margin-right:1em;padding:.25em;border-radius:.75em;color:white;background:#ed5d4e;font-weight:700;">
                  Enter Resolution</div>
                <b-button
                  v-if="ticket.status==='complete' && ticket.closed === false && ticket.resolution.length>0 && (parseFloat(ticket.actual.hours) || 0) === 0 && this.$root.isInRole('tickets-close') && ticketCanClose()"
                  class="btn btn-sm btn-fill btn-danger m-1" size="sm" @click="closeCase()"><span style="font-size:1em;">Close Case Without Hours</span>
                </b-button>
                <b-button
                  v-if="ticket.status==='complete' && ticket.closed === false && ticket.resolution.length>0 && (parseFloat(ticket.actual.hours) || 0) !== 0 && this.$root.isInRole('tickets-close') && ticketCanClose()"
                  class="btn btn-sm btn-fill btn-success m-1" size="sm" @click="closeCase()"><span style="font-size:1em;font-weight:700;">Close
                    Case</span></b-button>
                <b-button v-if="ticket.closed===true && $root.isInRole('tickets-reopen')" size="sm" class="btn btn-sm btn-fill btn-warning m-1"
                  @click="reOpenCase()">
                  <span style="font-size:1em;">Re-Open Case</span>
                </b-button>
              </td>
            </tr>
            <tr style="background:lightgray;border:black;">
              <td colspan=5 style="margin:0;padding:2px;border:0;color:lightgray;vertical-align:middle;">
                <div style="">
                  <div style="float:left;">
                  <b-form-tags :disabled="isReadOnly()" style="width:35em;background:lightgray;" separator=" ,;" tag-pills @input="ticketSetChanged()" size="sm" :tag-validator="tagValidator" v-model="ticket.tags" ></b-form-tags>
                  </div>
                  <div style="float:right;">
                  <span style="width:10em;text-align:right;margin:10px .5em;color:black;">Preset Tags:</span>
                  <b-select size="sm" style="margin:8px;border:1px solid black;background:#eee;color:black;width:10em;" :options="tags" v-model="tagSelect" @change="ticket.tags.push(tagSelect);ticketSetChanged();">Tags</b-select>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan=5 style="background-color:gray;padding-top:1px;"></td>
            </tr>

          </table>
        </div>

        <b-toast id="status-toast" title="Ticket STATUS" static>
          <table class="table-sm">
            <thead style="background-color:gray;color:white;">
              <tr>
                <th style="width:100px;">STATE</th>
                <th>Description</th>
              </tr>
            </thead>
            <tr>
              <td>NEW</td>
              <td>New Ticket; this ticket is saying "look at me!"</td>
            </tr>
            <tr>
              <td>OPEN</td>
              <td>Open and in queue to be worked on at some point by someone</td>
            </tr>
            <tr>
              <td>IN-PROCESS</td>
              <td>Someone is working on this NOW</td>
            </tr>
            <tr>
              <td>COMPLETE</td>
              <td>This ticket is complete and ready to close</td>
            </tr>
            <tr>
              <td>STUCK</td>
              <td>Awaiting action/info from someone/something; This is an ACTION state</td>
            </tr>
            <tr>
              <td>PARKED</td>
              <td>Waiting for something in the future to happen, like awaiting parts; this is NOT an action state</td>
            </tr>
          </table>
        </b-toast>


        <b-tabs style="margin-top:1em;" class="tab-hover" active-nav-item-class="selected-tab" v-model="activeMainTab">
          <b-tab title="General">

            <div v-if="ticket" style="">
              <b-form @input="ticketSetChanged()"  style="padding-top:10px;height:100%;" v-on:submit.prevent>
                <b-row>
                  <b-col class="col-12" >

                    <div :style="isNotifyBeforeArrival() ? 'border-radius:.5em;background:rgba(228,155,15,.1);' : ''">
                       <sf-input :disabled="isReadOnly()" type="list" cstyle="width:15em;" :labelCols="2" label="Category" :options="ticketCategoryList()" v-model="ticket.category" @input="ticketSetChanged()" />
                      <div v-if="isNotifyBeforeArrival()" class="blink" style="color:firebrick;margin-left:17%;">Please notify before arrival</div>
                    </div>

                  </b-col>
                  <b-col class="col-12">

                    <sf-input :disabled="isReadOnly()" type="text" :labelCols="2" label="Summary" cstyle="overflow:hidden;text-overflow:ellipsis;" v-b-popover.hover.top="{content:ticket.title,delay:{show:(ticket.title && ticket.title.length>50) ? 750 : 20000,hide:100}}" helpText="Short description about the issue (elevator pitch)" v-model="ticket.title" />
                    <sf-input :readonly="isReadOnly()" type="textarea" :rows="descriptionRows" :labelCols="2" label="Details" v-model="ticket.description" />
                    <sf-input :readonly="isReadOnly()" v-if="ticket.status==='complete'" type="textarea" :cstyle="(ticket.resolution.length===0) ? 'background:#ffe3d9;' : '' " :rows=8  :labelCols="2" label="Resolution" v-model="ticket.resolution" />

                    <div v-if="ticket.status!=='complete' && ticketAllowsComments() && isCommentsOnMainScreen()" style="border:0;padding:0 2em 0 0;margin:0 0 0 16%;height:90%;" >

                      <h4><span><i class="fa fa-plus-square show-hand"  @click="askComment( null )" v-b-popover.hover.top="'Add a comment'"></i></span> Progress/Comments
                        <span style="font-size:.8em;">({{commentCount()}})</span>
                        <b-badge v-if="hasNewComment()" pill style="background:firebrick;font-size:.4em;position:relative;top:-7px;">new</b-badge>
                      </h4>

                      <div style="padding:0 1em;height:100%;width:100%;overflow:auto;" >
                        <!-- <b-table-simple small>
                          <b-tr v-for="(c,index) in actionComments()" :key="index" style="" >
                            <b-td style="vertical-align:top;">
                              <img :src="myGravatar(c.actionUserEmail)" :height="48" style="border-radius:50%;" :alt="c.actionUserEmail"  v-b-popover.hover.top="c.actionUserEmail || 'No email'"/>
                            </b-td>
                            <b-td>
                              <div style="vertical-align:top;width:100%;">
                                <span style="font-weight:600;font-size:.8em;padding-right:1em;">{{(c.actionUserAlias || "").trim()}}</span>
                                <span style="font-style:italic;font-weight:100;font-size:.7em;" v-b-popover.hover.left="moment(c.actionDate).format('LLL')">({{moment(c.actionDate).fromNow()}})</span>
                                <div v-if="c.hours!=0" style="float:right;padding:0 1em;border-radius:10px;background:black;color:white;text-align:center;font-size:.8em;font-weight:600;"> {{parseFloat(c.hours).toFixed(2)}} Hours</div>
                                <div style="padding-right:1em;white-space:pre-line;">{{(c.actionDescription || "").trim()}}</div>
                                <div style="margin-bottom:1em;"></div>
                              </div>
                            </b-td>
                          </b-tr>
                        </b-table-simple> -->
                        <template v-for="(action,tlIndex) in actionComments()" >
                          <vue-timeline-update
                            :key="tlIndex"
                            class="comment-timeline"
                            :date="new Date(action.actionDate)"
                            :title="action.actionUserAlias"
                            :description="action.actionDescription"
                            category="comment"
                            theme="dark"
                            icon="envelope"
                            color="black"
                          />
                        </template>


                      </div>
                    </div>
                 </b-col>
                </b-row>
              </b-form>
            </div>

          </b-tab>

          <b-tab v-if="ticketAllowsTasks()" :title="taskTitle()" class="noprint" id="taskTab">
            <div style="height:675px;margin:0 1em 0 1em;">

              <div v-if="!task">
                <div style="margin-top:1em;width:100%;display:inline-block;">
                  <i class="fa fa-plus-square show-hand" v-if="!isReadOnly()" style="float:left;font-size:1.5em;" @click="taskAdd( null )" v-b-popover.hover.top="'Add a task'"></i>
                  <h4 style="margin:0 0 0 2em;padding:0;" >Tasks / Steps</h4>
                  <div style="width:100%;display:flex;margin-bottom:.5em;" @change="taskOptionSelectionsChanged()">
                    <span style="font-size:.8em;margin-right:1em;"><b-checkbox v-model="taskOptions.showCompletedTasks">Show Completed </b-checkbox></span>
                    <span style="font-size:.8em;margin-right:1em;"><b-checkbox v-model="taskOptions.showBudgetYear">Show Budget Year</b-checkbox></span>
                    <span style="font-size:.8em;margin-right:1em;"><b-checkbox v-model="taskOptions.showTaskCategory">Show Category</b-checkbox></span>
                    <span style="font-size:.8em;margin-right:1em;"><b-checkbox v-model="taskOptions.showTaskDetails">Show Details</b-checkbox></span>
                    <span style="font-size:.8em;margin-right:1em;"><b-checkbox v-model="taskOptions.showTaskDescription">Show Descriptions</b-checkbox></span>
                    <span style="font-size:.8em;margin-right:1em;"><b-checkbox v-model="taskOptions.showTaskVendor">Show Vendors</b-checkbox></span>
                    <span v-if="ticketHasPayments()" style="font-size:.8em;"><b-checkbox v-model="taskOptions.showAllTasks">Show Payments</b-checkbox></span>
                  </div>
                </div>

                <template v-if="!taskOptions.showBudgetYear">
                  <div v-for="(gTaskList, budgetYear) in groupedTasks" :key="budgetYear">
                    <h5 style="margin:0;padding:.5em;background:black;color:white;">Budget Year: <span style="color:orange;font-size:1.25em;font-weigth:900;">{{ budgetYear }}</span></h5>
                    <task-list
                      :tasks="gTaskList"
                      :isDetails="taskOptions.showTaskDetails"
                      :isDescription="taskOptions.showTaskDescription"
                      :isVendor="taskOptions.showTaskVendor"
                      :isCategory="taskOptions.showTaskCategory"
                      :isProject="isProject()"
                      :isBudget="isBudget()"
                      :isShowAllTasks="taskOptions.showAllTasks"
                      :isShowBudgetYear="taskOptions.showBudgetYear"
                      :hourlyRate="parseFloat(ticket.hourlyRate)"
                      :budgetYear="ticket.budgetYear"
                      :projectList="ticket.projects.map((p) => { return {text: p.title, value: p.id, id: p.id}})"
                      @edit="taskEdit($event)"
                      @vendorEdit="vendorEdit($event)"
                      @clone="taskClone($event)"
                      @remove="taskRemove($event)"
                      @complete="taskMarkComplete($event)"
                      @incomplete="taskMarkIncomplete($event)"
                    />
                  </div>
                </template>

                <template v-if="taskOptions.showBudgetYear">
                  <task-list
                    :tasks="ticketTaskList"
                    :isDetails="taskOptions.showTaskDetails"
                    :isDescription="taskOptions.showTaskDescription"
                    :isVendor="taskOptions.showTaskVendor"
                    :isCategory="taskOptions.showTaskCategory"
                    :isProject="isProject()"
                    :isBudget="isBudget()"
                    :isShowAllTasks="taskOptions.showAllTasks"
                    :isShowBudgetYear="taskOptions.showBudgetYear"
                    :hourlyRate="parseFloat(ticket.hourlyRate)"
                    :budgetYear="ticket.budgetYear"
                    :projectList="ticket.projects.map((p) => { return {text: p.title, value: p.id, id: p.id}})"
                    @edit="taskEdit($event)"
                    @vendorEdit="vendorEdit($event)"
                    @clone="taskClone($event)"
                    @remove="taskRemove($event)"
                    @complete="taskMarkComplete($event)"
                    @incomplete="taskMarkIncomplete($event)"
                  />
                </template>

                <div v-if="!isReadOnly()">
                  Task Quick Add <span style="font-size:.8em;color:lightgray;">(Type the tile and press ENTER)</span>
                  <b-input label="Quick Add" ref="task-quick-add" type="text" v-model="taskQuickAddTitle" @keyup.enter.native="taskQuickAddChanged()" />
                </div>
              </div>

              <div v-if="task" style="margin-top:1em;" >
                <task-edit
                  :task="task"
                  :taskList="ticket.steps.map((t) => { return {text: t.stepId + ' ' + t.title, value: t.stepId, taskType: t.taskType}})"
                  :ticketNumber="ticket.ticketNumber"
                  :title="ticket.title"
                  :categories="taskCategoryList()"
                  :enableBudgetEdit="canEditTaskBudget()"
                  :isBudget="isBudget()"
                  :isProject="isProject()"
                  :isEditable="task.stepType==='task'"
                  :budgetYear="ticket.budgetYear"
                  :budgetYears="budgetYears()"
                  :projectList="ticket.projects.map((p) => { return {text: p.title, value: p.id}})"
                  @changed="ticketSetChanged()"
                  @save="taskSave($event)"
                  @done="taskSave($event)"
                  @addChangeOrder="addChangeOrder($event)"
                >
                </task-edit>

                <template v-if="isChangeOrders()">
                  <div>
                    <i class="fa fa-plus" v-if="!isReadOnly()" style="float:left;margin-right:.5em;font-size:1.5em;color:green;cursor:pointer;" v-b-popover.hover.top="'Add a new change order'" @click="addChangeOrder(task)"></i>
                    <h4>Change Orders</h4>
                  </div>
                </template>
                <template v-if="ticket.changeOrders && ticket.changeOrders.length>0"  >
                  <change-order-card
                    v-for="(c,index) in ticket.changeOrders.filter((c) => {return (c.step && c.step.id) ? c.step.id == task._id.toString() : false})"
                    :key         = "index"
                    :keyIndex    = "index"
                    :changeOrder = "c"
                    stepPrompt   = "For Task: "
                    @edit        = "editChangeOrder($event)"
                    @delete      = "deleteChangeOrder($event)"
                  />
                </template>


              </div>

            </div>

          </b-tab>

<!-- v-if="isProject()"  -->
          <b-tab v-if="ticket && isFinancials()" title="Financial" class="noprint" @click="getManualTransactions( ticket._id )">


<!--            <b-modal ref="transaction-receipt" title="Receipt" size="lg" style="padding:0 1em 0 1em;" scrolable ok-only @click="$refs['transaction-receipt'].hide();transaction=null;" >-->
<!--              <div v-if="transaction" class="receipt-format1">-->
<!--                <div v-for="(r,rindex) in transaction.receipt" :key="rindex">{{r}}</div>-->
<!--              </div>-->
<!--            </b-modal>-->

            <b-modal ref="manual-transaction" size="xl" style="padding:0 1em 0 1em;" scrolable hide-header hide-footer @click="$refs['manual-transaction'].hide();">
                <manual-transaction-form
                  :title="ticketTypeTitleEx(ticket.ticketType) + ' Transaction'"
                  :postingAccount="postingAccount"
                  :postingTicket ="ticket"
                  :postingTotal="postingTotal"
                  :accountClasses="allowedAccountClasses"
                  :transactionSource="'helpdesk-ticket'"
                  :transactionOwnerId="ticket._id"
                  :acceptedTotalClasses="allowedTotalClasses"
                  :acceptedRevenueCenters="allowedRevenueCenters"
                  :acceptedTenderNumbers="allowedTenderNumbers"
                  :isAllowTransactionTypeSelect="false"
                  :defaultTransactionType="'payment'"
                  @cancel="cancelTransaction"
                  @posted="postedTransaction"
                />
            </b-modal>

            <div style="width:100%;margin-top:1em;">

              <template v-if="isBudget() && !isProject()">
                <h4 style="margin:0;padding:0;">Financials & Budget</h4>
                <div style="display:flex;justify-content:center;font-size:.9em;padding:.25em;">
                  <b-checkbox style=""                  switch v-model="isShowBudgetGL" @input="toggleShowBudgetGL">Show GL Fields</b-checkbox>
                  <b-checkbox style="margin-left:1em;" switch v-model="isShowOriginalBudget" @input="toggleShowOriginalBudget">Show Starting Budget</b-checkbox>
                  <b-checkbox style="margin-left:1em;" switch v-model="isShowCurrentBudget" @input="toggleShowCurrentBudget">Show Adjusted Budget</b-checkbox>
                  <b-checkbox style="margin-left:1em;" switch  v-model="isShowCharts" @input="toggleShowCharts">Show Charts</b-checkbox>
                </div>
<!--                <p style="margin:1em 0 0 0;width:85%;text-align:left;font-size:.9em;font-decoration:italic;color:indianred;">-->
<!--                  <span style="font-weight:800;" >NOTE:</span>-->
<!--                  BUDGET values are locked once financial data is entered. (use change orders to alter budgets after that)<br>-->
<!--                </p>-->

              </template>
              <template v-else>
                <h4>Financials</h4>
                <b-checkbox style="float:right;top:-30px;margin-right:1em;" switch  v-model="isShowCharts" @input="toggleShowCharts">Show Charts</b-checkbox>
              </template>

              <div v-if="isShowCharts" class="hide-too-small" style="width:100%;display:inline-flex;">
                <pie-widget
                  v-if="isBudget()"
                  style="width:30%;max-width:275px;;margin-right:1em;"
                  title="Budget Distribution"
                  chartColor="lightgray"
                  :cutoutPercentage="60"
                  :chartLabels="['Labor','Parts','Supplies','Expenses','Outside']"
                  :chartData="[parseFloat(ticket.adjustedBudget.labor)+(parseFloat(ticket.adjustedBudget.hours)*parseFloat(ticket.hourlyRate)), ticket.adjustedBudget.parts, ticket.adjustedBudget.supplies,ticket.adjustedBudget.expenses,ticket.adjustedBudget.outside]"
                  :options="chartOptions"
                />
                <pie-widget
                  style="width:30%;max-width:275px;margin-right:1em;"
                  title="Actual Distribution"
                  chartColor="lightgray"
                  :cutoutPercentage="60"
                  :chartLabels="['Labor','Parts','Supplies','Expenses','Outside']"
                  :chartData="[parseFloat(ticket.actual.labor)+(parseFloat(ticket.actual.hours)*parseFloat(ticket.hourlyRate)), ticket.actual.parts, ticket.actual.supplies,ticket.actual.expenses,ticket.actual.outside]"
                  :options="chartOptions"
                />
                <pie-widget
                  v-if="isBudget()"
                  style="width:30%;max-width:275px;margin-right:1em;"
                  title="Internal/External"
                  chartColor="lightgray"
                  :chartDataColors="['darkgreen','orange','#0000ff']"
                  :cutoutPercentage="60"
                  :chartLabels="['Inside','Outside']"
                  :chartData="[parseFloat(ticket.adjustedBudget.labor)+(parseFloat(ticket.adjustedBudget.hours)*parseFloat(ticket.hourlyRate))+parseFloat(ticket.adjustedBudget.parts)+parseFloat(ticket.adjustedBudget.supplies)+parseFloat(ticket.adjustedBudget.expenses),ticket.adjustedBudget.outside]"
                  :options="chartOptions"
                />
              </div>


<!--/*              <div style="float:right;text-align:right;padding-top:.5em;margin:0 0 .5em 0;">*/-->
<!--                <div style="position:relative;right:2em;background:lime;">-->
<!--                  <sf-input :labelCols="4" :disabled="isReadOnly()" label="Hourly Rate" cstyle="font-weight:700;width:9em;border:2px solid #9af6eb;border-radius:.5em;text-align:right;" labelStyle="font-weight:700;font-size:1.1em;width:400px;" type="comma" isCurrency v-model="ticket.hourlyRate" @change="hourlyRateChanged()" />-->
<!--                </div>-->
<!--              </div>-->

              <template v-if="isProject() && taskBudgetTotals" v-for="(current,index) in taskBudgetTotals">
                <div style="width:100%;overflow:auto;margin-bottom:1em;">
                  <table @input="ticketSetChanged()" class="taskGridTable" style="border:2px solid lightgray;margin:auto;">
                    <thead>
                      <th style="text-align:center;color:orange;">{{current.budgetYear}}</th>
                      <th># Hours</th>
                      <th>Hourly $</th>
                      <th>Fixed Labor</th>
                      <th>Parts</th>
                      <th>Supplies</th>
                      <th>Expenses</th>
                      <th>Outside</th>
                      <th>Payments</th>
                      <!-- <th>Deposits</th> -->
                      <th>Total</th>
                    </thead>  
                    <tbody>
                      <tr>
                        <td>BUDGET</td>
                        <td>{{current.budget.hours}}</td>
                        <td>{{USDnz(current.budget.hourlyLabor) }}</td>
                        <td>{{USDnz(current.budget.labor)}}</td>
                        <td>{{USDnz(current.budget.parts)}}</td>
                        <td>{{USDnz(current.budget.supplies)}}</td>
                        <td>{{USDnz(current.budget.expenses) }}</td>
                        <td>{{USDnz(current.budget.outside)}}</td>
                        <td></td>
                        <!-- <td></td> -->
                        <td>{{USDnz(current.budget.total)}}</td>
                      </tr> 
                      <tr>
                        <td>ACTUAL</td>
                        <td>{{current.actual.hours}}</td>
                        <td>{{USDnz(current.actual.hourlyLabor) }}</td>
                        <td>{{USDnz(current.actual.labor)}}</td>
                        <td>{{USDnz(current.actual.parts)}}</td>
                        <td>{{USDnz(current.actual.supplies)}}</td>
                        <td>{{USDnz(current.actual.expenses) }}</td>
                        <td>{{USDnz(current.actual.outside)}}</td>
                        <td style="color:#ccc;">{{USDnz(current.actual.payments)}}</td>
                        <!-- <td>{{USDnz(current.actual.deposits)}}</td> -->
                        <td>{{USDnz(current.actual.total)}}</td>
                      </tr> 
                    </tbody>
                    <tfoot>
                      <tr>
                        <td>AVAILABLE</td>
                        <td>{{current.budget.hours-current.actual.hours}}</td>
                        <td>{{USDnz(currency(current.budget.hourlyLabor).subtract(current.actual.hourlyLabor).value) }}</td>
                        <td>{{USDnz(current.budget.labor-current.actual.labor)}}</td>
                        <td>{{USDnz(current.budget.parts-current.actual.parts)}}</td>
                        <td>{{USDnz(current.budget.supplies-current.actual.supplies)}}</td>
                        <td>{{USDnz(current.budget.expenses-current.actual.expenses) }}</td>
                        <td>{{USDnz(current.budget.outside-current.actual.outside)}}</td>
                        <!-- <td>{{USD(currency(current.budget.payments).subtract(current.actual.payments))}}</td> -->
                        <!-- <td>{{USD(currency(current.budget.deposits).subtract(current.actual.deposits))}}</td> -->
                         <td></td>
                         <!-- <td></td> -->
                        <td>{{USD(current.budget.total-current.actual.total)}}</td>
                      </tr> 
                    </tfoot>
                  </table>  
                </div>  
              </template> 

              <div v-if="!isProject()" style="width:100%;overflow:auto;">
                <table @input="ticketSetChanged()" style="border:2px solid lightgray;margin:auto;">

                  <tr style="background:darkslategray;color:white;">
                    <th style="padding:1.5em;"></th>
                    <template v-if="isBudget()">
                      <th v-if="isShowBudgetGL"  style="text-align:right;padding-right:1em;">GL Account #</th>
                      <th v-if="isShowOriginalBudget" style="text-align:right;padding-right:1em;">Starting Budget</th>
                      <th v-if="isShowCurrentBudget" style="text-align:right;padding-right:1em;">Adjusted Budget</th>
                    </template>
                    <th v-if="ticket.steps.length>0" style="text-align:right;padding-right:1em;">Tasks Actual</th>
                    <th v-if="!isProject()" style="text-align:right;padding-right:1em;">
                      <span v-if="ticket.steps.length>0" >+/- Adjust</span>
                      <span v-if="ticket.steps.length===0" >Actual</span>
                    </th>
                    <th style="text-align:right;padding-right:1em;">TOTAL</th>
                  </tr>

                  <tr style="border-bottom:8px solid rgba(136, 168, 123, 0.28);background:rgba(136, 168, 123, 0.28);">
                    <td style="text-align:right;padding-right:.5em;">Hourly Rate</td>
                    <template v-if="isBudget()">
                      <td v-if="isShowBudgetGL" ></td>
                      <td v-if="isShowOriginalBudget"></td>
                      <td v-if="isShowCurrentBudget"></td>
                    </template>
                    <td v-if="ticket.steps.length>0"></td>
                    <td v-if="!isProject()" ></td>
                    <td> <sf-input :labelCols="0" type="comma" isCurrency cstyle="width:9em;text-align:right;" v-model="ticket.hourlyRate" :disabled="ticket.posted || isReadOnly() " @change="calculateTicketTotals()" /></td>
                  </tr>

                  <tr style="background:rgba(136, 168, 123, 0.68);">
                    <td style="text-align:right;padding-right:.5em;"># Hours</td>
                    <template v-if="isBudget()">
                      <td v-if="isShowBudgetGL" style="text-align:left;margin:0;padding:0;"><sf-input :labelCols="0" type="text" :disabled="isReadOnly()"  cstyle="width:12em;" v-model="ticket.glAccount.hourly"/></td>
                      <td v-if="isShowOriginalBudget"><sf-input :labelCols="0" type="number" cstyle="width:9em;text-align:right;" v-model="ticket.estimatedHours" :disabled="ticket.actual.hours!=0 || ticket.changeOrders.length>0 || ticket.steps.length>0 || isReadOnly()" @change="calculateChangeOrderTotals()" />
                      </td>
                      <td v-if="isShowCurrentBudget"><sf-input :labelCols="0" type="number" cstyle="width:9em;text-align:right;" v-model="ticket.adjustedBudget.hours" :disabled="true" />
                      </td>
                    </template>
                    <td v-if="ticket.steps.length>0" ><sf-input :labelCols="0" type="number" cstyle="width:9em;text-align:right;" v-model="taskTotals.hours" :disabled="true" />
                    </td>
                    <td v-if="!isProject()" >
                      <sf-input :labelCols="0" type="number"  cstyle="width:9em;text-align:right;" v-model="ticket.hours" :disabled="ticket.posted || isReadOnly()"  @change="calculateTicketTotals()"/>
                    </td>
                    <td>
                      <div style="text-align:right;padding-right:.5em;" :style="(ticket.actual.hours>ticket.adjustedBudget.hours && isBudget()) ? 'color:maroon':''" ><span style="margin-right:.5em;font-size:.8em;">(hours)</span>{{USD(ticket.actual.hours)}}</div>
                    </td>
                  </tr>

                  <tr style="border-top:3px transparent;background:rgba(136, 168, 123, 0.28);">
                    <td style="text-align:right;padding-right:.5em;">Hourly Labor</td>
                    <template v-if="isBudget()">
                      <td v-if="isShowBudgetGL" style="text-align:left;margin:0;padding:0;">&nbsp;</td>
                      <td v-if="isShowOriginalBudget">
                        <div style="text-align:right;width:9em;padding:.5em;font-weight:200;font-style:italic;" >{{ USD(currency(ticket.hourlyRate).multiply(ticket.estimatedHours).value)}}</div>
                      </td>
                      <td v-if="isShowCurrentBudget">
                      <div style="text-align:right;width:9em;padding:.5em;font-weight:200;font-style:italic;" >{{ USD(currency(ticket.hourlyRate).multiply(ticket.adjustedBudget.hours).value)}}</div>
                      </td>
                    </template>
                    <td v-if="ticket.steps.length>0" >
                      <div style="text-align:right;width:9em;padding:.5em;font-weight:200;font-style:italic;" >{{ USD(currency(ticket.hourlyRate).multiply(taskTotals.hours).value)}}</div>
                    </td>
                    <td v-if="!isProject()" >
                      <div style="text-align:right;width:9em;padding:.5em;font-weight:200;font-style:italic;" >{{ USD(currency(ticket.hourlyRate).multiply(ticket.hours).value)}}</div>
                    </td>
                    <td>
                      <div style="text-align:right;width:9em;padding:.5em;font-weight:700;font-style:italic;" >{{ USD(currency(ticket.hourlyRate).multiply(ticket.actual.hours).value)}}</div>
                    </td>
                  </tr>


                  <tr style="border-top:3px solid darkslategray;">
                    <td style="text-align:right;padding-right:.5em;">Fixed Labor</td>
                    <template v-if="isBudget()">
                      <td v-if="isShowBudgetGL" style="text-align:left;margin:0;padding:0;"><sf-input :labelCols="0" type="text"  :disabled="isReadOnly()"  cstyle="width:12em;" v-model="ticket.glAccount.labor"/></td>
                      <td v-if="isShowOriginalBudget"><sf-input :labelCols="0" type="comma" isCurrency cstyle="width:9em;text-align:right;" v-model="ticket.estimatedLabor" :disabled="ticket.actual.labor!=0  || ticket.changeOrders.length>0 || ticket.steps.length>0 ||  isReadOnly()" @change="calculateChangeOrderTotals()" /></td>
                      <td v-if="isShowCurrentBudget"><sf-input :labelCols="0" type="comma" isCurrency cstyle="width:9em;text-align:right;" v-model="ticket.adjustedBudget.labor" :disabled="true" /></td>
                    </template>
                    <td v-if="ticket.steps.length>0" ><sf-input :labelCols="0" type="comma" isCurrency cstyle="width:9em;text-align:right;" v-model="taskTotals.labor" :disabled="true" /></td>
                    <td v-if="!isProject()" ><sf-input :labelCols="0" type="comma" isCurrency cstyle="width:9em;text-align:right;" v-model="ticket.laborTotal" :disabled="ticket.posted || isReadOnly() " @change="calculateTicketTotals()" /></td>
                    <td><div style="width:9em;text-align:right;padding:1em .75em;font-weight:700;" :style="(ticket.actual.labor>ticket.adjustedBudget.labor && isBudget()) ? 'color:maroon;':''" >{{USD(ticket.actual.labor)}}</div></td>
                  </tr>

                  <tr style="border-top:1px dashed lightgray;">
                    <td style="text-align:right;padding-right:.5em;">Parts</td>
                    <template v-if="isBudget()">
                      <td v-if="isShowBudgetGL" style="text-align:left;margin:0;padding:0;"><sf-input :labelCols="0" type="text" :disabled="isReadOnly()" cstyle="width:12em;" v-model="ticket.glAccount.parts"/></td>
                      <td v-if="isShowOriginalBudget"><sf-input :labelCols="0" type="comma" isCurrency cstyle="width:9em;text-align:right;" v-model="ticket.estimatedParts" :disabled="(ticket.actual.parts!=0) || ticket.changeOrders.length>0 || ticket.steps.length>0 ||  isReadOnly()" @change="calculateChangeOrderTotals()" /></td>
                      <td v-if="isShowCurrentBudget"><sf-input :labelCols="0" type="comma" isCurrency cstyle="width:9em;text-align:right;" v-model="ticket.adjustedBudget.parts" :disabled="true" /></td>
                    </template>
                    <td v-if="ticket.steps.length>0" ><sf-input :labelCols="0" type="comma" isCurrency cstyle="width:9em;text-align:right;" v-model="taskTotals.parts" :disabled="true" /></td>
                    <td v-if="!isProject()" ><sf-input :labelCols="0" type="comma" isCurrency cstyle="width:9em;text-align:right;" v-model="ticket.partsTotal" :disabled="ticket.posted || isReadOnly() " @change="calculateTicketTotals()" /></td>
                    <td><div style="width:9em;text-align:right;padding:1em .75em;font-weight:700;" :style="(ticket.actual.parts>ticket.adjustedBudget.parts && isBudget()) ? 'color:maroon;':''" >{{USD(ticket.actual.parts)}}</div></td>
                  </tr>

                  <tr style="border-top:1px dashed lightgray;">
                    <td style="text-align:right;padding-right:.5em;">Supplies</td>
                    <template v-if="isBudget()">
                      <td v-if="isShowBudgetGL" style="text-align:left;margin:0;padding:0;"><sf-input :labelCols="0" type="text" :disabled="isReadOnly()" cstyle="width:12em;" v-model="ticket.glAccount.supplies"/></td>
                      <td v-if="isShowOriginalBudget"><sf-input :labelCols="0" type="comma" isCurrency cstyle="width:9em;text-align:right;" v-model="ticket.estimatedSupplies" :disabled="ticket.actual.supplies!=0  || ticket.changeOrders.length>0 || ticket.steps.length>0 || isReadOnly()" @change="calculateChangeOrderTotals()" /></td>
                      <td v-if="isShowCurrentBudget"><sf-input :labelCols="0" type="comma" isCurrency cstyle="width:9em;text-align:right;" v-model="ticket.adjustedBudget.supplies" :disabled="true" /></td>
                    </template>
                    <td v-if="ticket.steps.length>0" ><sf-input :labelCols="0" type="comma" isCurrency cstyle="width:9em;text-align:right;" v-model="taskTotals.supplies" :disabled="true" /></td>
                    <td v-if="!isProject()" ><sf-input :labelCols="0" type="comma" isCurrency cstyle="width:9em;text-align:right;" v-model="ticket.supplyTotal" :disabled="ticket.posted || isReadOnly() " @change="calculateTicketTotals()" /></td>
                    <td><div style="width:9em;text-align:right;padding:1em .75em;font-weight:700;" :style="(ticket.actual.supplies>ticket.adjustedBudget.supplies && isBudget()) ? 'color:maroon;':''" >{{USD(ticket.actual.supplies)}}</div></td>
                  </tr>

                  <tr style="border-top:1px dashed lightgray;">
                    <td style="text-align:right;padding-right:.5em;">Expenses</td>
                    <template v-if="isBudget()">
                      <td v-if="isShowBudgetGL" style="text-align:left;margin:0;padding:0;"><sf-input :labelCols="0" type="text" :disabled="isReadOnly()" cstyle="width:12em;" v-model="ticket.glAccount.expenses"/></td>
                      <td v-if="isShowOriginalBudget"><sf-input :labelCols="0" type="comma" isCurrency cstyle="width:9em;text-align:right;" v-model="ticket.estimatedExpenses" :disabled="ticket.actual.expenses!=0  || ticket.changeOrders.length>0 || ticket.steps.length>0 ||  isReadOnly()" @change="calculateChangeOrderTotals()" /></td>
                      <td v-if="isShowCurrentBudget"><sf-input :labelCols="0" type="comma" isCurrency cstyle="width:9em;text-align:right;" v-model="ticket.adjustedBudget.expenses" :disabled="true" /></td>
                    </template>
                    <td v-if="ticket.steps.length>0" ><sf-input :labelCols="0" type="comma" isCurrency cstyle="width:9em;text-align:right;" v-model="taskTotals.expenses" :disabled="true" /></td>
                    <td v-if="!isProject()" ><sf-input :labelCols="0" type="comma" isCurrency cstyle="width:9em;text-align:right;" v-model="ticket.expenseTotal" :disabled="ticket.posted || isReadOnly() " @change="calculateTicketTotals()" /></td>
                    <td><div style="width:9em;text-align:right;padding:1em .75em;font-weight:700;" :style="(ticket.actual.expenses>ticket.adjustedBudget.expenses && isBudget()) ? 'color:maroon;':''" >{{USD(ticket.actual.expenses)}}</div></td>
                  </tr>

                  <tr style="border-top:1px dashed lightgray;">
                    <td style="text-align:right;padding-right:.5em;">Outside Expenses</td>
                    <template v-if="isBudget()">
                      <td v-if="isShowBudgetGL" style="text-align:left;margin:0;padding:0;"><sf-input :labelCols="0" type="text" :disabled="isReadOnly()" cstyle="width:12em;" v-model="ticket.glAccount.outside"/></td>
                      <td v-if="isShowOriginalBudget"><sf-input :labelCols="0" type="comma" isCurrency cstyle="width:9em;text-align:right;" v-model="ticket.estimatedOutsideTotal" :disabled="ticket.actual.outside!=0  || ticket.changeOrders.length>0 || ticket.steps.length>0 ||  isReadOnly()" @change="calculateChangeOrderTotals()" /></td>
                      <td v-if="isShowCurrentBudget"><sf-input :labelCols="0" type="comma" isCurrency cstyle="width:9em;text-align:right;" v-model="ticket.adjustedBudget.outside" :disabled="true" /></td>
                    </template>
                    <td v-if="ticket.steps.length>0" ><sf-input :labelCols="0" type="comma" isCurrency cstyle="width:9em;text-align:right;" v-model="taskTotals.outside" :disabled="true" /></td>
                    <td v-if="!isProject()" ><sf-input :labelCols="0" type="comma" isCurrency cstyle="width:9em;text-align:right;" v-model="ticket.outsideTotal" :disabled="ticket.posted || isReadOnly() " @change="calculateTicketTotals()" /></td>
                    <td><div style="width:9em;text-align:right;padding:1em .75em;font-weight:700;" :style="(ticket.actual.outside>ticket.adjustedBudget.outside && isBudget()) ? 'color:maroon;':''" >{{USD(ticket.actual.outside)}}</div></td>
                  </tr>

                  <tr style="border-top:3px solid lightgray;background:#eee;">
                    <td style="text-align:right;padding-right:.5em;">Totals</td>
                    <template v-if="isBudget()">
                      <td v-if="isShowBudgetGL" style="text-align:left;margin:0;padding:0;">&nbsp;</td>
                      <td v-if="isShowOriginalBudget"><div style="width:9em;text-align:right;padding:1em .75em;font-weight:700;">{{USD(ticket.estimatedTotal)}}</div> </td>
                      <td v-if="isShowCurrentBudget"><div style="width:9em;text-align:right;padding:1em .75em;font-weight:700;">{{USD(ticket.adjustedBudget.total)}}</div></td>
                    </template>
                    <td v-if="ticket.steps.length>0" ><div style="width:9em;text-align:right;padding:1em .75em;font-weight:700;">{{USD(taskTotals.total)}}</div></td>
                    <td v-if="!isProject()" ><div style="width:9em;text-align:right;padding:1em .75em;font-weight:700;">{{USD(currency(ticket.laborTotal).add(ticket.partsTotal).add(ticket.supplyTotal).add(ticket.expenseTotal).add(ticket.outsideTotal).add(currency(ticket.hours).multiply(ticket.hourlyRate)).value)}}</div></td>
                    <td><div style="width:9em;text-align:right;padding:1em .75em;font-weight:700;" :style="(ticket.actual.total>ticket.adjustedBudget.total && isBudget()) ? 'color:maroon;':''" >{{USD(ticket.actual.total)}}</div></td>
                  </tr>

                </table>
                <p v-if="ticket.actual.hours && ticket.hourlyRate != 0" style="margin:auto;text-align:center;font-size:.9em;font-decoration:italic;color:gray;">** Total includes (hours x hourly rate) in addition to the fixed labor.</p>
              </div>


              <template v-if="isChangeOrders()">
                  <div style="margin-top:.75em;">
                    <i class="fa fa-plus" v-if="!isReadOnly() && ticket.steps.length===0" style="float:left;margin-right:.5em;font-size:1.5em;color:green;cursor:pointer;" v-b-popover.hover.top="'Add a new change order'" @click="addChangeOrder(null)"></i>
                    <h4 style="margin:0;padding:0;">Change Orders</h4>
                  </div>
              </template>
              <template v-if="ticket.changeOrders && ticket.changeOrders.length>0">
                <div v-for="(c,index) in ticket.changeOrders"  style="display:inline-block;vertical-align: top;" :key = "index">
                  <change-order-card
                    :key         = "index"
                    :keyIndex    = "index"
                    :changeOrder = "c"
                    stepPrompt   = "For Task: "
                    :enableEditTask="true"
                    @editTask    = "taskEdit(ticket.steps.find(s=>s._id.toString()==$event))"
                    @edit        = "editChangeOrder($event)"
                    @delete      = "deleteChangeOrder($event)"
                  />
                  </div>
              </template>

              <b-row>
                <b-col>
                </b-col>
              </b-row>

              <div>
                <template v-if="ticketAllowsManualTransactions && !ticket.closed">
                  <hr>
                  <b-button  @click="transactionAdd()" class="btn btn-sm btn-success btn-fill">Add Payment/Charge</b-button>
                </template>

<!--                {-->
<!--                "_id": "6612e635193b707ff64620fe",-->
<!--                "totalClassId": 20,-->
<!--                "accountNumber": "W111",-->
<!--                "badgeNumber": "wb-111",-->
<!--                "alias": "Wolfie",-->
<!--                "title": "Manual Transaction",-->
<!--                "tenderNumber": 200,-->
<!--                "tenderAmount": 13.11,-->
<!--                "tenderQty": 1,-->
<!--                "isPayment": true,-->
<!--                "isMealPlan": false,-->
<!--                "revenueCenter": 9000,-->
<!--                "transactionDate": "2024-04-07T19:00:00.000Z",-->
<!--                "transactionNumber": "240407142901",-->
<!--                "checkNumber": "240407142901",-->
<!--                "transactionTotal": 13.11-->
<!--                }-->
                <template v-if="transactionList.length>0">
                  <h4>Transactions</h4>
                  <table class="table table-sm  mt-2 w-100">
                    <thead>
                      <tr>
                        <td>Date</td>
                        <td>Type</td>
                        <td>Account #</td>
                        <td>Alias</td>
                        <td>Transaction</td>
                        <td>Amount</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(trans,index) in transactionList" :key="index">
                        <td>{{ moment(trans.transactionDate).tz($root.TZ).format("LLLL")}}</td>
                        <td>{{ trans.isPayment ? 'Pmt' : 'Chg' }}</td>
                        <td>{{ trans.accountNumber }}</td>
                        <td>{{ trans.alias }}</td>
                        <td>{{ trans.title }}</td>
                        <td>{{ USD( trans.tenderAmount )}}</td>
                      </tr>
                    </tbody>
                  </table>
                </template>
              </div>

            </div>
          </b-tab>

          <b-tab v-if="isProject()" title="Project" class="noprint" id="projectTab">
            <div style="height:675px;margin:0 2em 0 2em;">
              <b-form class="mt-4"  @input="ticketSetChanged()">
                <!-- <sf-input :disabled="isReadOnly()" type="text" cstyle="width:15em;" :labelCols="2" label="Section" v-model="ticket.section" /> -->
                <sf-input :disabled="isReadOnly()" type="text" cstyle="width:15em;" :labelCols="2" label="Cost Code" v-model="ticket.costCode" />
                <sf-input :disabled="isReadOnly()" type="comma" isCurrency :labelCols="2" cstyle="width:15em;" label="Hourly Rate" v-model="ticket.hourlyRate" @change="hourlyRateChanged()" />
                <br>
                <template v-if="$root.isFeature('projects')">
                  <sf-input :disabled="isReadOnly()" type="list" cstyle="width:30em;" :options="masterProjectList" :labelCols="2" label="Capital Project"  @change="setMasterProject(ticket.projectId)" v-model="ticket.projectId" />
                  <sf-input :disabled="isReadOnly()" type="list" cstyle="width:15em;text-transform:capitalize;" :labelCols="2" label="Project Priority" :options="projectPriorityList()" v-model="ticket.projectPriority" @input="ticketSetChanged()" />

<!--                  <sf-input :disabled="!$root.isAdmin()" type="list" cstyle="width:15em;" :options="masterProjectList.map((p) => {return p.projectCategory })"   :labelCols="2" label="Budget Category" v-model="ticket.projectCategory" />-->
                  <br>
                  <sf-input :disabled="isReadOnly()" type="date" :labelCols="2" cstyle="width:12em;" label="Scheduled Begin" v-model="ticket.scheduledDate" :helpText="(ticket.scheduledDate) ? moment(ticket.scheduledDate).fromNow().toString() : ''"/>
                  <sf-input :disabled="isReadOnly()" type="date" :labelCols="2" cstyle="width:12em;" label="Due/Desired By" v-model="ticket.dueDate" :helpText="(ticket.dueDate) ? moment(ticket.dueDate).fromNow().toString() : ''" />
                  <sf-input :disabled="isReadOnly()" type="list" :labelCols="2" cstyle="width:12em;" label="Project Year" :options="budgetYears()" v-model="ticket.budgetYear" />
                </template>
              </b-form>

              <!-- <template v-if="ticket.steps && ticket.steps.length>0">
                <i class="fa fa-plus" style="cursor:pointer;font-size:1.25em;float:right;" @click="attachProject()" v-b-popover.hover.left="'Add project to this ticket'"></i>
                <list-card-ex
                  title="Task Projects"
                  :fields="ticketProjectFields"
                  :items="ticket.projects"
                  :enableDelete='true'
                  @delete="removeProjectFromList($event)"
                />
              </template> -->

            </div>

          </b-tab>

          <b-tab :title="(ticket.privateNotes) ? 'Private (1)' : 'Private'" class="noprint">
            <div style="height:675px;margin:0 2em 0 2em;">
              <b-form @input="ticketSetChanged()" class="mt-4">
                <wysiwyg v-model="ticket.privateNotes" :readOnly="isReadOnly()"></wysiwyg>
              </b-form>

            </div>
          </b-tab>

          <b-tab v-if="ticketAllowsComments()" class="noprint">
            <template slot="title">
              Progress/Comments({{commentCount()}})
              <b-badge v-if="hasNewComment()" pill style="background:red;font-size:.4em;position:relative;top:-7px;">new</b-badge>
            </template>
            <div style="margin:0 2em 0 2em;">
            <b-button :disabled="isReadOnly()" class="btn btn-sm btn-fill btn-success "
              style="margin: 1em 0 2em 3em;padding: .25em .55em .25em .25em;font-size:.8em;" @click="askComment( null )"><span><i class="fa fa-plus"></i>Add Comment</span></b-button>

            <!-- <div style="height:1000px;overflow:auto;">
              <comments-card :comments="actionComments()" :showLike=false :showReply=true @like="clickLike($event)" @reply="clickReply($event)" />
            </div> -->

              <template v-for="(action,tlIndex) in actionComments()" >
                <vue-timeline-update
                  :key="tlIndex"
                  class="comment-timeline"
                  :date="new Date(action.actionDate)"
                  :title="action.actionUserAlias"
                  :description="action.actionDescription"
                  category="comment"
                  theme="dark"
                  icon="envelope"
                  color="black"
                />
              </template>

            </div>
          </b-tab>

          <b-tab v-if="ticket.linkedTickets && ticket.linkedTickets.length>0" title="Linked Tickets">

            <ul>
              <li v-for="lt in ticket.linkedTickets" style="margin:1em 0 1em 0;">
<!--                <b-link :to="'/ticket/'+lt" style="font-size:1.25em;">{{lt}}</b-link>-->
                <div @click="ticketEdit(lt)">{{ lt }}</div>
              </li>
            </ul>
          </b-tab>

          <b-tab v-if="ticketAllowsFiles()" style="margin-top:2em;" @click="getFiles()">
            <template #title>
              <b-spinner v-if="isFilesLoading" type="border" small style="margin-right:1em;"></b-spinner>Files
            </template>

            <file-List
              title="Documents & Images"
              :folder="uploadFolder()"
              :files="files"
              :showImages="isShowImages"
              :enableDelete="true"
              :iconSize="64"
              :enableMultipleFileUpload="true"
              @getFiles="getFiles(true)"
            />
          </b-tab>


          <b-tab title="History" class="noprint">
            <div style="height:95%;overflow:auto;margin:2em 2em 0 2em;">
              <!-- <comments-card :comments="actionHistory()" :showTitle=true :showLike=false :showReply=false /> -->
              <b-checkbox style="float:right;" v-model="isHistoryIgnoreUpdates">Hide Updates</b-checkbox>
              <template v-for="(action,tlIndex) in actionHistory()" >
                <vue-timeline-update
                  :key="tlIndex"
                  class="history-timeline"
                  :date="new Date(action.actionDate)"
                  :title="action.actionUserAlias"
                  :description="action.actionDescription"
                  category="history"
                  theme="dark"
                  icon="envelope"
                  color="black"
                />
              </template>

            </div>
          </b-tab>

        </b-tabs>
      </div>

      <div v-if="isShowRightPanel" class="col-sm-12 col-md-12 col-xl-4 " style="margin-top:100px;border-radius:1em;overflow-y:auto;">

        <card style="border-radius:1em" >
          <div style="float:right;" @click="toggleShowPerformanceStatus()">
            <i class="noprint fa show-hand" :class="(showPerformanceStatus) ? 'fa-compress' : 'fa-expand'"/>
          </div>

          <b-collapse :visible="!showPerformanceStatus" >
<!--            <div style="width:100%;margin:0;" @click="toggleShowPerformanceStatus()">-->
<!--              <template v-if="projectEstimatedTotal()">-->
<!--                <span style="min-width:20em;text-align:right;">Remaining to Spend:</span><span style="margin-left:2em;font-weight:800;font-size:1.25em" :style="((projectEstimatedTotal()-projectTotal()) < 0) ? 'color:maroon;' : ''">{{USD(projectEstimatedTotal()-ticket.ticketTotal)}} </span>-->
<!--              </template>-->
<!--              <template v-else>-->
<!--                <span style="background:red;min-width:20em;text-align:right;"> Total:</span><span style="margin-left:2em;font-weight:800;font-size:1.25em" >{{USD(ticket.ticketTotal)}} </span>-->
<!--              </template>-->
<!--              <div v-if="ticket.actual.payments">-->
<!--                <span style="min-width:20em;text-align:right;">Bal Due:</span>-->
<!--                <span style="margin-left:2em;font-weight:800;font-size:1.25em">{{ USD(ticket.ticketTotal+ticket.actual.payments)}}</span>-->
<!--              </div>-->
<!--            </div>-->

<!--            <div style="margin:0; display: flex; flex-direction: column;">-->
<!--              <template v-if="projectEstimatedTotal()">-->
<!--                <div style="display: flex; justify-content: flex-start;text-align:right;">-->
<!--                  <span style="min-width:10em;">Remaining to Spend:</span>-->
<!--                  <span style="font-weight:800; font-size:1.25em; ">{{USD(projectEstimatedTotal()-projectTotal()) < 0 ? USD(projectEstimatedTotal()-ticket.ticketTotal) : ''}}</span>-->
<!--                </div>-->
<!--              </template>-->
<!--              <template v-else>-->
<!--                <div style="background:lime;display: flex; justify-content: flex-start;text-align:right">-->
<!--                  <span style="min-width:10em;text-align:right;"> Total:</span>-->
<!--                  <span style="font-weight:800; font-size:1.25em; ">{{USD(ticket.ticketTotal)}}</span>-->
<!--                </div>-->
<!--              </template>-->
<!--              <template v-if="ticket.actual.payments && !ticket.closed" >-->
<!--                <div style="display: flex; justify-content: flex-start;text-align:right;">-->
<!--                  <span style="min-width:10em;text-align:right;">Payments:</span>-->
<!--                  <span style="background:lime;font-weight:800; font-size:1.25em; ">{{ USD(ticket.actual.payments)}}</span>-->
<!--                </div>-->
<!--                <div style="display: flex; justify-content: flex-start;text-align:right;">-->
<!--                  <span style="min-width:10em;">Bal Due:</span>-->
<!--                  <span style="font-weight:800; font-size:1.25em; ">{{ USD(ticket.ticketTotal+ticket.actual.payments)}}</span>-->
<!--                </div>-->
<!--              </template>-->
<!--            </div>-->

<!--            <div style="margin:0; display: grid; grid-template-columns: auto auto; text-align: right;">-->
<!--              <template v-if="projectEstimatedTotal()">-->
<!--                <div>-->
<!--                  <span style="min-width:10em;">Remaining to Spend:</span>-->
<!--                  <span style="font-weight:800; font-size:1.25em; ">{{USD(projectEstimatedTotal()-projectTotal()) < 0 ? USD(projectEstimatedTotal()-ticket.ticketTotal) : ''}}</span>-->
<!--                </div>-->
<!--              </template>-->
<!--              <template v-else>-->
<!--                <div style="background:lime;">-->
<!--                  <span style="min-width:10em;"> Total:</span>-->
<!--                  <span style="font-weight:800; font-size:1.25em; ">{{USD(ticket.ticketTotal)}}</span>-->
<!--                </div>-->
<!--              </template>-->
<!--              <template v-if="ticket.actual.payments && !ticket.closed" >-->
<!--                <div>-->
<!--                  <span style="min-width:10em;">Payments:</span>-->
<!--                  <span style="background:lime;font-weight:800; font-size:1.25em; ">{{ USD(ticket.actual.payments)}}</span>-->
<!--                </div>-->
<!--                <div>-->
<!--                  <span style="min-width:10em;">Bal Due:</span>-->
<!--                  <span style="font-weight:800; font-size:1.25em; ">{{ USD(ticket.ticketTotal+ticket.actual.payments)}}</span>-->
<!--                </div>-->
<!--              </template>-->
<!--            </div>-->

            <table style="width:100%; text-align:right;">
              <thead>
                <tr>
                  <td style="width:15em;"></td>
                  <td style="width:12em;"></td>
                  <td></td>
                </tr>
              </thead>
              <template v-if="projectEstimatedTotal()">
                <tr>
                  <td style="width:60%;text-align:right;">Estimated:</td>
                  <td style="width:12em;text-align:right;font-weight:800;">{{USD(projectEstimatedTotal())}}</td>
                  <td></td>
                </tr>
                <tr style="border-bottom:1px solid gray;">
                  <td style="min-width:10em;">Remaining to Spend:</td>
                  <td style="font-weight:800;">{{USD(projectEstimatedTotal()-ticket.ticketTotal)}}</td>
                  <td></td>
                </tr>
              </template>
<!--              <template v-else>-->
<!--                <tr>-->
<!--                  <td style="min-width:10em;"> Total:</td>-->
<!--                  <td style="font-weight:800; font-size:1.25em; ">{{USD(ticket.ticketTotal)}}</td>-->
<!--                  <td></td>-->
<!--                </tr>-->
<!--              </template>-->
              <template v-if="ticket.actual.payments && !ticket.posted" >
                <tr>
                  <td style="min-width:10em;">Actual Total:</td>
                  <td style="font-weight:800; font-size:1.25em; ">{{USD(ticket.ticketTotal)}}</td>
                  <td></td>
                </tr>
                <tr>
                  <td style="min-width:10em;">Payments:</td>
                  <td style="font-weight:800; font-size:1.25em; ">{{ USD(ticket.actual.payments)}}</td>
                  <td></td>
                </tr>
                <tr>
                  <td style="min-width:10em;">Bal Due:</td>
                  <td style="font-weight:800; font-size:1.25em; ">{{ USD(ticket.ticketTotal+ticket.actual.payments)}}</td>
                  <td></td>
                </tr>
              </template>
            </table>




          </b-collapse>
          <b-collapse v-model="showPerformanceStatus" >
            <h4 style="padding:0;margin:0;"  @click="toggleShowPerformanceStatus()">Performance</h4>
            <div class="hide-too-small" style="margin-top:1em;width:100%;display:flex;">
              <pie-widget
                style="width:30%;max-width:270px;margin-left:auto;margin-right:auto;"
                title="Hours"
                chartColor="lightgray"
                :cutoutPercentage="60"
                :chartLabels="['Complete','Remain']"
                :chartDataColors="['darkgreen','orange','#0000ff']"
                :chartData="[ticket.actual.hours,(ticket.adjustedBudget.hours-ticket.actual.hours)]"
                :options="chartOptions"
              />
              <pie-widget
                v-if="ticket.steps.length>0"
                style="width:30%;max-width:270px;margin-left:auto;margin-right:auto;"
                title="Tasks"
                chartColor="lightgray"
                chartDataBackgroundColor="rgba(192,0,128,.4)"
                :chartDataColors="['darkgreen','orange','#0000ff']"
                :cutoutPercentage="60"
                :chartLabels="['Complete','Remain']"
                :chartData="[tasksCompleted,ticket.steps.length-tasksCompleted]"
                :options="chartOptions"
              />
              <pie-widget
                v-if="isProject()"
                style="width:30%;max-width:270px;margin-left:auto;margin-right:auto;"
                title="Spend"
                chartColor="lightgray"
                :chartDataColors="['darkgreen','white']"
                chartDataBackgroundColor="rgba(192,0,128,.4)"
                :chartLabels="['Spent']"
                :cutoutPercentage="60"
                :chartData="[projectTotal(),projectEstimatedTotal()-projectTotal()]"
                :options="chartOptions"
              />

            </div>
            <table style="width:90%;">
              <tr><td colspan=2 style="">Due: {{(ticket.dueDate) ? moment(ticket.dueDate).fromNow() : '(not set)'}}</td></tr>
              <tr><td colspan=2 style="">Begins: {{(ticket.scheduledDate) ? moment(ticket.scheduledDate).fromNow() : '(not set)'}}</td></tr>
              <tr><td></td><td>&nbsp;</td></tr>
              <tr><td style="width:60%;text-align:right;">Estimated:</td><td style="width:12em;text-align:right;font-weight:800;">{{USD(projectEstimatedTotal())}}</td></tr>
              <tr><td style="width:60%;text-align:right;">Actual:</td><td style="width:12em;text-align:right;">{{USD(ticket.ticketTotal)}}</td></tr>
              <tr v-if="projectEstimatedTotal()"><td style="width:60%;text-align:right;">Remaining to Spend:</td><td style="width:12em;text-align:right;font-weight:800;font-size:1.5em" :style="((projectEstimatedTotal()-projectTotal()) < 0) ? 'color:maroon;' : ''">{{USD(projectEstimatedTotal()-ticket.ticketTotal)}}</td></tr>
              <tr v-if="ticket.actual.payments && !ticket.closed"><td style="width:60%;text-align:right;">Balance Due</td><td style="width:12em;text-align:right;font-weight:800;font-size:1.5em" >{{USD(ticket.ticketTotal+ticket.actual.payments)}}</td></tr>
            </table>
          </b-collapse>
        </card>


        <card style="border-radius:1em;">
          <div style="float:right;" @click="toggleShowProjectStatus()">
            <i class="noprint fa show-hand" :class="(showProjectStatus) ? 'fa-compress' : 'fa-expand'"/>
          </div>
          <b-collapse :visible="!showProjectStatus" >
            <div @click="toggleShowProjectStatus()">
              <div v-if="isProject() || ticket.BudgetYear">
                <span>Budget Year</span>
                <span style="margin-left:1em;font-weight:800;">{{ticket.budgetYear}}</span>
              </div>
              <div>
                <span>Due: </span>
                <span style="margin-left:1em;font-weight:800;">{{ (!ticket.dueDate) ? "Not Set" : moment( ticket.dueDate ).add(1,'day').fromNow()}}</span>
              </div>
            </div>
          </b-collapse>

          <b-collapse v-model="showProjectStatus" >
            <h4 style="padding:0;margin:0;"  @click="toggleShowProjectStatus()">{{(isProject()) ? 'Project Status & Dates' : 'Case Status'}}</h4>
            <b-avatar v-if="ticket.assignedToPictureUrl" variant="default" size="5em" style="margin-top:10px;float:right;" :title="ticket.assignedTo" :src="ticket.assignedToPictureUrl" />
            <img v-else height="64" style="margin-top:10px;border-radius:50%;float:right;" :title="ticket.assignedToEmail" :src="myGravatar(ticket.assignedToEmail)">

            <table class="mt-5">
              <tr v-if="ticket.status!=='complete'" style="background:#eee;">
                <td style="width:100px;text-align:right;padding-right:4px;">Due</td>
                <td style="font-weight:800;">{{ (!ticket.dueDate) ? "Not Set" : moment( ticket.dueDate ).add(1,'day').fromNow()}}</td>
              </tr>

              <tr>
                <td style="width:100px;text-align:right;padding-right:4px;">Updated</td>
                <td style="font-weight:800;">{{moment(ticket.updatedAt).fromNow()}}</td>
              </tr>
              <tr><td colspan=2>&nbsp;</td></tr>
              <tr>
                <td style="width:100px;text-align:right;padding-right:4px;">Assigned</td>
                <td style="font-weight:800;">{{ ticket.assignedTo }}</td>
              </tr>
              <tr>
                <td style="width:100px;text-align:right;padding-right:4px;">Email</td>
                <td style="font-weight:800;" v-b-popover.hover.top="'Click To Send Email'"><a
                    :href="'mailto:'+ticket.assignedToEmail">{{ticket.assignedToEmail}}</a></td>
              </tr>
              <tr v-if="ticket.status==='complete'">
                <td style="width:100px;text-align:right;padding-right:4px;">Complete</td>
                <td style="font-weight:800;color:maroon;" v-if="ticket.closed">Closed Within
                  {{(moment(ticket.closedDate).diff( ticket.createdAt,'hours')/24).toFixed(2)}} Days</td>
                <td style="font-weight:800;" v-else>Ready To Close</td>
              </tr>
            </table>
            <div style="width:100%;border-top:1px solid black;margin-bottom:2em;">
              <table class="mt-1">
                <tr v-b-popover.hover.top="moment(ticket.createdAt).fromNow()">
<!--                  <td style="font-size:12px;width:100px;text-align:right;padding-right:4px;"><i v-if="$root.isAdmin()" class="fa fa-pencil show-hand" v-b-popover.hover.top="'Edit Date Ticket Started'" @click="askCreateDate()"/>Created</td>-->
                  <td style="font-size:12px;width:100px;text-align:right;padding-right:4px;">Created</td>
                  <td style="font-weight:600;"> {{ticket.ticketSource}} {{moment(ticket.createdAt).format('ddd DD-MMM-YY  hh:mma')}}</td>
                </tr>
                <tr v-b-popover.hover.top="ticket.createdByEmail">
                  <td style="font-size:12px;width:100px;text-align:right;padding-right:4px;">By</td>
                  <td style="font-weight:600;">{{ticket.createdBy}}</td>
                </tr>

              </table>
            </div>

            <b-form @input="ticketSetChanged()" >
              <sf-input v-if="isProject() || ticket.BudgetYear" :disabled="isReadOnly()" cstyle="width:8em;" type="list" label="Budget Year" :options="budgetYears()" v-model="ticket.budgetYear" />
              <sf-input :disabled="isReadOnly() || !$root.isAdmin()" type="date-time" :noTime="true" :TZ="$root.TZ"  cstyle="width:12em;" label="Start Date" v-model="ticket.startDate" />
              <sf-input :disabled="isReadOnly()" type="date" cstyle="width:12em;" label="Scheduled Begin" v-model="ticket.scheduledDate" />
              <sf-input :disabled="isReadOnly()" type="date" cstyle="width:12em;" label="Due/Desired By" v-model="ticket.dueDate" />
              <div v-if="ticket.completeDate"  style="width:100%;padding:.5em 0 .5em 0;border-top:1px solid black;border-bottom:1px solid black;">
                  <h4 style="padding:0;margin:0;">Completed By</h4>
                  <div>
                  <b-dropdown :disabled="isReadOnly()" style="width:100%;border-radius:.75em;background:gray;" size="sm" class="m-1" variant=" text-white" v-model="ticket.completeById" :text="ticket.completeBy || '------'">
                    <template v-for="(user,index) in availableUsers()">
                      <b-dropdown-item @click="setCompleteBy(user)" style="display:inline-flex;width:350px;" :key="index">
                        <div style="width:100%;">
                          <b-avatar variant="default" size="2rem" :src="getAccountImage(user)" style="margin-left:.5em;margin-right:.5em;"></b-avatar>
                          <div style="font-weight:600;">{{user.lastName + ', ' + user.firstName }}  ({{user.alias}})</div>
                          <div v-if="user.email" style="font-size:.8em;font-weight:300;">({{(typeof user.notificationEmail !== 'undefined' && user.notificationEmail.trim().length > 0 ) ? user.notificationEmail : user.email }})</div>
                        </div>
                      </b-dropdown-item>
                    </template>
                  </b-dropdown>
                  </div>
                <sf-input  :disabled="isReadOnly()" type="tz-date" label="On" v-model="ticket.completeDate" />
              </div>
              <div v-if="ticket.closeDate" style="width:100%;padding:.5em 0 .5em 0;border-top:1px solid black;border-bottom:1px solid black;">
                <h4 style="padding:0;margin:0;">Closed By: {{ticket.closedBy}}</h4>
                <sf-input  :disabled="isReadOnly()" type="tz-date" label="On" v-model="ticket.closeDate" />
              </div>

            </b-form>
          </b-collapse>
        </card>


        <card v-if="isClientAssignment" style="border-radius:1em;" :style="(ticket.posted) ? 'background:#eecccc;' : (isNotifyBeforeArrival()) ? 'background:rgba(228,155,15,.1);' : ''" >

          <form  style="width:100%; margin-top:1em;" @input="ticketSetChanged()">
            <div style="width:40%;float:right;">
              <span v-if="!(ticket.client.clientId || hasUnit()) && !ticket.closed"
                style="float:right;position:relative;top:-1em;border-radius:.5em;font-weight:600;background:red;color:white;padding:.25em .5em .25em .5em;"
                v-b-popover.hover.top="'This ticket is not linked to an account'">Not Linked </span>
              <b-checkbox v-if="ticket.client && ticket.client.clientAccountNumber && ticket.client.clientAccountNumber.length > 0 && ticketCanPost()"
                v-model="ticket.billable" :disabled="!$root.isInRole('tickets-enable-billing') || ticket.posted || isReadOnly()">Billable</b-checkbox>
              <div v-if="ticket.posted" style="font-size:.9em;color:white;background:red;text-align:center;padding:.5em;border-radius:1em;">Total
                Posted: {{moment( ticket.postedDate ).tz($root.TZ).format("LLLL")}}</div>
            </div>
            <div class="show-hand" @click="showSearch=!showSearch">
              <i class="fa fa-search" v-if="!ticket.posted" style="float:left;position:relative;top:4px;padding-right:5px;" v-b-popover.hover.top="'Search/Assign Client'"></i>
              <h4 class="card-title mb-1">Client Details</h4>
            </div>
            <h5>{{ticket.client.clientName}}</h5>
            <div class="center" style="display:flex;margin-left:auto;margin-right:auto;" @click="editAccount(ticket.client)">
              <img v-if="ticket.client.clientPictureUrl" height="64" style="border-radius:2em;float:right;" :title="ticket.client.clientPictureUrl" :src="ticket.client.clientPictureUrl">
              <img v-else height="64" style="border-radius:2em;float:right;" :title="ticket.client.clientEmail" :src="myGravatar(ticket.client.clientEmail)">
            </div>

          </form>

          <b-tabs class="mt-4 tab-hover" active-nav-item-class="selected-tab">
            <b-tab title="Details">
              <div class="mt-2">
                <form @input="ticketSetChanged()" >
                  <sf-input :disabled="ticket.posted || isReadOnly() || !(!ticket.client.clientId) || ticket.client.isVacant" type="text" label="Name" v-model="ticket.client.clientName" />
                  <sf-input :disabled="ticket.posted || isReadOnly() || hasBuilding()" type="text" label="Building" v-model="ticket.client.clientBuilding" />
                  <sf-input :disabled="ticket.posted || isReadOnly() || hasUnit()" type="text" label="Room" v-model="ticket.client.clientRoomNumber" />
                  <sf-input v-if="ticket.client.clientMoveInDate" :disabled="ticket.posted || isReadOnly() || hasUnit()" type="date" label="Moved In" v-model="ticket.client.clientMoveInDate" cstyle="width:12em;"/>
                  <b-form @input="badgeChanged()">
                    <sf-input :disabled="ticket.posted || isReadOnly()" type="text" label="Email" v-model="ticket.client.clientEmail" />
                    <sf-input :disabled="ticket.posted || isReadOnly()" type="text" label="Phone" v-model="ticket.client.clientPhone" />
                    <sf-input :disabled="ticket.posted || isReadOnly()" type="text" label="Mobile" v-model="ticket.client.clientMobile" />
                    <sf-input :disabled="ticket.posted || isReadOnly()" type="textarea" label="Notes" v-model="ticket.client.clientNotes" />
                  </b-form>
                </form>
              </div>
            </b-tab>

            <b-tab v-if="isEnableClientNotifyTab" title="Notify">
              <form @input="ticketSetChanged()" class="p-4">
                <b-form-checkbox type="checkbox" v-model="ticket.client.options.notifyBeforeArrival" >Notify Before Arrival</b-form-checkbox>
                <b-form-checkbox type="checkbox" v-model="ticket.client.options.notifyOnAssignment" >Notify On Assignment</b-form-checkbox>
                <b-form-checkbox type="checkbox" v-model="ticket.client.options.notifyOnScheduleChange" >Notify On Schedule Change</b-form-checkbox>
                <b-form-checkbox type="checkbox" v-model="ticket.client.options.notifyOnActivity" >Notify On Activity</b-form-checkbox>
                <b-form-checkbox type="checkbox" v-model="ticket.client.options.notifyOnComplete" >Notify On Complete</b-form-checkbox>
              </form>
            </b-tab>

            <b-tab title="Address">
              <div class="mt-2">
                <b-form @input="badgeChanged()">
                  <sf-input :disabled="ticket.posted || isReadOnly()" type="text" label="Address1" v-model="ticket.client.clientAddress1" />
                  <sf-input :disabled="ticket.posted || isReadOnly()" type="text" label="Address2" v-model="ticket.client.clientAddress2" />
                  <sf-input :disabled="ticket.posted || isReadOnly()" type="text" label="City" v-model="ticket.client.clientCity" />
                  <sf-input :disabled="ticket.posted || isReadOnly()" type="text" label="State" v-model="ticket.client.clientState" />
                  <sf-input :disabled="ticket.posted || isReadOnly()" type="text" label="Zip" v-model="ticket.client.clientZip" />
                </b-form>
              </div>
            </b-tab>

            <b-tab v-if="ticket.billable" title="Billing">
              <div class="mt-3">

                <table style="margin-left:4em;border-radius:.5em;">
                  <tr>
                    <td style="width:60%;text-align:right;padding-right:2em;">Labor </td>
                    <td style="text-align:right;font-weight:800;">{{currency(ticket.hourlyRate).multiply(ticket.actual.hours).add(ticket.actual.labor)}}</td>
                  </tr>
                  <tr v-if="ticketHasParts()">
                    <td style="width:60%;text-align:right;padding-right:2em;">Parts/Supplies</td>
                    <td style="text-align:right;font-weight:800;">{{currency(ticket.actual.supplies).add(ticket.actual.parts) }}
                    </td>
                  </tr>
                  <tr v-if="ticketAllowsTasks()">
                    <td style="width:60%;text-align:right;padding-right:2em;">Expenses</td>
                    <td style="text-align:right;font-weight:800;">{{currency(ticket.actual.expenses) }}
                    </td>
                  </tr>
                  <tr v-if="ticketAllowsTasks()">
                    <td style="width:60%;text-align:right;padding-right:2em;">Outside Costs</td>
                    <td style="text-align:right;font-weight:800;">{{currency(ticket.actual.outside) }}
                    </td>
                  </tr>
                  <tr>
                    <td style="width:60%;text-align:right;padding-right:2em;">Coupon</td>
                    <td style="text-align:right;font-weight:800;">{{currency(ticket.couponTotal)}}</td>
                  </tr>
                  <tr style="border-top:1px solid black;">
                    <td style="width:60%;text-align:right;padding-right:2em;font-weight:800;">Ticket Total</td>
                    <td style="text-align:right;font-size:1.25em;font-weight:900;">{{ currency(ticket.ticketTotal)}}</td>
                  </tr>
                  <template v-if="ticket.actual.payments && !ticket.posted" >
                    <tr>
                      <td style="width:60%;text-align:right;padding-right:2em;font-weight:800;">Payments:</td>
                      <td style="text-align:right;font-size:1.25em;font-weight:900;">{{ USD(ticket.actual.payments)}}</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td style="width:60%;text-align:right;padding-right:2em;font-weight:800;">Bal Due:</td>
                      <td style="text-align:right;font-size:1.25em;font-weight:900;">{{ USD(ticket.ticketTotal+ticket.actual.payments)}}</td>
                      <td></td>
                    </tr>
                  </template>
                </table>
              </div>
            </b-tab>
          </b-tabs>

        </card>

        <card v-if="isUnitAssignment()" style="border-radius:1em;" :style="(ticket.posted) ? 'background:#eecccc;' : ''" >
          <div class="mt-2">
            <div style="">
              <span v-if="!(hasBuilding() || hasUnit()) && !ticket.closed" style="float:right;position:relative;top:-.5em;border-radius:.5em;font-weight:600;background:red;color:white;padding:.25em .5em .25em .5em;" v-b-popover.hover.top="'This ticket is not linked to an UNIT'">Not Linked </span>
              <div v-if="ticket.posted" style="font-size:.9em;color:white;background:red;text-align:center;padding:.5em;border-radius:1em;">Total Posted: {{moment( ticket.postedDate ).tz($root.TZ).format("LLLL")}}</div>
            </div>
            <div class="show-hand" >
              <h4 class="card-title mb-2">Assigned Unit</h4>
            </div>
            <form @input="ticketSetChanged()" >
              <i  class="fa fa-search" v-if="!ticket.posted" style="float:left;position:relative;top:4px;padding-right:5px;" v-b-popover.hover.top="'Search/Assign Unit'" @click="showUnitSearch=!showUnitSearch"></i>
              <sf-input v-if="ticket.links" :disabled="true" type="text" label="Room" v-model="ticket.links.unitTitle" />
              <i class="fa fa-search" v-if="!ticket.posted" style="float:left;position:relative;top:4px;padding-right:5px;" v-b-popover.hover.top="'Search/Assign Building'" @click="showBuildingSearch=!showBuildingSearch"></i>
              <sf-input v-if="ticket.links" :disabled="true" type="text" label="Building" v-model="ticket.links.buildingTitle" />
            </form>
          </div>
        </card>


        <card style="border-radius:1em;">
          <div v-if="ticket!=null" style="width:100%;">

            <div style="float:right;" @click="toggleShowTotalStatus()">
              <i class="noprint fa show-hand" :class="(showTotalStatus) ? 'fa-compress' : 'fa-expand'"/>
            </div>
            <!-- <h4 style="padding:0;margin:0;">Performance</h4> -->

            <b-collapse :visible="!showTotalStatus">
              <div style="width:100%;margin:0;" @click="toggleShowTotalStatus()">
                <span>Ticket Total:</span><span style="margin-left:2em;font-weight:800;font-size:1.25em" >{{USD(ticket.ticketTotal || 0)}} </span>
              </div>
            </b-collapse>
            <b-collapse v-model="showTotalStatus" >
              <h4 style="padding:0;margin:0;" @click="toggleShowTotalStatus()">Totals <span v-if="!ticket.billable && !isProject()" style="font-size:.8em;color:#ed5d4e;padding-left:2em;">** not billable **</span></h4>
              <table @input="ticketSetChanged()" style="width:100%;overflow:auto;">
                <tr>
                  <th style="width:30%;">&nbsp;</th>
                  <th style="width:40%;padding-right:2em;">&nbsp;</th>
                </tr>
                <tr style="border-radius:1em;">
                  <td style="text-align:right;padding-right:.5em;font-weight:700;">Hourly Rate</td>
                  <td style="text-align:left;padding:.5em .25em .25em .25em;">
                    <sf-input :labelCols="0" type="comma" isCurrency cstyle="font-weight:700;width:9em;border:2px solid #9af6eb;background:#7ad6cb;border-radius:1em;text-align:right;"   v-model="ticket.hourlyRate" @change="hourlyRateChanged()" />
                  </td>
                </tr>
                <tr>
                  <td style="min-width:30%;text-align:right;padding-right:.5em;">Hours</td>
                  <td style="min-width:40px;text-align:left;padding:.25em;">
                    <sf-input :labelCols="0" type="comma" :isCurrency="true" cstyle="width:9em;text-align:right;" v-model="ticket.actual.hours" :disabled="true" />
                  </td>
                </tr>
                <tr>
                  <td style="text-align:right;padding:.5em;">
                    <div>+ Hourly Labor $</div>
                  </td>
                  <td><div style="width:9em;text-align:right;background:#eee;padding:.5em;border-radius:.25em;margin-bottom:.25em;" >{{ USD(currency(ticket.hourlyRate).multiply(ticket.actual.hours).value)}}</div></td>
                </tr>
                <tr>
                  <td style="text-align:right;padding-right:.5em;">+ Fixed Labor $</td>
                  <td style="font-weight:800;font-size:.85em;padding:.25em;">
                    <sf-input :labelCols="0" type="comma" :isCurrency="true" cstyle="width:9em;text-align:right;" v-model="ticket.actual.labor" :disabled="true" />
                  </td>
                </tr>
                <tr>
                  <td style="text-align:right;padding-right:.5em;"></td>
                  <td>&nbsp;</td>
                </tr>
                <tr v-if="ticketHasParts()">
                  <td style="text-align:right;padding-right:.5em;">+ Parts $</td>
                  <td style="font-weight:800;font-size:.85em;padding:.25em;">
                    <sf-input :labelCols="0" type="comma" :isCurrency="true" cstyle="width:9em;text-align:right;" v-model="ticket.actual.parts" :disabled="true" />
                  </td>
                </tr>
                <tr v-if="ticketHasParts()">
                  <td style="text-align:right;padding-right:.5em;">+ Supplies $</td>
                  <td style="font-weight:800;font-size:.85em;padding:.25em;">
                    <sf-input :labelCols="0" type="comma" :isCurrency="true" cstyle="width:9em;text-align:right;" v-model="ticket.actual.supplies" :disabled="true" />
                  </td>
                </tr>
                <tr v-if="ticketAllowsTasks()">
                  <td style="text-align:right;padding-right:.5em;">+ Expenses $</td>
                  <td style="font-weight:800;font-size:.85em;padding:.25em;">
                    <sf-input :labelCols="0" type="comma" :isCurrency="true" cstyle="width:9em;text-align:right;" v-model="ticket.actual.expenses" :disabled="true" />
                  </td>
                </tr>
                <tr>
                  <td style="text-align:right;padding-right:.5em;">+ Outside $</td>
                  <td style="font-weight:800;font-size:.85em;padding:.25em;">
                    <sf-input :labelCols="0" type="comma" :isCurrency="true"  cstyle="width:9em;text-align:right;" v-model="ticket.actual.outside" :disabled="true" />
                  </td>
                </tr>

                <tr>
                  <td style="text-align:right;padding-right:.5em;font-weight:700;">- Coupon $</td>
                  <td style="font-weight:800;font-size:.85em;padding:.25em;">
                    <sf-input :labelCols="0" type="comma" :isCurrency="true"  cstyle="width:9em;text-align:right;" v-model="ticket.couponTotal" :disabled="ticket.posted || isReadOnly()" @change="calculateTicketTotals()" />
                  </td>
                </tr>
                <tr v-if="parseFloat(ticket.couponTotal)!==0">
                  <td style="text-align:right;padding-right:.5em;font-weight:700;">Coupon #</td>
                  <td style="font-weight:800;font-size:.85em;padding:.25em;">
                    <sf-input :labelCols="0" type="text" :isCurrency="true" cstyle="width:9em;text-align:right;" v-model="ticket.couponNumber" :disabled="ticket.posted || isReadOnly()" />
                  </td>
                </tr>

                <tr>
                  <td colspan="2">
                    &nbsp;
                  </td>
                </tr>
                <tr v-if="ticket.ticketTotal !== 0 || ticket.actual.hours !== 0 || ticket.couponTotal !== 0">
                  <td style="padding-right:.5em;text-align:right;">Labor Total</td>
                  <td style="font-weight:800;text-align:right;">{{USD(currency(ticket.hourlyRate).multiply(ticket.actual.hours).add(ticket.actual.labor).value)}}</td>
                </tr>
                <tr v-if="(ticket.actual.labor + ticket.actual.parts + ticket.actual.supplies + ticket.actual.expenses + ticket.actual.outside ) !== 0">
                  <td style="padding-right:.5em;;text-align:right;">Other Total</td>
                  <td style="font-weight:800;text-align:right;">{{USD(currency(ticket.actual.parts).add(ticket.actual.supplies).add(ticket.actual.expenses).value)}}</td>
                </tr>
                <tr v-if="(ticket.actual.labor + ticket.actual.parts + ticket.actual.supplies + ticket.actual.expenses + ticket.actual.outside ) !== 0">
                  <td style="padding-right:.5em;;text-align:right;">Outside Total</td>
                  <td style="font-weight:800;text-align:right;">{{USD(ticket.actual.outside) }}</td>
                </tr>
                <tr v-if="(ticket.couponTotal) !== 0">
                  <td style="padding-right:.5em;;text-align:right;">Coupons</td>
                  <td style="font-weight:800;text-align:right;">{{USD(ticket.couponTotal)}}</td>
                </tr>
                <tr v-if="ticket.actual.total !== 0" style="border-top:1px solid black;">
                  <td style="padding:1em;font-weight:800;text-align:right;">Ticket Total</td>
                  <td style="font-size:1.25em;font-weight:900;text-align:right;">{{ USD(ticket.ticketTotal)}}</td>
                </tr>
                <template v-if="ticket.actual.payments !== 0 & !ticket.posted">
                  <tr  style="">
                    <td style="padding:1em;font-weight:800;text-align:right;">Payments</td>
                    <td style="font-size:1.25em;font-weight:900;text-align:right;">{{ USD(ticket.actual.payments)}}</td>
                  </tr>
                  <tr >
                    <td style="padding:1em;font-weight:800;text-align:right;">Bal Due:</td>
                    <td style="font-size:2em;font-weight:900;text-align:right;">{{ USD(ticket.ticketTotal+ticket.actual.payments)}}</td>
                  </tr>
                </template>


              </table>
            </b-collapse>
          </div>
        </card>

      </div>

    </div>

  </b-container>

</template>
<script>

  import AccountService     from "/src/Services/AccountService";
  import RoomService        from "/src/Services/RoomService";
  import TicketService      from "/src/Services/TicketService";
  import UserService        from "/src/Services/UserService";
  import FileService        from "@/Services/FileService";
  import TenantService      from "/src/Services/TenantService";
  import VendorService      from "/src/Services/VendorService";
  import GeneralService     from "/src/Services/GeneralService";
  import TransactionService from "/src/Services/TransactionService";
  import PieWidget          from "/src/components/UIComponents/Widgets/PieWidget.vue";
  import BarWidget          from "/src/components/UIComponents/Widgets/BarWidget.vue";

  // import TicketsLib from "/src/Lib/TicketsLib";
  import TicketDisplayPage from "./TicketDisplayPage.vue";
  import TicketInvoice     from "./TicketInvoice.vue";
  import TicketsLib        from "/src/Lib/TicketsLib.js";
  import TaskEdit          from "./TaskEdit.vue";
  import ChangeOrderEdit from "./ChangeOrderEdit.vue";
  import ManualTransactionForm from "/src/components/UIComponents/Cards/ManualTransactionForm2.vue";

  import Currency        from "currency.js";
  import gravatar        from "gravatar";
  import Moment          from "moment-timezone";
  import Card            from "/src/components/UIComponents/Cards/Card.vue";
  import CommentsCard    from "/src/components/UIComponents/Cards/CommentsCard.vue";
  import TicketCard      from "/src/components/UIComponents/Cards/TicketCard.vue";
  import TicketTableList from "/src/components/UIComponents/Cards/TicketTableList.vue";
  import FileList        from "/src/components/UIComponents/Cards/FileList.vue";
  import SfInput         from "/src/components/UIComponents/Inputs/SFormInput.vue";
  import LiveSearch      from "/src/components/UIComponents/Inputs/LiveSearch.vue";
  import ChecklistEditor from "/src/components/UIComponents/ChecklistEditor";
  import TaskList        from "/src/components/UIComponents/Cards/TaskList";
  import ChangeOrderCard from "/src/components/UIComponents/Cards/ChangeOrderCard";
  import ListCardEx      from "/src/components/UIComponents/Cards/ListCardEx";

  import VueSimpleSuggest from 'vue-simple-suggest'
  import 'vue-simple-suggest/dist/styles.css' // Optional CSS

  var propTicketId;
  //var propUser;

  export default {
    directives: {
    },
    components: {
      BarWidget,
      Card,
      ChangeOrderCard,
      ChangeOrderEdit,
      ChecklistEditor,
      CommentsCard,
      FileList,
      ListCardEx,
      LiveSearch,
      ManualTransactionForm,
      PieWidget,
      SfInput,
      TaskEdit,
      TaskList,
      TicketCard,
      TicketDisplayPage,
      TicketInvoice,
      TicketTableList,
      VueSimpleSuggest,
    },
    props: {
      propTicketId: { type: String, default: null },
      tags        : { type: Array, default : () => [] },
    },
    watch: {
    },
    data() {
      return {
        activeMainTab        : 0,

        isBadgeChanged        : false,
        isEditChangeOrder     : false,
        isEditTask            : false,
        isHistoryIgnoreUpdates: true,
        isManualTransaction   : false,
        isResult              : false,
        isShowBudgetGL        : true,
        isShowCharts          : true,
        isShowCurrentBudget   : true,
        isShowFilter          : false,
        isShowOriginalBudget  : true,
        isShowRightPanel      : true,
        isTicketChanged       : false,
        isEditAttachProject   : false,
        attachProjectId       : null,

        popupItem            : null,
        showSearch           : false,
        showUnitSearch       : false,
        showBuildingSearch   : false,

        searchMultipleClasses: false,
        searchString         : "",
        typing               : false,
        clientSearchList     : [],

        selectedPerson       : null,
        user                 : null,
        unit                 : null,
        building             : null,
        myTicketTypes        : [],

        ticketListTitle: "",
        ticketConfig: null,

        isShowImages  : true,
        isFilesLoading: false,
        files         : null,
        filenames     : [],
        downloadLink  : "",
        // uploadFolder  : "tickets/",

        ticketNumber       : null,
        itemSearchString   : "",
        dateRange          : null,
        showClosed         : false,
        showStuck          : false,
        showParked         : false,
        showComplete       : false,

        taskOptions: {
          showTaskCategory   : false,
          showTaskDetails    : false,
          showCompletedTasks : false,
          showTaskDescription: false,
          showTaskVendor     : true,
          showAllTasks       : false,
          showBudgetYear     : false
        },

        postingAccount: {
          accountNumber:"",
          accountTitle:"",
          badgeNumber:"",
          badgeTitle:"",
          totalClassId: 20

        },
        postingTotal  : {},
        postingStep   : {},

        showProjectStatus  : true,
        showPerformanceStatus: true,
        showTotalStatus: true,

        searchTitle        : true,
        searchDescription  : true,
        searchClient       : false,
        searchResolution   : false,
        searchAssigned     : false,
        ticketType         : "",

        ticketHasHours     : false,
        ticketHasExpenses  : false,
        ticketHasLabor     : false,
        ticketHasSupplies  : false,
        ticketHasOutside   : false,
        // ticketHasParts     : false,

        transactionList    : [],

        chartOptions: {
          responsive         : true,
          maintainAspectRatio: true,
          plugins:{
            legend: {
              display: true
            },
          }
        },

        showTicket       : false,
        showInvoice      : false,
        ticket           : null,
        task             : null,
        taskQuickAddTitle: "",
        changeOrder      : null,

        action          : {},
        comment         : {},
        tagSelect       : "",
        // tags            : [],   // scraped from the cases we have
        ticketStatuses  : [],
        ticketPriorities: [],

        ticketTypes: [
        ],

        taskTotals: {
          budgetYear: "",   // added this although not used so it doesn't break the common rendering part.
          hours   : 0,
          labor   : 0,
          parts   : 0,
          supplies: 0,
          expenses: 0,
          outside : 0,
          payments: 0,
          deposits: 0,
          total   : 0,
        },

        taskBudgetTotals: [],

        users            : [],
        assignToUsers    : [],
        assignToJobCode  : "" ,
        vendorSearchField: "",

        // budgetYears: ['','2021','2022','2023','2024','2025','2026','2027','2028','2029','2030'],
        masterProjectList      : [],
        masterBudgetProjectList: [],

        variants                 : ['primary', 'secondary', 'success', 'warning', 'danger', 'info', 'light', 'dark'],
        headerBgVariant          : 'dark',
        headerTextVariant        : 'light',
        bodyBgVariant            : 'light',
        bodyTextVariant          : 'dark',
        footerBgVariant          : 'warning',
        footerTextVariant        : 'dark',
        isShowCompleted          : false,
        commentModalShow         : false,
        taskVendorLookupModalShow: false,
        loading                  : false,
        isLoading                : "fa fa-spinner  fa-refresh-animate mr-3",
        type                     : ['', 'info', 'success', 'warning', 'danger'],

        ticketProjectFields: [
            // {key: "projectId", label: "Project #", sortable: true, formatter: (value) => { return value ? this.projectField(value, "projectId") : "" }},
          {key: "title", label: "Project", sortable: true, tdClass: "text-left" },
          {key: "estimated.total", label: "Budget", tdClass: "align-right", tdFooterClass: "align-right", sortable: true, dataType: "currency", isSum: true, formatter: (value) => {return Currency(value,{precision: 2, separator: ",", symbol: ""}).format()}},
          {key: "actual.total",    label: "Actual", tdClass: "align-right", tdFooterClass: "align-right", sortable: true, dataType: "currency", isSum: true, formatter: (value) => {return Currency(value,{precision: 2, separator: ",", symbol: ""}).format()}},
        ],

        notifications    : {
          topCenter: false
        }
      }
    },
    async created() {

      this.showProjectStatus     = JSON.parse(localStorage.getItem("showProjectStatus" ) || true);
      this.showPerformanceStatus = JSON.parse(localStorage.getItem("showPerformanceStatus" ) || true);
      this.showTotalStatus       = JSON.parse(localStorage.getItem("showTotalStatus"    ) || true);
      this.isShowBudgetGL        = JSON.parse(localStorage.getItem("isShowBudgetGL"    ) || true);
      this.isShowOriginalBudget  = JSON.parse(localStorage.getItem("isShowOriginalBudget" ) || true);
      this.isShowCurrentBudget   = JSON.parse(localStorage.getItem("isShowCurrentBudget" ) || true);
      this.isShowCharts          = JSON.parse(localStorage.getItem("isShowCharts" ) || true);
      this.isShowRightPanel      = JSON.parse(localStorage.getItem("ticketShowRightPanel" ) || true);

      this.ticket       = null;
      this.ticketConfig = await TicketsLib.getTicketConfig();

      await Promise.all([
        this.getMyTicketTypes(),
        this.getTicketStatuses(),
        this.getTicketPriorities(),
        // this.getTicketUsers(),
        this.getUserOptions(),
      ])

      this.getTicket(this.propTicketId);

    },
    mounted() {
      this.getTaskOptionSelections();

      this.popupItem             = this.$el;
      // this.showProjectStatus     = JSON.parse(localStorage.getItem("showProjectStatus" ) || true);
      // this.showPerformanceStatus = JSON.parse(localStorage.getItem("showPerformanceStatus" ) || true);
      // this.showTotalStatus       = JSON.parse(localStorage.getItem("showTotalStatus"    ) || true);
      // this.isShowBudgetGL        = JSON.parse(localStorage.getItem("isShowBudgetGL"    ) || true);
      // this.isShowOriginalBudget  = JSON.parse(localStorage.getItem("isShowOriginalBudget" ) || true);
      // this.isShowCurrentBudget   = JSON.parse(localStorage.getItem("isShowCurrentBudget" ) || true);
      // this.isShowCharts          = JSON.parse(localStorage.getItem("isShowCharts" ) || true);
      // this.isShowRightPanel      = JSON.parse(localStorage.getItem("ticketShowRightPanel" ) || true);
      this.scrollToTop();
    },
    computed: {
      groupedTasks() {
        return this.ticketTaskList.reduce((acc, task) => {
          const year = task.budgetYear || 'Unknown'; // Handle cases where budgetYear might be undefined
          if (!acc[year]) {
            acc[year] = [];
          }
          acc[year].push(task);
          return acc;
        }, {});
      },
      descriptionRows() {
        if( this.ticket.status === 'complete' ) {
          return 4;
        }
        if( !this.ticket.description ) {
          return 4;
        }
        let result = this.ticket.description.split( "\n" );
        if( result.length > 12) {
          return 18;
        }
        if( result.length > 4) {
          return 12;
        }
        return 4;
      },
      ticketTaskList() {
        if( this.taskOptions.showCompletedTasks === true) {
          return _.sortBy( this.ticket.steps , ["sequence"] );
        }
        let tl =  this.ticket.steps.filter((t) => {return t.complete !== true});
        return _.sortBy( tl , ["sequence"] );
      },
      hasNotifyFlags() {
        if( !this.ticket || !this.ticket.client || !this.ticket.client.options ) {      // nothing to test, so no flags.
          return false;
        }
        if( this.ticket.client.options.notifyBeforeArrival ||
            this.ticket.client.options.notifyOnAssignment ||
            this.ticket.client.options.notifyOnScheduleChange ||
            this.ticket.client.options.notifyOnActivity ||
            this.ticket.client.options.notifyOnComplete ) {
          return true;
        }
        return false;
      },
      allowedAccountClasses() {
        if( ! this.ticket.ticketType ) {
          return [];
        }
        let tt =  _.find( this.ticketConfig.ticketType , { key: this.ticket.ticketType });
        if( tt ) {
          return tt.posting.allowedAccountClasses;
        }
        return [];
      },
      allowedTotalClasses() {
        if( ! this.ticket.ticketType ) {
          return [];
        }
        let tt =  _.find( this.ticketConfig.ticketType , { key: this.ticket.ticketType });
        if( tt ) {
          return tt.posting.allowedTotalClasses;
        }
        return [];
      },
      allowedRevenueCenters() {
        if( ! this.ticket.ticketType ) {
          return [];
        }

        let tt =  _.find( this.ticketConfig.ticketType , { key: this.ticket.ticketType });
        if( tt ) {
          return tt.posting.allowedRevenueCenters;
        }
        return [];
      },
      allowedTenderNumbers() {
        if( ! this.ticket.ticketType ) {
          return [];
        }
        let tt =  _.find( this.ticketConfig.ticketType , { key: this.ticket.ticketType });
        if( tt ) {
          return tt.posting.allowedTenderNumbers;
        }
        return [];
      },
      isEnableClientNotifyTab() {
        if( ! this.ticket.ticketType ) {
          return false;
        }
        if( this.hasNotifyFlags ) {   // if we have any flags, then we should allow the tab because we may want to alter the flags. (or even turn them off)
          return true;
        }
        let tt =  _.find( this.ticketConfig.ticketType , { key: this.ticket.ticketType });
        if( tt ) {
          return tt.options.enableClientNotify;
        }
        return true;
      },
      isClientAssignment() {
        if( ! this.ticket.ticketType ) {
          return true;
        }
        let tt =  _.find( this.ticketConfig.ticketType , { key: this.ticket.ticketType });
        if( tt ) {
          return tt.options.enableClientAssignment;
        }
        return true;
      },
      taskCount() {
        return this.ticket.steps.filter((t) => {return t.stepType==='task'}).length;
      },
      tasksCompleted() {
        return this.ticket.steps.filter((t) => {return t.complete === true}).length;
      },
      statusStyle() {
        let c;
        switch( this.ticket.status ) {
          case "new":
            c = "background:orange;color:white;";
            break;

          case "open":
            c = "background:green;color:white;";
            break;

          case "in-process":
            c = "background:#9C27B0;color:white;";
            break;

          case "complete":
            c = "background:#333;color:white;";
            break;

          case "stuck":
            c = "background:#d25a53;color:white;";
            break;

          case "parked":
            c = "background:#1B76FF;color:black;";
            break;

            default:
              c = "background:#8B63A6;color:white;";
        }

        return "border-radius:.75em;" + c;
      },
      priorityStyle() {
        let c = "green";
        switch (this.ticket.priority) {
          case "low"         : c = "background:gray;color:black;"; break;
          case "normal"      : c = "background:green;color:white;"; break;
          case "high"        : c = "background:#FA9600;color:white;"; break;
          case "critical"    : c = "background:#FF2D00;color:white;"; break;
          default            : c = "background:black;color:white;"; break;
        }
        return "border-radius:.75em;" + c;
      }
    },
    methods: {
      done() {
        if( this.task ) {
          this.taskSave();
          return;
        }
        this.$emit("done");
      },
      removeProjectFromList( p ) {
        if( this.ticket.projects && this.ticket.projects.length > 0 ) {
          let i = this.ticket.projects.findIndex( (pr) => { return pr.id === p.id });
          if( i >= 0 ) {
            this.ticket.projects.splice(i,1);
          }
        }
      },
      budgetYears() {
        if( !this.ticket.projectId) {
          return [this.ticket.budgetYear];
        }

        let project = this.masterProjectList.find( (p) => { return p.value === this.ticket.projectId });

        if( !project ) {
          return [this.ticket.budgetYear];
        }
        return project.budgets.map( (b) => { return b.budgetYear });

      },
      attachProject(id) {

        if( !id ) {
          if( !this.attachProjectId ) {
            this.attachProjectId = this.ticket.projectId;      // point to our main project id.
          }
          // this.attachProjectId = null;      // ensure we start fresh.
          this.isEditAttachProject = true;
          return;
        }

        let found = this.masterBudgetProjectList.find( (p) => { return p.value === id });

        if(!found) {
          this.isEditAttachProject = false;
          return;
        }

        if( this.ticket.projects.find( (p) => { return p.id === found.value }) ) {
          this.$root.warningMessage( "Oops...","That project is already attached");
          return;
        }

        this.isEditAttachProject = false;

        let project = {
          id    : found.value,
          title : found.text,
          milestone: "",
          actual: {
            hours   : 0,
            labor   : 0,
            parts   : 0,
            supplies: 0,
            expenses: 0,
            outside : 0,
            payments: 0,   // payments and deposits aren't actually used currently (30-May-24)
            deposits: 0,
            total   : 0,
          },
          estimated: {
            hours   : 0,
            labor   : 0,
            parts   : 0,
            supplies: 0,
            expenses: 0,
            outside : 0,
            total   : 0,
          },
        }
        this.ticket.projects.push( project );
        this.ticketSetChanged();
      },
      getTaskOptionSelections()
      {
        let t = localStorage.getItem("taskOptions");
        if( t ) {
          this.taskOptions = JSON.parse(t);
        }
      },
      ticketHasPayments() {
        return this.ticket.steps.some(step => step.stepType === "transaction");
      },
      isNotifyBeforeArrival() {
        if( !this.ticket.client ) {
          return false;
        }
        if( !this.ticket.client.options ) {
          return false;
        }
        return this.ticket.client.options.notifyBeforeArrival;
      },
      async transactionAdd() {
        if( this.isTicketChanged ) {
          await this.saveTicket(this.ticket, false);    // save the ticket, don't leave the edit
        }

        this.isManualTransaction = true;
        this.$refs['manual-transaction'].show();
      },
      async postedTransaction(e) {
        this.isManualTransaction = false;
        this.$refs['manual-transaction'].hide();

        this.activeMainTab = 0;
        await this.getTicket(this.ticket._id);     // todo: figure out how to update the step and transactions.

      },
      cancelTransaction(e){
        this.isManualTransaction = false;
        this.$refs['manual-transaction'].hide();
      },
      hasTransactions() {
        if( ! this.ticket || !this.ticket.steps || this.ticket.steps.length === 0 ) {
          return false;
        }
        if( this.ticket.steps.filter( (t) => { return t.stepType === 'transaction' }).length > 0 ) {
          return true;
        }
        return false;
      },
      async getManualTransactions() {

        // todo: might want to smartly retrieve if we don't have one yet.

        try {
          this.isLoading = true;
          let response         = await TransactionService.transactionListByOwnerId(this.ticket._id);
          this.transactionList = response.data;
        } catch( error ) {
          console.log("getManualTransactions() Error", error);
        }
        this.isLoading = false;
      },
      taskOptionSelectionsChanged() {
        localStorage.setItem("taskOptions", JSON.stringify(this.taskOptions));
      },
      scrollToTop() {
        let t = this;
        let doc = document.getElementById("__MainContentPanel__");
        t.$nextTick(() => {
          doc.scrollTop = 0;
        });
      },
      canUserBeAssigned( user ) {
        // if( user._id === this.ticket.assignedToId ) {
        //   return false;
        // }

        if( this.ticket.assignedToId === user._id && user.alias === this.ticket.assignedTo ) {    // already assigned
          return false;
        }
        return true;      // just show the list - we'll only send alerts if the id changes so this won't matter if it's reassigned to the same person...

        // if( !this.ticket.assignedToId ) {   // ticket is NOT currently assigned, so every one can.
        //   return true;
        // }
        // if( (this.ticket.assignedTo == user.alias) && ((this.ticket.assignedToEmail == user.email) || (this.ticket.assignedToEmail == user.notificationEmail))) {
        //   return false;
        // }
        // return true;

      },
      canEditTaskBudget() {
        if( this.ticket.hours+this.ticket.ticketTotal !== 0 ) {
          return false;// if we have any values, budget is blocked, regardless if we can edit or not
        }
        if( this.ticket.changeOrders.length>0 ) {
          return false;
        }
        return true;
      },
      uploadFolder() {
        if( !this.ticket) {
          return "tickets/0000000000";
        }
        return "tickets/" + this.ticket._id;
      },
      async editAccount( client ) {
        if( !this.$root.isAdmin()) {
          return;
        }
        let accountNumber = client.clientAccountNumber.toUpperCase();
        if( ! accountNumber ) {
          return;
        }
        if( await this.$root.confirmYesNo( "Would you like to edit this account?" ) ) {
          let route = `/app/accountEdit/${encodeURIComponent(accountNumber)}`;
          await this.$router.push(route);
        }
      },
      async getFiles(force=true) {
        try {
          if( ! this.ticket ) {
            return;
          }

          if( this.files && !force ) {
            return;
          }

          this.files = null;
          let folder = this.uploadFolder(); // this.uploadFilder + this.ticket.ticketNumber;

          let response  = await FileService.getFiles( folder );

          this.files        = response.data.entries;
          this.downloadLink = response.data.downloadLink;

          this.fileRefresh = false;

        } catch(error) {
          console.log("getFiles() Error", error);
          await this.$root.alert(error.message + " Your Files May Not Be Present - Please Call Support");
          this.files      = [];
          this.fileRefresh    = true;
          this.$root.errorMessage("Oops", error.response.data.message || error.message);
        }
      },
      printTicket() {
        this.showTicket = true;
      },
      printInvoice() {
        this.showInvoice = true;
      },
      async vendorSearch( s ) {
        let response = await VendorService.liveSearch( s );
        return response.data;
      },
      taskSearchVendor() {
        if( this.isReadOnly()) {
          return;
        }
        this.taskVendorLookupModalShow = true;
      },
      removeVendorFromTask() {
        this.task.vendorId   = null;
        this.task.vendorName = "";
        this.taskVendorLookupModalShow = false;
        this.ticketSetChanged();
      },
      assignVendorToTask( e ) {
        if( !e) {
          return;
        }
        this.task.vendorId = e._id;
        this.task.vendorName = `${e.name}${(e.primary.city) ? ' : ' + e.primary.city + ', ' + e.primary.state || '' : ''}`
        this.taskVendorLookupModalShow = false;
        this.ticketSetChanged();
      },
      async taskQuickAddChanged() {
        if( this.taskQuickAddTitle.trim().length == 0 ) {
          return;
        }
        this.taskAdd( this.taskQuickAddTitle , false);
        this.taskQuickAddTitle = "";
        this.$nextTick(() => {
          this.$refs["task-quick-add"].focus();
        });
      },
      toggleShowProjectStatus() {
        this.showProjectStatus = !this.showProjectStatus;
        localStorage.setItem("showProjectStatus", JSON.stringify(this.showProjectStatus));
      },
      toggleShowPerformanceStatus() {
        this.showPerformanceStatus = !this.showPerformanceStatus;
        localStorage.setItem("showPerformanceStatus", JSON.stringify(this.showPerformanceStatus));
      },
      toggleShowTotalStatus() {
        this.showTotalStatus = !this.showTotalStatus;
        localStorage.setItem("showTotalStatus", JSON.stringify(this.showTotalStatus));
      },
      toggleShowBudgetGL(){
        localStorage.setItem("isShowBudgetGL", JSON.stringify(this.isShowBudgetGL));
      },
      toggleShowOriginalBudget(){
        localStorage.setItem("isShowOriginalBudget", JSON.stringify(this.isShowOriginalBudget));
      },
      toggleShowCurrentBudget(){
        localStorage.setItem("isShowCurrentBudget", JSON.stringify(this.isShowCurrentBudget));
      },
      toggleShowCharts(){
        localStorage.setItem("isShowCharts", JSON.stringify(this.isShowCharts));
      },
      toggleShowRightPanel() {
        this.isShowRightPanel=!this.isShowRightPanel
        localStorage.setItem("ticketShowRightPanel", JSON.stringify(this.isShowRightPanel));
      },
      projectTotal() {
        try {
          let total = this.ticket.ticketTotal;
          return total
        } catch( error ) {
          console.log( "projectTotal error", error );
          return 0;
        }
      },
      projectEstimatedTotal() {
        try {
          let total = this.ticket.adjustedBudget.total;
          return total;
        } catch( error ) {
          console.log( "projectEstimatedTotal", error );
          return 0;
        }
      },
      // calculateTaskTotals( ticket = this.ticket ) {   // case:936 Do something with this thing - it's simple

      //   let ttl = {
      //     hours   : 0,
      //     labor   : 0,
      //     parts   : 0,
      //     supplies: 0,
      //     expenses: 0,
      //     outside : 0,
      //     total   : 0,
      //   };
      //   ticket.steps.forEach((step) => {
      //     if( step.stepType === 'task' ) {
      //       ttl.hours    = Currency(ttl.hours).add(step.hours).value;
      //       ttl.labor    = Currency(ttl.labor).add(step.labor).value;
      //       ttl.parts    = Currency(ttl.parts).add(step.parts).value;
      //       ttl.supplies = Currency(ttl.supplies).add(step.supplies).value;
      //       ttl.expenses = Currency(ttl.expenses).add(step.expenses).value;
      //       ttl.outside  = Currency(ttl.outside).add(step.outside).value;
      //       ttl.total    = Currency(ttl.total)
      //         .add(Currency(step.hours).multiply(ticket.hourlyRate))
      //         .add(step.labor)
      //         .add(step.parts)
      //         .add(step.supplies)
      //         .add(step.expenses)
      //         .add(step.outside).value;
      //     }
      //
      //     if( step.stepType === 'transaction' ) {
      //       ttl.payments = Currency(ttl.payments).add(step.labor).add(step.parts).add(step.supplies).add(step.expenses).add(step.outside).value;
      //     }
      //
      //   });
      //   this.taskTotals  = TicketsLib.calculateTaskTotals( ticket );
      //   return this.taskTotals;
      // },
      calculateTicketTotals( ticket = this.ticket ) {
        if( ! ticket ) {
          return;
        }

        // this.calculateTaskTotals(ticket);
        ticket = TicketsLib.calculateTicketTotals(ticket);
        this.taskTotals = ticket.taskTotals;

        // this.budgetYearsTotals = TicketsLib.calculateBudgetYearTotals(this.budgetYears(), ticket);
        this.taskBudgetTotals = TicketsLib.calculateTaskTotalsByBudgetYear( ticket );

        return ticket;
      },
      calculateTaskTotalsByBudgetYear( ticket ) {
        return TicketLib.calculateTaskTotalsByBudgetYear(ticket);
      },
      calculateChangeOrderTotals(ticket = this.ticket ){
        if( ticket ) {
          TicketsLib.calculateChangeOrderTotals(ticket)
        }
      },
      hourlyRateChanged() {
        this.calculateChangeOrderTotals()
        this.calculateTicketTotals();
      },
      roomDetails( r) {

        if( ! r.isOccupied ) {
          return "(vacant)";
        }

        if( r.account.length === 0 ) {
          return "(**vacant)";
        }

        let result = "";
        r.account.forEach( (a) => {
          result += "/" + a.accountTitle;
        });

        return result;
      },
      currency(c) {
        return Currency(c);
      },
      USD( value ) {
        return Currency(value, { precision: 2, symbol: ' ' }).format();
      },
      USDnz( value ) {
        if( !value ) {
          return "";
        }
        return Currency(value, { precision: 2, symbol: ' ' }).format();
      },
      async cancelEdit() {
        if( this.isTicketChanged ) {
          if( ! await this.$root.confirmYesNo( "You made changes, continue to leave?" )) {
            return;
          }
        }
        this.ticketResetChanged();
        // this.isTicketChanged = false;
        this.isTicket = false;
        this.$emit("done");
      },
      doTicketPrintCancel() {
        this.showTicket = false;
        this.showInvoice = false;
      },
      doTicketPrint() {
        window.print();
      },
      removeSearchQuery: function() {
        this.searchQuery = '';
        this.isResult = false;
      },
      hideFilter() {
        this.isShowFilter = false;
      },
      // listStyleSave(e) {
      //   localStorage.setItem("ticketListStyle", e);
      // },
      hasNewTask() {
        return true;
      },
      taskTitle() {
        if( ! this.ticket.steps || this.taskCount===0) {
          return "Tasks/Steps";
        }

        return `Tasks/Steps (${this.tasksCompleted}/${this.taskCount})`;
        // return this.ticket.steps.filter((t) => {return t.complete !== true}).length;
      },
      numberOfTasks() {
        return this.ticket.steps.length || 0;
      },
      taskMarkComplete(t) {
        t.complete      = true;
        t.completedById = this.$root.user.tenantUser;
        this.ticketSetChanged();
      },
      taskMarkIncomplete(t) {
        t.complete      = false;
        this.ticketSetChanged();
      },
      async taskRemove( t ) {
        if( ! await this.$root.confirmYesNo( `Remove: ${t.title}` )) {
          return;
        }

        if( t.stepType === 'transaction' ) {

          if( ! this.$root.isAdmin() ) {
            await this.$root.alert( "Unable to remove this transaction at this time. (Admin Required)" , "warning");
            return;
          }

          if( ! await this.$root.confirmYesNo( `This is a payment.  Are you sure you want to remove it?` )) {
            return;
          }

          if( ! t.transactionId ) {
            if( !await this.$root.confirmYesNo( "Cannot automatically remove the TRANSACTION, remove this step anyway?")) {
              return;
            }
          } else {
            const providedDate = Moment.tz(t.completedAt, this.$root.TZ);
            const date2DaysAgo = Moment.tz(this.$root.TZ).subtract(2, 'days');

            if(providedDate.isBefore(date2DaysAgo)) {
              if( !await this.$root.confirmYesNo("This transaction is more than 2 days old.<br><br>Are you sure?<br><br><span style='font-size:.8em;color:red;'>Posting a credit transaction.</span>" )) {
                return;
              }
            }

            try {     // not doing anything with this error
              let response = await TransactionService.transactionDelete(t.transactionId);
            } catch ( error ) {
              await this.$root.alert( "Error removing the transaction, the step will still be removed; <br>" + error.message );
              console.log( error.message );
            }
          }
        }


        let taskId = t._id;
        let co;

        if( this.ticket.changeOrders && this.ticket.changeOrders.length>0) {
          co = _.findIndex( this.ticket.changeOrders, (co) => {return  co.step.id == taskId });
          if( co >= 0 ) {
            if( ! await this.$root.confirmYesNo( `This task has change orders which will be removed.  Continue with delete?` )) {
              return;
            }
          }
        }

        let index = _.findIndex( this.ticket.steps , { _id: t._id });
        if( index >= 0) {
          this.ticket.steps.splice( index , 1 );
          this.ticketSetChanged();
          if( co ) {
            _.remove( this.ticket.changeOrders, (c) => {return c.step.id === taskId} );
          }
        } else {
          await this.$root.alert( "Please save this ticket first, then try to remove this task" , "warning");
        }

      },
      async ticketEdit(id ) {
        if( this.isTicketChanged ) {
          this.$root.warningToast("Please save your changes before editing another ticket", "Notice");
          return;
        }
        this.activeMainTab = 0;
        await this.getTicket(id);
        // this.$emit( "edit" , id );
      },
      taskEdit(t) {
        this.activeMainTab = 1;
        this.task          = t;
        this.isEditTask    = true;
      },
      async vendorEdit(t) {
        // todo: edit the vendor when clicked...
        // console.log("vendorEdit in TicketEdit", t);
        // if( t.vendorId ){
        //   await this.$root.alert(`Edit Vendor${t.vendorName}`);
        // }
        this.$root.alert( "Vendor not yet implemented" , "warning");
      },
      async taskClone(task) {

        if( ! task.title ) {
          await this.$root.alert( "Please enter a title for this task before cloning" , "warning");
          return;
        }

        if( ! await this.$root.confirmYesNo( `Clone: ${task.title}` )) {
          return;
        }

        let newTask = Object.assign( {} , task );
        newTask.title += " (clone)";

        this.ticket.steps.map((c) => {      // move all entries above what we're inserting up one
          if( c.sequence > task.sequence ) {
            c.sequence++;
          }
        })

        let newId;
        try {
          let response = await GeneralService.getNewId();
          newId        = response.data.id;
        } catch( error ) {
          await this.$root.alert( "Task not cloned. Had an error getting new ID" , "error");
          return;
        }

        newTask.sequence = task.sequence+1;
        newTask.stepId   = Math.max(...this.ticket.steps.map(t => t.stepId), 0) + 1;
        newTask.complete = false;
        newTask._id      = newId

        //newTask.sequence = Math.max(...this.ticket.steps.map(t => t.sequence), 0) + 1;
        this.ticket.steps.push( newTask );
        this.calculateTicketTotals(); // if no ticket passed in, works on current ticket.
        this.ticketSetChanged();

      },
      taskSave() {
        if( ! this.task ) {
          return;
        }
        // this.ticket.steps = _.sortBy( this.ticket.steps , ["sequence"] );
        this.ticket.steps = _.sortBy( this.ticket.steps ,(t) => { return parseInt(t.sequence,10) || 0 });
        this.calculateTicketTotals();
        this.ticket = this.verifyChangeOrders(this.ticket);

        this.task              = null;
        this.isEditTask        = false;
      },
      taskCancel() {
        this.isEditTask        = false;
        this.calculateTicketTotals();
        this.task              = null;
      },
      async taskAdd( title = "new" , editTask = true) {

        let newId;
        try {
          let response = await GeneralService.getNewId();
          newId        = response.data.id;
        } catch( error ) {
          await this.$root.alert( "Task not cloned. Had an error getting new ID" , "error");
          return;
        }

        let task      = {
          _id           : newId,
          stepType      : 'task',
          title         : title,
          category      : "",
          complete      : false,
          description   : "",
          budgetYear    : this.ticket.budgetYear,   // 23-Jun-24 not currently using in the tasks specifically yet.
          projectId     : this.ticket.projectId,
          laborRate     : this.ticket.hourlyRate,
          hours         : 0,
          labor         : 0,
          parts         : 0,
          supplies      : 0,
          expenses      : 0,
          outside       : 0,
          scheduledDate : null,
          total         : 0,
          estimatedHours: 0,
          sequence      : 0,
          step          : {
            id    : null,
            title : "",
            stepId: null
          }
        };
        task.sequence = Math.max(...this.ticket.steps.map(t => t.sequence), 0) + 1;
        task.stepId   = Math.max(...this.ticket.steps.map(t => t.stepId), 0) + 1;
        this.ticket.steps.push(task);
        this.isEditTask = editTask;
        this.ticketSetChanged();
        if(editTask) {
          this.task = task;
        }

      },

      addChangeOrder(task) {
        let suffix = 1;
        if( this.ticket.changeOrders.length > 0 ) {
          suffix = parseInt(this.ticket.changeOrders[this.ticket.changeOrders.length-1].orderNumber.split('-')[1])+1;
        }
        this.changeOrder = {
          orderDate   : Moment().tz(this.$root.TZ).format("YYYY-MM-DD"),
          orderNumber : this.ticket.ticketNumber + "-" + (suffix).toString(),
          orderType   : 'change',
          total       : 0,
          hours       : 0,
          labor       : 0,
          parts       : 0,
          supplies    : 0,
          expenses    : 0,
          outside     : 0,
          reason      : "",
          requestedBy : this.$root.user.alias,
          approvedBy  : this.$root.user.alias,
          approvedDate: Moment().tz(this.$root.TZ).format("YYYY-MM-DD"),
          updatedAt   : Moment().tz(this.$root.TZ).toDate(),
          step        : { id:null, title: "", budgetYear: "" }
        };

        if( task ) {
          this.changeOrder.step.id         = task._id;
          this.changeOrder.step.title      = task.title;
          this.changeOrder.step.budgetYear = task.budgetYear;
        }

        this.isShowCurrentBudget  = true;

        this.isEditChangeOrder = true;
        this.ticketSetChanged();
      },

      editChangeOrder( co , k ) {
        this.changeOrder = co;
        this.isEditChangeOrder = true;
      },

      async deleteChangeOrder( index ) {
        if( ! await this.$root.confirmYesNo( `Delete Change Order?<br>${this.ticket.changeOrders[index].orderNumber}` )) {
          return;
        }

        this.ticket.changeOrders.splice( index , 1 );

        this.ticketSetChanged();
        TicketsLib.calculateChangeOrderTotals(this.ticket);

      },

      async saveChangeOrder() {
        this.changeOrder.total =  Currency(this.changeOrder.labor).add(this.changeOrder.parts).add(this.changeOrder.supplies).add(this.changeOrder.expenses).add(this.changeOrder.outside).value;
        if(( this.changeOrder.total === 0 ) && (this.changeOrder.hours === 0 )){
          await this.$root.alert( "Please enter some valid values" , "warning");
          return;
        }

        let co;

        this.changeOrder.updatedAt = Moment();

        if( !this.changeOrder._id && (_.findIndex(this.ticket.changeOrders, {orderNumber: this.changeOrder.orderNumber}) < 0 )) {
          co = Object.assign( {} , this.changeOrder );
          this.ticket.changeOrders.push( co );
        }

        this.isEditChangeOrder = false;
        this.changeOrder       = null;
        this.ticketSetChanged();
        TicketsLib.calculateChangeOrderTotals(this.ticket);     // now integrate that into the ticket

      },

      ticketServiceTypes() {
        if( this.ticket.ticketType ) {
          let tt =  _.find( this.ticketConfig.ticketType , { key: this.ticket.ticketType });
          if( tt ) {
            return tt.serviceTypes;
          }
        }
        return ['standard'];
      },

      defaultServiceType() {
        if( this.ticket.ticketType ) {
          let tt =  _.find( this.ticketConfig.ticketType , { key: this.ticket.ticketType });
          if( tt ) {
            return tt.defaultServiceType;
          }
        }
        return 'standard';
      },


      ticketCanPost() {
        if( ! this.ticket.ticketType ) {
          return false;
        }
        let tt =  _.find( this.ticketConfig.ticketType , { key: this.ticket.ticketType });
        if( tt ) {
          return (tt.posting.tenderNumber > 0) && tt.options.enableBilling;
        }
        return false
      },
      ticketCanClose() {
        if( ! this.ticket.ticketType ) {
          return false;
        }
        let tt =  _.find( this.ticketConfig.ticketType , { key: this.ticket.ticketType });
        if( tt ) {
          return tt.options.allowClose || false;
        }
        return false
      },
      isReadOnly() {
        if( this.ticket.closed ) {
          return true;
        }
        if( ! this.isMyTicketType( this.ticket.ticketType )) {  // FIXME: This should be addressed ... we're not assigning to USERS, but to ACCOUNTS so this won't work
          if( this.ticket.assignedToId !== this.$root.user.tenantUser ) {
            return true;
          }
        }
        return false;
      },

      isUnitAssignment() {
        if( ! this.ticket.ticketType ) {
          return false;
        }
        let tt =  _.find( this.ticketConfig.ticketType , { key: this.ticket.ticketType });
        if( tt ) {
          return tt.options.enableUnitAssignment ;
        }
        return false;
      },
      isProject() {   // this looks inside the TICKET CONFIG, not FEATURES.
        if( ! this.ticket.ticketType ) {
          return false;
        }
        let tt =  _.find( this.ticketConfig.ticketType , { key: this.ticket.ticketType });
        if( tt ) {
          return tt.options.enableProjects;
        }
        return false;
      },
      isFinancials() {   // this looks inside the TICKET CONFIG, not FEATURES.
        if( ! this.ticket.ticketType ) {
          return false;
        }
        let tt =  _.find( this.ticketConfig.ticketType , { key: this.ticket.ticketType });
        if( tt ) {
          return tt.options.enableFinancials;
        }
        return false;
      },
      isChangeOrders() {
        if( ! this.ticket.ticketType ) {
          return false;
        }
        let tt =  _.find( this.ticketConfig.ticketType , { key: this.ticket.ticketType });
        if( tt ) {
          return tt.options.enableChangeOrders;
        }
        return false;
      },
      isBudget() {
        if( ! this.ticket.ticketType ) {
          return false;
        }
        let tt =  _.find( this.ticketConfig.ticketType , { key: this.ticket.ticketType });
        if( tt ) {
          return tt.options.enableBudgets;
        }
        return false;
      },
      hasUnit() {
        if( ! this.ticket ) {
          return false;
        }
        if( ! this.ticket.links ) {
          return false;
        }
        if( ! (typeof this.ticket.links[ 'unitId' ] === undefined )) {
          return this.ticket.links.unitId > "";
        }
        return false;
      },
      hasBuilding() {
        if( ! this.ticket ) {
          return false;
        }
        if( ! this.ticket.links ) {
          return false;
        }

        if( ! (typeof this.ticket.links[ 'buildingId' ] === undefined )) {
          return this.ticket.links.buildingId > "";
        }
        return false;
      },
      availableUsers() {
        // fixme: is this the "assignment" list and where is it used?
        // return this.users.filter((u) => { return u.ticketTypes.indexOf( this.ticketType ) >= 0 }) ;
        return this.assignToUsers;
      },
      projectPriorityList() {
        if( ! this.ticket.ticketType || !this.ticketConfig.project || !this.ticketConfig.project.priorities ) {
          return null;
        }
        return this.ticketConfig.project.priorities;
      },
      ticketCategoryList() {
        if( ! this.ticket.ticketType ) {
          return null;
        }
        let tt =  _.find( this.ticketConfig.ticketType , { key: this.ticket.ticketType });
        if( tt ) {
          return tt.categories;
        }
        return null;
      },
      taskCategoryList() {
        if( ! this.ticket.ticketType ) {
          return null;
        }
        let tt =  _.find( this.ticketConfig.ticketType , { key: this.ticket.ticketType });
        if( tt ) {
          return tt.taskCategories;
        }
        return null;
      },
      ticketHasParts() {
        if( ! this.ticket.ticketType ) {
          return false;
        }
        let tt =  _.find( this.ticketConfig.ticketType , { key: this.ticket.ticketType });
        if( tt ) {
          return tt.options.enableParts || false;
        }
        return true;
      },
      ticketHasPriority() {
        if( ! this.ticket.ticketType ) {
          return false;
        }
        let tt =  _.find( this.ticketConfig.ticketType , { key: this.ticket.ticketType });
        if( tt ) {
          return tt.options.enablePriority || false;
        }
        return true;
      },
      ticketHasServiceType() {
        if( ! this.ticket.ticketType ) {
          return false;
        }
        let tt =  _.find( this.ticketConfig.ticketType , { key: this.ticket.ticketType });
        if( tt ) {
          return tt.options.enableServiceType || false;
        }
        return true;
      },
      ticketAllowsComments() {
        if( ! this.ticket.ticketType ) {
          return false;
        }
        let tt =  _.find( this.ticketConfig.ticketType , { key: this.ticket.ticketType });
        if( tt ) {
          return tt.options.enableComments || false;
        }
        return true;
      },
      ticketAllowsClone() {
        if( ! this.ticket.ticketType ) {
          return false;
        }
        let tt =  _.find( this.ticketConfig.ticketType , { key: this.ticket.ticketType });
        if( tt ) {
          return tt.options.enableClone || false;
        }
        return true;
      },
      ticketAllowsManualTransactions() {
        if( ! this.ticket.ticketType ) {
          return false;
        }
        let tt =  _.find( this.ticketConfig.ticketType , { key: this.ticket.ticketType });
        if( tt ) {
          return tt.options.allowManualTransactions || false;
        }
        return true;
      },
      ticketAllowsFiles() {
        if( ! this.ticket.ticketType ) {
          return false;
        }
        let tt =  _.find( this.ticketConfig.ticketType , { key: this.ticket.ticketType });
        if( tt ) {
          return tt.options.enableFiles || false;
        }
        return true;
      },
      isCommentsOnMainScreen() {
        if( ! this.ticket.ticketType ) {
          return false;
        }
        let tt =  _.find( this.ticketConfig.ticketType , { key: this.ticket.ticketType });
        if( tt ) {
          return tt.options.commentsOnMainScreen || false;
        }
        return true;
      },
      ticketAllowsTasks() {
        if( ! this.ticket.ticketType ) {
          return false;
        }
        let tt =  _.find( this.ticketConfig.ticketType , { key: this.ticket.ticketType });
        if( tt ) {
          return tt.options.enableTasks || false;
        }
        return true;
      },

      isMyTicketType( t ) {
        if( this.myTicketTypes ) {
          return this.myTicketTypes.indexOf(t) >= 0
        }
        return false;
      },
      myFilteredTicketTypes() {
        return this.ticketConfig.ticketType.filter((t) => { return this.isMyTicketType( t.key ) });
      },
      ticketTypesList() {
        return this.myFilteredTicketTypes().map((t) => {return { value: t.key, text: t.title }} );
      },
      userName( id ) {
        //fixme: username?
        let n = _.find( this.users , {_id: id });
        return (n) ? n.user.alias : "" ;
      },
      ticketTypeTitle( ticketType ) {
        if( !ticketType || !this.ticketConfig ) {
          return "** (undefined)"
        }
        let user = "";
          if( this.propUser === 'unassigned' ) {
            user =  "Unassigned";
          }
          if( this.propUser ) {
            user = " [" + this.userName(this.propUser) +"]";
          }

        let t = _.find( this.ticketConfig.ticketType , { key: ticketType });
        return (t) ? t.title + user : "** " + (ticketType || "");
      },
      ticketTypeTitleEx(ticketType) {
        if (!ticketType || !this.ticketConfig) {
          return "** (undefined)"
        }
        let t = _.find(this.ticketConfig.ticketType, { key: ticketType });
        return (t) ? t.title : "** " + (ticketType || "");
      },
      async clearUser() {
        this.propUser = null;
        this.ticketType = "";   // force the first time to read
        await this.selectTicketType( this.myTicketTypes[0] );
      },
      ticketSearchClasses( ticketType ) {
        this.searchMultipleClasses = false;
        let t = _.find( this.ticketConfig.ticketType , { key: ticketType });
        if( ! t ) {
          return 'resident';
        }
        let result = t.searchClasses.join(",");
        if( t.searchClasses.length > 1 ) {
          this.searchMultipleClasses = true;
        }
        return result;
      },
      tagValidator(tag) {
        return tag === tag.toLowerCase() && tag.length > 1 && tag.length < 20
      },
      async getMyTicketTypes() {

        try {
          let response = await UserService.getTicketTypes();
          let results = response.data;

          if (results) {
            this.myTicketTypes = results;
            if (this.myTicketTypes.length === 1) {
              this.ticketType = "";   // force the first time to read
              await this.selectTicketType(this.myTicketTypes[0]);
            } else {
              if (this.propTicketType) {  // 04-Jun-20 wjs
                this.ticketType = "";
                await this.selectTicketType(this.propTicketType);
              }
            }
          }
        } catch( error ) {
          console.log( error.message );
        }
      },
      async getUserOptions() {
        try {
          let response = await UserService.getUserOption( "ticket" , "filter" );
          let result = response.data;
          if( result ) {
            this.showClosed   = result.showClosed;
            this.showParked   = result.showParked;
            this.showStuck    = result.showStuck;
            this.showComplete = result.showComplete;
          }
        } catch( error ) {
          console.log( error.message );
        }
      },
      async saveUserOptions() {
        try {
          let data  = {
            showClosed: this.showClosed,
            showParked: this.showParked,
            showStuck: this.showStuck,
            showComplete: this.showComplete
          }
          await UserService.setUserOption("ticket" , "filter" , data );
        } catch( error ) {
          console.log( "saveUserOptions()" , error.message );
        }
      },
      assignClient( user ) {

        let c = this.ticket.client;
        if( ! this.user ) {
          return;
        }

        c.clientType          = this.user.accountType;
        c.clientId            = this.user._id;
        c.clientAccountNumber = this.user.accountNumber || "";
        c.clientBadgeNumber   = this.user.badgeNumber || "";
        c.clientName          = this.user.badgeTitle || "";
        c.clientPictureUrl    = this.user.pictureUrl || "";
        c.clientBuilding      = this.user.buildingNumber || "";
        c.clientRoomNumber    = this.user.roomNumber || "";
        c.clientPhone         = this.user.badgePhone || "";
        c.clientMobile        = this.user.badgePhone2 || "";
        c.clientEmail         = this.user.badgeEmail || "";
        c.glAccount           = this.user.glAccount  || "";

        c.clientMoveInDate = this.user.moveInDate;

        if( this.user.address ) {
          c.clientAddress1 = this.user.address.address1 || "";
          c.clientAddress2 = this.user.address.address2 || "";
          c.clientCity     = this.user.address.city || "";
          c.clientState    = this.user.address.state || "";
          c.clientZip      = this.user.address.zip || "";
        }

        if( this.user.helpdesk ) {
          c.clientNotes = this.user.helpdesk.notes;
          c.options.notifyBeforeArrival    = this.user.helpdesk.notifyBeforeArrival;
          c.options.notifyOnAssignment     = this.user.helpdesk.notifyOnAssignment;
          c.options.notifyOnScheduleChange = this.user.helpdesk.notifyOnScheduleChange;
          c.options.notifyOnActivity       = this.user.helpdesk.notifyOnActivity;
          c.options.notifyOnComplete       = this.user.helpdesk.notifyOnComplete;
        }

        let links = this.ticket.links;

        if( user.room ) {
          let room = user.room;
          links.unitId = room._id;
          links.unitType = room.roomModel;
          links.unitTitle = room.title,
          links.roomNumber = room.roomNumber;
        }

        if( user.building ) {
          links.buildingId    = user.building._id;
          links.buildingTitle = user.building.title;
          links.building      = user.building.building;
          links.buildingType  = user.building.housingType;
        }

        this.ticketSetChanged();
        this.showSearch = false;
      },
      assignUnit() {
        let c = this.ticket.client;
        let l = this.ticket.links;

        if( ! this.unit) {
          return;
        }

        let t = this.ticket.links;

        t.unitId = this.unit._id;
        t.unitType = this.unit.housingType;
        t.unitTitle = this.unit.title,
        t.roomNumber = this.unit.roomNumber;

        t.buildingId = this.unit.building._id;
        t.buildingTitle = this.unit.building.title;
        t.building = this.unit.building.building;
        t.buildingType = this.unit.building.housingType;

        this.ticketSetChanged();
        this.showUnitSearch = false;
      },
      assignBuilding() {
        let c = this.ticket.client;
        let l = this.ticket.links;

        if( ! this.building) {
          return;
        }

        let t = this.ticket.links;

        t.unitId     = null;//this.unit._id;
        t.unitType   = "";// this.unit.housingType;
        t.unitTitle  = ""; // this.unit.title,
        t.roomNumber = "";// this.unit.roomNumber;

        t.buildingId    = this.building.building._id;
        t.buildingTitle = this.building.building.title;
        t.building      = this.building.building.building;
        t.buildingType  = this.building.building.housingType;

        this.ticketSetChanged();
        this.showBuildingSearch = false;
      },
      async doSubmitSearch( searchString ) {

        let searchCategory = this.ticketSearchClasses( this.ticket.ticketType );

        try {
          this.isResult = false;
          let response  = await AccountService.liveSearch(searchCategory, searchString, 10);
          this.clientSearchList = response.data;
          this.isResult = true;
          return this.clientSearchList;
        } catch( error ) {
          console.log( error.message );
        }
        return null;
      },
      async doSubmitUnitSearch( searchString ) {

        try {
          this.isResult = false;
          let response  = await RoomService.liveSearch( searchString, null, 10);
          this.unitSearchList = response.data;
          this.isResult = true;
          return this.unitSearchList;
        } catch( error ) {
          console.log( error.message );
        }
        return null;
      },

      async doSubmitBuildingSearch( searchString ) {

        try {
          this.isResult       = false;
          let buildingTypes   = "building,land,parking";
          let response        = await RoomService.liveSearch( searchString, buildingTypes, 10);
          this.unitSearchList = response.data;
          this.isResult       = true;
          return this.unitSearchList;
        } catch( error ) {
          console.log( error.message );
        }
        return null;
      },
      ticketList() {
        var t = this;
        var key;

        return t.tickets.filter( (item) => {

          if (this.categoryFilter) {
            if (this.categoryFilter.indexOf(item.category) >= 0) {
              return true;
            }
          }

          if( item.status === "stuck" && ! t.showStuck && !t.ignoreFilters) {
            return false;
          }

          if (item.status === "parked" && !t.showParked && !t.ignoreFilters ) {
            return false;
          }

          if ((item.status === "complete" && !item.closed) && !t.showComplete && !t.ignoreFilters) {
            return false;
          }

          if (item.closed === true && !t.showClosed && !t.ignoreFilters) {
            return false;
          }

          if (t.itemSearchString === "") {
            return true;
          } else {

            if( item.tags.indexOf( t.itemSearchString ) >= 0 ) {
              return true;
            }

            if( t.searchTitle ) {
              if( item.title.toLowerCase().includes(t.itemSearchString.toLowerCase()) ) {
                return true;
              }
            }

            if( t.searchDescription) {
              if( item.description.toLowerCase().includes(t.itemSearchString.toLowerCase()) ) {
                return true;
              }
            }

            if( t.searchAssigned) {
              if( item.assignedTo.toLowerCase().includes(t.itemSearchString.toLowerCase()) ) {
                return true;
              }
            }

            try {
              if( t.searchResolution ) {
                if( item.resolution !== undefined ) {
                  if( item.resolution.toLowerCase().includes(t.itemSearchString.toLowerCase()) ) {
                    return true;
                  }
                }
              }
            } catch ( error ) {;}


            if( t.searchClient) {
              if( item.client.clientName.toLowerCase().includes(t.itemSearchString.toLowerCase()) ) {
                return true;
              }
              try {
                if( item.client.clientNotes.toLowerCase().includes(t.itemSearchString.toLowerCase()) ) {
                  return true;
                }
              } catch( err ) {;}

              try {
                if( item.client.clientEmail.toLowerCase().includes(t.itemSearchString.toLowerCase()) ) {
                  return true;
                }
              } catch( error ) {;}

              try {
                if( item.client.clientPhone.toLowerCase().includes(t.itemSearchString.toLowerCase()) ) {
                  return true;
                }
              } catch( error ) {;}
            }

            return false;
          }

        })
      },
      moment(d) {
        return Moment(d);
      },
      toggleStatusHelp() {
        this.$bvToast.show('status-toast');
      },
      hasPicture() {
        if( ! this.ticket.assignedToPictureUrl) {
          return false;
        }
        if( this.ticket.assignedToPictureUrl.length>0 ) {
          return true;
        }
        return false;
      },
      myGravatar( email ) {
        return gravatar.url( email );
      },
      getImage( user ) {
        return this.getAccountImage( user );
      },
      getAccountImage( user ) {
        if( user.pictureUrl ) {
          return user.pictureUrl;
        }
        if( user.assignToEmail ) {
          return this.myGravatar( user.notificationEmail);
        }
        return this.myGravatar( user.email );
      },
      clickReply( comment ) {
        this.askComment( comment );
      },
      // totalTicketHours() {
      //   let tl = this.ticketList();
      //   return _.map(tl , (t) => {return t.ticket.hours });
      // },
      // ticketTotal() {
      //   let t = this;
      //   TicketsLib.calculateTicketTotals(this.ticket);
      //   // t.ticket.ticketTotal = Currency(t.ticket.laborTotal).multiply(t.ticket.hours).add(t.ticket.supplyTotal).add(t.ticket.partsTotal).add(t.ticket.outsideTotal).subtract(t.ticket.couponTotal).value;
      //   return t.ticket.ticketTotal;
      // },
      ticketSetChanged() {
        if( !this.isTicketChanged ) {     // only fire the event the first time we're setting this.
          this.$emit("changed" , true );
          this.isTicketChanged = true;
        }
      },
      ticketResetChanged() {
        if( this.isTicketChanged ) {     // only fire the event the first time we're setting this.
          this.$emit("changedReset" , false );
          this.isTicketChanged = false;
        }
      },
      badgeChanged() {
        this.ticketSetChanged();         // ensure the ticket gets set too...

        if (!this.ticket.client.clientId) {
          return;
        }
        this.isBadgeChanged = true;
      },
      setCompleteBy( user ) {
        this.ticket.completeById = user._id;   // t.ticket.assignedToId;  // this.$root.tenantUser._id;
        this.ticket.completeBy   = user.alias; // t.ticket.assignedTo;    // this.$root.tenantUser.alias;
        this.ticketSetChanged();
      },
      setStatus( status ) {
        let t = this;
        if( t.ticket.status !== status ) {

          if( [ 'complete' ].indexOf(t.ticket.status) > -1 ) {      // this ticket is complete -
            if( [ 'close','complete' ].indexOf(status) <0 ) {       // and this is NOT setting this to complete or closed
              t.ticket.completeDate = null;
              t.ticket.completeById = null;
              t.ticket.completeBy   = "";
            }
          }

          if( status === 'complete' )  {
            if( !t.ticket.completeDate  ) {
              t.ticket.completeDate = Moment().toISOString();   // Moment().format("YYYY-MM-DD").toString();
              if( t.ticket.assignedToId ) {
                t.ticket.completeBy   = t.ticket.assignedTo
                t.ticket.completeById = t.ticket.assignedToId
              } else {
                t.ticket.completeBy   = this.$root.user.alias;
                t.ticket.completeById = this.$root.user._id;
              }
            }
          }

          t.ticket.status = status;
          t.ticketSetChanged();
        }
      },
      setPriority( priority ) {
        let t = this;
        if( t.ticket.priority !== priority ) {
          t.ticket.priority = priority;
          t.ticketSetChanged();
        }
      },
      setServiceType(serviceType) {
        let t = this;
        if( t.ticket.serviceType !== serviceType ) {
          t.ticket.serviceType = serviceType;
          t.ticketSetChanged();
        }
      },
      async updateBadge() {
        if (!this.ticket.client._id) {
          this.isBadgeChanged = false;
          return;
        }
        await this.$root.alert( "Update Badge " + this.ticket.client.email  );
        this.isBadgeChanged = false;
      },
      selectTicket(id) {
        this.getTicket(id);
      },
      hasNewComment() {
        if( ! this.ticket || ! this.ticket.actions) {
          return false;
        }
        if( this.ticket.actions.filter( action => { return action.actionType === 'comment' && action.actionDate > this.$root.lastOn() }).length > 0 ) {
          return true;
        }
        return false;
      },
      commentCount() {
        if( ! this.ticket || ! this.ticket.actions) {
          return 0;
        }
        return this.ticket.actions.filter( action => action.actionType === 'comment' ).length;
      },
      actionComments() {
        if( ! this.ticket || ! this.ticket.actions) {
          return [];
        }
        return this.ticket.actions.filter( action => action.actionType === 'comment').slice().reverse();
      },
      actionHistory() {
        if( ! this.ticket || ! this.ticket.actions) {
          return [];
        }
        if( this.isHistoryIgnoreUpdates ) {
          return this.ticket.actions.filter( action => ['comment','update'].indexOf(action.actionType) < 0 ).slice().reverse();
        }
        return this.ticket.actions.filter( action => action.actionType !== 'comment').slice().reverse();
      },
      async assignTicket( user ) {
        let t = this;
        if( t.ticket.assignedToId === user._id && user.alias === t.ticket.assignedTo ) {    // already assigned
          return;
        }
        t.ticket.assignedTo           = user.alias;
        t.ticket.assignedToId         = user._id;
        t.ticket.assignedToPictureUrl = user.pictureUrl || "";
        t.ticket.assignedToEmail      = (user.notificationEmail>"") ? user.notificationEmail : user.email;
        t.ticketSetChanged();
      },
      setTicketListTitle( title ) {
        this.ticketListTitle = title;
      },
      async selectTicketType(key) {
        if( this.ticketType === key ) {    // type already selected.
          return;
        }
        let t = this;
        this.ticketType = key;
        let title = t.ticketTypeTitleEx(this.ticketType);

        t.getAssignToUsers(key);

        await t.getTickets();
        t.setTicketListTitle( title + (title.toLowerCase().indexOf("ticket") < 0 ? " Tickets" : "" ) + ` (${t.tickets.length})`);
      },
      askComment( reply ) {
        this.comment = { subject: "Ticket Comment" , comment: "", replyTo: reply };
        this.commentModalShow = true;
      },
      async closeCase() {
        if (!await this.$root.confirmYesNo( "Ready to Close This Case?")) {
          return;
        }

        if (this.ticket.client.clientAccountNumber.length>0 && !this.ticket.posted && this.ticket.ticketTotal !== 0 && !this.ticket.billable) {
          if(! await this.$root.confirmYesNo( "Ticket Has a Total But Not Marked Billable -- Continue?")) {
            return;
          }
        }

        let postingTotal = Currency(this.ticket.ticketTotal).add(this.ticket.actual.payments).value;

        if (this.ticket.billable && !this.ticket.posted) {
          if( !await this.$root.confirmYesNo( "Closing - Ready to Post:" + this.currency(postingTotal))) {
            return;
          }
          let postingStatus = await this.postTicketBilling(this.ticket);
          if (!postingStatus) {
            await this.$root.alert("Unable to post - aborting close...","error");
            return;
          }
        }
        this.ticket.closed = true;
        this.ticket.closeDate = new Date();
        this.ticket.closedBy = this.$root.user.alias;
        this.ticket.closedById = this.$root.user.tenantUser;    // this is the id of the tenantUser
        this.ticketSetChanged(); // set change status

        await this.saveTicket(this.ticket);
        await this.$root.alert("Ticket Has Been Closed");

      },
      async reOpenCase() {
        if( this.$root.isInRole( "tickets-reopen" )) {

          this.ticket.closed    = false;
          this.ticket.closeDate = null;
          this.ticket.closedBy  = "";
          this.ticket.closedById = null;

          this.ticketSetChanged();

          if( this.ticket.posted && this.$root.isAdmin() ) {
            if( await this.$root.confirmYesNo("This ticket has previously been posted.  Do you wish to apply a credit?")) {
              await this.postTicketCredit(this.ticket);
            }
          }

        }
      },
      async addAction( action ) {
        let t = this;
        let updateAction = {
          actionType       : action.actionType || "comment",
          actionTitle      : action.actionTitle || "",
          actionDescription: action.actionDescription || ""
        }

        try {
          let response = await TicketService.addTicketAction( t.ticket._id , updateAction);
          t.ticket.actions = response.data.actions; // only save the actions as we may have changes
          t.ticket.updatedAt = response.data.updatedAt;     // since our action causes this to get updated.
        } catch( error ) {
          console.log(error);
          if (error.response) {
            t.$root.errorMessage("Oops", error.response.data.message);
          }
        }
      },
      async addComment( ) {
        let t = this;
        if( t.comment.comment.length === 0 ) {
          return;
        }

        try {
          let response = await TicketService.addTicketComment( t.ticket._id , t.comment );
          t.ticket.actions = response.data.actions;         // only save the actions as we may have changes
          t.ticket.updatedAt = response.data.updatedAt;     // since our action causes this to get updated.
        } catch( error ) {
          console.log(error);
          if (error.response) {
            t.$root.errorMessage("Oops", error.response.data.message);
          }
        }
        this.commentModalShow = false;
      },
      async postTicketBilling(ticket) {

        let config = _.find(this.ticketConfig.ticketType, { key: ticket.ticketType });

        if (!config) {
          await this.$root.alert("Unable to find a matching configuration for this ticket type - cannot post yet", 'error');
          return false;
        }

        if (!ticket.client.clientAccountNumber || !ticket.client.clientBadgeNumber) {
          await this.$root.alert("It appears this ticket is not linked to an account", 'warning');
          return false;
        }

        let postingTotal = Currency(ticket.ticketTotal).add(ticket.actual.payments).value;    // the payments are negative, charges positive, so we add them

        let receipt =
`Posting TRANSACTION
Helpdesk Ticket: ${ticket.ticketNumber}
Created Date: ${Moment(ticket.createdAt).format("LLLL")}
Start Date: ${Moment(ticket.startDate).format("LLLL")}
Closed Date:  ${Moment(ticket.closedDate).format("LLLL")}

Transaction Total: ${Currency(postingTotal)}

Title: ${ticket.title}

`

        let posting = {
          accountNumber      : ticket.client.clientAccountNumber,
          badgeNumber        : ticket.client.clientBadgeNumber,
          interfaceName      : "Manual",
          transactionEmployee: 0,
          checkEmployee      : 0,
          revenueCenter      : config.posting.revenueCenter,

          terminalNumber: 0,

          checkNumber      : "ticket-" + ticket.ticketNumber,
          transactionNumber: this.moment().format("YYMMDDHHmmSS") + (Math.floor(Math.random() * 99)).toString(),
          coverCount       : 1,
          tenderNumber     : config.posting.tenderNumber,
          tenderQty        : 1,
          tenderAmount     : postingTotal,

          discount        : ticket.couponTotal,
          salesItemizer   : [postingTotal, 0, 0, 0, 0, 0, 0, 0],
          taxItemizer     : [0,0,0,0],
          serviceCharge   : 0,
          previousPay     : 0,
          transactionTotal: postingTotal,
          totalClassId    : config.posting.totalClassId,
          title           : "Helpdesk Ticket " + ticket.ticketNumber,
          comment         : ticket.title,
          receipt         : [],

          transactionSource: "helpdesk-ticket",
          transactionOwnerId: ticket._id

        }

        posting.receipt.push("Helpdesk Ticket " + ticket.ticketNumber + "\n-------------------------\n" );
        posting.receipt.push(receipt);
        posting.receipt.push( "Details\n" + ticket.description );
        posting.receipt.push( "Resolution\n" + ticket.resolution );

        let postingResult;

        try {
          let result = await TransactionService.transactionPost(posting);
          postingResult = result.data;
        } catch (error) {
          console.log(error.message);
          await this.$root.alert(error.message, 'error');
          return false;
        }

        ticket.postingReference = postingResult.transactionDetail._id;
        ticket.posted = true;
        ticket.postedDate = new Date();

        await this.addAction( { actionType: "payment" , actionTitle: "POST Ticket" , actionDescription: receipt });

        await this.$root.alert("Your ticket has been successfully posted" );
        return true;
      },
      async postTicketCredit(ticket) {

        let config = _.find(this.ticketConfig.ticketType, { key: ticket.ticketType });

        if (!config) {
          await this.$root.alert("Unable to find a matching configuration for this ticket type - cannot post credit yet", 'error');
          return false;
        }

        if (!ticket.client.clientAccountNumber || !ticket.client.clientBadgeNumber) {
          await this.$root.alert("It appears this ticket is not linked to an account", 'warning');
          return false;
        }

        let creditTotal = Currency(this.ticket.ticketTotal).add( this.ticket.actual.payments ).multiply(-1).value;

        let receipt =
`** Posting CREDIT **

Helpdesk Ticket: ${ticket.ticketNumber}
Created Date: ${Moment(ticket.createdAt).format("LLLL")}
Start Date: ${Moment(ticket.startDate).format("LLLL")}
Credit Date:  ${Moment().format("LLLL")}

Transaction Total: ${Currency(creditTotal)}

Title: ${ticket.title}

-- Previous Posting --
Credit applied from: ${ticket.postingReference}
                 on: ${Moment(ticket.postedDate).format("LLLL")}
                 to: ${ticket.client.clientAccountNumber} / ${ticket.client.clientBadgeNumber}
                     ${ticket.client.clientName} (${ticket.client.clientEmail})

`

        let posting = {
          accountNumber      : ticket.client.clientAccountNumber,
          badgeNumber        : ticket.client.clientBadgeNumber,
          interfaceName      : "Manual",
          transactionSource  : "helpdesk-ticket",
          transactionEmployee: 0,
          checkEmployee      : 0,
          revenueCenter      : config.posting.revenueCenter,

          terminalNumber: 0,

          checkNumber      : "ticket-" + ticket.ticketNumber,
          transactionNumber: this.moment().format("YYMMDDHHmmSS") + (Math.floor(Math.random() * 99)).toString(),
          coverCount       : 1,
          tenderNumber     : config.posting.tenderNumber,
          tenderQty        : 1,
          tenderAmount     : Currency(creditTotal).value,

          discount        : Currency(-ticket.couponTotal).value,
          salesItemizer   : [Currency(creditTotal).value, 0, 0, 0, 0, 0, 0, 0],
          taxItemizer     : [0,0,0,0],
          serviceCharge   : 0,
          previousPay     : 0,
          transactionTotal: Currency(creditTotal).value,
          totalClassId    : config.posting.totalClassId,
          title           : "Helpdesk Ticket CREDIT " + ticket.ticketNumber,
          comment         : ticket.title,
          receipt         : []
        }

        posting.receipt.push("Helpdesk Ticket CREDIT " + ticket.ticketNumber + "\n-------------------------------\n" );
        posting.receipt.push(receipt);
        posting.receipt.push( "Details\n" + ticket.description );
        posting.receipt.push( "Resolution\n" + ticket.resolution );

        let postingResult;

        try {
          let result = await TransactionService.transactionPost(posting);
          postingResult = result.data;
        } catch (error) {
          console.log(error.message);
          await this.$root.alert(error.message, 'error');
          return false;
        }

        ticket.postingReference = ""; // postingResult._id;
        ticket.posted = false;
        ticket.postedDate = null; // w Date();

        await this.addAction( { actionType: "payment" , actionTitle: "UNPOST :: Credit Applied" , actionDescription: receipt });
        await this.saveTicket(ticket);

        await this.$root.alert("Your credit has been successfully posted" );
        return true;
      },
      async addTicket( preset = null) {

        if( !preset ) {   // if we get a preset, there is some special action taking place before us to create a ticket.
          if( ! await this.$root.confirmYesNo( "Add New Ticket?" )) {
            return;
          }
          preset = {};    // we didn't get one of these, so let's make a default so we don't break when we build up our ticket below.
        }

        let t = this;

        t.isLoading = true;

        let starterTicket = { title: "New Ticket" , active: false, ticketType: t.ticketType }; // just stub this because we'll get the defaults from the API.
        let ticket = Object.assign( starterTicket, preset );

        try {
          let response = await TicketService.addTicket( ticket );  // get db defaults.

          t.ticket             = response.data;
          t.ticket.serviceType = t.defaultServiceType();

          t.isTicket             = true;
          t.isShowOriginalBudget = true;
          t.isShowCurrentBudget  = false;     // the calculated value, non-editable

        } catch( error ) {
          console.log(error);
          if (error.response) {
            t.$root.errorMessage("Oops", error.response.data.message);
          }
        }
        t.ticketSetChanged();
        t.isLoading = false;
      },
      async getTicketStats() {
        // todo: retrieve the categories, and other things here...
      },
      async cloneTicket( ticket ) {

        if( this.isTicketChanged ) {
          if( !await this.$root.confirmYesNo( "You have unsaved changes. Is it ok to continue to clone this ticket?")) {
            return;
          }
        } else {
          if( !await this.$root.confirmYesNo( "Clone this ticket?")) {
            return;
          }
        }

        let newTicket = _.cloneDeep(ticket);

        newTicket.status = "new";
        newTicket.title += " (clone)";
        newTicket.ticketSource = "Cloned from ticket # " + newTicket.ticketNumber;
        delete newTicket.ticketNumber;

        if( newTicket.tags.indexOf('clone') < 0 ) {
          newTicket.tags.push("clone");
        }

        if( await this.$root.confirmYesNo( "Do you wish to keep the comments?" )) {   // we are going to remove all actions, so do we keep comments?
          newTicket.actions = _.filter( newTicket.actions, (a) => { return a.actionType === 'comment' });
        } else {
          newTicket.actions = [];
        }

        if( newTicket.changeOrders.length > 0  ) {
          if( !await this.$root.confirmYesNo( "There are Change Orders.<br>Do you want to keep them?")) {
            newTicket.changeOrders = [];
          }
        }

        if( ! await this.$root.confirmYesNo( "OK, all set!<br>Are you ready to clone?")) {
          return;
        }

        await this.addTicket( newTicket );

      },
      async saveTicket( ticket , okToExit = true ) {
        let t = this;
        let response;

        if( t.task ) {      // if we have a task open, let's close that first.
          t.taskSave();
          return;
        }

        try {
          if( t.isTicketChanged ) {
            if( t.ticket.actions && t.ticket.actions.length === 1 ) {
              if( t.ticket.status === 'new' ) {
                t.ticket.status = 'open';
              }
            }

            t.hourlyRateChanged();    // this method calls all recalculations

            delete ticket.actions;

            ticket.active = true;
            ticket.isProject = this.isProject();      // this only detetects if "project features" are enabled in tenant config.
            response = await TicketService.saveTicket( ticket );

            t.isTicketChanged = false;  // I think we need to RESET this field

            this.$emit("saved", response.data);   // so our "list" parent can update acordigly, if they care.
          }

          if( okToExit ) {
            t.ticket          = null;
            t.isTicket        = false;  // if we get here, all went well so close the ticket edit.
            this.$emit("done");
          } else {
            t.ticket = response.data;   // wjs: 06-Apr-24 :: Not sure if this is the right thing to do or not -- fixme:
          }

        } catch( error ) {
          console.log(error);
          if (error.response) {
            t.$root.errorMessage("Oops", error.response.data.message);
          } else {
            t.$root.errorMessage("Oops" , error.message );
          }
        }
      },
      verifyChangeOrders( ticket ) {    // let's make sure the each of the step titles are current
        if( !ticket.changeOrders || ticket.changeOrders.length === 0 ) {
          return ticket;
        }

        ticket.changeOrders.forEach((co) => {
          if( co.step.id ) {
            let step = _.find(ticket.steps, { "_id": co.step.id});
            if( step ) {
              if( co.step.title != step.title || co.step.budgetYear != step.budgetYear ) {
                this.ticketSetChanged();
              }
              co.step.title      = step.title;
              co.step.budgetYear = step.budgetYear;
            }
          }
        });

        return ticket;
      },
      verifyTicketHasLinks(ticket) {
        if( this.isProject() ) {        // fixme: add the links section if we don't have it but fix this better to only add if these don't already exist
          if( !ticket.links ) {
            ticket["links"] = {
              unitId       : null,
              roomNumber   : "",
              unitTitle    : "",
              buildingId   : null,
              building     : "",
              buildingTitle: ""
            };
          }
        }
        return ticket;
      },
      async verifyTicketSteps( ticket ) {
        let updated = false;
        if( ticket.steps && ticket.steps.length > 0 ) {
          ticket.steps.forEach( (step) => {

            if( !step.stepId ) {
              step.stepId = step.sequence;
              this.ticketSetChanged();
              updated = true;
            }
          });
           if( updated ) {
            await this.$root.alert( "This is an older ticket and appears to not have Task-Step ID's;  That's ok, I added them. Please be sure to save when you are done editing.", 'warning');
           }
        }
        return ticket;
      },

      async getTicket(id) {
        let t = this;
        t.loading = true;

        try {

          t.ticketEditButtonTitle = "Done";
          let response            = await TicketService.getTicket(id);
          t.ticket                = response.data;

          t.ticketResetChanged();

          t.isBadgeChanged  = false;

          if( this.myTicketTypes.indexOf( t.ticket.ticketType ) <= 0 ) {    // somehow our ticket type got changed so let's add THIS ticket type
            this.myTicketTypes.push( t.ticket.ticketType );
          }

          if( t.ticketType !== t.ticket.ticketType ) {
            t.ticketType = "";
            await t.getAssignToUsers( t.ticket.ticketType );
          }

          if( t.isProject() ) {
            await t.getMasterProjectList();
            // await t.getMasterBudgetProjectList();
          }

          t.ticket = t.verifyChangeOrders(t.ticket);
          t.ticket = t.verifyTicketHasLinks(t.ticket);
          t.ticket = await t.verifyTicketSteps(t.ticket);
          t.hourlyRateChanged();    // this method calls all recalculations

          if( t.ticket.estimatedTotal === 0 && t.ticket.estimatedHours === 0 ) {
            t.isShowOriginalBudget = true;
            t.isShowCurrentBudget  = false;
          }

          if( t.ticket.estimatedTotal === t.ticket.adjustedBudget.total ) {
            t.isShowOriginalBudget = true; // starting budget
            t.isShowCurrentBudget  = false;
          }

        } catch (error) {
          console.log(error);
          if (error.response) {
            t.$root.errorMessage("Oops", error.response.data.message);
          }
        }
        t.loading = false;
      },
      setMasterProject( id ) {
        let t = this;
        let mp = _.find(t.masterProjectList,{value:id});
        if( mp ) {
          t.ticket.projectCategory = "" + mp.projectCategory;
          t.ticket.budgetYear = mp.budgetYear;
        } else {
          t.ticket.projectCategory  = "";
        }
      },
      async getMasterProjectList() {
        this.masterProjectList = [{text:"-none-", value: "000000000000000000000000", projectCategory: "" }];
        let response = await TicketService.getProjectsTextValue();
        let v = response.data;

        if( this.ticket.projectId ) {

          let fp = _.find(v,{value: this.ticket.projectId});

          if( !fp ) {
            let response = await TicketService.getProjectsTextValue(null,this.ticket.projectId);
            let v1 = response.data;

            if( v1.length > 0 ) {   // found it...
              v.push(...v1);
            } else {
              this.ticket.projectId = "000000000000000000000000";
              this.ticketSetChanged();
              this.$root.alert("Project Not Found","The project you had selected is no longer available.  Please select a new project.");
            }
          }
        }

        //v = _.sortBy(v, ['text']);
        v = _.orderBy(v, [item => item.text.toLowerCase()]);    // case insensitive sort.

        this.masterProjectList.push( ...v );
      },
      // async getMasterBudgetProjectList() {
      //   this.masterBudgetProjectList = []; //[{text:"-none-", value: "000000000000000000000000", projectCategory: "" }];
      //   let response = await TicketService.getProjectsTextValue(true);
      //   let v = response.data;
      //
      //   this.masterBudgetProjectList.push( ...v );
      //
      // },
      // async getTicketsByDateRange() {
      //   if( ! this.dateRange || this.dateRange === "" ) {
      //     return;
      //   }
      //
      //   let beginDate;
      //   let endDate;
      //   switch( this.dateRange ) {
      //     case 'today':
      //       beginDate = Moment().tz( this.$root.TZ ).startOf('day').toISOString();
      //       endDate = Moment(beginDate).tz( this.$root.TZ ).endOf('day').toISOString();
      //       break;
      //
      //     case 'yesterday':
      //       beginDate = Moment().tz( this.$root.TZ ).subtract(1,'day').startOf('day').toISOString();
      //       endDate = Moment(beginDate).tz( this.$root.TZ ).endOf('day').toISOString();
      //       break;
      //
      //     case 'thisweek':
      //       beginDate = Moment().tz( this.$root.TZ ).startOf('week').toISOString();
      //       endDate = Moment(beginDate).tz( this.$root.TZ ).endOf('week').toISOString();
      //       break;
      //
      //     case 'thismonth':
      //       beginDate = Moment().tz( this.$root.TZ ).startOf('month').toISOString();
      //       endDate = Moment(beginDate).tz( this.$root.TZ ).endOf('month').toISOString();
      //       break;
      //
      //
      //     case 'lastweek':
      //       beginDate = Moment().tz( this.$root.TZ ).startOf('week').subtract(1,'week').toISOString();
      //       endDate = Moment(beginDate).tz( this.$root.TZ ).endOf('week').toISOString();
      //       break;
      //
      //     case 'lastmonth':
      //       beginDate = Moment().tz( this.$root.TZ ).startOf('month').subtract(1,'month').toISOString();
      //       endDate = Moment(beginDate).tz( this.$root.TZ ).endOf('month').toISOString();
      //       break;
      //
      //     default:
      //       beginDate = Moment().tz( this.$root.TZ ).startOf('day').toISOString();
      //       endDate = Moment(beginDate).tz( this.$root.TZ ).endOf('day').toISOString();
      //       break;
      //   }
      //
      //   try {
      //     let response = await TicketService.getTicketsByDate( this.myTicketTypes.join(",") , true, beginDate , endDate );
      //     this.tickets = response.data;
      //
      //     let title = _.find( this.ticketDateRange , { value: this.dateRange }).text;
      //     this.setTicketListTitle( `Closed Tickets ${title} (${this.tickets.length})` );
      //     this.ticketType = null;
      //
      //   } catch( error ) {
      //     console.log( error.message );
      //   }
      // },
      async getAssignToUsers( ticketType ) {
        let t = this;
        let response;
        let tt = _.find(this.ticketConfig.ticketType, { key: ticketType });

        let jobCodes = "";
        if( tt ) {
          jobCodes = tt.authorizedJobCodes.join( "," );
        }

        if( t.assignToJobCode &&  t.assignToJobCode === jobCodes ) {
          return;
        }

        t.assignToUsers  = [];

        try {
          response = await AccountService.getAccountsByJobCode('employee,vendor,client,user' , jobCodes );

          t.assignToUsers  = response.data;
          t.assignToJobCode = jobCodes;   // so we don't keep getting the same ones

        } catch(err) {
          if(err.response) {
            if(err.response.data) {
              t.$root.errorMessage("Oops", err.response.data.message);
            }
          } else {
            t.$root.errorMessage("Oops", "Had an error getting the assign-to users");
          }
        }

        if( t.ticket ) {
          t.ticket = t.verifyTicketHasLinks(t.ticket);      // if we're changing TO a project Type
        }

      },
      async getTicketUsers() {

        let t = this;
        let response;

        try {
          let t        = this;
          response = await UserService.getTicketUsers(); // users only that have the "tickets" role
          t.users  = [];
          t.users  = response.data; // .filter( (u) => {return (u.active !== false) && (u.roles.indexOf( "tickets" ) >= 0)});

        } catch(err) {
          if(err.response) {
            if(err.response.data) {
              t.$root.errorMessage("Oops", err.response.data.message);
            }
          } else {
            t.$root.errorMessage("Oops", "Had an error getting the users");
          }
        }
      },
      async getTicketStatuses() {
        let t = this;
        let response;

        try {
          let t        = this;
          response = await TicketService.getTicketStatuses();
          t.ticketStatuses = response.data;
        } catch(err) {
          if(err.response) {
            if(err.response.data) {
              t.$root.errorMessage("Oops", err.response.data.message);
            }
          } else {
            t.$root.errorMessage("Oops", "Had an error getting the ticket statuses");
          }
        }
      },
      async getTicketPriorities() {
        let t = this;
        let response;

        try {
          let t        = this;
          response = await TicketService.getTicketPriorities();
          t.ticketPriorities = response.data;
        } catch(err) {
          if(err.response) {
            if(err.response.data) {
              t.$root.errorMessage("Oops", err.response.data.message);
            }
          } else {
            t.$root.errorMessage("Oops", "Had an error getting the ticket priorities");
          }
        }
      },
      async deleteTicket(id) {

        if( ! await this.$root.confirmYesNo( "Delete this ticket?" )) {
          return;
        }

        let t = this;
        try {
          await TicketService.deleteTicket( id );
          _.remove(t.tickets , {_id:id});
          t.ticket   = {};
          t.isTicket = false;  // if we get here, all went well so dump the current ticket
        }catch( error ) {
          console.log(error);
          if (error.response) {
            t.$root.errorMessage("Oops", error.response.data.message);
          }
        }
      },
      async getTicketTypeSummary() {
        let t = this;
        let response;

        try {
          let t        = this;
          response = await TicketService.getTicketTypeSummary();
          t.ticketTypes = response.data;
        } catch(err) {
          console.log("getTicketTypeSummary Error: ", err);
          if(err.response) {
            if(err.response.data) {
              t.$root.errorMessage("Oops", err.response.data.message);
            }
          } else {
            t.$root.errorMessage("Oops", "Had an error getting the ticket categories");
          }
        }
      }
    }
  }

</script>
<style lang="scss" >

  .comment-timeline {
    h2 {
      color: black;
    }
    p {
      color: black !important;
      white-space:pre-line;
    }
    img {
      height:32px;
    }

  }

  .history-timeline {
    h2 {
      color: black;
    }
    p {
      color: black !important;
      white-space: pre;
      width:600px;
    }
    img {
      height:32px;
    }

  }


  .outline-shadow {
    -webkit-box-shadow: 0px 0px 25px 5px rgba(0,0,0,0.35);
    box-shadow: 0px 0px 25px 5px rgba(0,0,0,0.35);
  }

  .collapsible {
    background-color: #eee;
    margin-top: .5em;
    border-radius: 1em;
    color: #444;
    cursor: pointer;
    padding: 10px;
    border: none;
    text-align: left;
    outline: none;
  }

  ins {
     background-color:rgba( 0,255,0,.1);
     text-decoration:none;
  }

  del {
    background-color:rgba( 255,0,0,.1);
  }

  .hover-track-light:hover{
    background: #eee;
    color:black;
  }

  .hover-track-dark:hover {
    background: #333;
    color: white;
  }


  .page-stamp {

    position: absolute;
    top: 4em;
    left: 2em;
    z-index: 1;
    font-family: Arial,sans-serif;
    -webkit-transform: rotate(-30deg); /* Safari */
    -moz-transform: rotate(-30deg); /* Firefox */
    -ms-transform: rotate(-30deg); /* IE */
    -o-transform: rotate(-30deg); /* Opera */
    transform: rotate(-30deg);
    font-size: 6em;
    color: #c00;
    background: #fff;
    border: solid 4px #c00;
    padding: 0 .5em 0 .5em;
    border-radius: .25em;
    zoom: 1;
    filter: alpha(opacity=20);
    opacity: 0.2;
    -webkit-text-shadow: 0 0 2px #c00;
    text-shadow: 0 0 2px #c00;
    box-shadow: inset 0px 0px 0px 10px #c00;
  }


  .page-stamp:after {
    content:'';
    border-radius:.25em;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url("@/assets/img/stamp-background.jpg");
    mix-blend-mode: lighten;
  }

  table.taskGridTable {
    width: 100%;
    text-align: right;
    border-collapse: collapse;
  }
  table.taskGridTable td, table.taskGridTable th {
    border:1px solid lightgray;
    padding: .5em .25em;
  }
  table.taskGridTable tbody td {
    min-width:7em;
    width:7em;
  }

  table.taskGridTable tbody td:first-child {
    font-weight:600;
  }

  table.taskGridTable tr:nth-child(even) {
  }

  table.taskGridTable tr:hover {
    background: #ccc;
    color:black;
  }
  
  table.taskGridTable thead {
    background: #000000;
  }
  table.taskGridTable thead th {
    font-weight: bold;
    color: #FFFFFF;
    text-align: center;
    border-left: 2px solid #333333;
  }
  table.taskGridTable thead th:first-child {
    border-left: none;
  }

  table.taskGridTable tfoot {
    font-weight: normal;
    color: black;
    border: 3px solid #B3B3B3;
  }
  table.taskGridTable tfoot td:first-child {
    font-weight:600;
  }

  @media only screen and (max-device-width: 1700px) {
    .hide-too-small {
      display:none;
    }
  }


  @media print {

    @page {
      size: letter portrait;
    }

    body {
      -webkit-print-color-adjust: exact !important;
      width: 100%;
    }

    .logo {
      width: 300px !important;
      max-width: 300px !important;
    }

    .noprint {
      display: none;
    }

    .container {
      width: 100%;
      max-width: 100%;
    }
  }

</style>
