import { render, staticRenderFns } from "./PortalAuthorizations.vue?vue&type=template&id=00db7197&scoped=true"
import script from "./PortalAuthorizations.vue?vue&type=script&lang=js"
export * from "./PortalAuthorizations.vue?vue&type=script&lang=js"
import style0 from "./PortalAuthorizations.vue?vue&type=style&index=0&id=00db7197&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00db7197",
  null
  
)

export default component.exports