<template>
    <card class="shadow" style="border-radius:1em;">

      <div>
        <b-button v-if="!isChanged" class="btn btn-sm btn-fill btn-success" style="float:left;margin-right:1em;" @click="done()">Done</b-button>
        <b-button v-if="isChanged" class="btn btn-sm btn-fill btn-success" style="float:left;margin-right:1em;" @click="save()">Save</b-button>
        <b-button v-if="isChanged" class="btn btn-sm btn-fill btn-warning" style="float:left;margin-right:1em;" @click="cancel()">Cancel</b-button>
        <h4 slot="header" class="card-title">
          Housing Configuration
        </h4>
      </div>
      <hr>

      <b-tabs v-if="housingConfig" id="housingConfig" lazy class="tab-hover" content-class="mt-2 ml-3 mr-3" active-nav-item-class="selected-tab" >

        <b-tab title="Overhead">
          <div style="height:650px;padding-right:1em;overflow-y:auto;overflow-x:hidden;">
            <h4>Housing Configuration</h4>
            <form @input="dataChanged()">
              <sf-input label="Notes" :labelCols="1" :rows="12" type="textarea" v-model="housingConfig.notes"></sf-input>
            </form>
            <p style="font-size:.8em;color:gray;font-style:italic;text-align:right;">When configuring the Key/Description fields, the Key is for the Database, while the description is the public visible entry</p>
          </div>
        </b-tab>

        <b-tab title="General" >
          <div style="min-height:650px;">
            <b-tabs vertical card class="tab-hover-left" content-class="mt-2 ml-3 mr-3" active-nav-item-class="selected-tab-left" >

              <!----------------------------------------------------------------------------[ Zoning Types ]-->
              <b-tab title="Zoning Types" style="border-left:4px solid gray;">
                <p class="help-text">Zoning types as defined by the state zoning board; e.g., R1, C1, etc.</p>
                <div style="width:80%;height:650px;padding-right:1em;overflow-y:auto;overflow-x:hidden;margin-left:auto;margin-right:auto;">
                  <option-management-table
                    title="Zoning types"
                    :tableData="housingConfig.zoning"
                    @change="dataChanged()"
                  />
                </div>
              </b-tab>

              <!----------------------------------------------------------------------------[ Contract Types ]-->
              <b-tab title="Contract Types" style="border-left:4px solid gray;">
                <p class="help-text">Contract types provide the "type" of contracts that will be configured for tracking; example: pest-control, insurance, sales, etc. </p>
                <div style="width:80%;height:650px;padding-right:1em;overflow-y:auto;overflow-x:hidden;margin-left:auto;margin-right:auto;">
                  <option-management-table
                    title="Contract types"
                    :tableData="housingConfig.contractTypes"
                    @change="dataChanged()"
                  />
                </div>
              </b-tab>

              <!----------------------------------------------------------------------------[ Construction Types ]-->
              <b-tab title="Construction Types " style="border-left:4px solid gray;">
                <p class="help-text">Construction types are set at the governance level, typically, but you can change or add your own.  ISO1, ISO2, etc.</p>
                <div style="width:80%;height:650px;padding-right:1em;overflow-y:auto;overflow-x:hidden;margin-left:auto;margin-right:auto;">
                  <option-management-table
                    title="Construction Types"
                    :tableData="housingConfig.constructionTypes"
                    @change="dataChanged()"
                  />
                </div>
              </b-tab>

              <!----------------------------------------------------------------------------[ Owner Types ]-->
              <b-tab title="Owner Types" style="border-left:4px solid gray;">
                <p class="help-text">Owner Types are things like, INTERNAL, PRIVATE, COMMUNITY, LEASE, INVESTOR, and such BUT you could setup dedicated investment categories </p>
                <div style="width:80%;height:650px;padding-right:1em;overflow-y:auto;overflow-x:hidden;margin-left:auto;margin-right:auto;">
                  <option-management-table
                    title="Owner Types"
                    :tableData="housingConfig.ownerTypes"
                    @change="dataChanged()"
                  />
                </div>
              </b-tab>

              <!----------------------------------------------------------------------------[ Primary Use ]-->
              <b-tab title="Primary Use " style="border-left:4px solid gray;">
                <p class="help-text">Primary Use is for USAGE analysis and this can overlap the other categories.  examples include, ADMINISTRATION, RESIDENTIAL, RETAIL, MEETING, etc</p>
                <div style="width:80%;height:650px;padding-right:1em;overflow-y:auto;overflow-x:hidden;margin-left:auto;margin-right:auto;">
                  <option-management-table
                    title="Primary Usage"
                    :tableData="housingConfig.primaryUse"
                    @change="dataChanged()"
                  />
                </div>
              </b-tab>

              <!----------------------------------------------------------------------------[ Housing Types ]-->
              <b-tab title="Property Types" style="border-left:4px solid gray;">
                <p class="help-text">Housing Types identify the BUILDING, LAND, INVESTMENT; This is NOT the room/unit types. <span v-if="_.findIndex(housingConfig.housingTypes,(h) => {return h.value == 'building'}) < 0" style="font-size:1.25em;color:red;font-weight:600;"><br>NOTE: A type of "<strong>building</strong>" MUST exist for building/unit tracking.</span></p>
                <div style="width:80%;height:650px;padding-right:1em;overflow-y:auto;overflow-x:hidden;margin-left:auto;margin-right:auto;">
                  <option-management-table
                    title="Property/Housing Types"
                    :tableData="housingConfig.housingTypes"
                    @change="dataChanged()"
                  />
                </div>
              </b-tab>

            </b-tabs>

          </div>
        </b-tab>

        <b-tab title="Summary Groups" >
          <div style="min-height:650px;">
            <b-tabs vertical card class="tab-hover-left" content-class="mt-2 ml-3 mr-3" active-nav-item-class="selected-tab-left" >

              <!----------------------------------------------------------------------------[ Line of business ]-->
              <b-tab title="Line of Business" style="border-left:4px solid #eecc55;">
                <p class="help-text">Line of Business</p>
                <div style="width:80%;height:650px;padding-right:1em;overflow-y:auto;overflow-x:hidden;margin-left:auto;margin-right:auto;">
                  <option-management-table
                    title="Line of Business"
                    :tableData="housingConfig.lineOfBusinessTypes"
                    @change="dataChanged()"
                  />
                </div>
              </b-tab>

              <!----------------------------------------------------------------------------[  Sections ]-->
              <b-tab title="Sections" style="border-left:4px solid  #eecc55;">
                <p class="help-text">Sections are SITE classifications for reporting and grouping and can include buildings, structures, land, or any other resource.</p>
                <div style="width:80%;height:650px;padding-right:1em;overflow-y:auto;overflow-x:hidden;margin-left:auto;margin-right:auto;">
                  <option-management-table
                    title="Sections"
                    :tableData="housingConfig.sections"
                    @change="dataChanged()"
                  />
                </div>
              </b-tab>

            </b-tabs>
          </div>
        </b-tab>


        <b-tab title="Building" >
          <b-tabs vertical card class="tab-hover-left" content-class="mt-2 ml-3 mr-3" active-nav-item-class="selected-tab-left" >
          <div style="min-height:650px;">
            <!----------------------------------------------------------------------------[ Building Categories ]-->
            <b-tab title="Building Categories" style="border-left:4px solid  #55cc55;">
              <p class="help-text">Building Categories are the TYPE of building; examples are ADMIN, CARPORT, MAINTENANCE, GENERAL, and a catch-all as GENERAL, for example</p>
              <div style="width:80%;height:650px;padding-right:1em;overflow-y:auto;overflow-x:hidden;margin-left:auto;margin-right:auto;">
                <option-management-table
                  title="Building Categories"
                  :tableData="housingConfig.buildingCategories"
                  @change="dataChanged()"
                />
              </div>
            </b-tab>
           
          </div>
          </b-tabs>
        </b-tab>

        <b-tab title="Unit" >
          <div style="min-height:650px;">
            <b-tabs vertical card class="tab-hover-left" content-class="mt-2 ml-3 mr-3" active-nav-item-class="selected-tab-left" >

              <!----------------------------------------------------------------------------[ Unit Classes ]-->
              <b-tab title="Unit Classes" style="border-left:4px solid #5555cc;">
                <p class="help-text">Unit Classes are definitions at a BASE level; Things like <strong>SINGLE, DUPLEX, MULTI </strong>that help identify the type of building or investment</p>
                <div style="width:80%;height:650px;padding-right:1em;overflow-y:auto;overflow-x:hidden;margin-left:auto;margin-right:auto;">
                  <option-management-table
                    title="Unit Classes"
                    :tableData="housingConfig.unitClass"
                    @change="dataChanged()"
                  />
                </div>
              </b-tab>

              <!----------------------------------------------------------------------------[ Unit Types ]-->
              <b-tab title="Unit Types" style="border-left:4px solid #5555cc;">
                <p class="help-text">Unit Types identify the TYPE of units allowed to be attached to a building. e.g., ROOM, UNIT, SUITE</p>
                <div style="width:80%;height:650px;padding-right:1em;overflow-y:auto;overflow-x:hidden;margin-left:auto;margin-right:auto;">
                  <option-management-table
                    title="Unit Types"
                    :tableData="housingConfig.unitTypes"
                    @change="dataChanged()"
                  />
                </div>
              </b-tab>

            </b-tabs>
          </div>
        </b-tab>

        <b-tab title="Options">
          <div style="height:650px;padding-right:1em;overflow-y:auto;overflow-x:hidden;">
            <h4>Housing Options</h4>
            <p style="font-size:.8em;color:gray;font-style:italic;">These options control the features & functions of the housing module</p>
            <form style="margin-left: 7em;margin-bottom:1em;" @input="dataChanged()">
              <b-checkbox v-model="housingConfig.options.enableAmenities">Enable Amenities Tab</b-checkbox>
              <b-checkbox v-model="housingConfig.options.enableEmergencyInformation">Enable Emergency Information Tab</b-checkbox>
              <b-checkbox v-model="housingConfig.options.enableImprovements">Enable Improvement Tracking</b-checkbox>
              <b-checkbox v-model="housingConfig.options.enableNotes">Enable Notes Tab</b-checkbox>
              <b-checkbox v-model="housingConfig.options.enableTickets">Enable Tickets Tab</b-checkbox>
              <b-checkbox v-model="housingConfig.options.enableDocuments">Enable Documents Tab</b-checkbox>
            </form>
          </div>
        </b-tab>
          <!----------------------------------------------------------------------------[ Models ]-->
          <b-tab>
            <template v-slot:title>
              <span>Models</span><i class="fa fa-plus-square show-hand" style="margin-left:.75em;font-size:1.25em;" v-b-popover.hover.top="'Add a new Model'" @click="addModel()"></i>
            </template>
            <p v-if="!isShowCoverImageUpload" class="help-text">Models are the 'marketing' terms used to identify the different classifications of units. <strong> Sandpiper, Villa I, Super-2</strong>, are all examples.</p>

            <div v-if="isShowCoverImageUpload" >
              <!-- <b-modal id="imageUpload" hide-footer size="lg" hide-backdrop v-model="isShowCoverImageUpload" model-class="image-upload-class" > -->
                <image-upload 
                  :title="imageUploadTitle"
                  :folder="uploadFolder"
                  filename="cover-photo"
                  :width="300"
                  :height="300"
                  @cancel="isShowCoverImageUpload=false"
                  @save="imageUploadComplete"
                />
              <!-- </b-modal> -->
            </div>

            <div v-if="!isShowCoverImageUpload" style="min-height:650px;">
              <!-- <i class="fa fa-plus-square show-hand" style="font-size:1.25em;float:right;" v-b-popover.hover.top="'Add a new Model'" @click="addModel()"></i> -->
              <b-tabs vertical  card lazy class="tab-hover-left"  v-model="activeModelTab" content-class="mt-2 ml-3 mr-3" active-nav-item-class="selected-tab-left" >

                <!----------------------------------------------------------------------------[ Housing Types ]-->
                <b-tab :title="m.text || 'new model'" style="border-left:4px solid #5555cc;" v-for="(m,index) in housingConfig.models" :key="index" @click="selectModel(m)">

                  <div style="position:relative;float:right;top:8px;cursor:pointer;border:4px solid white;color:white;background:black;border-radius:1em;padding:.5em .75em .5em .75em;" @click="uploadCoverImage(m)">
                    <i class="fa fa-camera" style="" v-b-popover.hover.top="'Upload an image for THIS asset only'" ></i> Upload Cover Photo
                  </div>

                  <h4 style="margin:0 0 1em 0;padding:0;border-bottom:1px solid gray;">{{m.text}}</h4>

                  <i class="fa fa-trash show-hand" style="position:relative;font-size:1.25em;float:left;" v-b-popover.hover.top="'Remove This Model'" @click="removeModel(m)"></i>

                  <div style="width:80%;height:650px;padding-right:1em;overflow-y:auto;overflow-x:hidden;margin-left:auto;margin-right:auto;">
                    <b-tabs class="tab-hover-normal" v-model="modelTab" content-class="mt-2 ml-3 mr-3" active-nav-item-class="selected-tab" >
                      <b-tab title="General" >
                        <div style="margin-top:1em;">
                          <img :src="m.coverPhoto" height=150 style="z;padding:2px;border:3px solid rgba(0,50,0,.5);transform:rotate(-7deg);border-radius:1em;"/>
                          <b-form @input="dataChanged()">
                            <sf-input :id="'model-dbkey'+index.toString()" type="text" :readonly="m.text.length>0" cstyle="width:12em;" v-model="m.value" label="Database KEY"></sf-input>
                            <sf-input :id="'model-title'+index.toString()" type="text" v-model="m.text" label="Title"></sf-input>
                            <sf-input :id="'model-class'+index.toString()" type="list" :options="housingConfig.unitClass" v-model="m.class" label="Unit Class"></sf-input>
                            <sf-input :id="'model-style'+index.toString()" type="text" v-model="m.style" label="Style" helpText="Example: 2b2b, 2b1b w/balcony"></sf-input>
                            <sf-input :id="'model-sqft'+index.toString()" type="comma" cstyle="width:15em;" :isCurrency="false" v-model="m.squareFeet" label="Sq/Ft"></sf-input>
                            
                            <sf-input :id="'model-desc'+index.toString()" type="textarea"  v-model="m.notes" :rows=12 label="Notes"></sf-input>
                          </b-form>
                        </div>
                      </b-tab>

                      <b-tab title="Pricing">
                        <div style="margin-top:1em;">
                          <b-form v-if="hasPricing(m)" @input="dataChanged()">
                            <sf-input :id="'model-effective-date'+index.toString()" type="date" cstyle="width:15em;" v-model="m.prices[0].effectiveDate" label="Effective Date"></sf-input>
                            <sf-input :id="'model-price'+index.toString()" type="comma" cstyle="width:15em;" :isCurrency="true" v-model="m.prices[0].price" label="Advanced Fee"></sf-input>
                            <sf-input :id="'model-admin-fee'+index.toString()" type="comma" cstyle="width:15em;" :isCurrency="true" v-model="m.prices[0].administrationFee" label="Administration/Closing Fee"></sf-input>
                            <sf-input :id="'model-monthly-maintenance'+index.toString()" type="comma" cstyle="width:15em;" :isCurrency="true" v-model="m.prices[0].monthlyMaintenance" label="Monthly Maintenance"></sf-input>
                            <sf-input :id="'model-annual-maintenance'+index.toString()" type="comma" cstyle="width:15em;" :isCurrency="true" v-model="m.prices[0].annualMaintenance" label="Annual Maintenance"></sf-input>
                            <sf-input :id="'model-approved-by'+index.toString()" type="text" cstyle="width:15em;" v-model="m.prices[0].approvedBy" label="Approved By"></sf-input>
                          </b-form>
                        </div>
                      </b-tab>

                      <b-tab title="Options">
                        <div style="height:650px;padding-right:1em;overflow-y:auto;overflow-x:hidden;">
                          <h4>{{m.text}} Options</h4>
                          <p style="font-size:.8em;color:gray;font-style:italic;">These options control the features & functions of the {{m.text}} model</p>
                          <form style="margin-left: 7em;margin-bottom:1em;" @input="dataChanged()">
                            <b-checkbox v-model="m.options.enableAddressEdit">Enable Address Edit</b-checkbox>
                            <b-checkbox v-model="m.options.enableTickets">Enable Ticket Tracking</b-checkbox>
                            <b-checkbox v-model="m.options.enableSensors">Enable In-Room Sensors</b-checkbox>
                            <b-checkbox v-model="m.options.showOnAvailableModels">Show On Available Models</b-checkbox>
                            <b-checkbox v-model="m.options.showOnAvailableUnits">Show On Available Units</b-checkbox>
                          </form>
                        </div>
                      </b-tab>

                      <b-tab v-if="m.value && m.value.length>0" lazy title="Documents & Images" @click="selectModel(m, true)">
                        <div style="margin-top:1em;">
                          <div  style="display:block;margin-left:1em;">
                            <b-checkbox v-model="isShowImages">Show Images</b-checkbox>
                            <file-List
                              title="Model Documents"
                              :folder="modelsFolder(m)"
                              :files="modelFilesList"
                              :showImages="isShowImages"
                              :enableDelete="true"
                              :enableMultipleFileUpload="true"
                              @getFiles="getFiles(m)"
                            />
                          </div>
                        </div>
                      </b-tab>

<!--                      <b-tab title="Stats">-->
<!--                        <div style="margin-top:1em;">-->
<!--                          <b-form @input="dataChanged()">-->
<!--                            <sf-input type="comma" cstyle="width:15em;" :isCurrency="false" v-model="m.sqFt" label="Sq/Ft"></sf-input>-->
<!--                          </b-form>-->
<!--                        </div>-->
<!--                      </b-tab>-->

                    </b-tabs>
                  </div>
                </b-tab>
              </b-tabs>
            </div>
          </b-tab>


      </b-tabs>
    </card>
</template>

<script>
  // import AccountService from "/src/Services/AccountService";
  import TenantService  from "/src/Services/TenantService";
  // import QueryService   from "/src/Services/QueryService";
  import FileService        from "/src/Services/FileService";
  import Moment         from "moment-timezone";
  import Card           from "/src/components/UIComponents/Cards/Card.vue";
  import OptionManagementTable from "/src/components/UIComponents/Cards/OptionManagementTable.vue";
  import OptionManagementTableWithClass from "/src/components/UIComponents/Cards/OptionManagementTableWithClass.vue";
  import SfInput        from "/src/components/UIComponents/Inputs/SFormInput.vue";
  import LTable         from "/src/components/UIComponents/Table.vue";
  import FileList           from "/src/components/UIComponents/Cards/FileList.vue";
  import ImageUpload  from "/src/components/UIComponents/Cards/ImageUpload.vue";

  export default {
  components: {
    LTable,
    Card,
    SfInput,
    OptionManagementTable,
    OptionManagementTableWithClass,
    ImageUpload,
    FileList
  },
  data() {
    return {
      saveDisabled          : true,
      isChanged             : false,
      isAdding              : false,
      isEditing             : false,
      isShowCoverImageUpload: false,
      imageUploadTitle      : "Upload Cover Photo",
      uploadFolder          : "",
      activeModelTab        : 0,
      small                 : true,
      housingConfig         : null,
      isShowImages          : true,
      modelFiles            : null,
      downloadLink          : null,
      fileRefresh           : false,
      modelTab              : 0,
      selectedModel         : ""
    };
  },
  watch: {
  },
  mounted: async function() {
    await Promise.all([
      this.getHousingConfig()
    ]);
  },
  async beforeRouteLeave( to , from, next ) {
    if( !this.isChanged ) {
      return next();
    }
    if( await this.$root.confirm('You have unsaved changes, continue leaving without saving?', "warning")) {
      return next();
    }
    next(false)
  },
  computed: {
    modelFilesList() {
      if( this.modelFiles ) {
        if(this.isShowImages) {
          return this.modelFiles;
        }
        return this.modelFiles.filter((b) => {
          return !this.isImage(b.name);
        })
      }
      return [];
    }
  },
  methods: {
    async hey( message ) {
      await this.$root.alert( message );
    },
    done() {
      this.$router.go(-1);
    },
    isImage( filename ) {
      let ext = filename.split(".").pop();
      if( ['png','gif','ico','jpg','jpeg','webm'].indexOf( ext.toLowerCase() ) >= 0 ) {
        return true;
      }
      return false;
    },
    async removeModel( model ) {
      if( ! await this.$root.confirm( "Remove " + model.text + "?" )) {
        return;
      }

      let index = _.findIndex( this.housingConfig.models , {value: model.value });

      if( index >= 0 ) {
        if( await this.$root.confirm( "Are you sure?  \nNOTE: The documents will not be removed automatically, so if any exist, remove those first before removing this model.")){
          // console.log( "found this at index " , index );
          this.housingConfig.models.splice(index,1);
          this.dataChanged();
        }
      } else {
        await this.$root.alert( "Oops, cannot find it - this is a possible issue")
      }

    },
    uploadCoverImage( model ) {
      this.imageUploadTitle = "Upload Cover for " + model.text;
      this.isShowCoverImageUpload = true;
      // this.$bvModal.show('imageUpload');
      this.selectedModel          = model;
      this.uploadFolder           = this.modelsFolder( model ) + "/cover";
    },
    modelsFolder( model ) {
      return 'housingModels/' + model.value ; 
    },
    async imageUploadComplete(file , serverResponse ) {
      this.isShowCoverImageUpload = false;

      let pathname = file.folder + "/" + file.filename;
      let response = await FileService.makeFilenameWithToken( pathname , 5256000 ); // get a 10 year token for this image.

      this.selectedModel.coverPhoto = response.data.fileUrl;
      this.dataChanged();

    },
    selectModel( model , force = false ) {
      if( this.modelTab !== 3 && !force) {
        return;
      }
       
      if( this.selectedModel === model.value ) {
        return;
      }

      this.selectedModel = model.value;

      this.getFiles(model);
    },
    async getFiles(model) {
      try {
        this.modelFiles    = null;
        if(! model.value) {
          return;
        }
        let response          = await FileService.getFiles(this.modelsFolder(model));
            this.modelFiles   = response.data.entries;
            this.downloadLink = response.data.downloadLink;
            this.fileRefresh  = false;
      } catch(error) {
        console.log("getFiles() Error", error);
        await this.$root.alert(error.message + " Your Files May Not Be Present - Please try again later");
        this.modelFiles   = [];
        this.fileRefresh = true;
        this.$root.errorMessage("Oops", error.response.data.message || error.message);
      }
    },
    async addModel() {
      if( await this.$root.confirm( "Add a new model?" )) {
        let model = { text:"",title:"", value:"new",class:"", prices: [] , options: {}};
        model.prices.push(  { effectiveDate: "", price: 0, approvedBy: "", setBy: "" } );
        this.housingConfig.models.push( model );
        this.dataChanged();
      }
    },
    hasPricing( m ) {
      if( m.prices ) {
        if( m.prices.length === 0 ) {
          m.prices.push( { effectiveDate: "", price: 0, approvedBy: "", setBy: "" });
        }
        return true;
      }
      return false;
    },
    async alert(e) {
      await this.$root.alert( e );
    },
    async save() {
      await this.saveHousingConfig();
      this.done();
    },
    async cancel() {
      // if( this.isChanged ) {
      //   if( ! await this.$root.confirm( "Data changed -- You sure?" )) {
      //     return;
      //   }
      // }
      this.$router.go(-1);
    },
    moment(d) {
      return Moment(d);
    },

    dataChanged() {
      this.isChanged = true;
    },

    showModal () {
      this.$refs.myModalRef.show()
    },
    hideModal () {
      this.$refs.myModalRef.hide()
    },

    async getHousingConfig() {
      var t = this;
      var response;
      try {
        response = await TenantService.getHousingConfig( );
        this.housingConfig = response.data;
        // this.modelFiles = this.housingConfig.models
      } catch (err) {
        console.log("getHousingConfig error: ", err);
        if (err.response) {
          if (err.response.data) {
            t.$root.errorMessage("Oops", err.response.data.message);
          }
        } else {
          t.$root.errorMessage("Oops", "Had an error getting the housing configuration");
        }
      }
    },

    async saveHousingConfig() {
      var t = this;
      var response;
      try {

        response = await TenantService.saveHousingConfig(this.housingConfig);

        localStorage.setItem( "housingConfig" , JSON.stringify(this.housingConfig));

        this.isChanged = false;
        t.$root.successMessage("SUCCESS", "Housing Config Updated");

      } catch (err) {
        if (err.response) {
          console.log("error response ", err.response);
          t.$root.errorMessage("Oops", err.response.data.message);
        } else if (err.request) {
          console.log("Error Request ", err.request);
        } else {
          console.log("message only ", err.message);
        }
        t.$root.errorMessage("Oops", "Unable to Save Housing Configuration Data");
      }
    }
  }

};
</script>

<style lang="scss">

  .help-text {
    color:gray;
    margin-left:1em;
    margin-right:8em;
    font-size:.8em;
    font-style:italic;
  }



</style>
