<template>
  <card class="col-md-12" style="border-radius:1em;" >
    <div style="margin-bottom:10px;">
      <b-button v-if="!scheduleItems && !schedule" class="btn btn-success btn-fill btn-sm float-left mr-2" @click="$router.go(-1)">
        Done
      </b-button>
      <h4 slot="header" class="card-title">
        Schedule Manager <i class="fa fa-plus-square show-hand float-right " v-b-popover.hover.top='"Add Schedule"'
                            @click="addNewSchedule()"></i>
      </h4>
      <hr />
    </div>

    <div v-if="((!scheduleItems) && (!schedule))" style="width:50%;">
      <h4>Category Selection</h4>
      <table class="table table-striped table-hover">
        <thead class="thead-dark">
          <tr>
            <th>Schedule Category</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(category,index) in categories" :key="index">
            <td class="show-hand" @click="getScheduleItems(category)">{{ category }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="scheduleItems && !schedule" >
      <h4>Schedule Items: {{(scheduleItems.length>0) ? scheduleItems[0].category : '(new)' }}</h4>
      <b-table :small="small" striped hover head-variant="dark" class="show-hand" :items="scheduleItems" :fields="columns" @row-clicked="editSchedule">
        <template v-slot:cell(actions)="row">
          <span @click.stop="deleteScheduleItem( $event , row )">
            <i class="fa fa-trash show-hand" ></i>
          </span>
        </template>
      </b-table>
      <div style="margin-top:5px;float:right;">
        <b-button class="btn btn-sm btn-fill btn-success" @click="getScheduleCategories()">Done</b-button>
      </div>
    </div>
    <!--
SchedulesForm.vue?a6f8:102 {
  "sequence": 0,
  "scheduleId": "1",
  "category": "lunch",
  "title": "Lower Grades",
  "description": "",
  "match": [
    "",
    " ",
    "K",
    "P1",
    "P2",
    "P3",
    "P4",
    "P5"
  ],
  "_id": "5d559b9aa7072e32ccb742be",
  "lastUpdateBy": "Wolfie",
  "updatedAt": "2019-08-19T16:08:26.395Z"
} -->

    <div v-if="schedule">

      <b-row>
        <b-col class="col col-md-12 col-lg-7">
          <h4>Schedule: {{schedule.title}}</h4>
          <b-checkbox style="float:right;" v-model="allowIdEdit" switch>Enable Id Edit</b-checkbox>
          <div>
            <b-form horizontal>
              <sf-input type="string" labelCols="3" cstyle="width:10em;" label="Schedule Id" v-model="schedule.scheduleId" :disabled="!allowIdEdit" helpText="Recommended NOT to edit this"/>
              <sf-input type="number" labelCols="3" cstyle="width:10em;" label="Sequence" v-model="schedule.sequence" />
              <sf-input type="text" labelCols="3" cstyle="width:15em;" label="Category" v-model="schedule.category" />
              <sf-input type="text" labelCols="3" cstyle="width:15em;" label="Group" v-model="schedule.group" />
              <sf-input type="text" labelCols="3" label="Title" v-model="schedule.title" />
              <sf-input type="textarea" labelCols="3" label="Description" v-model="schedule.description" />
              <div style="">
                <h5><i class="fa fa-plus-square show-hand" style="padding-right:5px;" @click="schedule.match.push('');"></i>Match List</h5>
                <hr>
                <ul style="list-style:none;padding-left:0;">
                  <li v-for="(match,index) in schedule.match" :key="index" style="text-align:left;">
                    <div style="display:inline;">
                      <i class="fa fa-trash" @click="schedule.match.splice(index,1);"></i>
                      <input cstyle="width:5em;" :label="'Match ' + (index+1).toString()" v-model="schedule.match[index]" />
                    </div>
                  </li>
                </ul>
              </div>
            </b-form>
          </div>

          <div style="margin-top:5px;float:right;">
            <b-button class="btn btn-sm btn-fill btn-danger" @click="schedule=null;">Cancel</b-button>
            <b-button class="btn btn-sm btn-fill btn-success" @click="saveScheduleItem( schedule )">Save</b-button>
          </div>
        </b-col>
        <b-col class="col col-md-12 col-lg-5">
          <div style="white-space:pre-line;font-size:.8em;height:auto;padding:1em;">
            <h4>Help</h4>
            <h5>Category</h5>
            <p>
              The Category is what defines a "schedule".  Creating several of the same category effectively
              builds up a multi-record "schedule.  Also, if have several with a schedule category and chagnejust
              one will seem like it created a new schedule
            </p><p>
              Categories that start with "myschool-" will be visible on the Production Summary Page.
            </p>
            <h5>Group</h5>
            <p>
              The GROUP is how the various sections will be identified and grouped.  Duplicate groups are treated as the same group.
            </p>
          </div>
        </b-col>

      </b-row>


    </div>
    <!-- <div class="clearfix"></div> -->
  </card>
</template>

<script>
  import ScheduleService from "/src/Services/ScheduleService";
  import Card from "/src/components/UIComponents/Cards/Card.vue";
  import SfInput from "/src/components/UIComponents/Inputs/SFormInput.vue";
  import LTable from "/src/components/UIComponents/Table.vue";

  export default {
    components: {
      LTable,
      Card,
      SfInput
    },
    data() {
      return {
        saveDisabled     : true,
        changeCount      : 0,
        modalTitle       : "Adding New Schedule",
        showScheduleModal: false,
        small            : true,
        allowIdEdit: false,
        categories       : [],
        scheduleItems    : null,
        schedule         : null,
        isScheduleChanged: false,
        columns          : [{
            key: "actions",
            label: "Actions"
          },
          {
            key: "scheduleId",
            label: "ID",
            sortable: true
          },
          {
            key: "sequence",
            label: "Sequence",
            sortable: true
          },
          // {key: "category", label: "Category", sortable: true},
          {
            key: "title",
            label: "Title",
            sortable: true
          },
          {
            key: "match",
            label: "Match",
            sortable: true
          },
          {
            key: "description",
            label: "Description",
            sortable: true
          }
        ]
      };
    },
    watch: {},
    created() {
      this.getScheduleCategories();
    },
    methods: {
      showAlert() {
        alert("you hit an alert");
      },
      itemChanged() {
        this.isScheduleChanged = true;
      },
      scheduleEditDone(schedule) {
        if (this.isScheduleChanged) {
          this.saveSchedule(schedule);
          this.isScheduleChanged = false;
        }
        this.showScheduleModal = false;
      },
      priceRowClass(isActive) {
        if (!isActive) {
          return "table-dark";
        }
      },
      showModal() {
        this.$refs.myModalRef.show()
      },
      hideModal() {
        this.$refs.myModalRef.hide()
      },
      checkMatch(item) {
        // todo: verify that a match value only exists once in a match array.
      },
      editSchedule(item, index, event ) {

        this.isScheduleChanged = false;
        this.schedule          = item;
      },
      async getScheduleCategories() {
        try {
          let response = await ScheduleService.getScheduleCategories();

          this.categories        = response.data;
          this.scheduleItems     = null;
          this.schedule          = null;
          this.group             = null;
          this.isScheduleChanged = false;

        } catch (error) {
          console.log(error.message);
        }
      },
      async getScheduleItems(category) {
        try {
          let response = await ScheduleService.getScheduleItems(category);
          this.scheduleItems = response.data;
          this.isScheduleChanged = false;

        } catch (error) {
          console.log(error.message);
        }
      },
      async saveScheduleItem(scheduleItem) {

        try {
          let category = scheduleItem.category;
          await ScheduleService.saveScheduleItem(scheduleItem);
          await this.getScheduleItems(category);
          this.isScheduleChanged = false;
          this.schedule          = null;

        } catch (error) {
          console.log("saveScheduleItem Error: " , error.message);
        }
      },
      async deleteScheduleItem(event , item) {

        if (!confirm("Remove " + item.item.title + "?")) {
          return;
        }

        event.stopPropagation();    // not stopping the row event -- have tried just about everything ... :(

        try {
          let response = await ScheduleService.deleteScheduleItem(item.item._id);

          this.schedule      = null;
          this.scheduleItems = null;

          await this.getScheduleItems(item.category);
        } catch (error) {
          console.error(error);
        }
      },
      async addNewSchedule() {
        let t = this;

        if (!confirm("Add New Schedule?")) {
          return;
        }

        let newItem = {
          title: "New Schedule Item"
        };

        if( t.schedule ) {
          newItem = _.clone( t.schedule );
          newItem.title += " (added)";
          newItem.scheduleId += "-1";
          newItem.sequence++;
        } else {
          let m =_.maxBy( t.scheduleItems, "scheduleId" );
          if (t.scheduleItems) {
            newItem["scheduleId"] = t.scheduleItems[0].scheduleId + "-" + (t.scheduleItems.length+1).toString();
            newItem["category"]   = t.scheduleItems[0].category;
          } else {
            newItem["category"] = 'new-schedule';
          }
        }

        try {
          let response = await ScheduleService.addScheduleItem(newItem);
          this.schedule = response.data;
          this.showScheduleModal = false;
        } catch (error) {
          console.log(error.message);
        }

      }
    }
  };
</script>

<style lang="scss">
</style>
