<template>
  <!-- "Printable" ticket display page -->
  <div>
    <div class="noprint" style="margin-bottom:1em;">
      <b-button class="btn btn-sm btn-fill btn-success mr-2" @click="cancel">Cancel</b-button>
      <b-button class="btn btn-sm btn-fill btn-warning" @click="printTicket">Print This Ticket</b-button>
    </div>
    <div class="print-page-setup">
      <div class="ticket-number">Request ID:<span>{{ticket.ticketNumber}}</span></div>
      <div class="ticket-assigned">Assigned To <span>{{ticket.assignedTo}}</span></div>
      <img class="ticket-logo" :src="logo" />
      <div class="ticket-date">Created: {{moment(ticket.createdAt).format("dddd, MMMM Do, YYYY")}}</div>
      <h2 class="ticket-header">Service Request</h2>
      <div v-if="config" class="ticket-address">
        <div>
          <table>
            <tr>
              <td>{{config.address.line1}}</td>
            </tr>
            <tr>
              <td>{{config.address.line2}}</td>
            </tr>
            <tr>
              <td>{{config.address.line3}}</td>
            </tr>
            <tr>
              <td>{{config.address.line4}}</td>
            </tr>
            <tr>
              <td>{{config.address.line5}}</td>
            </tr>
            <tr>
              <td>{{config.address.line6}}</td>
            </tr>
          </table>
        </div>
      </div>

      <div class="client-area">
        <h4>Client Information</h4>
        <div v-if="ticket.client.clientName" >
          <div class="client-name"><span>{{ticket.client.clientName}}</span></div>
          <div class="client-building"><span>{{ticket.client.clientBuilding}}</span> <span>{{ticket.client.clientRoomNumber}}</span></div>
          <div class="client-phone"><span>{{ticket.client.clientPhone}}</span></div>
          <div class="client-email"><span>{{ticket.client.clientEmail}}</span></div>
        </div>
        <div v-else>
          <span>Unassigned Ticket</span>
        </div>
      </div>
      <div v-if="hasAddress()" class="client-address">
        <div v-if="ticket.client.clientCompany">{{ticket.client.clientCompany}}</div>
        <div  v-if="ticket.client.clientAddress1">{{ticket.client.clientAddress1}}</div>
        <div  v-if="ticket.client.clientAddress2">{{ticket.client.clientAddress2}}</div>
        <div  v-if="ticket.client.clientCity || ticket.client.clientState || ticket.client.clientZip">{{ticket.client.clientCity}} {{ticket.client.clientState}} {{ticket.client.clientZip}}</div>
      </div>
      <div class="ticket-details-area">
        <h4>Work Description</h4>
        <div>
          <div class="ticket-title"><span>{{ticket.title}}</span></div>
          <div class="ticket-description"><span>{{ticket.description}}</span></div>
        </div>
      </div>

      <div v-if="ticket.client.clientName" class="signature-area">
        <div class="signature">
          <span>({{ticket.client.clientName}}) Authorized Signature</span>
        </div>

      </div>
    </div>
  </div>
</template>
<script>


  import Moment from "moment";

  export default {
    props: {
      ticket: { type: Object, default: () => { } },
      config: { type: Object, default: () => { } },
      logo:   { type: String, default: "" }
    },
    data() {
      return {
      }
    },
    mounted() {
      // console.log( "ticketDisplayPage" , this.ticket )
    },
    methods: {
      moment(d) {
        return Moment(d);
      },
      cancel() {
        this.$emit("cancel");
      },
      printTicket() {
        //window.print();
        this.$emit("print");
      },
      hasAddress() {
        let c = this.ticket.client;
        if (c.clientAddress1 || c.clientAddress2 || c.clientCity || c.clientState || c.clientzip) {
          return true;
        }
        return false;
      }
    }
  }

</script>
<style lang="scss" scoped>

  .print-page-setup {
    width:8.5in;
    height:11in;
    padding:.25em;
    margin:0;
  }


  .ticket-logo {
    position: relative;
    // width:15%;
    top:20px;
    left:20px;
    height:192px;
    width:192px;
    object-fit:contain;
  }


  .ticket-date {
    position:relative;
    top:-130px;
    text-align:center;
    font-size:12pt;
  }

  .ticket-header {
    position:relative;
    top:-220px;
    text-align:center;
  }

  .ticket-assigned {
    position:relative;
    top:20px;

    // text-align:left;
    span {
      font-weight:600;
    }
  }

  .ticket-number {
    float:right;
    margin-top:1em;
    font-size:1.2em;
    span {
      padding-left:1em;
      padding-right:1em;
      font-weight:600;
    }
  }

  .ticket-address {
    position:relative;
    display:block;
    top: -200px;
    left: 510px;
    width: 50%;
    font-size:12pt;
    text-align:right;
    tr:first-child {
      font-size:24pt;
    }
    tr {
      line-height:.95em;
    }
  }

  .ticket-details-area {
    position:absolute;
    padding:.5em;
    top:570px;
    height:650px;
    border-radius:1em;
    width:8.40in;
    h4 {
      margin-top:0;
      margin-bottom:0;
    }
  }

  .ticket-title {
    /*font-weight:600;*/
    font-size:1.25em;
    margin-left:.75em;
    border-bottom:1px solid black;
    width:98%;
  }

  .ticket-description {
    height:560px;
    max-width:100%;
    white-space: pre-line;
    padding-left:.5em;
    padding-top:.25em;
    font-size: 10pt;
    margin-left: 1em;

    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    -webkit-column-gap: 20px;
    -moz-column-gap: 20px;
    column-gap: 20px;
    column-fill:auto;

    span {
      overflow:hidden;
      text-overflow: ellipsis;
    }

  }

  .client-area {
    display:block;
    position:relative;
    background:#eee;
    // top:400px;
    top:-150px;
    padding:.5em;
    border-radius:1em;
    width: 350px; // 35%;
    h4 {
      margin-top:0;
      margin-bottom:0;
    }
    div {
      margin-top:0;
      margin-left:20px;
    }
  }

  .client-address {
    display:block;
    position:absolute;
    border-radius:1em;
    background:#eee;
    padding:.5em .5em .5em 1em;
    top:420px;
    width:400px;
/*    height:100px;*/
    left:450px;
  }

  .signature-area {
    position:relative;
    // top: 670px;
    top:540px;
    left: 400px;
    width: 325px;
    .signature {
      position:relative;
      top: 20px;
      text-align:center;
      border-top: 2px solid black;
      padding-right:1em;
      font-size:.8em;

    }
  }



  @media print {

    @page {
      width:8.5in;
      height:11in;
      margin: 0;
      padding: 0;
      size: letter;
    }

    /*.ticket-description {*/
    /*  height: 800px;*/
    /*}*/

    .ticket-details-area {
      height:510px;
    }

    .ticket-description {
      height:410px;
    }

    .noprint {
      display: none;
    }

  }

</style>
