///-------------------------------------------------------------------------------------------------
// summary:	Task service class
///-------------------------------------------------------------------------------------------------

import Api from '@/Services/Api';

export default {
  
  getProjects() {
    const response = Api().get(`/api/1/projects`);
    return response;
  },
  getTasksByProjectId( projectRefId ) {
    const response = Api().get(`/api/1/task/${encodeURI(projectRefId)}`);
    return response;
  },
  addTask(task, projectId) {
    const response = Api().post(`/api/1/task/${encodeURI(projectId)}`, task);
    return response;
  },
  saveTask(task, taskId) {
    delete task.history;            // just a little housekeeping to keep the data to a minimum.
    delete task.notes;

    const response = Api().patch(`/api/1/task/${encodeURI(taskId)}`, task);
    return response;
  },
  
  getTasks(projectId) {
    const response = Api().get(`/api/1/tasks/${encodeURI(projectId)}`);
    return response;
  },
  getTask(taskId) {
    const response = Api().get(`/api/1/task/${encodeURI(taskId)}`);
    return response;
  },

  deleteTask(taskId) {
    const response = Api().delete(`/api/1/task/${encodeURI(taskId)}`);
    return response;
  },

  moveTaskState(taskId, newState) {
    const data = { _id: taskId, currentState: newState };
    const response = Api().patch(`/api/1/task/${encodeURI(taskId)}`, data);
    return response;
  },

  setTaskStep(taskId, data) {
    const response = Api().post(`/api/1/taskSetStep/${encodeURI(taskId)}`, data);
    return response;
  },

  setTaskNote(taskId, data) {
    const noteType = 2;  // user note
    const response = Api().post(`/api/1/taskHistory/${encodeURI(taskId)}/${encodeURI(noteType)}`, data);
    return response;
  },

  setTaskReleaseNote(taskId, data) {
    const noteType = 3;  // release note
    const response = Api().post(`/api/1/taskHistory/${encodeURI(taskId)}/${encodeURI(noteType)}`, data);
    return response;
  },
  getSimpleTaskCategories() {
    return Api().get(`/api/1/simpleTaskCategories`);
  },
  getSimpleTasks( category , isShowCompleted ) {
    return Api().get(`/api/1/simpleTasks/${encodeURI(category)}`, {params: { includeComplete: (isShowCompleted) ? 'yes' : '' }});
  },
  getSimpleTask( id ) {
    return Api().get( `/api/1/simpleTask/${id}`)
  },
  postSimpleTask( task ) {
    return Api().post( "/api/1/simpleTask" , task );
  },
  updateSimpleTask( task ) {
    return Api().patch( "/api/1/simpleTask" , task );
  },
  deleteSimpleTask( id ) {
    return Api().delete( `/api/1/simpleTask/${id}`)
  },
  markComplete( id ) {
    return Api().post( `/api/1/simpleTaskComplete/${id}`)
  },
  markIncomplete( id ) {
    return Api().post( `/api/1/simpleTaskIncomplete/${id}`)
  }
  
  
}
