<template>
  <div class="content">
    <div class="container-fluid">
      <card>
        <template slot="header">
          <h3 class="card-title">Teacher Listing
            <button class="float-left mr-3 btn btn-success btn-sm btn-fill" @click="$router.push('/app');"><span v-show="loading" :class="isLoading"></span>Done</button>
          </h3>
          <hr>
        </template>
        <div class="row">
          <div v-if="teachers.length>0" class="col-md-6">
            <h4>Teachers</h4>
<!--            <ol>-->
<!--              <li v-for="(teacher,index) in teachers">{{teacher}}</li>-->
<!--            </ol>-->
            <!-- <table class="table-striped table-bordered table-hover thead-dark" v-if="teachers.length>0 " >
              <tbody>
              <tr v-for="(teacher,index) in teachers" :key="index">
                <td style="width:60px;">{{index+1}}</td>
                <td style="width:350px;">{{ teacher.teacher }}</td>
                <td style="width:100px;">{{ teacher.grade }}</td>
              </tr>
              </tbody>
            </table> -->
            <b-table striped hover head-variant="dark" stacked="md" sort-by="title" :items="teachers" :fields="columns" >
              <template v-slot:cell(index)="data">
                {{data.index+1}}
              </template>
            </b-table>
          </div>
          <div v-else>
            <h5>No Teachers</h5>
          </div>
        </div>
      </card>

    </div>
  </div>
</template>
<script>
  import Card from "/src/components/UIComponents/Cards/Card.vue";
  import PortalRegistrationService from "/src/Services/PortalRegistrationService";
  import Moment from "moment";

  export default {
    components: {
      Card,
      Moment
    },
    data() {
      return {
        columns: [
            {key: "index" , label: "index" , sortable: true },
            {key: "teacher" , label: "Teacher" , sortable: true },
            {key: "grade" , label: "Grade" , sortable: true },
        ],
        teachers     : [],
        loading      : false,
        isLoading    : "fa fa-spinner  fa-refresh-animate mr-3",
        type         : [ '', 'info', 'success', 'warning', 'danger' ],
        notifications: {
          topCenter: false
        }
      }
    },
    async mounted() {
      await this.getTeachers();
    },
    methods   : {
      moment(d) {
        return Moment(d);
      },
      notifyVue(verticalAlign, horizontalAlign) {
        const notification = {
          template: `<span>Welcome to <b>Light Bootstrap Dashboard</b> - a beautiful freebie for every web developer.</span>`
        }

        const color = Math.floor((Math.random() * 4) + 1)
        this.$notifications.notify(
          {
            component      : notification,
            icon           : 'nc-icon nc-app',
            horizontalAlign: horizontalAlign,
            verticalAlign  : verticalAlign,
            type           : this.type[ color ]
          })
      },
      async getTeachers() {
        let response;
        let t = this;

        t.loading = true;

        try {

          response      = await PortalRegistrationService.getPortalRegistrationsTeachers("student");
          this.teachers = response.data;
          t.loading     = false;
        } catch(err) {
          if(err) {
            console.error(err);
            t.$root.errorMessage("Oops", err.response.data.message);
          }
          t.loading = false;
        }
      }
    }
  }

</script>
<style lang="scss">

</style>
