<template>
  <div class="ticket">
    <div class="ticket-body" @click="ticketClick(ticket)" >
      <div class="ticket-status">
        <div>
          {{ticket.ticketNumber}} <b-badge v-if="moment(ticket.updatedAt).isAfter(lastDate)" pill variant="danger" style="font-size:.75em;" v-b-popover.hover.top="'This ticket has been updated'">updated</b-badge>
        </div>
        <div class="show-hand ticket-style" :style="statusStyle()" >
          {{ticket.closed ? "CLOSED" : ticket.status}}
        </div>
      </div>
      <div class="ticket-description show-hand" >
        <div>
          {{ticket.title}}
        </div>
        <div>
          <span v-if="ticket.category" style="text-transform:capitalize;margin-right:.5em;font-size:.8em;font-weight:300;">({{ticket.category}})</span>{{ticket.description}}
        </div>
      </div>
    </div>
   <div class="ticket-footer">
        <div class="ticket-square drop-small" @click="ticketPin(ticket)" :class="ticket.isPin? 'ticket-pin-dark' : 'ticket-pin-light'">
        </div>
        <div class="ticket-square drop-small" @click="ticketBillable(ticket)" :class="ticket.billable? 'ticket-star-dark' : 'ticket-star-light'">
        </div>
        <div class="ticket-square ticket-more-light drop-small" @click="ticketMore(ticket)" >
        </div>
<!--     <div class="ticket-square ticket-more-light dropdown show" id="dropdown" ref="ticketdropdown" @click="$refs.ticketdropdown('toggle')">-->
<!--       <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">-->
<!--         <a class="dropdown-item" href="#">Action</a>-->
<!--         <a class="dropdown-item" href="#">Another action</a>-->
<!--         <a class="dropdown-item" href="#">Something else here</a>-->
<!--       </div>-->
<!--     </div>-->


<!--        <div class="ticket-square" @click="ticketMore(ticket)" :class="isMore? 'ticket-more-dark' : 'ticket-more-light'">-->
<!--            <ul role="menu" :class="isMore ? 'dropdown-menu show' :  'dropdown-menu'" x-placement="right-start">-->
<!--            <li data-v-abeadf28="">-->
<!--                <a role="menuitem" target="_self" href="#" class="dropdown-item">Action</a>-->
<!--            </li>-->
<!--            <li data-v-abeadf28="">-->
<!--                <a role="menuitem" target="_self" href="#" class="dropdown-item">Action 2</a>-->
<!--            </li>-->
<!--        </ul>-->
<!--        </div>-->

        <div class="ticket-assign">
            <!-- <div class="img-container" v-if="ticket.assignedToPictureUrl"> -->
              <div class="img-container" v-if="ticket.assignedToEmail">
                <img v-if="ticket.assignedToPictureUrl" :src="ticket.assignedToPictureUrl" width="24" alt="picture" srcset="" />
                <img v-else :src="myGravatar(ticket.assignedToEmail)" width="24" alt="picture" srcset="" />
            </div>
            <!-- <div class="no-image" v-if="!ticket.assignedToPictureUrl" :style="getRandomColor(true)"> -->
            <div class="no-image" v-if="!ticket.assignedToEmail" :style="getRandomColor(true)">
                <p>{{assigneeShortName}}</p>
            </div>
            <div class="assigned-text">
            <span>assigned to</span>
            <div>{{ticket.assignedTo}}</div>
            </div>
        </div>
        <div v-if="ticket.client" class="ticket-assign" >
            <div class="img-container" v-if="ticket.client.clientEmail">
                <img v-bind:src="myGravatar(ticket.client.clientEmail)" width="24" alt="picture" srcset="" />
            </div>
            <div class="no-image" v-if="!ticket.client.clientEmail && ticket.client.clientName && ticket.client.clientName.length>0" :style="getRandomColor()">
                <p>{{clientShortName}}</p>
            </div>
            <div class="assigned-text">
              <span v-if="ticket.client.clientName && ticket.client.clientName.length>0" >client</span>
              <div>{{ticket.client.clientName}}</div>
            </div>
        </div>
        <div class="ticket-priorty">
            <div v-if="!isShowTicketType" class="priority-text">
              <span v-if="!ticket.closed">priorty</span>
              <div v-if="!ticket.closed" :style="priorityStyle()">{{ticket.priority}}</div>
            </div>
            <div v-if="isShowTicketType" class="priority-text">
              <span class="priority-text-ex">{{ticketTypeTitle(ticket.ticketType)}}</span>
              <div v-if="!ticket.closed" :style="priorityStyle()">{{ticket.priority}}</div>
            </div>
        </div>
       <div class="ticket-due-date drop-small">
         <div class="due-date-text" v-b-popover.hover.top="moment(ticket.startDate).format('LLLL')">
           <span >Start</span>
           <div>{{ moment(ticket.startDate).format("MM-DD-YY")}}</div>
         </div>
       </div>
        <div class="ticket-due-date drop-small">
            <div class="due-date-text">
            <span v-if="!ticket.closed">due in</span>
            <div :style="ticketTime()" v-if="!ticket.closed"  v-b-popover.hover.top="(ticket.dueDate) ? moment(ticket.dueDate).format('LLLL') : 'No Date Set'">{{(ticket.dueDate) ? moment(ticket.dueDate).endOf('day').subtract(8,'hours').format("MM-DD-YY"):"-"}}</div>
            </div>
        </div>
       <div class="ticket-due-date drop-small" >
         <div class="due-date-text">
           <span>Total</span>
           <div>{{currency(ticket.ticketTotal)}}</div>
         </div>
       </div>
    </div>
  </div>
</template>
<script>
  //import Card from './Card.vue'
  import Moment from "moment";
  import gravatar from "gravatar";
  import Currency from "currency.js";

  export default {
    name: 'ticket-card',
    components: {
      //Card
    },
    data() {
      return {
        cutoffDate: null
        // isPin: false,
        // isStar: false,
        // isMore: false
      }
    },
    props: {
      ticket          : { type: Object, default: () => {} },
      ticketIndex     : { type: Number, default: 1 },
      isShowTicketType: { type: Boolean, default: false },
      lastDate        : { type: String, defualt: null },
      ticketTypes     : { type: Array, default: () => []}
    },
    computed: {
      assigneeShortName() {
        if( ! this.ticket.assignedto ) {
          return "";
        }
        let name = this.ticket.assignedTo.toUpperCase().split(" ")
        let firstLetter = name[0].charAt(0)
        return name.length > 1 ? (firstLetter + name[1].charAt(0)) : firstLetter
      },
      clientShortName() {
        if( !this.ticket.client) {
          return "";
        }
        let clientName = this.ticket.client.clientName.toUpperCase().split(" ")
        let firstLetter = clientName[0].charAt(0)
        return clientName.length > 1 ? (firstLetter + clientName[1].charAt(0)) : firstLetter
      }
    },
    methods: {
      myGravatar(email) {
        return gravatar.url(email);
      },
      currency(c) {
        return Currency(c, { symbol: "", precision: 2 }).format();
      },
      getRandomColor(sortColorDesc) {
        let that = this
        const letters = "0123456789ABCDEF";
        var color = "#";
        let colorArr = [
          "d89385",
          "4543b2",
          "5bd992",
          "000f35",
          "1c87c9",
          "d12ef6"
        ]
        let index = sortColorDesc ? 5 - (this.ticketIndex % 6) : (this.ticketIndex % 6)
        color += colorArr[index]
        /* if(sortColorDesc) { //sortColorDesc is using for switch color for assigned to column if image not present
             switch() {
               case 0:
                 color +=
               break;
               case 1:
                 color +=
               break;
               case 2:
                 color +=
               break;
               case 3:
                 color +=
               break;
               case 4:
                 color +=
               break;
               case 5:
                 color +=
               break;
             }
         } else {
           switch(this.ticketIndex % 6) {
               case 5:
                 color += "d89385"
               break;
               case 4:
                 color += "4543b2"
               break;
               case 3:
                 color += "5bd992"
               break;
               case 2:
                 color += "000f35"
               break;
               case 1:
                 color += "1c87c9"
               break;
               case 0:
                 color += "d12ef6"
               break;
             }
         }*/

        return "background-color:" + color + ";";
      },
      moment(d) {
        return Moment(d);
      },
      ticketTime() {
        let t = this;
        let color,days;

        if( !this.ticket.dueDate ) {
          return "color:black;";
        }

        if (t.ticket.status === 'parked') {
          return "background:#1B76FF;color:white;;color:white;padding: 0 1em 0 1em;font-size:.8em;text-align:center;";
        }

        if (t.ticket.status === 'complete') {
          return "background-color:#fff;color:white;";
        }

        days = Moment().endOf( 'day').subtract(6,'hours').diff(t.ticket.dueDate, 'days');

        if( days < 0 )
          return "";

        if(days >= 5) {
          color = "#B01E1E" // red
        } else {
          color = "#E0A52F" // yellow
        }

        return "background-color:" + color + ";color:white;padding: 0 1em 0 1em;font-size:.8em;text-align:center;";
      },
      ticketTypeTitle( ticketType ) {
        // return TicketsLib.about();
        if( !ticketType || !this.ticketTypes ) {
          return "** (undefined)"
        }
        let t = _.find( this.ticketTypes , { key: ticketType });
        return (t) ? t.title : "** " + (ticketType || "");
      },
      priorityColor() {
        let t = this;
        let color;
        if (t.ticket.priority === "normal") {
          color = "forestgreen";
        } else if (t.ticket.priority === "high") {
          color = "firebrick"
        } else if (t.ticket.priority === "low") {
          color = "lightgray"
        } else if (t.ticket.priority === "critical") {
          color = "fuchsia"
        } else {
          color = "black";
        }

        return "color:" + color + ";";
      },
      statusColor() {
        let t = this;
        let color;
        let status = t.ticket.status;

        if (t.ticket.closed) {
          return "background-color:red;";
        }

        if (status === "open") {
          color = "forestgreen";
        } else if (status === "new") {
          color = "orange"
        } else if (status === "parked") {
          color = "lightgray"
        } else if (status === "in-process") {
          color = "fuchsia"
        } else {
          color = "black";
        }

        return "background-color:" + color + ";";
       },
      dueStyle() {
        if( ! this.ticket.dueDate ) {
          return "";
        }
        let days = Moment().subtract( this.ticket.dueDate ).format("DD" );
      },
      priorityStyle() {
        let c = "";
        switch( this.ticket.priority ) {
          case "low":
            c = "color:gray;";
            break;

          case "normal":
            c = "color:green;";
            break;

          case "high":
            c = "background:#FA9600;color:white;padding:0 2em 0 2em;text-align:center;";
            break;

          case "critical":
            c = "background:#FF2D00;color:white;padding:0 2em 0 2em;text-align:center;";
            break;

          default:
            c = "background:black;color:white;padding:0 2em 0 2em;text-align:center;";
        }

        return "" + c;
      },
      statusStyle() {
        let c;
        switch( this.ticket.status ) {
          case "new":
            c = "background:orange;color:white;";
            break;

          case "open":
            c = "background:green;color:white;";
            break;

          case "in-process":
            c = "background:#9C27B0;color:white;";
            break;

          case "complete":
            c = "background:#333;color:white;";
            break;

          case "stuck":
            c = "background:#d25a53;color:white;";
            break;

          case "parked":
            c = "background:#1B76FF;color:white;";
            break;

          default:
            c = "background:#8B63A6;color:white;";
        }

        return "" + c;
      },
      ticketClick(ticket) {
        this.$emit("select", ticket._id);
      },
      ticketPin(ticket) {
        this.$emit("pin", ticket._id);
      },
      ticketBillable(ticket) {
        this.$emit("billable", ticket._id);
      },
      ticketStar(ticket) {
        this.$emit("star", ticket._id);
      },
      ticketMore(ticket) {
        this.$emit("more", ticket._id);
      }
    }
  }

</script>
<style lang="scss" scoped>
    .ticket {
      display: flex;
      /*height: 70;*/
      width: 100%;
      border-radius: .5em;
      margin-bottom: 10px;
      background-color: #eee;
      flex-direction: column;
      border: 1px solid #eeeeee;
      overflow: hidden;
      box-shadow: 0px 0px 10px -7px #000;

      .ticket-body {
        display: flex;
        width: 100%;
        background-color: #fff;
        flex-direction: row;
        padding-top: 10px;
        padding-bottom: 10px;

        .ticket-priority {
          vertical-align: top;
          border-radius: .25em;
          height: 100%;
          width: 10px;
          margin-left: 3px;
        }

        .ticket-status {
          height: 100%;
          width: 140px;
          padding: 2px 0px 2px;

          div:first-child {
            font-size: .8em;
            font-weight: 800;
            text-align: center;
          }

          div:nth-child(2) {
            margin-top: .5em;
            width: 100%;
            text-align: center;
            font-size: .7em;
            font-weight: 700;
            padding: 5px 5px;
            border-radius: 2em;
            color: white;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            text-transform: uppercase;
            box-sizing: border-box;
          }
        }

        .ticket-description {
          width: 100%;
          text-align: left;
          padding-left: 15px;
          color: #777;
          overflow: hidden;

          div:first-child {
            height: 28px;
            font-size: 1.25em;
            font-weight: 700;
            color: #686868;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          div:nth-child(2) {
            height: 18px;
            font-size: .9em;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          div:nth-child(3) {
            height: 18px;
            font-size: .9em;
            // padding-left:16px;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }



      .ticket-footer {
        vertical-align: bottom;
        display: flex;
        flex-direction: row;
        width: 100%;
        border-style: solid;
        border-width: 1px 0 0 0;
        border-color: #eee;
        background-color: #f9f9f9;

        .no-image {
          width: 32px;
          height: 32px;
          background: red;
          position: relative;
          border-radius: 50%;
          overflow: hidden;
          margin: 5px 10px;

          p {
            font-weight: 900;
            position: absolute;
            transform: translate(-50%, -50%);
            left: 50%;
            top: 50%;
            margin: 0;
            padding: 0;
            font-size: 13px;
            font-family: Arial;
            color: #fff;
          }
        }

        .ticket-square {
          vertical-align: top;
          display: inline-block;
          width: 40px;
          color: gold;
          font-size: 24pt;
          vertical-align: middle;
          text-align: center;
          border-right: 1px solid #eee;
          cursor: pointer;
          background-size: 52%;
          background-repeat: no-repeat;
          background-position: center;
          position: relative;

          &.ticket-pin-light {
            background-image: url('../../../assets/svg/push-pin.svg');

            &:hover {
              background-image: url('../../../assets/svg/push-pin-dark.svg');
            }
          }

          &.ticket-pin-dark {
            background-image: url('../../../assets/svg/push-pin-dark.svg');
          }

          &.ticket-star-light {
            background-image: url('../../../assets/svg/dollar-sign.svg');

            &:hover {
              background-image: url('../../../assets/svg/dollar-sign-dark.svg');
            }
          }

          &.ticket-star-dark {
            background-image: url('../../../assets/svg/dollar-sign-dark.svg');
          }

          &.ticket-more-light {
            background-image: url('../../../assets/svg/more.svg');

            &:hover {
              background-image: url('../../../assets/svg/more-dark.svg');
            }
          }

          &.ticket-more-dark {
            background-image: url('../../../assets/svg/more-dark.svg');
          }

          ul.dropdown-menu {
            transform: translate3d(35px, -48px, 0px);
            position: absolute;
            top: 0px;
            left: 0px;
            will-change: transform;
          }
        }


        text-transform: capitalize;

        .ticket-assign {
          border-right: 1px solid #eee;
          display: flex;
          align-items: center;
          width: 24%;
          padding: 5px 0px;
          text-align: left;

          .img-container {
            border-radius: 50%;
            width: 32px;
            height: 32px;
            overflow: hidden;
            margin: 5px 5px;

            img {
              width: 100%;
              height: auto;
            }
          }

          .assigned-text {
            font-size: 0.85rem;
            overflow:hidden;
            div {
              font-weight: 700;
            }
          }
        }

        .ticket-priorty {
          border-right: 1px solid #eee;
          display: flex;
          align-items: center;
          width: 15%;
          padding-left: 10px;
          text-align: left;

          .priority-text {
            font-size: 0.85rem;

            div {
              font-weight: 700;
            }

            .priority-text-ex {
              font-weight:900;
            }

          }
        }

        .ticket-due-date {
          border-right: 1px solid #eee;
          display: flex;
          align-items: center;
          width: 15%;
          padding-left: 10px;
          text-align: left;

          .due-date-text {
            font-size: 0.85rem;

            div {
              font-weight: 700;
            }
          }
        }
      }
    }


    @media only screen and (max-width: 768px) {
      .drop-small {
        display:none;
      }
    }


</style>
