<template>
  <div class="content container-fluid">

    <div v-if="phoneDirectory"  class="phone0directory-wrapper">
      <div class="directory-control noprint" >
        <div class="directory-control-options noprint" >
          <b-checkbox style="margin-right:2em;" v-model="phoneDirectoryAccounts">Include Accounts</b-checkbox>
          <b-checkbox style="margin-right:2em;" v-model="phoneDirectoryBirthdays">Include Birthday Summary</b-checkbox>
          <b-checkbox style="margin-right:2em;" v-model="directoryIncludeAddress">Include Address</b-checkbox>
          <b-checkbox style="margin-right:2em;" v-model="directoryIncludeAltPhone">Include Alt Phone #</b-checkbox>
          <b-checkbox style="margin-right:2em;" v-if="$root.isAdmin()" v-model="directoryIncludeAccountNumber">Include Account #</b-checkbox>
        </div>
        <b-button class="btn btn-fill btn-sm btn-info mr-4 noprint" @click="phoneDirectory=null">Close Directory</b-button>
        <b-button class="btn btn-fill btn-sm btn-success mr-4 noprint" @click="makeDirectoryPdf()">Generate PDF</b-button>
        <b-button class="btn btn-fill btn-sm btn-warning mr-4 noprint" @click="printPhoneDirectory()">Print Directory</b-button>
      </div>
      <div class="phone-directory" id="theDirectory">
        <h2 style="margin:0;padding:0;">{{ searchAccountType }} Directory</h2>
        <h4 style="margin:0;padding:0;">{{ emoment().format("dddd, MMMM DD, YYYY") }}</h4>

        <div v-if="phoneDirectoryAccounts" class="directory-row" v-for="(letter, index) in letters" :key="index">
          <div style="page-break-inside: avoid;">
            <div class="directory-grid-title">{{letter.toUpperCase()}}</div>
            <div class="directory-grid">
              <div v-for="entry in phoneDirectory.filter(entry => ((entry.lastName) ? entry.lastName.charAt(0).toLowerCase() : '')  === letter)" class="directory-entry" :style="isThisMonth(entry.dob) ? 'background:#ccffe6;' :''">
                <img v-if="entry.pictureUrl" :src="entry.pictureUrl" style="height:64px;border-radius:1em;" />
                <div @click="editAccount(entry.accountNumber)"><strong>{{ entry.lastName }}, {{entry.firstName}}</strong><span v-if="entry.status.isOffSite" style="margin-left:1em;"><img src="@/assets/img/out-icon.png" style="width:.7em;"/></span></div>
                <div v-if="directoryIncludeAccountNumber" style="margin-left:1em;font-size:.9em;">{{ entry.accountNumber }}</div>
                <div style="margin-left:1em;">
                  <div v-if="entry.status.isPublishEmail !== false && entry.email">{{ entry.email }}</div>
<!--                  <div v-if="entry.phone"><i class="fa fa-phone" style="font-size:.8em;font-weight:100;"></i> {{ entry.phone }} <span v-if="directoryIncludeAltPhone">{{entry.altPhone }}</span></div>-->
                  <div v-if="entry.phone"><img src="@/assets/img/phone-icon.png" style="width:.7em;"/> {{ entry.phone }} <span v-if="directoryIncludeAltPhone">{{entry.altPhone }}</span></div>
                  <div v-if="entry.status.isPublishCell !== false && entry.cell"><i class="fa fa-mobile" style="font-size:.8em;font-weight:100;"></i> {{ entry.cell }}</div>
                  <div v-if="entry.status.isPublishDOB !== false && entry.dob"><span><img src="@/assets/img/birthday-icon.png" style="width:.7em;"/> {{emoment(entry.dob).format("MMMM, Do")}}</span></div>
                  <div v-if="entry.status.isPublishAddress !== false && directoryIncludeAddress">
                    <div v-if="entry.address && entry.address.address1">{{ scrubAddressEntry(entry.address.address1) }}</div>
                    <div v-if="entry.address && entry.address.address2">{{ scrubAddressEntry(entry.address.address2) }}</div>
                    <div v-if="entry.address && entry.address.city">{{ entry.address.city }}, {{ entry.address.state }} {{ entry.address.zip }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="phoneDirectoryBirthdays">
          <div >
            <!-- todo: Can add a field for # of advanced months, and then just loop  OR create another listing of birthdays with checkboxes for the months desired -->
            <div class="directory-grid-title">Birthdays For {{emoment().format("MMMM")}}</div>
            <div class="directory-grid">
              <div v-for="entry in phoneDirectory.filter(entry => isThisMonth(entry.dob) && entry.status.isPublishDOB !== false ).sort((a, b) => emoment(a.dob).date() - emoment(b.dob).date())" class="directory-entry">
                <img v-if="entry.pictureUrl" :src="entry.pictureUrl" style="height:64px;border-radius:1em;" />
                <div @click="editAccount(entry.accountNumber)"><strong>{{ entry.lastName }}, {{entry.firstName}}</strong><span v-if="entry.status.isOffSite" style="margin-left:1em;"><img src="@/assets/img/out-icon.png" style="width:.7em;"/></span></div>
                <div style="margin-left:1em;">
                  <div v-if="entry.status.isPublishEmail !== false && entry.email">{{ entry.email }}</div>
                  <!--                  <div v-if="entry.phone"><i class="fa fa-phone" style="font-size:.8em;font-weight:100;"></i> {{ entry.phone }} <span v-if="directoryIncludeAltPhone">{{entry.altPhone }}</span></div>-->
                  <div v-if="entry.phone"><img src="@/assets/img/phone-icon.png" style="width:.7em;"/> {{ entry.phone }} <span v-if="directoryIncludeAltPhone">{{entry.altPhone }}</span></div>
                  <div v-if="entry.status.isPublishCell !== false && entry.cell"><i class="fa fa-mobile" style="font-size:.8em;font-weight:100;"></i> {{ entry.cell }}</div>
                  <div v-if="entry.status.isPublishDOB !== false && entry.dob"><span><img src="@/assets/img/birthday-icon.png" style="width:.7em;"/> {{emoment(entry.dob).format("MMMM, Do")}}</span></div>
                  <div v-if="entry.status.isPublishAddress !== false && directoryIncludeAddress">
                    <div v-if="entry.address && entry.address.address1">{{ scrubAddressEntry(entry.address.address1) }}</div>
                    <div v-if="entry.address && entry.address.address2">{{ scrubAddressEntry(entry.address.address2) }}</div>
                    <div v-if="entry.address && entry.address.city">{{ entry.address.city }}, {{ entry.address.state }} {{ entry.address.zip }}</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="directory-grid-title">Birthdays For {{emoment().add(1,'month').format("MMMM")}}</div>
            <div class="directory-grid">
              <div v-for="entry in phoneDirectory.filter(entry => isThisMonth(entry.dob,1) && entry.status.isPublishDOB !== false ).sort((a, b) => emoment(a.dob).date() - emoment(b.dob).date())" class="directory-entry">
                <img v-if="entry.pictureUrl" :src="entry.pictureUrl" style="height:64px;border-radius:1em;" />
                <div @click="editAccount(entry.accountNumber)"><strong>{{ entry.lastName }}, {{entry.firstName}}</strong><span v-if="entry.status.isOffSite" style="margin-left:1em;"><img src="@/assets/img/out-icon.png" style="width:.7em;"/></span></div>
                <div style="margin-left:1em;">
                  <div v-if="entry.status.isPublishEmail !== false && entry.email">{{ entry.email }}</div>
                  <!--                  <div v-if="entry.phone"><i class="fa fa-phone" style="font-size:.8em;font-weight:100;"></i> {{ entry.phone }} <span v-if="directoryIncludeAltPhone">{{entry.altPhone }}</span></div>-->
                  <div v-if="entry.phone"><img src="@/assets/img/phone-icon.png" style="width:.7em;"/> {{ entry.phone }} <span v-if="directoryIncludeAltPhone">{{entry.altPhone }}</span></div>
                  <div v-if="entry.status.isPublishCell !== false && entry.cell"><i class="fa fa-mobile" style="font-size:.8em;font-weight:100;"></i> {{ entry.cell }}</div>
                  <div v-if="entry.status.isPublishDOB !== false && entry.dob"><span><img src="@/assets/img/birthday-icon.png" style="width:.7em;"/> {{emoment(entry.dob).format("MMMM, Do")}}</span></div>
                  <div v-if="entry.status.isPublishAddress !== false && directoryIncludeAddress">
                    <div v-if="entry.address && entry.address.address1">{{ scrubAddressEntry(entry.address.address1) }}</div>
                    <div v-if="entry.address && entry.address.address2">{{ scrubAddressEntry(entry.address.address2) }}</div>
                    <div v-if="entry.address && entry.address.city">{{ entry.address.city }}, {{ entry.address.state }} {{ entry.address.zip }}</div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>
    </div>

    <b-row class="row" v-if="!phoneDirectory">

      <!-----------[ Account Selection ]------------------------ -->

      <b-col class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-7 offset-xs='0' shadow p-3 mt-0 ml-3 mr-1 mb-5 noprint " order-sm="2" order-md="1" style="border-radius:1em;background:#eee;">

        <h2 class="mt-0">
          <i class="fa fa-plus-square show-hand float-right mr-3" @click="newAccountInfo" v-b-popover.hover.top="'Add Account'"></i>
          Account Search
        </h2>
        <div v-if="!accountClasses || accountClasses.length == 0">Loading...</div>
        <table style="width:100%;" v-if="accountClasses.length>0">
          <tr>
            <td><label>Search Filter</label></td>
            <td colspan="3">
              <div style='display:flex;'>
                <b-form-select type="list" style="width:17em;border-radius:.75em;" :options="accountClasses.filter((c) => {return c.options.indexOf('allow-search') >= 0}).map((a) => {return { text:a.title, value:a.title, searchFields:a.searchListFields}}).sort((a,b) => {return a.text===b.text ? 0 : a.text>b.text ? 1 : -1})" v-model="searchAccountType" />
                <i v-if="searchAccountType" class="fa fa-eraser show-hand" style="font-size:1.25em;margin-left:.5em;" @click="searchAccountType=searchString=''" v-b-popover.hover.right="'Clear Search Selection'"></i>
              </div>
            </td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td style="width:14%;text-align:right;">
              <b-button class="btn btn-fill btn-sm btn-success" style="margin-right:2em;" @click="searchAccounts()"><span v-show="loading" :class="isLoading"></span>Search
              </b-button>
            </td>
            <td style="width:17em;">
              <b-form style="" @submit.prevent="searchAccounts()">
                <!-- <label>Search Filter</label> -->
                <!-- <b-form-select type="list" :options="accountClassList.map((a) => {return { text:a.title, value:a.title}})" v-model="searchAccountType"/> -->
                <b-form-input ref="inputField" style="border-radius:.75em;" autofocus autocomplete=false id="searchString" type="search" v-model="searchString" required placeholder="Search criteria" />
              </b-form>
            </td>
            <td></td>
<!--            <td></td>-->
            <td style="text-align:right;" colspan=3>
              <div v-if="canCreateDirectory()">
                <b-button class="btn btn-sm btn-fill btn-info" @click="createDirectoryListing(searchAccountType)">Create {{ searchAccountType}} Directory</b-button>
              </div>
            </td>
          </tr>
          <tr>
            <td></td>
            <td colspan="5" style="">
              <p style="padding:1em 2em 0 0;font-size:.8em;font-style:italic;width:50%;">Enter a search criteria and press SEARCH to search accounts. Commas and quote characters
                are not recommended.</p>
            </td>
          </tr>
          <tr>
            <td colspan="2">
              <b-checkbox style="float:left;" v-model="allAccounts" @input="includeInactive"><span style="font-size:.75em;">Include Inactive</span></b-checkbox>
            </td>
            <td colspan="3">
              <b-checkbox v-if="$root.isAdmin()" style="float:left;" v-model="maxSearchLimit"><span style="font-size:.75em;">Return >50 results</span></b-checkbox>
            </td>
            <td>
              <b-checkbox v-if="$root.isAdmin()" style="float:right;" v-model="showAccountStats"><span style="font-size:.75em;">Show Account Stats</span></b-checkbox>
            </td>
          </tr>
        </table>
      </b-col>

      <b-col v-if="accountClassList && showAccountStats" class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-4 ml-1 mb-5 shadow noprint " order-sm="1" order-md="2" style="border-radius:1em;background:#ff9933;overflow:auto;">
        <!-- <b-checkbox v-if="$root.isAdmin()" style="float:right;" v-model="maxSearchLimit"><span style="font-size:.75em;">Max</span></b-checkbox> -->

        <h5 style="margin:.5em 0 0 0;padding:0;border-bottom:1px gray solid;">Active Accounts <span style="font-size:.8em;font-style:italic;">({{
            _.sumBy(accountClassList,
                    (a) => {return a.count})
          }})</span></h5>
        <div style="padding:1em;">
          <table style="width:100%;font-size:.9em;line-height:16px">
            <template v-for="(ac, index) in accountClassList">
              <tr v-if="accountClassHasOption( ac.accountClassId, 'show-summary')" :key="index" @click="searchAccountType=ac.title" class="show-hand">
                <td style="width:35%;text-align:right;padding-right:1em;">{{ ac.count }} / {{ ac.badges }}</td>
                <td :style="(ac.title===searchAccountType)?'font-weight:1000;':''">{{ ac.title }}</td>
              </tr>
            </template>
          </table>
        </div>
      </b-col>

      <!-- <b-col class="col col-md-4 ml-2 shadow mb-5" style="border-radius:1em;background:#eee;" >
        <h4 class="mt-2 mb-0">Quick List</h4>
        <ul>
          <li>Inactive Accounts</li>
          <li>Off-site Accounts</li>
          <li>On-Hold Accounts</li>
          <li>Birthays This Month</li>
        </ul>
      </b-col> -->

    </b-row>

    <!--------[ MODAL WINDOWS ]------------------------>

    <b-modal ref="addAccount" size="xl" hide-footer hide-header>

      <b-row>
        <b-col>
          <h3>Account Details</h3>
          <b-form>
            <sf-input id="aa-accountType" label="Account Type" type="list" v-model="accountClassId" :options="accountClassOptionsList()" required @change="selectAccountClass(accountClassId)"></sf-input>
            <hr />
            <div v-if="selectedAccountClass && naso">

              <extendable-input v-show="naso.askCompanyCode" id="aa-companyCode" :label="naso.companyCodePrompt" v-model.trim="account.companyCode" @blur="verifyCompanyCode()" @change="verifyCompanyCode()"></extendable-input>

              <div style="display:inline;">
                <i v-if="naso.allowAutoAccount" style="float:right;position:relative;top:8px;" v-b-popover.hover.right="'Auto-Generate Account Number'" class="show-hand fa fa-magic" @click="makeAccountNumber();"></i>
                <sf-input id="aa-accountNumber" :disabled="naso.forceAutoAccount" :label="naso.accountNumberPrompt" type="text" v-model.trim="account.accountNumber" placeholder="Account (not badge)" required @focus="removeCompanyCode(account.accountNumber)" @blur="verifyAccountNumber(account.accountNumber)"></sf-input>
              </div>

              <sf-input v-if="naso.askBadgeNumber" id="aa-badgeNumber" :label="naso.badgeNumberPrompt" type="icon-text" v-model.trim="account.badge[0].badgeNumber" placeholder="Enter or scan badge/fob" @blur="verifyBadge(account.badge[0].badgeNumber)" :appendIcon="askBadgeIcon"></sf-input>
              <sf-input v-if="naso.askFirstName" id="aa-firstName" :label="naso.firstNamePrompt" type="text" v-model.trim="account.badge[0].firstName" @change="verifyBadgeAlias( account.accountTitle || account.badge[0].firstName )"></sf-input>
              <sf-input v-if="naso.askLastName" id="aa-firstName" :label="naso.lastNamePrompt" type="text" v-model.trim="account.badge[0].lastName" @change="verifyAccountTitle(account.badge[0].lastName)"></sf-input>
              <sf-input v-if="naso.askAccountTitle" id="aa-accountTitle" :label="naso.accountTitlePrompt" type="text" v-model.trim="account.accountTitle" placeholder="Typically Last Name"></sf-input>
              <sf-input v-if="naso.askBadgeAlias" id="aa-badgeAlias" :label="naso.badgeAliasPrompt" type="text" v-model.trim="account.badge[0].alias" placeholder="Typically First Name"></sf-input>

              <extendable-input v-show="naso.askGLAccount" id="aa-glAccount" :label="naso.glAccountPrompt" v-model.trim="account.glAccount"></extendable-input>
              <extendable-input v-show="naso.askJobCode" id="aa-jobCode" :label="naso.jobCodePrompt" v-model="account.jobCode"></extendable-input>
              <extendable-input v-show="naso.askArType" id="aa-arType" :label="naso.arTypePrompt" v-model="account.arType"></extendable-input>
              <extendable-input v-show="naso.askPayrollId" id="aa-payrollId" :label="naso.payrollIdPrompt" v-model="account.payrollId"></extendable-input>

              <extendable-input v-show="naso.askTenderAmount" id="aa-initialValue" :label="naso.initialValuePrompt" type="currency" v-model="tenderAmount"></extendable-input>

            </div>
            <div style="color:red">{{ modalMessage }}</div>
          </b-form>
        </b-col>
        <b-col>
          <div v-if="account" style="border-left:5px solid lightgray;padding-left:1em;height:650px;overflow-y:auto;">
            <markdown-card :text="naso.helpText" />
          </div>
        </b-col>
      </b-row>
      <div class="float-right mt-3">
        <b-btn class="btn btn-danger btn-sm btn-fill" variant="outline-danger" @click="hideModal">Cancel</b-btn>
        <b-btn v-if="account && account.accountNumber" class="btn-success btn-sm btn-fill ml-2" variant="success" @click="createAccount">Create Account</b-btn>
      </div>
    </b-modal>

    <b-modal ref="pictureDialog" hide-footer hide-header size="sm">
      <h4>{{ pictureModal.alias }}</h4>
      <img style="border-radius:2em;" width="100%" :src="pictureModal.pictureUrl" />
      <br>
      <br>
      <b-btn class="btn btn-success btn-fill" variant="outline-success" @click="hidePicture">Done</b-btn>
    </b-modal>

    <!------------[ Account List ]-------------------->

    <b-row class="row" v-if="accountList && accountList.length > 0 && !phoneDirectory">
      <b-col class="col col-md-12">
        <template>
          <div class="shadow" style="padding:.5em;border-radius:1em;">
            <div style="width:100%;">
              <b-form-input id="filterString" type="text" class="filter-field" style="float:right;position:relative;top:-4px;" v-model="filterString" placeholder="Filter selections" />
              <i class="fa fa-file-o show-hand" style="float:left;position:relative;top:5px;" v-b-popover.hover.top="'Export this list as CSV'" @click="csvExport()"></i>
              <h4 style="margin-top:0;padding-left:1em;">Search Results <span style="font-size:.7em;color:#444;">({{ accountList.length }} Found)</span>
                <span style="color:red;font-size:.7em;">{{ cached }}</span></h4>
            </div>

            <div v-if="accountList && accountList.length > 0" class="">
              <div class="noprint">
                <div class="noprint" style="float:right;width:200px;position:relative;top:-.5em;">
                  <sf-input class="noprint" label="" v-model="perPage" type="list" cstyle="width:8em;" :options="[5,10,25,50,100,250,500,9999]" @change="savePerPage"></sf-input>
                </div>
                <p style="float:right;font-weight:bold;">Current Page: {{ currentPage }}/{{ Math.floor((accountList.length - 1) / perPage) + 1 }}</p>
                <b-pagination align="center"
                              size="sm"
                              class="mt-0 mb-0"
                              first-text="First"
                              prev-text="Prev"
                              next-text="Next"
                              last-text="Last"
                              v-model="currentPage"
                              :total-rows="accountList.length"
                              :per-page="perPage"
                              aria-controls="my-table">
                </b-pagination>
              </div>
              <div style="width:100%;overflow:auto;">
                <b-table sticky-header striped hover head-variant="dark" stacked="md" small
                         id="account-list-table"
                         :tbody-transition-props="transProps"
                         primary-key="indexId"
                         :per-page="perPage"
                         :current-page="currentPage"
                         :rows="accountList.length"
                         @row-clicked="rowClicked($event)"
                         :filter="filterString"
                         sort-by="totalNumber"
                         :items="accountList"
                         :fields="accountColumns">

                  <template v-slot:cell(active)="row">
                    <span v-if="accountList[0].status" class="show-hand">
                      <i v-if="$root.isInRole('accounts')" style="color:gray;" class="fa fa-pencil-square show-hand" v-b-popover.hover="'Edit Account'" @click="editAccount(row.item.accountNumber)"></i>
                      <i v-if="$root.isInRole('account-delete')" style="color:gray;margin-left:.5em;margin-right:.5em;" class="fa fa-trash show-hand" v-b-popover.hover="'Delete Account'" @click.stop="removeAccount(row.item )"></i>
                      <i v-if="$root.isInRole('account-fix') && row.item.accountNumber != row.item.accountNumber.toUpperCase()" style="color:gray;margin-left:.5em;margin-right:.5em;" class="fa fa-wrench show-hand" v-b-popover.hover="'FIX Account casing'" @click.stop="accountFix(row.item )"></i>
                    </span>
                    <span v-if="accountList[0].status">
                      <i v-if="!row.item.active" class='fa fa-user-o' v-b-popover.hover.top="'Inactive'" style="color:darkorange;"></i>
                      <i v-if="row.item.active && !row.item.status.isOffSite && row.item.primaryBadge" class='fa fa-user' v-b-popover.hover.top="'Active, Primary'" style="color:green;"></i>
                      <i v-if="row.item.active && !row.item.status.isOffSite && !row.item.primaryBadge" class='fa fa-user' v-b-popover.hover.top="'Active, Secondary'" style="color:yellowgreen;"></i>
                      <i v-if="row.item.active && row.item.status.isOffSite" class='fa fa-user-secret' v-b-popover.hover.top="'Offsite'" style="color:orangered;"></i>

                      <i v-if="row.item.shared" class='fa fa-share-alt' v-b-popover.hover.top="'Shared Account'" style="color:yellowgreen;"></i>
                      <i v-if="row.item.active && row.item.status.isOnHold" class='fa fa-clock-o' v-b-popover.hover.top="'On Hold'" style="color:red;"></i>
                      <i v-if="row.item.active && row.item.status.isVIP" class='fa fa-star' v-b-popover.hover.top="'VIP'" style="color:indigo;"></i>
                      <i v-if="row.item.active && row.item.status.isParent" class='fa fa-user-plus' v-b-popover.hover.top="'Is Parent'" style="color:coral;"></i>
                      <i v-if="row.item.active && row.item.status.isMinor" class='fa fa-id-card' v-b-popover.hover.top="'Is Minor'" style="color:FireBrick;"></i>
                    </span>
                  </template>

                  <template v-slot:cell(alias)="row">
                    <span style="font-weight:bold;">{{ row.item.alias }} </span>
                    <!--<div>{{row.item.accountTitle}}</div>-->
                  </template>

                  <template v-slot:cell(pictureUrl)="row">
                    <div v-if="row.item.pictureUrl" style="height:48px;width:48px;text-align:center; border-radius:50%;overflow:hidden;position:relative;">
                      <img :src="row.item.pictureUrl" @click.stop="showPicture(row)" style="width:100%;" />
                    </div>
                  </template>

                  <template v-slot:cell(accountNumber)="row">
                    {{ row.item.accountNumber }}
                    <!-- <div v-if="row.item.roomNumber">
                      Room: {{row.item.roomNumber}}
                    </div> -->
                  </template>

                  <!--                  <template v-slot:cell(accountType)="row">-->
                  <!--                    {{ accountTypeTitle( row )}}-->
                  <!--                  </template>-->

                  <template v-slot:cell(lastSeen.when)="row">
                    <div v-if="row.item.lastSeen['when']" v-b-popover.hover.left="emoment(row.item.lastSeen['when']).format('LLL')">
                      {{ emoment(row.item.lastSeen[ 'when' ]).fromNow() }}
                    </div>
                  </template>

                  <template v-slot:cell(updatedAt)="row">
                    {{ emoment(row.item.updatedAt).fromNow() }}   <!-- {{tzDateShort(row.item.updatedAt)}} -->
                  </template>

                </b-table>
              </div>
            </div>
          </div>
        </template>
        <!-- <template>
          {{ accountList }}
        </template> -->
      </b-col>

    </b-row>
    <div class="table-responsive" v-else>
      <div v-if="!phoneDirectory">
        <hr />
        No Entries
      </div>
    </div>
  </div>
  <!--  </div>-->
</template>
<script>

import iLib               from "@/Lib/ioihanLib";
import JsPDF              from "jspdf";
import moment             from "moment";
import momentTZ           from "moment-timezone";
import AccountService     from "/src/Services/AccountService";
import TenantService      from "/src/Services/TenantService";
import TotalService       from "/src/Services/TotalService";
import TransactionService from "/src/Services/TransactionService";
import SfInput            from "/src/components/UIComponents/Inputs/SFormInput.vue";
import ExtendableInput    from "/src/components/UIComponents/Inputs/ExtendableInput.vue";
import LTable             from "/src/components/UIComponents/Table.vue";
import Card               from "/src/components/UIComponents/Cards/Card.vue";
import MarkdownCard       from "/src/components/UIComponents/Cards/MarkdownCard.vue";
import rootLib            from "../../Lib/rootLib"
import _                  from "lodash"
import exportLib          from "/src/Lib/dataExportLib.js";
import Currency           from "currency.js";

//{
//  "status": {
//    "isOnHold": false,
//    "isVIP": false,
//    "isOffSite": false,
//    "isGuest": false,
//    "isMinor": false,
//    "isParent": false
//  },
//  "lastSeen": {
//    "where": "Giftcard",
//    "when": "2019-09-20T03:21:37.750Z",
//    "who": "Employee: undefined"
//  },
//  "active": true,
//  "badgeNumber": "g4",
//  "alias": "Giftcard",
//  "accountNumber": "GC-g4",
//  "accountTitle": "Giftcard",
//  "accountType": "giftcard",
//  "shared": false,
//  "updatedAt": "2019-09-20T03:21:37.750Z"
//}

var t = this;

export default {
  components: {
    LTable,
    Card,
    MarkdownCard,
    SfInput,
    ExtendableInput
  },
  data() {
    return {
      transProps             : {
        name: 'flip-list'
      },
      allAccounts            : false,
      cached                 : "",
      perPage                : 10,
      currentPage            : 1,
      maxSearchLimit         : false,
      showAccountStats       : false,
      loading                : false,
      isLoading              : "fa fa-spinner  fa-refresh-animate",
      message                : "Please enter your credentials",
      iconClicked            : false,
      pictureModal           : {alias: "", pictureUrl: ""},
      searchAccountType      : "",
      searchString           : "",
      enableFilter           : false,
      filterString           : "",
      modalMessage           : "",
      directoryIncludeAltPhone: false,
      directoryIncludeAddress: false,
      directoryIncludeAccountNumber: false,
      phoneDirectory         : null,
      phoneDirectoryAccounts : true,
      phoneDirectoryBirthdays: false,
      letters                : "",
      selectedAccountClass   : null,
      isValidAccount         : null,
      askBadgeIcon           : 'fa fa-circle',
      askAccountIcon         : 'fa fa-circle',
      naso                   : null,                                  // New Account Setup Options
      newAccountTemplate     : {
        accountClassId: "",
        companyCode   : "",
        accountNumber : "",
        accountTitle  : "",
        glAccount     : "",
        jobCode       : "",
        arType        : "",
        payrollId     : "",
        badge         : [ {
          badgeNumber: "",
          firstName  : "",
          lastName   : "",
          alias      : ""
        } ]
      },
      account                : null,
      accountClassId         : "",
      accountType            : "",
      accountTitle           : "",
      accountNumber          : "",
      badgeNumber            : "",
      badgeAlias             : "",
      glAccount              : "",
      // accountTypeCounts: null,
      totalClasses       : [],  // all of them
      transaction        : null,
      tenderKey          : null,
      tenderAmount       : "0",
      allowedTotalClasses: [],  // the ones that this profile says we can choose from
      accountList        : [],
      accountClasses     : [],
      accountClassList   : [],
      defaultListFields  : [
        {key: "active", label: "Status", dataType: "boolean", default: true},
        {key: "pictureUrl", label: "", dataType: "string", default: true},
        {key: "accountTitle", sortable: true, label: "Title", dataType: "string"},
        {key: "roomNumber", sortable: true, label: "Room", dataType: "string", default: true},
        {key: "badgeNumber", sortable: true, label: "Badge", dataType: "string", default: true},
        {key: "accountNumber", sortable: true, label: "Account", dataType: "string", default: true},
        {key: "alias", sortable: true, label: "Alias", dataType: "string", default: true},
        {key: "lastName", sortable: true, label: "Last Name", dataType: "string", default: true},
        {key: "firstName", sortable: true, label: "First Name", dataType: "string", default: true},
        {key: "accountClassId", sortable: true, label: "Profile", dataType: "string", default: true, formatter: (v) => {return this.accountTypeTitle(v)}},
        {key: "lastSeen.when", sortable: true, label: "Last Seen", dataType: "date", default: true, "exportColumnHeader": "lastSeen"},
        {key: "updatedAt", sortable: true, label: "Last Updated", dataType: "date", default: true}
      ],

      accountColumns: [],
    };
  },
  computed: {},
  async created() {

    // this.getAccountTypeCounts();
    await this.getAccountClasses();
    await this.getTotalClasses();

    if(!this.$root.tenant.searchAccountType) {
      this.$root.tenant.searchAccountType = "";
    }

    if(localStorage.getItem("perPage")) {
      this.perPage = localStorage.getItem('perPage');
    }

    this.allAccounts = JSON.parse(localStorage.getItem("allAccounts"));

    if(this.$root.tenant.accountList) {
      if(this.$root.tenant.accountList.length > 0) {
        this.cached            = "(CACHED)";
        this.accountList       = this.$root.tenant.accountList;
        this.searchAccountType = this.$root.tenant.searchAccountType || ""; // || "resident";
        this.setAccountColumns();
        return;
      }
    }

    if(this.$root.tenant.searchString) {
      this.searchAccountType = this.$root.tenant.searchAccountType || ""; // || "resident";
      this.searchString      = this.$root.tenant.searchString;
      this.getAccounts();
    }

  },
  methods: {
    setFocus() {
      this.$refs.inputField.focus();
    },
    savePerPage() {
      localStorage.setItem("perPage", this.perPage);
    },
    hideModal() {
      this.$refs.addAccount.hide()
    },
    scrubAddressEntry(v) {
      // VANCO AUTO PAYMENT
      v = v.replace("- VANCO", "");   // todo: make this something we can configure somewhere.
      v = v.replace("-VANCO", "");   // todo: make this something we can configure somewhere.
      v = v.replace(" VANCO", "");   // todo: make this something we can configure somewhere.
      v = v.replace("VANCO", "");   // todo: make this something we can configure somewhere.
      v = v.replace(" AUTOMATIC", "");
      v = v.replace(" AUTO", "");
      v = v.replace(" PAYMENT", "");
      v = v.replace(" PAY", "");
      return v;
    },
    isThisMonth(d,offset = 0) {
      if(!d) {
        return false;
      }
      return momentTZ.tz(d,this.$root.user.TZ).month() === momentTZ.tz(this.$root.user.TZ).add(offset,'month').month();
    },
    canCreateDirectory() {
      if(this.searchAccountType) {
        let ac = _.find(this.accountClasses, {title: this.searchAccountType});
        if(!ac) {
          return false;
        }
        return ac.options.indexOf("create-directory") >= 0;
      }
      return false;
    },
    async createDirectoryListing(acTitle) {
      if(!await this.$root.confirmYesNo("Create A Directory Listing?")) {
        return;
      }
      let ac = _.find(this.accountClasses, {title: acTitle});
      if(!ac) {
        return await this.$root.alert("Unable to find the account class", "danger");
      }
      this.loading = true;
      let response = await AccountService.getAccountDirectoryInformation(ac.accountClassId);
      if(response.data) {
        this.phoneDirectory = response.data;
// console.log( "phoneDirectory", this.phoneDirectory );
        const uniqueLetters = new Set(this.phoneDirectory.map(entry => (entry.lastName) ? entry.lastName.charAt(0).toLowerCase() : ''));
        this.letters = [ ...uniqueLetters ].sort();
        this.loading = false;
      } else {
        this.loading = false;
        this.$root.alert("Unable to create the directory", "danger");
      }
    },
    printPhoneDirectory() {
      window.print();
    },
    async makeDirectoryPdf() {
      if(!await this.$root.confirmYesNo("Output PDF File?")) {
        return;
      }

      let self = this;
      let pdf = new JsPDF('portrait', 'px');

      let table  = document.querySelector('#theDirectory');
      let width  = table.getBoundingClientRect().width;
      let height = table.getBoundingClientRect().height + 30;

      // pdf.internal.pageSize.width  = (width + 200);
      pdf.internal.pageSize.width  = width + 4;
      pdf.internal.pageSize.height = height + 4;

      pdf.html(table, {
        callback: function(pdf) {
          let title = "DirectoryListing-" + moment().format("YYYY-DD-MMMM").toString();
          let filename = iLib.makeSafeFilename( title ) + ".pdf";
          // saveAs(pdf, filename);
          pdf.save(filename);
        },
        margin  : [ 2, 2, 2, 2 ]
        // x: 32,
        // y: 32,
      });

    },
    async verifyCompanyCode() {
      if(this.account.accountNumber.length > 0) {
        await this.verifyAccountNumber(this.account.accountNumber);
      }
    },
    async removeCompanyCode(a) {
      if(this.naso.accountNumberIncludeCompanyCode) {
        if(!this.account.companyCode) {
          return;
        }
        let t = [];
        if(this.naso.companyCodeSeparator) {
          t = this.account.accountNumber.split(this.naso.companyCodeSeparator);
        } else {
          t.push(this.account.accountNumber.substring(0, this.account.companyCode.length));
          t.push(this.account.accountNumber.substring(this.account.companyCode.length + 1));
        }
        a                          = t[ 1 ] || t[ 0 ];
        this.account.accountNumber = a;
      }
    },
    async verifyAccountNumber(a) {
      if(this.naso.accountNumberIncludeCompanyCode) {
        if(!this.account.companyCode) {
          return await this.$root.alert("Please enter a company code", "warning");
        }
        let t = [];
        if(this.naso.companyCodeSeparator) {
          t = this.account.accountNumber.split(this.naso.companyCodeSeparator);
        } else {
          t.push(this.account.accountNumber.substring(0, this.account.companyCode.length));
          t.push(this.account.accountNumber.substring(this.account.companyCode.length + 1));
        }
        a                          = t[ 1 ] || t[ 0 ];
        this.account.accountNumber = (this.account.companyCode + this.naso.companyCodeSeparator + a);
      }
      this.account.accountNumber = this.account.accountNumber.toUpperCase();
    },
    verifyAccountTitle(n) {
      if(!this.account.accountTitle) {
        this.account.accountTitle = n;
      }
    },
    async verifyBadge(b) {
      let result = await this.isBadgeExist(b);
      if(result) {
        this.askBadgeIcon = 'fa fa-exclamation-triangle text-warning ';
      } else {
        this.askBadgeIcon = 'fa fa-check text-success';
      }
    },
    verifyBadgeAlias(n) {
      if(!this.naso.askBadgeAlias) {
        this.account.badge[ 0 ].alias = this.account.accountTitle;
        return;
      }

      if(!this.account.badge[ 0 ].alias) {
        this.account.badge[ 0 ].alias = n;
      }
    },
    async selectAccountClass(id) {

      try {
        if(!this.selectedAccountClass || (this.selectedAccountClass && this.selectedAccountClass.id != id)) {
          let response              = await AccountService.getAccountClassWithSetupInfo(id);
          this.selectedAccountClass = response.data;
        }
      } catch(error) {
        console.log(error.message);
        await this.$root.alert("Unable to get the account setup template from the server, please try again", "danger");
        return;
      }

      this.naso = this.selectedAccountClass.setupOptions;

      this.makeNewAccount();

    },
    makeNewAccount() {    // this gets called when we make a new "account type" selection.  If we have an account object, then we're not changing types.

      if(!this.account) {
        this.account            = Object.assign({}, this.newAccountTemplate, this.naso.accountTemplate);  // get the account class presets.
        this.account.badge[ 0 ] = Object.assign(this.account.badge[ 0 ], this.naso.badgeTemplate);

        if(!this.naso.askTotalClass && !this.account.totalClass) {    // if we're not asking, and we didn't get one in the template
          this.account.totalClass = [];
          this.naso.allowedTotalClasses.forEach((t) => {
            try {
              this.account.totalClass.push(_.find(this.totalClasses, {totalClassId: t})._id);
            } catch(error) {
              console.log("Adding Total Classes:", error.message);
            }
          });
        }
      }

      this.account.accountType    = this.selectedAccountClass.accountType;
      this.account.accountClassId = this.selectedAccountClass.accountClassId;   // this is from the first field we select; if this changes, we toss the entire account and start over

      if(this.naso && this.naso.forceAutoAccount) {
        this.makeAccountNumber();
      } else {
        this.account.accountNumber = "";
      }
    },
    askBadgeDetails() {
      if(this.selectedAccountClass) {
        let s = this.selectedAccountClass;    // add additional badge criteria as it becomes available
        if(s.askBadgeNumber) {
          return true;
        }
      }
      return false;
    },
    askPostingDetails() {
      if(this.selectedAccountClass) {
        let s = this.selectedAccountClass;
        //uePrompt": "Initial Value", "autoPostValue": false, "askPayrollId": true, "payrollIdPrompt": "Payroll ID", "askTotalClass": true, "totalClassPrompt": "Total Profile", "presetTotalClass": []
        if(s.askPosting) {
          return true;
        }
      }
      return false;
    },
    showPicture(row) {
      this.pictureModal.alias      = row.item.alias;
      this.pictureModal.pictureUrl = row.item.pictureUrl;
      this.$refs.pictureDialog.show()
    },
    hidePicture() {
      this.$refs.pictureDialog.hide()
    },
    makeIdNumber() {
      return moment().format("YYMMDDHHmmssSS").toString();
    },
    newAccountInfo() {

      this.modalMessage = "";

      if(this.account) {
        this.account.accountNumber = "";
        this.account.accountTitle  = "";

        this.account.badge[ 0 ].badgeNumber = "";
        this.account.badge[ 0 ].alias       = "";
        this.account.badge[ 0 ].firstName   = "";
        this.account.badge[ 0 ].lastName    = "";

        this.makeNewAccount();
      }

      this.askBadgeIcon = 'fa fa-circle'; // start with open
      this.$refs.addAccount.show()
    },
    makeAccountNumber() {
      if(this.naso) {
        if(this.naso.accountNumberIncludeCompanyCode) {
          if(!this.account.companyCode) {
            return this.$root.errorMessage("Company Code Required", "warning");
          }
          let a                      = this.account.companyCode.trim() + this.naso.companyCodeSeparator.trim() + this.makeIdNumber();
          this.account.accountNumber = a.trim();
          return;
        } else {
          let a                      = this.naso.companyCodeSeparator.trim() + this.makeIdNumber();
          this.account.accountNumber = a;
          return;
        }
      }
      this.account.accountNumber = "A-" + this.makeIdNumber();
    },
    accountBadgeTitle(accountClassId) { // the "badge" type name is programmable, this gets what the user calls this
      let ac = _.find(this.accountClasses, {accountClassId: accountClassId});
      if(ac) {
        return ac.badgeTitle;
      }
      return "Badge";
    },
    doNothing() {},
    csvExport() {
      exportLib.csvExportAskFilename(this.accountList, this.accountColumns);
    },
    onReset(e) {
      e.preventDefault();
    },
    emoment(v) {
      return momentTZ(v);
    },
    accountClassHasOption(id, op) {
      let ac = _.find(this.accountClasses, {accountClassId: id});
      if(ac) {
        return ac.options.indexOf(op) >= 0;
      }
      return false;
    },
    tzDate(d) {
      return momentTZ.tz(d, this.$root.TZ).format('DD-MMM-YY hh:mm a');
    },
    tzDateShort(d) {
      return momentTZ.tz(d, this.$root.TZ).format('dd  DD-MMM hh:mm a');
    },
    includeInactive() {
      localStorage.setItem("allAccounts", JSON.stringify(this.allAccounts));
    },
    editAccount(accountNumber) {
      // if(this.$root.isAdmin()) {
        let route = `/app/accountEdit/${encodeURIComponent(accountNumber)}`;
        this.$router.push(route);
      // }
    },
    async rowClicked(item) {
      if(!item.badgeNumber) {
        let message = "";
        if(this.$root.isAdmin()) {
          message = "No Badge Number assigned. Edit this account using the 'edit' icon";
        } else {
          message = "No Badge Number assigned. Please contact your administrator";
        }
        await this.$root.alert(message);
        return;
      }

      // console.log( "row clicked (item,classes)" , item , this.accountClasses );
      let url = `/app/account/${encodeURIComponent(item.badgeNumber)}/${encodeURIComponent(item.accountNumber)}`;   // this may get replaced below
      let ac  = _.find(this.accountClasses, {accountClassId: item.accountClassId});

      if(ac) {
        if(ac.options.indexOf('disable-show-transactions') >= 0) {    // disabling show transactions is the transaciton list before the edit screen
          url = `/app/accountEdit/${encodeURIComponent(item.accountNumber)}`;
        }
      }
      this.$router.push(url);
    },
    accountTypeTitle(id) {
      let a;
      // console.log("looiing for" , id , this.accountClassList );
      if(this.accountClassList) {
        a = _.find(this.accountClassList, {accountClassId: id});
      }
      if(a) {
        return id.toString() + " : " + a.title
      } else {
        return id.toString() + " : **"; // + ":" + row.item.accountType;
      }
    },
    async isBadgeExist(badgeNumber) {
      try {
        let response = await AccountService.isBadgeExist(badgeNumber);
        if(response.data.exists) {
          return response.data.exists;
        }
      } catch(e) {
        console.log("error", e);
      }
      return false;
    },
    async isAccountExist(accountNumber) {
      try {
        let response = await AccountService.isAccountExist(accountNumber);
        if(response.data.exists) {
          return response.data.exists;
        }
      } catch(e) {
        console.log("error", e);
      }
      return false;
    },
    async createAccount() {
      let response;
      let t = this;

      if(t.account.accountNumber === "") {
        return await t.$root.alert("Account Number Required", "warning")
      }

      t.account.accountNumber = t.account.accountNumber.toUpperCase();    // 14-Dec-23 wjs :: force uppercase

      if(await t.isAccountExist(t.account.accountNumber)) {
        return await t.$root.alert("Account Number Already Exists", "warning")
      }

      try {

        if(!t.naso.askAccountTitle) {
          t.account.accountTitle = t.account.badge[ 0 ].firstName + " " + t.account.badge[ 0 ].lastName;
        }

        if(!t.naso.askBadgeNumber) {
          t.account.badge[ 0 ].badgeNumber = t.account.accountNumber;
          t.account.badge[ 0 ].alias       = t.account.accountTitle;
        }

        if(t.account.badge[ 0 ].badgeNumber) {
          response = await AccountService.isBadgeExist(t.account.badge[ 0 ].badgeNumber);
          if(response.data.exists) {
            if(!await t.$root.confirmYesNo(`This ${this.naso.badgeNumberPrompt} Already Exists on Account<br>${response.data.accountNumber} ${response.data.accountTitle}<br>Continue to add?`)) {
              return;
            }
          }
        }
      } catch(error) {
        return await t.$root.alert(error.message, "danger")
      }

      if(!await t.$root.confirmYesNo(`Account:${t.account.accountNumber}<br>Badge: ${t.account.badge[ 0 ].badgeNumber}<br>Continue to add?`)) {
        return;
      }

      t.$root.tenant.searchString = t.searchString = t.account.accountNumber;    // save this so the user can use it.

      try {
        response = await AccountService.addAccount(t.account.accountNumber, t.account);

        if(t.naso.askPosting) {     // if we're asking for posting, then let's create the posting transaction (which also creates the total record)
          let transaction = {
            account      : t.account.accountNumber,
            badgeNumber  : t.account.badge[ 0 ].badgeNumber,
            tenderNumber : t.naso.postTenderNumber,            // todo: get this info from the tenant config
            revenueCenter: t.naso.postRevenueCenter,
            isPayment    : t.naso.postIsPayment,
            tenderAmount : t.tenderAmount,
            reference    : t.account.accountTitle,
            totalClassId : t.naso.postTotalClassId,
            receipt      : [ `Initial Balance: ${Currency(t.tenderAmount).format()}` ]
          }

          let result = await TransactionService.transactionPost(transaction);
        }

        t.hideModal();

        if(this.naso.isEditAfterAdd) {
          t.accountClassList = t.$root.tenant.accountClassList = null; // force fetch of a new list
          t.$router.push(`/app/accountEdit/${encodeURIComponent(t.account.accountNumber)}`);
        } else {
          this.getAccountClasses();     // update this list since we may have just added a new type.
          this.makeNewAccount();        // ready for the  next in case we don't change account classes
          //todo: push on a "local friendly" version of this new account
        }

      } catch(err) {
        // do something in case of an error
        console.log(err.message);
        if(err.response && err.response.data && err.response.data.message !== undefined) {
          t.$root.errorMessage("oops", err.response.data.message);
          t.modalMessage = err.response.data.message;
        } else {
          t.modalMessage = "Error Adding Record, please verify your entries.";
          t.$root.errorMessage("Oops", "Unable to add account.");
        }
      }

    },
    listAllAccounts(title) {
      this.searchAccountType = title;
      this.searchString      = "";
      this.searchAccounts();
    },
    searchAccounts() {
      let t = this;

      t.$root.tenant.searchAccountType = t.searchAccountType;
      t.$root.tenant.accountList       = [];

      t.getAccounts();
      this.setFocus();
    },
    accountClassOptionsList() {
      return this.accountClasses.filter((c) => {return c.options.indexOf('manual-add') >= 0}).map(a => { return {text: a.title, value: a.accountClassId}});
    },
    async accountFix(item) {
      if(!await this.$root.confirmYesNo(`FIX Account: [${item.accountNumber}] <br>Alias: [${item.alias}]<br>This patches the Account,Transactions, and Totals ensure consistent values`)) {
        return;
      }
      try {
        // the Account ID is a special field because the list we're working from is a "BADGE" listing and the account id is stuffed in
        let response = await AccountService.accountFix(item.accountId);
        let a        = response.data.account;

        item.accountNumber = a.accountNumber;         // this is what we're currently fixing.

        this.$root.successMessage("Account Fixed", `Account: ${item.accountNumber} ${item.alias} has been fixed.`);

      } catch(error) {
        this.$root.errorMessage("Oops", error.message);
      }
    },
    async removeAccount(item) {
      if(!await this.$root.confirmYesNo(`Remove Account: [${item.accountNumber}] <br>Alias: [${item.alias}]`)) {
        return;
      }

      let accountNumber = item.accountNumber;
      try {

        await AccountService.removeAccount(accountNumber);
        let i = 0;

        // debugger;
        while(i >= 0){
          i = _.findIndex(this.accountList, {accountNumber: accountNumber});
          if(i >= 0) {
            this.accountList.splice(i, 1);
          }
        }
      } catch(error) {
        this.$root.errorMessage("Oops", error.message);
      }

    },
    async getAccountClassId(title) {
      if(!this.accountClassList) {
        await this.getAccountClasses();
      }
      return _.find(this.accountClassList, {title: title}).accountClassId || 0;
    },
    async getTotalClasses() {
      let t = this;

      if(this.totalClasses && this.totalClasses.length > 0) {
        return;
      }

      if(!t.$root.tenant.totalClasses) {
        let response                = await TotalService.getTotals();
        t.$root.tenant.totalClasses = response.data;
      }
      this.totalClasses = this.$root.tenant.totalClasses
    },
    async getAccountClasses() {
      var x = 1;
      if(!this.accountClasses || this.accountClasses.length === 0) {
        try {
          let [ response1, response2 ]       = await Promise.all([
                                                                   TenantService.getAccountClassesWithCounts(),
                                                                   AccountService.getAccountClasses()
                                                                 ])
          this.accountClassList              = response1.data;
          this.accountClasses                = response2.data;
          this.$root.tenant.accountClassList = this.accountClassList;
          this.$root.tenant.accountClasses   = this.accountClasses;
        } catch(error) {
          console.log(error);
        }
      }
    },
    setAccountColumns() {
      this.accountColumns = [];

      // console.log( "searchAccountType" , this.searchAccountType , this.accountClasses);

      if(this.searchAccountType && this.accountClasses.length > 0) {
        let ac = _.find(this.accountClasses, {title: this.searchAccountType});

        if(ac && ac.searchListFields) {
          ac.searchListFields.forEach(f => {
            let t = _.find(this.defaultListFields, {key: f});
            if(t) {
              this.accountColumns.push(t);
            }
          });
        }

      }

      if(this.accountColumns.length === 0) {
        this.accountColumns = this.defaultListFields.filter((f) => {return f.default === true});
      }
    },
    async getAccounts() {
      var response;
      var t = this;

      if(this.searchString.indexOf(",") >= 0 || this.searchString.indexOf("'") >= 0 || this.searchString.indexOf('"') >= 0) {
        await this.$root.alert("Search prefers simple terms - comma and quotes not allowed", 'warning');
        return;
      }

      this.$root.tenant.searchString = this.searchString;
      this.searchAccountType         = this.$root.tenant.searchAccountType;

      let search = this.searchString;

      if(!search) {
        if(!this.searchAccountType) {
          await this.$root.alert("Search cannot be blank when no profile/filter selected");
          return;
        } else {
          search = ".*";
        }

      }

      t.loading = true;

      if(search.toLowerCase() === 'all') {
        search = ".*";
      }

      let accountClassId = "";

      if(t.searchAccountType && this.accountClasses.length > 0) {
        let ac = _.find(this.accountClasses, {title: t.searchAccountType});
        if(ac) {
          accountClassId = ac.accountClassId;
        }
      }

      this.setAccountColumns();
      // console.log( "accountColumns" , this.accountColumns );

      try {

        let searchLimit = 50;
        if(this.maxSearchLimit === true) {
          searchLimit = 10000;
        }

        // if( accountClassId ) {
        response = await AccountService.searchBadgeWithAccountClassId(search, accountClassId, searchLimit, this.allAccounts);
        // } else {
        //   response = await AccountService.searchBadgeWithAccountClassId(search, ''            ,searchLimit, this.allAccounts );
        // }

        this.cached      = "";
        this.accountList = response.data;

        // console.log( this.accountList );

        this.accountList.forEach((a, index) => {a[ "indexId" ] = index;})
        this.$root.tenant.accountList = this.accountList;
        t.loading                     = false;

        // console.log( "Search Accounts" , this.accountList );

      } catch(err) {
        if(err) {
          console.error(err);
          t.$root.errorMessage("Oops", err.response.data.message);
        }
        t.loading = false;
      }
    }
  }
};

</script>
<style lang="scss">



table.b-table[aria-busy='true'] {
  opacity: 0.6;
}

.badge-entry {
  margin-left: 6px;
}

.filter-field {
  padding: 0 1.25em 0 .75em;
  height: 2.5em;
  border: solid 2px #2f2f2f;
  border-radius: 2em;
  background: #f0f0f0;
  width: 200px;
  float: right;
}

.filter-field:focus {
  outline: 0;
  border-color: #007593;

}

table#account-list-table .flip-list-move {
  transition: transform 1s;
}


.directory-control {
  display:inline-block;
  margin:auto;
  padding-bottom:2em;
}

.directory-control-options {
  display:flex;
  border-bottom:1px solid black;
  margin-bottom:1em;
}

.phone-directory-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 30000;
  background: #eee;
}

.phone-directory {
  width: 100%;
  margin: 0 auto;
}

.directory-grid-title {
  width:100%;
  background:#555;
  border-radius:.5em;
  color:white;
  font-weight:900;
  font-size:1.5em;
  text-align:center;
  margin:.5em 0 .5em 0;
}

.directory-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

.directory-entry {
  border-radius: .5em;
  border: 1px solid #eee;
  width: 100%;
  padding:10px;
  line-height:1.2em;
  box-sizing: border-box;
}

.directory-entry div {
  //margin: 0 0 0 10px;
  //line-height: 1.5;
}

.directory-entry strong {
  font-size: 1.1em;
  font-weight: bold;
}



@media print {

  // there is a large left-margin on the print for some reason - new clue why.
  // I have tried everything I can think of to get rid of it.

  @page {
    //size: letter landscape;
    //size: auto;
    margin: 0 !important;
    padding: 0 !important;
  }


  @page :left {
    margin: 0;
    padding: 0;
  }

  @page :right {
    margin: 0;
    padding: 0;
  }

  h1 h2 h3 h4 h5 h6 {
    margin: 0;
    padding: 0;
  }

  html, body {
    //width: 8.5in;
    //height: 11in;
    //width: 210mm !important;
    //height: 297mm !important;
    height: 100% !important;
    width: 100% !important;
    //width:3440px !important;
  }

  body {
    -webkit-print-color-adjust: exact !important;
    height: 100% !important;
    width: 100% !important;
    margin: 0;
    color: #000;
    background-color: #fff;
  }

  .content {
    //position:absolute;
    text-align: left;
    margin: 0 !important;
    padding: 0 !important;
    width: 100% !important;
    height: 100% !important;
    //font-size:12pt !important;
    font-size: 1em;
    //overflow: visible;
    overflow: auto;
  }

  table {
    //page-break-inside: auto;
    //font-size:1vw;
    width: 100% !important;
    height: 100% !important;
    overflow: visible;
  }

  table tr {
    page-break-inside: avoid;
    //page-break-after: auto;
    overflow: visible !important;
    //line-height: .5em;
    margin: 0;
    padding: 0;
    //background:lime !important;
  }

  .overview-page {
    height: 100% !important;
    width: 100% !important;
    overflow: visible;
  }

  .noprint {
    display: none !important;
  }

  .directory-entry {
    page-break-inside: avoid;
  }

}


</style>
