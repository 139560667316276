<template>
  <div class="content">
    <div class="col-12" style="min-height:700px;padding:1em;border-radius:1em;">

      <div v-show="!device" style="margin-bottom:10px;">
        <i class="fa fa-plus-square show-hand " style="position:absolute;right:1em;" v-b-popover.hover.top='"Add New Schedule Config"' @click="addDevice()"></i>
        <b-button v-if="!device" class="btn btn-success btn-fill btn-sm float-left mr-2" style="border-radius:.75em;float:left;" @click="$router.go(-1)">Done</b-button>
        <h4 slot="header" class="card-title">Schedule Configurations</h4>
      </div>

      <div v-if="!device" style="width:100%;overflow:auto;">
        <b-table striped hover head-variant="dark" class="show-hand" stacked="md" sort-by="deviceType" :items="devices" :fields="deviceColumns" @row-clicked="editDevice($event)">
          <template v-slot:cell(actions)="row">
            <i class="fa fa-trash show-hand float-left" @click.stop="removeDevice(row)"></i>
          </template>
        </b-table>
      </div>

      <div v-if="device">
        <i class="fa fa-clone" @click="cloneDevice" style="float:right;" v-b-popover.hover.top="'Clone this Schedule'"></i>
        <b-button v-if="isChanged" class="btn btn-sm btn-fill btn-warning" style="border-radius:.75em;float:left;margin-right:1em;" @click="saveDevice( device )">Save</b-button>
        <b-button v-if="device" class="btn btn-success btn-fill btn-sm float-left mr-2" style="border-radius:.75em;float:left;" @click="cancelEdit()">Cancel</b-button>
        <h4 style="margin-top:0;">{{device.title}}</h4>
        <hr />

        <b-tabs style="margin-top:2em;" class="tab-hover" active-nav-item-class="selected-tab">
          <b-tab title="General">
            <b-form style="margin-top:1em;" @input="hasChanged()">
              <b-row class="row">
                <b-col class="col-xs-12 col-sm-12 col-lg-12 col-xl-6">
                  <b-checkbox style="padding-left:9em;" v-model="device.active">Active?</b-checkbox>

                  <sf-input :labelCols=3 label="Title" type="text" v-model="device.title" />
                  <sf-input :labelCols=3 label="Description" type="textarea" :rows=5 v-model="device.description" />
                  <sf-input :labelCols=3 label="Closed Prompt" type="text" v-model="device.options.defaultPrompt" />
                </b-col>
                <b-col class="col-xs-12 col-sm-12 col-lg-12 col-xl-6">
                  <sf-input :labelCols=3 label="device Type" type="list" v-model="device.deviceType" :options="['kiosk']" />
                  <sf-input :labelCols=3 label="Timezone" type="list" v-model="device.TZ" :options="activeTimezones" />
                  <sf-input :labelCols=3 label="Kiosk Account" type="text" v-model="device.email" />
                  <sf-input :labelCols=3 label="Update Interval" helpText="Time in Minutes" cstyle="width:5em;" type="number" v-model="device.updateMinutes" />
                  <b-button class="btn btn-sm btn-fill" style="position:relative;float:right;" @click="hasChanged();device.restartTime=moment().add(2,'minutes').format('HH:mm')">Set Restart</b-button>
                  <sf-input :labelCols=3 label="Restart Time" helpText="When we restart the kiosk" cstyle="width:5em;" type="text" v-model="device.restartTime" />

                </b-col>

              </b-row>
            </b-form>
          </b-tab>

          <b-tab title="Schedules">
            <b-form v-if="device" @input="hasChanged">

              <b-row>

                <b-col class="col-12" >
                  <table style="margin-left:1em;" class="comicGreen">
                    <thead>
                      <tr style="background:black;color:white;font-weight:bold;">
                        <td>Action</td>
                        <td class="show-hand" @click="addNewSchedule"><i class="fa fa-plus"></i> Schedule Name</td>
                        <td>Su</td>
                        <td>Mo</td>
                        <td>Tu</td>
                        <td>We</td>
                        <td>Th</td>
                        <td>Fr</td>
                        <td>Sa</td>
                        <td>Begin</td>
                        <td>End</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(s,index) in deviceScheduleList()" :key="index" class="show-hand" :style="scheduleSelection(s)">
                        <td @click="removeSchedule(s._id)"><i class="fa fa-trash"></i></td>
                        <td @click="selectSchedule(s._id)">{{s.title}}</td>
                        <td @click="selectSchedule(s._id)"><i v-if="s.weekdays[0]===true" class="fa fa-check"></i></td>
                        <td @click="selectSchedule(s._id)"><i v-if="s.weekdays[1]===true" class="fa fa-check"></i></td>
                        <td @click="selectSchedule(s._id)"><i v-if="s.weekdays[2]===true" class="fa fa-check"></i></td>
                        <td @click="selectSchedule(s._id)"><i v-if="s.weekdays[3]===true" class="fa fa-check"></i></td>
                        <td @click="selectSchedule(s._id)"><i v-if="s.weekdays[4]===true" class="fa fa-check"></i></td>
                        <td @click="selectSchedule(s._id)"><i v-if="s.weekdays[5]===true" class="fa fa-check"></i></td>
                        <td @click="selectSchedule(s._id)"><i v-if="s.weekdays[6]===true" class="fa fa-check"></i></td>
                        <td @click="selectSchedule(s._id)">{{s.beginTime}}</td>
                        <td @click="selectSchedule(s._id)">{{s.endTime}}</td>
                      </tr>
                    </tbody>
                  </table>
                </b-col>

                <b-col v-if="schedule" class="col col-xl-5 col-md-6 col-sm-12 col-xs-12">
                  <div class="show-hand"  v-b-popover.hover.top="'Add a new file to this schedule'" @click="addNewScheduleFile">
                    <i class="fa fa-plus" style="position:relative;top:8px;float:left;padding-right:1em;"  ></i>
                    <h4 style="margin:0;padding:0;text-align:left">Files</h4>
                  </div>
                  <div style="margin-left:1em;height:625px;overflow-y:auto;overflow-x:hidden;">
                    <table style="padding:0 .5em 0 .5em;border:1px gray solid;width:100%;">
                      <thead>
                        <tr style="background:black;color:white;">
                          <td style="padding-left:.5em;">Action</td>
                          <td>Filename</td>
                          <td></td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(file,index) in schedule.files" :key="index" style="border:1px solid black;">
                          <td style="text-align:center" class="show-hand" @click="removeScheduleFile(index)"><i class="fa fa-trash" style=""></i></td>
                          <td>
                            <sf-input :labelCols=0 type="list" cstyle="width:100%;" :options="kioskFileNames" v-model="schedule.files[index]" />
                          </td>
                          <td>
                            <img :src="imageUri(file)" height=96 style="cursor:pointer;padding:.25em;" @click="openImage(file)" >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </b-col>

                <b-col v-if="schedule" class="col col-xl-7 col-md-6 col-sm-12 col-xs-12">
                  <b-tabs class="tab-hover" style="margin-top:1em;" active-nav-item-class="selected-tab" v-model="scheduleTab">
                    <b-tab title="General" style="margin-top:1em;">
                      <sf-input :labelCols=3 label="Sort Order" cstyle="width:5em;" type="number" v-model="schedule.sortOrder" />
                      <sf-input :labelCols=3 label="title" type="text" v-model="schedule.title" />
                      <sf-input :labelCols=3 label="Display Seconds" helpText="How Long to Display the Background Image " type="number" v-model="schedule.displaySeconds" />
                      <sf-input :labelCols=3 label="Dashboard Id" type="list" v-model="schedule.dashboardId" :options="dashboardIdList"/>
                      Data
                      <sf-input :labelCols=0 type="textarea" :rows=12 v-model="schedule.dashboardData" v-if="schedule.dashboardId "/>


                    </b-tab>
                    <b-tab title="Days" style="margin-top:1em;">
                      <sf-input :labelCols=3 label="Begin Time" type="text" v-model="schedule.beginTime" />
                      <sf-input :labelCols=3 label="End Time" type="text" v-model="schedule.endTime" />
                      <hr />
                      <b-checkbox style="padding-left:10em;" v-model="schedule.weekdays[0]">Sunday</b-checkbox>
                      <b-checkbox style="padding-left:10em;" v-model="schedule.weekdays[1]">Monday</b-checkbox>
                      <b-checkbox style="padding-left:10em;" v-model="schedule.weekdays[2]">Tuesday</b-checkbox>
                      <b-checkbox style="padding-left:10em;" v-model="schedule.weekdays[3]">Wednesday</b-checkbox>
                      <b-checkbox style="padding-left:10em;" v-model="schedule.weekdays[4]">Thursday</b-checkbox>
                      <b-checkbox style="padding-left:10em;" v-model="schedule.weekdays[5]">Friday</b-checkbox>
                      <b-checkbox style="padding-left:10em;" v-model="schedule.weekdays[6]">Saturday</b-checkbox>
                    </b-tab>
                    <b-tab title="Options" style="margin-top:1em;">
                      <b-checkbox style="padding-left:7em;" v-model="schedule.options.showClock">Show Clock</b-checkbox>
                      <b-checkbox style="padding-left:7em;" v-model="schedule.options.hideCursor">Hide Cursor</b-checkbox>
                    </b-tab>

                    <!-- <b-tab title="Dashboard" style="margin-top:1em;">

                    </b-tab> -->

                    <b-tab v-if="schedule.dashboardId.substring(0,6)=='Widget'" title="Widgets" style="margin-top:1em;">

                    </b-tab>
                  </b-tabs>
                </b-col>


              </b-row>
            </b-form>
          </b-tab>
          <b-tab title="Files (Master List)">
            <div @click="enableUpload=!enableUpload">
              <i v-if="!enableUpload" class="fa fa-cloud-upload show-hand" v-b-popover.hover.top="'Add a new file'" style="position:relative;float:left;top:8px;margin-right:1em;"></i>
              <i v-if="enableUpload" class="fa fa-eject show-hand" v-b-popover.hover.top="'Add a new file'" style="position:relative;float:left;top:8px;margin-right:1em;"></i>
              <b-button v-if="fileRefresh" class="btn btn-sm btn-fill btn-info" style="position:relative;float:right;" @click="getKioskFiles">Refresh</b-button>
              <h4>Available Files <span style="font-size:.7em;font-style:italic;">({{formatFileSize(totalSize)}})</span></h4>
            </div>

            <div v-if="enableUpload" style="margin-bottom:1em;background:#eee;padding:.5em;border-radius:.5em;">
              <h4 id="example-title" class="example-title">File Upload</h4>
              <div class="upload">
                <ul style="list-style:none;">
                  <li v-for="(file, index) in files" :key="file.id">
                    <i class="fa fa-trash show-hand" @click="files.splice(index,1)"></i>
                    <span>{{file.name}}</span> :
                    <span>({{formatFileSize(file.size)}})</span>
                    <span v-if="file.error">{{file.error}}</span>
                    <span v-else-if="file.success"> - success</span>
                    <span v-else-if="file.active"> - active</span>
                    <!-- <span v-else-if="file.active">active</span> -->
                    <span v-else></span>
                  </li>
                </ul>
                <div class="example-btn">
                  <file-upload class="btn btn-fill btn-sm btn-primary"
                               extensions="gif,jpg,jpeg,png,webp,mp4"
                               accept="image/png,image/gif,image/jpeg,image/webp,video/mp4"
                               :multiple=true
                               :size="1024 * 1024 * 1000"
                               :custom-action="uploadFile"
                               v-model="files"
                               @input-filter="inputFilter"
                               @input-file="inputFile"
                               ref="upload">
                    <i class="fa fa-plus"></i>
                    Select files
                  </file-upload>
                  <button type="button" class="btn btn-fill btn-sm btn-success" v-if="!$refs.upload || !$refs.upload.active" @click.prevent="$refs.upload.active = true">
                    <i class="fa fa-arrow-up" aria-hidden="true"></i>
                    Start Upload
                  </button>
                  <button type="button" class="btn btn-fill btn-sm btn-danger" v-else @click.prevent="$refs.upload.active = false">
                    <i class="fa fa-stop" aria-hidden="true"></i>
                    Stop Upload
                  </button>
                </div>
              </div>
            </div>

            <div style="">
              <b-table outlined striped hover head-variant="dark" @row-clicked="fileClicked( $event )" :fields="fileColumns" :items="kioskFiles">
                <template v-slot:cell(actions)="row">
                  <i @click.stop="removeKioskFile(row.item.name)" class="fa fa-trash show-hand"></i>
                </template>
                <template v-slot:cell(name)="row">
                  <a :href="row.item.uri" target="_blank">
                    <template v-if="row.item.contentSettings.contentType.substring(0,5)=='video'">
                      <video width="128" controls="controls" preload="metadata">
                        <source :src="row.item.uri" type="video/mp4">
                      </video>
                      <span style="margin-left:1em;font-weight:bold;">{{row.item.name.substring(row.item.name.indexOf('/')+1,255)}}</span>  
                    </template>
                    <template v-if="row.item.contentSettings.contentType.substring(0,5)=='image'">
                      <img height=64 :src="row.item.uri" />
                      <span style="margin-left:1em;font-weight:bold;">{{row.item.name.substring(row.item.name.indexOf('/')+1,255)}}</span>
                    </template> 
                  </a>
                </template>
                <template v-slot:cell(lastModified)="row">
                  <div v-b-popover.hover.top="moment( row.item.lastModified ).format('LLLL').toString()">{{moment( row.item.lastModified ).fromNow()}}</div>
                </template>
                <template v-slot:cell(contentLength)="row">
                  <div style="text-align:right;">
                    {{formatFileSize( row.item.contentLength )}}
                  </div>
                </template>
              </b-table>

            </div>
          </b-tab>
          <b-tab title="Devices">
            <b-row v-if="device" style="margin-top:1em;">
              <b-col class="col col-md-12" style="margin-top:1em;">
                <div style="">
                  <div class="show-hand" style="margin-bottom:.5em;margin-top:-1em;" @click="addSubDevice" v-b-popover.hover.top="'Add a new kiosk device'"><i class="fa fa-plus-square mr-1"></i>Add new device</div>
                  <table style="" class="comicGreen">
                    <thead>
                      <tr style="background:black;color:white;font-weight:bold;">
                        <td>Action</td>
                        <td>Active?</td>
                        <!-- <td>Device ID</td> -->
                        <td>Device Name</td>
                        <td>Location</td>
                        <td>IP</td>
                        <td>Activations</td>
                        <td>Last Seen</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(d,index) in device.device" :key="d.deviceId" class="show-hand" :style="deviceSelection(d.title)">
                        <td @click="removeSubDevice(index)"><i class="fa fa-trash"></i></td>
                        <td @click="selectSubDevice(index)"><i v-if="d.active" class="fa fa-check"></i><i v-if="d.activated" class="fa fa-check-circle"></i></td>
                        <!-- <td @click="selectSubDevice(index)">{{d.deviceId}}</td> -->
                        <td @click="selectSubDevice(index)">{{d.title}}</td>
                        <td @click="selectSubDevice(index)">{{d.location}}</td>
                        <td @click="selectSubDevice(index)">{{d.ipAddress}}</td>
                        <td @click="selectSubDevice(index)">{{d.numberActivations}}</td>
                        <td @click="selectSubDevice(index)"><span v-if="d.lastSeen">{{moment(d.lastSeen).fromNow()}}</span></td>
                      </tr>
                    </tbody>
                  </table>
                </div>

              </b-col>

              <b-col class="col col-md-6 col-sm-12 col-xs-12">

                <b-form v-if="subDevice" style="margin-top:1em;" @input="hasChanged">
                  <b-button v-if="subDevice.activationCode.length>0 && subDevice.activated" class="btn btn-fill btn-sm btn-info" style="margin-bottom:.25em;" @click="subDevice.activationCode=''">Clear Activation</b-button>
                  <b-checkbox style="padding-left:9.5em;" v-model="subDevice.active">Active?</b-checkbox>
                  <b-checkbox style="padding-left:9.5em;" v-model="subDevice.relaxIp">Relax IP?</b-checkbox>

                  <sf-input :labelCols=3 label="Title" type="text" v-model="subDevice.title" />
                  <sf-input :labelCols=3 label="Location" type="text" v-model="subDevice.location" />
                  <sf-input :labelCols=3 label="Serial #" type="text" v-model="subDevice.serialNumber" />
                  <sf-input :labelCols=3 label="Device ID" type="text" disabled v-model="subDevice.deviceId" @input="deviceIdChanged" />

                </b-form>
              </b-col>

              <b-col class="col col-md-6 col-sm-12 col-xs-12">
                <b-form v-if="subDevice" style="margin-top:1em;" @input="hasChanged">
                  <sf-input :labelCols=3 label="Page Style" :rows=5 type="textarea" v-model="subDevice.pageStyle" />
                  <sf-input :labelCols=3 label="Width" cstyle="width:8em;" type="number" v-model="subDevice.resolution.width" />
                  <sf-input :labelCols=3 label="Height" cstyle="width:8em;" type="number" v-model="subDevice.resolution.height" />
                </b-form>
              </b-col>

              <b-col class="col-md-6 col-sm-12 col-xs-12">
                <b-form v-if="subDevice" @input="hasChanged">
                  <div v-if="$root.isAdmin()">
                    <b-checkbox style="padding-left:9.5em;" v-if="subDevice.activationCode.length===0" v-model="subDevice.activated">Activated?</b-checkbox>
                    <sf-input v-if="!subDevice.activated" :labelCols=3 label="Activation Code" type="text" cstyle="width:12em;" v-model="subDevice.activationCode" />
                  </div>

                  <sf-input :labelCols=3 label="Activate Date" type="datetime" disabled v-model="subDevice.activationDate" />
                  <sf-input :labelCols=3 label="# Activations" cstyle="width:6em;" type="number" disabled v-model="subDevice.numberActivations" />
                  <sf-input :labelCols=3 label="Last Seen" type="datetime" disabled v-model="subDevice.lastSeen" />
                  <sf-input :labelCols=3 label="IP Address" type="text" disabled v-model="subDevice.ipAddress" />
                  <sf-input :labelCols=3 label="Details" type="textarea" helpText="Populated when this device is activated; You should/can edit AFTER activation." v-model="subDevice.deviceModel" />
                </b-form>
              </b-col>

            </b-row>
          </b-tab>
        </b-tabs>

      </div>


      <div class="clearfix"></div>
      <br />
    </div>
  </div>
</template>

<script>

  import LTable from "/src/components/UIComponents/Table.vue";
  import DeviceService from "/src/Services/DeviceService";
  import GeneralService from "/src/Services/GeneralService";
  import UserService from "/src/Services/UserService";
  import FileService from "/src/Services/FileService";
  import SfInput       from "/src/components/UIComponents/Inputs/SFormInput.vue";
  import Moment from "moment-timezone";
  import Card from "/src/components/UIComponents/Cards/Card.vue";
  import FileUpload from "vue-upload-component";
  import Numeral from "numeral";
  import _ from "lodash";
  import Lib from "/src/Lib/ioihanLib.js";


export default {
  components: {
    LTable,
    Card,
    SfInput,
    FileUpload,
  },
  data() {
    return {
      scheduleTab  : 0,
      enableUpload : false,
      fileRefresh  : false,
      files        : [],
      accept       : 'image/gif,image/jpg,image/jpeg,image/png,image/webp,video/mp4,',
      extensions   : "gif,jpg,jpeg,png,webp,mp4",
      multiple     : true,
      directory    : false,
      drop         : true,
      dropDirectory: false,
      // postAction   : '/file',
      dashboardIdList: ["","News","News2","MenuCalendar","MenuItems","YouTube","Clock","Widget114", "Widget411" ],
      saveDisabled: true,
      modalTitle: "Adding New Device",
      timezones: null,
      isChanged    : false,
      isAdding    : false,
      isEditing   : false,
      small: true,
      device: null,
      subDevice: null,
      devices: null,
      schedule: null,
      kioskFileNames:null,
      kioskFiles: null,
      downloadLink : null,
      deviceColumns     : [
        "actions",
        { key: "active", label: "Active", sortable: true },
        // { key: "deviceType", label: "Device Type" , sortable: true },
        { key: "title", sortable: true },
        { key: "updateMinutes", label: "Update Interval", sortable: true },
        { key: "numberDevices", label: "# Devices", sortable: true },
        { key: "numberSchedules", label: "# Schedules" , sortable: true },
        { key: "TZ", label: "Timezone" , sortable: true }
      ],
      fileColumns: [
        "actions",
        { key: "name", label: "Filename", sortable: true },
        { key: "contentSettings.contentType", label: "Type", sortable: true },
        { key: "lastModified", label: "Date Added", sortable: true },
        { key: "contentLength", label: "Size", sortable: true }
      ]
    };
    },
    computed: {
      activeTimezones() {
        return this.timezones.map((t) => { return t.TZ });
      },
      totalSize() {
        let size = this.kioskFiles.reduce((a, b) => { return parseInt(a) + parseInt(b["contentLength"]) }, 0)
        return size;
      }
    },
    async mounted() {
      await Promise.all([
        this.getTimezones(),
        this.getDevices(),
        this.getKioskFiles()
      ])
    },
    async beforeRouteLeave( to , from, next ) {
      // console.log("hit beforeRouteLeave" );
      if( !this.isChanged ) {
        return next();
      }
      if( await this.$root.confirm('You have unsaved changes, continue leaving without saving?', "warning")) {
        return next();
      }
      next(false)
    },
    methods: {
      moment(d) {
        return Moment(d);
      },
      fileClicked(index) {
        //alert("fileClicked() - not implemented");
      },
      imageUri( filename ) {
        let f = _.find(this.kioskFiles, {name:`kiosk/${filename}`});
        if( f ) {
          return f.uri;
        } 
        return null;
      },
      openImage( filename ) {
        let uri = this.imageUri( filename );
        if( uri ) {
          window.open( uri , "_blank");
        }
      },
      inputFile(newFile, oldFile) {
        if (newFile && oldFile && !newFile.active && oldFile.active) {
          // Get response data
//          console.log('response', newFile.response)
          if (newFile.xhr) {
            //  Get the response status code
//            console.log('status', newFile.xhr.status)
          }
        }
      },
      inputFilter(newFile, oldFile, prevent) {
        if (newFile && !oldFile) {
          // Filter non-image file
          if (!/\.(jpeg|jpe|jpg|gif|png|webp|mp4)$/i.test(newFile.name)) {
            return prevent()
          }
        }

        // Create a blob field
        newFile.blob = ''
        let URL = window.URL || window.webkitURL
        if (URL && URL.createObjectURL) {
          newFile.blob = URL.createObjectURL(newFile.file)
        }
      },
      formatFileSize(bytes) {
        if (bytes < 10000) {    // Just bytes
          return Numeral(bytes).format("0,0") + " b";
        }
        if (bytes < 1000000000) {  // K
          return Numeral(Math.round(bytes/1000)).format("0,0") + " kb"
        }
        if (bytes < 1000000000000) {  // m
          return Numeral(Math.round(bytes/1000000)).format("0,0") + " m"
        }
        if (bytes < 1000000000000) {  // g
          return Numeral(Math.round(bytes/1000000000)).format("0,0") + " g"
        }

      },
      hasChanged() {
        this.isChanged = true;
      },
      deviceScheduleList() {
        let s = _.sortBy( this.device.schedules , ["sortOrder", "beginTime"] );
        // console.log( s );
        return s;
      },
      async removeDevice(selection) {
        if (!confirm(`Remove ${selection.item.title}?`)) {
          return;
        }

        try {
          await DeviceService.removeDevice(selection.item._id);
          await this.getDevices();
          this.$root.successMessage("Success", `${selection.item.title} Removed Successfully`)
        } catch (error) {
          console.log(error);
          this.$root.errorMessage("Oops", error.message);
        }

      },
      async removeKioskFile(filename) {
        if (!confirm(`Remove ${filename}?`)) {
          return;
        }
        try {
          let folder = "kiosk";     // we're getting the folder with the filename here...
          await FileService.deleteFile("", filename);
          this.$root.successMessage("Success", "Deleted Successfully");
          this.getKioskFiles();
        } catch (error) {
          console.log(error);
          this.$root.errorMessage("Oops", error.message);
        }

      },
      scheduleSelection(s) {
        if (s) {
          let isActive = s.weekdays.find(e => e === true);
          if (!isActive) {
            return "background:rgba(0,0,0,.2);color:#eee;";
          }
          if (this.schedule) {
            if (this.schedule.title === s.title) {
              return "background:rgba(200,00,00, .2);";
            }
          }
        }
      },
      deviceSelection(title) {
        if (this.subDevice) {
          if (this.subDevice.title === title) {
            return "background:rgba(200,00,00, .2);";
          }
        }
      },

      deviceIdChanged() {
        this.subDevice.activationCode = "";
        this.subDevice.activated = false;
        this.hasChanged();
      },
      async editDevice( row ) {
        let id = row._id;
        await this.getDevice(id);
      },
      cancelEdit() {
        this.device    = null;
        this.isChanged = false;
      },
      async uploadFile(file, component) {

        try {
          let folder = "kiosk";
          let filename = file.name;
          let filesize = file.size;

          let form = new FormData()
          form.postAction = "POST";
          form.append(component.name, file.file, file.file.filename || file.name)
          form.append( 'content-type' , file.type)

          let response = await FileService.postFile(folder, form);

          this.fileRefresh = true;

        } catch (error) {
          console.log("uploadFile()", error);
        }

      },
      async getKioskFiles() {
        try {
          this.kioskFiles     = null;
          this.kioskFileNames = null;
          let response = await FileService.getFiles("kiosk/");
          this.kioskFiles = response.data.entries;

          this.downloadLink = response.data.downloadLink;
          this.kioskFileNames = this.kioskFiles.map((f) => { return f.name.split('/')[1] });
          this.fileRefresh = false;
        } catch (error) {
          console.log( "getKioskFiles() Error" , error);
          await this.$root.alert( error.message + " Your Files May Not Be Present - Please Call Support" );
          this.kioskFiles = [];
          this.kioskFileNames = [];
          this.fileRefresh = true;
          this.$root.errorMessage("Oops", error.response.data.message || error.message );
        }
      },
      selectSubDevice(i) {
        this.subDevice = this.device.device[i];
        if (!this.subDevice.activated) {
          if (this.subDevice.activationCode) {
            this.subDevice.activationCode = "";
            this.hasChanged();
          }
        }
      },
      selectSchedule(id) {
        let i = _.findIndex( this.device.schedules, {_id: id });
        if( i >= 0 ) {
          this.schedule = this.device.schedules[i];
        } else {
          this.$root.alert( "Schedule Not Found" );
        }
      },
      removeScheduleFile(index) {
        if (index < this.schedule.files.length) {
          this.schedule.files.splice(index, 1);
          this.hasChanged();
        }
      },
      async addNewScheduleFile() {
        //if (!confirm("Add New File?")) {
        //  return;
        //}
        this.schedule.files.push("");
        this.hasChanged();
      },
      async removeSchedule(id) {
        if (this.isChanged) {
          await this.$root.alert("Please save your changes before removing a schedule");
          return;
        }
        if (this.device.schedules) {
          let i = _.findIndex( this.device.schedules, {_id: id });
          if( i >= 0 ) {
            this.device.schedules.splice(i, 1);
            this.schedule = null;
            this.hasChanged();
          } else {
            this.$root.alert( "Schedule Not Found" );
          }
        }
      },
      async addNewSchedule() {
        if (this.isChanged) {
          await this.$root.alert("Save changes before adding a new schedule");
          return
        }
        if (! await this.$root.confirm("Add New Schedule?")) {
          return;
        }
        this.device.schedules.push({ title: "New Schedule" });
        let response = await DeviceService.saveDevice(this.device);
        this.device = response.data;
        this.schedules = this.device.schedules; //[this.device.schedules.length - 1];
      },
      async removeSubDevice(i) {
        if (this.isChanged) {
          await this.$root.alert("Save your current changes before removing this device");
          return;
        }
        if( ! await this.$root.confirm( `Remove ${this.device.device[i].title}?`)) {
          return;
        }
        this.subDevice = null;
        this.device.device.splice(i, 1);
        let response = await DeviceService.saveDevice(this.device);
        this.device = response.data;
        // this.hasChanged();
      },
      async addSubDevice() {
        if (this.isChanged) {
          await this.$root.alert("Save changes before adding a new device");
          return;
        }
        if (!await this.$root.confirm("Add New Device")) {
          return;
        }
        var deviceId = this.$root.tenantDomain() + "-" + Lib.make16DigitId();
        this.device.device.push({ title: "New Device", deviceId: deviceId });
        let response = await DeviceService.saveDevice(this.device);
        this.device = response.data;
        this.subDevice = this.device.device[this.device.device.length - 1];
      },
      async addDevice() {
        var t     = this;
        if (!confirm("Add new device?")) {
          return;
        }
        this.device = { title: "New Device" };
        let response = await DeviceService.addDevice( this.device )
        this.device = response.data;
        this.hasChanged();
      },
      async saveDevice(d) {
        try {

          await DeviceService.saveDevice(this.device);  // no need to capture return value

          this.device    = null;
          this.subDevice = null;
          this.schedule  = null;
          await this.getDevices();

        } catch (error) {
          let message = error.message;
          if (error.response) {
            if (error.response.data) {
              message = error.response.data.message;
            }
          }
          this.$root.errorMessage( message )
          console.log(error);
        }
      },
      async cloneDevice() {
        await this.$root.alert("clone not implemented yet");
      },
      async getDevices() {
        try {
          let response = await DeviceService.getDevices();
          this.devices = response.data;
          this.isChanged = false;
        } catch (error) {
          console.log( error.message )
        }
      },
      async getDevice( id ) {
        try {
          let response       = await DeviceService.getDevice( id );

          this.device    = Object.assign(response.data);
          this.schedule  = null;
          this.subDevice = null;
          this.isChanged = false;
        } catch (error) {
          console.log( error.message )
        }
      },
      async getTimezones() {
        try {
          let response = await GeneralService.activeTimezones();
          this.timezones = response.data;
        } catch (error) {
          console.log( error )
        }
      },
      showModal () {
        this.$refs.myModalRef.show()
      },
      hideModal () {
        this.$refs.myModalRef.hide()
      },
    },
  };
</script>

<style lang="scss" scopoed>

  li:hover {
    background:rgba(100,100,100,.2);
    /*color:white !important;*/
  }

  table.comicGreen {
  font-family: "Comic Sans MS", cursive, sans-serif;
  border: 2px solid #4F7849;
  background-color: #EEEEEE;
  width: 100%;
  text-align: center;
  border-collapse: collapse;
}
table.comicGreen td, table.comicGreen th {
  border: 1px solid #4F7849;
  padding: 3px 2px;
}
table.comicGreen tbody td {
  font-size: 17px;
  font-weight: bold;
  color: #4F7849;
}
table.comicGreen tr:nth-child(even) {
  background: #CEE0CC;
}
table.comicGreen tfoot {
  font-size: 19px;
  font-weight: bold;
  color: #FFFFFF;
  background: #4F7849;
  background: -moz-linear-gradient(top, #7b9a76 0%, #60855b 66%, #4F7849 100%);
  background: -webkit-linear-gradient(top, #7b9a76 0%, #60855b 66%, #4F7849 100%);
  background: linear-gradient(to bottom, #7b9a76 0%, #60855b 66%, #4F7849 100%);
  border-top: 1px solid #444444;
}
table.comicGreen tfoot td {
  font-size: 19px;
}

</style>
