<template>
  <div class="content"  >

    <div v-if="!paymentProvider">
      <list-card
        title="Payment Providers"
        titleHelp="Select the desired Payment Provider to edit"
        :items="paymentProviders"
        :fields="fields"
        :enableAdd="true"
        :enableDelete="true"
        :enableDone="true"
        :enableShowActive="true"
        @click="edit($event)"
        @add="add()"
        @delete="remove($event)"
        @done="$router.go(-1)"
      >
        <template v-slot:action="row" >
          <i class="fa fa-link" style="color:green;" v-if="row.item.merchantActive"></i>
          <i class="fa fa-chain-broken" style="color:orange;" v-if="!row.item.merchantActive"></i>
        </template>
      </list-card>
    </div>


    <div v-if="paymentProvider">
      <payment-provider-edit
        :provider="paymentProvider"
        :changed="isDataChanged"
        :editing="isEditing"
        @done="done($event)"
        @save="save($event)"
        @cancel="cancel($event)"
        @clone="clone($event)"
        @changed="dataChanged()"
      />
    </div>

  </div>
</template>
<script>

  import PaymentProvidersService  from "/src/Services/PaymentProvidersService";
  import PaymentProviderEdit      from "./PaymentProviderEdit.vue";

  import Currency       from "currency.js";
  import gravatar       from "gravatar";
  import Moment         from "moment-timezone";
  import Card           from "/src/components/UIComponents/Cards/Card.vue";

  import SfInput        from "/src/components/UIComponents/Inputs/SFormInput.vue";

  import ClickOutside     from 'vue-click-outside'
  import VueSimpleSuggest from 'vue-simple-suggest'
  import 'vue-simple-suggest/dist/styles.css' // Optional CSS
  // import VJstree          from 'vue-jstree';
  import PieWidget        from "/src/components/UIComponents/Widgets/PieWidget.vue";
  import BarWidget        from "/src/components/UIComponents/Widgets/BarWidget.vue";

  import LineChart from "/src/components/Charting/LineChart.js";
  import BarChart  from "/src/components/Charting/BarChart.js";
  import exportLib from "../../../Lib/dataExportLib";
  import ListCard           from '/src/components/UIComponents/Cards/ListCard.vue';

  import SimpleCalendar from "/src/components/UIComponents/SimpleCalendar";

  var propTicketType;
  //var propUser;
  let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  //                 0         1         2         3         4         5         6         7         8        9         10        11        12
  let colors  = ['#a7a7a7','#64609A','#933709','#14A989','#D05340','#436CB9','#3AA8C1','#B56917','#AA4069','#2D5DA1','#832A0D','#B56917','#469A84'];
  let colors2 = ['#F0EAD6','#DD4132','#9E1030','#FE840E','#FF6F61','#C62168','#8D9440','#FFD662','#00539C','#755139','#D69C2F','#E8B5CE','#343148'];

  export default {
    directives: {
      ClickOutside
    },
    components: {
      Card,
      SfInput,
      PieWidget,
      BarWidget,
      BarChart,
      LineChart,
      ListCard,
      VueSimpleSuggest,
      // VJstree,
      SimpleCalendar,
      PaymentProviderEdit
    },
    props: {
      propProviderId: { type: String, default: null }
    },
    watch: {
    },
    data() {
      return {
        isDataChanged: false,
        loading      : false,
        loadingClass : "fa fa-spinner  fa-refresh-animate mr-3",
        listTitle    : "List Title",
        isEditing    : false,
        fields       : [
          { key: "action", label: "", sortable: true },
          { key: "title", label: "Title", sortable: true },
          // { key: "accountNumber", label: "Account", sortable: true },
          // { key: "alias", label: "Alias", sortable: true },
          // { key: "primary.city", label: "City", sortable: true },
          // { key: "primary.state", label: "State", sortable: true },
          // { key: "primary.phone", label: "Phone", sortable: true },
          // { key: "primary.email", label: "Email", sortable: true },
          { key: "updatedAt", label: "Last Update", sortable: true,  formatter:(value) => { return Moment(value).fromNow() } }
        ],
        paymentProviders: [],
        paymentProvider : null
      }
    },
    async created() {
      this.paymentProvider = null;
      this.paymentProviders = null;

      // if (this.propsCategory) {
      //   this.categoryFilter = this.propsCategory.split(",");
      // }

      await Promise.all([
        this.getPaymentProviders()
      ])
    },
    async beforeRouteLeave( to , from, next ) {
      if( !this.isDataChanged ) {
        return next();
      }
      if( await this.$root.confirmYesNo('You have unsaved changes, continue leaving without saving?', "warning")) {
        return next();
      }
      next(false)
    },
    async mounted() {
    },
    computed: {
    },
    methods: {
      done(provider) {
        this.isDataChanged   = false;
        this.paymentProvider = null;
      },
      cancel(provider) {
        this.done();
      },
      async clone( provider) {
        let t           = this;
        let newProvider = Object.assign({},provider);

        t.paymentProvider = null;
        newProvider.title = provider.title + " (copy)";
        t.isLoading       = true;

        try {
          let response = await PaymentProvidersService.addPaymentProvider( newProvider);  // get db defaults.
          t.paymentProvider= response.data;
          t.isEditing = true;
          t.dataChanged();
        } catch( error ) {
          console.log(error);
          await this.alert(error.message , "danger");
        }
        t.isLoading = false;
      },
      dataChanged() {
        this.isDataChanged = true;
      },
      currency(c) {
        return Currency(c);
      },
      moment(d) {
        return Moment(d);
      },
      setChartYear() {
        this.makeCharts();
      },
      async calendarGotoToday() {
        let newDate = Moment().tz(this.$root.TZ);
        // await this.getTransactions( newDate );
        this.calendarDate = newDate;
      },
      async calendarPrevMonth() {
        let newDate = Moment(this.calendarDate).tz(this.$root.TZ).subtract( 1, 'month' );
        // await this.getTransactions( newDate );
        this.calendarDate = newDate;
      },
      async calendarNextMonth() {
        // if( this.calendarDate < this.moment()) {
          let newDate = Moment(this.calendarDate).tz(this.$root.TZ).add( 1, 'month' );
          // await this.getTransactions( newDate );
          this.calendarDate = newDate;
        // }
      },
      csvExportvendorSummary() {
        exportLib.csvExportAskFilename( this.ticketStatusList.filter((x) => { return x.dueDate.substring(0,4) == this.chartYear })); //, this.ticketSummaryFields );
      },
      toggleControlWindow() {
        this.isShowControlWindow = !this.isShowControlWindow;

        if( this.isShowControlWindow ) {
           this.ticketWindowClass   = "col-md-12 col-lg-8 col-xl-9";
        } else {
          this.ticketWindowClass="col-12";
        }

      },
      tagList() {
        let a = this.tickets.flatMap( t => t.tags );
        return _.uniq(a).sort();
      },
      tagValidator(tag) {
        return tag === tag.toLowerCase() && tag.length > 2 && tag.length < 15
      },
      isReadOnly() {
        // if( this.ticket.closed ) {
        //   return true;
        // }
        // if( ! this.isMyTicketType( this.ticket.ticketType )) {
        //   if( this.ticket.assignedToId !== this.$root.user.tenantUser ) {
        //     return true;
        //   }
        // }
        return false;
      },
      async getUserOptions() {
        // try {
        //   let response = await UserService.getUserOption( "vendors" , "filter" );
        //   let result = response.data;
        //   if( result ) {
        //     this.showClosed   = result.showClosed;
        //     this.showParked   = result.showParked;
        //     this.showStuck    = result.showStuck;
        //     this.showComplete = result.showComplete;
        //   }
        // } catch( error ) {
        //   console.log( error.message );
        // }
      },
      async saveUserOptions() {
        // try {
        //   let data  = {
        //     showClosed  : this.showClosed,
        //     showParked  : this.showParked,
        //     showStuck   : this.showStuck,
        //     showComplete: this.showComplete
        //   }
        //   await UserService.setUserOption("vendors" , "filter" , data );
        // } catch( error ) {
        //   console.log( "saveUserOptions()" , error.message );
        // }
      },
      async add( provider = {}) {

        if( ! await this.$root.confirmYesNo( "Add New Provider?" )) {
          return;
        }

        let t           = this;
        let newProvider = { title: "New Provider"  };

        t.isLoading = true;
        provider = Object.assign( {} , newProvider , provider);  // merge a preset passed in with our defaults;

        try {
          let response          = await PaymentProvidersService.addPaymentProvider( provider );  // get db defaults.
              t.paymentProvider = null;
              t.paymentProvider = response.data;
              t.isEditing       = true;

          console.log( "addProvider() response ", response.data );

          t.dataChanged();

        } catch( error ) {
          console.log(error);
          if (error.response) {
            t.$root.errorMessage("Oops", error.response.data.message);
          }
        }
        t.isLoading = false;
      },
      async getPaymentProviders() {
        var t = this;

        t.loading   = true;
        t.isEditing = false;

        try {

          let response = await PaymentProvidersService.getPaymentProviders( );
          t.paymentProviders = response.data;

        } catch (error) {
          if (error) {
            console.error(error);
            if( error.response ) {
              t.$root.errorMessage("Oops", error.response.data.message);
            } else {
              t.$root.errorMessage( "Oops" , error.message );
            }
          }
          t.paymentProviders = [];
        }
        t.loading = false;
      },
      async save( provider = this.paymentProvider ) {
        let t = this;

        try {
          let response;
          if( provider ._id ) {
            response = await PaymentProvidersService.updatePaymentProvider( provider );
          } else {
            response = await PaymentProvidersService.addPaymentProvider( provider);
          }

          let id = response.data._id;

          let found = false;
          for( var i=0; i<t.paymentProviders.length; i++ ) {
            if( t.paymentProviders[i]._id === id ) {
              t.paymentProviders[i] = response.data;
              found = true;
              break;
            }
          }
          if( ! found ) {
            t.paymentProviders.unshift( response.data );    // plug the new ticket at the top.
          }

          t.paymentProvider = null;
          t.isDataChanged = false;
          t.isEditing     = false;

        } catch( error ) {
          console.log(error);
          if (error.response) {
            t.$root.errorMessage("Oops", error.response.data.message);
          } else {
            t.$root.errorMessage("Oops" , error.message );
          }
        }
      },
      edit( provider ) {
        let t = this;
        t.getPaymentProvider(provider ._id);
      },
      async getPaymentProvider(id) {
        let t = this;

        t.loading = true;
        try {
          let response = await PaymentProvidersService.getPaymentProvider(id);

          t.paymentProvider = response.data;
          t.isDataChanged = false;
          t.isEditing     = false;

        } catch (error) {
          console.log(error);
          if (error.response) {
            t.$root.errorMessage("Oops", error.response.data.message);
          }
        }
        t.loading = false;
      },
      async remove(e) {

        let provider = e.item;    // what we get from the list component.

        if( ! await this.$root.confirmYesNo( `Delete\n[${provider.title}]?` )) {
          return;
        }

        let t = this;
        let id = provider._id;
        let index = _.findIndex( t.paymentProviders, { _id: id } );

        try {

          await PaymentProvidersService.deletePaymentProvider( id );

          t.paymentProviders.splice(index,1);
          t.paymentProvider= null;
          t.isDataChanged = false;
          t.isEditing     = false;

        }catch( error ) {
          console.log(error);
          if (error.response) {
            t.$root.errorMessage("Oops", error.response.data.message);
          }
        }
      }
    }
  }

</script>
<style lang="scss" scoped >

 .odd-even {

    tr:nth-child(odd) {
      background: #eee;
    }
    tr:nth-child(even) {
      background: #fff
    }
  }

blockquote {
    font-family: Georgia, serif;
    font-size: 18px;
    font-style: italic;
    width: 500px;
    margin: 0.25em 0;
    padding: 0.35em 40px;
    line-height: 1.45;
    position: relative;
    color: #383838;
}

blockquote:before {
    display: block;
    padding-left: 10px;
    content: "\201C";
    font-size: 80px;
    position: absolute;
    left: -20px;
    top: -20px;
    color: #7a7a7a;
}

// blockquote:after {
//   display: block;
//   padding-right: 10px;
//   content: "\201D";
//   font-size: 80px;
//   position: relative;
//   // right: 20px;
//   top: 20px;
//   color: #7a7a7a;
// }

blockquote cite {
    color: #999999;
    font-size: 14px;
    display: block;
    margin-top: 5px;
}

blockquote cite:before {
    content: "\2014 \2009";
}
  .outline-shadow {
    -webkit-box-shadow: 0px 0px 25px 5px rgba(0,0,0,0.35);
    box-shadow: 0px 0px 25px 5px rgba(0,0,0,0.35);
  }

  .collapsible {
    background-color: #eee;
    margin-top: .5em;
    border-radius: 1em;
    color: #444;
    cursor: pointer;
    padding: 10px;
    border: none;
    text-align: left;
    outline: none;
  }

  ins {
     background-color:rgba( 0,255,0,.1);
     text-decoration:none;
  }

  del {
    background-color:rgba( 255,0,0,.1);
  }

  .hover-track-light:hover{
    background: #eee;
    color:black;
  }

  .hover-track-dark:hover {
    background: #333;
    color: white;
  }


  .page-stamp {

    position: absolute;
    top: 4em;
    left: 2em;
    z-index: 1;
    font-family: Arial,sans-serif;
    -webkit-transform: rotate(-30deg); /* Safari */
    -moz-transform: rotate(-30deg); /* Firefox */
    -ms-transform: rotate(-30deg); /* IE */
    -o-transform: rotate(-30deg); /* Opera */
    transform: rotate(-30deg);
    font-size: 6em;
    color: #c00;
    background: #fff;
    border: solid 4px #c00;
    padding: 0 .5em 0 .5em;
    border-radius: .25em;
    zoom: 1;
    filter: alpha(opacity=20);
    opacity: 0.2;
    -webkit-text-shadow: 0 0 2px #c00;
    text-shadow: 0 0 2px #c00;
    box-shadow: inset 0px 0px 0px 10px #c00;
  }


  .page-stamp:after {
    content:'';
    border-radius:.25em;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url("@/assets/img/stamp-background.jpg");
    mix-blend-mode: lighten;
  }

  @media only screen and (max-device-width: 1700px) {
    .hide-too-small {
      display:none;
    }
  }


  @media print {

    @page {
      size: letter portrait;
    }

    body {
      -webkit-print-color-adjust: exact !important;
      width: 100%;
    }

    .logo {
      width: 300px !important;
      max-width: 300px !important;
    }

    .noprint {
      display: none;
    }

    .container {
      width: 100%;
      max-width: 100%;
    }
  }

</style>
