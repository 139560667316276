///-------------------------------------------------------------------------------------------------
// summary:	Portal Registrations service class
///-------------------------------------------------------------------------------------------------

import Api from '@/Services/Api';

export default {

  getPortalAvailableAuthorizations( table = "student") {
    const response = Api().get(`/api/1/portalAvailableAuthorizations/${table}`);
    return response;
  },
  getPortalRegisteredAuthorizations( table = "student") {
    const response = Api().get(`/api/1/portalRegisteredAuthorizations/${table}`);
    return response;
  },
  portalAuthorizationsUpload( csvFile , postFlag = 'false') {
    // return Api().post( `/api/1/portalAuthorizationsUpload` , csvFile ,{ params: {okToPost: postFlag }});
    return Api().post( '/api/1/portalAuthorizationsUpload' , csvFile , { params: {okToPost: postFlag , extra: 'no' }});
  },
  getPortalAuthorizationsImportTags( table = "student" ) {
    return Api().get( `/api/1/portalAuthorizationsImportTags/${table}`);
  },
  getPortalRegisteredAuthorizationsShortList( table = "student" , importTags = "" ) {
    const response = Api().get(`/api/1/portalRegisteredAuthorizations/${table}`,{ params: {shortlist: 'yes', importtag: importTags }} );
    return response;
  },
  getPortalRegistrationsTeachers( table = "student") {
    const response = Api().get(`/api/1/portalAuthorizationsTeachers/${table}`);
    return response;
  },
  getAuthorization( id ) {
    return Api().get( `/api/1/portalAuthorization/${id}`);
  },
  addAuthorization( authorization ) {
    return Api().post( `/api/1/portalAuthorization` , authorization );
  },
  updateAuthorization( authorization ) {
    return Api().patch( `/api/1/portalAuthorization` , authorization );
  },
  removeAuthorization( id ) {
    return Api().delete( `/api/1/portalAuthorization/${id}`);
  }


}
