<template>
  <card class="" style="width:100%;height:100%;padding:2px 1em;">
    <h3>{{ title }}</h3>

      <vue-avatar-editor
        :width="parseInt(width)"
        :height="parseInt(height)"
        :rotation="parseFloat(rotation)"
        :scale="parseFloat(scale)"
        :finishText="saveButtonText"
        :color="color"
        :border="5"
        ref="vueavatar"
        @finished="userImageReady"
        @cancel="cancel"
        @select="select($event)"
      />

      <div>
        {{message}}
      </div>

  </card>
</template>

<script>
import Card from './Card.vue'
import FileService from  "/src/Services/FileService";
import VueAvatarEditor from '/src/components/UIComponents/Avatar/VueAvatarEditor';

export default {
    name: 'image-upload-card',
    components: {
      Card,
      VueAvatarEditor
    },
    props: {
      height        : { type: Number, default: 150 },
      width         : { type: Number, default: 150 },
      title         : { type: String, default: "Image Upload"},
      color         : { type: Array, default: () => [128,0,0,.5] },
      filename      : { type: String, default: null },
      folder        : { type: String, default: "/"},
      prefix        : { type: String, default: ""},
      saveButtonText: { type: String, default: "Yep, Save My Photo" }
    },
    data () {
      return {
        rotation    : 0.0,
        scale       : 1.0,
        borderRadius: 0,
        message     : "",
        file        : null,
        fileResponse: null
      }
    },
    methods: {
      save( data ) {
        this.$emit("save", data, this.fileResponse );
      },
      cancel() {
        this.$emit("cancel");
      },
      select( files ) {
        this.file = files[0];
      },
      async userImageReady( e ) {

        let imageScaled = e.getImageScaled();
        // let image = e.getImage();

        try {

          let filename;
          if( this.filename ) {
            filename = this.filename;
            if( filename.indexOf(".") < 0 ) {
              filename = filename + "." + this.file.name.split(".").pop();
            }
          } else {
            filename = this.prefix + this.file.name;
          }

          Object.defineProperty(this.file, 'name', {   // there was no "setter" for a file "name" object. :(
            writable: true,
            value: filename
          });

          let data = {
            upload  : imageScaled.toDataURL(this.file.type),
            // upload  : image.toDataURL(this.file.type),
            filename: this.file.name,
            size    : this.file.size,
            folder  : this.folder
          }

          let result = await FileService.uploadImageFromText( data );

          this.fileResponse = result.data;

// console.log( this.fileResponse );

          this.save( data );

        } catch( error ) {
          this.message = error.message;
        }

      }
    }
  }

</script>

<style>
</style>
