<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <totals-form>
          </totals-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TotalsForm from "./TotalsForm.vue";

export default {
  components: {
    TotalsForm
  }
};
</script>

<style>
</style>
