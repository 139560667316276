<template>
  <card class="" style="margin-top:-20px;width:450px;height:100%;padding:1em;border:0px solid white;border-radius:1em;background:#eee;">
    <h3 style="margin-top:-20px;">{{ title }}</h3>
    <div class="upload">
      <ul style="list-style:none;">
        <li v-for="(file, index) in uploadFileList" :key="file.id">
          <i class="fa fa-trash show-hand" @click="uploadFileList.splice(index,1)"></i>
          <span>{{ file.name }}</span> :
          <span>({{ LIB.formatFileSize(file.size) }})</span>
          <span v-if="file.error"><i class="fa fa-times" style="color:red;font-size:1.5em;"></i>{{ file.error }}</span>
          <span v-else-if="file.success"> - success <i class="fa fa-check" style="color:green;font-size:1.5em;"></i></span>
          <span v-else-if="file.active"> - <b-spinner variant="success" ></b-spinner> Uploading</span>
          <span v-else></span>
        </li>
      </ul>
      <div class="">
        <file-upload class="btn btn-fill btn-sm btn-primary"
                     style="margin-right:2em;"
                     :extensions="allowedExtensions"
                     :multiple="allowMultipleFiles"
                     :size="1024 * 1024 * 1000"
                     :custom-action="uploadFile"
                     v-model="uploadFileList"
                     @input-filter="inputFilter"
                     @input-file="inputFile"
                     ref="upload">
                     <div v-if="uploadFileList.length==0">
                        <i class="fa fa-plus"></i>{{selectFilesPrompt}}
                     </div>
        </file-upload>
        <div v-if="uploadFileList.length>0 && isShowStartUpload" style="display:inline-block;padding-right:2em;">
          <button  type="button" class="btn btn-fill btn-sm btn-success" v-if=" ( isShowStartUpload && (!$refs.upload || !$refs.upload.active))" @click.prevent="$refs.upload.active = true">
            <i class="fa fa-arrow-up" aria-hidden="true"></i>
            {{startUploadPrompt}}
          </button>
          <button type="button" class="btn btn-fill btn-sm btn-danger" v-else @click.prevent="$refs.upload.active = false">
            <i class="fa fa-stop" aria-hidden="true"></i>
            {{stopUploadPrompt}}
          </button>
        </div>
        <button v-if="!isShowStartUpload" type="button" class="btn btn-fill btn-sm btn-warning float-right" @click="done()">
          <i class="fa fa-window-close" aria-hidden="true"></i>
          {{doneUploadPrompt}}
        </button>
      </div>
    </div>

    <div>
      {{message}}
    </div>

  </card>
</template>

<script>
import Card from './Card.vue'
import FileService from  "/src/Services/FileService";
import FileUpload   from "vue-upload-component";
import LIB          from "/src/Lib/rootLib.js";

export default {
    name: 'file-upload-card',
    components: {
      Card,
      FileUpload
    },
    props: {
      title: { type: String, default: "Image Upload"},
      readonly: {type: Boolean, default: false },
      uploadFolder: { type: String, default:null},
      allowMultipleFiles: { type: Boolean, default: false },
      filePrefix: { type: String, default: ""},
      saveButtonText: { type: String, default: "Yep, Upload These Things!" },
      allowedExtensions: { type: String, default: "csv,bmp,ico,gif,jpg,jpeg,png,svg,webp,webm,weba,mp3,mepg,md,wav,mp4,mov,pdf,doc,docx,xls,xlsx,pptx,ppt,txt,zip,isl" },
      acceptedContentTypes: { type: String, default: "image/png,image/gif,image/jpeg,image/bmp,image/webp,image/svg+xml,audio/wav,audio/webm,video/webm,audio/mpeg,video/mp4,text/csv,text/x-csv,text/plain,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.oasis.opendocument.spreadsheet,application/pdf,application/vnd.visio,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/zip,application/octet-stream,application/vnd.ms-powerpoint" },
      selectFilesPrompt: { type: String, default: "Select Files" },
      startUploadPrompt: { type: String, default: "Start Upload" },
      stopUploadPrompt: { type: String, default: "Stop Upload"},
      doneUploadPrompt: { type: String, default: "Done"}
    },
    data () {
      return {
        LIB              : LIB,     // tie in our lib routines so we can use them in the template above.
        uploadFileList   : [],
        isShowStartUpload: false,
        message          : "",
      }
    },
    methods: {
      done() {
        this.$emit("done");
      },
      select( files ) {
        this.file = files[0];
        console.log( "ImageUpload() -> select()" ,this.file );
      },
      inputFile(newFile, oldFile) { // guessing this gets called as we're selecting the file - dunno

        if( oldFile ){
          this.isShowStartUpload = false;
        } else {
          this.isShowStartUpload = !newFile.active;
        }

        if(newFile && oldFile && !newFile.active && oldFile.active) {
          if(newFile.xhr) {
            //  Get the response status code
            //            console.log('status', newFile.xhr.status)
          }
        }
      },
      inputFilter(newFile, oldFile, prevent) {
        if(newFile && !oldFile) {
          // Filter non-image file
          let ext = newFile.name.substring(newFile.name.lastIndexOf(".") + 1, 256).toLowerCase();
          if( this.allowedExtensions.split(",").indexOf( ext ) < 0 ) {
          // if(!/\.(jpeg|jpe|jpg|gif|png|webp|mp4|mp3|xls|xlsx|doc|docx|txt|pdf|zip)$/i.test(newFile.name)) {
            return prevent()
          }
        }
        // Create a blob field
        newFile.blob = ''
        let URL      = window.URL || window.webkitURL
        if(URL && URL.createObjectURL) {
          newFile.blob = URL.createObjectURL(newFile.file)
          //          console.log( "inputFilter() newFile" , JSON.stringify( newFile,null,2 ))
        }
      },
      async uploadFile(file, component) {

        this.isShowStartUpload = false;

        try {
          let folder   = this.uploadFolder;
          let filename = file.name;
          let filesize = file.size;

          let form        = new FormData();
          form.postAction = "POST";
          form.append( component.name, file.file, (file.file.filename || file.name));
          form.append('content-type', file.type);

          this.$emit( "fileUploadBegin" , this.file );
          let response = await FileService.postFile(folder, form);
          this.$emit( "fileUploadComplete" , this.file );

        } catch(error) {
          this.$emit( "fileUploadError" , this.file );
          console.log("uploadFile()", error);
        }

      },

    }
  }

</script>

<style>

  .upload {
  }

</style>
