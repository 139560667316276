<template>
  <div class="content container-fluid">
    <div class="row">
      <div class="col badge-entry col-md-11 ">
        <h3><i class="fa fa-plus-square show-hand" style="margin-right:.75em;" v-b-popover.hover.top="'Add a NEW asset'" @click="addNewAsset()"></i>Asset Management </h3>

        <div style="float:right;">
          <b-form @change="selectAssetType" style="display:block;">
            <i class="fa fa-eraser float-right show-hand" @click="assetType=searchString=''" v-b-popover.hover.top="'Clear TYPE to search all assets'"></i>
            <label style="margin:0;padding:0;">Asset Filter</label>
            <sf-input type="list" style="padding:0;" cstyle="margin-bottom:2em;margin-top:-1em;width:15em;" :options="assetTypeList()" v-model="assetType" ></sf-input>
          </b-form>
        </div>

        <div  style="color:#555;width:400px;font-style:italic;font-size:.8em;padding-left:2em;">Select an "Asset Filter" to restrict your search to a specific Asset TYPE or leave blank to search ALL. Next, enter the information you are seeking, and press the "SEARCH" button.
        </div>

        <b-form @submit.prevent="searchAssets(searchString)">
          <!--        <b-button class="btn btn-fill float-right" size="sm" style="border-radius:.75em;margin-bottom:.5em;" @click="searchString=''" variant="light">clear</b-button>-->
          <b-input-group class="float-left" id="badge-label" label-for="searchString">
            <b-btn class="btn btn-fill btn-sm float-left" style="margin-right:1em;" @click="searchAssets(searchString)" variant="success">
              Search
            </b-btn>
            <b-form-input ref="inputField" autofocus id="searchString" style="border-radius:.75em;" type="search" v-model="searchString" required placeholder="Search criteria (Select Asset Filter to restrict search) " />
          </b-input-group>
        </b-form>
        <br />

      </div>

      <b-modal v-model="isAdding"
               :title="modalTitle"
               size="lg"
               ref="addAssetDialog"
               header-bg-variant="info"
               header-text-variant="light"
               footer-bg-variant="info"
               footer-text-variant="light">
        <b-container fluid>
          <b-form-group label-cols-lg="3" label="Asset Information" label-size="lg" label-class="font-weight-bold pt-0" class="mb-0">
            <sf-input id="assetType1" type="list" label="Asset Type" v-model="asset.assetType" :options="assetTypeList()"></sf-input>
            <sf-input id="title" type="text" label="Asset Name/Title" v-model="asset.title" required placeholder="Friendly name" @keyup.enter.native="addAsset()"></sf-input>
          </b-form-group>
        </b-container>
        <div slot="modal-footer" class="w-100 mt-3">
          <b-btn v-if="!isEditing" size="sm" class="float-left btn-fill" variant="info" @click="asset.assetNumber='';asset.title='';">
            Clear Form
          </b-btn>
          <div v-if="asset.assetType && asset.title">
            <b-btn v-if="!isEditing" size="sm" class="float-right btn-fill" variant="success" @click="addAsset();"  >
              Add This Asset
            </b-btn>
            <b-btn v-if="isEditing" size="sm" class="float-right btn-fill" variant="success" @click="addAsset();">
              Update Asset
            </b-btn>
          </div>
          <b-btn size="sm" class="float-right btn-fill mr-2" variant="danger" @click="isAdding=false">
            Cancel
          </b-btn>
        </div>
      </b-modal>


    </div>
    <div class="row">
      <div v-if="assets.length > 0" class="col-12" style="margin-top:1.5em;">
        <div class="table-title" >
          <h4  class=" mt-0">Asset List <span style="font-size:.75em">({{assets.length}})</span><span v-if="isCache" style="font-size:.75em;font-style:italic;margin-left:1em;color:red;">(cached)</span></h4>
          <div class="table-filter">
            <b-form-input id="filterString" class="filter-field" type="search"  v-model="filterString" placeholder="Filter selected assets" />
          </div>
        </div>
        <div style="width:100%;overflow:auto;">
          <b-table
              class="show-hand"
              striped hover head-variant="dark" sticky-header="true"
              small
              responsive="md"
              :filter="filterString"
              :filter-ignored-fields="['assetType', 'assetCategory']"
              stacked="md" sort-by="assetNumber"
              :items="assets" :fields="columns"
              @row-clicked="editAsset($event)">
            <template v-slot:cell(actions)="row">
              <span style="vertical-align:top;">
                <i class="fa fa-trash show-hand" style="color:#bbb;margin-right:.5em;" v-b-popover.hover.right='"Delete This Asset"' @click.stop="removeAsset(row.item._id)"></i>
                <i class="fa fa-arrow-right" style="color:#FF5828;" v-b-popover.hover.right='"Loaned OUT To: " +  row.item.loanedTo || "unknown"' v-if="row.item.status.isOnLoan"></i>
                <i class="fa fa-arrow-left" style="color:#009393;" v-b-popover.hover.right='"Borrowed Item From: " + row.item.borrowedFrom || "unknown"' v-if="row.item.status.isBorrowed"></i>
                <i class="fa fa-lock" style="color:#FF5828;" v-b-popover.hover.right='"Locked by " + row.item.ownerName || "unknown"' v-if="row.item.status.isOwnerUpdateOnly"></i>
                <i class="fa fa-home" style="color:#009090;" v-b-popover.hover.right='"Room Asset " + row.item.roomId || "unknown"' v-if="row.item.isRoomAsset"></i>
              </span>
            </template>
<!--            <template v-slot:cell(assetNumber)="row">-->
<!--              <div class="show-hand" v-b-tooltip.hover.top="row.item.notes">{{ row.item.assetNumber }}</div>-->
<!--            </template>-->
            <template v-slot:cell(updatedAt)="row">
              {{ Moment(row.item.updatedAt).fromNow() }}
            </template>
          </b-table>
          <hr>
          <!-- <b-button class="btn btn-success btn-sm btn-fill float-right" @click="$router.go(-1)">
            Done
          </b-button> -->
        </div>
        <div class="clearfix"></div>
        <br />
      </div>
      <div v-else style="margin-top:2em;padding-left:2em;">
        No Results
      </div>
    </div>
  </div>
</template>

<script>
import AssetService from "/src/Services/AssetService";
import Currency     from "currency.js/dist/currency.js";
import _            from "lodash";
import moment       from "moment-timezone";
import Card         from "/src/components/UIComponents/Cards/Card.vue";
import SfInput      from "/src/components/UIComponents/Inputs/SFormInput.vue";
import LTable       from "/src/components/UIComponents/Table.vue";

export default {
  components: {
    LTable,
    Card,
    SfInput
  },
  props     : [
    // "searchString" // domain OBJECT
  ],
  data() {
    return {
      saveDisabled   : true,
      searchString   : "",
      filterString: "",
      changeCount    : 0,
      modalTitle     : "Adding New Asset",
      isAdding       : false,
      isEditing      : false,
      isCache: false,
      small          : true,
      assetConfig    : null,
      assetCategories: [],
      assetTypes     : [],
      assetType      : "",
      assetTypeTitle : null,
      asset          : {assetType: "general", assetNumber: "", title: "", category: "", "purchase.price": 0.00},
      columns        : [
        "actions",
        // {key: "assetType", label: "Type", sortable: true},
        // {key: "category", sortable: true},
        {key: "assetNumber", label: "Asset #", sortable: true},
        //{ key: "ownerName", label: "Stakeholder", sortable: true, tdClass:"d-none", thClass: "d-none"  },
        {key: "title", sortable: true},
        {key: "purchase.price", sortable: true , formatter: (v) => { return parseFloat(v).toFixed(2)}},
        {key: "location", sortable: true},
        {key: "serialNumber", label: "Serial #", sortable: true},
        {key: "inventoryTag", label: "Inventory Tag", sortable: true},
        {key: "updatedAt", label: "Last Update", sortable: true}
      ],
      assets         : []
    }
  },
  methods   : {
    selectAssetType() {
      this.assets = [];
      this.$emit("assetTypeChanged", this.assetType );
    },
    addNewAsset() {
      this.asset.assetType = this.assetType;
      this.isAdding        = true;
      this.isEditing       = false;
      this.modalTitle      = 'Adding New Asset'
    },
    async addAsset() {
      var t      = this;
      var found  = false;
      t.isAdding = false;
      try {
        const response             = await AssetService.addAsset("", t.asset);
        t.$root.tenant.assetSearch = t.asset.assetNumber;
        t.isEditing                = false;
        t.hideModal();
        this.editAsset(response.data);
      } catch(err) {
        console.error("getAsset error: ", err);
        t.$root.errorMessage("Oops", "Had an error getting the asset list");
      }
    },
    assetTypeList() {
      if( this.assetConfig ) {
        let l = this.assetConfig.assetTypes.map((a) => { return {text: a.text, value: a.value}});
        return l;
      }
      return [ {text:'undefined' , value: 'undefined'}];
    },
    editAsset(d) {
      var t = this;
      t.$router.push(`/app/assetEdit/${d._id}`);
    },
    async removeAsset(id) {
      var t = this;
      var response;

      if(!await t.$root.confirm("Remove [" + _.find(t.assets, {_id: id}).assetNumber + " :: " + _.find(t.assets, {_id: id}).title + "] ?")) {
        return;
      }
      try {
        response = await AssetService.removeAsset(id);
        let x = _.findIndex(t.$root.tenant.assets, {_id: id}); // (a) => {return a._id === t.asset._id} );
        if(x >= 0) {
          t.$root.tenant.assets.splice( x , 1 );
        }

      } catch(err) {
        console.error("removeAsset error: ", err);
        t.$root.errorMessage("REMOVE ASSET", "Had an error removing this asset.  It may be locked");
      }
    },
    currency(c) {
      return Currency(c);
    },
    has(o, i) {
      return _.has(o, i);
    },
    showModal() {
      this.$refs.addAssetDialog.show()
    },
    hideModal() {
      this.$refs.addAssetDialog.hide()
    },
    Moment(v) {
      return moment(v);
    },
    async getAssetConfig() {
      try {
        if(this.$root.tenant.assetConfig) {
          this.assetConfig = this.$root.tenant.assetConfig;
          this.assetTypes = this.assetConfig.assetTypes;
          return;
        }
        let response                 = await AssetService.getConfig();
        this.assetConfig             = response.data;
        this.assetTypes              = this.assetConfig.assetTypes;
      } catch(error) {
        console.log(error);
      }
    },
     async getAssetCategories() {
      try {
        let response    = await AssetService.getCategories(this.assetType);
        this.assetTypes = response.data;
      } catch(error) {
        console.log(error);
      }
    },
    async searchAssets(s , assetType = this.assetType , exact = null ) {
      var t = this;

      s = '' + s;

      if(s.length === 0) {
        s = "-";
      }

      try {
        t.$root.tenant.assetSearch = s;
        let response               = await AssetService.searchAssets(s, assetType, exact);
        t.$root.tenant.assets      = t.assets = response.data;
        t.isCache = false;
      } catch(err) {
        console.error("searchAsset error: ", err);
        t.$root.errorMessage("Oops", "Had an error searching the asset list");
      }
    }
  },

  mounted: async function() {
    this.assetType = this.$root.tenant.assetType;
    await this.getAssetConfig();

    if(!this.assetType) {
      this.assetType = this.$root.tenant.assetType = this.assetTypes[ 0 ].value;
    }

    if(this.$root.tenant.assets) {
      this.isCache = true;
      this.assets  = this.$root.tenant.assets;
      // console.log( " cache" , this.assets );
      return;
    }

  }
};
</script>

<style lang="scss">

.table-title {
  display:flex;
}

.table-filter {
  padding-top:.5em;
  margin-left:auto;
}

.filter-field {

  padding: 0 1.25em 0 .75em;
  margin-bottom:.5em;
  height: 2.5em;
  border: solid 2px #2f2f2f;
  border-radius: 2em;
  background:#f0f0f0;
  width:200px;
}

//.filter-field:focus {
//  outline: 0;
//  border-color: #007593;
//
//}


</style>
