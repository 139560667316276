<template>
  <div class="container-fluid">
    <div class="login-container">

      <!-- <div class="shadow" style="margin:0 15% 2em 15%;text-align:center;padding:15px;background:rgba(0,75,180,.6);border-radius:6em;"> -->
      <div class="shadow" style="margin:0 15% 2em 15%;text-align:center;padding:15px;background:#7ab63f;border:4px solid #9ad65f;border-radius:3em;">
        <!-- <img src="/static/img/newcare-logo-350.png" alt="Newcare International Logo" height="150" /> -->
        <img src="@/assets/img/icare-cafe-logo-225.png" alt="iCare Cafe by Newcare International" />
      </div>

      <div v-show='domains.length === 0'>
        <b-form class="login-form" @reset="onReset">
          <b-form-group class="login-credentials text-center" label-for="username" :description="message">
            <h3>login</h3>
            <b-form-input @keyup.enter.native="$event.target.nextElementSibling.focus()" id="username" type="email" v-model="username"
              autocomplete="email" required placeholder="Email address">
            </b-form-input>
            <b-form-input @keyup.enter.native="loginClick('')" id="password" type="password" v-model="password" autocomplete="password" required
              placeholder="password">
            </b-form-input>
          </b-form-group>
          <b-checkbox class="remember-me" v-model="rememberMe">Remember Me</b-checkbox>
        </b-form>
        <div >
          <router-link class="login-forgot-password" :to="{path: '/forgotpassword'}">I forgot my password</router-link>
          <b-button @click="loginClick('')" class="btn btn-success btn-fill float-right btn-sm ml-2 mb-3"
            style="margin-right:6em;border-radius:.75em;" variant="success">
            <span v-show="loading" :class="isLoading"></span>
            {{ loginMessage }}
          </b-button>
          <b-button type="reset" class="btn btn-danger btn-fill btn-sm float-right" style="border-radius:.75em;" variant="danger"
            @click="$router.push('/')">Cancel</b-button>
        </div>
      </div>
    </div>

    <div class="row" v-show='domains.length > 0'>
      <div class="col-md-10 ml-auto mr-auto">
        <div style="display:inline-flex;">
          <b-button @click='loginCancel()' variant="danger" class='btn btn-fill'>
            Cancel
          </b-button>
          <h3 style="margin-left:2em;">Please select a company</h3>
        </div>
        <hr>
        <div class="tenent-select" v-for="domain in domains" :key="domain.name">
          <tenant-card class="show-hand app-card" @selectDomain='loginClick($event)' :domain="domain"></tenant-card>
        </div>
      </div>
    </div>

    <footer class="login-footer">
      <div class="container">

        <div class="copyright">
        <span style="float:left;"><a href="/tos">Terms of Services</a></span><span style="float:left;margin-left:2em;"><a href="/privacy">Privacy Policy</a></span>  &copy; Copyright 2018-{{ new Date().getFullYear() }}, Newcare International, (an IOIHAN Company) - {{$root.appVersion}}
        </div>
      </div>
    </footer>

  </div>
</template>

<script>

  import AuthenticationService from "/src/Services/AuthenticationService";
  import TenantCard from "/src/components/UIComponents/Cards/TenantCard";
  import rootLib from "./../Lib/rootLib";

  var t = this;

  export default {
    components: {
      "tenant-card": TenantCard
    },
    data() {
      return {
        title       : "Login",
        rememberMe  : false,
        loginMessage: "Log In",
        loading     : false,
        isLoading   : "fa fa-spinner  fa-refresh-animate",
        username    : "",
        password    : "",
        domains     : [],
        errors      : null,
        message     : "Please provide your email address and password"
      };
    },
    mixins: [rootLib],
    mounted() {

      let lastEmail   = localStorage.getItem('lastEmail') || "";
      this.rememberMe = localStorage.getItem('rememberMe') || false;

      if( this.$route.query.uid ) {     // allows providing on the command line (perhaps not the greatest idea, but for team kiosks AND whitelist, it's reasonable)
        if( this.$route.query.pw ) {
          console.log( "Hit the uid/pw combo");
          this.rememberMe = false;
          lastEmail = "";
          this.username = this.$route.query.uid;
          this.password = this.$route.query.pw;
          this.loginClick("");
        }
      }

      if (this.rememberMe && lastEmail ) {
        let block = JSON.parse(localStorage.getItem(lastEmail));
        if( block ) {
          let lastDomain = block.lastDomain || "";
          if( lastDomain ) {
            this.$root.logIn(block[lastDomain]);
            let newRoute = this.$root._route.query.redirect;
            if( this.$router && newRoute ) {
              this.$router.push(newRoute);
            }
          }
        }
      }
    },
    methods: {

      onReset(e) {
        e.preventDefault();
        this.username = "";
        this.password = "";
        this.$router.push("/");     //  async, so "this" works just fine (instead of using the cached "t")
      },
      loginCancel() {
        this.domains=[];
        this.message="Please provide your email address and password";
      },
      validateBeforeSubmit() { },
      async loginClick(inboundDomain) {
        const t = this;
        const r = this.$root;
        let d = {
          email   : this.username,
          password: this.password
        };
        let response;

        if (typeof inboundDomain === "string") {
          d.domain = inboundDomain;
          t.message = "Please provide your email address and password";
        }

        this.loading      = true;
        this.loginMessage = "Sending...",
        this.token        = "Sending Request...";  // todo: refactor this to the message area.

        localStorage.setItem("rememberMe", this.rememberMe);

        try {
          response          = await AuthenticationService.login(d);
          this.loading      = false;
          this.loginMessage = "Log In";
        } catch (err) {
          this.loading = false;
          this.loginMessage = "Log In";
          if( err.response && err.response.data ) {
            console.log( err.response.data );
            t.$root.errorMessage("Oops", err.response.data.message || "Login Error...");
          } else {
            t.$root.errorMessage( "Oops" , err.message );
          }
          return;
        }

        if (response.status === 203) {  // when several domains exist
          t.message = "Your ID is associated with multiple companies.  Please select one...";
          t.domains = response.data;
        } else {
          if (response.status === 200) {

            if (response.data.accountType !== 1) {
              t.$root.errorMessage("Oops", "Cannot Log in - Invalid USER Type - Must be a USER account");
              return;
            }

            let loginData = response.data;
            loginData.loginId = this.username;
            t.$root.logIn(loginData); // we're in so let the "global" login() funciton setup the environment

            if (response.data.isSA === true) {
              // do some SA setup here if we have any
            }

            if( process.env.NODE_ENV != "development" && LogRocket && response.data.email) {
              LogRocket.init('6rlyyl/icare-ui');
            }

            if( this.rememberMe ) {
              localStorage.setItem('lastEmail', this.username );
              if( process.env.NODE_ENV != 'development') {
                LogRocket.identify( response.data.tenantUser, {
                  name   : response.data.alias,
                  email  : this.userName,
                  isSA   : response.data.isSA,
                  isAdmin: response.data.isAdmin,
                  domain : response.data.domain
                });
              }
            } else {
              localStorage.removeItem( 'lastEmail' );
            }

            if (this.$route.query.redirect) {
              this.$router.push(this.$route.query.redirect);
            } else {
              this.appHome();
            }
          } else {
            t.message = response.data.message;
          }
        }
        return true;
      },

      appHome() {
        this.$router.push("/app");
      },
      home() {
        t.$router.push("/");       // jump to the APP root to start his show
      },
      clearErrors() {
        t.errors.clear();
      }
    }
  };
</script>
<style lang="scss" scoped>

  .login-footer {
    position  : fixed;
    bottom    : 10px;
    width     : 100%;
    text-align: right;
    font-size : .8em;
    color     : gray;
  }

  .login-container {

    margin-top  : 7%;
    margin-left : auto;
    margin-right: auto;
    width       : 500px;

    .login-logo {
      text-align: center;
      margin-bottom: 40px;
    }

    .login-form {
      border        : 1px solid black;
      border-radius : 1em;
      margin-left   : auto;
      margin-right  : auto;
      padding-bottom: 2em;
      margin-bottom : 2em;
      width         : 350px;
    }

    .remember-me {
      text-align:center;
      font-size:.8em;
    }

   .login-credentials {
      margin-left:2em;
      margin-right:2em;
    }

    .login-forgot-password {
      float:left;
      margin-left:4em;
    }
  }

  .fa-refresh-animate {
    -animation: spin .7s infinite linear;
    -webkit-animation: spin2 .7s infinite linear;
  }

  @-webkit-keyframes spin2 {
    from {
      -webkit-transform: rotate(0deg);
    }

    to {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    from {
      transform: scale(1) rotate(0deg);
    }

    to {
      transform: scale(1) rotate(360deg);
    }
  }
</style>
