<template>
  <div class="content ">
    <div class="" style="">

      <div style="width:100%;display:flex;">
        <!-- <i class="fa fa-plus-square show-hand" style="float:right;font-size:1.25em;padding-right:2em;" v-b-popover.hover.top="'Add a new land holding'" @click="newLand()"></i> -->
        <div style="width:40%;">
          <b-button class="btn btn-sm btn-fill" variant="success" style="position:relative;top:18px;border-radius:.75em;float:left;margin-right:1em;" @click="$router.push('/marketing/marketing')">Done</b-button>
          <h3 class="card-title">Model Information</h3>
        </div>
        <div>
        <b-form-group style=""  >
          <b-form-radio v-model="sortOrder" name="byName" value="name" @change="modelSortByName()" >By Name</b-form-radio>
          <b-form-radio v-model="sortOrder" name="byPrice" value="price" @change="modelSortByPrice()">By Initial Fee</b-form-radio>
          <b-form-radio v-model="sortOrder" name="byMonth" value="monthly" @change="modelSortByMonthly()">By Monthly Fee</b-form-radio>
        </b-form-group>
        </div>
        <div>
        <b-checkbox style="margin-left:3em;" v-model="isShowBreakdown">Show Cost Breakdown</b-checkbox>
        </div>
      </div>
      <hr />

      <div class="row" style="width:100%;" v-if="housingConfig">
        <unit-card  v-for="(model,index) in housingConfig.models" 
          :key="index"
          :index="index"
          :title="'Models'"
          :unit="{title:null, roomMode:model.value}"
          :model="model"
          :enableControls="true"
          :enableShowBreakdown="isShowBreakdown"
          :enableUnitStatus="false"
          :enableSelect="true"
          controlsEventName="doAction"
          :unitOptions='[ { name: "Send Information" , slug: "sendInformation" }]'
          @click="modelSelected($event)"
          @doAction="action($event)"
          @sendInformation="sendInformation($event)"
        />

      </div>

    </div>
  </div>

</template>
<script>
  import Moment      from "moment";
  import Card        from '/src/components/UIComponents/Cards/Card.vue'
  import UnitCard        from '/src/components/UIComponents/Cards/UnitCard.vue'
  import SfInput     from "/src/components/UIComponents/Inputs/SFormInput.vue";
  import StatusWidget from '/src/components/UIComponents/Widgets/StatusWidget.vue';
  import RoomService          from "/src/Services/RoomService";
  import TenantService          from "/src/Services/TenantService";

  let roomModel;

  export default {
    components: {
      Card,
      SfInput,
      StatusWidget,
      UnitCard
    },
     props     : {
      roomModel: { type: String, default: 'all'}
    },
    data() {
      return {
        variants         : [ 'primary', 'secondary', 'success', 'warning', 'danger', 'info', 'light', 'dark' ],
        headerBgVariant  : 'dark',
        headerTextVariant: 'light',
        bodyBgVariant    : 'light',
        bodyTextVariant  : 'dark',
        footerBgVariant  : 'warning',
        footerTextVariant: 'dark',
        loading          : false,
        isShowBreakdown  : false,
        sortOrder        : "byName",
        housingConfig    : null,
        occupancyItems   : [],
        units            : [],
        isLoading        : "fa fa-spinner  fa-refresh-animate mr-3",
        type             : [ '', 'info', 'success', 'warning', 'danger' ],
        notifications    : {
          topCenter: false
        }
      }
    },
    async mounted() {
      await this.getHousingConfig();
      // await this.getBuildingOccupancyStats();
      // await this.getRoomsByModel( this.roomModel );
    },
    computed  : {
    },
    methods   : {
      moment(d) {
        return Moment(d);
      },
      modelSelected( model ) {
        // console.log( "Model Selected" , model );
      },
      action( a , b ,c ) {
        // console.log( "action received" ,  a,b,c );
      },
      sendInformation( a,b,c ) {
        // console.log( "send Information event received" , a,b,c);
      },
      modelName( value ) {
        if(! this.housingConfig ) {
          return "*"+value;
        }
        let m = _.find(this.housingConfig.models, {value: value });
        if( m ) {
          return m.text;
        }
        return "**" + value;
      },
      selectModel( model ) {
        // console.log( "selectModel()" , model );
      },
      modelSortByName() {
        let newArray = _.sortBy( this.housingConfig.models , m => m.text );
        this.housingConfig.models = newArray;
      },
      modelSortByPrice() {
        let newArray = _.sortBy( this.housingConfig.models , m => parseFloat(m.prices[0].price) );
        this.housingConfig.models = newArray;
      },
      modelSortByMonthly() {
        let newArray = _.sortBy( this.housingConfig.models , m => parseFloat(m.prices[0].monthlyMaintenance) );
        this.housingConfig.models = newArray;
      },
      getModel( roomModel ) {
        let m = _.find( this.housingConfig.models , { value: roomModel });
        if( m ) {
          return m;
        }
        return { value: '', text: 'Not Found' , coverPhoto: null };
      },
      async getHousingConfig() {
        try {
          let hc =  localStorage.getItem( "housingConfig" );

          if ( hc ) {
            this.housingConfig = JSON.parse(hc);
          } else {
            let response = await TenantService.getHousingConfig();
            this.housingConfig = response.data;
          }

        } catch (error) {
          console.log(error);
        }
      },
      async getRoomsByModel( roomModel ) {

        try {
          let response = await RoomService.getRoomsByModel( roomModel );
          // this.units = response.data;

          // this.units = response.data.map((u) => {
          //   u["coverPhoto"] = "coverphoto.jpeg";
          //   return u;
          // });
          this.units = response.data;
          // console.log( "rooms" , this.units );

        } catch( error ) {
          console.log( error.message )
        }

      },
      async getBuildingOccupancyStats() {

        try {

          let response = await RoomService.getBuildingOccupancyStats( "" , this.housingConfig.unitTypes.map( (u) => { return u.value }).join(","));

          if(response.data) {
            this.occupancyItems = response.data.map((s) => {
              s[ "title" ] = this.modelName(s.roomModel);
              if(s.available <= 0) {
                s[ "itemStyle" ] = "color:gray;"
              }
              return s;
            })
          } else {
            this.occupancyItems = [];
          }

          // console.log( "getBuildingOccupancyStats" , this.occupancyItems , response.data)

        } catch(error) {
          console.log(error.message);
        }
      }
    }
  }
</script>
<style>

</style>
