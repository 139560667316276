<template>
  <div>
    <div class="form-controls" >
      <div v-if="isDataChanged">
        <b-button  class="btn btn-sm btn-fill btn-success checklist-button" @click="save">Save</b-button>
        <b-button class="btn btn-sm btn-fill btn-danger checklist-button" @click="cancel">Cancel</b-button>
      </div>
      <div v-else>
        <b-button class="btn btn-sm btn-fill btn-success checklist-button" @click="done">Done</b-button>
      </div>
      <span style="font-size:1.25em; color:white; margin-left:1em;" >{{ checklist.title }}</span>
    </div>
    <general-information :formData="checklist" :itemName="itemName" @categoryChanged="categoryChanged" @dataChanged="dataChanged" />
    <!-- <hr />

    <div>
      <div>
        <i class="fa fa-plus-square" style="float:left;position:relative;top:4px;margin-right:1em;font-size:1.25em;cursor:pointer;" v-b-popover.hover.top="'Add Step'" @click="addStep"></i>
        <i class="fa fa-question-circle" style="float:right;position:relative;top:4px;margin-right:1em;font-size:1.25em;color:blue;cursor:help;" v-b-popover.hover.top="'Step Help'" @click="helpSteps"></i>
        <h4 class="form-sub-header">{{checklist.category.charAt(0).toUpperCase() + checklist.category.slice(1)}} Steps</h4>
        <p style="font-size:.8em;text-align:left;margin-left:3em;margin-top:-8px;color:gray;">( Click to expand a step, or drag/drop to rearrange. )</p>
      </div>

      <div class="form-steps" style="" role="tablist">
        <draggable v-model="checklist.steps" style="" ghost-class="ghost" @end="stepDropped" >
          <div style="margin-bottom:.25em;" no-body v-for="(step,stepIndex) in checklist.steps" :key="stepIndex" >
            <b-card-header :id="'stepHeader-' + stepIndex" header-tag="header" class=""  style="background:#ddd;padding:.5em;border-radius:.5em;" role="tab">
              <div class="step-header">
                <i class="fa fa-trash"  @click="removeStep(step)"></i>
                <i v-if="step.active"  class="fa fa-check" style="color:green;" @click="step.active=false;dataChanged()"></i>
                <i v-if="!step.active" class="fa fa-times" style="color:red;" @click="step.active=true;dataChanged()"></i>
                <i :class="step.icon"></i>
                <div block v-b-toggle="'step-'+stepIndex"><span>{{step.title}}</span><span>{{step.description}}</span></div>
              </div>
            </b-card-header>
            <b-collapse :id="'step-' + stepIndex"  accordion="my-accordion" role="tabpanel">
              <b-card-body>
                <step-information :formData="step" :iconOptions="iconOptions" :itemName="itemName" @dataChanged="dataChanged" @editImage="editStepImage(step,$event)" />
              </b-card-body>
            </b-collapse>
          </div>
        </draggable>
      </div>
    </div> -->
  </div>
</template>

<script>

/**
 * todo: Fix the "select" when the step window is expanded - it moves the form instead of selecting --
 * todo: Add the sub-item management
 *
*/

import draggable from "vuedraggable";
import GeneralInformation from "./GeneralInformation.vue";
import StepInformation from "./StepInformation.vue";
import _ from "lodash";

export default {
  name: "Checklist",
  components: {
    draggable,
    GeneralInformation,
    StepInformation
  },
  props: {
    checklist   : { type: Object, default: () => {} },
    stepTemplate: { type: Object, default: () => {} },
    iconOptions: { type: Array, default: () => [] },
    itemName: { type: String, default: "Checklist"}
  },
  data() {
    return {
      step          : null,
      isDataChanged : false,
      stepFieldsList: [
        { key: "actions" , label: "" , sortable: true},
        { key: "active" , label: "Active" , sortable: true},
        { key: "sequence" , label: "Seq #" , sortable: true},
        { key: "title" , label: "Action", sortable: true},
        { key: "description" , label: "Description" , sortable: true}
      ]
    };
  },
  methods: {
    dataChanged() {
      this.isDataChanged = true;
      this.$emit( "dataChanged" );
    },
    categoryChanged(category) {
      this.itenMame = category;
    },
    resetDataChanged() {
      this.isDataChanged = false;
    },
    stepDropped() {
      for(let i=0; i<this.checklist.steps.length; i++) {
        this.checklist.steps[i].sequence = (i+1)*100;
      }
      this.dataChanged();
    },
    save() {
      //this.checklist.version = parseInt( this.checklist.version) + 1;
      this.$emit( "save" , this.checklist );
      this.resetDataChanged();
    },
    async cancel() {
      if (this.isDataChanged) {
        if (! await this.$root.confirm("You made changes, continue with cancel?", 'warning')) {
          return;
        }
      }
      console.log("index->cancel()");
      this.$emit( "cancel" , this.checklist );
      this.resetDataChanged();
    },
    done() {
      this.$emit( "done" , this.checklist );
      this.resetDataChanged();
    },
    helpSteps() {
      this.$emit( "stepsHelp" );
    },
    async addStep() {
      if( ! await this.$root.confirm( "Add New Step?" )) {
        return;
      }
      let newStep = Object.assign({}, this.stepTemplate);
      this.checklist.steps.push(newStep);
      this.dataChanged();
    },
    async removeStep(step) {
      if( ! await this.$root.confirm( "Remove [" + step.title + "]" )) {
        return;
      }
      let i = +_.findIndex( this.checklist.steps , { title: step.title , sequence: step.sequence, description: step.description });
      if( i >= 0 ) {
        this.checklist.steps.splice(i,1);
        this.dataChanged();
      }
    },
    stepEditDone() {
      this.step = null;
    },
    editStepImage( step , imageUrl ) {
      // todo: make this do something for real.
      step.imageUrl = "https://cdn.xl.thumbs.canstockphoto.com/branding-cycle-sign-concept-illustration-design-graphic-stock-illustration_csp29101865.jpg";
    },
    addItem() {

    }
  }
};
</script>

<style lang="scss" scoped>
.form-controls {
  display:flex;
  margin-bottom:1em;
  padding:.5em;
  background:#333;
  border-radius:1em;
}

.checklist-button {
  float:left;
  border-radius:1em;
  margin-right:.5em;
}

table {
  text-align:left;
}

.form-sub-header {
  text-align:left;
  padding-left:.5em;
}

.form-steps {
  margin-left:1em;
/*  padding: 1em 0 1em 0 ;*/
}

.step-header {
  padding:.25em;
  text-align:left;
  overflow:auto;
  i {
    float:left;
    text-align:center;
    width:1.5em;
  }
  i:first-child {
    cursor:pointer;
  }
  i:nth-child(2) {
    cursor:pointer;
  }
  div {
    cursor:grab;
    span:first-child {
    margin-left: 1em;
    font-weight: 600;
    font-size:1.25em;
    padding-right: 2em;
  }
    span:nth-child(2) {
      float:right;
      font-weight:100;
      text-decoration:italic;
      font-size:.8em;
    }
  }
}

.ghost {
  opacity: 0.3;
  background: #008080;
}


// .add-step-holder {
//   text-align: right;
//   padding: 0 15px;
// }
// .card-header {
//   background-color: unset;
//   text-align: left;
//   border-bottom: unset;
// }
// .ml-20 {
//   margin-left: 20px;
//   display: flex;
// }
// .btn:focus {
//   box-shadow: unset;
// }
// .pointer:hover {
//   cursor: pointer;
// }
// .btn-text {
//   line-height: 20px;
// }
// .cursor {
//   cursor: move !important;
//   cursor: grab !important;
// }
// @media only screen and (min-width: 320px) and (max-width: 575px) {
//   .ml-20 {
//     margin-left: 0;
//     padding: 0;
//   }
//   .card-body {
//     padding: 0;
//   }
//   .card-header {
//     padding: 0 !important;
//     margin-bottom: 15px;
//   }
// }
// @media only screen and (min-width: 576px) and (max-width: 767px) {
//   .ml-20 {
//     margin-left: 0;
//     padding: 0;
//   }
//   .card-body {
//     padding: 0;
//   }
//   .card-header {
//     padding: 0 !important;
//     margin-bottom: 15px;
//   }
// }
// @media only screen and (min-width: 768px) and (max-width: 991px) {
//   .ml-20 {
//     margin-left: 0px;
//   }
// }
</style>
