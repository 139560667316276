///-------------------------------------------------------------------------------------------------
// summary:	Asset service class
///-------------------------------------------------------------------------------------------------

import Api from '@/Services/Api';

export default {

  getAssets() {
    const response = Api().get(`/api/1/assets`);
    return response;
  },
  getAssetsInRoom(roomNumber) {
    const response = Api().get(`/api/1/assetsInRoom/${roomNumber}`);
    return response;
  },
  searchAssets( searchString , assetType , categoryMatch ) {
    //const response = Api().get(`/api/1/assets/all/${encodeURIComponent(searchString)}`);
    const response = Api().get(`/api/1/assets/${encodeURI(searchString)}/${assetType}` , { params: { categoryMatch: categoryMatch } });
    return response;
  },
  getAsset( id ) {
    const response = Api().get(`/api/1/asset/${encodeURI(id)}`);
    return response;
  },
  getSummary(assetType) {
    if (!assetType) {
      assetType = "";
    }
    const response = Api().get(`/api/1/assetSummaryByCategory/${assetType}`);
    return response;
  },
  getSummaryByType() {
    const response = Api().get(`/api/1/assetSummaryByType`);
    return response;
  },
  addAsset( assetNumber , asset  ) {
    const response = Api().post(`/api/1/asset/${encodeURI(assetNumber)}`, asset );
    return response;
  },
  updateAsset( id ,asset ) {
    return Api().patch(`/api/1/asset/${encodeURI(id)}`, asset );
  },
  removeAsset(id) {
    const response = Api().delete(`/api/1/asset/${encodeURI(id)}`);
    return response;
  },
  getConfig() {
    const response = Api().get(`/api/1/tenantAssetConfig`);
    return response;
  },
  getTypes() {
    const response = Api().get(`/api/1/assetTypes`);
    return response;
  },
  getCategories(assetType) {
    if (!assetType) {
      assetType = "";
    }
    const response = Api().get(`/api/1/assetCategories/${assetType}`);
    return response;
  }
}
