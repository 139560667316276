///-------------------------------------------------------------------------------------------------
// summary:	Log service class
///-------------------------------------------------------------------------------------------------

import Api from '@/Services/Api';


export default {

 

  authorizeMerchant( authToken, merchant="myschool", provider="shift4" ) {
    return Api().post( '/api/1/payments/authorizeMerchant' , { authToken:authToken, ccMerchant:merchant, provider:provider } );
  },
  deAuthorizeMerchant() {
    return Api().post( '/api/1/payments/deAuthorizeMerchant' , {});
  },
  getMerchantInformation() {
    return Api().get('/api/1/payments/merchantInformation');
  },

  providerAuthorizeMerchant( providerId, authToken, merchant="myschool") {
    return Api().post( `/api/1/payments/providerAuthorizeMerchant/${providerId}` , { authToken:authToken, ccMerchant:merchant } );
  },
  providerDeAuthorizeMerchant( providerId ) {
    return Api().post( `/api/1/payments/providerDeAuthorizeMerchant/${providerId}` , {});
  },
  providerGetMerchantInformation( providerId ) {
    return Api().get(`/api/1/payments/providerMerchantInformation/${providerId}`);
  },

}
