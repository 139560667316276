<template>
  <div class="">
    <div class="row" v-if="!calendar">
      <card class="col col-md-10 ml-auto mr-auto shadow noprint">
        <template slot="header">

          <h3 class="card-title">
            Production Summary - Calendar Selection
            <button class="float-left mr-3 btn btn-sm btn-success btn-fill"
                    @click="$router.push('/app');">
              <span v-show="loading" :class="isLoading"></span>Done
            </button>
          </h3>
          <hr />
        </template>
        <div class="row">
          <div class="col-md-12">
            <button class="float-right mt-0 mr-3 btn btn-default btn-sm btn-fill" style="border-radius:.75em;" v-b-popover.hover.top="'Get ALL Calendars, Even Expired Ones'" @click="getOrderCalendars('all')">
              <span v-show="loading" :class="isLoading"></span>Show Expired Calendars
            </button>
            <h4 class="mt-0">Select the calendar</h4>
            <b-table ref="mytable"
                     v-if="orderCalendars.length>0"
                     striped
                     hover
                     class="show-hand"
                     :items="orderCalendars"
                     :fields="fields"
                     @row-clicked="getOrderCalendar">
              <template v-slot:cell(title)="data">
                {{data.item.title}}
              </template>

              <template v-slot:cell(active)="data">
                {{(data.item.active===true) ? 'Yes' : 'No'}}
              </template>
              <template v-slot:cell(activeDate)="data">
                {{ (data.item.activeDate) ? moment(data.item.activeDate).format("MMM Do, YY") : 'Not Set' }}
              </template>
              <template v-slot:cell(calendarStartDate)="data">
                {{(data.item.calendarStartDate) ? moment(data.item.calendarStartDate).format("MMM Do, YY") : 'Not Set' }}
              </template>
              <template v-slot:cell(calendarEndDate)="data">
                {{(data.item.calendarEndDate) ? moment(data.item.calendarEndDate).format( "MMM Do, YY") : 'Not Set' }}
              </template>
              <template v-slot:cell(updatedAt)="data">
                {{moment(data.item.updatedAt).tz($root.TZ).fromNow()}}
              </template>
            </b-table>
          </div>
        </div>
      </card>
    </div>

    <div class="row" v-if="calendar">
      <card style="border-radius:1em;" class="col-md-4 ml-auto mr-auto shadow noprint">
        <button class="float-left mt-0 mr-2 btn btn-sm btn-success btn-fill"
                style="border-radius:.75em;"
                @click="$router.push('/app')">
          <span v-show="loading" :class="isLoading"></span>Done
        </button>
        <div>
          <h3 class="mt-1" style="text-align:center">Calendar</h3>

          <sweet-calendar :eventCategories="eventCategories"
                          :events="events"
                          :initialDate="calendar.calendarStartDate"
                          :beginDate="calendar.calendarStartDate"
                          :endDate="calendar.calendarEndDate"
                          cursor="pointer"
                          :selectedDate="selectedDay"
                          :enableControls="enableCalendarControls"
                          eventCursor="crosshair"
                          @click="selectDay($event)"
                          ref="calendar" />
        </div>
      </card>

      <card class="col-md-7 ml-auto mr-auto shadow noprint" style="border-radius:1em;">
        <template slot="header">
          <h3 class="card-title">Summary Information</h3>
          <div v-if="!items">
            <hr />
            <p>Choose a calendar day</p>
          </div>
          <div v-if="items">
            <div style="width:200px;display:inline-block;vertical-align:top;">
              <h4>Schedule Selection</h4>
              <ul style="display:grid;list-style:none;padding:0;margin:0;">
                <li v-for="(category,catIndex) in scheduleCategories" :key="catIndex" @click="getOrdersSummary(productionDate, category)" class="show-hand btn btn-sm btn-fill btn-info" style="border-radius:1em;margin:5px;font-size:1em;"> {{ capFirst(category.split('-')[1]) }}</li>
              </ul>
            </div>
            <div style="padding-left:40px;width:200px;display:inline-block;vertical-align:top;" @change="saveSelections()">
              <h4>Include</h4>
              <b-checkbox v-model="selection.menu">Daily Menu</b-checkbox>
              <b-checkbox v-model="selection.drinks">Drinks</b-checkbox>
              <b-checkbox v-model="selection.always">Always Available</b-checkbox>
              <b-checkbox v-model="selection.condiments">Condiments</b-checkbox>
              <b-checkbox v-model="selection.showDescription">Show Description</b-checkbox>
              <br>
              <b-checkbox v-model="selection.byPriceLevel">Group Including Price Level</b-checkbox>
            </div>
          </div>
        </template>
      </card>
    </div>
    <div class="row" v-if="calendar">
      <div  v-if="items" class="col-sm-12 col-md-11 ml-auto mr-auto shadow printable" style="border-radius:1em;">
        <h3 class="card-title">
          Production Summary For: {{productionDateTitle}}
          <b-button v-if="items"
                    class="btn btn-sm btn-fill noprint"
                    style="border-radius:.75em;float:right;font-size:.5em;"
                    @click="sendSummary()">Send it to me</b-button>
        </h3>
        <hr />

        <template v-if="items">
          <div ref="productionSchedule">
            <div v-for="(schedule,scheduleIndex) in schedules" :key="scheduleIndex">

              <div style="padding-left:20px;margin:0;background-color:#ddd;border-radius:.5em;">
                <h3 style="margin:0;padding-top:4px;">{{scheduleTitle( schedule )}}</h3>
                <p style="margin-top:0;padding-left:20px;padding-bottom:2px;">{{scheduleDescription( schedule )}}</p>
              </div>

              <div v-if="selection.menu" style="">
                <div style="margin-left:30px;">
                  <h4 style="margin-bottom:0;border-bottom:1px solid black;">Daily Menu</h4>
                  <table>
                    <tr v-for="(mi) in filteredOrders(schedule,['meal','alacarte'])" :key="mi._id" style="border-bottom:1px solid black;" :style="(mi.priceLevel===0) ? 'background-color:yellow;color:black;' : ''">
                      <td style="font-size:1.2em;width:50px;text-align:center;">{{mi.qty}}</td>
                      <td style="width:50px;" ><span v-if="mi.priceLevel===0" style="font-size:.8em;padding-left:3px;">pre-k</span></td>
                      <td style=""><div style="font-weight:700;margin-bottom:0;">{{mi.title}}<span style="padding:0 20px;font-size:.8em;font-style:italic;">({{mi.itemNumber}})</span></div><div v-if="selection.showDescription"  style="font-style:italic;margin-top:0;font-size:.8em;padding-left:20px;">{{mi.description}}</div></td>
                    </tr>
                  </table>
                </div>
              </div>

              <div v-if="selection.drinks">
                <div style="margin-left:30px;">
                  <h4 style="margin-bottom:0;border-bottom:1px solid black;">Drinks</h4>
                  <table>
                    <tr v-for="(mi) in filteredOrders(schedule,['drinks'])" :key="mi._id" style="border-bottom:1px solid black;" >
                      <td style="font-size:1.2em;width:50px;text-align:center;">{{mi.qty}}</td>
                      <td style="width:50px;"><span></span></td>
                      <td style=""><div style="font-weight:700;margin-bottom:0;">{{mi.title}}<span style="padding:0 20px;font-size:.8em;font-style:italic;">({{mi.itemNumber}})</span></div><div v-if="selection.showDescription" style="font-style:italic;margin-top:0;font-size:.8em;padding-left:20px;">{{mi.description}}</div></td>
                    </tr>
                  </table>
                </div>
              </div>

              <div v-if="selection.always">
                <div style="margin-left:30px;">
                  <h4 style="margin-bottom:0;border-bottom:1px solid black;">Always Available</h4>
                  <table style="">
                    <tr v-for="(mi) in filteredOrders(schedule,['always'])" :key="mi._id" style="border-bottom:1px solid black;">
                      <td style="font-size:1.2em;width:50px;text-align:center;">{{mi.qty}}</td>
                      <td style="width:50px;"><span></span></td>
                      <td style=""><div style="font-weight:700;margin-bottom:0;">{{mi.title}}<span style="padding:0 20px;font-size:.8em;font-style:italic;">({{mi.itemNumber}})</span></div><div v-if="selection.showDescription"  style="font-style:italic;margin-top:0;font-size:.8em;padding-left:20px;">{{mi.description}}</div></td>
                    </tr>
                  </table>

                  <!--<div v-for="(mi) in filteredOrders(schedule,['always'])" :key="mi._id">
                    <div style="border-bottom:1px solid black;padding:2px;">
                      <div style="display:inline-block;width:100%;">
                        <div style="display:inline-block;font-size:1.2em;width:40px;text-align:center;">{{mi.qty}}</div>
                        <div style="display:inline-block;font-weight:700;">{{mi.title}}</div>
                        <div style="display:inline-block;font-size:.8em;font-style:italic;">({{mi.itemNumber}})</div>
                      </div>
                      <div style="font-style:italic;font-size:.8em;padding-left:50px;">{{mi.description}}</div>
                    </div>
                  </div>-->

                </div>
              </div>

              <div v-if="selection.condiments">
                <div style="margin-left:30px;">
                  <h4 style="margin-bottom:0;border-bottom:1px solid black;">Condiments</h4>
                  <table>
                    <tr v-for="(mi) in filteredOrders(schedule,['condiments'])" :key="mi._id" style="border-bottom:1px solid black;" >
                      <td style="font-size:1.2em;width:50px;text-align:center;">{{mi.qty}}</td>
                      <td style="width:50px;"><span></span></td>
                      <td style=""><div style="font-weight:700;margin-bottom:0;">{{mi.title}}<span style="padding:0 20px;font-size:.8em;font-style:italic;">({{mi.itemNumber}})</span></div><div v-if="selection.showDescription" style="font-style:italic;margin-top:0;font-size:.8em;padding-left:20px;">{{mi.description}}</div></td>
                    </tr>
                  </table>
                </div>
              </div>

              <div style="padding:20px;"></div>

            </div>
          </div>
        </template>

      </div>
    </div>
  </div>
</template>
<script>
  import OrderService from "/src/Services/OrderService";
  import RotationService from "/src/Services/RotationService";
  import ScheduleService from "/src/Services/ScheduleService";
  import GeneralService from "/src/Services/GeneralService";
  import Moment from "moment";
  import Card from "/src/components/UIComponents/Cards/Card.vue";
  import MenuItemProductionCard from "/src/components/UIComponents/Cards/MenuItemProductionCard.vue";
  import SfInput from "/src/components/UIComponents/Inputs/SFormInput.vue";
  import { Calendar } from "vue-sweet-calendar";
  import "vue-sweet-calendar/dist/SweetCalendar.css";

  export default {
    components               : {
      Card,
      MenuItemProductionCard,
      SfInput,
      SweetCalendar          : Calendar
    },
    data() {
      return {
        isLoading            : "fa fa-spinner  fa-refresh-animate mr-3",
        loading: false,
        selection: {
          menu        : true,
          condiments  : true,
          drinks      : true,
          always      : true,
          showDescription: true,
          byPriceLevel: false
        },
        items                : null,
        productionDateTitle  : "(Select Day)",
        productionDate       : null,
        numberProductionItems: 0,
        menuItemCategories   : [],
        orderCalendars       : [],
        calendar             : null,
        selectedDay: new Date(),
        day                  : null,
        schedules            : [],
        scheduleItems        : [],
        scheduleItem         : null,
        rotation             : null,
        fields               : [
          // {key            : "action", label: "Action", sortable: false},
          { key              : "active", label: "Active", sortable: false },
          { key              : "title", label: "Item Name", sortable: true },
          { key              : "activeDate", label: "Show Begin", sortable: true },
          { key              : "calendarStartDate", label: "Start Date", sortable: true },
          { key              : "calendarEndDate", label: "End Date", sortable: true },
          { key              : "updatedAt", label: "Last Update", sortable: true },
          { key              : "lastUpdateBy", label: "By", sortable: true }
        ],
        eventCategories      : [
          {
            id: 1,
            title: "No Items",
            textColor: "white",
            backgroundColor: "#39a6c2"
          },
          {
            id: 2,
            title: "One Item",
            textColor: "white",
            backgroundColor: "#a0e14f"
          },
          {
            id: 3,
            title: "Two Items",
            textColor: "white",
            backgroundColor: "#7fb847"
          },
          {
            id: 4,
            title: "More Than 2",
            textColor: "white",
            backgroundColor: "#ff813a"
          },

          {
            id: 5,
            title: "Unassigned",
            textColor: "white",
            backgroundColor: "#555555"
          }
        ],
        events: [
          // {
          //   title     : 'Week 1',
          //   start     : '2019-08-05 00:00 cst',
          //   end       : '2019-08-09 00:00 cst',
          //   repeat    : 'never',
          //   categoryId: 4
          // }
        ]
      };
    },
    async created() {
      // this.getPendingAccounts();
      this.getSelections();   // this first BEFORE we get the default orders so we can know if to get price levels or not.
      await Promise.all([
        // this.getMenuItemCategories(),
        this.getOrderCalendars(""),
        this.getScheduleCategories("myschool-")
        // this.getScheduleItems()
        //this.getMenuItemCategories(),
      ]);

    },
    computed: {},
    methods: {
      moment(d) {
        return Moment(d);
      },
      capFirst(s) {
        if (typeof s !== 'string') return s;
        return s.charAt(0).toUpperCase() + s.slice(1)
      },
      saveSelections() {
        // console.log("Save Selections", this.selection);
        localStorage.setItem("productionSelections", JSON.stringify(this.selection));
      },
      getSelections() {
        let s = localStorage.getItem("productionSelections");
        if (s) {
          this.selection = JSON.parse(s);
        }
      },
      filteredOrders(scheduleId, categories) {
        let o = this.items.filter(item => ((item.scheduleId === scheduleId) && (categories.includes( item.itemCategory ))));
        // let o = this.items.filter(function( a ) {
        //   return ( a.scheduleId === scheduleId && a.itemCategory === category )
        // });
        this.numberProductionItems = o.length;
        // console.log("Filtered Orders", o);
        return o;
      },
      scheduleTitle(id) {
        let s = _.find(this.scheduleItems, { scheduleId: id });
        if (s) {
          return s.title;
        } else {
          return "Schedule ID not found";
        }
      },
      scheduleDescription(id) {
        let s = _.find(this.scheduleItems, { scheduleId: id });
        if (s) {
          return s.description;
        } else {
          return "Schedule ID not found";
        }
      },
      async sendSummary() {
        if (!confirm("Do you want this summary emailed to you?")) {
          return;
        }

        let subject = "Production Summary For: " + this.productionDateTitle;
        let result = `<style type="text/css"> </style>` + this.$refs.productionSchedule.innerHTML;

        result += `<br><br><br><br><hr><small>iCare Cafe by Newcare International (an IOIHAN Company)<br>Visit us online at www.newcare.international</small>`


        await GeneralService.sendMail(null, subject, result);
        alert("Report Sent")
    },
    selectDay(day) {
      let d = Moment(day).format("YYYY-MM-DD");
      this.selectedDay = day;
      this.productionDateTitle = Moment(day).format("dddd, Do [of] MMMM");
      this.productionDate = d;
      this.getOrdersSummary(d , this.scheduleCategories[0] );
    },
    async getOrdersSummary(day, schedule, email = false) {
      var response;
      var t = this;
      t.day = day;
      t.loading = true;

      try {
        await this.getScheduleItems(schedule);
        response   = await OrderService.getOrdersSummaryBySchedule( this.calendar.calendarNumber, day , schedule , this.selection.byPriceLevel );
        this.items = response.data;
        this.schedules      = _.uniq(this.items.map(x => x.scheduleId));

        // console.log( "getOrdersSummary()" , this.schedules, this.items );

        t.loading = false;
      } catch (err) {
        if (err) {
          console.error(err);
          t.$root.errorMessage("Oops", err.response.data.message);
        }
      }
      t.loading = false;
    },
    buildEvents(calendar) {
      this.events = [];

      const NoItem = 1;
      const OneItem = 2;
      const TwoItem = 3;
      const More = 4;

      //        console.log("TZ=", this.$root.TZ);

      for (let m of calendar.menu) {
        var cat;
        if (m.choices.length > 0) {
          // complete?
          if (m.choices.length > 3) {
            cat = More;
          } else {
            cat = m.choices.length + 1;
          }
        } else {
          cat = 1;
        }

        // console.log( "buildEvents()" , this.moment(m.menuDate).tz(this.$root.TZ).format( "YYYY-MM-DD").toString(), typeof this.moment(m.menuDate).tz(this.$root.TZ).format( "YYYY-MM-DD").toString())
        //          console.log("buildEvents()", m.menuDate, typeof m.menuDate, this.moment(m.menuDate).tz(this.$root.TZ).format("YYYY-MM-DD").toString())

        let event = {
          title     : m.title,
          start     : this.moment(m.menuDate).tz(this.$root.TZ).add(12, "hours"),
          end       : this.moment(m.menuDate).tz(this.$root.TZ).add(12, "hours"),
          repeat    : "never",
          categoryId: cat
        };

        //          console.log(JSON.stringify(event, null, 2))
        this.events.push(event);
      }
    },
    hydrateOrderCalendar(calendar) {
      this.isCalendarChanged      = false;
      this.calendar               = calendar;
      this.enableCalendarControls = false;

      try {
        this.buildEvents(calendar);
        if ( new Date(calendar.calendarStartDate).getMonth() !== new Date(calendar.calendarEndDate).getMonth() ) {
          this.enableCalendarControls = true;
        }
      } catch (error) {
        // todo: tell the user something about this issue.
        console.error(error);
      }
    },
    async getRotation( id ) {
      try {
        let response = await RotationService.getRotation(id);
        this.rotation = response.data;

        let r = this.rotation.rotations;

        this.classrooms.map((c) => {
          if( ! _.find( r , { classroom: c.siteId })) {
            this.rotation.rotations.push( { classroom: c.siteId , deliveryLocation: "" });
            this.dataChanged();
          }
        })

        this.rotation.rotations = _.orderBy( this.rotation.rotations , ["classroom"], ["asc"] );

        if( this.rotation.rotationId.indexOf( "(copy)" ) >= 0 ) {
          this.isEditId = true;
        }

      } catch (error) {
        console.log(error.message);
      }
    },
    async getOrderCalendars(all) {
      let t = this;
      t.loading = true;

      try {
        let response = await OrderService.getOrderCalendars(all);
        t.orderCalendars = response.data;
      } catch (err) {
        if (err) {
          console.error(err);
          t.$root.errorMessage("Oops", err.response.data.message);
        }
      }
      t.loading = false;
    },
    async getOrderCalendar(calendarRow) {
      let id = calendarRow._id;
      try {
        let response = await OrderService.getOrderCalendar(id);
        this.hydrateOrderCalendar(response.data);
      } catch (error) {
        console.error(error);
      }
    },
    async getMenuItemCategories() {
      var t = this;
      try {
        let response = await MenuItemService.getMenuItemCategories();
        this.menuItemCategories = response.data;
      } catch (err) {
        if (err) {
          console.error(err);
          t.$root.errorMessage("Oops", err.response.data.message);
        }
      }
    },
    async getScheduleItems( schedule ) {
       try {
          let response = await ScheduleService.getScheduleItems(schedule);
          this.scheduleItems = response.data;
          this.isScheduleChanged = false;
//console.log( "just got scheduleItems" , this.scheduleItems );
        } catch (error) {
          console.log(error.message);
        }
      },
    async getScheduleCategories( filter ) {
       try {
          let response = await ScheduleService.getScheduleCategories( filter );
          this.scheduleCategories = response.data;
          this.isScheduleChanged = false;
//console.log( "just got scheduleCategories" , this.scheduleCategories );
        } catch (error) {
          console.log(error.message);
        }
    }
  }
};
</script>
<style lang="scss" id="myStyle">

  @media print {

    @page {
      position:static;
      margin-top: 2cm;
      margin-bottom: 2cm;
      margin-left: 2cm;
      margin-right: 2cm;
      height: 100%;
    }

    .main-background {
      height: auto;
    }

    nav-bar, notifications, content-footer, mobile-menu, side-bar  {
      display:none;
    }
     body {
      margin: 0;
      color: #000;
      background-color: #fff;
      height: auto;
    }
    .noprint {
      display: none;
    }

    h1 {
      page-break-before: always;
      break-before: always;
    }

    table {
      page-break-inside: avoid;
      break-inside: avoid;
    }

    .printable {
      position:static;
      top:0;
      left:0;
      display:block;
      height: 100%;
    }

    .production-card {
      background-color: #fff;
    }


  }

  .production {
    background-color: #eee;
    border-radius: 1em;
    padding: 10px;
    margin-bottom: 15px;
  }

  .production-card {
    display: grid;
    grid-template-columns: 1fr 6fr;
    /*grid-template-rows   : 1fr;*/
    grid-template-areas: "title detail";
    width: 100%;
    /*border:1px black solid;*/
    /*background-color: salmon;*/
    margin-bottom: 20px;
    /*border-top: 1px solid black;*/
  }

  .production-title {
    grid-area: title;
    height: 100%;
  }

    .production-title p {
      -webkit-transform-origin: 0 0;
      -moz-transform-origin: 0 0;
      transform-origin: 20px -10px;
      -webkit-transform: rotate(90deg) translateY(-20px);
      transform: rotate(90deg) translateY(-20px);
      /*background-color:green;*/
      font-style: italic;
      font-size: .7em;
    }

  .production-title-text {
    /*display:cell;*/
    -webkit-transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    transform-origin: -20px 20px;
    -webkit-transform: rotate(90deg) translateY(-6em);
    transform: rotate(90deg) translateY(-6em);
    width: 100%;
    text-align: center;
    /*background-color        : aqua;*/
    font-size: 1.5em;
    font-weight: 800;
    padding: 15px;
  }

  .production-body {
    display: grid;
    grid-area: detail;
    grid-template-columns: 0.3fr 1.2fr;
    grid-template-rows: 1fr;
    grid-template-areas: "g1 g2";
    /*background-color: red;*/
    width: 100%;
  }

  .production-subtitle {
    display: grid;
    /*background-color: green;*/
    text-align: right;
    vertical-align: top;
    padding-right: 2em;
    margin-bottom: 1em;
    margin-right: 1em;
    /*border-bottom:1px black solid;*/
  }

  .production-detail {
    grid-area: g2;
    /*background-color:yellow;*/
  }

  .production-items {
    /*background-color: wheat;*/
    /*border-bottom: 1px black solid;*/
    padding-bottom: 1em;
    margin-bottom: 1.5em;
    margin-right: 1em;
  }
</style>
