<template>
  <div class="mt-4" id="printable-calendar">
    <div class="row" style="margin-bottom:.75em;">

      <div class="col-lg-7 col-md-12 pl-0 pr-1">
        <slot name="title">
          <h2 class="calendar-heading">{{ title }}</h2>
        </slot>
        <div style="display:flex;">
          <b-checkbox v-if="isAllowList" size="sm" switch type="checkbox" class="noprint calendar-list-option" v-model="isList" @change="updateListState">Show as List</b-checkbox>
          <template v-if="!isList && isEnableToggles">
            <b-checkbox class="noprint calendar-list-option" size="sm" switch v-model="isShowDescription">Show Descriptions</b-checkbox>
            <b-checkbox class="noprint calendar-list-option" size="sm" switch v-model="isShowQty">Show Qty</b-checkbox>
            <b-checkbox class="noprint calendar-list-option" size="sm" switch v-model="isShowTotal">Show Totals</b-checkbox>
          </template>
        </div>
      </div>
      <div class="col-lg-5 col-md-12">
        <div class="calendar-actions" style="min-width:60%;">
          <div class="" style="margin-bottom:.5em;">
            <h2 class="calendar-month-year" :style="(isEnableSelectDate) ? 'cursor:pointer;' : ''" @dblclick="dblClickDay($event)" @click="selectDate()">
              {{ currentMonthName }}<span>{{ selectedYear }}</span></h2>
          </div>
          <div class="noprint print-button" @click="printCalendar('printable-calendar')" v-if="isEnablePrintCalendar"><i class="fa fa-print"></i></div>
          <div v-if="!isCalendarMode">
            <b-button v-if="calendarStartMonth!==calendarEndMonth" class="noprint calendar-button" @click="gotoToday()">Today</b-button>
            <b-button class="noprint calendar-button" @click="selectNextMonth(-1)" :disabled="isDisabledDecrementMonth()" variant="light" v-if="config.enablePrevButton && !(calendarStartMonth === calendarEndMonth)">
              <i class="fa fa-chevron-left"></i></b-button>
            <b-button class="noprint calendar-button" @click="selectNextMonth(1)" :disabled="calendarEndMonth === selectedMonth" variant="light" v-if="config.enableNextButton && !(calendarStartMonth === calendarEndMonth)">
              <i class="fa fa-chevron-right"></i></b-button>
          </div>
          <div v-if="isCalendarMode">
            <b-button class="noprint calendar-button" :disabled="isThisMonth()" @click="gotoToday()">Today</b-button>
            <b-button class="noprint calendar-button" @click="selectNextMonth(-1)" variant="light"><i class="fa fa-chevron-left"></i></b-button>
            <b-button class="noprint calendar-button" @click="selectNextMonth(1)" variant="light"><i class="fa fa-chevron-right"></i></b-button>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="!isList">
      <!-- {{  events }} -->
      <div class="col-12">
        <div class="row">
          <div class="col-2 calendar-days calendar-days-bottom" :class="'calendar-'+day.toLowerCase()" v-for="(day, index) in days" :key="'days'+index">
            {{ day }}
          </div>
        </div>
      </div>
      <div class="col-2 calendar" :class="index === 1 ? 'day-container-sunday' : ''" v-for="index in emptyDays" :key="'emp'+index">
      </div>
      <div class="col-2 calendar calendar-body" :class="[dayContainerClass(index),isToday(index) ? 'calendar-today' : '']" @click="clickBody(index)" v-for="(items, index) in filterData" :key="'event='+index">

        <div class="calendar-date" @click.stop="selectDay(index)">{{ index + 1 }}</div>
        <div class="calendar-text">
          <div v-for="(item, itemIndex) in items" :key="'item-'+itemIndex">
            <div v-if="itemIndex<maxItemsPerDay" class="calendar-item" :class="itemIndex==0 ? 'no-border' : ''" @click.stop="selectItem(item)">
              <div v-if="isShowTime && !item.allDay" class="item-time">{{ moment(item.eventDate).tz(timeZone).format("hh:mma") }}</div>
              <div v-if="isShowTime && item.allDay" class="item-time">{{ allDayPrompt }}</div>
              <slot name="detailItem" v-bind="item">
                <div class="calendar-title" :class="item.allDay ? 'item-allday' : ''" :style="item.tStyle" v-b-popover.hover.top="{title:item.title, content:item.description, delay:1000}">
                  <i v-if="item.icon" :class="item.icon"></i>
                  {{item.title}}
                </div>
                <span v-if="isShowDescription && item.description" class="item-description" :style="itemStyle(item)">{{ item.description }}</span>
                <div v-if="isShowValue && item.value" class="item-value">{{ item.value }}</div>
                <div v-if="isShowQty && _.get(item , qtyField)" class="item-total">{{qtyPrompt}}{{ _.get(item,qtyField) }}</div>
                <div v-if="isShowTotal && item[totalField]" class="item-total">{{totalPrompt}}{{ USD( item[totalField]) }}</div>
                <div v-if="isShowPrice && item.price" class="item-price">{{ currency(item.price) }}</div>
                <template v-if="isShowItems && item.items">
                  <div v-for="(subItem, index) in item.items" :key="'itemx-'+index" class="subitem">
                    <span class="subitem-title">{{ subItem }}</span>
                  </div>
                </template>
              </slot>
            </div>
            <div style="text-align:right;" v-if="itemIndex==maxItemsPerDay" @click.stop="selectMore(index+1)">
              {{ morePrompt }}
            </div>
          </div>
        </div>
      </div>

    </div>
    <div v-if="isList" style="margin:1em 0 0 1em;">

      <div v-for="(items,itemsIndex ) in filterData" :key="itemsIndex" class="calendar-list-day">

        <div v-if="items && items.length>0">
          <h5>
            {{ moment((items[ 0 ].eventDate) ? items[ 0 ].eventDate : items[ 0 ].startDate).tz(timeZone).format("dddd, MMMM, Do, YYYY") }}
          </h5>

          <table class="greyGridTable" style="width:600px;">
            <thead>
            <tr>
              <th style="text-align:center;">Time</th>
              <th style="padding-left:.5em;">Title</th>
              <th style="text-align:right;padding-right:.5em;">Price</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item,itemIndex) in items" :key="itemIndex" class="show-hand" @click="selectItem(item)">
              <td style="width:10em;">
                <template v-if="isShowTime && !item.allDay">{{ moment(item.eventDate ? item.eventDate : item.startDate).tz(timeZone).format("hh:mm a") }}</template>
                <template v-if="isShowTime && item.allDay">{{ allDayPrompt }}</template>
                <template v-if="!isShowTime">{{ moment(item.eventDate ? item.eventDate : item.startDate).tz(timeZone).format("LL") }}</template>
              </td>
              <td style="text-align:left;padding-left:1em;">{{ item.title }}
                <template v-if="isShowDescription" style="font-weight:400;font-size:.9em;"><br>{{ item.description }}</template>
              </td>
              <td style="text-align:right;padding-right:1em;">
                <template v-if="isShowValue && item.value">{{ item.value }}</template>
                <template v-if="isShowQty && item[qtyField]" class="item-total">{{qtyPrompt}}{{ toFixed( item[qtyField],0) }}</template>
                <template v-if="isShowTotal && item[totalField]">{{totalPrompt}}{{ USD(item[totalField]) }}</template>
                <template v-if="isShowPrice && item.price">{{ currency(item.price) }}</template>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Moment   from "moment-timezone"
import Currency from "currency.js"

export default {
  name      : 'simple-calendar',
  components: {},
  props     : {
    id                   : {type: String, default: "calendar"},
    title                : {type: String, default: null},
    isCalendarMode       : {type: Boolean, default: false},                                                             // this only loads ONE calendar
    calendarDate         : {type: Object},                                                                              // set when we're in "calendar" mode,
    morePrompt           : {type: String, default: "more..."},
    allDayPrompt         : {type: String, default: "All Day"},
    totalPrompt          : {type: String, default: ""},
    totalField           : {type: String, default: "total"},
    qtyPrompt            : {type: String, default: ""},
    qtyField             : {type: String, default: "qty"},
    timeZone             : {type: String, default: null},
    isEnableSelectDate   : {type: Boolean, default: true},
    // isEnableMonthYearSelect    : {type: Boolean, default: false},   // when this is false, and enableDateSelect is true, generates an event, otherwise it's handled inside
    isEnablePrevNext     : {type: Boolean, default: false},
    isWeekdaysOnly       : {type: Boolean, default: false},
    isShowItems          : {type: Boolean, default: false},
    isShowDescription    : {type: Boolean, default: true},
    isShowTime           : {type: Boolean, default: true},
    isShowPrice          : {type: Boolean, default: false},
    isShowValue          : {type: Boolean, default: false},
    isShowQty            : {type: Boolean, default: false},
    isShowTotal          : {type: Boolean, default: false},
    isEnablePrintCalendar: {type: Boolean, default: true},
    isEnableToggles      : {type: Boolean, default: false},
    isAllowList          : {type: Boolean, default: false},
    isListView           : {type: Boolean, default: null},
    maxItemsPerDay       : {type: [ String, Number ], default: 99},
    maxDescriptionLines  : {type: [ String, Number ], default: null},
    config               : {type: Object, default: () => { return {enablePrevButton: true, enableNextButton: true}}},
    events               : {type: Array, default: () => []},
  },
  data() {
    return {
      // Moment,
      selectedMonth     : 0,                                                                                // Moment().month() + 1, // current Month
      selectedYear      : 0,                                                                                // Moment().year(), //current year /
      sortedEvents      : null,
      days              : [ 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday' ],
      calendarFirstDate : null,
      calendarLastDate  : null,
      calendarStartMonth: null,                                                                             //(Moment(this.eventsFirstDate()).month()+1),
      calendarEndMonth  : null,                                                                             // (Moment(this.eventsLastDate()).month()+1),
      isList            : null

    }
  },
  watch: {
    calendarDate: {
      immediate: true,
      handler(oldValue, newValue) {
        this.sortEvents();
        this.selectedMonth = parseInt(Moment.tz(this.calendarDate,this.timeZone).month()) + 1;
        this.selectedYear  = parseInt(Moment.tz(this.calendarDate,this.timeZone).year());
      }
    },
    events      : {
      immediate: true,
      handler(oldValue, newValue) {
        this.sortEvents();
      }
    },
  },
  mounted() {
    if(this.isCalendarMode) {
      this.selectedMonth = parseInt(Moment.tz(this.calendarDate,this.timeZone).month()) + 1;
      this.selectedYear  = parseInt(Moment.tz(this.calendarDate,this.timeZone).year());
    } else {
      this.selectedMonth = parseInt(Moment.tz(this.eventsFirstDate(),this.timeZone).month()) + 1;
      this.selectedYear  = parseInt(Moment.tz(this.calendarFirstDate,this.timeZone).year());
      this.eventsLastDate();
    }
    if(this.isListView) {
      this.isList = this.isListView;    // tuck away the initial desired view (default to calendar)
    } else {
      let lv = localStorage.getItem(this.id);
      if(lv) {
        this.isList = JSON.parse(lv);
      }
    }
  },
  computed: {
    filterData() {
      let self = this
      if(!self.sortedEvents) {
        if(!self.isCalendarMode) {
          self.eventsFirstDate();
        } else {
          self.sortEvents();
        }
      }
      // let days  = Moment(`${self.selectedYear}-${self.selectedMonth}-01`).tz(this.timeZone).daysInMonth()
      let days  = Moment.tz(this.calendarDate,this.timeZone).daysInMonth()
      let data  = Array(days);
      var index = 0
      self.sortedEvents.forEach((e) => {
        let eventDate = e.eventDate || e.startDate;
        // let numDays = Moment(e.startDate).tz(this.timeZone).format( "YYYY-MM-DD" ) == Moment(e.endDate).tz(this.timeZone).format( "YYYY-MM-DD" )

        let numDays = 1;

        if(e.startDate && e.endDate) {
          numDays = Moment(e.endDate).diff(e.startDate, 'days') + 1; // 1
        } else {
          e.startDate = eventDate;    // numDays set above
        }

        for(let eDay = 0; eDay < numDays; eDay++) {
          if(numDays > 1) {
            eventDate   = Moment.tz(e.startDate,this.timeZone).add(eDay, 'day').format("YYYY-MM-DD");
            e.eventDate = eventDate;
            e.multiDay  = true;
          } else {
            e.multiDay  = false;
            e.eventDate = eventDate;
          }
          if(eventDate) {
            let m = self.getMonthNumber(eventDate);
            let y = self.getYearNumber(eventDate);
            if(m === self.selectedMonth && y === self.selectedYear) {
              let d = self.getDayNumber(eventDate) -1;

              if(!data[ d ]) {  // let's make sure we have a bucket to put this in
                data[ d ] = [];
              }

              if(e.allDay) {
                data[ d ].unshift(e);   // add all day stuff to the top
              } else {
                data[ d ].push(e);
              }
            }
          }
        }
      })
      return data
    },
    currentMonthName() {
      // return Moment(`${this.selectedMonth}`, "MM").tz(this.timeZone).format('MMMM');
      return Moment.tz(this.calendarDate,this.timeZone).format('MMMM');
    },
    emptyDays() {
      // let ed = Moment(`${this.selectedYear}-${this.selectedMonth}-01`).tz(this.timeZone).day();
      let ed = Moment.tz( this.calendarDate,this.timeZone).startOf('month').day();
      return ed;

    }
  },
  methods : {
    currency(c) {
      return Currency(c);
    },
    USD(value) {
      return Currency(value, {precision: 2, symbol: ' '}).format();
    },
    moment(d) {
      return Moment(d);
    },
    addEventToDays(e, data) {    // we expect data to be an array allocated with the proper # of buckets to hold a month

    },
    itemStyle(item) {

      if(this.maxDescriptionLines) {
        return `max-height: ${this.maxDescriptionLines}em;overflow:hidden; `
      }
      return item.dStyle;
    },
    updateListState(value) {
      localStorage.setItem(this.id, JSON.stringify(value));
    },
    isDisabledDecrementMonth() {
      if(this.calendarStartMonth === this.selectedMonth) {
        return true;
      }
      return false;
    },
    isToday(day) {
      let selectedDate = Moment.tz([this.selectedYear, this.selectedMonth-1, day+1], this.timeZone);
      let today = Moment.tz(this.$root.TZ);
      return selectedDate.isSame(today, 'day');
    },
    isThisMonth() {
      let selectedDate = Moment.tz([this.selectedYear, this.selectedMonth - 1], this.timeZone);
      let currentMonth = Moment().tz(this.timeZone);
      return selectedDate.isSame(currentMonth, 'month');
    },
    selectDate() {
      if(!this.isEnableSelectDate) {
        return;
      }
      this.$emit('selectDate', {year: this.selectedYear, month: this.selectedMonth});
    },
    gotoToday() {
      let month = parseInt(Moment().tz(this.timeZone).format("M"));
      let year  = parseInt(Moment().tz(this.timeZone).format("YYYY"));

      if(this.selectedMonth !== month || this.selectedYear !== year) {      // change both regardless.

        if(this.isCalendarMode) {
          this.$emit('gotoToday', {year: this.selectedYear, month: this.selectedMonth});
        } else {
          this.selectedMonth = month;
          this.selectedYear  = year;
          this.$emit('monthChanged', {year: this.selectedYear, month: this.selectedMonth});
        }
      }

    },
    dayContainerClass(index) {
      let cls = 'day-container-' + this.days[ ((this.emptyDays + index) % 7) ].toLowerCase();
      return ((this.emptyDays + index + 1) % 7 == 0 || this.filterData.length == (index + 1) ? 'calendar-right-border' : '') + ' ' + cls
    },
    getYearNumber(input) {
      return parseInt(Moment.tz(input,this.timeZone).format('YYYY'));
    },
    getMonthNumber(input) {
      let m = parseInt(Moment.tz(input,this.timeZone).format('M'));
      return m;
    },
    getDayNumber(input) {
      return parseInt(Moment.tz(input, this.timeZone).format('D'));
    },
    sortEvents() {
      this.sortedEvents = _.sortBy(this.events, 'eventDate');
    },
    eventsFirstDate() {
      if(this.events.length > 0) {
        this.sortEvents();
        this.calendarFirstDate  = this.sortedEvents[ 0 ].eventDate;
        this.calendarStartMonth = parseInt(Moment(this.calendarFirstDate).tz(this.timeZone).format("M"));
        return this.calendarFirstDate;
      }
      return Moment().tz(this.timeZone);
    },
    eventsLastDate() {
      if(this.sortedEvents.length > 0) {
        this.calendarLastDate = this.sortedEvents[ this.sortedEvents.length - 1 ].eventDate;
        this.calendarEndMonth = parseInt(Moment(this.calendarLastDate).tz(this.timeZone).format("M"));
        return this.calendarLastDate;
      }
      return Moment().tz(this.timeZone);
    },
    selectDay(day) {
      this.$emit('click', {year: this.selectedYear, month: this.selectedMonth, day: day + 1});
    },
    selectItem(item) {
      this.$emit("item", item._id);
    },
    clickBody(day) {
      this.$emit("clickBody", {year: this.selectedYear, month: this.selectedMonth, day: day + 1});
    },
    selectMore(day) {
      let more = {
        year : this.selectedYear,
        month: this.selectedMonth,
        day  : day
      }
      this.$emit("more", more);
    },
    selectNextMonth(increment) {
      if(this.isCalendarMode) { // in calendar mode, the parent moves our month since we're likely going to retrieve data
        if(increment > 0) {
          return this.$emit("nextMonth", {year: this.selectedYear, month: this.selectedMonth});
        } else {
          return this.$emit("prevMonth", {year: this.selectedYear, month: this.selectedMonth});
        }
      }

      this.selectedMonth += increment;

      if(this.selectedMonth === 13) {
        this.selectedMonth = 1
        this.selectedYear += 1
      }
      if(this.selectedMonth === 0) {
        this.selectedMonth = 12
        this.selectedYear -= 1
      }
      this.$emit('monthChanged', {year: this.selectedYear, month: this.selectedMonth})
    },
    selectedMonthIncrementDecrement(incrementDecrement) {
      if(incrementDecrement) {
        this.selectedMonth += 1
      } else {
        this.selectedMonth -= 1
      }
      if(this.selectedMonth === 13) {
        this.selectedMonth = 1
        this.selectedYear += 1
      }
      if(this.selectedMonth === 0) {
        this.selectedMonth = 12
        this.selectedYear -= 1
      }
      this.$emit('monthChanged', {year: this.selectedYear, month: this.selectedMonth})
    },
    printCalendar(divName) {
      this.$emit("print");
      window.print();

    }

  }
}
</script>
<style lang="scss" scoped>

table.cinereousTable {
  border: 6px solid #948473;
  background-color: #FFE3C6;
  width: 100%;
  text-align: center;
}

table.cinereousTable td, table.cinereousTable th {
  border: 1px solid #948473;
  padding: 4px 4px;
}

table.cinereousTable tbody td {
  font-size: 13px;
}

table.cinereousTable thead {
  background: #948473;
  background: -moz-linear-gradient(top, #afa396 0%, #9e9081 66%, #948473 100%);
  background: -webkit-linear-gradient(top, #afa396 0%, #9e9081 66%, #948473 100%);
  background: linear-gradient(to bottom, #afa396 0%, #9e9081 66%, #948473 100%);
}

table.cinereousTable thead th {
  font-size: 17px;
  font-weight: bold;
  color: #F0F0F0;
  text-align: left;
  border-left: 2px solid #948473;
}

table.cinereousTable thead th:first-child {
  border-left: none;
}

table.cinereousTable tfoot {
  font-size: 16px;
  font-weight: bold;
  color: #F0F0F0;
  background: #948473;
  background: -moz-linear-gradient(top, #afa396 0%, #9e9081 66%, #948473 100%);
  background: -webkit-linear-gradient(top, #afa396 0%, #9e9081 66%, #948473 100%);
  background: linear-gradient(to bottom, #afa396 0%, #9e9081 66%, #948473 100%);
}

table.cinereousTable tfoot td {
  font-size: 16px;
}

table.greyGridTable {
  border: 2px solid #FFFFFF;
  width: 100%;
  text-align: center;
  border-collapse: collapse;
}

table.greyGridTable td, table.greyGridTable th {
  border: 1px solid #FFFFFF;
  padding: 3px 4px;
}

table.greyGridTable tbody td {
  font-size: 13px;
}

table.greyGridTable td:nth-child(even) {
  background: #EBEBEB;
}

table.greyGridTable thead {
  background: #FFFFFF;
  border-bottom: 4px solid #333333;
}

table.greyGridTable thead th {
  font-size: 15px;
  font-weight: bold;
  color: #333333;
  text-align: center;
  border-left: 2px solid #333333;
}

table.greyGridTable thead th:first-child {
  border-left: none;
}

table.greyGridTable tfoot {
  font-size: 14px;
  font-weight: bold;
  color: #333333;
  border-top: 4px solid #333333;
}

table.greyGridTable tfoot td {
  font-size: 14px;
}


table.minimalistBlack {
  border: 3px solid #000000;
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}

table.minimalistBlack td, table.minimalistBlack th {
  border: 1px solid #000000;
  padding: 5px 4px;
}

table.minimalistBlack tbody td {
  font-size: 13px;
}

table.minimalistBlack thead {
  background: #CFCFCF;
  background: -moz-linear-gradient(top, #dbdbdb 0%, #d3d3d3 66%, #CFCFCF 100%);
  background: -webkit-linear-gradient(top, #dbdbdb 0%, #d3d3d3 66%, #CFCFCF 100%);
  background: linear-gradient(to bottom, #dbdbdb 0%, #d3d3d3 66%, #CFCFCF 100%);
  border-bottom: 3px solid #000000;
}

table.minimalistBlack thead th {
  font-size: 15px;
  font-weight: bold;
  color: #000000;
  text-align: left;
}

table.minimalistBlack tfoot {
  font-size: 14px;
  font-weight: bold;
  color: #000000;
  border-top: 3px solid #000000;
}

table.minimalistBlack tfoot td {
  font-size: 14px;
}


.calendar {
  padding: 0 !important;
  border-left: 1px solid #ccc !important;
  border-bottom: 1px solid #ccc !important;
  width: 14.2% !important;
  flex: 0 0 14.2% !important;
  max-width: 14.2% !important;

  .calendar-date {
    float: right;
    font-size: 24px;
    padding: 0px 12px;
    padding-bottom: 0;
    line-height: 20px;
    padding-top: 10px;
  }

  .calendar-title {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
  }

  .calendar-item {
    // margin-bottom:.1em;
    padding: .25em 0 .25em 0;
    // font-weight:600;
    line-height: 14px;
    border-top: 1px solid #ddd;
  }

  .no-border {
    border-top: none !important;
  }

  .calendar-item:hover {
    background: #eee;
    // font-weight:700;
  }

  .calendar-text {
    padding: 32px 8px 0 10px;
    text-align: left;
    font-size: 13px;
    // div {
    //   border-top: 1px solid #ddd;
    // }
    .item-time {
      float: right;
      position: relative;
      top: 0px;
      font-size: 11px;
      font-weight: 100;
      margin-right: 3px;
      padding-left: 3px;
    }

    .item-value {
      // float:right;
      margin-left: 6px;
      font-size: 1.1em;
      font-weight: 600;
    }

    .item-total {
      // float:right;
      text-align:left;
      font-size:.8em;
      //font-size: 1.1em;
      font-weight: 600;
    }

    .item-description {
      display: block;
      margin-left: 6px;
      white-space: pre-wrap;
      font-size: 11px;
      color: #999;
    }

    .item-allday {
      // font-weight:600;
      color: #9a159a;
    }

    .subitem {
      font-size: 11px;
      margin: 0 0 0 8px;
    }

    .subitem-title {
      // font-weight:300;
      text-decoration: italic;
    }

    .no-border {
      border: none;
    }
  }

  .calendar-no-data {
    min-height: 165px;
  }
}

.calendar-right-border {
  border-right: 1px solid #ccc;
}

.calendar-body {
  cursor: pointer;
  min-height: 165px;
}

.calendar-days {
  font-weight: 600;
  width: 14.2%;
  flex: 0 0 14.2%;
  text-align: center;
  padding: 8px 0;
}

.calendar-days-bottom {
  border-bottom: 1px solid #ccc !important;
}

.calendar-heading {
  font-size: 36px;
  font-weight: 500;
  color: #2980B9;

  span {
    font-size: 24px;
  }
}

.calendar-list-option {
  color: #2980B9;
  margin: 0 1em 0 0;
}


.empty-days-calendar {
  width: 14.2% !important;
  flex: 0 0 14.2% !important;
  border-bottom: 1px solid #ccc !important;
}

.calendar-sunday {
  background: #2980B9;
  color: #fff;
}

.calendar-monday {
  background: #17A187;
  color: #fff;
}

.calendar-tuesday {
  background: #94b84c;
  color: #fff;
}

.calendar-wednesday {
  background: #f1a00f;
  color: #fff;
}

.calendar-thursday {
  background: #c23a2b;
  color: #fff;
}

.calendar-friday {
  background: #55677B;
  color: #fff;
}

.calendar-saturday {
  background: #2980B9;
  color: #fff;
}

.calendar-list-day {
  margin-bottom: 1em;
}

.calendar-list-format {
  display: flex;
  width: 100%;
  cursor: pointer;

  div:nth-child(1) {
    width: 140px;
    margin-left: 2em;
  }

  div:nth-child(2) {
    min-width: 150px;
    font-weight: 600;
  }

  div:nth-child(3) {
    min-width: 200px;
    font-weight: 100;
    font-size: .8em;
    font-style: italic;
  }

  div:nth-child(4) {
    width: 90px;
    font-weight: 600;
    text-align: right;
  }
}

.calendar-list-format:nth-child(odd) {
  background: #eee;
}

.calendar-list-format:hover {
  background: #ddd;
}

.calendar-actions {
  // float: right;
  text-align: center;

  button {
    background: #2980B9 !important;
    color: #fff !important;
  }

  .calendar-button {
    margin: 5px 10px 5px 0;
  }

  .print-button {
    font-size: 2em;
    float: right;
    margin-right: 1em;
    color: #2980B9;
    cursor: pointer;
  }

  .calendar-month-year {
    font-size: 36px;
    color: #2980B9;
    border-top: 2px solid #2980B9;
    border-bottom: 2px solid #2980B9;
    margin: 0 20px 0 0;
    padding: 0 10px 0 10px;
    // display: inline-flex;
    span {
      margin-left: 8px;
      font-size: 36px;
      color: #f1a00f;
      font-weight: 1000;
    }
  }
}


.calendar.day-container-sunday {
  background: #f5f5f5;

  .calendar-date {
    color: #2980B9;
  }
}

.calendar.day-container-monday {
  background: #fff;

  .calendar-date {
    color: #17A187;
  }
}

.calendar.day-container-tuesday {
  background: #fff;

  .calendar-date {
    color: #94b84c;
  }
}

.calendar.day-container-wednesday {
  background: #fff;

  .calendar-date {
    color: #f1a00f;
  }
}

.calendar.day-container-thursday {
  background: #fff;

  .calendar-date {
    color: #c23a2b;
  }
}

.calendar.day-container-friday {
  background: #fff;

  .calendar-date {
    color: #55677B;
  }
}

.calendar.day-container-saturday {
  background: #f5f5f5;

  .calendar-date {
    color: #2980B9;
  }
}

@media print {

  body {
    -webkit-print-color-adjust: exact important;
    margin: 0;
    padding: 0;
  }
  /*.logo {
    width: 300px !important;
    max-width: 300px !important;
  }*/

  .noprint, #nav {
    display: none;
  }


  // .calendar.day-container-sunday {
  //   background: #fff;
  //   .calendar-date {
  //     color:#2980B9;
  //   }
  // }
  // .calendar.day-container-saturday {
  //   background: #fff;
  //   .calendar-date {
  //     color:#2980B9;
  //   }
  // }
}

.calendar-today {
  background: rgba(248, 192, 128, .6) !important;
}


// todo: This still requires some attention

@media print {
  @page {
    size: landscape;
    margin: 0;
  }
}
</style>
