<template>
    <div class="content col-md-12">
      <h4 slot="header" class="card-title">Sites<i class="fa fa-plus-square show-hand float-right" @click="addNewSite()" v-b-popover.hover.top="'Add New Site'"></i></h4>
      <div>

        <div>
          Filters:
          <b-radio-group style="margin-bottom:.5em;" switches v-model="siteFilter" @input="filterChanged" >
            <template v-for="(s,index) in siteTypes.filter((t) => { return t.value>''})" >
              <b-form-radio  :value="s.value" :key="index">{{s.text}}</b-form-radio>
            </template>
          </b-radio-group>
        </div>
        <b-table striped hover head-variant="dark" stacked="md" sort-by="siteId" :items="siteList" :fields="columns" @row-clicked="editSite($event)" >

          <template v-slot:cell(actions)="row">
            <i class="fa fa-trash show-hand float-left" @click.stop="removeSite(row.item)" v-b-popover.hover.right="'Remove This Entry'"></i>
          </template>

          <template v-slot:cell(siteId)="row">
             <div class="show-hand" :class="row.item.notes ? 'font-weight-bold' : ''"  v-b-tooltip.hover placement="righttop" :title="row.item.notes">{{row.item.siteId}}</div>
          </template>

        </b-table>

        <b-modal v-model="isShowModal"
            :title="modalTitle"
            size="lg"
            header-bg-variant="info"
            header-text-variant="light"
            footer-bg-variant="info"
            footer-text-variant="light">
          <b-container fluid v-if="site">
            <b-form-group horizontal breakpoint="lg" label="Site Information" label-size="lg" label-class="font-weight-bold pt-0" class="mb-0">
              <b-form-group  horizontal label-cols-sm="4" label-cols-lg="3" label-class="text-sm-right" label="Type" label-for="siteType">
                <b-form-select id="siteType" type="" style="width:14em;" readonly v-model="site.siteType" required placeholder="Site Type" :options="siteTypes"></b-form-select>
              </b-form-group>
               <b-form-group horizontal label-cols-sm="4" label-cols-lg="3" label-class="text-sm-right" label="Title" label-for="title">
                <b-form-input id="title" type="text" v-model="site.title" required placeholder="Site Title"></b-form-input>
              </b-form-group>
              <b-form-group horizontal label-cols-sm="4" label-cols-lg="3" label-class="text-sm-right" label="Description" label-for="description">
                <b-form-input id="description" type="text" v-model="site.description" required placeholder="Description"></b-form-input>
              </b-form-group>
              <hr />
              <!-- <b-form-group v-if="!isEditing" horizontal label-cols-sm="3" label-cols-lg="3" label-class="text-sm-right" label="Site ID" label-for="siteId">
                <b-form-input id="siteId" type="text" v-model="site.siteId" required placeholder="Site ID"></b-form-input>
              </b-form-group> -->
              <b-form-group  horizontal label-cols-sm="4" label-cols-lg="3" label-class="text-sm-right" label="Site ID" label-for="siteId">
                <b-form-input id="siteId" :readonly="site.title.length>0" type="text" v-model="site.siteId" required placeholder="Site ID"></b-form-input>
              </b-form-group>
              <hr />
              <b-form-group horizontal label-cols-sm="4" label-cols-lg="3" label-class="text-sm-right" label="Alert Title" label-for="alert-title">
                <b-form-input id="alert-title" type="text" v-model="site.alert.title" required placeholder="Alert Title"></b-form-input>
              </b-form-group>
              <b-form-group horizontal label-cols-sm="4" label-cols-lg="3" label-class="text-sm-right" label="Alert Email" label-for="alert-email">
                <b-form-input id="alert-email" type="text" v-model="site.alert.email" required placeholder="Alert Email"></b-form-input>
              </b-form-group>
              <hr />
              <b-form-group horizontal label-cols-sm="4" label-cols-lg="3" label-class="text-sm-right" label="Notes" label-for="notes">
                <b-form-textarea id="notes" type="text" :rows="3" v-model="site.notes" required placeholder="Notes"></b-form-textarea>
              </b-form-group>
            </b-form-group>
          </b-container>
          <div slot="modal-footer" class="w-100">
            <b-btn v-if="!isEditing" size="sm" class="float-left btn-fill" variant="info" @click="site=null;">
              Clear Form
            </b-btn>
            <b-btn v-if="!isEditing" size="sm" class="float-right btn-fill" variant="success" @click="addSite();">
              Add
            </b-btn>
            <b-btn v-if="isEditing" size="sm" class="float-right btn-fill" variant="success" @click="addSite();">
              Update
            </b-btn>
            <b-btn size="sm" class="float-right btn-fill mr-2" variant="danger" @click="isShowModal=false;site=null;">
              Cancel
            </b-btn>
          </div>
        </b-modal>

        <hr>
        <b-button class = "btn btn-success btn-fill float-right btn-sm"  @click="$router.go(-1)">
          Done
        </b-button>
        <!--<b-button class="btn btn-danger btn-fill float-right" @click="$router.go(-1)">
          Cancel
        </b-button>-->
      </div>
      <div class="clearfix"></div>
      <br/>
    </div>
</template>

<script>
import LTable from "/src/components/UIComponents/Table.vue";
import TenantService from "/src/Services/TenantService";
// import moment from "moment-timezone";
import Card from "/src/components/UIComponents/Cards/Card.vue";

export default {
  components: {
    LTable,
    Card
  },
  data() {
    return {
      saveDisabled: true,
      changeCount : 0,
      modalTitle  : "Adding New Site",
      isShowModal   : false,
      isAdding    : false,
      isEditing   : false,
      small       : true,
      site        : null,
      columns     : [
        "actions",
        { key: "siteId", label:"ID" ,sortable: true },
        { key: "title", sortable: true },
        { key: "description", sortable: true },
        { key: "alert.title", label:"Alert Title", sortable: true },
        { key: "alert.email", label: "Alert Email" ,sortable: true }
      ],
      siteTypes: [
        {value: "", text: "All Areas"},
        {value: "pos", text: "Sites"},
        {value: "delivery", text: "Delivery Locations"},
        {value: "class", text: "Rooms"},
        {value: "news", text: "News"}
      ],
      siteFilter: [
        "pos"
      ],
      sites: []
    };
  },
  watch: {
    tenant: {
      deep: true,
      handler(old) {
        this.changeCount++; // fixme: there are some internal things going on in the watcher that I prob need to fix.
        if (this.changeCount < 2) {
          return;
        }
        this.saveDisabled = false;
      }
    }
  },
  computed: {
    siteList() {
      let list = this.sites.filter((s) => { return (! s.siteType) || (this.siteFilter.indexOf( s.siteType) >= 0 )});
console.log( "site list" , list );
      return list;
    },
  },
  methods: {
    filterChanged() {
      localStorage.setItem( "siteFilter" , JSON.stringify(this.siteFilter) );
    },
    addNewSite() {
      this.isAdding   = true;
      this.isEditing  = false;
      this.isShowModal  = true;
      this.modalTitle = 'Adding New Site';

      let newSite          = Object.assign( {} , this.site);
          newSite.siteType = this.siteFilter[0]
          newSite.siteId   = this.siteFilter[0] + "-" + (this.siteList.length+1).toString();
          newSite.title    = "";
          newSite.description = "";
          newSite.alert    = {
            title: "",
            email: ""
          };
          this.site        = newSite ;

console.log( "addNewSite()", this.site );

    },
    async addSite() {
      var t     = this;
      var found = false;

      if( t.site && t.site.siteId == "" ) {
        await t.$root.alert( "Site ID Cannot Be Blank");
        return;
      }

      let index = _.findIndex( t.sites, { siteId: t.site.siteId , siteType: t.site.siteType });

      if( index >= 0 ) {
        if(t.isAdding ) {
          await t.$root.alert( "This Site ID Already Exists.  Might want to change the Site ID");
          return;
        }
        t.sites[index] = t.site;
      } else {
        t.sites.push( t.site );
      }

      t.site = null;
      await t.saveSites();
      t.isEditing = false;
      t.isAdding  = false;
      t.isShowModal = false;

    },
    editSite( item ) {
      var t = this;
      t.site          = Object.assign({} , item );;
      this.modalTitle = "Editing Site";
      this.isAdding   = false;
      this.isEditing  = true;
      this.isShowModal  = true;
    },
    async removeSite(d) {
      let t = this;
      if( ! await t.$root.confirm( "Remove " + "[" + d.siteId + "] " + d.title + "?")){
        return;
      }
      t.sites.forEach(function(item, index) {
        if (item.siteId === d.siteId && item.siteType === d.siteType ) {
          t.sites.splice(index, 1);
        }
      });
      await t.saveSites();
    },
    showModal () {
      this.$refs.myModalRef.show()
    },
    hideModal () {
      this.$refs.myModalRef.hide()
    },
    async getTenant() {
      var t = this;
      var response;
      try {
        response = await TenantService.getTenant();
        this.tenant = response.data;
      } catch (err) {
        console.log("getTenant error: ", err);
        if (err.response) {
          if (err.response.data) {
            t.$root.errorMessage("Oops", err.response.data.message);
          }
        } else {
          t.$root.errorMessage("Oops", "Had an error getting the tenant");
        }
      }
    },
    async getSites() {
      var t = this;
      var response;
      try {
        response = await TenantService.getSites();
        t.sites = response.data;
      } catch (err) {
        console.log("getTenant error: ", err);
        if (err.response) {
          if (err.response.data) {
            t.$root.errorMessage("Oops", err.response.data.message);
          }
        } else {
          t.$root.errorMessage("Oops", "Had an error getting the tenant");
        }
      }
    },
    async saveSites() {
      var t = this;
      var response;
      try {
        response             = await TenantService.saveSites(this.sites);
        t.sites = response.data;
        t.$root.tenant.sites = t.sites;
        t.$root.sites        = t.sites;

        t.site = null;
        t.$root.successMessage("SUCCESS", "Sites Updated");
        // t.$router.go(-1);

      } catch (err) {
        if (err.response) {
          console.log("error response ", err.response);
          t.$root.errorMessage("Oops", err.response.data.message);
        } else if (err.request) {
          console.log("Error Request ", err.request);
        } else {
          console.log("message only ", err.message);
        }
        t.$root.errorMessage("Oops", "Unable to Save Site Data");
      }
    }
  },
  mounted: function() {
    let sf =localStorage.getItem( "siteFilter" );

    if( sf ) {
      this.siteFilter = JSON.parse(sf);
    }

    this.getSites();
  }
};
</script>

<style lang="scss">
</style>
