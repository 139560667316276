///-------------------------------------------------------------------------------------------------
// summary:	Certifications Service
///-------------------------------------------------------------------------------------------------

import Api from '@/Services/Api';

export default {

  getCertificationsConfig( archived = null ) {
    return Api().get(`/api/1/tenantCertificationsConfig`);
  },
  updateCertificationsConfig(data) {
    return Api().patch("/api/1/tenantCertificationsConfig", data );
  },
  getCertifications( archived = null ) {
    return Api().get(`/api/1/certifications`, { params: { archived: archived }});
  },
  getCertificationCategories( cType ="" ) {
    return Api().get(`/api/1/certificationCategories/${cType}`);
  },
  // getCertificationsByAccount( accountNumber , all = null) {
  //   return Api().get(`/api/1/accountCertificationsList/${accountNumber}`,  { params: { all: all }});
  // },
  certificationsList( search = "" ) {
    console.log("SERVICE::certificationsList", search);
    return Api().get(`/api/1/certificationsList/${search}`  )
  },
  getCertification ( id ) {
    return Api().get(`/api/1/certification/${id}`);
  },
  addCertification( data ) {
    return Api().post("/api/1/certification", data );
  },
  updateCertification(data) {
    return Api().patch("/api/1/certification", data );
  },
  deleteCertification(id) {
    return Api().delete(`/api/1/certification/${id}`);
  },
  getVendorCertifications( id ) {
    return Api().get( `/api/1/certificationsForVendor/${id}` );
  }

}
