<template>
  <div class="comment-main-level">
    <div v-if="showImage" class="comment-avatar">
      <!-- <a :href="'mailTo:' + comment.actionUserEmail + '?subject=Helpdesk Ticket Comment Feedback&body=>> '+comment.actionDescription"><img :src="myGravatar(comment.actionUserEmail)" :alt="comment.actionUserEmail"  v-b-popover.hover.top="comment.actionUserEmail"/></a> -->
      <img :src="myGravatar(comment.actionUserEmail)" :alt="comment.actionUserEmail"  v-b-popover.hover.top="comment.actionUserEmail || 'No email'"/>
    </div>
    <div class="comment-box">
      <div class="comment-head">
        <h6 class="comment-name by-author">
          <a href="#">{{comment.actionUserAlias}}</a>
        </h6>
        <span>{{moment(comment.actionDate).fromNow()}}</span>
        <i v-if="showReply" class="fa fa-reply" @click="clickReply(comment)" ></i>
        <i v-if="showLike" class="fa fa-heart" @click="clickLike(comment)" ></i>
        <div v-if="comment.hours!=0" style="text-align:center;font-size:.8em;font-weight:600;"> {{parseFloat(comment.hours).toFixed(2)}} Hours</div>
      </div>
      <div v-if="showTitle" class="comment-title">{{comment.actionTitle}}</div>
      <div class="comment-content" v-html="comment.actionDescription" ></div>
    </div>
  </div>
</template>
<script>

import Moment from "moment";
import gravatar from "gravatar";

export default {
  name: "comment-card",
  components: {
  },
  props: {
    comment: { type: Object, default: () => { } },
    showTitle: { type: Boolean, default: false },
    showLike : { type: Boolean, default: true },
    showReply: { type: Boolean, default: true },
    showImage: { type: Boolean, default: true }
  },
  methods: {
    moment(d) {
      return Moment(d);
    },
    myGravatar( email ) {
      return gravatar.url( email );
    },
    clickReply(comment) {
      let t=this;
      // console.log( "commentCard.clickReply()" )
      t.$emit( 'reply' , comment );
    },
    clickLike(comment) {
      let t=this;
      t.$emit( 'like' , comment );
    }
  }
};
</script>
<style lang="scss" scoped>

* {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
 }

 a {
    color: #03658c;
    text-decoration: none;
 }

ul {
    list-style-type: none;
}

body {
    font-family: 'Roboto', Arial, Helvetica, Sans-serif, Verdana;
    background: #dee1e3;
}


/* ====================
    Comment related
 =======================*/
.comments-container {
    margin: 60px auto 15px;
    width: 768px;
}

.comments-container h1 {
    font-size: 36px;
    color: #283035;
    font-weight: 400;
}

.comments-container h1 a {
    font-size: 18px;
    font-weight: 700;
}


.comments-list {
    margin-top: 30px;
    position: relative;
}

/**
 * Lineas / Detalles
 -----------------------*/
.comments-list:before {
    content: '';
    width: 2px;
    height: 100%;
    background: #c7cacb;
    position: absolute;
    left: 32px;
    top: 0;
}

.comments-list:after {
    content: '';
    position: absolute;
    background: #c7cacb;
    bottom: 0;
    left: 27px;
    width: 7px;
    height: 7px;
    border: 3px solid #dee1e3;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
}

.reply-list:before, .reply-list:after {display: none;}
.reply-list li:before {
    content: '';
    width: 60px;
    height: 2px;
    background: #c7cacb;
    position: absolute;
    top: 25px;
    left: -55px;
}


.comments-list li {
    margin-bottom: 15px;
    display: block;
    position: relative;
}

.comments-list li:after {
    content: '';
    display: block;
    clear: both;
    height: 0;
    width: 0;
}

.reply-list {
    padding-left: 88px;
    clear: both;
    margin-top: 15px;
}
/**
 * Avatar
 ---------------------------*/
.comments-list .comment-avatar {
    width: 65px;
    height: 65px;
    position: relative;
    z-index: 99;
    float: left;
    border: 3px solid #FFF;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 2px rgba(0,0,0,0.2);
    -moz-box-shadow: 0 1px 2px rgba(0,0,0,0.2);
    box-shadow: 0 1px 2px rgba(0,0,0,0.2);
    overflow: hidden;
}

.comments-list .comment-avatar img {
    width: 100%;
    height: 100%;
}

.reply-list .comment-avatar {
    width: 50px;
    height: 50px;
}

.comment-main-level:after {
    content: '';
    width: 0;
    height: 0;
    display: block;
    clear: both;
}
/**
 * Caja del Comentario
 ---------------------------*/
.comments-list .comment-box {
    // width: 670px;
    width:100%;
    float: left;
    position: relative;
    -webkit-box-shadow: 0 1px 1px rgba(0,0,0,0.15);
    -moz-box-shadow: 0 1px 1px rgba(0,0,0,0.15);
    box-shadow: 0 1px 1px rgba(0,0,0,0.15);
}

.comments-list .comment-box:before, .comments-list .comment-box:after {
    content: '';
    height: 0;
    width: 0;
    position: absolute;
    display: block;
    border-width: 10px 12px 10px 0;
    border-style: solid;
    border-color: transparent #FCFCFC;
    top: 8px;
    left: -11px;
}

.comments-list .comment-box:before {
    border-width: 11px 13px 11px 0;
    border-color: transparent rgba(0,0,0,0.05);
    left: -12px;
}

.reply-list .comment-box {
    width: 610px;
}
.comment-box .comment-head {
    background: #FCFCFC;
    padding: 10px 12px;
    border-bottom: 1px solid #E5E5E5;
    overflow: hidden;
    -webkit-border-radius: 4px 4px 0 0;
    -moz-border-radius: 4px 4px 0 0;
    border-radius: 4px 4px 0 0;
}

.comment-box .comment-head i {
    float: right;
    margin-left: 14px;
    position: relative;
    top: 2px;
    color: #A6A6A6;
    cursor: pointer;
    -webkit-transition: color 0.3s ease;
    -o-transition: color 0.3s ease;
    transition: color 0.3s ease;
}

.comment-box .comment-head i:hover {
    color: #03658c;
}

.comment-box .comment-name {
    color: #283035;
    font-size: 14px;
    font-weight: 700;
    float: left;
    margin-right: 10px;
}

.comment-box .comment-name a {
    color: #283035;
}

.comment-box .comment-head span {
    float: left;
    color: #999;
    font-size: 13px;
    //font-size:.8em;
    position: relative;
    top: 1px;
}

.comment-title {
  font-weight:600;
  margin-left:.75em;
  margin-bottom:.5em;
}

.comment-box .comment-content {
    background: #fff;
    padding: 4px 12px 12px 24px;
    font-size: 15px;
    white-space:pre-line;
    color: #595959;
    font-weight:600;
    -webkit-border-radius: 0 0 4px 4px;
    -moz-border-radius: 0 0 4px 4px;
    border-radius: 0 0 4px 4px;
}

.comment-box .comment-name.by-author, .comment-box .comment-name.by-author a {color: #03658c;}
.comment-box .comment-name.by-author:after {
    content: 'author';
    background: #03658c;
    color: #FFF;
//    font-size: 12px;
    font-size:.8em;
    padding: 3px 5px;
    font-weight: 700;
    margin-left: 10px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}

/** =====================
 * Responsive
 ========================*/
@media only screen and (max-width: 766px) {
    .comments-container {
         width: 480px;
        //width:100%
    }

    .comments-list .comment-box {
        width: 390px;
    }

    .reply-list .comment-box {
        width: 320px;
    }
}


</style>
