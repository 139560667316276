<template>
  <b-container v-if="data" class="content container-fluid col-12" style="padding-top:.5em;overflow:auto;">
    <page-edit-header-card
      :enableClone="true"
      :isChanged="isDataChanged"
      :enableEditIcon="!isEditing"
      @clone="clone"
      @save="save"
      @cancel="cancel"
      @done="done"
      @edit="toggleEditMode()"
    >
      <template slot="title">
        <h3 class="card-title" style="text-transform:capitalize;">Provider: <span style="font-size:.8em;font-weight:800;">{{data.title}}</span></h3>
      </template>
    </page-edit-header-card>

    <b-tabs style="margin-top:2em;" class="tab-hover" active-nav-item-class="selected-tab" v-model="selectedTab">
      <b-tab title="General" style="margin-top:2em;">
        <b-row >
          <b-col xl="6" style="border-right:2px solid gray;">
            <b-form @input="dataChanged()" style=""  >
              <h4 style="margin:0 0 1em 0;padding:0;">General</h4>
              <div style="float:right;">Terms: <span style="font-weight:800;">{{data.terms || 'open'}}</span></div>
              <b-checkbox style="" v-model="data.active" :disabled="isReadOnly()">Active</b-checkbox>
              <b-checkbox style="" v-model="data.ccAccess.isAuthorized" :disabled="true">Authorized</b-checkbox>
              <!-- <b-checkbox style="" v-model="data.isOnHold" :disabled="isReadOnly()">On Hold</b-checkbox> -->
              <!-- <b-checkbox style="" v-model="data.isW2" :disabled="isReadOnly()" >Receive IRS W-2</b-checkbox> -->
              <!-- <b-checkbox style="" v-model="data.isW9" :disabled="isReadOnly()" >Receive IRS W-9</b-checkbox> -->
              <img v-if="data.logo" :src="data.logo" height="64">
              <br>
              <b-form-tags style="background:lightgray;margin-bottom:1em;" :disabled="isReadOnly()"  separator=" ,;" tag-pills size="md" :tag-validator="tagValidator" v-model="data.tags"></b-form-tags>
              <sf-input v-model="data.providerId" :disabled="isReadOnly() || data.title.length>0" :labelCols="2" cstyle="width:12em;" label="Provider Id" type="text" required/>
              <sf-input v-model="data.title"  :disabled="isReadOnly()" :labelCols="2"  label="Name" type="text" />
              <sf-input v-model="data.notes"  :readonly="isReadOnly()" :labelCols="2" :rows="12" label="Notes" type="textarea" />
            </b-form>
          </b-col>
          <b-col xl="6">
            <b-form  v-if="data.merchantData" @input="dataChanged()" style="text-align:left;">
              <h4 style="margin:0 0 1em 0;padding:0;">Merchant Overhead</h4>
              <sf-input v-model="data.ccInfo.merchantName"  :disabled="isReadOnly()" :labelCols="2"  cstyle="" label="Merchant Name" type="text" />

              <table style="margin-left:7%;margin-top:1em;" >
                <tr><td style="text-align:right;margin-right:1em;font-weight:700;">Processor</td><td style="padding-left:1em;">{{data.merchantProcessor}}</td></tr>
                <tr><td style="text-align:right;margin-right:1em;font-weight:700;">Auth App</td><td style="padding-left:1em;">{{data.ccAccess.ccMerchant}}</td></tr>
                <br>
                <tr><td style="text-align:right;margin-right:1em;font-weight:700;">Mid</td><td style="padding-left:1em;">{{data.merchantData.merchant.mid}}</td></tr>
                <tr><td style="text-align:right;margin-right:1em;font-weight:700;">Address</td><td style="padding-left:1em;">{{data.merchantData.merchant.addressLine1}}</td></tr>
                <tr><td style="text-align:right;margin-right:1em;font-weight:700;">City</td><td style="padding-left:1em;">{{data.merchantData.merchant.city}}</td></tr>
                <tr><td style="text-align:right;margin-right:1em;font-weight:700;">Region</td><td style="padding-left:1em;">{{data.merchantData.merchant.region}}</td></tr>
                <tr><td style="text-align:right;margin-right:1em;font-weight:700;">Zip</td><td style="padding-left:1em;">{{data.merchantData.merchant.postalCode}}</td></tr>
                <tr><td style="text-align:right;margin-right:1em;font-weight:700;">Serial</td><td style="padding-left:1em;">{{data.merchantData.merchant.serialNumber}}</td></tr>
              </table>
            </b-form>
          </b-col>
        </b-row>
      </b-tab>
      <b-tab title="Authorization" >
        <b-row>
          <b-col col-md="6">
            <b-form  v-if="data.ccAccess" style="width:600px;" >
              <i class="fa fa-link" style="float:right;cursor:pointer;" @click="deAuthorizeMerchant()" v-b-popover.hover.top="'deauthorize this merchant'"></i>
              <h4>CC Authorication Info</h4>
              <div v-if="data.ccAccess.isAuthorized">
                <b-form-group horizontal>
                  <table class="table striped">
                    <tr><td>Processor</td><td>{{data.merchantProcessor}}</td></tr>
                    <tr><td>Interface Name</td><td>{{data.ccAccess.interfaceName}}</td></tr>
                    <tr><td>Interface Version</td><td>{{data.ccAccess.interfaceVersion}}</td></tr>
                    <tr><td>Activation Count</td><td>{{data.ccAccess.authorizationCount}}</td></tr>
                     <tr><td>Authorization Date</td><td>{{moment(data.ccAccess.authorizationDate).format( "dddd DD-MMM-YY hh:mm:ssa")}}</td></tr>
                    <tr><td>Source</td><td>{{data.ccAccess.authorizationSource}}</td></tr>
                    <tr><td>Server</td><td>{{data.ccAccess.authorizationServer}}</td></tr>
                    <tr><td>Merchant</td><td>{{data.ccAccess.ccMerchant}}</td></tr> 
                    <!-- <tr><td>Auth Details</td><td style="max-width:300px;overflow-x:auto;pre-line:wrap;">{{data.ccAccess.authorizationResult}}</td></tr> -->
                  </table>
                </b-form-group>
              </div>
              <div v-else>
                <b style="color:red;">NOT ACTIVE</b>
                <br>
                <sf-input label="Payment Provider" type="list" :options="paymentProcessors" v-model="paymentProcessor"></sf-input>
                <sf-input label="Merchant" type="list" :options="ccMerchants" v-model="ccMerchant"></sf-input>
                <sf-input label="Auth Token"  type="text" v-model="authToken"></sf-input>
                <p class="mt-3 description">Please contact support if you have questions on ths step</p>
                <b-button :disabled="(authToken.length <10)" class="btn btn-fill btn-success mt-3" @click="authorizeMerchant()">
                  <span v-show="isAuthorizing" :class="animatedSpinner"></span> Authorize This Merchant Account
                </b-button>
              </div>
            </b-form>
          </b-col>
          <b-col>
            <div v-if="data.merchantData && data.merchantData.merchant">
              <h4>Accepted Card Types</h4>
              <b-form-group horizontal >
                <b-table striped hover responsive :items="data.merchantData.merchant.cardTypes" :fields="cardTypeFields"></b-table>
              </b-form-group>
            </div>
          </b-col>
        </b-row>
      </b-tab>

      <b-tab title="Contacts">
        <h4>No contacts on file</h4>
      </b-tab>
      
      
    </b-tabs>
  </b-container>
</template>
<script>

  import PieWidget                from "/src/components/UIComponents/Widgets/PieWidget.vue";
  import BarWidget                from "/src/components/UIComponents/Widgets/BarWidget.vue";

  import Currency       from "currency.js";
  import Moment         from "moment-timezone";
  import gravatar       from "gravatar";
  import Payments       from "/src/Services/Payments";

  import Card               from "/src/components/UIComponents/Cards/Card.vue";
  import SfInput            from "/src/components/UIComponents/Inputs/SFormInput.vue";
  import ChecklistEditor    from "/src/components/UIComponents/ChecklistEditor";
  import PageEditHeaderCard from '/src/components/UIComponents/Cards/PageEditHeaderCard.vue';
  import VueSimpleSuggest   from 'vue-simple-suggest'
  import 'vue-simple-suggest/dist/styles.css' // Optional CSS
  import ListCard           from '/src/components/UIComponents/Cards/ListCard.vue';

  var propId;
  //var propUser;

  export default {
    directives: {
    },
    components: {
      Card,
      SfInput,
      VueSimpleSuggest,
      ChecklistEditor,
      PieWidget,
      BarWidget,
      ListCard,
      PageEditHeaderCard
    },
    props: {
      provider: {type: Object, default: () => {}},
      changed : {type: Boolean, default: false},
      editing : {type: Boolean, default: false},
    },
    watch: {
    },
    data() {
      return {
        authorization: "",
        ccMerchant   : "",    // used for the authorize merchant process - data gets stuffed into the record if successful
        ccMerchants: [
          {'text':'mySchool Cafe','value':'myschool'},
          {'text':'Donations Express','value':'donationsexpress'}
        ],
        cardTypeFields: [
          {key: "type", label: "Card Type"},
          {key: "voiceCenter.accountNumber", label: "Account Number"},
          {key: "voiceCenter.phoneNumber", label: "Phone Number"}
        ],
        authToken    : "",
        paymentProcessor: "",
        paymentProcessors: [
           {'text':'Shift4','value':'shift4'},
        ],
        isDataChanged  : false,
        isAuthorizing  : false,
        isLoading      : false,
        isEditing      : false,
        data           : null,
        animatedSpinner: "fa fa-spinner  fa-refresh-animate mr-3",
        selectedTab    : 0
      }
    },
    async created() {

      await Promise.all([
        // this.getMyTicketTypes(),
        // this.getTicketStatuses(),
        // this.getTicketPriorities(),
        // // this.getTicketUsers(),
        // this.getUserOptions(),
      ])

      // this.getProject(this.propId);

    },
    mounted() {
      // console.log( "vendorEdit.mounted() before", this.isDataChanged , this.changed );
      this.data          = this.provider;
      this.isDataChanged = this.changed;
      this.isEditing     = this.editing;
      // console.log( "paymentProviderEdit.activated() after", this.isDataChanged , this.data );
    },
    computed: {
    },
    methods: {
      currency(c) {
        return Currency(c);
      },
      moment(d) {
        return Moment(d);
      },
      dataChanged() {
        this.isDataChanged = true;
        this.$emit('changed');
      },
      tagValidator(tag) {
        return tag === tag.toLowerCase() && tag.length > 2 && tag.length < 15
      },
      done() {
        // console.log("done");
        this.$emit("done" , this.data );
      },
      save() {
        // console.log( "save" );
        if( this.isDataChanged ) {
          this.$emit("save", this.data, this.isDataChanged );
          this.isDataChanged = false;
        } else {
          this.done();
        }
      },
      toggleEditMode() {
        this.isEditing = !this.isEditing;
      },
      isReadOnly(){
        return !this.isEditing;
      },
      async editTask() {
        await this.$root.alert("Edit task not implemented yet");
      },
      async clone() {
        // console.log( "clone" );
        if( ! await this.$root.confirmYesNo( "Clone this record?" )) {
            return;
        }
        this.dataChanged();
        this.$emit("clone", this.data );
      },
      async cancel() {
        // console.log( "cancel" );
        if( this.isDataChanged ) {
          if( ! await this.$root.confirmYesNo( "You made changes, continue to leave?" )) {
            return;
          }
        }
        this.isDataChanged = false;
        this.$emit("cancel", this.data );
      },
      async authorizeMerchant() {

        if( this.isDataChanged ) {
          return await this.$root.alert("Please save your changes before authorizing a merchant");
        }

        this.isAuthorizing = true;
        let response       = "";
        try {
          let authToken      = this.authToken;
              this.authToken = "***********************";
              response       = await Payments.providerAuthorizeMerchant(this.provider._id, authToken, this.ccMerchant);
              this.data      = response.data;

          if( response.status != 200 ) {
            await this.$root.alert(`Error authorizing merchant: ${response.statusText || ''}`, "warning");
            return;
          }

          await this.$root.alert("Success");

        } catch(err) {
          this.isAuthorizing = false;
          console.log( "ERROR: authorizeMerchant()", err );
          if(err.response && err.response.data) {
            await this.$root.alert(err.response.data.message, "warning");
          } else {
            await this.$root.alert(err.message, "warning");
          }
        }
        this.authToken     = ""
        this.isAuthorizing = false;
      },
      async deAuthorizeMerchant() {
        let response = "";

        if( this.isDataChanged ) {
          return await this.$root.alert("Please save your changes before de-authorizing a merchant");
        }

        if(!await this.$root.confirmYesNo("De-Authorize This Merchant Account?")) {
          return;
        }

        this.isAuthorizing = true;

        try {
          response = await Payments.providerDeAuthorizeMerchant(this.provider._id);
// console.log("deauthorization" , response);
          await this.$root.alert("Success");
          this.data = response.data;
        } catch(err) {
          console.log(JSON.stringify(err, null, 2));
          if(err.response.data) {
            await this.$root.alert(err.response.data.message, "warning");
          } else {
            await this.$root.alert(err.message, "warning");
          }
        }
        this.isAuthorizing = false;
      }
    }
  }

</script>
<style lang="scss">

  .outline-shadow {
    -webkit-box-shadow: 0px 0px 25px 5px rgba(0,0,0,0.35);
    box-shadow: 0px 0px 25px 5px rgba(0,0,0,0.35);
  }

  .collapsible {
    background-color: #eee;
    margin-top: .5em;
    border-radius: 1em;
    color: #444;
    cursor: pointer;
    padding: 10px;
    border: none;
    text-align: left;
    outline: none;
  }

  ins {
     background-color:rgba( 0,255,0,.1);
     text-decoration:none;
  }

  del {
    background-color:rgba( 255,0,0,.1);
  }

  .hover-track-light:hover{
    background: #eee;
    color:black;
  }

  .hover-track-dark:hover {
    background: #333;
    color: white;
  }


  .page-stamp {

    position: absolute;
    top: 4em;
    left: 2em;
    z-index: 1;
    font-family: Arial,sans-serif;
    -webkit-transform: rotate(-30deg); /* Safari */
    -moz-transform: rotate(-30deg); /* Firefox */
    -ms-transform: rotate(-30deg); /* IE */
    -o-transform: rotate(-30deg); /* Opera */
    transform: rotate(-30deg);
    font-size: 6em;
    color: #c00;
    background: #fff;
    border: solid 4px #c00;
    padding: 0 .5em 0 .5em;
    border-radius: .25em;
    zoom: 1;
    filter: alpha(opacity=20);
    opacity: 0.2;
    -webkit-text-shadow: 0 0 2px #c00;
    text-shadow: 0 0 2px #c00;
    box-shadow: inset 0px 0px 0px 10px #c00;
  }


  .page-stamp:after {
    content:'';
    border-radius:.25em;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url("@/assets/img/stamp-background.jpg");
    mix-blend-mode: lighten;
  }

  @media only screen and (max-device-width: 1700px) {
    .hide-too-small {
      display:none;
    }
  }


  @media print {

    @page {
      size: letter portrait;
    }

    body {
      -webkit-print-color-adjust: exact !important;
      width: 100%;
    }

    .logo {
      width: 300px !important;
      max-width: 300px !important;
    }

    .noprint {
      display: none;
    }

    .container {
      width: 100%;
      max-width: 100%;
    }
  }

</style>
