<template>
  <!--<div class="content shadow round" style="border-radius:1em;"> -->
  <div class="card col-md-12" style="min-height:750px;padding:1em;">
    <div v-show="!reportFormat" style="margin-bottom:10px;">
      <i class="fa fa-plus-square show-hand " style="position:absolute;right:1em;" v-b-popover.hover.top='"Add Report"' @click="addReportFormat()"></i>
      <b-button v-if="!reportFormat" class="btn btn-success btn-fill btn-sm float-left mr-2" style="border-radius:.75em;float:left;" @click="$router.go(-1)">Done</b-button>
      <h4 slot="header" class="card-title">Report & Action Templates </h4>
    </div>
    <div v-if="!reportFormat">
      <b-table striped hover head-variant="dark" class="show-hand" stacked="md" sort-by="sequence" :items="reportFormats" :fields="reportColumns" @row-clicked="rowClicked($event)">
        <template v-slot:cell(active)="data">
          <i class="fa fa-trash show-hand" @click.stop="removeReport(data)"></i>
          <i v-if="!data.item.active" class="fa fa-times" style="color:red;"></i>
          <i v-if="data.item.active" class="fa fa-check" style="color:green;"></i>
        </template>
      </b-table>
    </div>
    <div v-else>
      <i class="fa fa-clone" @click="cloneReportFormat" style="float:right;" v-b-popover.hover.top="'Clone this report format'"></i>
      <b-button v-if="isChanged" class="btn btn-sm btn-fill btn-warning" style="border-radius:.75em;float:left;margin-right:1em;" @click="saveReportFormat( reportFormat )">Save</b-button>
      <b-button v-if="reportFormat" class="btn btn-success btn-fill btn-sm float-left mr-2" style="border-radius:.75em;float:left;" @click="cancelEdit()">Cancel</b-button>
      <h4 style="margin-top:0;">{{reportFormat.title}}</h4>
      <hr />
      <b-tabs class="tab-hover" active-nav-item-class="selected-tab">
        <b-tab title="GENERAL" style="margin-top:2em;">
          <b-form @change="hasChanged" style="">
            <b-row class="row">
              <b-col class="col col-md-8">

                <b-form-group horizontal>
                  <b-checkbox v-model="reportFormat.active" style="position:relative;float:right;">Active</b-checkbox>
                  <sf-input type="number" cstyle="width:8em;" label="Sequence" v-model="reportFormat.sequence" />
                  <sf-input type="text" cstyle="width:25em;" label="Title" v-model="reportFormat.title" />
                  <sf-input type="text" cstyle="width:25em;" label="Sub Title" v-model="reportFormat.subTitle" />
                  <sf-input type="textarea" cstyle="width:25em;" :rows=5 label="Description" v-model="reportFormat.description" />
                  <hr>
                  <b-checkbox v-model="reportFormat.enableExport" style="position:relative;margin-left:14em;">Enable Export</b-checkbox>
                  <sf-input type="text" cstyle="width:15em;" label="Action Text" :maxlength=20 v-model="reportFormat.actionText" />
                  <sf-input type="list" cstyle="width:15em;" label="Action Type" v-model="reportFormat.actionType" :options="['report','action','link']" />
                  <sf-input type="list" cstyle="width:15em;" label="Data Source" v-model="reportFormat.actionLink" :options="reportDataSources" />
                  <sf-input type="list" cstyle="width:15em;" label="Report Icon" v-model="reportFormat.image" :options="reportIcons"/>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </b-tab>

        <b-tab title="Filters">
          <b-row>
            <b-col class="col-md-4">
              <div style="margin-left:3em;">
                <h4>Account Profiles</h4>
                <b-form @change="hasChanged()">
                  <b-checkbox-group stacked v-model="reportFormat.filter.accountClass">
                    <b-form-checkbox v-for="(ac,index) in accountClasses" :key="index" :value="ac.accountClassId">{{ac.title}}</b-form-checkbox>
                  </b-checkbox-group>
                </b-form>
              </div>
            </b-col>
            <b-col class="col-md-4">
              <div style="margin-left:3em;">
                <h4>Total Profiles</h4>
                <b-form @change="hasChanged()">
                  <b-checkbox-group stacked v-model="reportFormat.filter.totalClass">
                    <b-form-checkbox v-for="(t,index) in activeTotalProfiles" :key="t.totalClassId" :value="t.totalClassId">{{t.title}}</b-form-checkbox>
                  </b-checkbox-group>
                </b-form>
              </div>
            </b-col>
          </b-row>
        </b-tab>

        <b-tab title="Report Fields" style="margin-top:2em;">
          <b-row @change="hasChanged">
            <b-col class="col col-md-3">
              <h4 style="margin-top:0;margin-bottom:.5em;border-bottom:1px solid black;">Still Available</h4>
              <!--<b-checkbox-group v-model="reportFormat.reportFields">
            <b-checkbox v-for="(f,index) in accountBalanceFields" :key="f.key" :value="f">{{f.label}}</b-checkbox>
          </b-checkbox-group>-->


              <ul class="field-list" style="">
                <li class="show-hand" v-for="(f,index) in availableFields" :key="f.key" @click="addFieldToReport( f )">{{f.label}}</li>
              </ul>
            </b-col>
            <b-col class="col col-md-8" style="background:#eee;border-radius:1em;padding:1em;">
              <h4 style="margin-top:0;margin-bottom:0;">Current Report Columns</h4>
              <table style="width:100%;">
                <thead>
                  <tr style="color:white;background:gray;">
                    <td style="text-align:center;padding-right:1em;">Header Title</td>
                    <td>Data Key</td>
                    <td>Sortable?</td>
                    <td></td>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(i,index) in reportFormat.reportFields" :key="index">
                    <td class="report-field-header"><b-form-input style="font-weight:bold;background:#eee;text-align:center;" v-model="reportFormat.reportFields[index].label"></b-form-input></td>
                    <td style="">{{i.key}}</td>
                    <td class="report-field-header" @click="reportFormat.reportFields[index].sortable = !reportFormat.reportFields[index].sortable">{{(i.sortable)?"Yes" : "-"}}</td>
                    <td><i class="show-hand fa fa-trash" @click="removeFieldFromReport(index)"></i></td>
                  </tr>
                </tbody>
              </table>
            </b-col>
          </b-row>
        </b-tab>

        <b-tab title="Styles">
          <b-row>
            <b-col class="col col-md-7">

              <h4>Report Format</h4>
              <div style="">
                <b-form @change="hasChanged()">
                  <b-form-group horizontal>
                    <sf-input type="textarea" :rows=5 label="CSS CARD Style" v-model="reportFormat.cardStyle" placeHolder="Use Valid CSS Formatting Including Trailing ;" />
                  </b-form-group>
                  <b-form-group horizontal>
                    <sf-input type="textarea" :rows=5 label="CSS Table Style" v-model="reportFormat.tableStyle" placeHolder="Use Valid CSS Formatting Including Trailing ;" />
                  </b-form-group>
                  <p style="font-size:.8em;font-style:italic;color:#444;">This is CSS STYLE formatting for the CARD and the REPORT</p>
                </b-form>
              </div>
            </b-col>
          </b-row>
        </b-tab>

        <b-tab v-if="reportFormat.enableExport" title="Export Fields">
          <sf-input type="number" cstyle="width:8em;" label="Sequence" v-model="reportFormat.sequence" />
          <sf-input type="text" label="Title" v-model="reportFormat.title" />
          <sf-input type="textarea" label="Description" v-model="reportFormat.description" />
        </b-tab>

      </b-tabs>

    </div>
    <div class="clearfix"></div>
    <br />
  </div>
    <!--</div>-->
</template>

<script>

  import AccountService from "/src/Services/AccountService";
  import TotalService   from "/src/Services/TotalService";
  // import TenantService  from "/src/Services/TenantService";
  import ReportService  from "/src/Services/ReportService";
  import Card           from "/src/components/UIComponents/Cards/Card.vue";
  import SfInput        from "/src/components/UIComponents/Inputs/SFormInput.vue";
  import draggable      from 'vuedraggable'

  export default {
    components              : {
      Card,
      SfInput,
      draggable
    },
    data() {
      return {
        saveDisabled  : true,
        changeCount   : 0,
        isChanged     : false,
        isAdding      : false,
        isEditing     : false,
        small         : true,
        totals        : [],
        accountClasses: [],
        accountTypes  : [],
        fieldIndex    : 0,
        reportIcons   : ["Report-icon.jpg", "Report-icon2.png", "Report-PNG2.png", "Money-Bag.png", "newcare-icon-128.png", "account-icon.jpg", "Abstract-People.png", "default-avatar.png"],
        reportFormat  : null,
        reportFormats : null,

        reportDataSources: [
          { value: 'accountBalance', text: 'Account Balance' },
          { value: 'transactionList', text: "Transaction List" },
          { value: 'transactionSummary', text: "Transaction Summary" },
          { value: 'testReport', text: "Test Report" }
        ],

        reportColumns: [
          {key: "active"    , Label: "Active",   sortable: true},
          {key: "sequence"  , Label: "Sequence", sortable: true},
          {key: "actionType", Label: "Action",   sortable: true},
          {key: "title"     , Label: "Title",    sortable: true},
          {key: "actionLink", Label: "Link",     sortable: true}
        ]                   ,
        // These fields are just for reference - they get replaced when we pull in the configuration
        accountBalanceFields: [
          { key: "active", label: "Active", sortable: true , dataType: "boolean", export: true },
          { key: "total.totalType", label: "Total Type", sortable: true , dataType: "string", export: true},
          { key: "accountType", label: "Account Type", sortable: true , dataType: "string", export: true},
          { key: "accountTitle", label: "Title", sortable: true , dataType: "string", export: true},
          { key: "accountNumber", label: "Account #", sortable: true , dataType: "string", export: true},
          { key: "glAccount", label: "GL Account", sortable: true , dataType: "string", export: true},
          { key: "arType", label: "AR Type", sortable: true , dataType: "string", export: true},
          { key: "companyCode", label: "Company Code", sortable: true , dataType: "string", export: true},
          { key: "payrollId", label: "Payroll ID", sortable: true , dataType: "string", export: true},
          { key: "remainingBalanceAction", label: "R/Balance Action", sortable: true , dataType: "string", export: true},
          { key: "total.totalClassId", label: "Profile", sortable: true , dataType: "number", export: true},
          { key: "total.lastChargeDate", label: "Last Charge", sortable: true , dataType: "date", export: true},
          { key: "total.lastPaymentDate", label: "Last Payment", sortable: true , dataType: "date", export: true},
          { key: "total.total.chargeTotal", label: "Charges", sortable: true , dataType: "currency", export: true},
          { key: "total.total.paymentTotal", label: "Payments", sortable: true , dataType: "currency", export: true},
          { key: "balance", label: "Balance", sortable: true , dataType: "currency", export: true}
        ]

      };
    },
    computed: {
      availableFields() {
        return this.accountBalanceFields.filter((f) => { return !_.find(this.reportFormat.reportFields, { key: f.key }) });
      },
      activeTotalProfiles() {
        return this.totals.filter((t) => { return t.title !== 'DELETE' } );
      },
    },
    watch     : {},
    methods   : {
      hasChanged() {
        this.isChanged = true;
      },
      async removeReport(e) {
        if (!confirm("Remove " + e.item.title + "?")) {
          return;
        }
        if (!confirm("Really sure to remove " + e.item.title + "?")) {
          return;
        }
        try {
          let results = await ReportService.deleteReportFromat(e.item._id);
          this.reportFormats.splice(e.index, 1);
        } catch (error) {
          console.log(error);
        }
      },
      addFieldToReport(f) {
        this.hasChanged();
        this.reportFormat.reportFields.push(f);
      },
      removeFieldFromReport(index) {
        this.hasChanged();
        this.reportFormat.reportFields.splice(index, 1);
      },
      async rowClicked(item) {
        //console.log(JSON.stringify(item, null, 2));
        await this.getReportFormat(item._id);
      },
      cancelEdit() {
        this.reportFormat = null;
        this.isChanged    = false;
      },
      async cloneReportFormat() {
        if (!confirm("Clone This Report Format?")) {
          return;
        }
        var t = this;
        try {
          let results = await ReportService.addReportFormat({title:"New Report"});
          t.reportFormat._id = results.data._id;
          t.reportFormat.title += "(clone)"
          this.hasChanged();
        } catch (error) {
          console.log(error);
        }
      },
      async addReportFormat() {
        if (!confirm("Add New Report Format?")) {
          return;
        }
        var t = this;
        try {
          let results = await ReportService.addReportFormat({title:"New Report"});
          t.reportFormat = results.data;
        } catch (error) {
          console.log(error);
        }
      },
      async getReportFormat(id) {
        try {
          let results       = await ReportService.getReportFormat(id);
          this.reportFormat = results.data;
          //console.log(this.reportFormat);
        } catch(error) {
          console.log(error)
        }
      },
      async getReportFormats() {
        try {
          let results        = await ReportService.getAllReportFormats();
          this.reportFormats = results.data;
          this.reportFormat  = null;
          this.isChanged     = false;
        } catch(error) {
          console.log(error)
        }
      },
      async saveReportFormat(reportFormat) {
        try {
          await ReportService.saveReportFormat(reportFormat);
          this.getReportFormats();
        } catch(error) {
          console.log(error)
        }
      },
       total(id) {
        let t = this;
        let i;
        if (t.totals.length > 0) {
          for (i = 0; i < t.totals.length; i++) {
            if (t.totals[i].totalClassId == id) {
              return "" + id + " : " + t.totals[i].title;
            }
          }
        }
        return "" + id
      },
      async getAccountTypes() {
        try {
          let results = await AccountService.getAccountTypes();
          this.accountTypes = results.data;
        } catch (error) {
          console.log(error);
        }
      },
      async getAccountClasses() {
        try {
          let results = await AccountService.getAccountClasses();
          this.accountClasses = results.data; //.accountClass;
          //console.log("result Data", JSON.stringify(this.accountClasses , null, 2));
        } catch (error) {
          console.log(error);
        }
      },
      async getTotals() {
        var response;
        var t = this;
        try {
          response = await TotalService.getTotals();
          t.totals = response.data;

        } catch (error) {
          if (error) {
            alert( "oops hit error in getTotals", error.message );
            t.$root.errorMessage("Oops", error.message );
          }
          return;
        }
      },
    },
    async created() {
      let t = this;
      try {
        await Promise.all([
          //t.getRevenueCenters(),
          //t.getAccountTypes(),
          //t.getTenders(),
//          t.getReportFormat(this.reportFormatId),
          t.getAccountClasses(),
          t.getTotals(),
          t.getReportFormats()
        ]);
      } catch (err) {
        console.log("Transactions Mounted error: ", err);
        if (err.response) {
          if (err.response.data) {
            t.$root.errorMessage("Oops", err.response.data.message);
          }
        } else {
          t.$root.errorMessage("Oops", "Had an error getting initial settings");
        }
      }
    }
  };
</script>

<style lang="scss">

  .report-field-header:hover {
    background: #aaa;
    color: white;
    cursor: pointer;
  }

  .field-list {
    list-style-type: square;
  }





</style>
