<template>
  <div class="wrapper main-background"   >
    <!-- sidenav navbar navbar-vertical navbar-expand-xs navbar-light bg-white position-relative -->
    <!-- <b-collapse v-model="isShowMenu" > -->

    <side-bar  id="sidenav" ref="side-nav" title=""  :backgroundColor="$root.theme.background"  class="noprint scroll-content" :class="{'nav-open': $sidebar.showSidebar}">
      <!-- <mobile-menu slot="content"></mobile-menu> -->

      <template slot-scope="props" slot="links">

<!-- <i class="fa fa-chevron-right show-hand" style="text-align:right;" @click="toggleSidebar"></i>-->
<!--
        <i v-if="isShowMenu" class="fa fa-expand show-hand" style="width:100%;text-align:right;" @click="toggleMenu"  />
        <i v-else class="fa fa-compress show-hand" style="width:100%;text-align:right;" @click="toggleMenu"  /> -->

        <div style="margin-top:20px;"></div>

        <sidebar-item v-if="$root.isFeature('dashboard')" :link="{name: 'Dashboard', icon: 'fa fa-pie-chart', path: '/app/overview'}"></sidebar-item>

<!--        <sidebar-item  :link="{name: 'Accounts', icon: 'fa fa-address-book', path: ''}">-->
          <sidebar-item v-if="$root.isFeature('accounts')" :link="{name: 'Accounts', icon: 'fa fa-drivers-license', path: '/app/accounts'}"></sidebar-item>
          <!-- <sidebar-item v-if="$root.isFeature('links')" :link="{name: 'Links',  icon: 'fa fa-address-book-o', path: '/links/links'}"></sidebar-item> -->
<!--        </sidebar-item>-->

        <sidebar-item v-if="$root.isFeature('vendors')" :link="{name: 'Vendors',  icon: 'fa fa-address-book-o', path: '/vendors/vendors'}"></sidebar-item>

        <sidebar-item v-if="$root.isFeature('links')" :link="{name: 'Bookmarks & Links',  icon: 'fa fa-anchor', path: '/links/links'}"></sidebar-item>

        <sidebar-item v-if="$root.isFeature('catering')" :link="{name: 'Catering', icon: 'fa fa-cutlery', path: '/catering/calendar'}">
          <sidebar-item :link="{name: 'Calendar',  icon: 'fa fa-anchor', path: '/catering/calendar'}"></sidebar-item>
          <sidebar-item :link="{name: 'Orders',  icon: 'fa fa-dollar', path: '/catering/Invoices'}"></sidebar-item>
        </sidebar-item>

        <sidebar-item v-if="$root.isFeature('events')" :link="{name: 'Events & Clubs', icon: 'fa fa-calendar', path: '/events'}">
          <sidebar-item :link="{name: 'Calendar',  icon: 'fa fa-anchor', path: '/events/overview'}"></sidebar-item>
          <sidebar-item :link="{name: 'Groups & Clubs',  icon: 'fa fa-anchor', path: '/events/clubs'}"></sidebar-item>
<!--          <sidebar-item :link="{name: 'Attendance',  icon: 'fa fa-anchor', path: '/events/attendance'}"></sidebar-item>-->
        </sidebar-item>

        <sidebar-item v-if="$root.isFeature('transactions')" :link="{name: 'Transactions', icon: 'fa fa-money', path: '/app/transactions'}"></sidebar-item>
        <sidebar-item v-if="$root.isFeature('assets')" :link="{name: 'Assets', icon: 'fa fa-th-list', path: '/app/assets' }"></sidebar-item>

        <sidebar-item v-if="$root.isFeature('certifications')" :link="{name: 'Certifications', icon: 'fas fa-certificate', path: '/certifications/menu'}">
          <sidebar-item  :link="{name: 'Overview', icon: 'fa fa-list-alt', path: '/certifications/overview'}"/>
          <sidebar-item  :link="{name: 'Statistics', icon: 'fa fa-list-alt', path: '/certifications/stats'}"/>
          <sidebar-item  :link="{name: 'Matrix', icon: 'fa fa-list-alt', path: '/certifications/matrix'}"/>
          <sidebar-item  :link="{name: 'Batch Edit', icon: 'fa fa-list-alt', path: '/certifications/batchEdit'}"/>
          <sidebar-item  :link="{name: 'Master List', icon: 'fa fa-list-alt', path: '/certifications/certifications'}"/>
        </sidebar-item>

        <!-- <sidebar-item v-if="$root.isFeature('donations')" :link="{name: 'Donations', icon: 'fa fa-credit-card', path: '/donations/menu'}"></sidebar-item> -->
        <sidebar-item v-if="$root.isFeature('donations')" :link="{name: 'Donations', icon: 'fa fa-credit-card', path: '/donations/menu'}">
          <sidebar-item  :link="{name: 'Overview', icon: 'fa fa-list-alt', path: '/donations/overview'}"/>
          <sidebar-item  :link="{name: 'Contributions', icon: 'fa fa-calendar', path: '/donations/contributions'}"/>
          <!-- <sidebar-item  :link="{name: 'Donors', icon: 'fa fa-list-alt', path: '/donations/donors'}"/> -->
          <sidebar-item  :link="{name: 'Tiles', icon: 'fa fa-list-alt', path: '/donations/campaigns'}"/>
        </sidebar-item>

        <sidebar-item v-if="$root.isFeature('reports')" :link="{name: 'Reports & Queries', icon: 'fas fa-archive', path: '/reports/menu'}">
          <!-- <sidebar-item :link="{name: 'Reports', icon: 'nc-icon nc-single-copy-04', path: '/reports/menu'}"></sidebar-item>
          <sidebar-item :link="{name: 'Queries', icon: 'nc-icon nc-single-copy-04', path: '/app/overview'}"></sidebar-item> -->
        </sidebar-item>

        <sidebar-item v-if="$root.isFeature('portal') && $root.isPortal()" :link="{name: 'Orders & Items', icon: 'fa fa-opencart',path: '/portal/menu'}">
          <sidebar-item  :link="{name: 'Production Summary', icon: 'fa fa-list-alt', path: '/portal/portalOrdersSummary'}"/>
          <sidebar-item  :link="{name: 'Order Listing', icon: 'fa fa-list-alt', path: '/portal/portalOrders'}"/>
          <sidebar-item  :link="{name: 'Order Calendars', icon: 'fa fa-calendar', path: '/portal/orderCalendar'}"/>
          <sidebar-item  :link="{name: 'Menu Item Master', icon: 'fa fa-list-alt', path: '/portal/menuItemMaster'}"/>
        </sidebar-item>

        <sidebar-item v-if="$root.isFeature('portal') && $root.isPortal()" :link="{name: 'Portal Management', icon: 'fa fa-pencil', path: '/portal/menu'}">
          <sidebar-item  :link="{name: 'Master Registrations', icon: 'fa fa-hourglass-1', path: '/portal/portalAuthorizations'}"/>
          <sidebar-item  :link="{name: 'Pending Registrations', icon: 'fa fa-hourglass-3', path: '/portal/pendingAccounts'}"/>
          <sidebar-item  :link="{name: 'Teachers', icon: 'fa fa-graduation-cap', path: '/portal/portalAuthorizationsTeachers'}"/>
          <sidebar-item  :link="{name: 'News Management', icon: 'fa fa-newspaper-o', path: '/portal/newsManagement'}"/>
          <sidebar-item  :link="{name: 'FAQ Management', icon: 'fa fa-question', path: '/portal/faqManagement'}"/>

          <div v-if="$root.isAdmin()">
            <div style="text-align:center;background:#ccc;color:black;border-radius:1em;border:1px solid #eee;margin:0 10px 0 60px;"></div>
            <sidebar-item  :link="{name: 'Upload Student File', icon: 'fa fa-pencil', path: '/portal/portalUploadStudentFile'}"/>
            <sidebar-item  :link="{name: 'Rotations', icon: 'fa fa-pencil-square', path: '/admin/rotationConfig'}"/>
            <sidebar-item  :link="{name: 'Sites & Classrooms', icon: 'fa fa-pencil-square-o', path: '/admin/sites'}"/>
          </div>
        </sidebar-item>

        <sidebar-item v-if="$root.isFeature('menuboard')" :link="{name: 'Menuboard', icon: 'fa fa-compass', path: '/portal/menu'}">
          <sidebar-item  :link="{name: 'Menu Item Master', icon: 'fa fa-list-alt', path: '/portal/menuItemMaster'}"/>
          <sidebar-item  :link="{name: 'Order Calendars', icon: 'fa fa-calendar', path: '/portal/orderCalendar'}"/>
          <sidebar-item  :link="{name: 'News Management', icon: 'fa fa-newspaper-o', path: '/portal/newsManagement'}"/>
          <sidebar-item  :link="{name: 'FAQ Management', icon: 'fa fa-question', path: '/portal/faqManagement'}"/>
        </sidebar-item>


        <sidebar-item v-if="$root.isFeature('teamtasks')" :link="{name: 'Team Tasks', icon: 'fa fa-tasks', path: '/project/tasks'}"></sidebar-item>
        <sidebar-item v-if="$root.isFeature('procedures')" :link="{name: 'Knowledge Base', icon: 'fa fa-graduation-cap', path: '/procedures/procedures'}"></sidebar-item>
        <sidebar-item v-if="$root.isFeature('functions')" :link="{name: 'Functions', icon: 'fa fa-cogs', path: '/functions/menu'}"></sidebar-item>

        <sidebar-item v-if="$root.isFeature('housing')" :link="{name: 'Housing', icon: 'far fa-building', path: '/housing/housing'}">
          <sidebar-item v-if="$root.isFeature('housing')" :link="{name: 'Overview', icon: 'fas fa-file-contract', path: '/housing/housing'}"></sidebar-item>
          <sidebar-item v-if="$root.isFeature('housing')" :link="{name: 'Contracts', icon: 'fa fa-h-square', path: '/housing/contracts'}"></sidebar-item>
          <sidebar-item v-if="$root.isFeature('housing')" :link="{name: 'Buildings', icon: 'fas fa-home', path: '/housing/buildings'}"></sidebar-item>
          <sidebar-item v-if="$root.isFeature('housing')" :link="{name: 'Units', icon: 'fas fa-home', path: '/housing/units'}"></sidebar-item>
        </sidebar-item>

        <sidebar-item v-if="$root.isFeature('marketing')" :link="{name: 'Marketing', icon: 'fas fa-bullseye' }">
          <sidebar-item  :link="{name: 'Overview', icon: 'fa fa-address-card', path: '/marketing/marketing'}"/>
          <sidebar-item  :link="{name: 'Models', icon: 'fa fa-address-card', path: '/marketing/modelInformation'}"/>
          <sidebar-item  :link="{name: 'Available Units', icon: 'fa fa-address-card', path: '/marketing/availableUnits'}"/>
        </sidebar-item>

        <sidebar-item v-if="$root.isFeature('environmental')" :link="{name: 'Environmental', icon: 'fas fa-thermometer-three-quarters' }">
          <sidebar-item  :link="{name: 'Overview', title:'Something else', path: '/environmental/overview'}"/>
          <sidebar-item  :link="{name: 'Temperatures', path: '/environmental/temperatures'}"/>
        </sidebar-item>

        <sidebar-item v-if="$root.isFeature('kiosks') || $root.isFeature('displaydog')" :link="{name: 'DisplayDog', icon: 'fa fa-desktop', path: '/kiosks/menu'}">
          <sidebar-item  :link="{name: 'News Management', icon: 'fa fa-newspaper-o', path: '/kiosks/newsManagement'}"/>
          <sidebar-item  :link="{name: 'Kiosk Status', icon: 'fa fa-desktop', path: '/kiosks/menu'}"></sidebar-item>
          <sidebar-item v-if="$root.isAdmin()" :link="{name: 'Configuration', icon: 'fa fa-desktop', path: '/kiosks/device'}"></sidebar-item>
        </sidebar-item>

        <sidebar-item v-if="$root.isFeature('tickets')" :link="{name: 'Helpdesk', icon: 'fa fa-ticket', path: '/case/ticketMenu'}">
          <sidebar-item :link="{name: 'Overview', icon: 'fa fa-ticket', path: '/case/ticketMenu'}"/>
          <sidebar-item :link="{name: 'New Ticket Wizard', icon: 'fa fa-ticket', path: '/case/ticketWizard'}"/>
          <sidebar-item :link="{name: 'Tickets', icon: 'fa fa-ticket', path: '/case/tickets'}"/>
          <!-- <sidebar-item :link="{name: 'Unassigned Tickets', icon: 'fa fa-ticket', path: '/case/tickets/all/undefined'}"/> -->
          <!-- <sidebar-item :link="{name: 'Ticket Status', icon: 'fa fa-ticket', path: '/case/ticketStatus'}"/> -->
          <sidebar-item :link="{name: 'Ticket Summaries', icon: 'fa fa-ticket', path: '/case/ticketSummary'}"/>
        </sidebar-item>

        <sidebar-item v-if="$root.isFeature('projects')" :link="{name: 'Capital Projects', icon: 'fa fa-briefcase', path: ''}">
<!--          <sidebar-item :link="{name: 'Projects', icon: 'fa fa-money', path: '/projects/projects'}"/>-->
          <sidebar-item :link="{name: 'Projects', icon: 'fa fa-ticket', path: '/projects/projectBudgets'}"/>
        </sidebar-item>

        <!-- <sidebar-item :link="{name: ' ', path: ''}"></sidebar-item> -->
        <sidebar-item v-if="$root.isFeature('help')" style="margin-top:1em;" :link="{name: 'Help & Training', icon: 'fa fa-info-circle', path: '/help/menu'}"></sidebar-item>

      </template>

    </side-bar>
    <!-- </b-collapse> -->
    <div class="main-panel" style="" id="__MainContentPanel__">
      <!-- <i class="fa fa-plus" @click="isShowMenu=true"/> -->
      <top-navbar @click="announcementClick($event)"></top-navbar>
      <page-content></page-content>
      <!-- <page-content @click="toggleSidebar"></page-content> -->
      <content-footer></content-footer>
    </div>
  </div>
</template>

<script>
  import TopNavbar from "./TopNavbar.vue";
  import ContentFooter from "./ContentFooter.vue";
  import PageContent from "./Content.vue";
  import MobileMenu from "./MobileMenu.vue";

  export default {
    components: {
      TopNavbar,
      PageContent,
      ContentFooter,
      MobileMenu
    },
    data () {
      return {
        isShowMenu:true
      }
    },
    methods   : {
      toggleMenu() {
        this.isShowMenu = !this.isShowMenu;
      },
      toggleSidebar() {
        // console.log( "appLayout.vue->toggleSidebar()");
        if(this.$sidebar.showSidebar) {
          this.$sidebar.displaySidebar(false);
        } else {
          this.$sidebar.displaySidebar(true);
        }
      },
      announcementClick( message ) {
        // console.log( "AppLayout.vue->announcementClick()" , message );
        this.$root.alert( "AppLayout.vue says: " + message );
      }
    }
  };
</script>

<style lang="scss">

  .main-background {
    background-color: white;
  }

  .vue-notifyjs.notifications {
    .list-move {
      transition: transform 0.3s, opacity 0.4s;
    }

    .list-item {
      display: inline-block;
      margin-right: 10px;

    }

    .list-enter-active {
      transition: transform 0.4s ease-in, opacity 0.4s ease-in;
    }

    .list-leave-active {
      transition: transform 1.5s ease-out, opacity 0.4s ease-out;
    }

    .list-enter {
      opacity: 0;
      transform: scale(1.1);

    }

    .list-leave-to {
      opacity: 0;
      transform: scale(1.2, 0.7);
    }
  }
</style>
