<template>
  <div class="content">
    <div class="container-fluid">

      <h2>Help & Training</h2>
      <hr />

      <div class="row">
        <div class="col md-12">
          <h3>Videos</h3>
          <div class="card-columns">

          <div v-if="videos.length>0">
            <div  v-for="(v,index) in videos" :key="index" class="card mb-3" style="max-width:18rem;">
              <div class="card-body">
                <b-embed v-if="v.contentSettings.contentType=='video/mp4'" type="video" controls aspect="16by9" allowfullscreen>
                  <source :src="downloadLink+v.name">
                </b-embed>
                <img v-if="v.contentSettings.contentType!=='video/mp4'" :src="downloadLink+v.name" width="256" />
              </div>
              <div class="card-header dark">
                <h5 class="show-hand">
                  <a target="_blank" :href="downloadLink+v.name">{{v.metadata.title || v.name}}</a>
                </h5>
                <p class="card-text small">
                  {{v.metadata.description}}
                </p>
              </div>
            </div>
          </div>


          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
  import Card from "/src/components/UIComponents/Cards/Card.vue";
  import LTable from "/src/components/UIComponents/Table.vue";
  import FileService from "/src/Services/FileService";
  // import moment from "moment-timezone";

  export default {
    components: {
      Card,
      LTable
    },
    data() {
      return {
        something: "not here",
        downloadLink: "",
        videos: [],
        pdfTest: null
      };
    },
    mounted() {
      this.getVideos();
    },
    methods: {

      async loadTest() {
        let result  = await FileService.loadTest();

        this.pdfTest = result.data.orders;

      },
      async getVideos() {
        try {
          let response = await FileService.getVideos();
          // response.continuationToken -- do sometihng with it if it exists cuz there are more videos
          this.downloadLink = response.data.downloadLink + "/";
          this.videos = response.data.entries;
        } catch (err) {
          if (err) {
            console.log(err);
            this.$root.errorMessage("Oops", err.response.data.message);
          }
          return;
        }
      }
    }
  };

</script>
<style language="scss" scoped>
  .video-insert {
    position: absolute;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -100;
    background-size: cover;
    overflow: hidden;
  }

</style>
