<template>
  <div class="content">
    <div class="container-fluid">
      <card>
        <template slot="header">
          <h3 class="card-title">FAQ Management
            <button class="float-left mr-3 btn btn-success btn-fill btn-sm" @click="$router.push('/app');"><span v-show="loading" :class="isLoading"></span>Done</button>
          </h3>
          <hr>
        </template>
        <div class="row">
          <div class="col-md-11">
            <b-button class="btn btn-success btn-round btn-sm btn-fill float-right mr-4" @click="addFaq()"> Add New Question</b-button>
            <h4>FAQ Listing</h4>
            <b-table v-if="faqs.length > 0" :tbody-tr-class="faqRowClass" responsive small striped hover :items="faqs" :fields="fields" @row-clicked="selectFaq">
              <template v-slot:cell(action)="data">
                <i class="fa fa-trash show-hand" v-b-popover.hover.top="'Remove This FAQ'" @click="removeFaq(data.item)"></i>
                <i class="fa fa-edit show-hand" v-b-popover.hover.top="'Edit This FAQ'" @click="editFaq(data.item)"></i>
              </template>
              <template v-slot:cell(active)="data"> 
                {{ data.item.active ? "Yes" :"No" }}
              </template>
              <template v-slot:cell(title)="data">
                <div class="question-title">{{data.item.title}}</div>
                <div class="question-answer">{{data.item.description}}</div>
                <div class="question-more-information">{{data.item.moreInformation}}</div>
              </template>
              <template v-slot:cell(updatedAt)="data">
                {{moment(data.item.updatedAt).fromNow()}}
              </template>
            </b-table>
            <div v-else>
              <h5>No FAQ Entries Defined</h5>
            </div>
          </div>
        </div>
      </card>

      <b-modal
        ref="faqDialog"
        :title="faqTitle"
        size="lg"
        :header-bg-variant="headerBgVariant"
        :header-text-variant="headerTextVariant"
        :body-bg-variant="bodyBgVariant"
        :body-text-variant="bodyTextVariant"
        :footer-bg-variant="footerBgVariant"
        :footer-text-variant="footerTextVariant"
      >
        <b-container fluid>
          <!-- <sf-input v-model="newFaq.title" type="text" label="Question" placeholder="Question" />
          <sf-input v-model="newFaq.description" type="text" label="Answer" placeholder="Question" /> -->
          <b-checkbox style="float:right;" v-model="faq.active" > Show It?</b-checkbox>
          <sf-input cstyle="width:6em;" v-model="faq.sequence" type="number" label="Sequence"/>

          <sf-input
            label="Question"
            type="textarea"
            v-model="faq.title"
            placeholder="FAQ Question..."
            :rows=2
            :max-rows=6
          />
          <sf-input
            label="Answer"
            type="textarea"
            v-model="faq.description"
            placeholder="FAQ Answer..."
            :rows=8
          />
          <sf-input
            label="Media Link"
            type="text"
            v-model="faq.moreInformation"
            placeholder="Media Link"
          />
           <sf-input 
            v-if="faq.moreInformation && faq.moreInformation.length>0"
            label="Hover Tip"
            type="text"
            v-model="faq.tip"
            placeholder="Hover Tip"
          />
        </b-container>
        <div slot="modal-footer" class="mt-4">
          <b-btn variant="success" size="sm" class="btn btn-fill float-right" @click="saveFaq()">Save</b-btn>
          <b-btn variant="warning" size="sm" class="btn btn-fill float-right" @click="hideDialog()">Cancel</b-btn>
        </div>
      </b-modal>

    </div>
  </div>
</template>
<script>
  import FaqService from "/src/Services/FaqService";
  import Moment     from "moment";
  import Card       from '/src/components/UIComponents/Cards/Card.vue'
  import SfInput    from "/src/components/UIComponents/Inputs/SFormInput.vue";

  export default {
    components: {
      Card,
      Moment,
      SfInput
    },
    data() {
      return {
        variants         : [ 'primary', 'secondary', 'success', 'warning', 'danger', 'info', 'light', 'dark' ],
        headerBgVariant  : 'dark',
        headerTextVariant: 'light',
        bodyBgVariant    : 'light',
        bodyTextVariant  : 'dark',
        footerBgVariant  : 'warning',
        footerTextVariant: 'dark',
        isAddFaq         : false,
        faqErrorMessage  : "",
        faqs             : [],
        faqTemplate      : {
          category   : "FAQ",
          active     : true,
          sequence   : 0,
          title      : "New Question",
          description: "New Answer",
          moreInformation: "" 
        },
        newFaq           : {},
        faq              : {title: "", description: ""},
        fields           : [
          "action",
          { key: 'active' , label: "Show?" , sortable: true },
          {key: 'sequence', label: "Sequence", sortable: true},
          // { key: 'category', label: "Category" , sortable: true},
          {key: 'title', label: "Question/Answer", sortable: true},
          // {key: 'description', sortable: false},
          {key: 'updatedAt', label: "Last Update", sortable: true}
        ],
        loading          : false,
        isLoading        : "fa fa-spinner  fa-refresh-animate mr-3",
        type             : [ '', 'info', 'success', 'warning', 'danger' ],
        notifications    : {
          topCenter: false
        }
      }
    },
    async mounted() {
      this.getFaqs();
    },
    computed  : {
      faqTitle() {
        return "FAQ " + ((this.isAddFAQ) ? " Adding Question" : " Editing Question");
      }
    },
    methods   : {
      moment(d) {
        return Moment(d);
      },
      selectFaq( row , index ) {
//        console.log( "hit selectFaq()" , row , index);
        // alert( "Hit Select" );
        this.editFaq( row );
      },
      faqRowClass( item , type ){
        if( ! item ) {
          return;
        }
        if( item.active === false) {
          return "table-warning";
        }
      },
      editFaq(id) {
        this.faq = id;
        this.$refs.faqDialog.show();
      },
      addFaq() {
        this.isAddFaq = true;
        this.faq      = Object.assign({}, this.templateFaq);
        this.$refs.faqDialog.show();
      },
      hideDialog() {
        this.isAddFaq = false;
        this.$refs.faqDialog.hide();
      },
      async getFaqs() {
        var response;
        var t = this;

        t.loading = true;

        try {
          response = await FaqService.getFaqs();
          t.faqs   = response.data;

          t.loading = false;
        } catch(err) {
          if(err) {
            console.error(err);
            t.$root.errorMessage("Oops", err.response.data.message);
          }
          t.loading = false;
        }
      },
      async removeFaq(faq) {
        var response;
        var t = this;

        if(!confirm("Remove " + faq.title + "?")) {
          return
        }
        t.loading = true;

        try {
          response = await FaqService.removeFaq(faq._id);
          t.getFaqs();
          t.loading = false;
          t.hideDialog();
        } catch(err) {
          if(err) {
            console.error(err);
            t.$root.errorMessage("Oops", err.response.data.message);
          }
          t.loading = false;
        }
      },
      async saveFaq() {
        var response;
        var t = this;

        t.loading = true;

        try {
          if(this.isAddFaq) {
            response = await FaqService.addFaq(this.faq);
            this.faqs.push(response.data);
            this.faq = Object.assign({}, this.templateFaq);
          } else {
            response = await FaqService.saveFaq(this.faq._id, this.faq);        // save the whole list.
          }

          t.loading = false;
          this.hideDialog();
        } catch(err) {
          if(err) {
            console.error(err);
            t.$root.errorMessage("Oops", err.response.data.message);
          }
          t.loading = false;
        }
      }
    }
  }

</script>
<style lang="scss">

  .question-title {
    width:400px;
    white-space:nowrap;
    overflow:hidden;
    text-overflow:ellipsis;
  }
  .question-answer{
    width:400px;
    white-space:nowrap;
    overflow:hidden;
    text-overflow:ellipsis;
  }

  .question-more-information {
    color:magenta;
  }


</style>
