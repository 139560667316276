<template>
  <div class="card shadow " >
    <img v-if="logo" :src="logo" class="images">
<!--    <h4 class="show-hand" @click="select()">{{ title }}</h4>-->
    <div class="card-text">
      <span>{{ title }}</span>
      <div style="margin-left:2em;font-size:.8em;word-wrap:break-word;">{{ subTitle }}</div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    id        : {type: String },
    logo      : {type: String },
    author    : {type: String },
    title     : {type: String, default: ""},
    subTitle  : {type: String, default: ""},
    lastUpdate: {type: String | Date },
  },
  data() {
    return {
      nothing: ""
    };
  },
  computed: {},
  methods : {
    select() {
      this.$emit("click", this.id);
    }
  }
};
</script>

<style lang="scss" scoped>

/*******
  LOGO-CARD
 *******/

.card {
  display: inline-block;
  float: left;
  width: 450px;
  height: 100px;
  padding: .5em;
  border: 2px white solid;
  border-radius: 1em;
  text-align: center;
  margin: .5em;
  transition: all 0.3s ease;

  img {
    padding: .25em;
    max-width: 90%;
    max-height: 70%;
    object-fit: scale-down;
  }

  .card-text {
    span:first-child {
      font-size: 1.25em;
      overflow: hidden;
      text-shadow: -6px 8px 12px rgba(163, 163, 163, 0.7);
    }

    span:nth-child(2)::before {
      white-space: pre;
      content: "\A";
    }

    span:nth-child(2) {
      border-top: 1px solid lightgray;
      font-style: italic;
      color: lightgray;
    }
  }
}

.card:hover {
  border: 2px gray solid;
  /*border-radius:1em;*/
  /*margin:0;*/
  background: #f0f0f0;
  opacity: 1;
  box-shadow: inset 0 0 0 5px gray;
  //-webkit-transform: rotateZ(-3deg);
  //-ms-transform: rotateZ(-3deg);
  //transform: rotateZ(-3deg);
}


</style>
