<template>
  <card class="card-user">
    <img slot="image" src="/img/user-background.jpg" alt="..."/>
    <div class="author">
      <div>
        <img class="avatar border-gray show-hand" ref="userImage" @click="showUploadForm" :src="($root.user.pictureUrl) ? $root.user.pictureUrl+'?rnd=' + (new Date().valueOf()) : myGravatar($root.user.email)" v-b-popover.hover.top="'Upload Photo'" alt="..."/>
        <h4 class="title">{{$root.user.alias}}<br />
          <small>{{$root.user.email}}</small>
        </h4>
      </div>
    </div>
    <div class="description text-center" >
      <table class="table list" >
        <tr>
          <td style="text-align:right;font-weight:700;">Current IP</td>
          <td style="text-align:left;">{{$root.user.currentIP}}</td>
        </tr>
        <tr>
          <td style="text-align:right;font-weight:700;">Logon Count</td>
          <td style="text-align:left;">{{$root.user.logonCount}}</td>
        </tr>
        <tr>
          <td style="text-align:right;font-weight:700;">Last Logon</td>
          <td style="text-align:left;">{{formatDate($root.user.lastLogon)}}</td>
        </tr>
      </table>
    </div>
    <div style="height:400px;overflow-x:auto;">
      <h4>Access Log</h4>
      <table class="table list" style="font-size:.8em;" >
        <tr>
          <th>Who</th>
          <th>When</th>
          <th>Where</th>
        </tr>
        <tr v-for="(r,index) in accessLog()" :key="index" >
          <td  style="font-size:.9em;" >{{r.who}}</td>
          <td  style="font-size:.9em;" >{{formatDate(r.when)}}</td>
          <td  style="font-size:.9em;" v-b-popover.hover.top="r.notes" >{{r.where}}</td>
        </tr>
      </table>
    </div>
    
    <b-modal ref="uploadGravatar" hide-footer hide-header>
      <h4>Upload Your Picture</h4>
      <vue-avatar-editor
        :width="parseInt(150)"
        :height="parseInt(150)"
        :rotation="parseFloat(rotation)"
        :scale="parseFloat(scale)"
        finishText="Yep, Save My Photo"
        ref="vueavatar"
        @finished="userImageReady"
        @cancel="hideUploadForm"
      />
      <div>
        {{message}}
      </div>
    </b-modal>

  </card>
</template>
<script>
  import UserService     from "/src/Services/UserService";
  import moment          from "moment-timezone";
  import Card            from "/src/components/UIComponents/Cards/Card.vue";
  import gravatar        from "gravatar";
  import _               from "lodash";
  import VueAvatarEditor from '/src/components/UIComponents/Avatar/VueAvatarEditor';

  export default {
    components: {
      Card,
      VueAvatarEditor
    },
    data () {
      return {
        rotation: 0.0,
        scale: 1.0,
        message: ""
      }
    },
    methods: {
      formatDate(d, e) {
        return moment(d).tz(this.$root.user.TZ).format("DD-MMM-YY hh:mm a");    // based on USERS TZ
      },
      myGravatar( email ) {
        return gravatar.url( email );
      },
      accessLog() {
        return _.orderBy( this.$root.user.remoteAccessLog , [ "when" ] ,[ "desc" ]);
      },
      showUploadForm() {
        this.$refs.uploadGravatar.show();
      },
      hideUploadForm() {
        this.$refs.uploadGravatar.hide();
      },
      async userImageReady( e ) {

        let imageScaled = e.getImageScaled();
        let image = e.getImage();

        try {

          let data = {
            upload  : imageScaled.toDataURL("image/png"),
            userName: this.$root.user.alias,
            email   : this.$root.user.email
          }

          let result = await UserService.uploadPhotoFromText(data);
          this.$root.user.pictureUrl = result.data.pictureUrl + "?" + new Date().getTime();

          this.hideUploadForm();

        } catch( error ) {
            this.message = error.message;
        }

      }

    }
  }

</script>
<style>

</style>
