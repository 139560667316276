<template>
  <div :class="{'nav-open': $sidebar.showSidebar}">
    <notifications></notifications>
    <!-- updateExists -->
    <div v-if="updateExists" class="shadow" style="border:8px solid firebrick;cursor:pointer;position:absolute;top:10px;left:10px;z-index:9999;border-radius:2em;font-weight:700;background:darkred;color:white;padding:2em;">
      <img src="@/assets/img/icare-cafe-logo-300.png" height="96"/>
      <h2 style="padding:0;">New Update Available</h2>
      <h4 style="padding:0;margin-left:auto;margin-right:auto;">{{$root.appTitle}} - Version: {{$root.versionOnly}}</h4>
      <b-button  style="margin-top:2em;background:green;color:white;float:right;"  @click="refreshApp" >
          Apply This Update
      </b-button>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
// imports here...
export default {
  name: "app",
  bodyClass: "app",
  components: {},
  data() {
    return {
      refreshing  : false,
      registration: null,
      updateExists: false,
      title       : "Main App",
    };
  },
  mounted() {},
  created() {

    document.addEventListener("swUpdated", this.showRefreshUI, { once: true });
    console.log( "navigator (look for serviceWorker.addEventListener)", navigator );
    if( navigator.serviceWorker ) {
      navigator.serviceWorker.addEventListener("controllerchange", () => {
        if(this.refreshing) return;

        caches.keys().then(function(cacheNames) {   // wjs: 01-Nov-23 :: let's clear our cache to keep future issues with this reload thing at a minimum.
          cacheNames.forEach(function(cacheName) {
            caches.delete(cacheName);
          });
        });

        this.refreshing = true;
        window.location.reload();
      });
    } else {
      console.log("navigator.serviceWorker not found - likely development mode.");
    }
  },
  methods: {
    updateAvailable(message) {
      alert(message);
    },
    refreshApp() {
      console.log("app.vue->clicked refreshApp()");
      this.updateExists = false;
      if (!this.registration || !this.registration.waiting) {
        return;
      }
      this.registration.waiting.postMessage("skipWaiting");
    },
    showRefreshUI(e) {
      // this fires but doesn't show.
      console.log("app.vue->showRefreshUI() e=", e);
      this.registration = e.detail;
      this.updateExists = true;
    }
  }
};
</script>

<style lang="scss">
  /*see assets/css/app.css*/


  @media print {

    html, body, nav {
      background:white;
    }

    .content {
      background: white;
    }
  }

</style>
