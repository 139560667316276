<template>
  <b-container v-if="data" class="content container-fluid col-12" style="padding-top:.5em;overflow:auto;">
    <page-edit-header-card
      :prefix="'Vendor:'"
      :title="data.name"
      :enableClone="true"
      :isChanged="isDataChanged"
      :enableEditIcon="!isEditing"
      @clone="clone"
      @save="save"
      @cancel="cancel"
      @done="done"
      @edit="toggleEditMode()"
    >
    </page-edit-header-card>

    <b-tabs style="margin-top:2em;" class="tab-hover" lazy active-nav-item-class="selected-tab" v-model="selectedTab">
      <b-tab title="General" style="margin-top:2em;">
        <b-row >
          <b-col xl="6" style="border-right:2px solid gray;">
            <div v-if="data.logo" style="float:right;height:128px;width:128px;" ><img class="show-hand" @click="(isReadOnly()) ? isShowUpload=false : isShowUpload=true" :src="data.logo" height=128 /> </div>
            <b-form @input="dataChanged()" style=""  >
              <h4 style="margin:0 0 1em 0;padding:0;">General</h4>
              <div v-if="!data.logo && !isReadOnly()" style="">
                <b-button class="btn btn-sm btn-fill" style="margin-bottom:1em;position:absolute;top:-20px;right:10px;background:black;color:white;" @click="isShowUpload=true">Upload Logo</b-button>
              </div>
              <div v-if="isShowUpload">
              <image-upload
                style="background:#f7f7f7;border:0;text-align:center;margin:0;padding:0;"
                :folder="uploadFolder"
                :filename="'vendor-logo'"
                :width="300"
                :height="300"
                @cancel="isShowUpload=false"
                @save="imageUploadComplete"
              />
              </div>
              <div style="float:right;">Terms: <span style="font-weight:800;margin-right:1em;">{{data.terms || 'open'}}</span></div>
              <b-checkbox style="" v-model="data.active" :disabled="isReadOnly()">Active</b-checkbox>
              <b-checkbox style="" v-model="data.isOnHold" :disabled="isReadOnly()">On Hold</b-checkbox>
              <b-checkbox style="" v-model="data.isW2" :disabled="isReadOnly()" >Receive IRS W-2</b-checkbox>
              <b-checkbox style="" v-model="data.isW9" :disabled="isReadOnly()" >Receive IRS W-9</b-checkbox>
              <br>
              <b-form-tags style="background:lightgray;margin-bottom:1em;" :disabled="isReadOnly()"  separator=" ,;" tag-pills size="md" :tag-validator="tagValidator" v-model="data.tags"></b-form-tags>
              <sf-input v-model="data.vendorNumber" :disabled="isReadOnly()" :labelCols="2" cstyle="width:12em;" label="Vendor ID" helpText="OUR # for this vendor" type="text" required/>
              <!-- <sf-input v-model="data.accountNumber" :disabled="isReadOnly()" :labelCols="2" cstyle="width:12em;" label="Account #" helpText="Vendors # for our account with them" type="text" /> -->
              <sf-input v-if="addCategory" v-model="data.category" cstyle="width:12em;" :disabled="isReadOnly()" :labelCols="2"  label="Category" type="text" required/>
              <div v-if="!addCategory">
                <i class="fa fa-pencil" v-if="!isReadOnly()" style="cursor:pointer;font-size:1.5em; float:right;" @click="addCategory=true" v-b-popover.hover.left="'Add/Change Category'"></i>
                <sf-input v-model="data.category" cstyle="width:12em;" :disabled="isReadOnly()" :labelCols="2"  label="Category" type="list" :options="categoryList" @change="dataChanged()" required/>
              </div>
              <sf-input v-model="data.name"  :disabled="isReadOnly()" :labelCols="2"  label="Name" type="text" required/>
              <sf-input v-model="data.alias" :disabled="isReadOnly()"  :labelCols="2"  label="Alias" type="text" />
              <sf-input v-model="data.description"  :readonly="isReadOnly()" :labelCols="2" :rows="12" label="Notes" type="textarea" />
            </b-form>
        </b-col>
        <b-col xl="6">
          <div style="position:relative;display:flex;flex-wrap: wrap;justify-content:flex-start">
            <div style="position:absolute;right:0;">
              <i class="fa fa-plus-square show-hand" v-b-popover.hover.top="'Add Contact'"  @click="addContact()"></i>
            </div>
            <contact-card2
              :contact="data.primary"
              :index="0"
              :readOnly="isReadOnly()"
              :isShowHeader="false"
              @change="dataChanged()"
            />

            <template v-for="(contact,index) in data.contacts">
              <contact-card2
                :key="index"
                :index="index"
                :contact="contact"
                :readOnly="isReadOnly()"
                :isShowHeader="false"
                @change="dataChanged()"
              />
            </template>
          </div>
<!--          <b-tabs class="tab-hover" active-nav-item-class="selected-tab">-->
<!--              <b-tab title="Primary">-->
<!--                <contact-card-extended-->
<!--                  style="margin-top:1em;"-->
<!--                  :contact="data.primary"-->
<!--                  :imageSize="64"-->
<!--                  :readOnly="isReadOnly()"-->
<!--                  :isShowHeader="false"-->
<!--                  @change="dataChanged()"-->
<!--                />-->
<!--              </b-tab>-->
<!--              <b-tab :title="`Additional Contacts (${data.contacts.length})`">-->
<!--                <contact-card-extended-->
<!--                  style="margin-top:1em;"-->
<!--                  :contacts="data.contacts"-->
<!--                  :imageSize="64"-->
<!--                  :readOnly="isReadOnly()"-->
<!--                  :isAllowDelete="true"-->
<!--                  @add="addContact()"-->
<!--                  @change="dataChanged()"-->
<!--                />-->
<!--              </b-tab>-->
<!--            </b-tabs>-->
          </b-col>
        </b-row>
      </b-tab>
      <b-tab title="Accounting" >
        <b-row>
          <b-col col-md="6">
            <b-form @input="dataChanged()" style="width:600px;">
              <h4>Accounting</h4>
              <sf-input v-model="data.terms"   :disabled="isReadOnly()"  :labelCols="2"  label="Terms" type="text" cstyle="width:12em;" />
              <!-- <sf-input v-model="data.vendorNumber" :disabled="isReadOnly()"  :labelCols="2" cstyle="width:12em;" label="Vendor #" type="text" required/> -->
              <sf-input v-model="data.accountNumber" :disabled="isReadOnly()"  :labelCols="2" cstyle="width:12em;" label="Account #" type="text" required/>
              <sf-input v-model="data.glAccount"     :disabled="isReadOnly()"  :labelCols="2"  cstyle="width:12em;" label="GL Account" type="text" />
              <sf-input v-model="data.referenceId"   :disabled="isReadOnly()"  :labelCols="2"  label="Reference" type="text" helpText="Any value for OUR reference"/>
              <br>
              <sf-input v-model="data.dateJoined"  :disabled="isReadOnly()" :labelCols="2"  label="Date Joined" type="date" cstyle="width:12em;"/>
              <sf-input v-model="data.dateLeft"   :disabled="isReadOnly()"  :labelCols="2"  label="Date Left" type="date" cstyle="width:12em;"/>
            </b-form>
          </b-col>
          <b-col col-md="6">
            <h4>Accounting Information</h4>
            <hr />
            <div style="padding-right:20%;">
              <p>The Account # is the number assigned to us by this vendor</p>
              <p>The Vendor Id, on the GENERAL tab, is OUR # for this vendor as defined in the Accounting System</p>
              <p>You may use the Reference field to hold any information as well as the Notes field under the GENERAL tab</p>
            </div>
          </b-col>
        </b-row>
      </b-tab>

      <!-- <b-tab title="Contacts">
        <div>
          <i class="fa fa-plus-square" style="float:left;font-size:1.5em;margin-right:.5em;" @click="addContact()"/>
          <h4>Contacts</h4>
        </div>
        <div style="width:300px;margin-bottom:1em;display:inline-flex;" v-for="(contact,index) in data.contacts" :key="index">
          <contact-card
            :contact="contact"
            :isReadOnly="isReadOnly()"
            :index="index"
            :isNew="false"
            @change="saveContact($event,index)"
            @remove="deleteContact($event)"
          />
        </div>
      </b-tab> -->

      <b-tab  @click="getFiles()">
        <template #title>
          <b-spinner v-if="isImagesLoading" type="border" small style="margin-right:1em;"></b-spinner>Files
        </template>

        <file-List
            title="Documents & Images"
            :folder="uploadFolder"
            :files="files"
            :showImages="isShowImages"
            :enableDelete="true"
            :enableMultipleFileUpload="true"
            @getFiles="getFiles()"
          />

      </b-tab>


      <b-tab title="Active Tasks" @click="getVendorTasks()">
        <list-card
            title=" "
            :items="_.sortBy(taskList, ['title'])"
            :fields="fields"
            @click="editTask($event)"
            noDataPrompt="No tasks assigned to this vendor"
          >
          <template v-slot:action="data">
              <!-- <span v-if="data.item.active"  style="color:green;">open</span> -->
              <span>
                <i v-if="data.item.active && data.item.steps.complete"  class="ml-2 fa fa-check show-hand" style="color:green;"></i>
                <i v-if="data.item.closed || !data.item.steps.complete"  class="ml-2 fa fa-square-o show-hand" style="color:green;"></i>
              </span>
          </template>
        </list-card>
      </b-tab>

      <b-tab v-if="$root.isTenantFeature('certifications')" title="Certs & Trainings" @click="getCertifications()">
        <div>
        <template v-if="certificationList">
        <list-card
              title=" "
              :items="certificationList"
              :fields="certificationFields"
              :enableUpload="false"
              @click="editCertification($event)"
              noDataPrompt="No certifications or trainings assigned to this vendor"
          >
          </list-card>
        </template>
        </div>
      </b-tab>


    </b-tabs>
    <!-- <b-button @click="save" class="btn btn-sm btn-fill btn-primary">Save</b-button> -->
  </b-container>
</template>
<script>

  import iLib               from "/src/Lib/ioihanLib.js";
  import TicketService      from "/src/Services/TicketService";
  import VendorSerivce      from "/src/Services/VendorService";
  import NotesService        from "/src/Services/NotesService";
  import FileService        from "/src/Services/FileService";
  import CertificationsService   from "/src/Services/CertificationsService";
  import PieWidget          from "/src/components/UIComponents/Widgets/PieWidget.vue";
  import BarWidget          from "/src/components/UIComponents/Widgets/BarWidget.vue";

  // import TicketDisplayPage from "./TicketDisplayPage.vue";
  import TicketsLib        from "/src/Lib/TicketsLib.js";

  import Currency       from "currency.js";
  import gravatar       from "gravatar";
  import Moment         from "moment";
  import Card           from "/src/components/UIComponents/Cards/Card.vue";
  import CommentsCard   from "/src/components/UIComponents/Cards/CommentsCard.vue";
  import ContactCard2  from "/src/components/UIComponents/Cards/ContactCard3.vue";
  // import TicketCard      from "/src/components/UIComponents/Cards/TicketCard.vue";
  // import TicketTableList from "/src/components/UIComponents/Cards/TicketTableList.vue";
  import SfInput         from "/src/components/UIComponents/Inputs/SFormInput.vue";
  import ChecklistEditor from "/src/components/UIComponents/ChecklistEditor";
  import TaskList        from "/src/components/UIComponents/Cards/TaskList";
  // import ChangeOrderCard from "/src/components/UIComponents/Cards/ChangeOrderCard";
  import PageEditHeaderCard from '/src/components/UIComponents/Cards/PageEditHeaderCard.vue';
  import VueSimpleSuggest from 'vue-simple-suggest'
  import 'vue-simple-suggest/dist/styles.css' // Optional CSS
  import ListCard           from '/src/components/UIComponents/Cards/ListCard.vue';
  import ContactCard        from '/src/components/UIComponents/Cards/ContactCard.vue';
  import ContactCardExtended from '/src/components/UIComponents/Cards/ContactCardExtended.vue';
  import FileList      from "/src/components/UIComponents/Cards/FileList.vue";
  // import FileUpload       from "vue-upload-component";
  import ImageUpload      from "/src/components/UIComponents/Cards/ImageUpload.vue";

  var propId;
  //var propUser;

  export default {
    directives: {
    },
    components: {
      BarWidget,
      Card,
      ChecklistEditor,
      CommentsCard,
      ContactCard,
      ContactCard2,
      ContactCardExtended,
      FileList,
      ImageUpload,
      ListCard,
      PageEditHeaderCard,
      PieWidget,
      SfInput,
      TaskList,
      VueSimpleSuggest
    },
    props: {
      vendor : {type: Object, default: () => {}},
      categoryList: {type: Array, default: () => []},
      changed: {type: Boolean, default: false},
      editing: {type: Boolean, default: false},
    },
    watch: {
    },
    data() {
      return {
        isDataChanged   : false,
        isLoading       : false,
        isEditing       : false,
        addCategory     : false,
        data            : null,
        enableUpload    : false,
        fileRefresh     : false,
        files           : [],
        filenames       : [],
        downloadLink    : "",
        selectedTab     : 0,
        addressTab      : 0,
        taskList        : null,
        certificationList: null,
        uploadFolder    : "vendors/" + this.vendor._id.toString(),
        isShowUpload    : false,
        isImagesLoading : false,
        isShowImages    : false,
        imageUploadTitle: "Upload File",
        fields          : [
          { key: "action", label: "", sortable: true },
          { key: "steps.budgetYear", label: "Year", sortable: true },
          { key: "steps.title", label: "Title", sortable: true },
          { key: "ticketNumber", label: "Ticket #", sortable: true },
          { key: "ticketType", label: "Section", sortable: true },
          { key: "status", label: "Status", sortable: true },
          { key: "title", label: "Project", sortable: true },
          { key: "dueDate", label: "Due Date", sortable: true },

        ],
        fileColumns: [
          "actions",
          { key: "name", label: "Filename", sortable: true },
          { key: "contentSettings.contentType", label: "Type", sortable: true },
          { key: "lastModified", label: "Date Added", sortable: true },
          { key: "contentLength", label: "Size", sortable: true }
        ],
        certificationFields   : [
          // "action",
          {key: "title", label: "Title", sortable: true, tdClass: "text-left"},
          {key: "certificationType", label: "Type", sortable: true, formatter: (x) => {return this.certificationTypeName(x)}},
          {
            key: "category", label: "Category", sortable: true, tdClass: "text-left", formatter: (v1, v2, v3) => {
              return this.certificationCategoryName(v3.certificationType,
                                                    v3.category)
            }
          },
          {key: "section", label: "Section", sortable: true, tdClass: "text-left"},
          {key: "classification", label: "Classification", sortable: true, tdClass: "text-left"},
          {key: "coverage.certificationTerm", label: "# Months", sortable: true, tdClass: "center"},
          {key: "coverage.ceCredits", label: "# CE", sortable: true, tdClass: "center"},
          {key: "updatedAt", label: "Last Update", sortable: true, formatter: (x) => {return this.moment(x).fromNow()}}
        ],
      }
    },
    async created() {

      // this.ticketConfig = this.getTicketConfig();  // await TicketsLib.getTicketConfig();

      // await Promise.all([
      //   // this.getMyTicketTypes(),
      //   // this.getTicketStatuses(),
      //   // this.getTicketPriorities(),
      //   // // this.getTicketUsers(),
      //   // this.getUserOptions(),
      // ])


    },
    mounted() {
      this.data          = this.vendor;
      this.isDataChanged = this.changed;
      this.isEditing     = this.editing;
    },
    computed  : {
      totalSize() {
        let size = this.files.reduce((a, b) => { return parseInt(a) + parseInt(b["contentLength"]) }, 0)
        return size;
      },
    },
    methods: {
      currency(c) {
        return Currency(c);
      },
      sortContacts(){
        this.data.contacts = _.sortBy( this.data.contacts, ['name'] );
      },
      moment(d) {
        return Moment(d);
      },
      USD( value ) {
        return Currency(value, { precision: 2, symbol: ' ' }).format();
      },
      myGravatar( email ) {
        return gravatar.url( email );
      },
      getImage( c ) {
        if( c.pictureUrl ) {
          return c.pictureUrl;
        }
        return this.myGravatar( c.email );
      },
      dataChanged() {
        this.isDataChanged = true;
        this.isEditing     = true;
        this.$emit('changed');
      },
      async imageUploadComplete(file , serverResponse ) {
        this.data.logo = await this.tokenizeImage( file.filename , file.folder ); // response.data.fileUrl;
        this.dataChanged();
        this.isShowUpload = false;
      },
      async tokenizeImage( filename , folder = "") {   // requires a file object
        let pathname;
        if( folder == "" ) {
          pathname = filename;
        } else {
          pathname = `${folder}/${filename}`;
        }
        try {
          let response = await FileService.makeFilenameWithToken( pathname , 5256000 ); // get a 10 year token for this image.
          return response.data.fileUrl;
        } catch( error ) {
          return pathname;
        }
      },
      addContact() {
        // let address={contactType:"contact", title:"new", name: "" };
        let address = {contactType:"contact", title: "", name: "", alias:"", address1:"", city:"",  state:"" , zip:"", website: "", phone: "", email: "", cell: "", sms: "", smsCarrier: "" , TZ: "America/New_York" }
        this.data.contacts.unshift(address);
        this.addressTab = 1;
        this.dataChanged();
      },
      saveContact(contact,index){
        this.dataChanged();
        if( _.findIndex( this.data.contacts , {title: 'new' }) < 0 ) {
          this.data.contacts = _.sortBy(this.data.contacts, ['title']);
        }
      },
      async deleteContact(id) {
        let index = _.findIndex(this.data.contacts, {_id: id});
        if( index < 0 ) {
          return;
        }
        let title = this.data.contacts[index].title || "";
        if( ! await this.$root.confirmYesNo( `Remove [${title}]?` )) {
          return;
        }
        this.data.contacts.splice(index, 1);
        this.dataChanged();
      },
      formatFileSize(f) {
        return iLib.formatFileSize(f);
      },
      inputFilter(newFile, oldFile, prevent) {
        if (newFile && !oldFile) {
          // Filter non-image file
          if (!/\.(jpeg|jpe|jpg|gif|png|webp|mp4)$/i.test(newFile.name)) {
            return prevent()
          }
        }

        // Create a blob field
        newFile.blob = ''
        let URL = window.URL || window.webkitURL
        if (URL && URL.createObjectURL) {
          newFile.blob = URL.createObjectURL(newFile.file)
        }
      },
      inputFile(newFile, oldFile) {
        if (newFile && oldFile && !newFile.active && oldFile.active) {
          if (newFile.xhr) {
          }
        }
      },
      async uploadFile(file, component) {

        try {
          let folder = this.uploadFolder;//"vendors";
          let filename = file.name;
          let filesize = file.size;

          let form = new FormData()
          form.postAction = "POST";
          form.append(component.name, file.file, file.file.filename || file.name)
          form.append( 'content-type' , file.type)

          let response = await FileService.postFile(folder, form);

          this.fileRefresh = true;
        } catch (error) {
          console.log("uploadFile()", error);
          this.$root.alert( error.message , "error" );
        }
      },
      async getFiles() {
        try {
          this.files = null;
          let response        = await FileService.getFiles(this.uploadFolder);
          this.files = response.data.entries;
          this.downloadLink   = response.data.downloadLink;

          this.fileRefresh = false;
        } catch(error) {
          console.log("getFiles() Error", error);
          await this.$root.alert(error.message + " Your Files May Not Be Present - Please Call Support");
          this.contractsFiles     = [];
          this.fileRefresh    = true;
          this.$root.errorMessage("Oops", error.response.data.message || error.message);
        }
      },
      async removeFile(filename) {
        if (!confirm(`Remove ${filename}?`)) {
          return;
        }
        try {
          let folder = this.uploadFolder; // "vendors";     // we're getting the folder with the filename here...
          await FileService.deleteFile("", filename);
          this.$root.successMessage("Success", "Deleted Successfully");
          this.getFiles();
        } catch (error) {
          console.log(error);
          this.$root.errorMessage("Oops", error.message);
        }
      },
      tagValidator(tag) {
        return tag === tag.toLowerCase() && tag.length > 2 && tag.length < 15
      },
      done() {
        this.$emit("done" , this.data );
      },
      save() {
        if( this.isDataChanged ) {
          this.sortContacts();
          this.$emit("save", this.data, this.isDataChanged );
          this.isDataChanged = false;
        } else {
          this.done();
        }
      },
      toggleEditMode() {
        this.isEditing = !this.isEditing;
      },
      isReadOnly(){
        return !this.isEditing;
      },
      async editTask(task) {
        // console.log( "task" ,  task );
        // this.$router.push(`/certifications/certifications/${encodeURIComponent(cert._id)}`);
        await this.$root.alert("Edit task not implemented yet");
      },
      async clone() {
        if( ! await this.$root.confirmYesNo( "Clone this vendor?" )) {
            return;
        }
        this.dataChanged();
        this.$emit("clone", this.data );
      },
      async cancel() {
        if( this.isDataChanged ) {
          if( ! await this.$root.confirmYesNo( "You made changes, continue to leave?" )) {
            return;
          }
        }
        this.isDataChanged = false;
        this.$emit("cancel", this.data );
      },
      async getVendorTasks() {
        if( this.taskList ) {
          return;
        }
        let results = await TicketService.getVendorTasks( this.vendor._id );
        this.taskList = results.data;
      },
      async editCertification( cert ) {
        console.log( "Certificatin" ,  cert );
        this.$router.push(`/certifications/certifications/${encodeURIComponent(cert._id)}`);
        // await this.$root.alert( "edit cert" );
      },
      async getCertifications() {
        if( this.certificationList ) {
          return;
        }
        let results = await CertificationsService.getVendorCertifications( this.vendor._id );
        this.certificationList = results.data;
      },
      certificationTypeName(n) {
        return n;
      },
      certificationCategoryName(n) {
        return n;
      }
    }
  }

</script>
<style lang="scss">

  .outline-shadow {
    -webkit-box-shadow: 0 0 25px 5px rgba(0,0,0,0.35);
    box-shadow: 0 0 25px 5px rgba(0,0,0,0.35);
  }

  .collapsible {
    background-color: #eee;
    margin-top: .5em;
    border-radius: 1em;
    color: #444;
    cursor: pointer;
    padding: 10px;
    border: none;
    text-align: left;
    outline: none;
  }

  ins {
     background-color:rgba( 0,255,0,.1);
     text-decoration:none;
  }

  del {
    background-color:rgba( 255,0,0,.1);
  }

  .hover-track-light:hover{
    background: #eee;
    color:black;
  }

  .hover-track-dark:hover {
    background: #333;
    color: white;
  }


  .page-stamp {

    position: absolute;
    top: 4em;
    left: 2em;
    z-index: 1;
    font-family: Arial,sans-serif;
    -webkit-transform: rotate(-30deg); /* Safari */
    -moz-transform: rotate(-30deg); /* Firefox */
    -ms-transform: rotate(-30deg); /* IE */
    -o-transform: rotate(-30deg); /* Opera */
    transform: rotate(-30deg);
    font-size: 6em;
    color: #c00;
    background: #fff;
    border: solid 4px #c00;
    padding: 0 .5em 0 .5em;
    border-radius: .25em;
    zoom: 1;
    filter: alpha(opacity=20);
    opacity: 0.2;
    -webkit-text-shadow: 0 0 2px #c00;
    text-shadow: 0 0 2px #c00;
    box-shadow: inset 0px 0px 0px 10px #c00;
  }


  .page-stamp:after {
    content:'';
    border-radius:.25em;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url("@/assets/img/stamp-background.jpg");
    mix-blend-mode: lighten;
  }

  @media only screen and (max-device-width: 1700px) {
    .hide-too-small {
      display:none;
    }
  }


  @media print {

    @page {
      size: letter portrait;
    }

    body {
      -webkit-print-color-adjust: exact !important;
      width: 100%;
    }

    .logo {
      width: 300px !important;
      max-width: 300px !important;
    }

    .noprint {
      display: none;
    }

    .container {
      width: 100%;
      max-width: 100%;
    }
  }

</style>
