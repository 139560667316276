///-------------------------------------------------------------------------------------------------
// summary:	FAQ service class
///-------------------------------------------------------------------------------------------------

import Api from '@/Services/Api';


export default {

  getFaqs() {
    const response = Api().get('/api/1/notes/portal-faq');
    return response;
  },
  getFaq( id ) {
    const response = Api().get(`/api/1/note/${encodeURI(id)}`);
    return response;
  },
  addFaq( faq ) {
    faq.noteType = "portal-faq";    // let's make sure its tagged for our portal use.
    const response = Api().post(`/api/1/note/portal-faq`, faq );
    return response;
  },
  saveFaq( id ,  faq ) {
    faq.noteType = "portal-faq";      // just in case this got hosed.
    const response = Api().patch(`/api/1/note/${encodeURI(id)}`, faq );
    return response;
  },
  removeFaq( id ) {
    const response = Api().delete(`/api/1/note/${encodeURI(id)}`);
    return response;
  },

}
