<template>
  <b-container fluid>
    <b-form  @input="dataChanged()">
      <b-form-group v-if="$root.isAdmin()" label-cols-lg="1" label-size="lg" label-class="font-weight-bold pt-0" class="mt-0 mb-0" :label="formData.department.toUpperCase() + ' ' + formData.category.charAt(0).toUpperCase() + formData.category.slice(1)">
        <div v-if="!isVisiblePolicyHeader" class="show-hand" @click="isVisiblePolicyHeader=true">
          <i class="fa fa-chevron-down show-hand" style="color:gray;float:left;position:relative;top:.2em;"></i>
          <span style="font-style:italic;font-size:.8em;">Show Details</span>
        </div>
        <div v-if="isVisiblePolicyHeader" class="show-hand" @click="isVisiblePolicyHeader=false">
          <i class="fa fa-chevron-up show-hand" style="color: gray; float: left; position: relative; top: .2em;"></i>
          <span style="font-style:italic;font-size:.8em;">Hide Details</span>
        </div>
        <b-collapse id="collapse-policy-header" v-model="isVisiblePolicyHeader">
          <div class="form-version">Edit Version: <span v-b-popover.hover.left="'Updated: ' + moment(formData.updatedat).tz($root.TZ).format('LLL') + ' by ' + formData.lastUpdateBy">{{formData.version}} : {{moment(formData.updatedAt).fromNow()}}</span></div>
          <b-form-group class="fg-input" label-cols="2" label="Active?">
            <b-form-checkbox switch class="cb-input" v-model="formData.active" />
          </b-form-group>
          <b-form-group class="fg-input-right" label-cols="2" >
            <b-form-checkbox switch  class="cb-input-left" v-b-popover.hover.top="'Enable to make this a template'" v-model="formData.isTemplate">Template</b-form-checkbox>
            <b-form-checkbox switch v-if="$root.user.isSA" class="cb-input-left" v-b-popover.hover.top="'Is Global'" v-model="formData.isGlobal" >Global</b-form-checkbox>
          </b-form-group>

          <sf-input type="text" cstyle="width:80%;" :label-cols="2" helpText="Title of this document" label="Title" v-model="formData.title"></sf-input>
          <sf-input type="textarea" :label-cols="2" :rows="4" :maxlength="250" helpText="Short description of this document" label="Overview" v-model="formData.shortDescription"></sf-input>
          <hr />
          <sf-input type="list" cstyle="width:10em;" :label-cols="2" label="Category" :options="['procedure','policy','checklist','announcement']" v-model="formData.category" @change="categoryChanged"></sf-input>
<!--          <sf-input type="text" cstyle="width:15em;" :label-cols="2" label="Department" v-model="formData.department"></sf-input>-->
<!--          <sf-input type="text" cstyle="width:15em;" helpText="Your policy reference" :label-cols="2" label="Policy Reference" v-model="formData.policyReference"></sf-input>-->
          <b-form-group class="fg-input" label-cols="2" label="Tags">
            <b-form-tags tag-pills size="sm" v-model="formData.tags" name="tag" :tag-validator="tagValidator" @input="dataChanged()" />
            <template v-slot:description>
              <div id="tags-validation-help">
                Tags must be 3 to 16 characters in length and all lower case.
              </div>
            </template>
          </b-form-group>

          <!--<b-form-group class="fg-input" label-cols="2" label="Description">
      <b-form-textarea rows=10 v-model="formData.description" />
    </b-form-group>-->
        </b-collapse>
      </b-form-group>

      <div style="height:78vh;max-height:78vh;overflow:auto;">
        <wysiwyg v-if="$root.isAdmin()" v-model="formData.description"></wysiwyg>
<!--       <vue-editor v-if="$root.isAdmin()" v-model="formData.description"  @selection-change="dataChanged()" />-->
        <div v-if="!$root.isAdmin()" v-html="formData.description"></div>
      </div>
    </b-form>
  </b-container>
</template>

<script>
  import iLib    from "/src/Lib/ioihanLib.js";
  import Moment  from "moment";
  import SfInput from "/src/components/UIComponents/Inputs/SFormInput.vue";
  // import {VueEditor}      from "vue2-editor";

  export default {

  props: {
    formData: { type: Object, default: () => { } },
    itemName: { type: String, default: "Checklist"}
    },
  components: {
      SfInput,
      // VueEditor
    },
  data() {
    return {
      isVisiblePolicyHeader: true,
      isDataChanged: false
    }
  },
  mounted() {
    if (this.formData.title.length > 0) {
      this.isVisiblePolicyHeader = false;
    }
  },
  methods: {
    dataChanged() {
      this.isDataChanged = true;
      this.$emit( "dataChanged"  , true );
    },
    moment(d) {
      return Moment(d);
    },
    text(data) {
      return iLib.html2Text(data);
    },
    categoryChanged(category) {
      this.$emit("categoryChanged", category);
    },
    resetDataChanged() {
      this.isDataChanged = false;
    },
    tagValidator( tag ) {
      return tag === tag.toLowerCase() && tag.length > 2 && tag.length <= 16
    }
  }
};
</script>

<style lang="scss" scoped>

  .fg-input {
    margin:0;
    padding: 0 .25em .25em 0;
    text-align:right;
  }

  .fg-input-right {
    position:absolute;
    right:0;
    margin-left:2em;
  }

  .cb-input {
    text-align:left;
    top:5px;
  }

  .cb-input-right {
    text-align:right;
    top:5px;
    z-index:2;
  }

  .form-version {
     position:absolute;
     top:0;
     right:10px;
     span {
       font-weight:800;
     }
  }

  @media only screen and (min-width: 320px) and (max-width: 575px) {
    .container-fluid {
      padding: 0;
    }
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .container-fluid {
      padding: 0;
    }
  }
</style>>
