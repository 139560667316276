<template>
  <div class="content">
    <div class="container-fluid">

      <div class="row">
        <div class="col-md-12">
          <div class="noprint">
            <div v-if="reportFormat">
              <b-button class="btn btn-sm btn-fill" variant="success" style="border-radius:.75em;float:left;margin-right:1em;" @click="$router.push('/reports/menu')">Done</b-button>
              <h3 class="card-title" style="margin-bottom:0;">{{reportFormat.title}}</h3>
              <p style="margin-left:4em;width:50%;font-style:italic;">{{reportFormat.description}}</p>
            </div>
            <hr />
            <div style="background-color:#ddd;border-radius:1em;padding:2em;">
              <h4 style="margin-top:0;">Report Options</h4>
              <b-form-checkbox switch style="margin-bottom:0em;" v-model="omitZeros">Hide Zero Balance Accounts</b-form-checkbox>
              <b-form-checkbox switch style="margin-bottom:2em;" v-model="omitInactive">Hide Inactive Accounts</b-form-checkbox>
              <b-button v-if="!report && reportFormat" class="btn btn-fill" style="border-radius:1em;font-weight:600;" variant="warning" @click="runReport( reportFormat.filter.totalClass,reportFormat.subTitle)"><span v-show="isBusy" style="margin-right:1em;" :class="isLoading"></span>Run Report:  {{reportFormat.title}}</b-button>
             
            </div>
            
            <hr>
          </div>
          <div v-if="report">
 
            <div>
              <div class="show-hand noprint" style="float:right;position:relative;top:60px;">
                <!--<i class="fa fa-file show-hand noprint" style="font-size:1.25em;padding-right:1.5em;" v-b-popover.hover.top="'Export This Report As CSV'" @click="exportReport()"></i>-->
                <download-csv :data="report" :advancedOptions="exportOptions" >
                  <i class="fa fa-file-o show-hand " style="font-size:1.25em;padding-right:1.5em;" v-b-popover.hover.top="'Export This Report As CSV'"></i>
                </download-csv>
                <i class="fa fa-print show-hand " style="font-size:1.25em;padding-right:2em;"  @click="printReport()"></i>
              </div>
              <div style="float:right;font-size:1.25em;">Total: ({{reportTotalQty}}) ${{currency(reportTotal)}}</div>
              <div style="text-align:left;">Report Date: <span style="font-weight:600;">{{moment().format("LLL")}}</span></div>

              <h4 class="report-header mb-0">{{reportFormat.title}}</h4>
              <div class="sub-title" style="text-align:left;font-size:.9em;font-style:italic">{{subTitle}}</div>
            </div>
            <b-table outlined striped hover head-variant="dark" :busy="isBusy" :style="reportFormat.tableStyle" class="balance-table" :items="systemTotals" :fields="reportFormat.reportFields">
              <template v-slot:cell(active)="data">
                <i v-if="!data.item.active" class="fa fa-times" style="color:red;"></i>
              </template>
              <template v-slot:cell(total.totalClassId)="data">
                {{total(data.item.total.totalClassId)}}
              </template>
              <template v-slot:cell(total.total.chargeTotal)="data">
                <div class="format-right">{{currency(data.item.total.total.chargeTotal)}}</div>
              </template>
              <template v-slot:cell(total.total.paymentTotal)="data">
                <div class="format-right">{{currency(data.item.total.total.paymentTotal)}}</div>
              </template>
              <template v-slot:cell(total.lastChargeDate)="data">
                {{(!data.item.total.lastChargeDate)? "-" : moment(data.item.total.lastChargeDate).fromNow()}}
              </template>
              <template v-slot:cell(total.lastPaymentDate)="data">
                {{(!data.item.total.lastPaymentDate)? "-" : moment(data.item.total.lastPaymentDate).fromNow()}}
              </template>
              <template v-slot:cell(balance)="data">
                <div class="format-right" :class="(data.item.balance>0) ? 'show-negative' : ''">{{currency(data.item.balance)}}</div>
              </template>

            </b-table>
          </div>

        </div>


    </div>
    </div>
  </div>
</template>

<script>

  //import jsreports from "./jsreports";
  import AccountService from "/src/Services/AccountService";
  import TotalService from "/src/Services/TotalService";
  import QueryService from "/src/Services/QueryService";
  import ReportService from "/src/Services/ReportService";
  import AccountFilter from "/src/components/UIComponents/AccountFilter.vue";
  // import TenantService from "/src/Services/TenantService";
  import Card from "/src/components/UIComponents/Cards/Card.vue";
  import LTable from "/src/components/UIComponents/Table.vue";
  import Currency from "currency.js";
  import Moment from "moment";
  import Papa from "papaparse";

  var reportFormatId;

  export default {
    components: {
      Card,
      LTable,
      AccountFilter
    },
    props: {
      reportFormatId
    },
    computed: {
      systemTotalProfiles() {
        return this.totals.filter((t) => { return t.title !== 'DELETE' } );
      },
      systemTotals() {
        let t = this;
        if (!this.report) {
          t.filteredReport = [];
          return [];
        }

        if (!this.omitZeros && !this.omitInactive) {
          this.reportTotalQty = this.report.length;
          t.filteredReport = this.report;
          return this.report
        }

        let report = this.report.filter((t) => {
          if (this.omitZeros) {
            if (this.currency(t.balance).value === 0) {
              return false;
            }
          }
          if (this.omitInactive) { return t.active; }

          return true;
        });

        this.reportTotalQty = report.length;
        t.filteredReport = report;
        return report;
      }
    },
    data() {
      return {
        reportFormat: null,
        isBusy: false,
        isLoading: "fa fa-spinner  fa-refresh-animate",
        exportFields: [
          'active',
          'total.totalType',
          'accountNumber',
          'accountTitle',
          'glAccount',
          'arType',
          'balance',
          'total.total.lastChargeDate'
        ],
        exportOptions: {
          name: "AccountBalance.csv",
	        quotes: [true,true,true,true,true,true,false,true], //or array of booleans
	        quoteChar: '"',
	        escapeChar: '"',
	        delimiter: ",",
	        header: true,
	        newline: "\r\n",
	        skipEmptyLines: false, //or 'greedy',
	        columns: [
            'active',
            "total['totalType']",
            'accountNumber',
            'accountTitle',
            'glAccount',
            'arType',
            'balance',
            'total.lastChargeDate'
          ]
        },
        //{
        //  'active': 'active',
        //  'totalType': 'total.totalType',
        //  'account': 'accountNumber',
        //  'accountTitle': 'accountTitle',
        //  'glAccount': 'glAccount',
        //  'arType': 'arType',
        //  'balance': 'balance',
        //  'lastCharge' : 'total.total.lastChargeDate'
        //},
        fields: [
          { key: "active", label: "NA", sortable: true },
          { key: "total.totalType", label: "Total Type", sortable: true },
          { key: "accountType", label: "Type", sortable: true },
          { key: "total.totalClassId", label: "Profile", sortable: true },
          { key: "accountTitle", label: "Title", sortable: true },
          { key: "accountNumber", label: "Account #", sortable: true },
          { key: "glAccount", label: "GL Account", sortable: true },
          { key: "arType", label: "AR Type", sortable: true },
          { key: "total.lastChargeDate", label: "Last Charge", sortable: true },

          //{ key: "total.total.chargeTotal", label: "Charges", sortable: true },
          //{ key: "total.total.paymentTotal", label: "Payments", sortable: true },
          { key: "balance", label: "Balance", sortable: true },

        ],
        omitZeros: true,
        omitInactive:true,
        accountTypes: null,
        accountClasses: null,
        accountClassSelection: [],
        name: "Report Menu",
        title: "Test Report",
        subTitle: "Report Sub Title Goes Here",
        totals: [],
        report: null,
        filteredReport: null,
        reportTotal: 0,
        reportTotalQty : 0
      };
    },
    async created() {
      let t = this;
      try {
        await Promise.all([
          //t.getRevenueCenters(),
          //t.getAccountTypes(),
          //t.getTenders(),
          t.getReportFormat(this.reportFormatId),
          t.getAccountClasses(),
          t.getTotals()
        ]);
      } catch (err) {
        console.log("Transactions Mounted error: ", err);
        if (err.response) {
          if (err.response.data) {
            t.$root.errorMessage("Oops", err.response.data.message);
          }
        } else {
          t.$root.errorMessage("Oops", "Had an error getting initial settings");
        }
      }

    },
    mounted() {

    },
    methods: {
      currency(c) {
        return Currency(c);
      },
      moment(d) {
        return Moment(d);
      },
      printReport() {
        window.print();
      },
      exportReport() {
        console.log("Export data", Papa.unparse(this.filteredReport, this.exportOptions));

        this.csvExport(this.filteredReport);
      },
      csvExport(r) {
        //var csvContent = "data:text/csv;charset=utf-8,";
        //console.log("Exporting", JSON.stringify(r, null, 2));

        //csvContent += r.map(function(d){
        //  //console.log(d);
        //  return JSON.stringify(d);
        //})
        //.join('\n') 
        //.replace(/(^\{)|(\}$)/mg, '');
        //window.open( encodeURI(csvContent) );
      },
      total(id) {
        let t = this;
        let i;
        if (t.totals.length > 0) {
          for (i = 0; i < t.totals.length; i++) {
            if (t.totals[i].totalClassId == id) {
              return "" + id + " : " + t.totals[i].title;
            }
          }
        }
        return "" + id
      },
      async getAccountTypes() {
        try {
          let results = await AccountService.getAccountTypes();
          this.accountTypes = results.data;
        } catch (error) {
          console.log(error);
        }
      },
      async getAccountClasses() {
        try {
          let results = await AccountService.getAccountClasses();
          this.accountClasses = results.data; //.accountClass;
          //console.log("result Data", JSON.stringify(this.accountClasses , null, 2));
        } catch (error) {
          console.log(error);
        }
      },
      async getTotals() {
        var response;
        var t = this;
        try {
          response = await TotalService.getTotals();
          t.totals = response.data;

        } catch (err) {
          if (err) {
            alert(
              "oops hit error in getTotals",
              err
            );
            t.$root.errorMessage("Oops", "Had a problem getting the totals listing");
          }
          return;
        }
      },
      async getReportFormat(id) {
        //console.log("getReportFormat()", id);
        try {
          let result = await ReportService.getReportFormat(id);
          this.reportFormat = result.data;
          //console.log("reportFormat= ", JSON.stringify(this.reportFormat, null, 2));
          // could put stuff away here or just use it in the raw format 
        } catch (error) {
          console.log("Report Error", error.message);
        }
      },
      async runReport(totalClassId,title) {

        try {
          this.isBusy = true;
          this.subTitle = `${title}`;
          let query = {};
          if (this.reportFormat.filter.accountClass && this.reportFormat.filter.accountClass.length>0) {
            query.accountClassId = this.reportFormat.filter.accountClass.join("|");
          }
          //console.log("query=", JSON.stringify(query, null, 2));

          let result = await QueryService.getAccountBalance(totalClassId, query);
          this.report = result.data
          this.reportTotal = 0;
          if (this.report.length > 0 && this.report[0].balance !== undefined ) {
            this.report.forEach((t) => { this.reportTotal = Currency(this.reportTotal).add(t.balance) });
          }
        } catch (error) {
          console.log(error); // oops
        }
        this.isBusy = false;
      }
    }
  };

</script>
<style language="scss" >

  @media print {


    .thead-dark {
      page-break-before:avoid;
      margin:0;
      margin-top:3em;
      padding:0 2em 0 0;
      background-color:black !important;
    }
    .noprint {
      display: none;
    }
    
    .report-header {
      span {
        font-size: 1.75em;
      }
    }

    i {
      display:none;
    }
    .sub-title {
      page-break-after:avoid;
      width:100%;
      margin-bottom: 1em;
      border-bottom: 2px solid black;
    }
  }

  .format-right {
    text-align:right;
  }

  .balance-table {
    font-size: 10pt;
    line-height: 2px;

    thead {
      padding-bottom:1em;
    }

    show-negative {
      background-color:red !important;
      font-weight:600;
    }
  }
</style>
