<template>
    <div>

      <div>
        <b-button v-if="!isChanged" class="btn btn-sm btn-fill btn-success" style="float:left;margin-right:1em;" @click="done()">Done</b-button>
        <b-button v-if="isChanged" class="btn btn-sm btn-fill btn-success" style="float:left;margin-right:1em;" @click="save()">Save</b-button>
        <b-button v-if="isChanged" class="btn btn-sm btn-fill btn-warning" style="float:left;margin-right:1em;" @click="cancel()">Cancel</b-button>
        <h4>Donations Configuration</h4>
      </div>
      <hr>

      <b-tabs v-if="donationsConfig" id="donationsConfig" v-model="selectedTab" class="tab-hover" content-class="mt-2 ml-3 mr-3" active-nav-item-class="selected-tab" >

        <b-tab title="Overhead">
          <div style="height:650px;padding-right:1em;overflow-y:auto;overflow-x:hidden;">
            <form style="margin-top:2em;" @input="dataChanged()">
              <sf-input label="Notes" :labelCols="1" :rows="18" type="textarea" v-model="donationsConfig.notes"></sf-input>
            </form>
          </div>
        </b-tab>

        <b-tab title="Purpose Setup" >

          <div v-if="donationsConfig.purposeList.length===0" style="padding:2em;">
            <b-button class="btn btn-sm btn-fill btn-success" @click="addNew()">Let's begin.  Yep, start configuration!</b-button>
          </div>

          <div v-if="donationsConfig.purposeList.length>0" style="min-height:650px;" >
            <b-tabs vertical card class="tab-hover-left" v-model="selectedSubTab" content-class="mt-2 ml-3 mr-3" active-nav-item-class="selected-tab-left" >

              <b-tab :title="purpose.title.substring(0,18)" style="border-left:4px solid royalblue;" v-for="(purpose,index) in donationsConfig.purposeList" :key="index">
                <div>

                  <div style="width:4em;text-align:right;position:absolute;right:0;top:-5px;font-size:1.4em;">
                    <i class="fa fa-plus-square show-hand ml-3 mr-3"  v-b-popover.hover.top="'Add New'" @click="addNew()"></i>
                    <i class="fa fa-trash show-hand" v-b-popover.hover.top="'Remove this entry'" @click="removePurpose(index)"></i>
                  </div>

                  <b-form @input="dataChanged()">
                    <sf-input v-model.trim="purpose.title" :labelCols="2" type="text" cstyle="width:25em;" label="Title" helpText="A user-friendly title of this purpose"/>
                    <sf-input v-model.trim="purpose.purpose" :labelCols="2" type="text" cstyle="width:12em;" label="Purpose" />
                    <sf-input v-model.trim="purpose.glAccount" :labelCols="2" type="text" cstyle="width:12em;" label="GL Account" />
                    <sf-input v-model.trim="purpose.fund" :labelCols="2" type="text" cstyle="width:12em;" label="Fund" />
                  </b-form>

                  <p style="font-size:.8em;color:darkgray;width:50%;margin-top:2em;margin-left:auto;margin-right:auto;">
                    NOTE: Changing these values will NOT automatically change the values on the tiles.  If you change the title, it will reflect
                    correctly, however, changing the glAccount or Fund will NOT change the values on the Tile.
                  </p>

                </div>
              </b-tab>

            </b-tabs>

          </div>
        </b-tab>

      </b-tabs>


    </div>
</template>

<script>
  // import AccountService from "/src/Services/AccountService";
  import TenantService  from "/src/Services/TenantService";
  // import QueryService   from "/src/Services/QueryService";
  import Moment         from "moment-timezone";
  import Card           from "/src/components/UIComponents/Cards/Card.vue";
  import OptionManagementTable from "/src/components/UIComponents/Cards/OptionManagementTable.vue";
  import SfInput        from "/src/components/UIComponents/Inputs/SFormInput.vue";
  import LTable         from "/src/components/UIComponents/Table.vue";

  export default {
  components: {
    LTable,
    Card,
    SfInput,
    OptionManagementTable
  },
  data() {
    return {
      saveDisabled        : true,
      isChanged           : false,
      isAdding            : false,
      isEditing           : false,
      small               : true,
      selectedTab         : 0,
      selectedSubTab      : 0,
      selectedDonationsTab: 0,
      donationsConfig     : null,
    };
  },
  watch: {
  },
  mounted: async function() {
    await Promise.all([
      this.getDonationsConfig()
    ]);
  },
  async beforeRouteLeave( to , from, next ) {
    if( !this.isChanged ) {
      return next();
    }
    if( await this.$root.confirm('You have unsaved changes, continue leaving without saving?', "warning")) {
      return next();
    }
    next(false)
  },
  methods: {
    done() {
      this.$router.go(-1);
    },
    async alert(e) {
      await this.$root.alert( e );
    },
    async save() {
      await this.saveDonationsConfig();
      this.done();
    },
    async cancel() {
      if( this.isChanged ) {
        if( ! await this.$root.confirm( "Data changed -- You sure?" )) {
          return;
        }
      }
      this.$router.go(-1);
    },
    moment(d) {
      return Moment(d);
    },

    dataChanged() {
      this.isChanged = true;
    },

    showModal () {
      this.$refs.myModalRef.show()
    },
    hideModal () {
      this.$refs.myModalRef.hide()
    },
    async addNew() {
      if( ! await this.$root.confirm( "Add New Type" )) {
        return;
      }
      this.donationsConfig.purposeList.push( {
                                           title: "new" ,
                                           purpose: "",
                                           glAccount: "",
                                           fund: ""
                                         });
      this.dataChanged();
    },
    async removePurpose( index ) {
      if( ! await this.$root.confirm( "Remove " + this.donationsConfig.purposeList[ index ].title + "?" )) {
        return;
      }
      this.dataChanged();
      this.donationsConfig.purposeList.splice(index,1);
    },

    sortPurposeList() {
      this.donationsConfig.purposeList = _.sortBy( this.donationsConfig.purposeList, (a) => a.title );
    },
    async getDonationsConfig() {
      var t = this;
      var response;
      try {
        response = await TenantService.getDonationsConfig( );
        this.donationsConfig = response.data;

        this.sortPurposeList();

      } catch (err) {
        console.log("getDonationsConfig error: ", err);
        if (err.response) {
          if (err.response.data) {
            t.$root.errorMessage("Oops", err.response.data.message);
          }
        } else {
          t.$root.errorMessage("Oops", "Had an error getting the donations configuration");
        }
      }
    },

    async saveDonationsConfig() {
      var t = this;
      var response;
      try {
        this.sortPurposeList();
        response = await TenantService.saveDonationsConfig(this.donationsConfig);
        this.$root.tenant.donationsConfig = response.data;
        t.$root.successMessage("SUCCESS", "Donations Config Updated");
        t.isChanged = false;
      } catch (err) {
        if (err.response) {
          console.log("error response ", err.response);
          t.$root.errorMessage("Oops", err.response.data.message);
        } else if (err.request) {
          console.log("Error Request ", err.request);
        } else {
          console.log("message only ", err.message);
        }
        t.$root.errorMessage("Oops", "Unable to Save Donations Configuration Data");
      }
    }
  }

};
</script>

<style lang="scss">

</style>
