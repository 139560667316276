<template>
  <div class="content">
    <!-- <div style="display:flex;">
      <b-button class="btn btn-sm btn-primary" @click="done">Done</b-button>
      <b-button class="btn btn-sm btn-warning" v-if="isDataChanged" @click="save">Save</b-button>
      <h4>Certification Details</h4>
    </div> -->
    <page-edit-header-card
        style="margin-bottom:1em;"
        title="Certification Details"
        :enableClone="false"
        :isChanged="isDataChanged"
        @save="save"
        @cancel="done"
        @done="done"
    />
    <hr />
    <div v-if="data">

      <!--      <b-modal ref="assign-certificate" title="Assign Master Record" size="lg" style="padding:0 1em 0 1em;" scrolable ok-only @click="$refs['assign-certificate'].hide();">-->

      <!--        <div style="">-->
      <!--          <live-search-->
      <!--              style="width:100%;"-->
      <!--              :searchItem="certificationSearchField"-->
      <!--              :searchMethod="certificationSearch"-->
      <!--              valueField="_id"-->
      <!--              displayField="title"-->
      <!--              displayAttribute="background:lime"-->
      <!--              placeHolder="Type Master Record Search..."-->
      <!--              @select="$refs['assign-certificate'].hide();assignCertificationToAccount($event)"-->
      <!--          >-->
      <!--            <template v-slot:list-item="suggestion">-->
      <!--              <div style="line-height:1em;">-->

      <!--                <span style="font-weight:600;font-size:1.1em;margin-right:1em">{{ suggestion.title }}</span>-->
      <!--                <span> ({{ suggestion.certificationType }}) </span>-->
      <!--                <span v-if="suggestion.category">  Category: ({{ suggestion.category }})  </span>-->

      <!--                <span v-if="suggestion.vendorName">  Vendor: ({{ suggestion.vendorName }}) </span>-->
      <!--                <span v-if="suggestion.classification">  Classificatin: ({{ suggestion.classification }})</span>-->
      <!--              </div>-->
      <!--            </template>-->
      <!--          </live-search>-->
      <!--        </div>-->
      <!--      </b-modal>-->

      <b-row>
        <b-col class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
          <b-form @input="dataChanged()">

            <!-- <div  style="width:10em;background:lime;position:absolute;right:20px;font-weight:100;">
              <b-button v-if="!data.certificationNumber" class="btn btn-sm btn-warning btn-fill" @click="$refs['assign-certificate'].show();">Assign</b-button>
              <b-button v-if="data.certificationNumber" class="btn btn-sm btn-warning btn-fill" @click="$refs['assign-certificate'].show();">Re-Assign</b-button>
            </div> -->
            <b-checkbox v-model="data.active">Active</b-checkbox>
            <b-button v-if="!data.renewal && !isDataChanged" class="btn btn-sm btn-warning btn-fill float-right" @click="renew()">Renew</b-button>
            <!-- <sf-input v-model="data.certificationNumber" type="text" label="Number" :labelCols="2" cstyle="width:25em;" :disabled="isReadOnly"/> -->
            <!--            <sf-input v-if="!data.certificationNumber" v-model="data.certificationTitle" type="icon-text" label="Title" :labelCols="2" cstyle="width:25em;" appendIcon="fa fa-chain-broken show-hand" @appendClick="$refs['assign-certificate'].show()" v-b-popover.hover.top="'Link this to a master record'" :disabled="isReadOnly" />-->

            <!--            <div v-if="data.certificationNumber" style="">-->
            <!--              <sf-input v-model="data.certificationTitle" type="icon-text" label="Title" :labelCols="2" cstyle="width:25em;" appendIcon="fa fa-link show-hand" @appendClick="$refs['assign-certificate'].show()" v-b-popover.hover.top="'Change master-record link'" :disabled="isReadOnly" />-->
            <!--              <sf-input v-model="data.certificationType" type="text" label="Type" :labelCols="2" cstyle="width:12em;" :readonly="true" />-->
            <!--            </div>-->

            <sf-input
                id="new-cert-certification"
                iref="certAdd-0001"
                :labelCols="2"
                cstyle="width:25em;"
                :fetchFunction="certificationSearch"
                fetchValueField="title"
                fetchKeyField="certificationNumber"
                label-style="font-weight:700;" f
                combo-style="font-weight:500;"
                v-model="data.certificationTitle"
                type="combo"
                label="Certificate"
                @listSelect="selectCertificate( $event )"
            />

            <sf-input v-model="data.certificationType" :disabled="true" type="text" label="Type" :labelCols="2" cstyle="width:12em;" />
            <sf-input v-model="data.expireDate" type="tz-date" :TZ="$root.TZ" label="Expire Date" :labelCols="2" cstyle="width:12em;" @change="expireDateChanged($event)"/>
            <sf-input v-model="data.activeDate" type="date" :TZ="$root.TZ" label="Issue Date" :labelCols="2" cstyle="width:12em;" @change="activeDateChanged($event)" />
            <sf-input v-model="data.term" type="number" label="Term" :labelCols="2" cstyle="width:12em;" />
            <sf-input v-model="data.notes" type="textarea" label="Notes" :labelCols="2" :rows="8" cstyle="width:25em;" />
            <div v-if="data.renewal" style="margin-left:17%;margin-top:1em;">Renewal ID: {{
                data.renewal
              }}<i v-if="$root.isAdmin()" style="margin-left:2em;" class="fa fa-trash show-hand" @click="removeRenewal()" v-b-popover.hover.top="'Remove this renewal reference; This does NOT remove the other newly creaetd record'"></i>
            </div>
            <template v-if="data.certificate">
              <hr>
              <h5>Certificate Details</h5>
              <table>
                <tr v-if="data.certificate.licenseNumber">
                  <td>License Number</td>
                  <td>{{ data.certificate.licenseNumber }}</td>
                </tr>
                <tr v-if="data.certificate.licenseType">
                  <td>Classification</td>
                  <td>{{ data.certificate.licenseType }}</td>
                </tr>
                <tr v-if="data.certificate.licenseState">
                  <td>State</td>
                  <td>{{ data.certificate.licenseState }}</td>
                </tr>
                <tr v-if="data.certificate.reference">
                  <td>Reference</td>
                  <td>{{ data.certificate.reference }}</td>
                </tr>
              </table>
            </template>
          </b-form>
        </b-col>
        <b-col class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
          <!-- <list-card
            title="History"
            :items="data.history"
            :fields="historyFields"
            :enableAdd="false"
            :enableExport="false"
            :enableUpload="false"
            :enablePaging="true"
            :enableDelete="true"
            @delete="removeHistoryItem($event)"
          >
          </list-card> -->
          <div>
            <h4 style="margin-top:0;background:#ccc;width:100%;padding:.5em;border-radius:.25em;text-align:center;">Help Information</h4>
            <p>Using the "Renew" button will automatically renew the entry by creating a new record and tagging the existing entry as "renewed"</p>
            <p>The Renew button will disapear if you are making changes to this entry. If you need to make changes before renewing, make your changes, save, and then select this
              item again to Renew</p>
            <h4 style="background:#ccc;width:100%;padding:.5em;border-radius:.25em;text-align:center;">History</h4>
            <table class="log-entry" style="width:100%;padding: 5px;">
              <template v-for="(h, hIndex) in data.history">
                <tr class="action-row" :key="hIndex">
                  <td rowspan="2"><span class="action-name">{{ h.action }}</span></td>
                  <td><span class="action-date">{{ moment(h.actionDate).format("LLLL") }}</span><span class="manager-name">By: {{
                      h.manager
                    }}</span><i v-if="$root.isAdmin()" class="fa fa-trash float-right show-hand" style="position:relative;top:10px;" @click="removeHistoryItem($event)" v-b-popover.hover.left="'Remove this entry'"></i>
                  </td>
                </tr>
                <tr>
                  <td colspan="3">
                    <div class="title">{{ h.title }}</div>
                  </td>
                </tr>
              </template>
            </table>
          </div>

        </b-col>
      </b-row>
    </div>
    <div v-if="!data">
      <p>Loading Certification...</p>
    </div>

  </div>
</template>
<script>

import PageEditHeaderCard    from '/src/components/UIComponents/Cards/PageEditHeaderCard.vue';
import Moment                from "moment";
import Currency              from "currency.js";
import iLib                  from "/src/Lib/ioihanLib.js";
import Card                  from '/src/components/UIComponents/Cards/Card.vue'
import SfInput               from "/src/components/UIComponents/Inputs/SFormInput.vue";
import ChecklistEditor       from "/src/components/UIComponents/ChecklistEditor";
import QueryService          from "/src/Services/QueryService.js";
import CertificationsService from "/src/Services/CertificationsService.js";
import AccountService        from "/src/Services/AccountService.js";
import LiveSearch            from "/src/components/UIComponents/Inputs/LiveSearch.vue";
import ListCard              from '/src/components/UIComponents/Cards/ListCard.vue';
import exportLib             from "../../../Lib/dataExportLib";

export default {
  name      : "procedures",
  components: {
    Card,
    SfInput,
    ChecklistEditor,
    ListCard,
    LiveSearch,
    PageEditHeaderCard
  },
  props     : {
    account       : {type: Object, default: null},
    certification : {type: Object, required: true},
    certifications: {type: Array, default: () => []},
    // certificationTypes: {type: Array, default: () => []}
  },
  data() {
    return {
      isDataChanged           : false,
      certificationSearchField: "",
      isReadOnly              : false,
      data                    : null,     // our "working" certification
      foundCertification      : null,
      historyFields           : [
        {key: "action", label: "", sortable: true},
        {key: "title", label: "Description", sortable: true},
        {key: "actionDate", label: "When", sortable: true, formatter: (x) => {return this.moment(x).fromNow()}},
        {key: "points", label: "Points", sortable: true},
        // { key: "score", label:"Score" , sortable: true },
        {key: "manager", label: "Who", sortable: true},
        // { key: "notes", label:"Notes" , sortable: true },
      ],
    }
  },
  async created() {
    await this.getAccountCertification(this.certification._id);
    this.setFocusOnAccountField();
    if(! this.data.certificationNumber ) {
      this.dataChanged();
    }
  },
  computed: {},
  async beforeRouteLeave(to, from, next) {
    if(!this.isDataChanged) {
      return next();
    }
    if(await this.$root.confirmYesNo('You have unsaved changes, continue leaving without saving?', "warning")) {
      return next();
    }
    next(false)
  },
  methods: {
    iLib() {
      return this.iLib;
    },

    moment(d) {
      return Moment(d);
    },
    formatDate(d) {
      if(d) {
        return Moment(d).tz(this.$root.TZ).format("ddd Do MMM-YY HH:mm a");
      }
      return "";
    },
    USD(value) {
      return Currency(value, {precision: 2, symbol: ' '}).format();
    },
    async done() {
      // this.$router.go(-1);

      if(this.isDataChanged) {
        if(!await this.$root.confirmYesNo("You have pending changes, are you sure you want to cancel?")) {
          return;
        }
      }

      this.$emit("done", this.data, this.isDataChanged);
    },
    setFocusOnAccountField() {
      this.$nextTick(() => {   // Next tick because sometimes the element is hidden (v-if)
        console.log("refs", this.$refs);
        let el = document.getElementById("new-cert-certification");
        if(el) {
          el.focus();
          el.select();
        } else {
          console.log("no element");
        }
      })
    },
    async removeRenewal() {
      if(!await this.$root.confirmYesNo("Remove Renewal Reference?")) {
        return;
      }

      let history       = {
        action    : "remove",
        actionDate: new Date(),
        title     : `Removed Renewal Record Id:${this.data.renewal}`,
        manager   : this.$root.user.alias
      }
      this.data.renewal = null;
      this.data.history.unshift(history);
      this.dataChanged();
    },
    certificationTypeName(id) {
      if(this.certificationTypes) {
        let x = _.find(this.certificationTypes, {recordType: id});
        if(x) {
          return x.title;
        }
      }
      return "**" + id;
    },
    activeDateChanged(e) {
      console.log("accountCertificationEdit() -> Active Date Changed", e);
      this.data.expireDate = Moment(e).tz(this.$root.TZ).add(this.data.term, 'months').format("YYYY-MM-DD");
    },
    expireDateChanged(e) {
      console.log("accountCertificationEdit() -> Expire Date Changed", e);
      this.data.activeDate = Moment(e).tz(this.$root.TZ).subtract(this.data.term, 'months').format("YYYY-MM-DD");
    },
    async removeHistoryItem(item) {
      if(!await this.$root.confirmYesNo("Are you sure you want to delete this history item?", "warning")) {
        return;
      }
      this.data.history.splice(item.index, 1);
      this.dataChanged();
    },
    async save() {
      if(!this.data.term) {
        this.data.term = Math.abs(Moment(this.data.expireDate).tz(this.$root.TZ).diff(Moment(this.data.activeDate).tz(this.$root.TZ), "months"));
      }
      await AccountService.updateCertification(this.data);
      this.$emit("save", this.data, this.isDataChanged);
    },
    // async cancel() {
    //   if( this.isDataChanged ) {
    //     if( ! await this.$root.confirmYesNo("You have pending changes, are you sure you want to cancel?") ) {
    //       return;
    //     }
    //   }
    //   this.isDataChanged = false;
    // },
    // async certificationSearch(searchString) {
    //   try {
    //     this.isResult          = false;
    //     let response           = await CertificationsService.certificationsList(searchString);      // todo: update this to restrict to connectied account classes
    //     this.certificationList = response.data;
    //     this.isResult          = true;
    //     return this.certificationList;
    //   } catch(error) {
    //     console.log(error.message);
    //   }
    //   return null;
    // },
    async selectCertificate(item) {

      if(!item || !item._id) {
        console.log("assignCertificate", item);
        await this.$root.alert("You must select an active certificate");
        return;
      }

      try {

        let response       = await CertificationsService.getCertification(item._id);
        console.log("certification information", response.data );

        this.assignCertificateInformation(response.data);

      } catch(error) {
        await this.$root.alert(error.message);
      }

    },
    async assignCertificateInformation(cert) {

      if(!cert || !cert._id) {
        console.log("assignCertificateInformation - Not a valid certificate", cert);
        return;
      }

      let found = this.certifications.find((c) => { return c.certificationNumber === cert.certificationNumber && c.active === true });

      if( found ) {
        await this.$root.alert(`This certificate is already assigned`);
        return;
      }

      this.data.certificationNumber = cert.certificationNumber;
      this.data.certificationTitle  = cert.title;
      this.data.certificationType   = cert.certificationType
      this.data.section             = cert.section;
      this.data.category            = cert.category;
      this.data.classification      = cert.classification;
      this.data.term                = cert.coverage.certificationTerm;

      // if(this.newCert.activeDate) {
      //   this.setExpireDate(this.newCert);
      // }

    },
    async renew() {
      if(!await this.$root.confirmYesNo("Renew this certification?<br>This creates a new record with the new dates.")) {
        return;
      }

      try {
        let cert = {};
        let mCert;
        let term = 12;    // we will override below.

        if(this.data.certificationNumber) {
          mCert = await this.getMasterCertification(this.data.certificationNumber);

          if(!mCert) {
            await this.$root.alert("Oops:<br>Master Certification not found<br>Check the configuration.", "error");
            return
          }

          term = mCert.coverage.certificationTerm
        } else {
          term = Math.abs(Moment(this.data.expireDate).tz(this.$root.TZ).diff(Moment(this.data.activeDate).tz(this.$root.TZ), "months"));
        }

        cert = Object.assign(cert, this.data);

        cert.activeDate = Moment(cert.expireDate).tz(this.$root.TZ).format("YYYY-MM-DDT00:00:00.000Z").toString();
        cert.expireDate = Moment(cert.activeDate).tz(this.$root.TZ).add(term, "months").format("YYYY-MM-DDT00:00:00.000Z").toString();

        if(mCert) {
          cert.certificationTitle = mCert.title;
          cert.certificationType  = mCert.certificationType;
          cert.category           = mCert.category;
          cert.section            = mCert.section;
          cert.classification     = mCert.classification;
          cert.term               = term;
          cert.active             = true;
          cert.vendorName         = mCert.vendorName;
          cert.vendorNumber       = mCert.vendorNumber;
          cert.ce                 = mCert.ce;
          cert.points             = mCert.points;

          if(mCert.options.hasScore) {
            cert.score = "";
          }
        }

        cert.lastUpdateBy = this.$root.user.name;
        // cert.notes = `${Moment().format("ddd Do MMM-YY hh:mm a")}\nCertification assigned from Master Certification: ${mCert.title} by ${this.$root.user.alias}\n\n` + (cert.notes || "");

        let renewId = iLib.make16DigitId();

        cert.history = [ {
          action    : "renew",
          actionDate: new Date(),
          title     : `Renewed [${cert.certificationTitle}] for ${term} months; Id: ${renewId}`,
          points    : (mCert) ? mCert.points : null,
          ce        : (mCert) ? mCert.ce : null,
          manager   : this.$root.user.alias
        } ]

        this.data.history.unshift(cert.history[ 0 ]);      // tag the old one.

        let result = await AccountService.addCertification(cert.accountNumber, cert.badgeNumber, cert);

        this.data.renewal = result.data._id;
        await this.save();

        delete this.$root.certificationsMasterList;

        await this.$root.alert("Renewal Created Successfully", "success");
        this.done();

      } catch(error) {
        await this.$root.alert("Oops:<br>" + error.message, "error");
      }

    },
    async certificationSearch(s) {
      let response = await CertificationsService.certificationsList(s);
      return response.data;
    },
    async assignCertificationToAccount(e) {
      let mCert = await this.getMasterCertification(e._id);

      // todo: See if this cert is already in our list if we have an account record...

      if(mCert.certificationNumber.length === 0) {
        return await this.$root.alert("Oops:<br>This certification has an invalid ID.<br>Please edit this record and try again.", "error");
      }

      if(mCert.certificationNumber === this.data.certificationNumber) {
        if(!await this.$root.confirmYesNo("This appears to be the same record. Do you wish to reapply this entry?", "warning")) {
          return;
        }
      } else {
        if(this.certifications) {
          if(this.certifications.find((c) => { return c.certificationNumber === e.certificationNumber && c.active === true })) {
            // console.log("have", this.certifications.map((c) => { return {n: c.certificationNumber, t: c.certificationTitle}}));
            // console.log("trying", mCert)
            // this.data.certificationNumber = "";
            // this.data.certificationTitle  = "";
            await this.$root.alert(`Oops:<br>Already Has<br>${mCert.title}`, "warning");
            return;
          }
        }
      }

      // console.log("accountCertEdit, certifications", this.certifications);

      let cert = this.data;
      if(mCert) {
        cert.certificationTitle  = mCert.title;
        cert.certificationNumber = mCert.certificationNumber;
        cert.certificationType   = mCert.certificationType;
        cert.category            = mCert.category;
        cert.section             = mCert.section;
        cert.classification      = mCert.classification;
        cert.active              = true;
        cert.vendorName          = mCert.vendorName;
        cert.vendorNumber        = mCert.vendorNumber;
        cert.term                = mCert.coverage.certificationTerm;

        if(mCert.options.hasScore) {
          cert.score = "";
        }
        cert.lastUpdateBy = this.$root.user.name;
        // cert.notes = `${Moment().format("ddd Do MMM-YY hh:mm a")}\nCertification assigned from Master Certification: ${mCert.title} by ${this.$root.user.alias}\n\n` + (cert.notes || "");

        if(this.account) {
          cert.ownerId    = this.account._id;
          cert.ownerTitle = this.account.accountTitle;
        }

        let history = {
          action    : "assign",
          actionDate: new Date(),
          title     : "Assigned: " + mCert.title,
          points    : mCert.points,
          manager   : this.$root.user.alias
        }

        cert.history.unshift(history);

        delete this.$root.certificationsMasterList;     // todo: generate an event to have the parent do this op
        this.dataChanged();
      }

    },
    dataChanged() {
      this.isDataChanged = true;
    },
    async getAccountCertification(id) {
      let response = await AccountService.getCertification(id);
      this.data    = response.data;
      if(this.data.certificationNumber) {
        this.isReadOnly = true;
      }
    },
    async getMasterCertification(id) {
      let response = await CertificationsService.getCertification(id);
      return response.data;
    },
    // getAccountsFromCertifications() {
    //   this.accounts = _.uniqBy( this.certifications, "accountNumber" ).map((a) => {return { accountNumber: a.accountNumber, name: a.ownerTitle }});
    // },
    // async getMasterCertificationList() {
    //   let response = await QueryService.getMasterCertificationList();
    //   this.certifications = response.data;
    //   this.getAccountsFromCertifications();
    //   console.log( "certifications" , this.certifications );

    // }

  }
}
</script>
<style lang="scss" scoped>

.green-background {
  background: forestgreen !important;
}

.orange-background {
  background: orange !important;
}

.log-entry {
}

.log-entry .action-row {
  border-top: 2px solid #999;
  // border-bottom: 1px solid #ddd;
}

.log-entry .action-row:hover {
  background-color: #ddd;
}

.log-entry .action-name {
  font-weight: bold;
  font-size: .8em;
}

.log-entry .action-date {
  // margin-left:1em;
  font-size: .8em;
  font-weight: 100;

}

.log-entry .manager-name {
  margin-left: 1em;
  font-size: .8em;
  font-weight: 100;
}

.log-entry .title {
  //  font-weight:700;
}

// .log-entry .action-date,
// .log-entry .manager-name,
// .log-entry .title {
//   margin-left: 16px;
//   font-style: italic;
//   color: #777;
// }

</style>
