<template>
  <div class="content">
    <div class="container-fluid">


      <template v-if="certification ">
        <account-certification-edit
          :certification="certification"
          :account="account"
          :certifications="certifications"
          @done="certification=null"
          @save="dataChanged();certification=null;getCertifications()"
        >
        </account-certification-edit>
      </template>

      <div class="row" v-if="ticketId">
        <ticket-edit
          :propTicketId = "ticketId"
          @done         = "ticketId = null"
          @saved        = "updateTicketList($event)"
        ></ticket-edit>
      </div>

      <b-modal ref="transaction-receipt" title="Receipt" size="lg" style="padding:0 1em 0 1em;" scrolable ok-only @click="$refs['transaction-receipt'].hide();transaction=null;" >
        <div v-if="transaction" class="receipt-format1">
          <div v-for="(r,rindex) in transaction.receipt" :key="rindex">{{r}}</div>
        </div>
      </b-modal>

      <b-modal ref="order-receipt" size="md" style="padding:0 1em 0 1em;margin:0;" scrolable ok-only @click="$refs['order-receipt'].hide();order=null;" >
          <template v-if="order">
            <h3 v-if="order">Order For: {{order.alias}} <span style="font-size:.8em;font-weight:100;">({{order.badgeNumber}})</span></h3>
            <div style="text-align:right;">
              <div>Order Date: <span style="font-weight:800;">{{moment(order.orderDate).format("LLLL")}}</span></div>
              <div>Placed: <span style="font-weight:800;">{{moment(order.createdAt).format("LLLL")}}</span></div>
            </div>
            <order-receipt-card
                  color="#0078D4"
                  :orderDate="order.orderDate"
                  :order="order"
                  title="Receipt"
                  orderLabel=""
                  :disabled="true"
                  @cancel="order=null"
                >
            </order-receipt-card>
          </template>
      </b-modal>

      <div class="row" v-show="!ticketId && !certification">
        <div  class="mt-1 mb-5" :class="(accountTab===0) ? 'col-md-9' : 'col-12'">
          <div v-if="isDataChanged" class="">
            <b-button class="btn btn-success btn-fill btn-sm mr-2" :disabled=saveDisabled @click="saveDone()">
              Update Account
            </b-button>
            <b-button class="btn btn-warning btn-fill btn-sm mr-2" @click="done()">
              Cancel
            </b-button>
          </div>
          <div v-if="!isDataChanged" class="">
            <b-button class="btn btn-success btn-fill btn-sm mr-2" @click="done()">
              Done
            </b-button>
          </div>
          <h4 slot="header">Account:<span style="font-weight:800;"> {{account.accountTitle || primaryBadgeName( account ) }}</span>
            <span style="margin-left:.5em;font-size:.7em;font-weight:100;">({{account.accountNumber}})</span>
          </h4>
          <div class="tab-hover">
            <hr>
            <b-tabs style="min-height:700px;" v-if="account.accountNumber" v-model="accountTab" active-nav-item-class="selected-tab">

              <!-----------[ General Stuff ]------------------- -->

              <b-tab title="General" active>

                <div style="margin:1em 2em 0 2em;" :class="(account.active) ? (account.isOnHold) ? 'account-on-hold' : '' : 'account-disabled'" @input="dataChanged()">
                  <b-form-group horizontal breakpoint="lg" label="Account Information" label-size="lg" label-class="font-weight-bold pt-0" @input="dataChanged()">
                    <sf-input v-model="account.accountNumber" :labelCols="2" type="text" cstyle="width:25em;" required label="Account Number" placeholder="Account Number" readonly />

                    <template v-if="!hasFeature('badges') && account.badge && account.badge.length>0">
                      <sf-input v-model="account.accountTitle" :labelCols="2" type="text" required cstyle="width:25em;"  label="Account Title" placeholder="(First) & Last Name" @change="account.badge[0].alias = account.accountTitle"/>
                      <sf-input v-model="account.badge[0].lastName" :labelCols="2" cstyle="width:25em;" type="text" label="Last Name" placeholder="Last Name" />
                      <sf-input v-model="account.badge[0].firstName" :labelCols="2" cstyle="width:25em;" type="text" label="First Name" placeholder="First Name" />
                      <sf-input v-model="account.badge[0].phone1" :labelCols="2" cstyle="width:25em;" type="email" label="Phone" placeholder="(xxx) xxx-xxxx" />
                      <sf-input v-model="account.badge[0].notificationEmail" :labelCols="2" cstyle="width:25em;" type="email" label="Email" placeholder="Notification Email(abc@cbs.com)" />
                      <sf-input v-model="account.supervisor" :labelCols="2" cstyle="width:25em;" type="text" label="Supervisor" placeholder="Supervisor" />
                      <sf-input v-model="account.jobCode" :labelCols="2" cstyle="width:25em;" type="text" label="Job Code" placeholder="Job Code" />
                    </template>
                    <template v-else>
                      <sf-input v-model="account.accountTitle" :labelCols="2" type="text" required cstyle="width:25em;"  label="Account Title" placeholder="(First) & Last Name" />
                      <sf-input v-model="account.glAccount" :labelCols="2"  type="text" cstyle="width:15em;" label="GL Account" />
                      <sf-input v-model="account.arType" :labelCols="2" type="text" cstyle="width:15em;" label="AR Type" />
                    </template>
                  </b-form-group>
                </div>

                <div v-if="hasFeature('account-address')" style="margin:1em 2em 0 2em;">
                  <b-form-group horizontal breakpoint="lg" label="Address" label-size="lg" label-class="font-weight-bold pt-0" @input="dataChanged()">
                    <sf-input v-model="account.address.address1" :labelCols="2" type="text" cstyle="width:25em;" label="Address" />
                    <sf-input v-model="account.address.address2" :labelCols="2" type="text" cstyle="width:25em;" label="" />
                    <sf-input v-model="account.address.city" :labelCols="2" type="text" cstyle="width:15em;"  label="City" />
                    <sf-input v-model="account.address.state" :labelCols="2" type="text" cstyle="width:5em;" label="State" />
                    <sf-input v-model="account.address.zip" :labelCols="2" type="text" cstyle="width:10em;" label="Zip" />
                  </b-form-group>
                </div>

                <div v-if="hasFeature( 'notes')" style="margin:1em 2em 0 2em">

                  <b-form horizontal breakpoint="lg" label="Notes" label-size="lg" label-class="font-weight-bold pt-0">
                    <!-- <sf-input type="textarea" :rows="10" label="Notes" cstyle="max-width:40em;" v-model="account.description"/> -->
                    <!-- <vue-editor style="min-height:450px;" v-model="account.description" @selection-change="dataChanged()" /> -->
                    <wysiwyg style="min-height:450px;" v-model="account.description" @selection-change="dataChanged()" @change="dataChanged()" />
                  </b-form>
                </div>

              </b-tab>

              <!-------------[ Badges ]------------------------- -->

              <b-tab v-if="hasFeature('badges')" :title="accountBadgeTitle() + 's'" >
                <div style="overflow-x:auto;margin:1em 1em 0 1em;">
                <span v-if="$root.isAdmin()" class="float-right mt-1 mr-1">
                  <br>
                  <i class="fa fa-plus-square show-hand" style="font-size:1.5em;" @click="showAddBadgeScreen" v-b-popover.hover.left="'Add ' + accountBadgeTitle()"></i>
                </span>

                  <b-tabs card vertical small pills lazy active-nav-item-class="selected-tab-left" active-tab-class="" content-class="mt-0" >

                    <b-tab v-for="(b,index) in account.badge"  :key="index" style="height:100%;min-height:640px;border-left:4px solid gray;">
                      <template v-slot:title>
                        <div style="width:12em;max-width:12em;display:flex;text-align:right;">
                          <b-avatar :src="getImage(b)" :class="(!b.active) ? 'badge-disabled' : (b.status.isOnHold) ? 'badge-on-hold' : 'badge-active'"></b-avatar>
                          <div style="padding:.5em 0 0 .25em;margin-right:0;font-size:1.25em;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;">{{ b.alias || 'none' }}</div>
                        </div>
                      </template>

                      <b-tabs  class="ml-0 mr-0" card  v-model="badgeTab" active-nav-item-class="selected-tab">
                        <b-tab class="ml-0" title="General">
                          <b-row>
                            <b-col class="col col-md-12 col-lg-3">
                              <div v-if="b.pictureUrl">
                                <img width=192 style="margin-bottom:1em;border-radius:1em;" :src="b.pictureUrl">
                              </div>

                              <h4 style="margin-top:0;">{{accountBadgeTitle()}} Status</h4>

                              <div>
                                <b-form-checkbox type="checkbox" v-model="b.active" @change="dataChanged()">This {{accountBadgeTitle()}} Active</b-form-checkbox>
                              </div>
                              <div v-if="account.accountType==='student'">
                                <b-form-checkbox type="checkbox" v-model="b.status.isParent" @change="dataChanged()">This {{accountBadgeTitle()}} Is a Parent</b-form-checkbox>
                              </div>

                              <div>
                                <b-form-checkbox type="checkbox" v-model="b.status.isVIP" @change="dataChanged()">VIP</b-form-checkbox>
                              </div>
                              <div>
                                <b-form-checkbox type="checkbox" v-model="b.status.isOnHold" @change="dataChanged()">On Hold</b-form-checkbox>
                              </div>
                              <div>
                                <b-form-checkbox type="checkbox" v-model="b.status.isMinor" @change="dataChanged()">Minor</b-form-checkbox>
                              </div>
                              <div>
                                <b-form-checkbox type="checkbox" v-model="b.status.isOffSite" @change="dataChanged()">Off Site</b-form-checkbox>
                              </div>
                              <!-- <div>
                                <b-form-checkbox type="checkbox" v-model="b.status.isShowInDirectory" @change="dataChanged()">In Directory</b-form-checkbox>
                              </div>
                              <div>
                                <b-form-checkbox type="checkbox" v-model="b.status.isAllowSocial" @change="dataChanged()">Social Features</b-form-checkbox>
                              </div> -->

                              <!--          dob           : Date,-->
                              <!--          anniversary   : Date,-->
                              <!--          retirementDate: Date,-->
                              <!--          joinDate      : { type: Date, default: Date.now },-->
                              <!--          leaveDate     : Date,-->
                              <!--          admitDate     : Date,-->
                              <!--          dischargeDate : Date,-->
                              <!--          moveInDate    : Date,-->
                              <!--          moveOutDate   : Date,-->


                            </b-col>
                            <b-col class="col col-md-12 col-lg-9" >

                              <div v-if="$root.isAdmin()" style="float:right;width:100px;font-size:1.5em;">
                                <i class="fa fa-pencil-square-o show-hand" style="margin-right:1em;" @click="canChangeBadge=!canChangeBadge" v-b-popover.hover.top="`Edit ${accountBadgeTitle()} Number`"></i>
                                <i class="fa fa-trash show-hand" style="" @click="removeBadgeFromAccount(b.badgeNumber)" v-b-popover.hover.top="`Remove This ${accountBadgeTitle()}`"></i>
                              </div>

                              <b-form @input="dataChanged()">
                                <sf-input :labelCols="3" v-model="b.badgeNumber"  cstyle="width:15em;" type="text" :label="accountBadgeTitle() + ' Number'" :readonly="!canChangeBadge" />
                                <sf-input v-if="b.account || canChangeBadge" :labelCols="3" v-model="b.account"  cstyle="width:15em;" type="text" label="Account" :readonly="!canChangeBadge" />
                                <sf-input :labelCols="3" v-if="hasFeature('beds')" id="bedNumber" label="Bed" cstyle="width:5em;" type="text" v-model="b.room.bed" placeholder="Bed"/>
                                <sf-input :labelCols="3" v-model="b.alias" cstyle="width:25em;" type="text" label="Alias" placeholder="Alias" />
                                <sf-input :labelCols="3" v-model="b.lastName" cstyle="width:25em;" type="text" label="Last Name" placeholder="Last Name" />
                                <sf-input :labelCols="3" v-model="b.firstName" cstyle="width:25em;" type="text" label="First Name" placeholder="First Name" />
                                <sf-input :labelCols="3" v-model="b.transactionLimit" cstyle="width:10em;" type="currency" label="Trans Limit" />
                              </b-form>

                              <b-form @input="dataChanged()">
                                <sf-input :labelCols="3" v-model="b.email" type="email" label="Primary Email Address" placeholder="Email address (abc@cbs.com)" />
                                <sf-input :labelCols="3" v-model="b.notificationEmail" type="email" label="Notification Email Override" placeholder="Notification Email(abc@cbs.com)" helpText="When defined, notifictions are sent here instead of the default email address. Leave blank to use primary email address."/>
                                <sf-input :labelCols="3" v-model="b.phone1" type="text" label="Phone #" placeholder="Phone # (123-456-7890)" />
                                <sf-input :labelCols="3" v-model="b.phone2" type="text" label="Alt Contact #" placeholder="Phone # (123-456-7890)" />
                                <sf-input :labelCols="3" v-model="b.sms" type="text" label="Cell #" placeholder="Cell # (123-456-7890)" />
                              </b-form>
                            </b-col>
                          </b-row>
                        </b-tab>

                        <b-tab v-if="hasFeature('dates')" title="Dates">
                          <b-form style="margin-top:2em;"  @change="dataChanged()">
<!--                            <sf-input :labelCols="3" v-model="b.dates.joinDate" label="Join" type="date" cstyle="width:12em;"/>-->
<!--                            <sf-input :labelCols="3" v-model="b.dates.leaveDate" label="Leave" type="date" cstyle="width:12em;"/>-->
<!--                            <br>-->
                            <sf-input :labelCols="3" v-model="b.dates.dob" label="DOB" type="date" cstyle="width:12em;"/>
                            <sf-input :labelCols="3" v-model="b.dates.anniversary" label="Anniversary" type="date" cstyle="width:12em;"/>
                            <sf-input :labelCols="3" v-model="b.dates.retirementDate" label="Retirement" type="date" cstyle="width:12em;"/>
                            <br>
<!--                            <sf-input :labelCols="3" v-model="b.dates.admitDate" label="Admit" type="date" cstyle="width:12em;"/>-->
<!--                            <sf-input :labelCols="3" v-model="b.dates.dischargeDate" label="Discharge" type="date" cstyle="width:12em;"/>-->
                            <sf-input :labelCols="3" v-model="b.dates.moveInDate" label="Move In" type="date" cstyle="width:12em;"/>
                            <sf-input :labelCols="3" v-model="b.dates.moveOutDate" label="Move Out" type="date" cstyle="width:12em;"/>
                          </b-form>
                        </b-tab>

                        <b-tab title="Notes">
                          <b-form @input="dataChanged()">
                            <sf-input v-model="b.notes" :rows="10" :labelCols="0" type="textarea" label="Notes" placeholder="Notes" />
                          </b-form>
                        </b-tab>

                        <b-tab v-if="hasFeature('pendants')" title="Pendant">
                          <div style="margin-top:2em;">
                            <b-form v-if="b.pendant.pendantId!=''" @input="dataChanged()">
                              <sf-input v-model="b.pendant.title" type="text" cstyle="width:25em;" label="Title"/>
                              <sf-input v-model="b.pendant.make" type="text" cstyle="width:15em;" label="Make" />
                              <sf-input v-model="b.pendant.modelNumber" type="text" cstyle="width:15em;" label="Model" />
                              <sf-input v-model="b.pendant.pendantId" type="text" cstyle="width:15em;" label="Pendant Id" />
                              <sf-input v-model="b.pendant.serialNumber" type="text" cstyle="width:15em;" label="Serial Number" />
                            </b-form>
                            <div v-if="!b.pendant.pendantId!=''">
                              <b-button class="btn btn-sm btn-primary" @click="b.pendant.pendantId='new'">Add Pendant</b-button>
                            </div>
                          </div>
                        </b-tab>

                        <b-tab title="Picture">
                          <div style="margin-top:2em;">
                            <div v-if="b.pictureUrl">
                              <img height=256 class="shadow" style="align:center;margin-bottom:1em;border-radius:1em;" :src="b.pictureUrl">
                            </div>
                            <b-form  @input="dataChanged()">
                              <sf-input v-model="b.pictureUrl" :labelCols="2" cstyle="width:20em;" type="url" label="Picture URL" placeholder="URL Link (https://your link)" />
                            </b-form>
                            <b-button class="btn btn-sm btn-warning btn-fill" @click="uploadBadgePicture">Upload Picture</b-button>
                          </div>
                        </b-tab>

                        <b-tab v-if="hasFeature('social')" title="Social" >
                          <div style="margin-top:2em;">
                            <b-row>
                              <b-col class="col col-6">

                                <h4 style="margin:0;padding:0;">{{accountBadgeTitle()}} Social Options</h4>
                                <p style="margin:0 0 2em 0;padding:0;font-size:.8em;color:gray;">These options can also be set by the user in iCare Connect or Teams</p>
                                <b-form-group>

<!--                                  isShowInDirectory: { type: Boolean, default: true },-->
<!--                                  isAllowSocial    : { type: Boolean, default: true },-->
<!--                                  isPublishCell    : { type: Boolean, default: true },-->
<!--                                  isPublishEmail   : { type: Boolean, default: true },-->
<!--                                  isPublishRoom    : { type: Boolean, default: false },-->
<!--                                  isPublishDOB     : { type: Boolean, default: true },-->
<!--                                  isPublishAddress : { type: Boolean, default: true },-->
<!--                                  isPublishHobbies : { type: Boolean, default: true },-->

                                  <b-form-checkbox type="checkbox" v-model="b.status.isShowInDirectory" @change="dataChanged()">Publish in Directory</b-form-checkbox>
                                  <b-form-checkbox type="checkbox" v-model="b.status.isPublishDOB" @change="dataChanged()">Publish DOB</b-form-checkbox>
                                  <b-form-checkbox type="checkbox" v-model="b.status.isPublishCell" @change="dataChanged()">Publish Cell</b-form-checkbox>
                                  <b-form-checkbox type="checkbox" v-model="b.status.isPublishEmail" @change="dataChanged()">Publish Email</b-form-checkbox>
                                  <b-form-checkbox type="checkbox" v-model="b.status.isPublishAddress" @change="dataChanged()">Publish Address</b-form-checkbox>
                                  <b-form-checkbox type="checkbox" v-model="b.status.isPublishRoom" @change="dataChanged()">Publish Room</b-form-checkbox>
                                  <b-form-checkbox type="checkbox" v-model="b.status.isPublishHobbies" @change="dataChanged()">Publish Hobbies</b-form-checkbox>

                                  <b-form-checkbox type="checkbox" v-model="b.status.isAllowSocial" @change="dataChanged()">Enable Social Features</b-form-checkbox>
                                </b-form-group>

                              </b-col>
                            </b-row>
                          </div>
                        </b-tab>

<!--                        <b-tab v-if="'resident,employee,faculty'.indexOf(account.accountType) >= 0" title="Access Control">-->
<!--                          <div style="margin-top:1em;height:618px;">-->
<!--                            <h4>Access Control Configuration</h4>-->
<!--                          </div>-->
<!--                        </b-tab>-->

                        <b-tab v-if="hasFeature('students') && b.student" title="Student Information">
                          <div style="margin-top:2em;">
                            <b-form @input="dataChanged()">
                              <sf-input type="text" readonly v-model="b.student.studentId" label="Student Id"></sf-input>
                              <sf-input type="text" v-model="b.student.homeRoom" label="Homeroom"></sf-input>
                              <sf-input type="text" v-model="b.student.teacher" label="Teacher"></sf-input>
                              <sf-input type="text" cstyle="width:5em;" v-model="b.student.grade" label="Grade"></sf-input>
                              <sf-input type="number" cstyle="width:5em;" v-model="b.student.priceLevel" label="Price Level"></sf-input>
                            </b-form>
                          </div>
                        </b-tab>
                      </b-tabs>
                    </b-tab>
                  </b-tabs>
                </div>
              </b-tab>


              <!-------------[ Limits ]------------------------ -->

              <b-tab v-if="hasFeature('totals')" title="Totals & Limits" @click="getAccountBalances(account.accountNumber)">
                <div style="margin:1em 2em 0 2em;overflow:auto;">
                  <i v-if="hasOption('add-total')" class="fa fa-plus-square show-hand float-right" @click="showAddTotalsToBadgeScreen" v-b-popover.hover.top="'Add Total'"></i>
                  <div v-if="account.limits"  style="max-width:30rem;margin-left:8em;">
                    <h5>Account-Level Limits (by This Account)</h5>
                    <b-form  @input="dataChanged()">
                      <table class="table" style="line-height:8px;" >
                        <col width="30%">
                        <col width="35%">
                        <col width="35%">
                        <thead class="table-dark">
                        <tr>
                          <th></th>
                          <th>Qty</th>
                          <th>$ Total</th>
                        </tr>
                        </thead>
                        <tr>
                          <td>Daily Limit</td>
                          <td>
                            <sf-input  type="number" :labelCols=0 cstyle="width:6em;" inputClass="mt-0 mb-0" v-model="account.limits.dailyQty"></sf-input>
                          </td>
                          <td>
                            <sf-input  type="currency" :labelCols=0 cstyle="width:8em;"  inputClass="mt-0 mb-0" v-model="account.limits.daily"></sf-input>
                          </td>
                        </tr>
                        <tr>
                          <td>Overall Limit</td>
                          <td>
                            <sf-input  type="number" :labelCols=0 cstyle="width:6em;" inputClass="mt-0 mb-0" v-model="account.limits.totalQty"></sf-input>
                          </td>
                          <td>
                            <sf-input  type="currency" :labelCols=0 cstyle="width:8em;" inputClass="mt-0 mb-0" v-model="account.limits.total"></sf-input>
                          </td>
                        </tr>
                      </table>
                    </b-form>
                  </div>
                  <hr />
                  <p style="padding-left:10em;padding-right:10em;font-size:.8em;">The Global Limits are set at the system level in Setup.  Remember, the lowest limit is what is enforced, regardless if set on this local account or via the global total limits - the LOWEST limit is enforced.</p>
                  <hr />
                  <div class="" style="max-width:30rem;margin-left:8em;overflow-x:auto;">
                    <h5 class="card-title mb-0">Global Totals Available For This Account</h5>
                    <p style="font-size:.8em;">Global Limits are set in the Admin Config</p>
                    <div class="card-text">
                      <table class="table table-sm"  >
                        <thead class="table-dark">
                        <tr>
                          <th>Action</th>
                          <th>Id</th>
                          <th>Description</th>
                          <th>Total Type</th>
                          <th style="text-align:right;">Balance</th>
                        </tr>
                        </thead>
                        <tr  v-for="(t,index) in _.sortBy(account.totalClass , 'totalClassId')" :key="index" >
                          <td><i class="fa fa-trash-o show-hand float-right" @click="deleteTotalClassFromBadge(t.totalClassId)" v-b-popover.hover.top="'Delete Total'"></i></td>
                          <td>{{t.totalClassId}}</td>
                          <td>{{t.title}}</td>
                          <td style="font-size:.8em;" v-b-popover.hover.html.top="showMenu(t)">({{t.totalType}})</td>
                          <td style="text-align:right;padding-right:1.5em;">{{currency(accountBalance(t))}}</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </b-tab>

              <!--              &lt;!&ndash;-&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;[ Totals ]&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45; &ndash;&gt;-->

              <!--              <b-tab title="TOTALS">-->
              <!--                <br><i class="fa fa-plus-square show-hand float-right" @click="showAddTotalsToBadgeScreen" v-b-popover.hover.top="'Add Total'"></i>-->

              <!--                <b-card v-for="(t,index) in account.totalClass" :key="index" :title="t.title" style="max-width: 40rem;" class="mb-2">-->
              <!--                  <i class="fa fa-trash-o show-hand float-right" @click="deleteTotalClassFromBadge(t.totalClassId)" v-b-popover.hover.top="'Delete Total'"></i>-->
              <!--                  <div class="card-category">{{t.totalClassId}} :: {{t.totalType}}</div>-->
              <!--                </b-card>-->
              <!--                <br>-->
              <!--              </b-tab>-->

              <!-----------[ Room Configuration ]-------------------------- -->

              <b-tab v-if="hasFeature('room')"  title="Room">
                <div style="margin:1em 2em 0 2em;">
                  <b-form horizontal breakpoint="lg" label="Room Information" label-size="lg" label-class="font-weight-bold pt-0" @input="dataChanged()">
                    <sf-input id="buildingNumber" label="Building Number" cstyle="width:15em;" type="text" v-model="account.buildingNumber" placeholder="Building Number"/>
                    <sf-input id="roomNumber" label="Room Number" cstyle="width:15em;" type="text" v-model="account.roomNumber" placeholder="Room Number"/>
                    <hr />
                    <div v-if="hasFeature('beds')">
                    <template v-for="(badge,bedIndex) in account.badge">
                      <sf-input id="bedNumber" :key="bedIndex" :label="badge.alias + ' Bed'" cstyle="width:5em;" type="text" v-model="badge.room.bed" placeholder="Bed"/>
                    </template>
                  </div>
                  </b-form>
                </div>
              </b-tab>

            <b-tab v-if="hasFeature('assets')" title="Assets"  @click="assetsFocus">
              <div  class="mt-2">
                <b-input type="search" style="width:225px;float:right;margin-right:2em;margin-bottom:1em;border:1px black solid; border-radius:1em;" v-model="assetFilter" placeholder="Assets Filter"></b-input>
                <b-table striped sm head-variant="dark" class="show-hand" :filter="assetFilter" :items="assetList" :fields="assetFields" @row-clicked="selectAsset($event)"></b-table>
              </div>
            </b-tab>


            <b-tab v-if="hasFeature('transaction-history')" title="Trans History"  @click="historyFocus">
              <div  class="mt-2" style="">

                  <simple-calendar
                    title="Transactions"
                    id="transactionCalendar"
                    :isCalendarMode="true"
                    :calendarDate="calendarDate"
                    :timeZone="$root.TZ"
                    :isShowTime="true"
                    :isShowValue="true"
                    :isShowItems="true"
                    :isShowDescription="true"
                    :isAllowList="true"
                    :events="transactionHistory"
                    @click="transactionsSelectDay"
                    @item="transactionLookup"
                    @gotoToday="transactionsGotoToday"
                    @nextMonth="transactionsNextMonth"
                    @prevMonth="transactionsPrevMonth"
                  />
                </div>

            </b-tab>

            <b-tab v-if="hasFeature('contacts')" title="Contacts"  @click="contactsFocus">
              <b-form-checkbox type="checkbox" style="margin:1em 0 0 1em;" v-model="isShowContactList" @change="toggleShowContactList()">Show as List</b-form-checkbox>
              <i class="fa fa-plus-square show-hand" style="position:relative;float:right;:5px;top:-25px;;font-size:1.5em;z-index:100;margin-right:1em;" v-b-popover.hover.top="'Add new contact'" @click="addContact"></i>

              <div v-if="isShowContactList">
                <div class="mt-3 ml-5">
                  <ul>
                    <li v-for="(contact,index) in account.contacts" :key="index" style="padding:.25em .5em .25em .5em;">
                      <!-- <div style="border-top:1px solid gray;font-size:.8em;min-width:100px;font-weight:100;text-transform:capitalize;">({{contact.contactType}})</div> -->
                      <span style="font-weight:600;" v-b-popover.hover.top="contact.contactType">{{contact.name}} ({{contact.alias}}) </span>
                      <div style="margin-left:2em;">
                        <div style="font-style:italic;font-size:.8em;">({{contact.contactType}})</div>
                        <div v-if="contact.phone">Phone: {{ contact.phone }}</div>
                        <a v-if="contact.email" :src="'mailto:'+contact.email">Email: {{contact.email}}</a>
                      </div>

                    </li>
                  </ul>
                </div>
              </div>
<!--              <div v-if="!isShowContactList" class="row mt-4" >-->
              <div v-if="!isShowContactList" class="mt-4" style="display:flex;flex-wrap: wrap;justify-content:flex-start">

<!--                <div style="width:375px;border:1px solid gray;border-radius:1em;margin:.5em;padding: 0 14px 0 0;" v-for="(contact,index) in account.contacts" :key="index">-->
<!--                  <contact-card :contact="contact" :index="index"  @remove="removeContact(index)" @change="dataChanged()" ></contact-card>-->
<!--                </div>-->

                <template  v-for="(contact,index) in contactList" >
                  <contact-card2
                    style="flex:0 0 auto;margin:.25em;"
                    :contact="contact"
                    :index="index"
                    :isSelected="selectedCard===index"
                    :key="'c2-'+index"
                    @left-select="( selectedCard === parseInt($event)) ? selectedCard = -1 : selectedCard=parseInt($event)"
                    @remove="removeContact(index)"
                    @change="contactsChanged()" />
<!--                  {{ contact }}-->
                </template>
              </div>
            </b-tab>

              <!-------------[ Parking Management ]------------------------ -->

              <b-tab v-if="hasFeature('parking')" title="Parking"  @focus.native="parkingActivate" @blur.native="parkingLostFocus">
                <div style="margin:1em 2em 0 2em;">
                  <b-form horizontal breakpoint="lg" label="Parking Details" label-size="lg" label-class="font-weight-bold pt-0" @input="dataChanged()">
                    <sf-input id="parkingLot" label="Lot/Location" type="text" v-model="account.parking.lot" placeholder="Lot or Building" />
                    <sf-input id="parkingSpaceNumber" label="Space Number" cstyle="width:15em;" type="text" v-model="account.parking.spaceNumber" placeholder="Space Number" />
                    <sf-input id="parkingPassNumber" label="Pass Number" cstyle="width:15em;" type="text" v-model="account.parking.passNumber" placeholder="Pass Number" />
                    <sf-input id="parkingNote" label="Notes" type="textarea" :rows=10 v-model="account.parking.note" placeholder="Notes" />
                  </b-form>
                </div>
              </b-tab>


              <!-------------[ Portal Details  ]------------------------ -->
              <b-tab v-if="hasFeature('portal') " title="Portal" @click="getPInfo(account.accountNumber)" >

                <portal-registration v-if="isAddRegistration"
                  :badges = "account.badge"
                  @add="addRegistration( $event )"
                  @cancel="isAddRegistration=false;"
                />

                <div v-if="!isAddRegistration" style="margin:1em 2em 0 2em;">

                  <div v-if="!account.remoteAccess || account.remoteAccess.length==0">
                    <h4><i class="show-hand fa fa-plus-square mr-2" v-b-popover.hover.top="'Add Registration'" @click="isAddRegistration=true;"></i>No Portal Registrations</h4>
                  </div>

                  <div v-if="pInfo && pInfo.private && pInfo.private.billing.ccInfo.isAuthorized===true && $root.isInRole('cc-manual') " style="float:right;padding-right:2em;" >
                    <b-button class="btn btn-sm btn-fill btn-success mr-2" @click="showLoadFundsScreen()">Load Funds FROM cc</b-button>
                    <b-button class="btn btn-sm btn-fill btn-warning " @click="showPostRefundScreen()">Post Refund To CC</b-button>
                  </div>

                  <div v-if="account.remoteAccess && account.remoteAccess.length>0">

                    <div style="position:relative;left:0;top:0;" >
                      <i class="show-hand fa fa-plus-square"  style="font-size:1.25em;" v-b-popover.hover.top="'Add Registration'" @click="isAddRegistration=true;"></i>
                    </div>

                    <div v-if="pInfo && pInfo.private && pInfo.private.billing.ccInfo.isAuthorized===false" style="float:right;background:orange;color:white;border-radius:1em;padding:.25em 1em .25em 1em;">
                      No CC Authorized
                    </div>

                    <b-tabs card vertical small pills lazy active-nav-item-class="selected-tab-left" style="margin-bottom:2em;" active-tab-class="" content-class="mt-0" >
                      <b-tab v-for="(ra,raIndex) in account.remoteAccess" :key="raIndex"  style="height:100%;border-left:4px solid gray;">
                        <template v-slot:title>
                          <div style="width:12em;max-width:12em;display:flex;text-align:right;">
                            <!-- <b-avatar :src="getImage(b)" :class="(!b.active) ? 'badge-disabled' : (b.status.isOnHold) ? 'badge-on-hold' : 'badge-active'"></b-avatar> -->
                            <div style="padding:0 0 0 .25em;font-size:1.1em;margin:0;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;">{{ ra.user.title || 'none' }}</div>
                          </div>
                        </template>
                        <b-row>

                          <b-col class="col-xs-12 col-sm-12 col-md-7">
                            <b-form breakpoint="lg" @input="pInfoChanged">
                              <sf-input type="text" v-model="ra.user.title" label="Login Alias" placeholder="Alias"></sf-input>
                              <span>
                                <i v-if="$root.isAdmin()" class="fa fa-key show-hand" style="color:olive;margin-top:10px;float:right;" v-b-popover.hover.top="'Change user password'" @click="portalId=ra._id;isShowChangePassword=true;"/>
                                <sf-input type="email" v-model="ra.login.email" label="Login ID" placeholder="Portal Email"></sf-input>
                              </span>
                              <sf-input type="text" :disabled='true' v-model="ra.user.badgeNumber" label="Badge" placeholder="Badge"></sf-input>
                              <sf-input type="number" disabled cstyle="width:10em;" v-model="ra.login.logonCount" label="Logon Count"></sf-input>
                            </b-form>
                          </b-col>

                          <b-col class="col-xs-12 col-sm-12 col-md-5">
                            <b-form breakpoint="lg" @input="pInfoChanged">
                              <b-form horizontal label-cols-lg="4"  breakpoint="lg" label-class="text-sm-right" label="" >
                                <b-form-checkbox type="checkbox" v-model="ra.active" @input="pInfoChanged">Active</b-form-checkbox>
                                <b-form-checkbox type="checkbox" v-model="ra.login.isVerified" @input="pInfoChanged">Account Verified</b-form-checkbox>
                                <b-form-checkbox type="checkbox" v-model="ra.login.status.isLockedOut" @input="pInfoChanged">Account Locked Out</b-form-checkbox>
                                <b-form-checkbox type="checkbox" v-model="ra.login.status.isNeedChangePassword" @input="pInfoChanged">Must Change Password</b-form-checkbox>
                                <div v-b-popover.hover.top="'Remove this registration'" @click="removeRegistration(raIndex)" class="show-hand" style="float:right;"><i class="fa fa-trash" ></i></div>
                              </b-form>
                              <div style="margin-top:1em;">
                                <h4 style="margin:0 0 .5em 0;padding:0;border-bottom:1px solid gray;">Integration Details</h4>
                                <div style="padding-left:2em;">
                                  <table>
                                    <tr><td style="text-align:right;padding-right:.5em;">Name:</td><td>{{ra.integrationTitle}}</td></tr>
                                    <tr><td style="text-align:right;padding-right:.5em;">Type:</td><td>{{ra.integrationType}}</td></tr>
                                    <tr><td style="text-align:right;padding-right:.5em;">Key:</td><td>{{ra.integrationKey}}</td></tr>
                                    <tr><td style="text-align:right;padding-right:.5em;">Created:</td><td>{{ moment(ra.created).format("LLL" ) }}</td></tr>
                                  </table>
                                </div>
                              </div>
                            </b-form>
                          </b-col>
                        </b-row>
                      </b-tab>
                    </b-tabs>
                  </div>
                  <b-row class="row col-md-12" v-if="!pInfo || (pInfo.private && pInfo.private.billing.ccInfo.isAuthorized!=false)">

                      <b-col v-if="pInfo " class="col-6">
                        <h4 style="margin:0;padding:0;text-align:center;border-bottom:1px solid gray;">Credit Card Info On File</h4>
                        <b-form breakpoint="lg" @input="pInfoChanged">
                        <b-form horizontal style="margin-top:2em;" label-cols-lg="4"  breakpoint="lg" label-class="text-sm-right" label="" >
                          <b-form-checkbox v-if="pInfo" disabled v-model="pInfo.private.billing.ccInfo.isAuthorized" @input="pInfoChanged">CC Authorized?</b-form-checkbox>
                          <b-form-checkbox v-if="pInfo"  v-model="pInfo.private.billing.options.isAutoReload" @input="pInfoChanged">CC Auto Reload?</b-form-checkbox>
                        </b-form>
                        <sf-input type="text" readonly v-model="account.portal.endOfYear.remainingBalanceAction" cstyle="width:10em;" label="EOY Request" />
                        <sf-input type="currency" v-model="pInfo.private.billing.autoReloadTotal" cstyle="width:10em;" label="Auto Reload $" @input="pInfoChanged" />
                        <sf-input type="text" disabled v-model="pInfo.private.billing.title" label="Billing Info" />

                        <!-- <sf-input type="text" disabled v-model="pInfo.private.billing.ccInfo.isAuthorized" label="Authorized?" /> -->

                        <sf-input type="text" disabled v-model="pInfo.private.billing.ccInfo.ccNumber" label="CC" />
                        <sf-input type="text" disabled v-model="pInfo.private.billing.ccInfo.ccType" label="CC Type" />
                        <sf-input type="text" disabled v-model="pInfo.private.billing.ccInfo.ccExpDate" label="Exp Date" />

                        <!--                        <div>-->
                        <!--                        <b-form-checkbox  inline disabled v-model="pInfo.private.billing.ccInfo.isAuthorized" >CC Authorized?</b-form-checkbox>-->
                        <!--                        </div>-->
                        <!--                        <sf-input type="checkbox" style="" disabled v-model="pInfo.private.billing.ccInfo.isAuthorized" >CC Authorized?</sf-input>-->
                        <sf-input type="date" disabled cstyle="width:11em;" v-model="pInfo.private.billing.ccInfo.dateAdded" label="Date Added" />

                        <sf-input type="text" disabled v-model="pInfo.private.billing.ccInfo.firstName" label="First Name" />
                        <sf-input type="text" disabled v-model="pInfo.private.billing.ccInfo.lastName" label="Last Name" />
                        <sf-input type="text" v-model="pInfo.private.billing.reference" label="Reference" />
                        <sf-input type="text" v-model="pInfo.private.billing.ccInfo.ownerEmail" label="Notification Email" @input="pInfoChanged" />
                        <sf-input type="text" v-model="pInfo.private.billing.ccInfo.phone" label="Phone" @input="pInfoChanged"/>
                        <div v-if="pInfo && pInfo.private && pInfo.private.authorizations" >
                          <sf-input type="text" disabled v-model="pInfo.private.authorizations.cardAuthorization.i4go_response" label="Last Auth" />
                        </div>
                        </b-form>
                      </b-col>
                    </b-row>
                  <div v-if="account.remoteAccess && account.remoteAccess.length>0" style="margin: 2em 0 4em 2em;height:400px;overflow-x:hidden;overflow-y:auto;line-height:3px;font-size:12px;">
                    <b-table striped hover sticky-header head-variant="dark"  stacked="md" :items="_.orderBy(account.remoteAccessLog,['when'],'desc')" :fields="accessLogFields">
                      <template v-slot:cell(when)="data">
                        {{moment(data.item.when).fromNow()}}, {{moment(data.item.when).format( "LLLL")}}
                      </template>
                    </b-table>
                  </div>
                </div>
              </b-tab>

              <!------------------------------------------------------------------------------------------------[ Accounting ]----->

              <b-tab v-if="hasFeature('accounting')" title="Accounting">
                <div style="margin:1em 2em 0 2em;">
                  <b-form style="" @input="dataChanged()">
                    <h4>Account Details</h4>
                    <sf-input :labelCols="3" v-model="account.companyCode" type="text" cstyle="width:15em;" label="Company Code" />
                    <sf-input :labelCols="3" v-model="account.jobCode" type="text" cstyle="width:15em;" label="Job Code" helpText="This also links with work orders" v-b-popover.hover.top="'This field is how work order assignments are connected to define which employees are capable of having tickets assigned to them'"/>
                    <sf-input :labelCols="3" v-model="account.glAccount" type="text" cstyle="width:15em;" label="GL Account" />
                    <sf-input :labelCols="3" v-model="account.arType" type="text" cstyle="width:15em;" label="AR Type" />
                    <sf-input :labelCols="3" v-model="account.locationId" type="text" cstyle="width:15em;" label="Location" />
                    <sf-input :labelCols="3" v-model="account.supervisor" type="text" cstyle="width:15em;" label="Supervisor" />
                    <h4>Payroll Identifiers</h4>
                    <sf-input :labelCols="3" v-model="account.payrollId" type="text" cstyle="width:15em;" label="Payroll ID (General)" />
                    <sf-input :labelCols="3" v-model="account.ADPemployeeNumber" type="text" cstyle="width:15em;" label="Payroll ID (ADP)" />
                    <sf-input :labelCols="3" v-model="account.QBemployeeId" type="text" cstyle="width:15em;" label="Payroll ID (QB)" />
                    <h4>Additional Export Identifiers</h4>
                    <sf-input :labelCols="3" v-model="account.exportIdentifier" type="text" cstyle="width:15em;" label="Export Tag" />
                    <sf-input :labelCols="3" v-model="account.referenceId" type="text" cstyle="width:15em;" label="Reference Tag" />
                    <sf-input :labelCols="3" v-model="account.familyNumber" type="text" cstyle="width:15em;" label="Family Tag" />
                  </b-form>
                </div>
              </b-tab>

<!--              <b-tab v-if="'client,teacher,faculty,prospect,vendor'.indexOf(account.accountType) >= 0" title="Notes">-->
<!--                <div style="margin:1em 2em 0 2em;">-->
<!--                  <b-form-group horizontal breakpoint="lg" label="Notes" label-size="lg" label-class="font-weight-bold pt-0">-->
<!--                    <sf-input v-model="account.notes[0].note" labelcols=0 type="textarea" cstyle="width:15em;" />-->
<!--                  </b-form-group>-->
<!--                </div>-->
<!--              </b-tab>-->

              <!------------------------------------------------------------------------------------------------[ Tickets ]----->

              <b-tab v-if="hasFeature('tickets')" title="Tickets" @click="ticketsActivated()" >

                <div>

                  <i v-if="isShowTickets" class="fa fa-gear" style="position:absolute;top:40px;right:5px;color:gray;font-size: 2em;" @click="isShowTickets=false" v-b-popover.hover.top="'Ticket Settings'"></i>
                  <div v-if="!isShowTickets" class="margin 1em 2em 0 2em;">

                    <b-form @change="dataChanged()">

                      <h4>Work Order Settings</h4>
                      <div class="row">
                        <div class="col-md-12 col-lg-6">
                          <sf-input type="textarea" :labelCols="2" v-model="account.helpdesk.notes" label="Notes" helpText="Details here are visible to helpdesk operators"/>
                        </div>
                        <div class="col-md-12 col-lg-6">
                          <b-form-checkbox type="checkbox" v-model="account.helpdesk.notifyBeforeArrival" >Notify Before Arrival</b-form-checkbox>
                          <b-form-checkbox type="checkbox" v-model="account.helpdesk.notifyOnAssignment" >Notify On Assignment</b-form-checkbox>
                          <b-form-checkbox type="checkbox" v-model="account.helpdesk.notifyOnScheduleChange" >Notify On Schedule Change</b-form-checkbox>
                          <b-form-checkbox type="checkbox" v-model="account.helpdesk.notifyOnActivity" >Notify On Activity</b-form-checkbox>
                          <b-form-checkbox type="checkbox" v-model="account.helpdesk.notifyOnComplete" >Notify On Complete</b-form-checkbox>
                        </div>
                      </div>

                      <b-button class="btn btn-sm btn-fill btn-warning" @click="isShowTickets=true;">Show Tickets</b-button>

                    </b-form>

                  </div>

                  <div v-if="isShowTickets" class="margin:1em 2em 0 2em;width:100%;" >

                    <div v-if="tickets && tickets.length > 0">
                      <h4>Client Tickets</h4>
                      <b-checkbox style="font-size:.8em;float:right;margin:.5em;" v-model="isShowClosedTickets">Show Completed Tickets</b-checkbox>
                      <template v-for="(ticket,index) in tickets" >
                        <ticket-card v-if="ticket.status !== 'complete' || isShowClosedTickets"
                                     :key="index"
                                     :ticketIndex="index"
                                     :ticket=ticket
                                     :lastDate="$root.user.lastLogon"
                                     @select="selectTicket($event)"
                                     @pin="nop"
                                     @billable="nop"
                                     @more="nop" />
                      </template>
                    </div>
                     <div v-else>
                      <h4>No Client Tickets</h4>
                     </div>
                    <div v-if="myTickets && myTickets.length > 0">
                      <hr />
                      <h4>Assigned Open Tickets</h4>

  <!--                    <b-checkbox style="font-size:.8em;float:right;margin:.5em;" v-model="isShowClosedMyTickets">Show Completed Tickets</b-checkbox>-->
                      <template v-for="(ticket,index) in myTickets" >
                        <ticket-card v-if="ticket.status !== 'complete' || isShowClosedMyTickets"
                                     :key="index"
                                     :ticketIndex="index"
                                     :ticket=ticket
                                     :lastDate="$root.user.lastLogon"
                                     @select="selectTicket($event)"
                                     @pin="nop"
                                     @billable="nop"
                                     @more="nop" />
                      </template>
                    </div>
  <!--                  <b-button class="mt-3 btn btn-sm btn-fill btn-warning" @click="isShowTickets=false">Show Ticket Settings</b-button>-->
                  </div>
                </div>
              </b-tab>

              <!------------------------------------------------------------------------------------------------[ Certifications ]----->

              <b-tab v-if="hasFeature('certifications')" title="Certifications" @click="certificationsFocus">

                <div class="margin:1em 2em 0 2em;">
                  <b-checkbox style="font-size:.8em;float:right;margin:.5em;" v-model="isShowExpired">Show All Certifications</b-checkbox>
                  <div class="show-hand mt-1" @click="addCertification()"><i class="fa fa-plus-square mt-2 mr-2" v-b-popover.hover.top="'Add New'" ></i>Add Certification</div>
                  <template v-if="certifications">
                    <template v-if="certifications.length>0">
                      <b-table striped hover sticky-header head-variant="dark"  stacked="md" :items="certificationsList" :fields="certificationFields" @row-clicked="editCertification($event)">
                        <template v-slot:cell(action)="data">
                          <i class="fa fa-trash" @click.stop="removeCertification(data)" v-b-popover.hover.top="'Remove'"></i>
                          <i v-if="!data.item.active" class="fa fa-times" style="margin-left:.75em;color:red;" v-b-popover.hover.top="'Inactive'"></i>
                          <i v-if="data.item.active && moment(data.item.expireDate).tz($root.TZ) >= moment()" class="fa fa-check" style="margin-left:.75em;color:green;" v-b-popover.hover.top="'Active'"></i>
                          <i v-if="moment(data.item.expireDate).tz($root.TZ).subtract(90,'days') < moment() && moment(data.item.expireDate).tz($root.TZ) > moment() && !data.item.renewal" class="fa fa-exclamation-triangle" style="color:orange;" v-b-popover.hover.top="'Expiring soon'"> </i>
                          <i v-if="data.item.renewal" class="fa fa-repeat" style="color:purple;" v-b-popover.hover.top="'Has been renewed'" ></i>
                        </template>
                        <template v-slot:cell(activeDate)="data">
                          <div>{{ moment(data.item.activeDate).tz($root.TZ).format("MM-DD-YYYY") }}</div>
                        </template>
                        <template v-slot:cell(expireDate)="data">
                          <div :style="(moment(data.item.expireDate).tz($root.TZ)<moment()) ? 'color:red;' :''" >{{ moment(data.item.expireDate).tz($root.TZ).format("MM-DD-YYYY") }}</div>
                        </template>
                      </b-table>
                    </template>
                    <template v-else>
                      <div class="mt-5 ml-5">
                        No Active Certifications
                      </div>
                    </template>
                  </template>
                  <template v-else>
                    <div class="mt-5 ml-5" >
                      No Certifications
                    </div>
                  </template>
                </div>
              </b-tab>


            </b-tabs>

            <!----------[ Buttons ]----------------------- -->

            <!-- <div class="text-center">
              <b-button class="btn btn-success btn-fill btn-sm float-right" :disabled=saveDisabled @click="saveDone()">
                Update Account
              </b-button>
              <b-button class="btn btn-danger btn-fill btn-sm float-right mr-2" @click="$router.go(-1)">
                Cancel
              </b-button>
            </div> -->
            <div class="clearfix"></div>
          </div>
          <br />
        </div>

        <!--------------------------------------------------------------------[ ROW Account Type ]----------------- -->

        <div v-if="accountTab===0" class="col-md-3 mb-5  " style="min-height:500px;">
          <h4>Account Type</h4>

          <!--<b-form-group>
            <b-form-select id="accountType" v-model="account.accountType" :options="accountTypes" class=""></b-form-select>
          </b-form-group>-->

          <b-form label="" @change="dataChanged()">
            <b-form-select id="accountClass" v-model="account.accountClassId" :disabled="!$root.isAdmin()" :key="account.accountClassId" :options="accountClasses" @change.native="accountClassChanged" class=""></b-form-select>
            <b-form-tags v-model="account.tags" tag-pills size="md" :tag-validator="tagValidator" ></b-form-tags>
          </b-form>

          <br>

          <b-form style="margin-left:33%;" @change="dataChanged()">
            <b-form-checkbox id="accountActive" type="checkbox" v-model="account.active">Account Active</b-form-checkbox>
            <b-form-checkbox id="accountIsOnHold" type="checkbox" v-model="account.isOnHold">Account On Hold</b-form-checkbox>
            <b-form-checkbox id="accountIsSupervisor" type="checkbox" v-model="account.isSupervisor">Is Supervisor</b-form-checkbox>
          </b-form>

          <br>

          <b-form style="width:100%;" @input="dataChanged()">
            <sf-input v-model="account.activeDate" type="date" cstyle="width:12em;" label="Active" />
            <sf-input v-model="account.expireDate" type="date" cstyle="width:12em;" label="Expire" />
          </b-form>

          <div  style="position:absolute;bottom:40px;width:90%;text-align:center;padding:.5em;">

            <h4 style="margin:0;padding:0;border-bottom:1px solid gray;">Last Update</h4>
            <table>
              <tr v-if="account.updatedAt"><td style="text-align:right;width:40%;font-weight:800;padding-right:1em;">Date</td><td style="text-align:left;">{{ moment(account.updatedAt).tz($root.TZ).format("LLL")}}</td></tr>
              <tr  v-if="account.lastUpdateBy"><td style="text-align:right;width:40%;font-weight:800;padding-right:1em;">By</td><td style="text-align:left;">{{ account.lastUpdateBy}}</td></tr>
            </table>

            <template v-if="account.import && account.import.importInvocation">
              <h4 style="margin:1em 0 0 0;padding-top:0;border-bottom:1px solid grey;">Last Import</h4>
              <table>
                <tr><td style="text-align:right;width:40%;font-weight:800;padding-right:1em;">Date</td><td style="text-align:left;">{{ moment(account.import.importDate).tz($root.TZ).format("LLL")}}</td></tr>
                <tr><td style="text-align:right;width:40%;font-weight:800;padding-right:1em;">Import Task</td><td style="text-align:left;">{{ account.import.importTag}}</td></tr>
                <tr><td style="text-align:right;width:40%;font-weight:800;padding-right:1em;">Invocation</td><td style="text-align:left;">{{ account.import.importInvocation}}</td></tr>
              </table>
            </template>

<!--            <div v-if="account.updatedAt" style="text-align:left;"><strong>Last update:</strong> {{ moment(account.updatedAt).tz($root.TZ).format("LLL") }}</div>-->
<!--            <div v-if="account.lastUpdateBy" style="text-align:left;"><strong>Last update by:</strong> {{ account.lastUpdateBy }}</div>-->

            <!-- <div><strong>Date:</strong> {{ moment(account.import.importDate).format("LLL") }}</div>
            <div><strong>Import Task:</strong> {{ account.import.importTag }}</div>
            <div><strong>Invocation:</strong> {{ account.import.importInvocation}}</div> -->
            <!-- <div v-if="account.import.importError"><strong>Issues:</strong> {{ account.import.importError}}</div>
            <div v-if="account.import.importNote"><strong>Notes:</strong> {{ account.import.importNote}}</div>
            </div> -->
          </div>

          <div v-if="pInfo && pInfo.private && pInfo.private.authorizations" style="margin-top:2em;">
            <b-button class="btn btn-sm btn-fill" v-b-toggle.auth-details >Show Auth Message</b-button>
            <b-collapse id="auth-details" class="collapse" >
              <hr>
              {{pInfo.private.authorizations.cardAuthorization || "" }}
            </b-collapse>
          </div>

        </div>

        <!--------------------------------------------------------------------[ Add Total Class ]------------------ -->

        <b-modal ref="addBadgeTotalClassScreen" hide-footer hide-header>
          <h3>Add Total</h3>
          <b-form>
            <b-form-group label="Account Class">
              <b-form-select id="accountTotalClass" v-model="totalClassId" :key="totalClassId" :options="availableTotalClasses" class=""></b-form-select>
            </b-form-group>
          </b-form>
          <div class="float-right">
            <b-btn class="btn btn-danger btn-fill" variant="outline-danger" @click="hideAddTotalToBadgeScreen">Cancel
            </b-btn>
            <b-btn class="btn-success btn-fill" variant="success" @click="addTotalClassToAccount">Add Total</b-btn>
          </div>
        </b-modal>

        <!---------------------------------------------------------------------[ Add Badge ]------------------ -->

        <b-modal ref="addBadgeScreen" hide-footer hide-header>
          <h3>Add Badge Number</h3>
          <b-form-group>
            <b-form-group label-for="addBadgeNumber" label="Badge Number">
              <i class="fa fa-magic float-right"></i>
              <b-form-input id="addBadgeNumber" v-model="addBadgeNumber"></b-form-input>
            </b-form-group>
          </b-form-group>
          <div class="float-right">
            <b-btn class="btn btn-danger btn-fill" variant="outline-danger" @click="hideAddBadgeScreen">Cancel</b-btn>
            <b-btn class="btn-success btn-fill" variant="success" @click="addBadgeToAccount">Add Badge</b-btn>
          </div>
        </b-modal>

        <!-----------------------------------------------------------------------[ Load Funds To Account ]-------------------- -->

        <b-modal ref="loadFundsScreen" hide-footer hide-header>
          <loading :active.sync="isShowLoading" :is-full-page="true" :height="200" :width="200" color="coral"></loading>
          <h3>Load Funds to Account</h3>
          <b-form-group cols=3>
            <sf-input label="Reference" type="text" v-model="reference"></sf-input>
            <sf-input label="Amount to Load" type="number" v-model="tenderAmount" ></sf-input>
            <div v-if="reloadMessage>''" @click="reloadMessage=''" class="">
              <b-alert variant="danger" show>{{ reloadMessage }}</b-alert>
            </div>
          </b-form-group>
          <div class="float-right">
            <b-btn class="btn btn-danger btn-fill" variant="outline-danger" @click="hideLoadFundsScreen">Cancel</b-btn>
            <b-btn class="btn-success btn-fill" variant="success" @click="loadFundsToAccount">Yep, Add Funds</b-btn>
          </div>
        </b-modal>


        <b-modal ref="postRefundScreen" hide-footer hide-header>
          <loading :active.sync="isShowLoading" :is-full-page="true" :height="200" :width="200" color="coral"></loading>
          <h3 style="color:white;background:red;padding:.25em;text-align:center;">Post REFUND to Account</h3>
          <b-form-group>
            <sf-input label="Reference" type="text" v-model="reference"></sf-input>
            <sf-input label="Refund Amount" type="number" v-model="tenderAmount"></sf-input>
            <div v-if="reloadMessage>''" @click="reloadMessage=''" class="">
              <b-alert variant="danger" show>{{ reloadMessage }}</b-alert>
            </div>
          </b-form-group>
          <div class="float-right">
            <b-btn class="btn btn-danger btn-fill" variant="outline-danger" @click="hidePostRefundScreen">Cancel</b-btn>
            <b-btn class="btn-success btn-fill" variant="success" @click="postRefundToAccount">Yep, Post REFUND</b-btn>
          </div>
        </b-modal>


        <b-modal v-if="account" v-model="isShowChangePassword" hide-header size="lg" header-bg-variant="info" header-text-variant="light" footer-bg-variant="info" footer-text-variant="light">
          <b-container fluid>
            <h4 style="margin-top:0;">Change Password</h4>
            <hr />
            <template v-if="isDataChanged">
              <h4>NOTICE</h4>
              <p style="color:red;font-weight:600;width:80%;margin:auto 2em auto;">You have pending changes to this account record; If you continue, your current changes will automatically be saved.  If you don't remember making changes, cancel this operation and try again.  This is not an issue, just a notice that your data will be saved.</p>
              <hr/>
            </template>
            <form autocomplete="new-password">
              <sf-input id="newPassword" label="New Password" type="text" cstyle="font-family:symbol;" autocomplete="new-password" v-model="newPassword"></sf-input>
              <sf-input id="confirmPassword" label="Confirm Pasword" type="text" cstyle="font-family:symbol;" autocomplete="new-password" v-model="confirmPassword" />
            </form>
            <p style="margin-left:19em;font-size:.8em;color:red;">For your system protection, passwords should be at least 7 characters long, contain a capital letter, and a special character. When both fields match, the "Change Password" button will appear.</p>
          </b-container>
          <div slot="modal-footer" class="w-100 mt-3">
            <b-btn class="float-right btn-fill btn-sm" v-if="newPassword===confirmPassword && newPassword.length>6" variant="success" @click="changePassword();">
              Change Password
            </b-btn>
            <b-btn class="float-right btn-fill btn-sm mr-3" variant="danger" @click="isShowChangePassword=false">
              Cancel
            </b-btn>
          </div>
        </b-modal>


        <!----------------------------- -->
      </div>
    </div>
  </div>
</template>
<script>

  import AssetService       from "/src/Services/AssetService";
  import AccountService     from "/src/Services/AccountService";
  import TenantService      from "/src/Services/TenantService";
  import TotalService       from "/src/Services/TotalService";
  import RevenueCenterService from "/src/Services/RevenueCenterService";
  import TransactionService from "/src/Services/TransactionService";
  import TicketService      from "/src/Services/TicketService";
  import OrderService       from "/src/Services/OrderService";
  import CertificationsService     from "/src/Services/CertificationsService";
  import AccountCertificationEdit from "/src/App/Views/Certifications/AccountCertificationEdit.vue";
  import Currency           from "currency.js";
  import Moment             from "moment-timezone";
  import gravatar           from "gravatar";
  import BadgeCard          from "/src/components/UIComponents/Cards/BadgeCard.vue";
  import OrderReceiptCard   from "/src/components/UIComponents/Cards/OrderReceiptCard.vue";
  import PortalRegistration from "./PortalRegistrationCard.vue";
  import Card               from "/src/components/UIComponents/Cards/Card.vue";
  import ContactCard        from "/src/components/UIComponents/Cards/ContactCard.vue";
  import ContactCard2        from "/src/components/UIComponents/Cards/ContactCard3.vue";
  import SfInput            from "/src/components/UIComponents/Inputs/SFormInput.vue";
  import LTable             from "/src/components/UIComponents/Table.vue";
  import TicketCard         from "/src/components/UIComponents/Cards/TicketCard.vue";
  import TicketEdit         from "/src/App/Views/Projects/TicketEdit.vue";
  import SimpleCalendar     from "/src/components/UIComponents/SimpleCalendar";
  import Loading            from "vue-loading-overlay";
  import {VueEditor}        from "vue2-editor";
  import "vue-loading-overlay/dist/vue-loading.css";

  //var t             = this;
  var accountNumber = "";

  // todo: Format this transaciton list specifically here so we can get better control of the outputs

  // transaction list example record
  // "totalClassId": 10,
  // "accountNumber": "26",
  // "badgeNumber": "26",
  // "title": "Sale",
  // "tenderNumber": 10,
  // "tenderAmount": 0,
  // "tenderQty": 1,
  // "isPayment": false,
  // "isMealPlan": false,
  // "revenueCenter": 0,
  // "transactionNumber": "",
  // "checkNumber": "",
  // "transactionTotal": 1.25,
  // "transactionDate"
  // "_id": "5afe5c94f226662fc80a180a"

  export default {
    Name      : "AccountEdit",
    props     : {
      accountNumber
    },
    components: {
      AccountCertificationEdit,
      BadgeCard,
      Card,
      ContactCard,
      ContactCard2,
      Loading,
      LTable,
      OrderReceiptCard,
      PortalRegistration,
      SfInput,
      SimpleCalendar,
      TicketCard,
      TicketEdit,
      VueEditor
    },
    data() {
      return {
        isDataChanged          : false,
        isShowChangePassword   : false,
        isShowExpired          : false,
        selectedCard: -1,
        portalId               : null,
        newPassword            : "",
        confirmPassword        : "",
        isAccountChanged       : false,
        isPInfoChanged         : false,
        isShowLoading          : false,
        isShowContactList      : true,
        isShowTickets          : true,
        isAddRegistration      : false,
        transactionListStyle   : false,
        // transactionListOptions: [
        //   {value: 'calendar',text: 'Calendar View'} ,
        //   {value: 'list',text: 'List View'} ,
        // ],
        canChangeBadge    : false,
        certifications    : null,
        certification     : null,
        accountLoaded     : false,
        calendarDate      : Moment().tz(this.$root.TZ),
        transactionHistory: null,
        transaction       : null,
        order             : null,
        title             : "undefined",
        message           : "Please enter your credentials",
        accountTab        : 0,
        badgeTab          : 0,
        accessLogFields   : [
          //{ key: "who", label: "Who", sortable: true },
          { key: "when", label: "When", sortable: true, sortDirection:'desc' },
          { key: "where", label: "From Where", sortable: true },
          { key: "who", label: "Who", sortable: true },
          { key: "notes", label: "Info", sortable: true },
        ],
        assetFields: [
          { key: "assetType", label: "Type", sortable: true },
          { key: "assetNumber", label: "#", sortable: true },
          // { key: "category", label: "Category", sortable: true },
          { key: "title", label: "Asset", sortable: true },
          { key: "description", label: "Description", sortable: true },
          { key: "purchase.price", label: "Price", sortable: true , formatter: (v) => { return this.currency(v)}},
          { key: "updatedAt", label: "Last Update", sortable: true , formatter: (v) => { return this.moment(v).fromNow()}}
        ],

        certificationFields: [
          { key: "action", label: "", sortable: false },
          { key: "certificationTitle", label: "Certification", sortable: true, formatter:(v1,v2,v3) => (v3.certificationNumber) ? v1 : "**" + v1 },
          { key: "main.certificationType", label: "Type", sortable: true,  },
          { key: "main.category", label: "Category", sortable: true,  },
          { key: "main.classification", label: "Classification", sortable: true,  },
          { key: "main.coverage.certificationTerm", label: "# Months", sortable: true,  },
          { key: "main.coverage.ceCredits", label: "CE's", sortable: true },
          { key: "activeDate", label: "Active", sortable: true , formatter:(v) => Moment(v).tz(this.$root.TZ).format("MM-DD-YYYY")},
          { key: "expireDate", label: "Expire", sortable: true , formatter:(v) => Moment(v).tz(this.$root.TZ).format("MM-DD-YYYY")},
        ],

        addBadgeNumber       : "",
        saveDisabled         : false,
        searchString         : "",
        reloadMessage        : "",
        isLoadFunds          : false,
        isPostRefund         : false,
        tenderAmount         : 0.00,
        reference            : "",
        sdate                : "",
        totalClassId         : 0,
        startDate            : new Date("01-01-2018"),
        endDate              : new Date(),
        accountTypes         : [ "user", "employee", "resident", "giftcard", "student", "faculty", "department", "vending", "coupon", "ticket", "parking", "other" ],   // todo: get these from the api
        accountClasses       : [],
        accountClass         : {},
        totalClasses         : [],
        accountBalances      : null,
        totalClassList       : [],
        availableTotalClasses: [],
        isShowClosedTickets  : false,
        isShowClosedMyTickets: false,
        assetList            : [],
        assetFilter          : "",
        myTickets            : null,
        tickets              : null,
        ticketId             : null,
        account              : {},
        pInfo                : null,
        portalConfig         : null
        // badge         : { badgeNumber: "", badgeType: "", alias: "", status: {isVIP: false, isOnHold: false, isOffSite: false} }
      };
    },
    watch     : {
      "account.accountClassId": {
        handler: function(val) {
          this.updateAccountClass();
        }
      }
    },
    async mounted() {
      let i;

      try {
        await Promise.all([
                            this.getRevenueCenters(),
                            this.getAccountClasses(),
                            this.getTotalClasses()
                          ]);
      } catch(err) {
        console.error("BadgeEdit:mounted() ", err);
        if(err.response) {
          if(err.response.data) {
            this.$root.errorMessage("Oops", err.response.data.message);
          }
        } else {
          this.$root.errorMessage("Oops", "Had an error getting initial settings");
        }
        return;
      }

      if(this.$root.tenant) {

        if(this.$root.tenant.accountClass) {   // this should never happen since we're getting it above.
          this.accountClasses = [];
          for(i = 0; i < this.$root.tenant.accountClass.length; i++){
            if(this.$root.tenant.accountClass[ i ].accountClassId === this.account.accountClassId) {
              this.accountClass = this.$root.tenant.accountClass[ i ];
            }
            this.accountClasses.push({
                                       value: this.$root.tenant.accountClass[ i ].accountClassId,
                                       text : this.$root.tenant.accountClass[ i ].title
                                     });
          }
        }

        if(this.$root.tenant.totalClasses) {
          this.totalClasses   = [];
          this.totalClassList = [];
          for(i = 0; i < this.$root.tenant.totalClasses.length; i++){
            this.totalClasses.push(this.$root.tenant.totalClasses[ i ]);
            this.totalClassList.push({
                                       value: this.$root.tenant.totalClasses[ i ].totalClassId,
                                       text : "" + this.$root.tenant.totalClasses[ i ].totalClassId + " : " + this.$root.tenant.totalClasses[ i ].title
                                     });
          }

          // this.totalClasses.forEach( function( x ) {
          //   this.totalClassList.push( {value: x.totalClassId , text: "" + x.totalClassId + " : " + x.title });
          // });

        }
      }

      this.isShowContactList = JSON.parse(localStorage.getItem( "isShowContactList" ));
      this.accouantNumber = decodeURIComponent( this.accountNumber );
      this.getAccount(decodeURIComponent( this.accountNumber ));

    },
    async beforeRouteLeave(to, from, next) { // this does not work and I "think" it is because the admin route is using the topBar nav which isn't wired in to the router
      if(!this.isDataChanged) {
        return next();
      }
      if(await this.$root.confirmYesNo('You have unsaved changes, continue leaving without saving?', "warning")) {
        return next();
      }
      next(false)
    },
    computed: {
      contactList() {
        if( !this.account.contacts ) {
          return [];
        }
        return this.account.contacts;
      },
      certificationsList() {
        // let today = Moment().subtract(120, 'days')
        if( !this.isShowExpired) {
          return this.certifications.filter( (c) => {
            if( c.active !== false && Moment(c.expireDate) >= Moment()) {
              return true;
            }
            // return !this.isExpired( today, c );
          });
        }

        return this.certifications.filter( (c) => {
          // if( c.active === false ) {
            return true;
          // }
          // return this.isExpired(today, c);
        });
      }
    },
    methods   : {
      nop() {
      },
      isExpired( today, c ) {
          // if( Moment(c.activeDate) <= today ) {
          if( Moment(c.expireDate) >= today ) {
            return false;
          }
          // }
        return true;
      },
      async addRegistration( registration ) {
        this.account.remoteAccess.push( registration );
        this.isAddRegistration = false;

        this.dataChanged();   // just in case we need the change flag to force a change.

        await this.saveAccount();     // save and reload the account so we get the _id, in case the user wants to change password next.
        await this.getAccount(this.account.accountNumber);
      },
      dataChanged() {
        this.isDataChanged = true;
      },
      contactsChanged() {
        console.log( "hit AccountEdit->contactsChanged()");
        this.dataChanged();
        this.account.contacts = _.sortBy( this.account.contacts, ['name','title']);
      },
      toggleShowContactList() {
        localStorage.setItem( "isShowContactList", JSON.stringify(this.isShowContactList ));
      },
      parkingActivate() {
        alert( "You clicked parking");
      },
      parkingLostFocus() {
        alert("parking lost focus");
      },
      async removeRegistration( i ) {
        if( await this.$root.confirm( "Remove this registration?")) {
          this.account.remoteAccess.splice(i,1);
          this.dataChanged();
        }
      },
      async changePassword() {
        if( ! await this.$root.confirmYesNo("Change password?")) {
          return;
        }

        if( ! this.portalId ) {
          return await this.$root.alert( "No Portal ID Available.  Please SAVE this account first." );
        }

        // let savedData = false

        if( this.isDataChanged ) {
          await this.saveAccount();      // save doesn't return the totalClass stuff so have to refresh that with a call to getAccount()
        }

        try {

          let result = await AccountService.setPortalPassword( this.portalId, this.newPassword );

          if( result.status !== 200 ) {
           return await this.$root.alert( result.data.message , "warning" );
          }

          await this.$root.alert( "Password reset complete" );
          this.isShowChangePassword = false;
        } catch( error ) {
          await this.$root.alert( error.message , 'warning' );
        }

        // if( savedData ) {   // because we are changing the "last modified" date/time, we need to reload this data.
          await this.getAccount(this.account.accountNumber);   // because we're changing data on the account record.
          // await this.getPInfo(this.account.accountNumber)
        // }

      },
      pInfoChanged() {
        this.isPInfoChanged = true;
        this.dataChanged();
      },
      accountChanged() {
        this.isAccountChanged = true;
      },
      revenueCenterName( revenueCenterId ) {
        let rc = _.find( this.revenueCenters , {revenueCenterId: revenueCenterId });
        if( !rc ) {
          return "**" + revenueCenterId.toString();
        }
        return rc.title;
      },
      async ticketsActivated() {
        if (!this.tickets) {
          await this.getTickets();
        }
        if( !this.myTickets ) {
          await this.getTicketsByAssigned();
        }
        this.isShowTickets = true;
      },
      updateTicketList(ticket) {
        let t = this;
        let found = false;
        for( var i=0; i<t.tickets.length; i++ ) {   // fixime: roll this into the list.
          if( t.tickets[i]._id === ticket._id ) {
            t.tickets[i] = ticket;
            found = true;
            break;
          }
        }
        if( ! found ) {
          t.tickets.unshift( ticket );    // plug the new ticket at the top.
        }
      },
      accountBalance( totalClass ) {
        let total = _.find( this.accountBalances , {totalClassId: totalClass.totalClassId } );
        if( total ) {
          return total.balance;

          // if( totalClass.totalType == 'debit' || totalClass.totalType == 'fund' ) {
          //   return -total.balance;
          // }
          // return total.balance;
        }
        return 0;
      },
      primaryBadgeName( account , index = 0 ) {
        if( account.badge && account.badge.length > index ) {
          return account.badge[index].lastName +', '+ account.badge[index].firstName
        }
        return account.accountTitle + `(No Badge ${index})`;
      },
      tagValidator(tag) {
        return tag === tag.toLowerCase() && tag.length > 2 && tag.length < 15
      },
      async selectTicket(e) {
        this.ticketId = e;
      },
      accountClassChanged() {
        this.dataChanged();

      },
      async addContact() {
        if( ! await this.$root.confirm( "Add a new contact?" )) {
          return;
        }
        this.dataChanged();
        this.isShowContactList = false;
        let r = {contactType:"contact", title: "", name: "", alias:"", address1:"", city:"",  state:"" , zip:"", website: "", phone: "", email: "", cell: "", sms: "", smsCarrier: "" , TZ: "America/New_York" }
        this.account.contacts.unshift( r );
      },
      async removeContact( index ) {
        if( ! await this.$root.confirm( "Remove " + this.account.contacts[index].title + "?" )) {
          return;
        }
        this.account.contacts.splice(index,1);
        this.dataChanged();
      },
      showMenu(t) {
        return `<b>${t.title}</b><br /><strong>Daily Limit: </strong>${t.limits.dailyQty} / ${this.currency(t.limits.daily)} <br /><strong>Total Limit: </strong> ${t.limits.totalQty} / ${this.currency(t.limits.total)} `
      },
      async saveDone() {
        await this.saveAccount();
        return this.done();
      },
      async done() {
        // if( this.isDataChanged ) {
        //   if( ! await this.$root.confirmYesNo( "Data Changed, Are you sure you want to leave?" )) {
        //     return;
        //   }
        // }
        if( this.canChangeBadge ) {       // letting it go back to the account list after we change the badge is a problem.
          this.$router.push( "/app/accounts" );
        } else {
          // this.$router.back(-1);          // FIXME: update this to ensure we are *NOT* moving back to login.
          window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/app/overview');
        }
      },
      moment(v) {
        return Moment(v).tz(this.$root.TZ);
      },
      currency(c) {
        return Currency(c);
      },
      // rowClicked(item) {
      //   this.$router.push(`/app/transaction/${item._id}`);
      // },
      editBadge() {
        // alert( "you clicked Edit for Badge " + this.badge.badgeNumber );
      },
      async getRevenueCenters() {
      var response;
      var t     = this;

      if( t.$root.tenant.revenueCenters ) {
        t.revenueCenters = t.$root.tenant.revenueCenters;
        return;
      }

      t.loading = true;

      try {
        response                      = await RevenueCenterService.getRevenueCenters();
        t.revenueCenters              = response.data;
        t.$root.tenant.revenueCenters = response.data;
      } catch (err) {
        if (err) {
          // alert("oops hit error in getRevenueCenters",err);
          t.$root.errorMessage("Oops", "Had a problem getting the revenue center listing");
        }
      }
      t.loading   = false;
    },
    async getTransactionsDateRange( bd , ed ) {
// http://sandbox.ioihan.com:9040/api/1/transactionListByAccount/1/2022-08-01%2012:00:00/2022-09-01%2011:59:59
// http://sandbox.ioihan.com:9040/api/1/transactionListByAccount/1/2022-08-01%2012:00:00/2022-09-01%2011:59:59

// http://sandbox.ioihan.com:9040/api/1/transactionListByAccount/1/2022-07-01%2012:00:00/2022-08-01%2011:59:59

      },
      async getTransactions( d ) {

        let firstDate = Moment(d).tz(this.$root.TZ).startOf( "month").format("YYYY-MM-DD HH:mm:ss").toString();
        let endDate = Moment(d).tz(this.$root.TZ).endOf( "month").format("YYYY-MM-DD HH:mm:ss").toString();

        try {
          let response = await TransactionService.transactionListByAccount( this.account.accountNumber, firstDate, endDate);
          let ordersResponse;
          if( this.hasFeature( "order-history" )) {
            ordersResponse  = await OrderService.getOrdersByAccount( this.account.accountNumber, firstDate, endDate);
          }

          // accountNumber: "wscott@ioihan.com"
          // alias: "Wolfie"
          // badgeNumber: "wscott@ioihan.com"
          // checkNumber: "2054"
          // isMealPlan: false
          // isPayment: false
          // revenueCenter: 5
          // tenderAmount: 4
          // tenderNumber: 130
          // tenderQty: 1
          // title: "Online Charge"
          // totalClassId: 20
          // transactionDate: "2020-12-21T16:32:07.742Z"
          // transactionNumber: "2054"
          // transactionTotal: 4
          // _id: "5fe0ce07517906a6287381bf"

          let trans = response.data;

          let eventList = [];

          eventList = eventList.concat(trans.map((t) => { return {
            type       : "transaction",
            title      : this.revenueCenterName( t.revenueCenter ),
            eventDate  : t.transactionDate,
            tStyle     : "color:#ff9900;",
            value      : Currency(t.tenderAmount),
            description: t.title,
            _id        : t._id
          }}));

          if( ordersResponse ) {
            let orders = ordersResponse.data;
            let o = orders.map((t) => { return {
              type       : "order",
              title      : "Online Order",
              tStyle     : "color: #289e0a;",
              eventDate  : Moment(t.orderDate).tz(this.$root.TZ).format("YYYY-MM-DD").toString(),
              value      : Currency(t.total),
              description: t.alias + "\nPlaced " + Moment(t.createdAt).format("ddd,Do HH:mm a"),
              _id        : t._id
            }});
            eventList = eventList.concat(o);
          }


          this.transactionHistory = eventList;

        } catch( error ) {
          console.log( "getTransactions()" , error.message );
          this.transactionHistory = null
          await this.$root.alert( error.message );
        }
      },
      async transactionLookup( id ) {
        try {

          let item =_.find(this.transactionHistory, {_id:id} );
          if( item ) {

            if( item.type == "transaction") {
              let response = await TransactionService.getTransaction( id );
              this.transaction = response.data;
              this.$refs['transaction-receipt'].show();
            } else if( item.type == "order" ) {
              let response = await OrderService.getOrder( id );
              this.order = response.data;
              this.$refs['order-receipt'].show();
            }
          }


        } catch( error ) {
          console.log( "Error" , error.message );
          this.$root.alert( error.message );
        }
      },
      async historyFocus() {
        let newDate = Moment().tz(this.$root.TZ);
        await this.getTransactions( newDate );

        this.calendarDate = newDate;

      },
      async transactionsGotoToday() {
        let newDate = Moment().tz(this.$root.TZ);
        await this.getTransactions( newDate );
        this.calendarDate = newDate;
      },
      async transactionsPrevMonth() {
        let newDate = Moment(this.calendarDate).tz(this.$root.TZ).subtract( 1, 'month' );
        await this.getTransactions( newDate );
        this.calendarDate = newDate;
      },
      async transactionsNextMonth() {
        if( this.calendarDate < this.moment()) {
          let newDate = Moment(this.calendarDate).tz(this.$root.TZ).add( 1, 'month' );
          await this.getTransactions( newDate );
          this.calendarDate = newDate;
        }
      },
      async transactionsSelectDay() {

      },
      async uploadBadgePicture() {
        await this.$root.alert( "Not Yet Implemented" , "warning");
      },
      hideAddTotalToBadgeScreen() {
        let t = this;
        t.$refs.addBadgeTotalClassScreen.hide();
      },
      hideAddBadgeScreen() {
        let t = this;
        t.$refs.addBadgeScreen.hide();
      },
      showAddBadgeScreen() {
        let t               = this;
        this.addBadgeNumber = "";
        t.$refs.addBadgeScreen.show();
      },
      showLoadFundsScreen() {
        let t = this;
        t.reloadMessage = "";
        t.$refs.loadFundsScreen.show();
        this.tenderAmount = 0.00;
        this.reference    = "";
      },
      async createBadgeNumber() {
        this.addBadgeNumber = Moment().format("C-YYMMDDHHmmssS").toString();
      },
      hideLoadFundsScreen() {
        this.$refs.loadFundsScreen.hide();
      },
      showPostRefundScreen() {
        let t = this;
        t.reloadMessage = "";
        t.$refs.postRefundScreen.show();
        this.tenderAmount = 0.00;
        this.reference    = "";
      },
      hidePostRefundScreen() {
        this.$refs.postRefundScreen.hide();
      },
      myGravatar( email ) {
        return gravatar.url( email );
      },
      getImage( b ) {
        if( b.pictureUrl ) {
          return b.pictureUrl;
        }
        return this.myGravatar( b.email );
      },
      async getPortalConfig() {
        if( this.portalConfig ) {
          return this.portalConfig;
        }
        try {
            let response = await TenantService.getPortalConfig();
            this.portalConfig = response.data;
          } catch( error )  {
            console.log( error );
            await this.$root.errorMessage( "Oops" , error.message );
          }
          return this.portalConfig;
      },
      updateAccountClass() {    // todo: why are we doing this, wolfie?
        for(var i = 0; i < this.$root.tenant.accountClass.length; i++){
          if(this.$root.tenant.accountClass[ i ].accountClassId === this.account.accountClassId) {
            this.accountClass        = this.$root.tenant.accountClass[i];
            this.account.accountType = this.accountClass.accountType;
          }
        }
      },
      async loadFundsToAccount() {
        if(!await this.$root.confirm("Ready To Add " + Currency(this.tenderAmount).toString() + "?")) {
          return;
        }

        await this.getPortalConfig();

        if( !this.portalConfig.active ) {
          await this.$root.errorMessage( "NOTE", "Sorry, this feature is not available at this time." );
        }

        try {
          this.isShowLoading = true;
          let transaction = {
            account      : this.account.accountNumber,
            badgeNumber  : this.account.badge[ 0 ].badgeNumber,
            tenderNumber : this.portalConfig.reloadTransaction.tenderNumber,    // todo: get this info from the tenant config
            revenueCenter: this.portalConfig.reloadTransaction.revenueCenter,
            tenderAmount : this.tenderAmount,
            reference    : this.reference,
            totalClassId : this.portalConfig.reloadTransaction.totalClassId,
            comment      : this.reference,
            notes        : ""
          }

          let result = await TransactionService.ccTransactionPost(transaction);
          this.$root.successMessage("Funds Added Successfully", Currency(this.tenderAmount).toString() + " Added");
          this.hideLoadFundsScreen();
        } catch(error) {
          console.log(error);
          if( error.response ) {
            if( error.response.data ) {
              this.reloadMessage = error.response.data.message;
            } else {
              this.reloadMessage = error.message;
            }
          } else {
            this.reloadMessage = error.message;
          }
          this.$root.errorMessage("Oops", this.reloadMessage );
        }
        this.isShowLoading = false;
      },
      async getTickets() {
        var t = this;
        t.loading = true;
        try {

          let id = t.account._id;

          let response = await TicketService.getTicketsByClient(id);
          t.tickets = response.data;

        } catch (error) {
          if (error) {
            console.error(error);
            t.$root.errorMessage("Oops", error.response.data.message);
          }
        }
        t.loading = false;
      },
      async getTicketsByAssigned(ticketType = null) {
        let t = this;
        t.loading = true;
        try {
          let id = t.account._id;
          let response = await TicketService.getTicketsByAssigned( id  );
          t.myTickets = response.data;

        } catch (error) {
          if (error) {
            console.error(error);
            t.$root.errorMessage("Oops", error.response.data.message);
          }
        }
        t.loading = false;
      },
      async postRefundToAccount() {
        if(!await this.$root.confirm("Ready To Post a REFUND of " + Currency(this.tenderAmount).toString() + "?")) {
          return;
        }

        await this.getPortalConfig();

        if( !this.portalConfig.active ) {
          await this.$root.errorMessage( "NOTE", "Sorry, this feature is not available at this time." );
        }

        try {
          this.isShowLoading = true;
          let transaction = {
            account      : this.account.accountNumber,
            badgeNumber  : this.account.badge[ 0 ].badgeNumber,
            tenderNumber : this.portalConfig.reloadTransaction.tenderNumber,    // todo: get this info from the tenant config
            revenueCenter: this.portalConfig.reloadTransaction.revenueCenter,
            tenderAmount : this.tenderAmount,
            reference    : this.reference,
            comment      : this.reference,
            notes        : "",
            totalClassId : this.portalConfig.reloadTransaction.totalClassId
          }

          let result = await TransactionService.ccTransactionRefund(transaction);
          this.$root.successMessage("REFUND Processed Successfully", Currency(this.tenderAmount).toString() + " Refunded");
          this.hidePostRefundScreen();
        } catch(error) {
          console.log(error);
          if( error.response ) {
            if( error.response.data ) {
              this.reloadMessage = error.response.data.message;
            } else {
              this.reloadMessage = error.message;
            }
          } else {
            this.reloadMessage = error.message;
          }
          this.$root.errorMessage("Oops", this.reloadMessage );
        }
        this.isShowLoading = false;
      },
      isExist(badgeNumber) {
        let t = this;
        if(_.find(t.account.badge, {badgeNumber: badgeNumber})) {
          return true;
        }
        return false;
      },
      async selectAsset( asset ) {
        // let routeData = this.$router.resolve({name: 'AssetEdit', query: {assetId: asset._id }});
        // window.open(routeData.href, '_blank');
        if( !await this.$root.confirm( "This action will leave this Unit edit")) {
          return;
        }
        this.$router.push( {path: "/app/assetEdit/" + asset._id });
      },
      async certificationsFocus() {
        if (this.certifications) {
          return;
        }
        await this.getCertifications();
      },
      async editCertification( data ) {
        this.certification = data;
        delete this.$root.certificationsMasterList;
      },
      async saveCertification( data ) {
      },
      async cloneCertification( data ) {
      },
      async removeCertification(data) {
        if( ! await this.$root.confirmYesNo( `Remove<br>[${data.item.certificationTitle}]`)) {
          return;
        }
        let id = data.item._id;

        try {
          let response = await AccountService.removeCertification( id );
          this.certifications = _.filter( this.certifications, function( item ) {
            return item._id !== id;
          });
        } catch( error ) {
          this.$root.alert( error.message );
        }

      },
      async addCertification() {
        // if( ! await this.$root.confirm( "Add New Certification" )) {
        //   return;
        // }

        let accountNumber = this.account.accountNumber;
        let badgeNumber   = this.account.badge[0].badgeNumber;

        let cert = {
          accountNumber      : accountNumber,
          badgeNumber        : badgeNumber,
          active             : true,

          ownerId   : this.account._id,
          ownerTitle: this.account.accountTitle,

          // certificationNumber: "1416023563645315",
          certificationTitle: "New",
          certificationType : "-unassigned-",
          category          : "",
          section           : "",
          classification    : "",
          activeDate        : Moment().tz(this.$root.TZ).toDate(),
          expireDate        : Moment().tz(this.$root.TZ).add(12, 'months').toDate(),
          term              : 12,

          history: []
        }

        try {
          let response = await AccountService.addCertification(accountNumber, badgeNumber, cert);
          // this.certifications.push(response.data);
          this.editCertification( response.data );
        } catch (error) {
          console.error(error);
          if( error.response && error.response.data ) {
            await this.$root.alert( error.response.data.message );
          } else {
            await this.$root.alert( error.message );
          }
        }

      },

      async getCertifications() {
        try {
          let isGetAll = 'all' // (this.isShowAllCertifications) ? "all" : "";
          let response = await AccountService.getCertifications(this.account.accountNumber, "", isGetAll );
          this.certifications = response.data;

        } catch (error) {
          console.log(error);
        }
      },
      contactsFocus() {
        // nothing to do here yet.
      },
      assetsFocus() {
        if (this.assets) {
          return;
        }
        this.getAssets();
      },
       async getAssets() {
         try {
          let response = await AssetService.getAssetsInRoom(this.account.roomNumber);
          this.assetList = response.data;
        } catch (error) {
          console.log(error);
        }
      },
      accountBadgeTitle() {
        let ac = _.find( this.$root.tenant.accountClass, { accountClassId: this.account.accountClassId } );
        if( ac ) {
          return ac.badgeTitle;
        }
        return "Badge";
      },
      hasFeature( feature ) {
        let ac = _.find( this.$root.tenant.accountClass, { accountClassId: this.account.accountClassId } );
        if( ac ) {
          return ac.features.indexOf( feature ) >= 0;
        }
        return false;
      },
      hasOption( o ) {
        let ac = _.find( this.$root.tenant.accountClass, { accountClassId: this.account.accountClassId } );
        if( ac ) {
          return ac.options.indexOf( o ) >= 0;
        }
        return false;
      },
      async removeBadgeFromAccount(badgeNumber) {
        let t = this;
        if(t.account.badge.length <= 1) {
          //return alert("Cannot remove the last badge");
          return await this.$root.alert("Cannot remove the last badge");
        }

        if(await this.$root.confirm("Remove " + badgeNumber)) {
          // alert( "This feature has not yet been implemented");
          _.remove(t.account.badge, {badgeNumber: badgeNumber});

          await t.saveAccount();      // save doesn't return the totalClass stuff so have to refresh that witha  call to getAccount()
          await t.getAccount(t.account.accountNumber);
        }

      },
      async addBadgeToAccount() {
        let t = this;

        if(t.isExist(t.addBadgeNumber)) {
          //alert("That badge already exists");
          await this.$root.alert("That badge already exists");
          return;
        }

        // if(_.find(t.account.badge, {badgeNumber: t.addBadgeNumber})) {
        //   alert("That badge already exists");
        //   return;
        // }

        t.account.badge.push({
                               badgeNumber: t.addBadgeNumber,
                               active     : true,
                               alias      : t.addBadgeNumber,
                               status     : {isVIP: false, isOnHold: false}
                             });
        await t.saveAccount();      // save doesn't return the totalClass stuff so have to refresh that with a call to getAccount()
        await t.getAccount(t.account.accountNumber);
        t.hideAddBadgeScreen();
      },
      async showAddTotalsToBadgeScreen() {
        let t = this;

        t.availableTotalClasses = t.totalClassList.filter(function(tc) {
          return !_.find(t.account.totalClass,
                         [ "totalClassId", tc.value ])
        });

        if(t.availableTotalClasses.length === 0) {
          // alert("No Totals Available To Assign");
          await this.$root.alert("No totals available to assign");
          return;
        }

        t.totalClassId = t.availableTotalClasses[ 0 ].value;

        t.$refs.addBadgeTotalClassScreen.show();
      },
      addTotalClassToAccount() {
        let i;
        for(i = 0; i < this.totalClasses.length; i++){
          if(this.totalClassId === this.totalClasses[ i ].totalClassId) {
            this.account.totalClass.push(this.totalClasses[ i ]);
            this.hideAddTotalToBadgeScreen();
            this.dataChanged();
          }
        }
      },
      async deleteTotalClassFromBadge(totalClassId) {
        for(var index=0; index<this.account.totalClass.length; index++ ) {
          if( this.account.totalClass[index].totalClassId === totalClassId) {
            if(await this.$root.confirm("Remove " + this.account.totalClass[index].totalClassId + ":" + this.account.totalClass[ index ].title + "?")) {
              this.account.totalClass.splice(index, 1);
              this.dataChanged();
            }
            break;
          }
        }
      },
      async getAccountBalances(accountNumber) {
        let t = this;
        if( this.accountBalances ) {
          return;
        }
        try {
          let response = await AccountService.getAccountBalances(accountNumber);
          t.accountBalances = response.data;
        } catch(err) {
          if(err) {
            console.error(err);
            t.$root.errorMessage("Oops", err.response.data.message);
          }
        }
      },
      async getAccount(accountNumber) {
        let t = this;
        try {
          let response = await AccountService.getAccountByAccount(accountNumber);
          t.account    = response.data;
          t.updateAccountClass();
          t.pInfo = null;
          t.isPInfoChanged = false;
          t.isDataChanged = false;
          t.accountLoaded = true;
        } catch(err) {
          if(err) {
            console.error(err);
            t.$root.errorMessage("Oops", `${accountNumber} :: ${err.response.data.message}`);
          }
        }
      },
      async getPInfo(accountNumber) {
        let t = this;
        if (t.pInfo) {
          return;
        }

        try {
          let response = await AccountService.getAccountPInfo(accountNumber);
          t.pInfo = response.data;
        } catch(err) {
          if(err) {
            console.error(err);
            t.$root.errorMessage("Oops", err.response.data.message);
          }
        }
      },
      async savePInfo(accountNumber, pInfo ) {
        try {
          let response = await AccountService.saveAccountPInfo(accountNumber, pInfo);
          // this.pInfo = response.data;
        } catch(err) {
          if(err) {
            console.error(err);
            this.$root.errorMessage("Oops", err.response.data.message);
          }
        }
      },
      async getTotalClasses() {
        let t = this;

        if(!t.$root.tenant.totalClasses) {
          let response                = await TotalService.getTotals();
          t.$root.tenant.totalClasses = response.data;
        }
      },
      async getAccountClasses() {
        let t = this;

        if(!t.$root.tenant.accountClass) {
          let response                = await AccountService.getAccountClasses();
          t.$root.tenant.accountClass = response.data; //.accountClass;
        }
      },
      async saveAccount() {
        let t = this;
        try {
          let response;

          response = await AccountService.saveAccount(this.account._id, this.account);
          t.$root.successMessage("SUCCESS", "Account Updated");
          t.$root.tenant.accountList = [];       // force a list reload

          if( t.isPInfoChanged ) {    // do this last as it updates the updatedAt value.
            await this.savePInfo(this.account.accountNumber, this.pInfo);
            t.isPInfoChanged = false;
          }
          t.isDataChanged = false;

          // t.$router.go(-1);
          // t.account = response;
        } catch(err) {
          if(err) {
            console.error(err);
            t.$root.errorMessage("SAVE ACCOUNT", err.message );
          }
        }
      }
    }
  };
</script>
<style language="scss" >

  @keyframes fade-in{
      0% { background:rgba(255,25,25,0); }
      66% { background:rgba(255,25,25,0); }
      100% { background:rgba(255,25,25,.3); }
  }

  @-webkit-keyframes fade-in{
    0% { background:rgba(255,25,25,0); }
      66% { background:rgba(255,25,25,0); }
      100% { background:rgba(255,25,25,.3); }
  }

 @keyframes breath{
      0% { border:5px solid #eee; }
      100% { border:5px solid red; }
  }
  @-webkit-keyframes breath{
       0% { border:5px solid #eee; }
      100% { border:5px solid red; }
  }

  @keyframes smoothFadeIn {
    from { opacity: 0; }
  }

  .account-disabled {
    padding:1em;
    border-radius:1em;
    animation-name: fade-in;
    animation-duration: 1.5s;
    animation-delay: 0s;
    animation-fill-mode: forwards;
  }

  .account-on-hold {
    padding:1em;
    border-radius:1em;
    background:rgba(255,128,32,.2);
  }

  .badge-disabled {
    animation-name: breath;
    animation:breath 3s infinite alternate;
    /* animation-fill-mode: forward; */

    /* animation: fadeIn 3s infinite alternate; */
  }

  .badge-on-hold {
    border:5px solid orange;
  }

  .badge-active {
    border:2px solid green;
  }

  .receipt-format1 {
    /* font-size:14px; */
    margin-top:.5em;
    padding:.5em;
    margin-left:0;
    font-family:'Courier New', Courier, monospace;
    white-space:pre;
    line-height:1em;
    overflow:auto;
  }


</style>
