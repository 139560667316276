"use strict";

//
//  dataExportlib
//
//  author: wjscott 14-Jan-20
//
//  Some export goodness
//

import Currency from "currency.js";
import Moment   from "moment";
import _        from "lodash";

export default {

  moduleVersion : "1.0.0",
  dateFormat    : 'YYYY-MM-DD HH:mm',
  fieldSeparator: ",",
  lineTerminator: "\n",
  includeHeader : true,
  months  : [ 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec' ],

  currency(value, precision = 2, symbol = ' ' ) {
    return Currency(value, {precision: precision, symbol: symbol}).format();
  },
  moment(d) {
    return Moment(d);
  },
  q(value) {
    return '"' + value + '"';
  },
  USD(value, precision = 4, symbol = ' ' ) {
    return Currency(value, {precision: precision, symbol: symbol}).format();
  },
  monthName(m) {
    return this.months[ parseInt(m) - 1 ] || "-";
  },
  doFormatter(v3, f, value) {
    try {
      if(f.length <= 1 ) {  // if we don't have a { in the formatter, then we're not using the record, so we'll pass the value
        return f(value);
      }
      return f(v3, v3, v3);
    } catch(error) {
      return "";
    }
  },
  processFormula(formula, data, fieldIndex, itemIndex) {
    if(!formula) {
      return "**";
    }
    let f      = formula.replaceAll("v3.", "data.");
    let result = eval(f);
    return result;
  },

  getFieldData(field, data, fieldIndex, itemIndex) {
    let dt = field.dataType;
    if(field.formula && field.formula.length > 0) {
      dt = "formula";
    }
    switch(dt){
      case "currency":
        return this.USD(_.get(data, field.key));
        break;
      case "date":
        return moment(_.get(data, field.key)).format("MM/DD/YYYY");
        break;
      case "datetime":
        return moment(_.get(data, field.key)).format("MM/DD/YYYY hh:mm:ss");
        break;
      case "time":
        return moment(_.get(data, field.key)).format("hh:mm:ss");
        break;
      case "formula":
        let result = this.processFormula(field.formula, data, fieldIndex, itemIndex);
        return result;
      case "number":
        return _.get(data, field.key);
        break;
      case "boolean":
        return _.get(data, field.key) ? "Yes" : "No";
        break;
      case "string":
      default:
        if(field.formatter) {
          return field.formatter(_.get(data, field.key), field, data, fieldIndex, itemIndex);
        }
        return _.get(data, field.key);
    }
  },
  _csvExportData(data, fields, includeHeader = true) {
    let outputData     = "";
    this.includeHeader = includeHeader;
    // console.log("data" , data );
    if( !data || data.length === 0 ) {    // nothing to do...
      return "no data";
    }

    if (fields && fields.length > 0) {      // some callers send in a list of string names - we need to convert them to objects
      if (typeof fields[0] !== "object") {
        fields = fields.map(field => ({ key: field }));
      }
    }

    // if(!fields) {                           // nothing came in, so look at the incoming object and get all fields.
    //   let f  = Object.keys(data[ 0 ]);
    //   fields = [];
    //   for(var i = 0; i < f.length; i++){
    //     fields.push({key: f[ i ]});
    //   }
    // }

    if (!fields) { // nothing came in, so look at the incoming object and get all fields.
      let f = Object.keys(data[0]);
      fields = f.filter(key => {
        const value = data[0][key];
        return typeof value !== "object" || (value !== null && typeof value === "object");
      }).map(key => ({ key }));
    }

    // console.log("fields", fields);
    if(data && fields) {
      if(this.includeHeader) {
        let line = "";
        for(let fieldCount = 0; fieldCount < fields.length; fieldCount++){
          let columnHeader = fields[ fieldCount ][ "exportColumnHeader" ];
          if(typeof columnHeader === "undefined") {
            columnHeader = fields[ fieldCount ].key;
          }
          if(line.length > 0) {
            line += this.fieldSeparator + this.q(columnHeader);
          } else {
            line = this.q(columnHeader);
          }
        }
        outputData += line + this.lineTerminator;
      }

      for(let recordCount = 0; recordCount < data.length; recordCount++){
        let record = data[ recordCount ];
        let line   = "";
        for(let fieldCount = 0; fieldCount < fields.length; fieldCount++){
          let field     = fields[ fieldCount ].key;       // todo: make this a little more robust and forgiving
          let dataType  = fields[ fieldCount ].dataType || "";
          let formatter = fields[ fieldCount ].formatter || "";
          let fieldData = "";

          if(formatter.length > 0) {      // if we're formatting, then let's format - but if we're not a string type, we need to eat commas.
            let value = _.get(record, field) || "";
            fieldData = this.doFormatter(record, formatter, value);
            if(dataType === 'currency' || dataType === 'money' || dataType === 'number') {
              // console.log( field, fieldData, value , formatter);
              fieldData = fieldData.toString().replaceAll(",", "");
            } else {
              fieldData = this.q(fieldData);    // non-numeric types need to be quoted
            }
          } else {
            switch(dataType){
              case 'string':
                fieldData = this.q(_.get(record, field) || "");
                break;

              case 'date':
                try {
                  let da = _.get(record, field);
                  if(typeof da == "undefined") {    // prevent Moment() "invalid date" response
                    fieldData = this.q("");
                  } else {
                    fieldData = this.q(Moment(da).format(this.dateFormat).toString());
                  }
                } catch(error) {
                  fieldData = "";
                }
                break;

              case 'number':
                fieldData = parseInt(_.get(record, field) || 0).toString();
                break;

              case 'currency':
              case 'money':
                fieldData = Currency(_.get(record, field) || 0.00).toString().replaceAll(" ", "");
                break;

              case 'boolean':
              case 'bool':
              case 'logical':
                let d     = _.get(record, field) || false;
                fieldData = (d === true) ? 1 : 0;
                break;

              case "formula":
                fieldData = this.processFormula(field.formula, record, fieldCount, recordCount);
                break;

              case 'raw':
                fieldData = _.get(record, field) || "";
                break;

              default:
                fieldData = this.q(_.get(record, field) || "");
                break;
            }
          }

          let fData;
          if(typeof fieldData !== "string") {
            fData = fieldData.toString();
          } else {
            fData = fieldData;
          }
          if(line.length > 0) {
            line += this.fieldSeparator + fData;
          } else {
            line = fData;
          }
        }
        outputData += line + this.lineTerminator;
      }
    }
    return outputData;
  },
  csvExport(data, fields, outputFile = "export.csv") {
    let outputData = "\ufeff" + this._csvExportData(data, fields);
    let blob       = new Blob([ outputData ], {type: "application/csv;charset=utf-8"});
    // console.log("output File", outputData );
    saveAs(blob, outputFile);
  },
  csvExportAskFilename(data, fields = null, message = "Please provide a filename") {
    let filename = prompt(message);
    if(!filename || filename.length === 0) {
      return;
    }
    if(filename.indexOf('.') < 0) {
      filename += ".csv";
    }
    // console.log("filename", filename);
    this.csvExport(data, fields, filename);
  }

};
