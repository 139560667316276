import AppLayout from "./Layout/AppLayout.vue";

// GeneralViews
import NotFound from "/src/GeneralViews/NotFoundPage.vue";

//--------------------------------------------------------------------------------[ Free App pages
import Home from "/src/Home/Home.vue"
import Login from "/src/GeneralViews/Login.vue";
import Tos from "/src/components/UIComponents/tos";
import Privacy from "/src/components/UIComponents/privacy";
import ForgotPassword from "/src/GeneralViews/ForgotPassword.vue";
import Register from "/src/GeneralViews/Register.vue";

//---------------------------------------------------------------------------------[ Default Route
import Overview from "/src/App/Views/Overview.vue";

import Accounts from "/src/App/Views/Accounts.vue";
import Account from "/src/App/Views/Account.vue";
import AccountEdit from "/src/App/Views/AccountEdit.vue";
import Assets from "/src/App/Views/Assets.vue";
import AssetEdit from "/src/App/Views/AssetEdit.vue";
import Transactions from "/src/App/Views/Transactions.vue";
import Transaction from "/src/App/Views/Transaction.vue";
import Vendors from  "/src/App/Views/Vendors/Vendors.vue";

//---------------------------------------------------------------------------------[ User Routes
import Users from "/src/App/Views/Users/Users.vue";
import User from "/src/App/Views/Users/UsersForm.vue";
import UserProfile from "/src/App/Views/UserProfile/UserProfile.vue";

//---------------------------------------------------------------------------------[ Admin Routes
import TenantProfile from "/src/App/Views/Tenant/TenantProfile.vue";
import Sites from "/src/App/Views/Tenant/Sites.vue";
import Devices from "/src/App/Views/Tenant/Devices.vue";
import Integrations from "/src/App/Views/Tenant/Integrations.vue";
import AccountClass from "/src/App/Views/Tenant/AccountClass.vue";
import Templates from "/src/App/Views/Tenant/Templates.vue";
import Interfaces from "/src/App/Views/Tenant/InterfaceForm.vue";
import PaymentProviders from "/src/App/Views/Tenant/PaymentProviders.vue";
import Tenders from "/src/App/Views/Tenant/Tenders.vue";
import Totals from "/src/App/Views/Tenant/Totals.vue";
import Locations from "/src/App/Views/Tenant/Locations.vue";
import RevenueCenters from "/src/App/Views/Tenant/RevenueCenters.vue";
import TableList from "/src/App/Views/TableList.vue";
import Icons from "/src/App/Views/Icons.vue";
import TestPage from "/src/App/Views/TestPage.vue";
import Typography from "/src/App/Views/Typography.vue";
import Notifications from "/src/App/Views/Notifications.vue";
import CategorySetup from "/src/App/Views/Tenant/CategorySetup.vue";
import CertificationsSetup from "/src/App/Views/Tenant/CertificationsSetup.vue";
import ScheduleSetup from "/src/App/Views/Tenant/ScheduleSetup.vue";
import Reports from "/src/App/Views/Tenant/Reports.vue";
import WorkstationConfig from "/src/App/Views/Tenant/WorkstationConfig.vue";
import TicketConfig from "/src/App/Views/Tenant/TicketConfig.vue";
import HousingConfig from "/src/App/Views/Tenant/HousingConfig.vue";
import AssetConfig from "/src/App/Views/Tenant/AssetConfig.vue";
import DonationConfig from "/src/App/Views/Tenant/DonationConfig.vue";
import EventsConfig from "/src/App/Views/Tenant/EventsConfig.vue";
// import RotationConfig from "/src/App/Views/Tenant/RotationConfig.vue";
import Logs from "/src/App/Views/Logs.vue";

//---------------------------------------------------------------------------------[ Report Routes
import ReportMenu from "/src/Reports/Reports.vue";
import TestReport from "/src/Reports/TestReport.vue";
// import AccountBalance from "/src/Reports/AccountBalance.vue";

//---------------------------------------------------------------------------------[ Portal Routes
import PortalMenu from "/src/App/Views/Portal.vue";
import PendingAccounts from "/src/App/Views/Portal/PendingAccounts.vue";
import PortalAuthorizations from "/src/App/Views/Portal/PortalAuthorizations.vue";
import PortalAuthorizationsTeachers from "/src/App/Views/Portal/PortalAuthorizationsTeachers.vue";
import PortalUploadStudentFile from "/src/App/Views/Portal/PortalUploadStudentFile.vue";
import FaqManagement from "/src/App/Views/Portal/FaqManagement.vue";
import MenuItemMaster from "/src/App/Views/Portal/MenuItemMaster.vue";
import OrderCalendar from "/src/App/Views/Portal/OrderCalendar.vue";

import KioskMenu from "/src/App/Views/Kiosks/Kiosks.vue";

import PortalOrders from "/src/App/Views/Portal/PortalOrders.vue";
import PortalOrdersSummary from "/src/App/Views/Portal/PortalOrdersSummary.vue";

// import FunctionMenu from "/src/App/Views/Functions.vue";

//---------------------------------------------------------------------------------[ Housing Routes
// import Housing from "/src/App/Views/Housing/Housing.vue";
// import Lands from "/src/App/Views/Housing/Lands.vue";
// import Units from "/src/App/Views/Housing/Units.vue";
// import Buildings from "/src/App/Views/Housing/Buildings.vue";
// import Contracts from "/src/App/Views/Housing/Contracts.vue";

//---------------------------------------------------------------------------------[ Marketing Routes
import Marketing from "/src/App/Views/Marketing/Marketing.vue";
import AvailableUnits from "/src/App/Views/Marketing/AvailableUnits.vue";
import ModelInformation from "/src/App/Views/Marketing/ModelInformation.vue";

//---------------------------------------------------------------------------------[ Environmental Routes
import EnvironmentalOverview from "/src/App/Views/Environmental/Overview.vue";
import EnvironmentalTemperatures from "/src/App/Views/Environmental/Temperatures.vue";

//---------------------------------------------------------------------------------[ Tickets/Work orders Routes
import SimpleTasks from "/src/App/Views/Projects/SimpleTasks.vue";
import Procedures from "/src/App/Views/Procedures/Procedures.vue";

// import TicketMenu from "/src/App/Views/Projects/TicketMenu.vue";
// import Tickets from "/src/App/Views/Projects/Tickets.vue";
// import TicketStatus from "/src/App/Views/Projects/TicketStatus.vue";
// import TicketSummary from "/src/App/Views/Projects/TicketSummary.vue";
// import TicketWizard from "/src/App/Views/Projects/TicketWizard.vue";
// import TicketEdit from "/src/App/Views/Projects/TicketEdit.vue";

//---------------------------------------------------------------------------------[ Donation Routes
// import DonationsMenu from "/src/App/Views/Donations/Overview.vue";

//---------------------------------------------------------------------------------[ Help Routes
import HelpMenu from "/src/Help/Menu.vue";

/* no eslint */

//--------------------------------------------[ Free Pages ]--
let homePage = {
  path     : "/",
  name     : "home",
  component: Home,
};

let loginPage = {
  path     : '/login',
  name     : 'Login',
  component: Login
}

let tosPage = {
  path     : '/tos',
  name     : 'Tos',
  component: Tos
}

let privacyPage = {
  path     : '/privacy',
  name     : 'Privacy',
  component: Privacy
}

let registerPage = {
  path     : '/register',
  name     : 'Register',
  component: Register
}

let forgotPasswordPage          = {
  path     : '/forgotpassword',
  name     : 'ForgotPassword',
  component: ForgotPassword,
  props    : true
}
let forgotPasswordResetCodePage = {
  path     : "/forgotpassword/:resetCode",
  name     : "ForgotPasswordResetCode",
  component: ForgotPassword,
  props    : true
};

//--------------------------------------------------------------[ APP Pages ]--

let appMenu = {

  path     : "/app",
  name     : "appMenu",
  meta     : {requiresAuth: true},
  component: AppLayout,
  redirect : "/app/overview",
  children : [
    {
      path     : "overview",
      name     : "Overview",
      component: Overview
    },
    {
      path     : "accounts",
      name     : "Accounts",
      component: Accounts
    },
    {
      path     : "account/:badgeNumber/:accountNumber",
      name     : "Account",
      component: Account,
      props    : true
    },
    {
      path     : "accountedit/:accountNumber",
      name     : "AccountEdit",
      component: AccountEdit,
      props    : true
    },
    {
      path     : "transactions",
      name     : "Transactions",
      component: Transactions
    }, {
      path     : "transaction/:transactionId",
      name     : "Transaction",
      component: Transaction,
      props    : true
    },
    {
      path     : "logs",
      name     : "Logs",
      component: Logs
    }, {
      path     : "assets",
      name     : "Assets",
      component: Assets
    }, {
      path     : "assetEdit/:assetId",
      name     : "AssetEdit",
      component: AssetEdit,
      props    : true
    },
    {
      path     : "user",
      name     : "User",
      //component:
      // import UserProfile from "/src/App/Views/UserProfile/UserProfile.vue";
      // component: () => import( /* webpackChunkName: "userProfile" */ "/src/App/Views/UserProfile/UserProfile.vue"),
      component: () => import( "/src/App/Views/UserProfile/UserProfile.vue"),
    },

  ]
}

//------------------------------------------------------------[ ADMIN Pages ]---

let adminMenu = {

  path     : "/admin",
  name     : "adminMenu",
  redirect : "app/overview",
  meta     : {requiresAuth: true, requiresAdmin: true },
  component: AppLayout,
  children : [
    {
      path     : "users",
      name     : "Users",
      component: Users
    }, {
      path     : "tenantprofile",
      name     : "TenantProfile",
      component: TenantProfile
    }, {
      path     : "sites",
      name     : "Sites",
      component: Sites
    }, {
      path: "devices",
      name: "Devices",
      component: Devices
    }, {
      path: "integrations",
      name: "Integrations",
      component: Integrations
    }, {
      path     : "accountClass",
      name     : "AccountClass",
      component: AccountClass
    }, {
      path     : "templates",
      name     : "Templates",
      component: Templates
    },{
      path     : "interfaces",
      name     : "Interfaces",
      component: Interfaces
    },{
      path     : "paymentProviders",
      name     : "PaymentProviders",
      component: PaymentProviders
    }, {
      path     : "tenders",
      name     : "Tenders",
      component: Tenders
    }, {
      path     : "totals",
      name     : "Totals",
      component: Totals
    }, {
      path     : "locations",
      name     : "Locations",
      component: Locations
    }, {
      path     : "revenueCenters",
      name     : "RevenueCenters",
      component: RevenueCenters
    }, {
      path     : "table-list",
      name     : "Table List",
      component: TableList
    }, {
      path     : "typography",
      name     : "Typography",
      component: Typography
    }, {
      path     : "icons",
      name     : "Icons",
      component: Icons
    }, {
      path     : "testPage",
      name     : "TestPage",
      component: TestPage
    }, {
      path     : "notifications",
      name     : "Notifications",
      component: Notifications
    }, {
      path     : "categorySetup",
      name     : "categorySetup",
      component: CategorySetup
    }, {
      path     : "certificationsSetup",
      name     : "certificationsSetup",
      component: CertificationsSetup
    }, {
      path     : "scheduleSetup",
      name     : "scheduleSetup",
      component: ScheduleSetup
    }, {
      path: "reports",
      name: "reports",
      component: Reports
    },{
      path: "workstationConfig",
      name: "workstationConfig",
      component: WorkstationConfig
    }, {
      path: "ticketConfig",
      name: "ticketConfig",
      component:TicketConfig
    },
    {
      path: "assetConfig",
      name: "assetConfig",
      component:AssetConfig
    },
    {
      path: "eventsConfig",
      name: "eventsConfig",
      component:EventsConfig
    },
    {
      path: "donationConfig",
      name: "donationConfig",
      component:DonationConfig
    },
    {
      path: "housingConfig",
      name: "housingConfig",
      component:HousingConfig
    },
    {
      path: "rotationConfig",
      name: "rotationConfig",
      // component:RotationConfig
      component: () => import( /* webpackChunkName: "rotationConfig" */ '/src/App/Views/Tenant/RotationConfig.vue')

    }
  ]
}

//--------------------------------------------------------------[ Reports Pages ]--

let reportMenu = {

  path     : "/reports",
  name     : "reportsMenu",
  meta     : {requiresAuth: true},
  component: AppLayout,
  children: [
    {
      path: "menu",
      name: "ReportMenu",
      component: ReportMenu
    },
    {
      path: "accountBalance/:reportFormatId?",
      name: "AccountBalance",
      component: () => import( /* webpackChunkName: "accountbalance" */ "/src/Reports/AccountBalance.vue"),
      props: true
    },
    {
      path: "transactionSummary/:reportFormatId?",
      name: "transactionSummary",
      component: () => import( /* webpackChunkName: "transactionSummary" */ "/src/Reports/TransactionSummary.vue"),
      props: true
    },
    {
      path: "testreport/:reportFormatId?",
      name: "TestReport",
      component: TestReport,
      props: true
    }
  ]
}


//--------------------------------------------------------------[ Kiosk Pages ]--

let kioskMenu = {

  path: "/kiosks",
  name: "kiosksMenu",
  meta: { requiresAuth: true },
  component: AppLayout,
  children: [
    {
      path: "menu",
      name: "KioskMenu",
      component: KioskMenu
    },
    {
      path: "newsManagement",
      name: "KioskNewsManagement",
      component: () => import( /* webpackChunkName: "news" */ '/src/App/Views/News/NewsManagement.vue'),
      props: true
    },
    {
      path: "device/:scheduleId?",
      name: "kioskDevice",
      component: () => import( /* webpackChunkName: "kiosk" */ '/src/App/Views/Kiosks/Device.vue'),
      props: true
    },
    //{
    //  path: "testreport/:reportFormatId?",
    //  name: "TestReport",
    //  component: TestReport,
    //  props: true
    //}
  ]
}

//--------------------------------------------------------------[ Donations Pages ]--

let donationsMenu = {

  path: "/donations",
  name: "donations",
  meta: { requiresAuth: true },
  component: AppLayout,
  children: [
    // {
    //   path: "menu",
    //   name: "DonationsMenu",
    //   component: DonationsMenu
    // },
    {
      path: "overview",
      name: "donationsOverview",
      component: () => import( /* webpackChunkName: "donations" */ '/src/App/Views/Donations/Overview.vue'),
      props: false
    },
    {
      path: "campaigns",
      name: "donationsCampaigns",
      component: () => import( /* webpackChunkName: "donations" */ '/src/App/Views/Donations/Campaigns.vue'),
      props: false
    },
    {
      path: "donors",
      name: "donationsDonors",
      component: () => import( /* webpackChunkName: "donations" */ '/src/App/Views/Donations/Donors.vue'),
      props: false
    },
    {
      path: "contributions",
      name: "donationsContributions",
      component: () => import( /* webpackChunkName: "donations" */ '/src/App/Views/Donations/Contributions.vue'),
      props: false
    }

  ]
}

//--------------------------------------------------------------[ Certification Pages ]--

let certificationsMenu = {

  path: "/certifications",
  name: "certifications",
  meta: { requiresAuth: true },
  component: AppLayout,
  children: [
    {
      path: "overview",
      name: "certificationsOverview",
      component: () => import( /* webpackChunkName: "certifications" */ '/src/App/Views/Certifications/Overview.vue'),
      props: false
    },
    {
      path: "certifications/:certificationId?",
      name: "certificationsCertifications",
      component: () => import( /* webpackChunkName: "certifications" */ '/src/App/Views/Certifications/Certifications.vue'),
      props: true
    },
    {
      path: "stats",
      name: "certificationsStats",
      component: () => import( /* webpackChunkName: "certifications" */ '/src/App/Views/Certifications/Stats.vue'),
      props: false
    },
    {
      path: "batchEdit",
      name: "certificationsBatchEdit",
      component: () => import( /* webpackChunkName: "certifications" */ '/src/App/Views/Certifications/BatchEdit.vue'),
      props: false
    },
    {
      path: "matrix",
      name: "certificationsmMatrix",
      component: () => import( /* webpackChunkName: "certifications" */ '/src/App/Views/Certifications/Matrix.vue'),
      props: false
    },


  ]
}


//--------------------------------------------------------------[ Functions Pages ]--

let functionsMenu = {

  path: "/functions",
  name: "functionsMenu",
  meta: {
    requiresAuth: true
  },
  component: AppLayout,
  children: [{
      path: "menu",
      name: "functionMenu",
      component: () => import( /* webpackChunkName: "functions" */ "/src/App/Views/Functions.vue" ) // ;FunctionMenu
    },
    {
     path: "menuItemMaster",
     name: "POSMenuItemMaster",
     component: () => import( /* webpackChunkName: "functions" */ '/src/App/Views/Functions/MenuItemMaster.vue'),
     props: true
    },
    {
     path: "orderCalendar",
     name: "POSOrderCalendar",
     component: () => import( /* webpackChunkName: "functions" */ '/src/App/Views/Functions/OrderCalendar.vue'),
     props: true
    },
    {
      path: "newsManagement",
      name: "POSNewsManagement",
      //  component: () => import( /* webpackChunkName: "functions" */ '/src/App/Views/Functions/NewsManagement.vue'),
      component: () => import( /* webpackChunkName: "news" */ '/src/App/Views/News/NewsManagement.vue'),
      props: true
    }
  ]
}

//--------------------------------------------------------------[ Housing Modules ]--

let housingMenu = {

  path: "/housing",
  name: "housingMenu",
  meta: { requiresAuth: true },
  component: AppLayout,
  children: [
    //{
    //  path: "menu",
    //  name: "HousingMenu",
    //  component: HousingMenu
    //},
    {
      path: "housing",
      name: "Housing",
      // component: Housing
      component: () => import( /* webpackChunkName: "housing" */ '/src/App/Views/Housing/Housing.vue')
    },
    {
      path: "lands",
      name: "Lands",
      // component: Lands
      component: () => import( /* webpackChunkName: "housing" */ '/src/App/Views/Housing/Lands.vue')
    },
    {
      path: "buildings",
      name: "Buildings",
      // component: Buildings
      component: () => import( /* webpackChunkName: "housing" */ '/src/App/Views/Housing/Buildings.vue')
    },
    {
      path: "contracts",
      name: "Contracts",
      // component: Contracts
      component: () => import( /* webpackChunkName: "housing" */ '/src/App/Views/Housing/Contracts.vue')
    },
    {
      path: "units/:buildingProp?",
      name: "Units",
      // component: Units,
      component: () => import( /* webpackChunkName: "housing" */ '/src/App/Views/Housing/Units.vue'),
      props:true
    }
  ]
}

//--------------------------------------------------------------[ Marketing Modules ]--

let marketingMenu = {

  path: "/marketing",
  name: "marketingMenu",
  meta: { requiresAuth: true },
  component: AppLayout,
  children: [
    //{
    //  path: "menu",
    //  name: "HousingMenu",
    //  component: HousingMenu
    //},
    {
      path: "marketing",
      name: "Marketing",
      component: Marketing
    },
    {
      path     : "availableUnits/:roomModel?",
      name     : "AvailableUnits",
      component: AvailableUnits,
      props    : true
    },
    {
      path     : "modelInformation/:roomModel?",
      name     : "ModelInformation",
      component: ModelInformation,
      props    : true
    }
  ]
}

//--------------------------------------------------------------[ Environmental Modules ]--

let environmentalMenu = {

  path: "/environmental",
  name: "environmentalMenu",
  meta: { requiresAuth: true },
  component: AppLayout,
  children: [
    //{
    //  path: "menu",
    //  name: "HousingMenu",
    //  component: HousingMenu
    //},
    {
      path: "overview",
      name: "EnvironmentalOverview",
      component: EnvironmentalOverview
    },
    {
      path: "temperatures",
      name: "EnvironmentalTemperatures",
      component: EnvironmentalTemperatures
    },
  ]
}



let portalMenu = {

  path     : "/portal",
  name     : "portalMainMenu",
  meta     : {requiresAuth: true},
  component: AppLayout,
  children : [
    {
      path     : "menu",
      name     : "portalMenu",
      component: PortalMenu
    },
    {
      path     : "pendingAccounts",
      name     : "pendingAccounts",
      component: PendingAccounts
    },
    {
      path     : "portalAuthorizations",
      name     : "portalAuthorizations",
      component: PortalAuthorizations
    },
    {
      path     : "portalAuthorizationsTeachers",
      name     : "portalAuthorizationsTeachers",
      component: PortalAuthorizationsTeachers
    },
    {
      path     : "menuItemMaster",
      name     : "menuItemMaster",
      component: MenuItemMaster
    },
    {
      path     : "orderCalendar",
      name     : "orderCalendar",
      component: OrderCalendar
    },
    {
      path     : "newsManagement",
      name     : "newsManagement",
      // component: NewsManagement
      component: () => import( /* webpackChunkName: "news" */ '/src/App/Views/News/NewsManagement.vue'),
    },
    {
      path     : "faqManagement",
      name     : "faqManagement",
      component: FaqManagement
    },
    {
      path     : "portalOrders",
      name     : "portalOrders",
      component: PortalOrders
    },
    {
      path: "portalOrdersSummary",
      name: "portalOrdersSummary",
      component: PortalOrdersSummary
    },
    {
      path: "portalUploadStudentFile",
      name: "portalUploadStudentFile",
      component: PortalUploadStudentFile
    }
  ]
}

let ticketMenu = {

  path     : "/case",
  name     : "ticketMainMenu",
  meta     : {requiresAuth: true},
  component: AppLayout,
  children : [
    {
      path     : "ticketMenu",
      name     : "ticketMenu",
      // component: TicketMenu,
      component: () => import( /* webpackChunkName: "tickets" */ '/src/App/Views/Projects/TicketMenu.vue'),
      props:false
    },
    {
      path     : "tickets/:propTicketType?/:propUser?",
      name     : "tickets",
      // component: Tickets,
      component: () => import( /* webpackChunkName: "tickets" */ '/src/App/Views/Projects/Tickets.vue'),
      props:true
    },
    {
      path     : "ticketWizard",
      name     : "ticketWizard",
      // component: TicketWizard,
      component: () => import( /* webpackChunkName: "tickets" */ '/src/App/Views/Projects/TicketWizard.vue'),
      props:false
    },
    {
      path: "ticketWizard/:ticketId?",
      name: "ticketEdit",
      // component: TicketEdit,
      component: () => import( /* webpackChunkName: "tickets" */ '/src/App/Views/Projects/TicketEdit.vue'),
      props: true
    },
    {
      path     : "ticketStatus",
      name     : "ticketStatus",
      // component: TicketStatus
      component: () => import( /* webpackChunkName: "tickets" */ '/src/App/Views/Projects/TicketStatus.vue')
    },
    {
      path     : "ticketSummary",
      name     : "ticketSummary",
      // component: TicketSummary
      component: () => import( /* webpackChunkName: "tickets" */ '/src/App/Views/Projects/TicketSummary.vue')
    }
  ]
}

let projectMenu = {

  path     : "/projects",
  name     : "projectsMainMenu",
  meta     : {requiresAuth: true},
  component: AppLayout,
  children : [
    {
      path     : "projectBudgets",
      name     : "projectBudgets",
      component: () => import( /* webpackChunkName: "projects" */ '/src/App/Views/Projects/ProjectBudgets.vue')
    },
    {
      path     : "projects",
      name     : "projects",
      component: () => import( /* webpackChunkName: "projects" */ '/src/App/Views/Projects/Projects.vue')
    }
  ]
}

let vendorMenu = {
  path     : "/vendors",
  name     : "vendorsMainMenu",
  meta     : {requiresAuth: true},
  component: AppLayout,
  children : [
    {
      path     : "vendors",
      name     : "vendors",
      component: Vendors
      // component: () => import( '/src/App/Views/Vendors/Vendors.vue')
    }
  ]
}

let cateringMenu = {
  path     : "/catering",
  name     : "cateringMainMenu",
  meta     : {requiresAuth: true},
  component: AppLayout,
  children : [
    {
      path     : "calendar",
      name     : "cateringCalendar",
      component: () => import( /* webpackChunkName: "catering" */ '/src/App/Views/Catering/Calendar.vue')
    },
    {
      path     : "invoices",
      name     : "cateringInvoices",
      component: () => import( /* webpackChunkName: "catering" */ '/src/App/Views/Catering/Invoices.vue')
    }
  ]
}

let eventsMenu = {
  path     : "/events",
  name     : "eventsMainMenu",
  meta     : {requiresAuth: true},
  component: AppLayout,
  children : [
    {
      path     : "overview",
      name     : "eventsOverview",
      component: () => import( /* webpackChunkName: "events" */ '/src/App/Views/Events/Overview.vue')
    },
    {
      path     : "events",
      name     : "events",
      component: () => import( /* webpackChunkName: "events" */ '/src/App/Views/Events/Events.vue')
    },
    {
      path     : "clubs",
      name     : "clubs",
      component: () => import( /* webpackChunkName: "clubs" */ '/src/App/Views/Events/Clubs.vue')
    },
    // {
    //   path     : "catering",
    //   name     : "catering",
    //   component: () => import( /* webpackChunkName: "catering" */ '/src/App/Views/Catering/Catering.vue')
    // },
    {
      path     : "attendance",
      name     : "eventAttendance",
      component: () => import( /* webpackChunkName: "attendance" */ '/src/App/Views/Events/Attendance.vue')
    }
  ]
}

let linksMenu = {
  path     : "/links",
  name     : "linksMainMenu",
  meta     : {requiresAuth: true},
  component: AppLayout,
  children : [
    {
      path     : "links",
      name     : "links",
      component: () => import( /* webpackChunkName: "links" */ '/src/App/Views/Links/Links.vue')
    }
  ]
}




let procedureMenu = {

  path: "/procedures",
  name: "proceduresMainMenu",
  meta: { requiresAuth: true },
  component: AppLayout,
  children: [
    {
      path: "procedures",
      name: "procedures",
      component: Procedures
    }
  ]
}



//   path: "workorders", name: "Workorders", component: Workorders
// }, {
//   path: "spamanagement", name: "SpaManagement", component: SpaManagement
// }, {
//   path: "transportation", name: "Transportation", component: Transportation
// }

let helpMenu = {

  path     : "/help",
  name     : "helpMenu",
  meta     : {
    requiresAuth: true
  },
  component: AppLayout,
  children : [
    {
      path     : "menu",
      name     : "HelpMenu",
      component: HelpMenu
    }
  ]
}

//--------------------------------------------------------[ Assemble the Routes ]--

const routes = [
  homePage,
  loginPage,
  tosPage,
  privacyPage,
  registerPage,
  forgotPasswordPage,
  forgotPasswordResetCodePage,
  appMenu,
  reportMenu,
  portalMenu,
  housingMenu,
  marketingMenu,
  environmentalMenu,
  kioskMenu,
  functionsMenu,
  certificationsMenu,
  donationsMenu,
  projectMenu,
  procedureMenu,
  vendorMenu,
  cateringMenu,
  eventsMenu,
  linksMenu,
  ticketMenu,
  adminMenu,
  helpMenu,
  {path: "*", component: NotFound}
];

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
 function view(name) {
   var res= require('../components/App/Views/' + name + '.vue');
   return res;
};**/

export default routes;
