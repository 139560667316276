///-------------------------------------------------------------------------------------------------
// summary:	Room service class which includes buildings and lands
///-------------------------------------------------------------------------------------------------

import Api from '@/Services/Api';

export default {

  getRooms(building) {
    const response = Api().get(`/api/1/rooms/${building}`);
    return response;
  },
  getRoomsByModel(model, availableOnly = true) {
    const response = Api().get(`/api/1/roomsByModel/${model}`,  {params: { availableOnly: availableOnly }});
    return response;
  },
  addRoom(room) {
    const response = Api().post('/api/1/room', room );
    return response;
  },
  getRoom(id) {
    const response = Api().get(`/api/1/room/${id}`);
    return response;
  },
  saveRoom(room) {
    const response = Api().patch('/api/1/room', room);
    return response;
  },
  deleteRoom(id) {
    const response = Api().delete(`/api/1/room/${id}`);
    return response;
  },

  getBuildings() {
    const response = Api().get('/api/1/buildings');
    return response;
  },

  getBuildingsForPolicy( policyId ) {
    const response = Api().get('/api/1/buildings' ,{params: { insurancePolicy: policyId }});
    return response;
  },

  getMultiTenantBuildings() {
    const response = Api().get('/api/1/buildings/multi');
    return response;
  },
  getBuildingStats() {
    const response = Api().get('/api/1/buildingStats');
    return response;
  },
  getBuildingOccupancyStats(building="" , unitTypes ) {
    const response = Api().get(`/api/1/buildingOccupancyStats/${building}`, {params:{housingtype: unitTypes }});      // leave building blank for ALL registered "multiTenant" buildings
    return response;
  },
  getHousingTypes() {
    const response = Api().get('/api/1/housingTypes');
    return response;
  },

  getOwnerTypes() {
    const response = Api().get('/api/1/ownerTypes');
    return response;
  },

  liveSearch( searchString = "a", housingType = null, limit = 5) {
    const response = Api().get(`/api/1/roomSearchByRoomId/${encodeURIComponent(searchString)}`, { params: { limit: limit , housingType: housingType }});
    return response;
  },

}
