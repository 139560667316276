///-------------------------------------------------------------------------------------------------
// summary:	Kiosk service class
///-------------------------------------------------------------------------------------------------

import Api from '@/Services/Api';


export default {

  getRotations() {
    const response = Api().get('/api/1/rotations');
    return response;
  },

  getRotation( rotationId ) {
    const response = Api().get(`/api/1/rotation/${rotationId}`);
    return response;
  },

  saveRotation( rotation ) {
    const response = Api().patch('/api/1/rotation', rotation );
    return response;
  },

  addRotation( rotation ) {
    const response = Api().post('/api/1/rotation', rotation );
    return response;
  },

  deleteRotation( rotationId ) {
    const response = Api().delete(`/api/1/rotation/${rotationId}`);
    return response;
  }

}

