<template>
    <div v-if="ticketConfig" >

      <div>
        <b-button v-if="!isChanged" class="btn btn-sm btn-fill btn-success" style="float:left;margin-right:1em;" @click="done()">Done</b-button>
        <b-button v-if="isChanged" class="btn btn-sm btn-fill btn-success" style="float:left;margin-right:1em;" @click="save()">Save</b-button>
        <b-button v-if="isChanged" class="btn btn-sm btn-fill btn-warning" style="float:left;margin-right:1em;" @click="cancel()">Cancel</b-button>
        <h4 slot="header" class="card-title">
          {{ticketConfig.title}} Configuration
        </h4>
      </div>
      <hr>

      <b-form @input="dataChanged()">
        <sf-input v-model="ticketConfig.title" :labelCols="2" label="Help Desk Title" helpText="What you call your help desk solution" />
      </b-form>

      <b-tabs id="ticketConfig" lazy class="tab-hover" content-class="mt-3 ml-3 mr-3" active-nav-item-class="selected-tab mb-2" >

        <!----------------------------------------------------------------------------[ Ticket Types ]-->

        <b-tab title="Ticket Types" style="padding: .05em .5em .5em 0; background:gray;border-radius:1em;">

          <div style="margin: 0 1em 0 1.5em;">

<!--            <h5 class="mt-2">Ticket Types</h5>-->
<!--            <hr>-->
            <i v-if="ticketConfig.ticketType && ticketConfig.ticketType.length === 0" class="fa fa-plus-square show-hand "  v-b-popover.hover.top="'Add New Ticket Type'" @click="addNewTicketType()"></i>
            <b-tabs id="ticketTypes"  lazy vertical class="tab-hover-left" style="margin-top:.5em;background:#fff;padding:3em 1em;border-radius:.5em;" active-nav-item-class="selected-tab-left">
              <b-tab :title="ticket.title" v-for="(ticket,index) in ticketConfig.ticketType" :key="index">
              <div>
                <h4 style="position:absolute;top:-100px;border-radius:.5em;border:.25em gray solid;background:lightgray;margin-left:1em;padding:.25em 2em .25em 2em;">{{ticket.title}}</h4>
                <div class="float-right" style="margin-top:1em;font-size:1.4em;">
                  <i class="fa fa-trash show-hand mr-2" v-b-popover.hover.top="'Remove this ticket type'" @click="removeTicketType(index)"></i>
                  <i class="fa fa-plus-square show-hand "   v-b-popover.hover.top="'Add New Ticket Type'" @click="addNewTicketType()"></i>
                </div>

                <div class="row">
                  <div class="col-md-12 col-sm-12" style="min-height:500px;border-left:4px solid gray;">
                    <b-tabs  v-model="activeSubTab" class="tab-hover-normal" content-class="mt-3 ml-3 mr-3" active-nav-item-class="selected-tab" >
                      <b-tab title="General">
                        <h4>General Information</h4>
                        <b-form @input="dataChanged()">
                          <sf-input v-model="ticket.title" label="Title" />
                          <sf-input v-model.trim="ticket.key" :disabled="ticket.title.length>0" helpText="Database key; careful changing after tickets have been created" type="text" cstyle="width:12em;" label="Key" />
                          <sf-input v-model="ticket.sequence" type="number" cstyle="width:12em;"  @change="sortTicketTypes()" label="Sequence" />
                          <sf-input v-model="ticket.defaultServiceType" cstyle="width:12em;" type="list" :options="ticket.serviceTypes" label="Default Service Type" />
                          <sf-input v-model="ticket.hourlyRate" cstyle="width:12em;" type="comma" isCurrency  label="Default Hourly Rate" />
                        </b-form>
                      </b-tab>
                      <b-tab title="Client Types">
                        <div>
                          <h4 class="mb-0">Client Account Types</h4>
                          <p style="font-size:.9em;color:gray;">This page controls which CLIENTS/ACCOUNTS a ticket can be connected with.  This would be whom is receiving the service.</p>
                          <b-form @input="dataChanged()">
                            <sf-input v-model="ticket.accountType" type="list" :id="ticket.ticketType+index.toString()" cstyle="width:12em;" :options="accountTypes" label="Primary Type" />
                            <hr>
                            <div style="margin-left:2em;">
                              <h5 class="mt-3 mb-2" style="margin-left:-2em;">Allowed Client Types</h5>
                              <b-form-checkbox-group style="line-height:.8em;" v-model="ticket.searchClasses">
                                <b-form-checkbox v-for="(ac,index) in accountTypes" class="col-md-3 col-sm-6" style="margin:0;padding:0;" :value="ac" :key="index">
                                  <span style="text-transform: capitalize;">{{ac}}</span></b-form-checkbox>
                              </b-form-checkbox-group>
                            </div>
                          </b-form>
                        </div>
                      </b-tab>

                      <b-tab title="Categories">
                        <b-row>
                          <b-col>
                            <h4>Service Types</h4>
                            <!-- <i class="nc-icon nc-simple-add show-hand float-left" @click="addSkill"></i>  -->
                            <div class="mb-3">
                              <b-button @click="addServiceType(ticket)" class="btn btn-sm btn-fill btn-success float-left mr-2">Add </b-button>
                              <sf-input  cstyle="width:12em;" :labelCols="0"  v-model="serviceType" @keyup.enter.native="addServiceType(ticket)"/>
                            </div>
                            <ol>
                              <li v-for="(c,index) in ticket.serviceTypes" :key="index" >
                                <i class="nc-icon nc-scissors show-hand float-left mr-2" @click="removeServiceType(ticket,c)"></i>
                                {{c}}
                              </li>
                            </ol>
                          </b-col>
                          <b-col>
                            <h4>Ticket Categories</h4>
                            <!-- <i class="nc-icon nc-simple-add show-hand float-left" @click="addSkill"></i>  -->
                            <div class="mb-3">
                              <b-button @click="addCategory(ticket)" class="btn btn-sm btn-fill btn-success float-left mr-2">Add </b-button>
                              <sf-input  cstyle="width:12em;" :labelCols="0"  v-model="category" @keyup.enter.native="addCategory(ticket)"/>
                            </div>
                            <ol>
                              <li v-for="(c,index) in ticket.categories" :key="index" >
                                <i class="nc-icon nc-scissors show-hand float-left mr-2" @click="removeCategory(ticket,c)"></i>
                                {{c}}
                              </li>
                            </ol>
                          </b-col>
                          <b-col>
                            <h4>Task Categories</h4>
                          <!-- <i class="nc-icon nc-simple-add show-hand float-left" @click="addSkill"></i>  -->
                            <div class="mb-3">
                              <b-button @click="addTaskCategory(ticket)" class="btn btn-sm btn-fill btn-success float-left mr-2">Add </b-button>
                              <sf-input  cstyle="width:12em;" :labelCols="0"  v-model="taskCategory" @keyup.enter.native="addTaskCategory(ticket)"/>
                            </div>
                            <ol>
                              <li v-for="(c,index) in ticket.taskCategories" :key="index" >
                                <i class="nc-icon nc-scissors show-hand float-left mr-2" @click="removeTaskCategory(ticket,c)"></i>
                                {{c}}
                              </li>
                            </ol>
                          </b-col>
                        </b-row>
                      </b-tab>

                      <b-tab title="Address">
                        <h4>Ticket Address Information</h4>
                        <b-form style="margin-top:1em;" @input="dataChanged()">
                          <sf-input type="text" label="Main Title" v-model="ticket.address.line1"></sf-input>
                          <sf-input type="text" label="Line 2" v-model="ticket.address.line2"></sf-input>
                          <sf-input type="text" label="Line 3" v-model="ticket.address.line3"></sf-input>
                          <sf-input type="text" label="Line 4" v-model="ticket.address.line4"></sf-input>
                          <sf-input type="text" label="Line 5" v-model="ticket.address.line5"></sf-input>
                          <sf-input type="text" label="Line 6" v-model="ticket.address.line6"></sf-input>
                        </b-form>
                      </b-tab>

                      <b-tab title="Assignable Job Codes">
                        <div>
                          <h4 class="mb-0">Allowed Job Codes</h4>
                          <p style="font-size:.9em;color:gray;">This page controls which group of EMPLOYEES can be assigned to do the work.</p>
                            <div style="margin-left:2em;" class="row" >
                              <b-form-checkbox-group  style="margin-left:0;" v-model="ticket.authorizedJobCodes" @change="dataChanged()">
                                <template v-for="(jc,index) in jobCodes">
                                  <b-form-checkbox class="col-sm-12 col-md-12 col-lg-6 col-xl-3" v-if="jc.key"  :value="jc.key" :key="index">
                                    <span style="text-transform: capitalize;">{{jc.value}}</span>
                                  </b-form-checkbox>
                                </template>
                              </b-form-checkbox-group>
                            </div>
                        </div>
                      </b-tab>

                      <b-tab v-if="ticket.options" title="Options">
                        <b-row style="width:100%;margin:0;padding:0;">
                          <b-col class="col-6">
                            <h4 style="margin-top:0;">Ticket Options</h4>
                            <b-form style="margin-left:2em;line-height:.8em;width:80%;"  @input="dataChanged()">
                              <b-checkbox v-model="ticket.options.enableProjects">Enable Project Features</b-checkbox>
                              <b-checkbox v-model="ticket.options.enableFinancials">Enable Finacial Tab</b-checkbox>
                              <b-checkbox v-model="ticket.options.enableBudgets">Enable Budget Features</b-checkbox>
                              <b-checkbox v-model="ticket.options.enableChangeOrders">Enable Change Orders</b-checkbox>
                              <b-checkbox v-model="ticket.options.enableClientAssignment">Enable Client Assignment</b-checkbox>
                              <b-checkbox v-model="ticket.options.enableUnitAssignment">Enable Unit/Building Assignment</b-checkbox>
                              <b-checkbox v-model="ticket.options.enableAssetAssignment">Enable Asset Assignment</b-checkbox>
                              <b-checkbox v-model="ticket.options.enablePriority">Enable Priority</b-checkbox>
                              <b-checkbox v-model="ticket.options.enableServiceType">Enable Service Type</b-checkbox>
                              <b-checkbox v-model="ticket.options.enableComments">Enable Comments</b-checkbox>
                              <b-checkbox v-model="ticket.options.commentsOnMainScreen">Show Comments on GENERAL Tab</b-checkbox>
                              <b-checkbox v-model="ticket.options.enableParts">Enable Parts</b-checkbox>
                              <b-checkbox v-model="ticket.options.enableTasks">Enable Tasks</b-checkbox>
                              <b-checkbox v-model="ticket.options.enableBilling">Enable Billing</b-checkbox>
                              <b-checkbox v-model="ticket.options.enableFiles">Enable Files</b-checkbox>
                              <b-checkbox v-model="ticket.options.enableClone">Enable Clone</b-checkbox>
                              <b-checkbox v-model="ticket.options.enableClientNotify">Enable Client Notify Tab</b-checkbox>
                              <b-checkbox v-model="ticket.options.allowClose">Allow CLOSE Operation</b-checkbox>
                              <b-checkbox v-model="ticket.options.allowManualTransactions">Allow Manual Transactions</b-checkbox>
                              <hr>
                              <b-checkbox v-model="ticket.options.allowUnassignedTickets">Allow Unassigned Clients</b-checkbox>
                            </b-form>
                          </b-col>

                          <b-col class="col-6">
                            <h4 style="margin-top:0;">Wizard Options</h4>
                            <b-form style="margin-left:2em;line-height:.8em;width:80%;"  @input="dataChanged()">
                              <!-- <sf-input type="list" labelCols=4 label="Default Ticket Type" :options="ticketTypeSelections()" v-model="ticket.wizard.defaultTicketType"></sf-input> -->
                              <b-checkbox v-model="ticket.wizard.showExistingTickets">Show Existing Tickets</b-checkbox>
                              <b-checkbox v-model="ticket.wizard.allowUnassignedTickets">Allow Unassigned Clients</b-checkbox>
                              <hr>
                              <b-checkbox v-model="ticket.wizard.askGettingStarted">Ask Getting Started</b-checkbox>
                              <b-checkbox v-model="ticket.wizard.askCaseType">Ask Case Type</b-checkbox>
                              <b-checkbox v-model="ticket.wizard.askClientSelection">Ask Client Selection</b-checkbox>
                              <b-checkbox v-model="ticket.wizard.askRoomSelection">Ask Room Selection</b-checkbox>
                              <b-checkbox v-model="ticket.wizard.askClientDetails">Ask Client Details</b-checkbox>
                              <b-checkbox v-model="ticket.wizard.askNotifyDetails">Ask Notification Details</b-checkbox>
                              <b-checkbox v-model="ticket.wizard.askCaseDetails">Ask Case Details</b-checkbox>
                              <b-checkbox v-model="ticket.wizard.askUrgency">Ask Urgency</b-checkbox>
                              <b-checkbox v-model="ticket.wizard.askAssignment">Ask Assignment</b-checkbox>
                            </b-form>
                          </b-col>
                        </b-row>
                      </b-tab>
                      <b-tab title="Posting">
                        <b-row>
                          <b-col class="col-md-12 col-lg-6 col-xl-4">
                            <h4>Auto Posting </h4>
                            <b-form style="margin-top:1em;" @input="dataChanged()">
                              <sf-input type="text" label="Total Class Id" cstyle="width:5em;" v-model="ticket.posting.totalClassId"></sf-input>
                              <sf-input type="number" label="Revenue Center" cstyle="width:5em;" v-model="ticket.posting.revenueCenter"></sf-input>
                              <sf-input type="number" label="Tender Number" cstyle="width:5em;" v-model="ticket.posting.tenderNumber"></sf-input>
                            </b-form>

                            <b-form style="margin-top:1em;" @input="dataChanged()">
                              <sf-input type="text" label="Reply-to Name" v-model="ticket.replyUser"></sf-input>
                              <sf-input type="text" label="Reply-to Email" v-model="ticket.replyEmail"></sf-input>
                              <sf-input type="text" label="Reply-to Phone" v-model="ticket.replyPhone"></sf-input>
                              <!--                          <sf-input type="text" label="Reply-to Public" v-model="ticket.replyPublic"></sf-input>-->
                            </b-form>
                          </b-col>
                          <b-col>
                            <h4>Manual Posting </h4>
                            <div @input="dataChanged()" >
                              <div style="margin-left:2em;">
                                <h4>Account Profiles</h4>
                                <b-form-checkbox-group id="accountProfiles" name="accountProfiles" v-model="ticket.posting.allowedAccountClasses">
                                  <b-form-checkbox v-for="(t,index) in accountClasses" class="col-xs-6 col-sm-6 col-md-4 col-lg-3 col-xl-3" :id="'ap'+t.accountClassId" :value="t.accountClassId" :key="index">{{t.title}}</b-form-checkbox>
                                </b-form-checkbox-group>
                                <h4>Total Profiles</h4>
                                <b-form-checkbox-group id="totalProfiles" name="totalProfiles" v-model="ticket.posting.allowedTotalClasses">
                                  <b-form-checkbox v-for="(t,index) in totals" class="col-xs-6 col-sm-6 col-md-4 col-lg-3 col-xl-3" :id="'ttl'+t.totalClassId" :value="t.totalClassId" :key="index">{{t.title}}</b-form-checkbox>
                                </b-form-checkbox-group>
                                <h4>Tender Keys</h4>
                                <b-form-checkbox-group id="tenderKeys" name="tenderKeys" v-model="ticket.posting.allowedTenderNumbers">
                                  <b-form-checkbox v-for="(t,index) in tenders" class="col-xs-6 col-sm-6 col-md-4 col-lg-3 col-xl-3" :id="'tnd'+t.tenderNumber" :value="t.tenderNumber" :key="index">{{t.tenderNumber}}:{{t.title}}</b-form-checkbox>
                                </b-form-checkbox-group>
                                <h4>Revenue Centers</h4>
                                <b-form-checkbox-group id="revenueCenter" name="revenueCenter" v-model="ticket.posting.allowedRevenueCenters">
                                  <b-form-checkbox v-for="(r,index) in revenueCenters" class="col-xs-6 col-sm-6 col-md-4 col-lg-3 col-xl-3" :id="'rev'+r.revenueCenterId" :value="r.revenueCenterId" :key="index">{{r.title}}</b-form-checkbox>
                                </b-form-checkbox-group>
                              </div>
                            </div>
                          </b-col>
                        </b-row>

                      </b-tab>
                    </b-tabs>
                  </div>
                </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </b-tab>

        <!-- <b-tab title="Wizard">
          <h4></h4>
          <b-form style="margin-top:1em;" @input="dataChanged()">
            <sf-input type="list" labelCols=4 label="Default Ticket Type" :options="ticketTypeSelections()" v-model="ticket.wizard.defaultTicketType"></sf-input>
          </b-form>

        </b-tab> -->

        <b-tab title="Projects">
          <div style="margin-left:2em;">
           <b-row>
            <b-col>
              <div v-if="ticketConfig">
                <simple-list-only
                  @change="dataChanged()"
                  title="Budget Categories"
                  :list="ticketConfig.project.categories || []"
                />
              </div>
            </b-col>
            <b-col>
              <div v-if="ticketConfig">
                <simple-list-only
                  @change="dataChanged()"
                  title="Project Status"
                  :list="ticketConfig.project.statusList || []"
                />
              </div>
            </b-col>
            <b-col>
              <div v-if="ticketConfig">
                <simple-list-only
                  @change="dataChanged()"
                  title="Project Priorities"
                  :list="ticketConfig.project.priorities || []"
                />
              </div>
            </b-col>
          </b-row>
          </div>
        </b-tab>


        <!----------------------------------------------------------------------------[ Status Colors ]-->
<!--        <b-tab title="Status Colors">-->
<!--          <h4>Colors are preset</h4>-->
<!--          <div class="col-12 mt-4 ml-0" style="padding:1em;background:#eee;border-radius:1em;">-->
<!--            <b-form @change="dataChanged()">-->
<!--              <div v-for="(e,index) in 10" :key="index">-->
<!--                <sf-input v-model="ticketConfig.statusStyle[e-1].backgroundColor" type="text" ></sf-input>-->
<!--              </div>-->
<!--            </b-form>-->
<!--          </div>-->
<!--        </b-tab>-->

        <!----------------------------------------------------------------------------[ Priority Colors ]-->
<!--        <b-tab title="Priority Colors">-->
<!--          <h4>Colors are preset</h4>-->
<!--        </b-tab>-->

      </b-tabs>


    </div>
</template>

<script>
  import AccountService from "/src/Services/AccountService";
  import TenantService  from "/src/Services/TenantService";
  import QueryService   from "/src/Services/QueryService";
  import TotalService   from "/src/Services/TotalService";
  import Moment         from "moment-timezone";
  import Card           from "/src/components/UIComponents/Cards/Card.vue";
  import SfInput        from "/src/components/UIComponents/Inputs/SFormInput.vue";
  import LTable         from "/src/components/UIComponents/Table.vue";
  import SimpleListOnly from "/src/components/UIComponents/Lists/SimpleListOnly.vue";
  import RevenueCenterService from "../../../Services/RevenueCenterService";
  import TenderService from "../../../Services/TenderService";

  export default {
  components: {
    LTable,
    Card,
    SfInput,
    SimpleListOnly
  },
  data() {
    return {
      saveDisabled  : true,
      isChanged     : false,
      isAdding      : false,
      isEditing     : false,
      small         : true,
      accountClasses: [],
      tenders       : [],
      revenueCenters: [],
      totals        : [],
      activeSubTab  : 0,
      serviceType   : "",
      category      : "",
      taskCategory  : "",
      projectPriority: "",
      ticketType    : {key: "", title: "", description: ""},
      columns     : [
        "actions",
        { key: "key", sortable: true },
        { key: "title", sortable: true },
        { key: "description", sortable: true }
      ],
      ticketConfig: null,
      jobCodes: []
    };
  },
  watch: {
  },
   mounted: async function() {
    await Promise.all([
        this.getTicketConfig(),
        this.getAccountTypes(),
        this.getJobCodes(),
        this.getSupportRecords()
      ]);
  },
  async beforeRouteLeave( to , from, next ) {
    if( !this.isChanged ) {
      return next();
    }
    if( await this.$root.confirm('You have unsaved changes, continue leaving without saving?', "warning")) {
      return next();
    }
    next(false)
  },
  methods: {
    done() {
      this.$router.go(-1);
    },
    async save() {
      await this.saveTicketConfig();
      this.done();
    },
    async cancel() {
      if( this.isChanged ) {
        if( ! await this.$root.confirm( "Data changed -- You sure?" )) {
          return;
        }
      }
      this.$router.go(-1);
    },
    moment(d) {
      return Moment(d);
    },
    addServiceType( ticket ) {
      var t = this;
      if( t.serviceType.length == 0 ) {   // gotta have one - blank not allowed
        return;
      }
      if (ticket.serviceTypes.indexOf(t.serviceType) < 0) {
        ticket.serviceTypes.push(t.serviceType.toLowerCase().replace(" " , "-").replace("/","-"));
        ticket.serviceTypes = ticket.serviceTypes.sort();
        this.dataChanged();
      }
      t.serviceType = "";
    },
    removeServiceType(ticket,c) {
      var i = ticket.serviceTypes.indexOf(c);
      if (i >= 0) {
        this.serviceType = c;
        ticket.serviceTypes.splice(i, 1);
        this.dataChanged();
      }
    },

    addCategory( ticket ) {
      var t = this;
      // if (t.category.length > 0) {
        if (ticket.categories.indexOf(t.category) < 0) {
          ticket.categories.push(t.category);
          ticket.categories = ticket.categories.sort();
          this.dataChanged();
        }
      // }
      t.category = "";
    },
    removeCategory(ticket,c) {
      var i = ticket.categories.indexOf(c);
      if (i >= 0) {
        this.category=c;
        ticket.categories.splice(i, 1);
        this.dataChanged();
      }
    },

    addProjectPriority( ticket ) {
      var t = this;
      if (ticket.projectPriorities.indexOf(t.projectPriority) < 0) {
        ticket.projectPriorities.push(t.projectPriority);
        ticket.projectPriorities = ticket.projectPriorities.sort();
        this.dataChanged();
      }
      t.pojectPriority = "";
    },
    removeProjectPriority(ticket,c) {
      var i = ticket.projectPriorities.indexOf(c);
      if (i >= 0) {
        this.projectPriority=c;
        ticket.projectPriorities.splice(i, 1);
        this.dataChanged();
      }
    },

    addTaskCategory( ticket ) {
      var t = this;
      // if (t.taskCategory.length > 0) {
        if (ticket.taskCategories.indexOf(t.taskCategory) < 0) {
          ticket.taskCategories.push(t.taskCategory);
          ticket.taskCategories = ticket.taskCategories.sort();
          this.dataChanged();
        }
      // }
      t.taskCategory = "";
    },
    removeTaskCategory(ticket,c) {
      var i = ticket.taskCategories.indexOf(c);
      if (i >= 0) {
        this.taskCategory=c;
        ticket.taskCategories.splice(i, 1);
        this.dataChanged();
      }
    },
    dataChanged() {
      this.isChanged = true;
    },
    async addNewTicketType() {
      if( ! await this.$root.confirm( "Add New Ticket Type" )) {
        return;
      }
      this.ticketConfig.ticketType.push( {
        title: "new" ,
        key: "new" ,
        categories: [],
        address: { line1:"", line2:"", line3:"", line4:"", line5:"", line6:"" },
        posting: {
          totalClassId:0,
          revenueCenter:0,
          tenderNumber:0
        }

      });
      this.dataChanged();
    },
    ticketTypeSelections() {
      return this.ticketConfig.ticketType.map((t) => {
        return { text: t.title, value: t.key };
      })
    },
    async removeTicketType( index ) {
      if( ! await this.$root.confirm( "Remove " + this.ticketConfig.ticketType[ index ].title + "?" )) {
        return;
      }
      this.dataChanged();
      this.ticketConfig.ticketType.splice(index,1);
    },
    showModal () {
      this.$refs.myModalRef.show()
    },
    hideModal () {
      this.$refs.myModalRef.hide()
    },
    async getAccountTypes() {
      try {
        let response = await AccountService.getAccountTypes();
        this.accountTypes = response.data;
      } catch (error) {
        console.log( error )
      }
    },
    sortTicketTypes() {
      this.ticketConfig.ticketType = _.sortBy( this.ticketConfig.ticketType, (a) => parseInt( a.sequence));
    },
    async getTicketConfig() {
      var t = this;
      var response;
      try {
        response = await TenantService.getTicketConfig( );
        this.ticketConfig = response.data;

        this.sortTicketTypes();

      } catch (err) {
        console.log("getTicketConfig error: ", err);
        if (err.response) {
          if (err.response.data) {
            t.$root.errorMessage("Oops", err.response.data.message);
          }
        } else {
          t.$root.errorMessage("Oops", "Had an error getting the ticket configuration");
        }
      }
    },
    async getSupportRecords () {
      let t = this;
      let response;
      try {
        response            = await TenderService.getTenders();
        this.tenders        = response.data;

        response            = await RevenueCenterService.getRevenueCenters();
        this.revenueCenters = response.data;

        response    = await TotalService.getTotals();
        this.totals = response.data;

        response            = await TenantService.getAccountClasses();
        this.accountClasses = response.data;

      } catch( err ) {
        console.log( "getSupportTotals error: ", err );
        if( err.response ) {
          if( err.response.data ) {
            t.$root.errorMessage( "Oops", err.response.data.message );
          }
        } else {
          t.$root.errorMessage( "Oops", "Had an error getting the support totals" );
        }
      }
    },
    async getJobCodes() {
      var t = this;
      var response;

      if( this.jobCodes.length > 0 ) {
        return;
      }

      try {
        response = await QueryService.getJobCodes('');

        if( response.data ) {
          this.jobCodes = response.data.map( jc => { return {key:jc.jobCode, value: jc.jobCode + " (" + jc.count.toString() + ")" }} );
        } else {
          this.jobCodes = [];
        }

      } catch (err) {
        console.log("getJobCodes error: ", err);
        if (err.response) {
          if (err.response.data) {
            t.$root.errorMessage("Oops", err.response.data.message);
          }
        } else {
          t.$root.errorMessage("Oops", "Had an error getting the Job Codes");
        }
      }
    },
    async saveTicketConfig() {
      var t = this;
      var response;
      try {

        this.sortTicketTypes();
        response = await TenantService.saveTicketConfig(this.ticketConfig);
        sessionStorage.setItem('ticketConfig' , JSON.stringify(response.data));
        t.isChanged = false;
        t.$root.successMessage("SUCCESS", "Ticket Config Updated");


      } catch (err) {
        if (err.response) {
          console.log("error response ", err.response);
          t.$root.errorMessage("Oops", err.response.data.message);
        } else if (err.request) {
          console.log("Error Request ", err.request);
        } else {
          console.log("message only ", err.message);
        }
        t.$root.errorMessage("Oops", "Unable to Save Ticket Configuration Data");
      }
    }
  }

};
</script>

<style lang="scss" scoped>



</style>
