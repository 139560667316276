<template>
  <nav class="navbar navbar-expand-lg">
    <div class="container-fluid">

      <button type="button" class="navbar-toggler navbar-toggler-left" :class="{toggled: $sidebar.showSidebar}" aria-controls="navigation-index" aria-expanded="false" aria-label="Toggle navigation" @click="toggleSidebar">
        <span class="navbar-toggler-bar burger-lines"></span>
        <span class="navbar-toggler-bar burger-lines"></span>
        <span class="navbar-toggler-bar burger-lines"></span>
      </button>

<!--      <router-link class="navbar-brand" :to="{path:'/app'}">{{$root.tenant.tenantName}}</router-link>-->

      <router-link class="navbar-brand"  to="">
        <div style="display:inline-flex;">

          <!-- this is the tenant loog -->
          <img style="border:1px;margin-right:1em;" height=64 :src="$root.user.logo" />

          <!-- <b-dropdown  class="clear-fix"  :text="$root.tenant.tenantName">
            <template v-for="(tenant,index) in $root.tenantList">
              <b-dropdown-item  :id="'button'+index" @click="changeTenant(tenant.domain)" :key="index">{{tenant.tenantName}}</b-dropdown-item>
            </template>
          </b-dropdown> -->
          <!-- <h3 style="padding:0;margin:0 0 0 1em;vertical-align:top;">{{ $root.tenant.tenantName }}</h3> -->

          <b-dropdown id="dropdown-1"  size="lg" no-caret toggle-class="text-decoration-none" style="margin:0;padding:0;vertical-align:top;">
            <!-- &#x1f50d; magnifyer icon -->
            <template v-slot:button-content>
              <h3 class="navbar-tenant-title">{{ $root.tenant.tenantName }}</h3>
            </template>
            <template v-for="(tenant,index) in $root.tenantList">
              <b-dropdown-item
                :id="'button'+index"
                @click="changeTenant(tenant.domain)"
                :key="index">
                  {{tenant.tenantName}}
              </b-dropdown-item>
            </template>
          </b-dropdown>

         <!-- <span class="page-title" >{{$root.tenant.tenantName}}</span> -->
       </div>
      </router-link>

      <div class="collapse navbar-collapse justify-content-end">

        <!--        <ul class="nav navbar-nav mr-auto">-->
        <!--           <li>&nbsp;&nbsp;&nbsp;|</li>-->
        <!--          -->
        <!--           <li class="nav-item">-->
        <!--             <a href="javascript:alert('Not Yet Implemented')" class="nav-link">-->
        <!--               <i class="nc-icon nc-zoom-split"></i>-->
        <!--               <span class="d-lg-block">&nbsp;Search</span>-->
        <!--             </a>-->
        <!--           </li>-->
        <!--          <drop-down tag="li">-->
        <!--            <template slot="title">-->
        <!--              <i class="nc-icon nc-planet"></i>-->
        <!--              <b class="caret"></b>-->
        <!--              <span class="notification" style="left:-2px;">12</span>-->
        <!--            </template>-->
        <!--            <a class="dropdown-item" href="/">Notification 1</a>-->
        <!--            <a class="dropdown-item" href="/">Notification 2</a>-->
        <!--            <a class="dropdown-item" href="/">Notification 3</a>-->
        <!--            <a class="dropdown-item" href="/">Notification 4</a>-->
        <!--            <a class="dropdown-item" href="/">Another notification</a>-->
        <!--          </drop-down>-->
        <!--        </ul>-->


        <ul class="navbar-nav ml-auto">

          <li class="nav-item" v-if="$root.user.isAdmin">
            <drop-down title="Admin" >
              <div  style="width:500px;position:relative;left:-100px;top:15px;padding:.5em;border-radius: 1em;background:#ddd;">
              <b-row>
                <b-col>
                  <router-link class="dropdown-item" to="/admin/users">Users</router-link>
                  <!-- <router-link class="dropdown-item" to="/app/jobSchedules">Jobs & Schedules</router-link> -->
                  <router-link class="dropdown-item" to="/app/logs">Logs</router-link>
                  <div class="divider"></div>
                  <router-link class="dropdown-item" to="/admin/tenantProfile">Tenant Overhead</router-link>
                  <div class="divider"></div>

                  <router-link class="dropdown-item" to="/admin/accountClass">Account Profiles</router-link>
                  <router-link v-if="$root.isFeature('assets')" class="dropdown-item" to="/admin/assetConfig">Asset Setup</router-link>

                  <router-link v-if="$root.isFeature('certifications')" class="dropdown-item" to="/admin/certificationsSetup">Certifications Setup</router-link>
                  <router-link v-if="$root.isFeature('displaydog')" class="dropdown-item" to="/admin/devices">Device Setup</router-link>
                  <router-link v-if="$root.isFeature('donations')" class="dropdown-item" to="/admin/donationConfig">Donations Setup</router-link>
                  <router-link v-if="$root.isFeature('events') || $root.isFeature('catering')" class="dropdown-item" to="/admin/eventsConfig">Events & Catering</router-link>
                  <router-link v-if="$root.isFeature('housing')" class="dropdown-item" to="/admin/housingConfig">Housing Setup</router-link>
                  <router-link class="dropdown-item" to="/admin/integrations">Integrations</router-link>
                  <router-link class="dropdown-item" to="/admin/categorySetup">Item Category Setup</router-link>
                  <router-link class="dropdown-item" to="/admin/paymentProviders">Payment Providers</router-link>
                  <router-link class="dropdown-item" to="/admin/interfaces">Posting Interfaces</router-link>
                  <router-link class="dropdown-item" to="/admin/reports">Report Setup</router-link>
              </b-col>
              <b-col>
                <br><br><br><br><br><br>
                <router-link class="dropdown-item" to="/admin/revenueCenters">Revenue Centers</router-link>
                <router-link class="dropdown-item" to="/admin/scheduleSetup">Schedules Setup</router-link>
                <router-link class="dropdown-item" to="/admin/sites">Sites</router-link>
                <router-link class="dropdown-item" to="/admin/templates" v-if="$root.isSA">Template Management</router-link>
                <router-link class="dropdown-item" to="/admin/tenders">Tendering Keys</router-link>
                <router-link v-if="$root.isFeature('tickets')" class="dropdown-item" to="/admin/ticketConfig">Ticket Setup</router-link>
                <router-link class="dropdown-item" to="/admin/totals">Total Profiles</router-link>
                <router-link v-if="$root.isFeature('ipos')" class="dropdown-item" to="/admin/workstationConfig">Workstation Config</router-link>

              </b-col>
              </b-row>
              </div>
            </drop-down>
          </li>
          <li>
            <drop-down title="Settings">
              <router-link class="dropdown-item" to="/app/user">My Profile</router-link>
              <!-- Admin functions below -->
              <div v-if="$root.user.isSA">
                <div class="divider"></div>
                <router-link class="dropdown-item" to="/admin/testPage">Test Page</router-link>
                <router-link class="dropdown-item" to="/admin/icons">Icons</router-link>
                <router-link class="dropdown-item" to="/admin/notifications">Notifications</router-link>
              </div>
            </drop-down>
          </li>

          <div class="show-hand"  @click="$router.push('/app/user')">
            <b-avatar variant="default"  :src="($root.user.pictureUrl>'') ? $root.user.pictureUrl : myGravatar($root.user.email)" />
          </div>
          <li class="nav-item" >
            <a class="nav-link"  @click="logOut">{{'Log Out ' + $root.user.alias}}</a>
          </li>

          <li class="nav-item">
            <i class="fa fa-bell" :class="announcement ? 'active-announcement' : 'inactive-announcement'" v-b-popover.hover.left="announcement" :title="announcementTitle" @click="announcementClick"></i>
          </li>

        </ul>
      </div>
    </div>
  </nav>
</template>

<script>

  import AuthenticationService from "../Services/AuthenticationService";
  import gravatar    from "gravatar";
  export default {
    computed: {
      routeName() {
        const {
                name
              } = this.$route;
        return this.capitalizeFirstLetter(name);
      }
    },
    data() {
      return {
        user               : "user",
        activeNotifications: false,
        announcement       : "",
        announcementTitle  : null
      };
    },
    methods: {
      // wjs: Refactor this into a common routine since we use this code in several places.
      async changeTenant(domain) {
        console.log( "Change tenant to " + domain, this.$root.user.domain );
        if (domain === this.$root.user.domain) {
          return;
        }
        let lastEmail = localStorage.getItem("lastEmail");
        let block = JSON.parse(localStorage.getItem(lastEmail));
        if( !block) {
          return await this.$root.alert( `Cannot switch tenants for [${lastEmail}]` );
        }

        this.$root.alert( "Switching<br>One Moment Please..." , "info");

        let p = this.$route.path.split("/");

        if( p.length > 3 ) {
          return this.$root.alert("Please complete your edit before switching tenants");
        }

        if(  this.$route.path  != '/app/overview' ) {
          this.$router.push(`/app/overview`);
        }
        this.$root.logIn(block[domain]);
        location.reload();
      },
      announcementClick() {
        if( !this.announcement ) {
          return;
        }
        this.$emit( "announcement-click" , this.announcement );
      },
      myGravatar( email ) {
        return gravatar.url( email );
      },
      capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      },
      toggleNotificationDropDown() {
        this.activeNotifications = !this.activeNotifications;
      },
      closeDropDown() {
        this.activeNotifications = false;
      },
      toggleSidebar() {
        console.log( "TopNavbar.vue->toggleSidebar()");
        // console.table( this.$sidebar )
        this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
      },
      hideSidebar() {
        this.$sidebar.displaySidebar(false);
      },
      async logOut() {
        try {
          // await AuthenticationService.logout();    // todo: trap 401 and redirect
        } catch (e) {
        }
        this.$root.logOut();
      }
    }
  };

</script>
<style lang="scss" >

  .active-announcement {
    color:#FB404B;
    cursor:pointer;
  }

  .inactive-announcement {
    color:gray;
  }

  .navbar-tenant-title {
    // padding:0 1em 0 0 ;
    margin: 0 1em 0 1em;
    vertical-align:top;
  }
  .dropdown-toggle-no-caret {
    // font-size:3em;
    border        : none;
    outline       : none;
    box-shadow    : none;
    margin        : 0 1em 0 1em;
    padding       : 0;
    vertical-align: top;
    // button        : {
    //   border:none;
    //   outline:none;
    //   box-shadow:none;
    //   background: orange;
    // }
  }

  .page-title {
    margin-left:1em;
    font-size: 1.25em;
    vertical-align: top;
  }

</style>
