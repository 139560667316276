<template>
  <div class="container fluid">
    <h4>{{title}}</h4>
    <div>
      <div>Account Range</div>
      <div>
        <b-input v-model="range.begin"></b-input>
        <b-input v-model="range.end"></b-input>
      </div>
    </div>

    <div v-if="enableProfiles">
      <div>Account Profile</div>
      <b-form-checkbox-group v-model="range.accountProfiles"  :options="accountProfiles"></b-form-checkbox-group>
    </div>
    <div v-if="enableFilter">
      <div>Filter</div>
      <div>
        <b-input v-model="range.filter"></b-input>
      </div>
    </div>
    <div v-if="enableTags">
      <div>Tags</div>
      <div>
        <b-input v-model="range.filter"></b-input>
      </div>
    </div>
    <b-button v-if="enableCancel" class="btn btn-sm btn-fill" variant="warning" @click="cancelClick()">Cancel</b-button>
    <b-button class="btn btn-sm btn-fill" style="border-radius:.75em;" variant="info" @click="run()">Run Report</b-button>
  </div>
</template>
<script>
  export default {
    name   : 'drop-down',
    props  : {
      title          : {type: String, default: "Report Title"},
      icon           : {type: String},
      accountProfiles: {type: Array, default: () => []},
      enableCancel   : {type: Boolean, default: false},
      enableProfiles : {type: Boolean, default: false},
      enableFilter   : {type: Boolean, default: false},
      enableTags     : {type: Boolean, default: false}

    },
    data() {
      return {
        range: {
          begin          : "",
          end            : "",
          filter         : "",
          accountProfiles: [],
          tags           : []
        }
      }
    },
    methods: {
      run() {
        this.$emit("run", this.range);
      },
      cancelClick() {
        this.$emit("cancel");
      }
    }
  }
</script>
<style scoped>
</style>
