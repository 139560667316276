<template>
  <div class="content">
    <div class="container-fluid">
      <div class="col-12 w-100 ">
        <h3 class="card-title">Upload Student File<button class="float-left mr-3 btn btn-warning btn-sm btn-fill" @click="$router.push('/app');"><span v-show="loading" :class="isLoading"></span>Cancel</button></h3>
        <hr>
        <div v-if="!verifiedStudents" >
          <h4 v-b-toggle.help-text >Import File Help <i class="fa fa-angle-up"></i></h4>
          <b-collapse v-model="isShowHelp" id="help-text" class="collapsible show-hand">
            <p style="max-width:800px;font-weight:400;font-style:italic;padding-left:2em;padding-right:2em;">
              The ImportTag (field below) tag should match what you have been using for this calendar year.  While it is ok to change it, if you do change it,
              don't forget to update the tenant->portal configuration to tag the current entry. If you are doing a partial upload, you should look
              at the tenant configuration to ensure all importTags are properly selected.<br><br>
              The first line in the .csv file should have a "header record" with the fields shown in the example below.  You may cut/paste into your CSV if they are not there.
              NOTE: The field order is NOT important as long as these fields exist in that top row (regardless of the order). Of course,
              the <strong>DATA</strong> should line up with the <strong>HEADER</strong>.<br><br>
              <span style="color:red;">NOTE: If you do not see any data in a column after selecting the CSV file,
              then it is likely the header line in your CSV file is formatted wrong and you should cancel this operation, fix the CSV and then retry this operation again.</span><br><br>
              After you select a .csv file, do a quick glance to make sure the fields look sensible; e.g., fields in the right columns, etc.  Sometimes data can get
              confused and the field mappings get out of alignment. If you notice any issues, edit the CSV file and try this operation again.<br><br>
              If all looks good, provide an import tag and press the [Import File] button.  That will send the file to iCare and attempt to import -
              it is possible to receive errors if something goes wrong.
              <br><br>
              <span style="color:red;">Notice the DATE Format of <strong>MM/DD/YYYY</strong> - this <strong>MUST</strong> be the format inside your CSV</span>
            </p>

            <div style="">
              <pre style="width:100%;overflow:auto;">
                <code>
                  <h5>Example CSV File Format</h5>
                  idNumber,lastName,firstName,homeRoom,teacher,grade,dob
                  1201234,Scott,Wolfie,8A,8,08,02/22/2007
                                     :
                                     :
                </code>
              </pre>
            </div>
          </b-collapse>
        </div>
        <div v-if="!verifiedStudents" >

          <b-form-group horizontal breakpoint="lg" label="Student Import Information" label-size="lg" label-class="font-weight-bold pt-0" class="mb-0">
            <b-form-group horizontal label-cols-sm="2" label-cols-lg="2" label-class="text-sm-right" label="Import Tag" label-for="importTag">
              <b-form-input id="importTag" type="text"  style="width:12em;" v-model="importTag" required placeholder="ImportTag"></b-form-input>
              <div>Current Tags: <span v-for="(tag,index) in importTags" :key="index" class="show-hand" style="margin-right:1em;font-weight:800;text-decoration:underline;" @click="importTag=tag.importTag">[{{tag.importTag}}]</span></div>
            </b-form-group>
          </b-form-group>

          <form v-if="importTag.length>0" style="padding-left:5.5em;" enctype="multipart/form-data">
<!--            <b-checkbox v-model="isOverrideHeaderRecord" @change="stuffHeaderRecord()">Override Header Record</b-checkbox>-->
            <br>
            <input type="file" @change="onFileChange">
          </form>

          <div v-if="jsonData" style="margin-top:1em;height:670px;overflow:auto;border:1px solid gray;">
            <h4 style="margin:0;padding:.25em;">RAW CSV Input</h4>
            <b-table  small striped hover sticky-header="600px" :busy="loading" head-variant="dark" :fields="fields" :items="jsonData">
            </b-table>
          </div>
          <b-button v-if="jsonData" class="btn btn-sm btn-fill btn-warning" style="margin-top:2em;" :disabled="!okToImport()" @click="importStudentFile" ><span v-show="loading" :class="isLoading"></span>Submit File For Verification</b-button>
        </div>
        <div v-if="verifiedStudents">
          <h4  style="margin:0;padding:.25em;">Processed Student Information</h4>
          <div style="margin-top:1em;height:620px;overflow:auto;border:1px solid gray;">
            <b-table striped hover sticky-header="600px"  :busy="loading" head-variant="dark" :items="verifiedStudents">

              <template v-slot:cell(registration)="data">
                {{data.item.registration.isRegistered === true ? "Yes" : "No" }}
              </template>

            </b-table>
          </div>
          <b-button v-if="verifiedStudents" id="importButton" class="btn btn-sm btn-fill btn-success" style="margin-top:1em;margin-left:1em;"  @click="uploadStudentFile" ><span v-show="loading" :class="isLoading"></span>All Good, Submit For Import</b-button>
        </div>

      </div>
    </div>
  </div>
</template>
<script>

import PortalRegistrationService from "/src/Services/PortalRegistrationService";
import Moment                    from "moment";
import Card                      from "/src/components/UIComponents/Cards/Card.vue";

export default {
    components: {
      Card,
      Moment
    },
    data () {
      return {
        fields                : [
          {key: 'idNumber', label: "Student ID", sortable: true},
          {key: 'lastName', label: "Last Name", sortable: true},
          {key: 'firstName', label: "First Name", sortable: true},
          {key: 'homeRoom', label: "Home Room", sortable: true},
          {key: 'teacher', label: "Teacher", sortable: true},
          {key: 'grade', label: "grade", sortable: true},
          {key: 'dob', label: "DOB", sortable: true},
        ],
        importTag             : "",
        importTags            : null,
        importFile            : null,
        loading               : false,
        isShowHelp            : true,
        // isOverrideHeaderRecord: false,
        isLoading             : "fa fa-spinner  fa-refresh-animate mr-3",
        students              : null,
        jsonData              : null,
        verifiedStudents      : null
      }
    },
    async mounted() {
      this.getCurrentImportTags();
    },
    methods: {
      moment(d){
        return Moment(d);
      },
      okToImport() {
        if( this.importTag.length > 0 ) {
          if( this.jsonData.length > 0 ) {
            return true;
          }
        }
        return false;
      },
      async importStudentFile() {
        try {
          this.loading = true;
          let formData = new FormData();
          formData.append( "uploadFile" , this.importFile[0], this.importFile[0].name );//, this.importFile.name );
          formData.append( "importTag" , this.importTag);//  , "importTag" );

          let result = await PortalRegistrationService.portalAuthorizationsUpload( formData , false ); // this.importTag , false);
          this.verifiedStudents = result.data.students;
          this.verifiedStudents = this.verifiedStudents.map((s) => {
            if( s.registration.isRegistered ) {
              s["_rowVariant"] = "success"
            }
            return s;
          })

          this.loading = false;
        } catch( error ) {
          this.loading = false;
          let message = error.message;
          console.log( error.response );
          if( error.response.data ) {
            message = error.response.data.message;
            if( error.response.data.missingFields ) {
              message += "\n" + error.response.data.missingFields.join(", ");
              message += "\nCheck your header line in the csv";
            }
          }
          await this.$root.alert( message , "error" );
        }
      },
      async uploadStudentFile() {
        try {
          this.loading = true;
          let formData = new FormData();
          formData.append( "uploadFile" , this.importFile[0], this.importFile[0].name );//, this.importFile.name );
          formData.append( "importTag" , this.importTag);//  , "importTag" );

          let result = await PortalRegistrationService.portalAuthorizationsUpload( formData , "postIt" ); // this.importTag , false);
          this.loading = false;

          await this.$root.alert( result.data.message );
          this.$router.push('/app')

        } catch( error ) {
          this.loading = false;
          let message = error.message;

          if( error.response.data ) {
            message = error.response.data.message;
            if( error.response.data.missingFields ) {
              message += "\n" + error.response.data.missingFields.join(", ");
              message += "\nCheck your header line in the csv";
            }
          }
          await this.$root.alert( message , "error" );
        }
      },
      async getCurrentImportTags() {
        try {
          let response = await PortalRegistrationService.getPortalAuthorizationsImportTags();
          this.importTags = response.data;
        } catch( error ) {
          console.log( error.message );
        }
      },
      async onFileChange(e) {
        var files = e.target.files || e.dataTransfer.files;
        if (!files.length)
          return;

        this.importFile = files;
        // console.log( "onFileChange() importFile = " , this.importFile );
        // console.log( "onFileChange() files 0 =" , files[0] );

        if( files[0].name.indexOf( ".csv" ) < 0  ) {
          await this.$root.alert( "File should be in .CSV format" );
          return;
        }

        // if( files[0].type !== "application/csv" ) {
        //   await this.$root.alert( "File should be in .CSV format" );
        //   return;
        // }

        //this.importFile = files[0];
        this.createInput(files[0]);
      },
      // stuffHeaderRecord() {
      //   if( ! this.students ) {
      //     return;
      //   }
      //   this.students[0] = "idNumber,lastName,firstName,homeRoom,teacher,grade,dob"
      // },
      async createInput(file) {
        var reader = new FileReader();
        var vm = this;
        vm.loading = true;
        reader.onload = (e) => {
          vm.students = reader.result.split( "\n" );

          // if( this.isOverrideHeaderRecord ) {
          //   // vm.students[0] = "idNumber,lastName,firstName,homeRoom,teacher,grade,dob"
          //   this.stuffHeaderRecord();
          // }
          let header = vm.students[0].split(",");

          header = header.map((h) => {
            if( h ) {
              return h.trim()
            }
          });

          vm.students.splice(0,1);
          vm.jsonData = [];

          vm.students.map((s) => {
            if( s ) {
              let data    = s.split(",");
              let student = {};
              for(var i = 0; i <= header.length; i++){
                student[ header[ i ] ] = data[ i ];
              }
              vm.jsonData.push(student);
            }
          });

          vm.loading = false;

          // console.log( "vm.fileInput" ,e,vm.students, vm.jsonData );
        }

        await reader.readAsText(file);

        // console.log(this.fileinput);
      }

    }
  }

</script>
<style lang="scss">

pre code {
  h5 {
    padding-top:0;
    margin-top:0;
    margin-left:2em;
  }
  background-color: #eee;
  border: 1px solid #999;
  border-radius: 1em;
  display: block;
  width:70%;
  margin-right:auto;
  margin-left:auto;
  //padding: 20px;
}

</style>
