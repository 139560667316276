///-------------------------------------------------------------------------------------------------
// summary:	Vendor service class
///-------------------------------------------------------------------------------------------------

import Api from '@/Services/Api';

export default {

  getVendors() {
    const response = Api().get(`/api/1/vendors`);
    return response;
  },
  getVendor(id) {
    const response = Api().get(`/api/1/vendor/${id}`);
    return response;
  },
  getCategoryList(id) {
    const response = Api().get(`/api/1/vendorCategories`);
    return response;
  },
  liveSearch(search = "a" , limit = 5) {
    const response = Api().get(`/api/1/vendorSearch/${encodeURIComponent(search)}`, { params: { limit: limit} });
    return response;
  },
  addVendor( vendor ) {
    const response = Api().post(`/api/1/vendor`, vendor );
    return response;
  },
  updateVendor( vendor ) {
    const response = Api().patch(`/api/1/vendor`, vendor );
    return response;
  },
  deleteVendor(id) {
    const response = Api().delete(`/api/1/vendor/${id}`);
    return response;
  }
}
