<template>
  <aside class="cd-frame shadow" :style="styleFrame" @click="frameClicked">
    <h1 class="cd-month-name" :style="styleMonthName">{{monthName}} </h1>
    <h1 class="cd-day-name" :style="styleDayName">{{dayName}}</h1>
    <h2 class="cd-day-number" :style="styleDayNumber">{{dayNumber}}</h2>
  </aside>
</template>
<script>

  import Currency from "currency.js";
  import Moment   from "moment";
  
  export default {
    
    props     : {
      eventDate       : {type: [ Object, String, Number, Date ], default: null},
      // width           : {type: String, default: "70"},
      // height          : {type: String, default: "100"},
      background      : {type: String, default: "white;"},
      titleColor      : {type: String, default: "#1f4d85"},
      titleBorderColor: {type: String, default: "#1f4d85"},
      dayColor        : {type: String, default: "black"},
      monthColor      : {type: String, default: "white"},
      nameColor       : {type: String, default: "black"},
      textAlign       : {type: String, default: "left"}
      // nameFontSize    : {type: String, default: "2em"},
      // dayFontSize     : {type: String, default: "3.2em"}
    },
    watch     : {
      eventDate: function(newVal, oldVal) {
        console.log("detected a date change");
        this.makeDates(this.eventDate);
      }
    },
    components: {
      Currency,
      Moment
    },
    computed  : {
      styleFrame() {
        let style = "border: 1px solid " + this.titleBorderColor +
                    ";border-top: 35px solid " + this.titleColor +
                    ";background:" + this.background + ";";
        
        return style;
      },
      styleMonthName() {
        return "color: " + this.monthColor + ";";
      },
      styleDayName() {
        return "color: " + this.nameColor + ";";
      },
      styleDayNumber() {
        return "color:" + this.dayColor + ";";
      }
    },
    data() {
      return {
        monthName: "",
        dayName  : "",
        dayNumber: 0,
      }
    },
    beforeMount() {
      this.makeDates(this.eventDate);
    },
    methods   : {
      moment(d) {
        return Moment(d);
      },
      currency(c) {
        return Currency(c);
      },
      makeDates(d) {
        this.monthName = this.moment(d).format("MMM").toUpperCase();
        this.dayName   = this.moment(d).format("dddd");
        this.dayNumber = this.moment(d).format("D");
      },
      frameClicked() {
        // this.$emit("click", this.title);  // fixme: make this a control event
      }
    }
  }
</script>
<style scoped lang="scss">

  .cd-frame {
    height: 100px;
    width: 70px;
    float: left;
    margin: 1em;
    border-radius: .4em;
    padding: 2px;
  }

  .cd-month-name {

    position: relative;
    top: -34px;
    font: 1.7em Arial, sans-serif;
    font-weight: 700;
    text-align: center;
  }

  .cd-day-name {

    position: relative;
    top: -57px;
    font: .8em Arial, sans-serif;
    font-weight: 700;
    text-align: center;
  }

  .cd-day-number {
    position: relative;
    font: 3em Arial, sans-serif;
    font-weight: 700;
    text-align: center;
    top: -88px;
    left: 0;
  }


  .cd-body {
    vertical-align: middle;
    display: table-cell;
    padding: 2px;
    overflow: auto;
  }


</style>


