<template>

  <div class="content row">
    <div class="col-md-12 " style="">
      <div class="noprint">
<!--        <i v-if="$root.isAdmin()" class="fa fa-gear show-hand" style="float:right;font-size:1.25em;"></i>-->
        <b-checkbox style="float:right;" v-model="showDetails">Details</b-checkbox>
        <h3 class="card-title">Hive Listing</h3>
      </div>
      <v-jstree v-if="treeView" :data="treeView"  whole-row  @item-click="selectNodes($event)" />
      <hr>

      <div v-if="kiosks && showList.length>0"  style="display:flex;flex-wrap:wrap;padding:1em;">
        <div v-for="(kiosk,index) in activeKiosks" :key="index" v-b-popover.hover.top="(kiosk.kiosk.activated) ? '' : 'Click to Activate Device'" class="mb-2 box-shadow" :style="kioskStyle(kiosk)" style="width:275px;min-height:80px;border:2px gray solid;border-radius:.5em;padding:.5em;margin:.5em;" @click="askActivationCode(kiosk.kiosk)">
          <div :id="'actionRequestElement'+index.toString()" >
            <img v-if="kiosk.kiosk.activated" width="32" style="float:right;padding-left:1em;cursor:pointer;" src="/img/kiosk-icon-green.png" @click="editDevice(kiosk)" v-b-popover.hover.top="'Edit this schedule'"/>
            <img v-else width="32" style="float:right;padding-left:1em;" src="/img/kiosk-icon-gray.png" />
          </div>
          <h5 style="text-align:center;margin:0;padding:0;border-bottom:1px solid gray">{{kiosk.kiosk.title}}</h5>
          <div style="padding:0;margin:0;font-size:.8em">
            {{kiosk.kiosk.location}}
          </div>
           <div v-if="showDetails" style="padding:0;margin:0;font-size:.8em;width:100%;">
            Device ID: {{kiosk.kiosk.deviceId}}  {{kiosk.kiosk.deviceModel}}
          </div>
          <div v-if="showDetails" style="padding:0;margin:0;font-size:.8em">
             IP: {{kiosk.kiosk.ipAddress}}
          </div>
          <div style="font-size:.9em;font-weight:100;margin-top:.5em;text-align:right;">
            {{kiosk.title}}
          </div>
          <div style="font-size:.8em;float:right;">
            {{(kiosk.kiosk.lastSeen) ? moment(kiosk.kiosk.lastSeen).fromNow() : '-never seen-'}}
          </div>
        </div>

      </div>
      <div v-else>
        <h4>No Devices Selected</h4>
      </div>

    </div>

    <b-modal v-if="kiosk" v-model="isActivating"
      :title="'Activate: ' + kiosk.title + ' (' + kiosk.deviceId + ')'"
      size="md"
      header-bg-variant="info"
      header-text-variant="light"
      footer-bg-variant="info"
      footer-text-variant="light">
    <b-container fluid>
       <b-form-group horizontal label-cols-sm="4" label-cols-lg="4" label-class="text-sm-right" label="Activation Code" label-for="activationId">
        <b-form-input id="activationId" style="width:15em;" type="text" v-model="activationCode" required></b-form-input>
      </b-form-group>
      <p style="font-size:.8em;padding:0 2em;">The Activation Code will be displayed on the target device while it is on the ACTIVATE screen.</p>
    </b-container>
    <div slot="modal-footer" class="w-100 mt-4">
      <b-btn size="sm" class="btn btn-fill mr-2" variant="danger" @click="isActivating=false">
        Cancel
      </b-btn>
      <b-btn size="sm" class="btn btn-fill" variant="success" @click="activateDevice(kiosk);">
        Activate This Device
      </b-btn>
  </div>
</b-modal>

  </div>
</template>

<script>

  import KioskService from "/src/Services/KioskService";
  import Card from "/src/components/UIComponents/Cards/Card.vue";
  import LTable from "/src/components/UIComponents/Table.vue";
  import Moment from "moment-timezone";
  import VJstree     from 'vue-jstree';

  export default {
    components: {
      Card,
      LTable,
      VJstree
    },
    data() {
      return {
        name          : "Kiosk Menu",
        showDetails   : false,
        pageInterval  : null,
        treeView      : null,
        kiosks        : null,
        kiosk         : null,
        isActivating  : false,
        activationCode: "",
        showList      : []
      };
    },
    computed: {
      activeKiosks() {
        if( this.showList.length === 0 ) {
          return this.kiosks.filter((k) => { return k.kiosk.active });
        } else {
          return this.kiosks.filter((k) => { return k.kiosk.active && this.showList.indexOf(k.kiosk._id) >= 0 });
        }
      }
    },
    beforeDestroy() {
      if (this.pageInterval) {
        clearInterval(this.pageInterval);
      }
    },
    async created() {
      await this.getKiosksStatus();
      this.pageInterval = setInterval(this.doTimer, 30000); // 30 second refresh
      let showList = localStorage.getItem("kiosk-showList");
      if( showList ) {
        this.showList = JSON.parse(showList);
      }
    },
    methods: {
      selectNodes(e) {
        if( e.$children.length > 0 ) {
          this.showList = e.$children.map((n) => { return n.data.id });
        } else {
          if( e.data.id ) {
            this.toggleNode( e.data.id );
          }
        }
        localStorage.setItem( "kiosk-showList" , JSON.stringify(this.showList));
      },
      toggleNode( id ) {
        let index = this.showList.indexOf(id);
        if( index >= 0 ) {
          this.showList.splice(index,1);
        } else {
          this.showList.push(id );
        }
      },
      moment(d) {
        return Moment(d);
      },
      doTimer() {
        //console.log("timer.");
        this.getKiosksStatus();
      },
      makeTitle(kiosk) {
        let title;
        if (Moment(kiosk.kiosk.lastSeen).add(60, 'minutes') < Moment()) {
          title = `<span style="color:red;"><i class="fa fa-clock-o"></i> ${kiosk.kiosk.title}</style>`;    // not seen in an hour.
        } else  {
          if( kiosk.kiosk.activated ) {
            title = `<span style="color:green;"><i class="fa fa-check"> ${kiosk.kiosk.title}</style>`;
          } else {
            title = `<span style="color:purple;"><i class="fa fa-chain-broken"> ${kiosk.kiosk.title}</style> (inactive)`;
          }
        }


        return title;
      },
      makeTreeView() {
        let x = [];

        if( ! this.kiosks ) {
          return x;
        }

        let me = this;      // get the unique list of locations, then loop through and find the devices under that location.
        var departments = _.uniq(this.kiosks.map( (k) => k.title.toUpperCase()));

        departments.forEach(d => {
          let p = { text: d.toUpperCase() , opened: false, children: []};
          p.children=_.sortBy(this.kiosks.filter( f =>  { return f.title.toUpperCase() == d }).map( m => { return { text: this.makeTitle(m), id: m.kiosk._id }}),"text");
          x.push(p);
        })

        setTimeout( () => {
          me.treeView = x;
        }, 1)

        return x;
      },
      async editDevice(kiosk) {
        if(! this.$root.isAdmin()) {
          return;
        }
        console.log( "kiosk = " , kiosk );
        this.$router.push(`/kiosks/device/${encodeURIComponent(kiosk._id)}`);
      },
      async activateDevice(kiosk) {
        if( this.activationCode.trim().length == 0 ) {
          await this.$root.alert( "Please enter an Activatin Code" , 'warning');
          return;
        }
        let data = {
          deviceId: kiosk.deviceId,
          activationCode: this.activationCode
        };

        try {
          let response = await KioskService.setActivationCode(data);
          console.log( response.data );
          this.isActivating = false;
        } catch( error ) {
          await this.$root.alert( error.message );
        }

        return true;
      },
      askActivationCode(kiosk) {
        if( kiosk.activated ) {
          return;
        }
        this.kiosk = kiosk;
        this.isActivating = true;
      },
      kioskStyle(kiosk) {
        let style = "";
        if (!kiosk.kiosk.activated) {
          return "background:lightgray;cursor:pointer;";
        }
        if (Moment(kiosk.kiosk.lastSeen).add(60, 'minutes') < Moment()) {
          return "background:rgba(239, 62, 46, .2);"    // offline; we add an hour to the last seen time and if it's still less than now, it's been a while.
        }
        return "background: rgba(233, 254, 200, 1";
      },
      async getKiosksStatus() {
        try {   // todo: this can be made to only get changes at some point when we get a lot of devices...
          let results = await KioskService.getKiosksStatus();
          this.kiosks = _.sortBy(results.data, ["kiosk.title"] );
          this.treeView = this.makeTreeView();
          //console.log("got kiosks", this.kiosks);
        } catch (error) {
          console.log( error )
        }
      }
    }
  };

</script>
<style language="scss" scoped>

  .report-card {

    border-radius:.75em;
    width: 360px;
    height:225px;
    margin-right:1em;

    img {
      padding-top:1em;
      padding-left:1em;
      width:80px;
      height:auto;
      overflow:hidden;
    }

    .report-card-description {
      display:flex;
      width:100%;
      height:100px;
      border-top:1px black solid;
      font-size:.8em;
      padding:.5em;
      margin-top:2px;
      white-space:pre-line;
      /*font-style:italic;*/
      line-height:1.25em;
      overflow-y:auto;
    }

    .report-card-controls {

      button {
        position:absolute;
        right:1em;
        top:1em;
        border-radius:.5em;
        padding: .25em .5em .25em .5em;
        background-color:green;
        color:white;
      }
    }
  }

  .box-shadow {
    -webkit-box-shadow: -4px 4px 11px -3px #000000;
    box-shadow: -4px 4px 11px -3px #000000;
  }

  .video-insert {
    position        : absolute;
    right           : 0;
    bottom          : 0;
    min-width       : 100%;
    min-height      : 100%;
    width           : auto;
    height          : auto;
    z-index         : -100;
    background-size : cover;
    overflow        : hidden;
  }

</style>
