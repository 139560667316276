///-------------------------------------------------------------------------------------------------
// summary:	stats service class
///-------------------------------------------------------------------------------------------------

import Api from '@/Services/Api';

export default {

  getDailySales() {
    const response = Api().get(`/api/1/query/dailySales`);
    return response;
  },
  // getMonthlySales() {
  //   const response = Api().post(`/api/1/totalClass`, total );
  //   return response;
  // },
  getDailySalesByTotalClass() {
    const response = Api().get(`/api/1/query/dailySalesByTotalClass`);
    return response;
  }
}
