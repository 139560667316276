<template>
  <div class="contact-card">
    <b-form @input="dataChanged()" style="text-align:left;">
      <template v-if="isShowHeader">
        <i v-if="isAllowAdd" class="fa fa-plus-square show-hand" style="float:left;font-size:1.5em;margin-right:.5em;" v-b-popover.hover.top="'Add Contact'" @click="addContact()" />
        <h4 style="margin:0;padding:0;">{{ title }}</h4>
      </template>
      <div style="overflow-x:hidden;overflow-y:auto;">
        <b-tabs v-if="contacts" :vertical="isVertical" lazy :class="(isVertical) ? 'tab-hover-left' : 'tab-hover'" style="padding:1em 1em;" :active-nav-item-class="(isVertical) ? 'selected-tab-left' : 'selected-tab'" v-model="addressTab">
          <b-tab v-for="(contact,index) in contacts" :key="index">
            <template v-slot:title>
              <div style="width:10em;max-width:10em;display:flex;text-align:right;">
                <!-- <b-avatar :src="myGravatar(contact.email)"></b-avatar> -->
                <div style="padding:0 0 0 .25em;margin-right:0;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;">{{ contact.alias || contact.name || 'none' }}</div>
              </div>
            </template>
            <b-form @change="dataChanged()">
              <div style="margin-top:1em;"></div>

              <i v-if="isAllowDelete && contact._id " class="fa fa-trash show-hand" style=";font-size:1.25em;position:absolute;right:20px;top:-18px;" v-b-popover.hover.left="'Remove Contact'" @click="deleteContact(contact._id)" />

              <img v-if="contact.email && isShowImage" style="cursor:pointer;margin-left:18%;margin-bottom:1em;border-radius:5px;" :src="myGravatar(contact.email)" :height="imageSize" @click="isAddressReadOnly=!isAddressReadOnly;" />
              <button v-if="!isReadOnly() && isAllowLookup" class="btn btn-sm btn-warning btn-fill" style="font-size:8px;margin-left:2em;" @click.prevent="assignContact(contact)">
                lookup
              </button>
              <sf-input v-if="isAskContactType" v-model="contact.contactType" :disabled="isReadOnly()" :labelCols="2" cstyle="width:10em;" label="Type" type="list" :options="contactTypes" />
              <sf-input v-if="isAskAccountType" v-model="contact.accountType" :disabled="isReadOnly()" :labelCols="2" cstyle="width:10em;" label="Type" type="list" :options="accountTypes" @change="accountTypeChanged()" />
              <sf-input v-if="contact.accountNumber" v-model="contact.accountNumber" :disabled="true" :labelCols="2" cstyle="" label="Acct #" type="text" />
              <sf-input v-if="contact.name || !isReadOnly()" v-model="contact.name" :disabled="isReadOnly()" :labelCols="2" cstyle="" label="Name" type="text" />
              <sf-input v-if="contact.alias || !isReadOnly()" v-model="contact.alias" :disabled="isReadOnly()" :labelCols="2" cstyle="" label="Alias" type="text" />
              <sf-input v-if="contact.title || !isReadOnly()" v-model="contact.title" :disabled="isReadOnly()" :labelCols="2" cstyle="" label="Title" type="text" />
              <sf-input v-if="contact.address1 || !isReadOnly()" v-model="contact.address1" :disabled="isReadOnly()" :labelCols="2" cstyle="" label="Address" type="text" />
              <sf-input v-if="contact.address2 || !isReadOnly()" v-model="contact.address2" :disabled="isReadOnly()" :labelCols="2" cstyle="" label="" type="text" />
              <sf-input v-if="contact.address3 || !isReadOnly()" v-model="contact.address3" :disabled="isReadOnly()" :labelCols="2" cstyle="" label="" type="text" />
              <sf-input v-if="contact.city || !isReadOnly()" v-model="contact.city" :disabled="isReadOnly()" :labelCols="2" cstyle="" label="City" type="text" />
              <sf-input v-if="contact.state || !isReadOnly()" v-model="contact.state" :disabled="isReadOnly()" :labelCols="2" cstyle="width:10em;" label="State" type="text" />
              <sf-input v-if="contact.zip || !isReadOnly()" v-model="contact.zip" :disabled="isReadOnly()" :labelCols="2" cstyle="width:10em;" label="Zip" type="text" />
              <br>
              <sf-input v-if="contact.phone || !isReadOnly()" v-model="contact.phone" :disabled="isReadOnly()" :labelCols="2" cstyle="width:12em;" label="Phone" type="text" />
              <sf-input v-if="contact.cell || !isReadOnly()" v-model="contact.cell" :disabled="isReadOnly()" :labelCols="2" cstyle="width:12em;" label="Cell" type="text" />
              <sf-input v-if="contact.email || !isReadOnly()" v-model="contact.email" :disabled="isReadOnly()" :labelCols="2" label="Email" type="icon-text" appendIcon="fa fa-envelope-o show-hand" @appendClick="emailClick(contact.email)" />
              <sf-input v-if="contact.website || !isReadOnly()" v-model="contact.website" :disabled="isReadOnly()" :labelCols="2" label="Website" type="text" />
              <sf-input v-if="contact.notes || !isReadOnly()" v-model="contact.notes" :readonly="isReadOnly()" :labelCols="2" :rows="8" label="Notes" type="textarea" />
            </b-form>
          </b-tab>
        </b-tabs>
        <template v-if="contact">
          <b-form @change="dataChanged()" style="">
            <div style="margin-top:1em;"></div>
            <img v-if="contact.email && isShowImage" style="cursor:pointer;margin-left:18%;margin-bottom:1em;border-radius:5px;" :src="myGravatar(contact.email)" :height="imageSize" @click="isAddressReadOnly=!isAddressReadOnly;" />
            <button v-if="!isReadOnly() && isAllowLookup && (contact.accountType > '')" class="btn btn-sm btn-fill" :class="contact.accountNumber ? 'btn-success mb-2' : 'btn-warning'" style="position:relative;float:right;margin-left:2em;" @click.prevent="assignContact(contact)">
              {{ contact.accountNumber ? 'Change' : 'Assign' }}
            </button>
            <div style=";font-size:1.5em;position:relative;left:290px;top:37px;">
              <i v-if="isAllowDelete && contact._id && index>0" class="fa fa-trash show-hand" v-b-popover.hover.top="'Remove Contact'" @click="deleteContact(contact._id)" />
              <!--              <i v-if="contact.accountNumber" class="show-hand fa fa-lock ml-1" @click="assignContact(contact)"></i>-->
              <!--              <i v-if="!contact.accountNumber" class="show-hand fa fa-unlock ml-1" @click="assignContact(contact)"></i>-->
            </div>

            <div>
<!--              <i v-if="contact.accountNumber" class="float-right show-hand fa fa-lock ml-1" @click="assignContact(contact)"></i>-->
              <i v-if="!contact.accountNumber" class="float-right show-hand fa fa-unlock ml-1" @click="assignContact(contact)"></i>

              <sf-input v-if="isAskContactType" v-model="contact.contactType" :disabled="isReadOnly()" :labelCols="2" cstyle="width:10em;" label="Type" type="list" :options="contactTypes" />
              <sf-input v-if="isAskAccountType" v-model="contact.accountType" :disabled="isReadOnly()" :labelCols="2" cstyle="width:10em;" label="Type" type="list" :options="accountTypes" @change="accountTypeChanged()" />
            </div>
            <sf-input v-if="contact.accountNumber" v-model="contact.accountNumber" :disabled="true" :labelCols="2" cstyle="" label="Acct #" type="text" />
            <sf-input v-if="contact.name || !isReadOnly()" v-model="contact.name" :disabled="isReadOnly()" :labelCols="2" cstyle="" label="Name" type="text" />
            <sf-input v-if="contact.alias || !isReadOnly()" v-model="contact.alias" :disabled="isReadOnly()" :labelCols="2" cstyle="" label="Alias" type="text" />
            <sf-input v-if="contact.title || !isReadOnly()" v-model="contact.title" :disabled="isReadOnly()" :labelCols="2" cstyle="" label="Title" type="text" />
            <sf-input v-if="contact.address1 || !isReadOnly()" v-model="contact.address1" :disabled="isReadOnly()" :labelCols="2" cstyle="" label="Address" type="text" />
            <sf-input v-if="contact.address2 || !isReadOnly()" v-model="contact.address2" :disabled="isReadOnly()" :labelCols="2" cstyle="" label="" type="text" />
            <sf-input v-if="contact.address3 || !isReadOnly()" v-model="contact.address3" :disabled="isReadOnly()" :labelCols="2" cstyle="" label="" type="text" />
            <sf-input v-if="contact.city || !isReadOnly()" v-model="contact.city" :disabled="isReadOnly()" :labelCols="2" cstyle="" label="City" type="text" />
            <sf-input v-if="contact.state || !isReadOnly()" v-model="contact.state" :disabled="isReadOnly()" :labelCols="2" cstyle="width:10em;" label="State" type="text" />
            <sf-input v-if="contact.zip || !isReadOnly()" v-model="contact.zip" :disabled="isReadOnly()" :labelCols="2" cstyle="width:10em;" label="Zip" type="text" />
            <br>
            <sf-input v-if="contact.phone || !isReadOnly()" v-model="contact.phone" :disabled="isReadOnly()" :labelCols="2" cstyle="width:12em;" label="Phone" type="text" />
            <sf-input v-if="contact.cell || !isReadOnly()" v-model="contact.cell" :disabled="isReadOnly()" :labelCols="2" cstyle="width:12em;" label="Cell" type="text" />
            <sf-input v-if="contact.email || !isReadOnly()" v-model="contact.email" :disabled="isReadOnly()" :labelCols="2" label="Email" type="icon-text" :is-text="true" appendIcon="fa fa-envelope-o show-hand"  @appendClick="emailClick(contact.email)" />
            <!--        <sf-input v-if="contact.email || !isReadOnly()" v-model="contact.email" :disabled="isReadOnly()" :labelCols="2" label="Email" type="text" />-->
            <sf-input v-if="contact.website || !isReadOnly()" v-model="contact.website" :disabled="isReadOnly()" :labelCols="2" label="Website" type="text" />
            <sf-input v-if="contact.notes || !isReadOnly()" v-model="contact.notes" :readonly="isReadOnly()" :labelCols="2" :rows="8" label="Notes" type="textarea" />
          </b-form>
        </template>
      </div>
    </b-form>
  </div>
</template>
<script>

import Currency from "currency.js";
import SfInput  from "/src/components/UIComponents/Inputs/SFormInput.vue";
import gravatar from "gravatar";
import moment   from "moment-timezone"; // find the docs here: https://momentjs.com/timezone/
import Numeral  from "numeral"; // find the docs here: http://numeraljs.com/
import LTable   from "/src/components/UIComponents/Table.vue";

export default {
  name      : 'ContactCard',
  props     : {
    title           : {type: String, default: "Contacts"},
    contacts        : {type: Array, default: () => { return null}},
    contact         : {type: Object, default: () => { return null}},
    index           : {type: Number, default: 0},
    imageSize       : {type: Number, default: 128},
    readOnly        : {type: Boolean, default: false},
    isShowImage     : {type: Boolean, default: true},
    isShowHeader    : {type: Boolean, default: true},
    isAllowAdd      : {type: Boolean, default: true},
    isAllowEdit     : {type: Boolean, default: true},
    isAllowDelete   : {type: Boolean, default: true},
    isAllowLookup   : {type: Boolean, default: false},
    isAskContactType: {type: Boolean, default: false},
    isAskAccountType: {type: Boolean, default: false},
    isEnableEmail   : {type: Boolean, default: true},
    isVertical      : {type: Boolean, default: true}
  },
  components: {
    SfInput,
    LTable
  },
  data() {
    return {
      addressTab       : 0,
      isDataChanged    : false,
      isExpand         : false,
      isAddressReadOnly: true,
      contactTypes     : [ // contactType:  enum: [ "contact", "billing", "shipping", "technical", "mailing", "newsletter", "portal", "account", "other"] },
        {value: "primary", text: "Primary"},
        {value: "contact", text: "Contact"},
        {value: "customer", text: "Customer"},
        {value: "attendee", text: "Attendee"},
        {value: "employee", text: "Employee"},
        {value: "sales", text: "Sales"},
        {value: "technical", text: "Technical"},
        {value: "prospect", text: "Prospect"},
        {value: "proposal", text: "Proposal"},
        {value: "vendor", text: "Vendor"},
        {value: "relative", text: "Relative"},
        {value: "billing", text: "Billing"},
        {value: "shipping", text: "Shipping"},
        {value: "mailing", text: "Mailing"},
        {value: "newsletter", text: "Newsletter"},
        {value: "portal", text: "Portal"},
        {value: "other", text: "Other"}
      ],
      accountTypes     : [
        {text: "Not Linked", value: ""},
        {text: "Resident", value: "resident"},
        {text: "Employee", value: "employee"},
        {text: "Vendor", value: "vendor"},
        {text: "Client", value: "client"},
        {text: "Contact", value: "contact"},
        {text: "Student", value: "student"},
        {text: "Faculty", value: "faculty"},
        {text: "Teacher", value: "teacher"},
        {text: "Department", value: "department"}
      ]
    }
  },
  beforeUpdate() {
    if(!this.contact || this.contact.name.toLowerCase() === 'new' || this.contact.name.length === 0) {    // force new contact into edit mode.
      this.isDataChanged = true;
      this.isExpand      = true;
    }
  },
  mounted() {

  },
  computed: {},
  methods : {
    dataChanged() {
      this.isDataChanged = true;
      this.$emit("change");
    },
    accountTypeChanged() {
      this.assignContact(this.data);    // this event only fires is we're allowed to change it...
    },
    numeral(x) {
      return Numeral(x);
    },
    isReadOnly() {
      let x = this.readOnly && this.isAddressReadOnly;
      return x;
    },
    emailClick(email) {
      if(this.isEnableEmail) {
        window.location.href = `mailto:${email}`;
      } else {
        this.$emit("email", email);
      }
    },
    assignContact(c) {
      console.log("Event Assign contact", c);
      if(this.isReadOnly()) {
        return;
      }
      this.$emit("assign", c);
    },
    addContact() {
      this.$emit("add");
    },
    async deleteContact(id) {
      // if( await this.$root.confirmYesNo( "Delete this contact?" )) {
      this.$emit("delete", id);
      // }
    },
    hasAddress(contact) {
      if(!this.readOnly) {
        return true;
      }

      let s = contact.address1 || "" +
              // this.data.primary.address2 || "" + "2" +
              // this.data.primary.address3 || "" + "3" +
              contact.city || "" +
              contact.state || "" +
              contact.zip || ""

      if(s.trim().length > 0) {
        return true;
      }

      return false;
    },
    myGravatar(email) {
      return gravatar.url(email);
    },
    currency(c) {
      return Currency(c);
    },
    addressEdit() {
      this.isExpand = false;
    },
    addressRemove() {
      this.isExpand = false;
      if(!this.contact._id) {    // this requires a save first.
        return;
      }
      this.$emit('remove', this.contact._id);
    },
    save() {
      if(this.isDataChanged) {
        this.$emit(`change`, {contact: this.contact, index: this.index});
        this.isDataChanged = false;
      }
    }
  }
}
</script>
<style lang="scss" scoped>

.contact-card {
  // width:190px;
  width: 100%;
  //padding: .5em;
  //line-height: 18px;
  //border: 1px solid gray;
  //box-shadow: 2px 2px 2px gray;
  //border-radius: 1em;
  //min-height: 100px;
}

.input-group-text {
  width: 80px;
}


.contact-input-edit {
  font-size: .8em;
  padding: 0;
  margin: 0;
  height: 1.25em;
  line-height: 1em;
}

.card-table {
  font-size: .8em;
  line-height: 70%;
  margin-top: 5px;
  background: #f0f0f0;
  border-radius: 1em;
}

</style>
