import { render, staticRenderFns } from "./AccountCertificationEdit.vue?vue&type=template&id=a4d49148&scoped=true"
import script from "./AccountCertificationEdit.vue?vue&type=script&lang=js"
export * from "./AccountCertificationEdit.vue?vue&type=script&lang=js"
import style0 from "./AccountCertificationEdit.vue?vue&type=style&index=0&id=a4d49148&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a4d49148",
  null
  
)

export default component.exports