///-------------------------------------------------------------------------------------------------
// summary:	Transaction service class
///-------------------------------------------------------------------------------------------------

import Api from '@/Services/Api';

export default {

  transactionPost(transaction) {
    let badgeNumber = transaction.badgeNumber;
    return Api().post(`/api/1/transactionPost/${badgeNumber}`, transaction);
  },

  transactionDelete(id) {
    return Api().delete(`/api/1/transaction/${id}` );
  },

  transactionListByAccount(accountNumber, startDate=null, endDate=null ) {
    let response;

    if( startDate ) {
      response = Api().get(`/api/1/transactionListByAccount/${accountNumber}/${startDate}/${endDate}`);
    } else {
      response  = Api().get(`/api/1/transactionListByAccount/${accountNumber}`);
    }

    if (response.data) {
      return response.data;
    }
    return response;
  },

  transactionListByOwnerId( id ) {
    let response  = Api().get(`/api/1/transactionListByOwnerId/${id}`);

    if (response.data) {
      return response.data;
    }
    return response;
  },

  transactionList(startDate, endDate , maxTrans , filters = null ) {
    const response = Api().get(`/api/1/transactionList/${startDate}/${endDate}/${maxTrans}`, { params: {filters: filters} } );

    if (response.data) {
      return response.data;
    }
    return response;
  },

  transactionChangeTotalClass( transactionId , newTotalClass ) {
    const response = Api().patch(`/api/1/transactionChangeTotalClass/${transactionId}/${newTotalClass}`);

    if (response.data) {
      return response.data;
    }
    return response;
  },

  transactionChangeTenderNumber( transactionId , newTenderNumber) {
    const response = Api().patch(`/api/1/transactionChangeTenderNumber/${transactionId}/${newTenderNumber}`);

    if (response.data) {
      return response.data;
    }
    return response;
  },

  getTransaction(transactionId) {
    const response = Api().get(`/api/1/transaction/${transactionId}`);

    if (response.data) {
      return response.data;
    }
    return response;
  },

  ccTransactionPost( transaction ) {
    return Api().post( `/api/1/ccTransactionPost/${transaction.badgeNumber}` , transaction );
  },

  ccTransactionRefund( transaction ) {
    return Api().post( `/api/1/ccTransactionRefund/${transaction.badgeNumber}` , transaction );
  }

}
