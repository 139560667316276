<template>
  <div class="content">
    <div class="container-fluid">
      <div style="display:flex;">
        <h3>Environmental: Temperatures</h3>
        <b-form-select style="margin-left:2em;position:relative;top:10px;width:12em;" size="lg" type="list" :options="['Landings','Section 1']"
          v-model="sectionSelect" />
      </div>
      <div v-if="sectionSelect=='Landings'">
        <div v-for="(sensor,index) in sensors" :key="'a'+index" class="sensor-card sensor-card-temperature">
          <div class="sensor-card-room-number">{{sensor.roomNumber}}</div>
          <div class="sensor-card-name">{{sensor.name}}</div>
          <div class="sensor-card-value" :style="rangeColor(sensor.value)">{{sensor.value}}</div>
        </div>
        <div v-for="(sensor,index) in sensors" :key="'b'+index" class="sensor-card sensor-card-temperature">
          <div class="sensor-card-room-number">{{sensor.roomNumber}}</div>
          <div class="sensor-card-name">{{sensor.name}}</div>
          <div class="sensor-card-value" :style="rangeColor(sensor.value)">{{sensor.value}}</div>
        </div>
      </div>

      <div v-else>
        <div v-for="(sensor,index) in sensors" :key="'c'+index" class="sensor-card sensor-card-temperature">
          <div class="sensor-card-room-number">{{sensor.roomNumber}}</div>
          <div class="sensor-card-name">{{sensor.name}}</div>
          <div class="sensor-card-value" :style="rangeColor(sensor.value)">{{sensor.value}}</div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
  // import Vue from "vue";
  // import TaskService       from "/src/Services/TaskService";
  // import ProceduresService from "/src/Services/ProceduresService";

  import Moment      from "moment";
  import Card        from '/src/components/UIComponents/Cards/Card.vue'
  import TaskList    from "/src/components/UIComponents/Cards/TaskList.vue";
  import SfInput     from "/src/components/UIComponents/Inputs/SFormInput.vue";
  import ChecklistEditor from "/src/components/UIComponents/ChecklistEditor";

  export default {
    name: "procedures",
    components: {
      Card,
      SfInput,
      TaskList,
      ChecklistEditor
    },
    data() {
      return {
        isDataChanged: false,
        sectionSelect: "Landings",
        loading      : false,
        isLoading    : "fa fa-spinner  fa-refresh-animate mr-3",
        type         : [ '', 'info', 'success', 'warning', 'danger' ],
        sensors      : [
  {
    "_id": "5fa719c6a66c2b0d594091a5",
    "index": 0,
    "deviceId": "device-101",
    "roomNumber": "L1-10",
    "active": false,
    "value": 75,
    "name": "Suarez"
  },
  {
    "_id": "5fa719c66a45662718af64ca",
    "index": 1,
    "deviceId": "device-102",
    "roomNumber": "L1-11",
    "active": false,
    "value": 84,
    "name": "Ramsey"
  },
  {
    "_id": "5fa719c6d7e7bdaa6e1ea0a1",
    "index": 2,
    "deviceId": "device-103",
    "roomNumber": "L1-12",
    "active": true,
    "value": 78,
    "name": "Kinney"
  },
  {
    "_id": "5fa719c61f06d2cc5fb1c8f6",
    "index": 3,
    "deviceId": "device-104",
    "roomNumber": "L1-13",
    "active": true,
    "value": 77,
    "name": "May"
  },
  {
    "_id": "5fa719c61a4ef782b46e5a0a",
    "index": 4,
    "deviceId": "device-105",
    "roomNumber": "L1-14",
    "active": true,
    "value": 74,
    "name": "Delgado"
  },
  {
    "_id": "5fa719c65682d67c3e5295a9",
    "index": 5,
    "deviceId": "device-106",
    "roomNumber": "L1-15",
    "active": true,
    "value": 77,
    "name": "Christian"
  },
  {
    "_id": "5fa719c67331f58cc242489b",
    "index": 6,
    "deviceId": "device-107",
    "roomNumber": "L1-16",
    "active": true,
    "value": 77,
    "name": "Richardson"
  },
  {
    "_id": "5fa719c6226b219477232679",
    "index": 7,
    "deviceId": "device-108",
    "roomNumber": "L1-17",
    "active": true,
    "value": 74,
    "name": "Thompson"
  },
  {
    "_id": "5fa719c65b8af16381047bd6",
    "index": 8,
    "deviceId": "device-109",
    "roomNumber": "L1-18",
    "active": true,
    "value": 65,
    "name": "Moran"
  },
  {
    "_id": "5fa719c682c3a767e08f330d",
    "index": 9,
    "deviceId": "device-110",
    "roomNumber": "L1-19",
    "active": true,
    "value": 76,
    "name": "Harrell"
  },
  {
    "_id": "5fa719c6a313c1a43a7a38a7",
    "index": 10,
    "deviceId": "device-111",
    "roomNumber": "L1-110",
    "active": true,
    "value": 75,
    "name": "Nolan"
  },
  {
    "_id": "5fa719c6fe4c963df5e7e35b",
    "index": 11,
    "deviceId": "device-112",
    "roomNumber": "L1-111",
    "active": true,
    "value": 78,
    "name": "Taylor"
  },
  {
    "_id": "5fa719c6fffa4fbebaf5f5f3",
    "index": 12,
    "deviceId": "device-113",
    "roomNumber": "L1-112",
    "active": true,
    "value": 70,
    "name": "Stafford"
  },
  {
    "_id": "5fa719c61b039ec0f279b896",
    "index": 13,
    "deviceId": "device-114",
    "roomNumber": "L1-113",
    "active": true,
    "value": 63,
    "name": "Webster"
  },
  {
    "_id": "5fa719c6f79638d1336f0812",
    "index": 14,
    "deviceId": "device-115",
    "roomNumber": "L1-114",
    "active": true,
    "value": 78,
    "name": "Rodriquez"
  },
  {
    "_id": "5fa719c60d219295a0bda933",
    "index": 15,
    "deviceId": "device-116",
    "roomNumber": "L1-115",
    "active": true,
    "value": 65,
    "name": "Fitzgerald"
  },
  {
    "_id": "5fa719c634d94fde77a9f17c",
    "index": 16,
    "deviceId": "device-117",
    "roomNumber": "L1-116",
    "active": true,
    "value": 70,
    "name": "Mcclain"
  },
  {
    "_id": "5fa719c68d84df01fa4f1c6b",
    "index": 17,
    "deviceId": "device-118",
    "roomNumber": "L1-117",
    "active": true,
    "value": 80,
    "name": "Strickland"
  },
  {
    "_id": "5fa719c62b957be059a0eb2d",
    "index": 18,
    "deviceId": "device-119",
    "roomNumber": "L1-118",
    "active": true,
    "value": 77,
    "name": "Price"
  },
  {
    "_id": "5fa719c676c0a427a0f764db",
    "index": 19,
    "deviceId": "device-120",
    "roomNumber": "L1-119",
    "active": true,
    "value": 72,
    "name": "Porter"
  },
  {
    "_id": "5fa719c66731dbed0dd4afc8",
    "index": 20,
    "deviceId": "device-121",
    "roomNumber": "L1-120",
    "active": true,
    "value": 74,
    "name": "Harmon"
  },
  {
    "_id": "5fa719c61a3d638195a2c3c9",
    "index": 21,
    "deviceId": "device-122",
    "roomNumber": "L1-121",
    "active": true,
    "value": 71,
    "name": "Barton"
  },
  {
    "_id": "5fa719c6367efdc8c2f1248f",
    "index": 22,
    "deviceId": "device-123",
    "roomNumber": "L1-122",
    "active": true,
    "value": 90,
    "name": "Powers"
  },
  {
    "_id": "5fa719c6f3d2e58c023cb3f6",
    "index": 23,
    "deviceId": "device-124",
    "roomNumber": "L1-123",
    "active": true,
    "value": 75,
    "name": "Foley"
  },
  {
    "_id": "5fa719c650aadc6b173471af",
    "index": 24,
    "deviceId": "device-125",
    "roomNumber": "L1-124",
    "active": true,
    "value": 73,
    "name": "Nielsen"
  },
  {
    "_id": "5fa719c68121bf7da9c08a20",
    "index": 25,
    "deviceId": "device-126",
    "roomNumber": "L1-125",
    "active": true,
    "value": 74,
    "name": "Carter"
  },
  {
    "_id": "5fa719c665c553d91a24f43f",
    "index": 26,
    "deviceId": "device-127",
    "roomNumber": "L1-126",
    "active": true,
    "value": 73,
    "name": "Boyle"
  },
  {
    "_id": "5fa719c644736a0e3df7238a",
    "index": 27,
    "deviceId": "device-128",
    "roomNumber": "L1-127",
    "active": true,
    "value": 74,
    "name": "Marshall"
  },
  {
    "_id": "5fa719c62f5f5a131957cc51",
    "index": 28,
    "deviceId": "device-129",
    "roomNumber": "L1-128",
    "active": false,
    "value": 74,
    "name": "Foreman"
  },
  {
    "_id": "5fa719c6e1b5db358c2d333c",
    "index": 29,
    "deviceId": "device-130",
    "roomNumber": "L1-129",
    "active": true,
    "value": 73,
    "name": "Oneill"
  },
  {
    "_id": "5fa719c6b1274f9234e899eb",
    "index": 30,
    "deviceId": "device-131",
    "roomNumber": "L1-130",
    "active": true,
    "value": 88,
    "name": "Dunlap"
  },
  {
    "_id": "5fa719c663198dd771b3feba",
    "index": 31,
    "deviceId": "device-132",
    "roomNumber": "L1-131",
    "active": true,
    "value": 74,
    "name": "Patel"
  },
  {
    "_id": "5fa719c62ef38f3eb2f16625",
    "index": 32,
    "deviceId": "device-133",
    "roomNumber": "L1-132",
    "active": true,
    "value": 88,
    "name": "Klein"
  },
  {
    "_id": "5fa719c6d81cab8f48fb446a",
    "index": 33,
    "deviceId": "device-134",
    "roomNumber": "L1-133",
    "active": true,
    "value": 77,
    "name": "Valentine"
  },
  {
    "_id": "5fa719c63aff3c370090b39e",
    "index": 34,
    "deviceId": "device-135",
    "roomNumber": "L1-134",
    "active": true,
    "value": 74,
    "name": "Gibson"
  },
  {
    "_id": "5fa719c6fbcc7c072a084be7",
    "index": 35,
    "deviceId": "device-136",
    "roomNumber": "L1-135",
    "active": false,
    "value": 72,
    "name": "Chaney"
  },
  {
    "_id": "5fa719c6c31197d31a31997d",
    "index": 36,
    "deviceId": "device-137",
    "roomNumber": "L1-136",
    "active": true,
    "value": 77,
    "name": "Mullen"
  },
  {
    "_id": "5fa719c62ba5d64e75826dce",
    "index": 37,
    "deviceId": "device-138",
    "roomNumber": "L1-137",
    "active": false,
    "value": 84,
    "name": "Valencia"
  },
  {
    "_id": "5fa719c6de971d6d00162680",
    "index": 38,
    "deviceId": "device-139",
    "roomNumber": "L1-138",
    "active": false,
    "value": 74,
    "name": "Mcintyre"
  },
  {
    "_id": "5fa719c6e40bd1326913a873",
    "index": 39,
    "deviceId": "device-140",
    "roomNumber": "L1-139",
    "active": true,
    "value": 73,
    "name": "Bass"
  }
],
        notifications    : {
          topCenter: false
        }
      }
    },
    async created() {
    },
    computed  : {
    },
    methods   : {
      moment(d) {
        return Moment(d);
      },
      done() {
        this.$router.go(-1);
      },
      cancel() {
        this.procedure = null;
      },
      
      dataChanged() {
        this.isDataChanged = true;
      },
      rangeColor( value ) {
        if( value < 70 ) {
          return 'background: rgba(0,0,192,.6);color:yellow;font-weight:100;';
        }
        if( value > 84 ) {
          return 'background: rgba(192,0,0,.6);color:white;font-weight:900;'
        }
        if( value > 78 ) {
          return 'background: rgba(192,96,0,.6);color:white;font-weight:900;'
        }
        return 'background: rgba(0,192,0,.6);color:white;font-weight:100;'
      }
    }
  }

</script>
<style lang="scss" >


  .sensor-card {
    display:inline-block;
    height:100px;
    width:100px;
    margin:4px;
    border-radius:25%;
    overflow:hidden;
  }

  .sensor-card-temperature {
    background: rgba(64,64,32,.6);
  }

  .sensor-card-room-number {
    text-align:center;
    padding:2px 0 0 0;
    margin:0;
    color:azure;
    font-size:14px;
  }

  .sensor-card-name {
    text-align:center;
    padding:0;
    margin:0;
    color:white;
    font-size:16px;
  }

  .sensor-card-value {
    text-align:center;
    font-size: 36px;
  }

  .sensor-card-value:after {
     content:"°";
  }



</style>
