<template>
  <div>
    <div v-if="!accountClass">
      <b-button class="btn btn-sm btn-fill btn-success" style="float:left;margin-right:.5em" @click="done()">Done</b-button>
      <i class="fa fa-plus-square show-hand float-left" style="margin-top:5px;font-size:1.5em;margin-right:.5em;" v-b-popover.hover.top='"Add New"' @click="add()"></i>
      <h4 style="margin-top:0;">Account Profile</h4>
      <div>
        <b-table v-if="accountClasses" striped hover head-variant="dark" stacked="md" sort-by="accountClassId" :items="accountClasses" :fields="columns" @row-clicked="edit($event)" >
          <template v-slot:cell(action)="row">
            <i class="fa fa-trash show-hand" @click.stop="removeAccountClass(row.item.accountClassId)" v-b-popover.hover.right="'Remove Record'"></i>
            <i class="fa fa-copy"  style="margin-left:.5em" @click.stop="clone(row.item)" v-b-popover.hover.right="'Clone Record'"></i>
            <i class="fa fa-address-card"  v-if="row && row.item && row.item.options && row.item.options.indexOf('manual-add')>=0 " style="color:forestgreen;margin-left:1em" v-b-popover.hover.right="'Allows Manual Add'"></i>
          </template>
          <template v-slot:cell(accountClassId)="row">
              <div class="show-hand" :class="row.item.notes ? 'font-weight-bold' : ''"  v-b-tooltip.hover placement="righttop" :title="row.item.notes">{{row.item.accountClassId}}</div>
          </template>
        </b-table>

      </div>
    </div>


    <div v-if="accountClass">
      <!--<b-form-group horizontal label-cols-sm="3" breakpoint="sm" label="Account Profile Information" label-size="sm" label-class="font-weight-bold pt-0" class="mb-0">-->
      <div style="float:left;margin-right:1em;">
        <b-btn v-if="isOk()" size="sm" class="btn-fill btn-sm" variant="success" @click="save()">
          {{ (isDataChanged) ? 'Save' : 'Done' }}
        </b-btn>
        <b-btn v-if="isDataChanged" class="btn-fill btn-sm ml-3" variant="danger" @click="cancelEdit()">
          Cancel
        </b-btn>
      </div>
      <h4 style="margin-top:0;">{{accountClass.title}}<span style="font-weight:100;font-size:.8em;margin-left:.5em;">({{accountClass.accountClassId}})</span></h4>

      <b-tabs style="min-height:500px;" class="tab-hover" content-class="mt-4" active-nav-item-class="selected-tab">
        <b-tab title="General">
          <b-form @input="dataChanged()" >
            <sf-input id="accountClassId" :readonly="!canEditId && accountClass.title.length>0" type="text" label="ID" cstyle="width:8em;" v-model="accountClass.accountClassId" required placeholder="Account Profile ID"></sf-input>
            <div v-if="!isOk()" style="text-align:center;color:red;font-size:.9em;">This ID already exists</div>
            <sf-input id="title" type="text" cstyle="width:15em;" label="Title" v-model="accountClass.title" required placeholder="Title"></sf-input>
            <sf-input id="badgeTitle" type="text" cstyle="width:15em;" label="Badge Title" v-model="accountClass.badgeTitle" required placeholder="Badge Title" :helpText="'What do you call users under this account'"></sf-input>
            <sf-input id="accountType" type="list" cstyle="width:15em;" label="Base Type" v-model="accountClass.accountType" required :options="accountTypes" placeholder="Type"></sf-input>
            <sf-input id="description" type="textarea" label="Notes" :rows="6" v-model="accountClass.notes"  placeholder="Notes"></sf-input>
          </b-form>
        </b-tab>

        <b-tab title="Limits">
          <b-form @input="dataChanged()">
            <h5>Daily Limits</h5>
            <sf-input id="dailyQty" type="comma" label="Qty" cstyle="width:8em;" v-model="accountClass.limits.dailyQty"></sf-input>
            <sf-input id="daily" type="currency" label="Amount" cstyle="width:10em;" v-model="accountClass.limits.daily"></sf-input>
            <hr>

            <h5>Overall Limits</h5>
            <sf-input id="totalQty" type="comma" label="Qty" cstyle="width:8em;" v-model="accountClass.limits.totalQty"></sf-input>
            <sf-input id="total" type="currency" label="Amount" cstyle="width:10em;" v-model="accountClass.limits.total"></sf-input>
          </b-form>
        </b-tab>

        <b-tab title="Search Field List">
<!--          <h4>Fields </h4>-->
<!--          <p>These fields are displayed on the search listing</p>-->
<!--          <div style="margin-left:3em;">-->
<!--            <b-form @input="dataChanged()">-->
<!--              <b-form-checkbox-group style="margin-left:0;" v-model="accountClass.searchListFields" >-->
<!--                <b-form-checkbox v-for="(feature,index) in accountClassSearchListFields" class="col-4" :value="feature.value" :key="index">-->
<!--                  <span style="text-transform: capitalize;">{{feature.text}}</span>-->
<!--                </b-form-checkbox>-->
<!--              </b-form-checkbox-group>-->
<!--            </b-form>-->
<!--          </div>-->

            <b-row @change="dataChanged()">
              <b-col class="col col-md-3">
                <h4 style="margin-top:0;margin-bottom:.5em;border-bottom:1px solid black;">Still Available</h4>


                <ul class="field-list" style="">
                  <li class="show-hand" v-for="(f,index) in accountClassSearchListFields" :key="f.key" @click="addFieldToList( f )">{{f.text}}</li>
                </ul>
              </b-col>
              <b-col class="col col-md-8" style="background:#eee;border-radius:1em;padding:1em;">
                <h4 style="margin-top:0;margin-bottom:0;">Current List Columns</h4>
                <table style="width:100%;">
                  <thead>
                  <tr style="color:white;background:gray;">
                    <td style="text-align:center;padding-right:1em;">Header Title</td>
                    <td></td>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(i,index) in accountClass.searchListFields" :key="index">
                    <td class="report-field-header">{{ searchFieldTitle( index ) }}</td>
                    <td><i class="show-hand fa fa-trash" @click="removeFieldFromList(index)"></i></td>
                  </tr>
                  </tbody>
                </table>
              </b-col>
            </b-row>

        </b-tab>

        <b-tab title="Features">
          <h4>Supported Features</h4>
          <div style="margin-left:3em;">
            <b-form @input="dataChanged()">
              <b-form-checkbox-group style="margin-left:0;" v-model="accountClass.features" >
                <b-form-checkbox v-for="(feature,index) in accountClassFeatureList" class="col-4" :value="feature.value" :key="index">
                  <span style="text-transform: capitalize;">{{feature.text}}</span>
                </b-form-checkbox>
              </b-form-checkbox-group>
            </b-form>
          </div>
        </b-tab>

        <b-tab title="Options">
          <h4>Profile Options</h4>
          <div style="margin-left:3em;">
            <b-form @input="dataChanged()">
              <b-form-checkbox-group style="margin-left:0;" v-model="accountClass.options" >
                <b-form-checkbox v-for="(feature,index) in accountClassOptions" class="col-6" :value="feature.value" :key="index">
                  <span style="text-transform: capitalize;">{{feature.text}}</span>
                </b-form-checkbox>
              </b-form-checkbox-group>
            </b-form>
          </div>
        </b-tab>

        <b-tab v-if="hasOption('manual-add')" title="Setup Information" >
          <h4>New Account Presets</h4>
          <p style="margin:0 0 0 2em;width:50%;font-size:.8em;font-color:gray;">Provide some details for each of the account profiles to make it easier for manual adding of accounts.  This page is only displayed for those account profiles which have the "allow manual" feature enabled.</p>
          <hr />
          <b-tabs vertical card class="tab-hover-left" v-model="selectedSubTab" content-class="mt-2 ml-3 mr-3" active-nav-item-class="selected-tab-left">
            <b-tab title="General">
              <div style="width:100%;">
                <b-form @input="dataChanged()" >
                  <sf-input id="soTitle" :labelCols="2" type="text" label="Title"  v-model="accountClass.setupOptions.title" placeholder="Title"></sf-input>
                  <!-- <sf-input id="soTitle" :labelCols="2" type="textarea" :rows="8" label="Help Text"  v-model="accountClass.setupOptions.helpText" ></sf-input> -->
                  <!-- <code-mirror v-model="accountClass.setupOptions.helpText" :options="codeMirrorOptions"></code-mirror> -->
                </b-form>
                <br/>
                <h4>Help Text</h4>
                <b-row>
                  <b-col class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                    <div class="width:100%;height:100%;">
                      <!-- <vue-simplemde   v-model="accountClass.setupOptions.helpText"  :configs="mdeConfig" :sanitize="false"  ref="markdwonEditor" @input="dataChanged()"/> -->
                      <!-- <vue-simplemde v-model="accountClass.setupOptions.helpText" preview-class="markdown-body" /> -->
                      <sf-input type="textarea" :labelCols="0" :rows="12" v-model="accountClass.setupOptions.helpText" @input="dataChanged()"></sf-input>
                    </div>
                  </b-col>
                  <b-col class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                    <markdown-card style="border:1px solid gray;border-radius:.5em;padding:.5em;"  :text="accountClass.setupOptions.helpText" />
                  </b-col>
                </b-row>
              </div>
            </b-tab>
            <b-tab title="Account">
              <b-form @input="dataChanged()" style="">
                <b-row>
                  <b-col>
                    <b-checkbox v-model="accountClass.setupOptions.isEditAfterAdd">Edit Account After Add</b-checkbox>
                    <b-checkbox v-model="accountClass.setupOptions.allowAccountRange">Allow Account Range</b-checkbox>
                    <b-checkbox v-model="accountClass.setupOptions.allowAutoAccount">Allow AUTO Account</b-checkbox>
                    <b-checkbox v-model="accountClass.setupOptions.forceAutoAccount">FORCE Auto Account</b-checkbox>
                    <b-checkbox v-model="accountClass.setupOptions.accountNumberIncludeCompanyCode">Account Number Includes Company Code</b-checkbox>
                    <b-checkbox v-model="accountClass.setupOptions.askCompanyCode">Ask For Company Code</b-checkbox>
                    <b-checkbox v-model="accountClass.setupOptions.askAccountTitle">Ask For Account Title</b-checkbox>
                  </b-col><b-col>
                    <b-checkbox v-model="accountClass.setupOptions.askGLAccount">Ask For GL Account</b-checkbox>
                    <b-checkbox v-model="accountClass.setupOptions.askJobCode">Ask For Jobcode</b-checkbox>
                    <b-checkbox v-model="accountClass.setupOptions.askArType">Ask For AR Type</b-checkbox>
                    <b-checkbox v-model="accountClass.setupOptions.askPayrollId">Ask For Payroll ID</b-checkbox>
                    <br>
                    <b-checkbox v-model="accountClass.setupOptions.askTotalClass">Ask For Total Class</b-checkbox>
                    <b-checkbox v-model="accountClass.setupOptions.askPosting">Ask For Posting Info</b-checkbox>
                  </b-col>
                  <b-col></b-col>
                </b-row>
                <hr />
                <b-row>
                  <b-col class="col-6">
                    <h4>Prompts</h4>
                    <sf-input type="text" :labelCols="4" v-model="accountClass.setupOptions.accountNumberPrompt" label="Account # Prompt" ></sf-input>
                    <sf-input cstyle="width:5em;" type="text" :labelCols="4" v-model.trim="accountClass.setupOptions.companyCodeSeparator" label="Company Sep Char" v-if="accountClass.setupOptions.accountNumberIncludeCompanyCode"></sf-input>
                    <sf-input type="text" :labelCols="4" v-model="accountClass.setupOptions.companyCodePrompt" label="Company Code" v-if="accountClass.setupOptions.askCompanyCode"></sf-input>
                    <sf-input type="text" :labelCols="4" v-model="accountClass.setupOptions.accountTitlePrompt" label="Account Title" v-if="accountClass.setupOptions.askAccountTitle"></sf-input>

                    <sf-input type="text" :labelCols="4" v-model="accountClass.setupOptions.glAccountPrompt" label="GL Account" v-if="accountClass.setupOptions.askGLAccount"></sf-input>
                    <sf-input type="text" :labelCols="4" v-model="accountClass.setupOptions.jobCodePrompt" label="Jobcode" v-if="accountClass.setupOptions.askJobCode"></sf-input>
                    <sf-input type="text" :labelCols="4" v-model="accountClass.setupOptions.arTypePrompt" label="AR Type" v-if="accountClass.setupOptions.askArType"></sf-input>
                    <sf-input type="text" :labelCols="4" v-model="accountClass.setupOptions.payrollIdPrompt" label="Payroll ID" v-if="accountClass.setupOptions.askPayrollId"></sf-input>
                  </b-col>
                  <b-col></b-col>
                </b-row>
              </b-form>
            </b-tab>
            <b-tab title="Badge/Card">
              <p>If you do not provde any badge details, the account info is copied into these fields.  Gift cards, for example, don't need badge details.</p>
              <b-form @input="dataChanged()" style="">
                <div>
                  <b-checkbox v-model="accountClass.setupOptions.askBadgeNumber">Ask For Badge #</b-checkbox>
                  <b-checkbox v-model="accountClass.setupOptions.askFirstName">Ask For First Name</b-checkbox>
                  <b-checkbox v-model="accountClass.setupOptions.askLastName">Ask For Last Name</b-checkbox>
                  <b-checkbox v-model="accountClass.setupOptions.askBadgeAlias">Ask For Badge Alias</b-checkbox>
                </div>
                <hr />
                <sf-input type="text" :labelCols="3" v-model="accountClass.setupOptions.badgeNumberPrompt" label="Badge # Prompt" v-if="accountClass.setupOptions.askBadgeNumber"></sf-input>
                <sf-input type="text" :labelCols="3" v-model="accountClass.setupOptions.firstNamePrompt" label="First Name Prompt" v-if="accountClass.setupOptions.askFirstName"></sf-input>
                <sf-input type="text" :labelCols="3" v-model="accountClass.setupOptions.lastNamePrompt" label="Last Name Prompt" v-if="accountClass.setupOptions.askLastName"></sf-input>
                <sf-input type="text" :labelCols="3" v-model="accountClass.setupOptions.badgeAliasPrompt" label="Badge Alias Prompt" v-if="accountClass.setupOptions.askBadgeAlias"></sf-input>
              </b-form>

            </b-tab>
            <b-tab title="Totals" @click="getTotalClasses()">
<!--                {{accountClass.setupOptions.allowedTotalClasses}}-->
              <b-form @input="dataChanged()" style="">
                <p>If a total profile is set, and ASK is NOT set, the value is simply created without prompting.  (the recommended way to set this up)</p>
                <div>
                  <b-checkbox v-model="accountClass.setupOptions.askTotalClass">Ask For Total Profile</b-checkbox>
                </div>
                <hr />
                <sf-input type="text" :labelCols="3" v-model="accountClass.setupOptions.totalClassPrompt" label="Ask Total Profile Prompt" v-if="accountClass.setupOptions.askTotalClass"></sf-input>
                <list-array-from-dropdown :title="((accountClass.setupOptions.askTotalClass) ? 'Allowed' : 'Adding') +  ' Total Profiles'" unique numeric :options="totalClassList" v-model="accountClass.setupOptions.allowedTotalClasses" @change="dataChanged()">
                </list-array-from-dropdown>
                <div v-if="accountClass.setupOptions.allowedTotalClasses.length == 0" style="color:red;font-weight:900;font-size:1.5em;">NO Account Classes Assigned<br><span style="font-size:.8em;">Don't forget to press ADD</span></div>
              </b-form>

            </b-tab>
            <b-tab v-if="accountClass.setupOptions.askPosting" title="Posting">
              <b-form @input="dataChanged()" style="">
                <div>
                  <b-checkbox v-model="accountClass.setupOptions.askTenderAmount">Ask For Tender $</b-checkbox>
                  <b-checkbox v-if="accountClass.setupOptions.askTenderAmount" v-model="accountClass.setupOptions.postIsPayment">Post As Payment</b-checkbox>
                </div>
                <hr />
                <sf-input type="text" :labelCols="3" v-model="accountClass.setupOptions.initialValuePrompt" label="Initial Value Prompt" ></sf-input>
                <hr/>
                <p>Look up these numbers in the admin section</p>
                <sf-input type="list" :options="accountClass.setupOptions.allowedTotalClasses" cstyle="width:12em;" :labelCols="3" v-model="accountClass.setupOptions.postTotalClassId" label="Total Class Id" ></sf-input>
                <sf-input type="number" cstyle="width:12em;" :labelCols="3" v-model="accountClass.setupOptions.postRevenueCenter" label="Revenue Center" ></sf-input>

                <sf-input type="number" cstyle="width:12em;" :labelCols="3" v-model="accountClass.setupOptions.postTenderNumber" label="Tender Number" ></sf-input>
                <sf-input type="number" cstyle="width:12em;" :labelCols="3" v-model="accountClass.setupOptions.postTenderAmount" label="Tender Amount" ></sf-input>

              </b-form>
            </b-tab>
            <!-- <b-tab title="Payment">
            </b-tab>
            <b-tab title="Account Template">
              <b-form style="height:200px;width:100%;border:1px solid gray;" @change="dataChanged()">
                <code-editor style="min-height:100%;min-width:100%;" v-model="accountClass.setupOptions.accountTemplate"  />
              </b-form>
            </b-tab>
            <b-tab title="Badge Template" >
              <b-form style="height:200px;width:100%;border:1px solid gray;" @change="dataChanged()">
                <code-editor v-model="accountClass.setupOptions.badgeTemplate" />
              </b-form>
            </b-tab>
            <b-tab title="XREF Template" >
              <b-form style="height:200px;width:100%;border:1px solid gray;" @change="dataChanged()">
                <code-editor v-model="accountClass.setupOptions.xref" />
              </b-form>
            </b-tab> -->
          </b-tabs>
        </b-tab>

      </b-tabs>

    </div>

  </div>
</template>

<script>

  import LTable from "/src/components/UIComponents/Table.vue";
  import TenantService from "/src/Services/TenantService";
  import AccountService from "/src/Services/AccountService";
  import TotalService from "/src/Services/TotalService";
  import Card from "/src/components/UIComponents/Cards/Card.vue";
  import ListCardEx from "/src/components/UIComponents/Cards/ListCardEx.vue";
  import MarkdownCard from "/src/components/UIComponents/Cards/MarkdownCard.vue";
  import CodeEditor from "/src/components/UIComponents/Cards/CodeEditorCard.vue";
  import SfInput from "/src/components/UIComponents/Inputs/SFormInput.vue";
  import ListArrayFromDropdown from "/src/components/UIComponents/Inputs/ListArrayFromDropdown.vue";
  // import CodeMirror from "codemirror";
  // import Marked from "marked";
  // import VueSimplemde from 'vue-simplemde';
  // import 'simplemde/dist/simplemde.min.css';
  import hljs from 'highlight.js';

  window.hljs = hljs;

export default {
  components: {
    LTable,
    Card,
    SfInput,
    CodeEditor,
    ListCardEx,
    // VueSimplemde,
    MarkdownCard,
    ListArrayFromDropdown
  },
  data() {
    return {
      isDataChanged : false,
      canEditId     : false,
      small         : true,
      accountTypes  : null,
      selectedSubTab: 0,
      totalClassList  : null,
      mdeConfig: {
        status      : true,
        spellChecker: true,
        mode        : "markdown"
      },
      xmdeConfig     : {
        tabSize          : 2,
        styleActiveLine  : true,
        lineNumbers      : true,
        line             : true,
        foldGutter       : true,
        styleSelectedText: true,
        toolbar          : ['bold', 'italic', 'heading', '|', 'quote'],
        // mode             : 'text/javascript',
        // keyMap                 : "sublime",
        matchBrackets          : false,
        showCursorWhenSelecting: true,
        theme                  : "monokai",
        extraKeys              : { "Ctrl": "autocomplete" },
        hintOptions            : {
          completeSingle: false
        }
      },
      accountClassOptions: [
        { value: "manual-add", text: "Allow Manual Account Add" },
        { value: "add-total", text: "Allow Add Totals In Account Edit" },
        { value: "show-summary", text: "Show on Summary Payne" },
        { value: "allow-search", text: "Allow Search In Account Search Window" },
        { value: "allow-list-edit", text: "Allow List Edit (future)" },
        { value: "disable-show-transactions", text: "Disable Initial Transaction Listing" },
        { value: "create-directory", text: "Enable 'Create Directory' Feature" },
      ],
      accountClassSearchListFields: [
        { value: "active" , text: "Status Flags" },
        { value: "accountClassId", text: "Account Profile Id" },
        { value: "accountNumber", text: "Account Number" },
        { value: "accountTitle", text: "Account Title" },
        { value: "accountType", text: "Account Type" },
        { value: "alias", text: "Badge Alias" },
        { value: "badgeNumber", text: "Badge Number" },
        { value: "firstName", text: "First Name" },
        { value: "lastName", text: "Last Name" },
        { value: "lastSeen.when", text: "When Last Seen" },
        { value: "lastSeen.where", text: "Where Last Seen" },
        { value: "lastSeen.who", text: "Who Last Seen" },
        { value: "roomNumber", text: "Room Number" },
        { value: "updatedAt", text: "Last Update" },
      ],
      accountClassFeatureList: [
        { value: "accounting", text: "Enable Accounting Fields" },
        { value: "account-address", text: "Show Account Address" },
        { value: "ar", text: "Enable AR Accounting" },
        { value: "account-details", text: "Show Account Details" },
        { value: "certifications", text: "Enable Certification Features" },
        { value: "dates", text: "Extra Date Management" },
        { value: "transaction-history", text: "Enable Transaction History" },
        { value: "order-history", text: "Enable Order History" },
        { value: "assets", text: "Enable Assets" },
        { value: "badges", text: "Show Badges" },
        { value: "social", text: "Enable Social Features (tbd)" },
        { value: "contacts", text: "Reference Contacts" },
        { value: "documents", text: "Documents (tbd)" },
        { value: "notes", text: "Enable Notes" },
        { value: "parking", text: "Parking Management" },
        { value: "pendants", text: "Pendant Management" },
        { value: "room", text: "Enable Rooms" },
        { value: "beds", text: "Show Bed Assignment" },
        { value: "students", text: "Enable Students Features" },
        { value: "tickets", text: "Show Work Orders" },
        { value: "catering", text: "Show Catering Orders" },
        { value: "events", text: "Show Events" },
        { value: "totals", text: "Show Totals & Limits" },
        { value: "portal", text: "Show Portal Information" }
      ],
      accountClass  : null,
      columns     : [
        "action",
        { key: "accountClassId", label: "Profile ID", sortable: true },
        { key: "badgeTitle", label:"Badge Title" , sortable: true },
        { key: "title", label:"Profile Title" , sortable: true }
      ],
      accountClasses: [{}]
    };
  },
  watch: {

  },
  methods: {
    // markdown(d){
    //   return Marked.parse(d);
    // },
    dataChanged() {
      this.isDataChanged = true;
      this.$emit("changed" , true );
    },
    addFieldToList( f ) {
      // console.log( "add" ,  f );
      this.accountClass.searchListFields.push( f.value );
      this.dataChanged();
    },
    removeFieldFromList( f ) {
      // console.log( "remove" ,  f, this.accountClass.searchListFields );
      if( f >= this.accountClass.searchListFields.length ) {
        return;
      }
      this.accountClass.searchListFields.splice(f,1);
      this.dataChanged();
    },
    searchFieldTitle( i ) {
      if( i < this.accountClass.searchListFields.length ) {
        let f = this.accountClass.searchListFields[i];
        let t = _.find( this.accountClassSearchListFields, { value: f });
        if( t ) {
          return t.text;
        }
      }
      return "** " + i.toString();
    },
    async cancelEdit() {
      if( this.isDataChanged ) {
        if( ! await this.$root.confirmYesNo("Data has changed. Are you sure you want to cancel?") ) {
          return;
        }
      }
      this.isDataChanged = false
      this.$emit("changed" , false );
      this.accountClass = null;
      this.canEditId    = false;
    },
    cancel() {
      this.done();
    },
    isOk() {
      if( this.accountClass && this.accountClass.accountClassId ) {
        let count = 0;
        this.accountClasses.forEach(a => {
          if( a.accountClassId == this.accountClass.accountClassId ) {
            count++;
          }
        });
        if( count === 0 ) {
          return true;
        }
        if( count === 1 ) {
          if( this.canEditId ) {
            return false;
          }
          return true;
        }
        return false;
      }
      return true;
    },
    async done() {
      this.$router.go(-1);
    },
    hasOption( o ) {
      if( this.accountClass ) {
        if( this.accountClass.options.indexOf( o ) > -1 ) {
          return true;
        }
      }
      return false;

    },
    makeNewAccountClass() {
      let t=this;
      t.canEditId = true;
      t.accountClass = {
        accountClassId: 0,
        title         : "",
        accountType   : "user",
        notes         : "",
        features      : [],
        options       : [],
        limits        : {
          dailyQty: 0,
          daily   : 0,
          totalQty: 0,
          total   : 0,
        },
        setupOptions: {}
      }
    },
    async getTotalClasses() {
      let t = this;

      if( t.totalClassList ) {
        return;
      }

      let response = await TotalService.getTotals();
// console.log( "got back" , response.data );
      t.totalClassList = response.data.map(t => {
        return {
          value: t.totalClassId,
          text: t.totalClassId + ":" + t.title
        }
      });

// console.log("Getting total classes", t.totalClassList );

    },
    async clone( accountClass ) {
      let t=this;

      if( ! await t.$root.confirmYesNo( "Clone Account Class?" )) {
        return;
      }

      let ac = Object.assign({}, accountClass );
      while( _.findIndex( t.accountClasses, { accountClassId: ac.accountClassId }) > -1 ) {
        ac.accountClassId++;
      }
      ac.title = `${ac.title} (Clone)`;
      t.accountClass = ac;
      t.canEditId = true;
      t.dataChanged();
    },
    add() {
      var t = this;
      t.makeNewAccountClass();
    },
    edit( accountClass ) {
      var t              = this;
          t.canEditId    = false;
          t.accountClass = Object.assign({},accountClass);
    },
    async removeAccountClass(id) {
      let t = this;

      if( ! await t.$root.confirmYesNo( "Remove " + id + "?")){
        return;
      };

      this.accountClasses.forEach(function(item, index) {
        if (item.accountClassId === id) {
          t.accountClasses.splice(index, 1);
        }
      });
    },
    async getAccountClasses() {
      var t = this;
      var response;
      try {
        response = await TenantService.getAccountClasses();
        this.accountClasses = response.data;
      } catch (err) {
        console.log("getTenant error: ", err);
        if (err.response) {
          if (err.response.data) {
            t.$root.errorMessage("Oops", err.response.data.message);
          }
        } else {
          t.$root.errorMessage("Oops", "Had an error getting the Account Profiles");
        }
      }
    },
    async getAccountTypes() {
      try {
        let response = await AccountService.getAccountTypes();
        this.accountTypes = response.data;
      } catch (error) {
        console.log( error )
      }
    },
    async save() {
      var t = this;
      var response;

      if( !t.isDataChanged ) {
        t.accountClass = null;
        return;
      }

      let i = _.findIndex(t.accountClasses, { accountClassId: t.accountClass.accountClassId });

      if( i >= 0 ) {
        t.accountClasses[i] = Object.assign({},t.accountClass);
      } else {
        t.accountClasses.push( t.accountClass );
      }

      try {

        // a little sort before we save since they would be stored in raw order otherwise and mess up the UI
        this.accountClasses.sort((a,b) => (parseInt(a.accountClassId) > parseInt(b.accountClassId)) ? 1 : ((parseInt(b.accountClassId) > parseInt(a.accountClassId)) ? -1 : 0));

        const ac = { accountClass: this.accountClasses};

        response                        = await TenantService.saveTenant(ac);
        t.$root.accountClasses          = response.data.accountClass;
        t.$root.tenant.accountClassList = response.data.accountClass;
        t.accountClasses                = response.data.accountClass;
        t.isDataChanged                 = false;
        t.accountClass                  = null;

// console.log( "save response: ", response.data );

        this.$emit( "changed" , false );
        t.$root.successMessage("SUCCESS", "Account Profiles Updated");

      } catch (err) {
        if (err.response) {
          console.log("error response ", err.response);
          t.$root.errorMessage("Oops", err.response.data.message);
        } else if (err.request) {
          console.log("Error Request ", err.request);
        } else {
          console.log("message only ", err.message);
        }
        t.$root.errorMessage("Oops", "Unable to Save Account Profile Data");
      }
    }
  },
  async mounted() {
    Promise.all([
      this.getAccountClasses(),
      this.getAccountTypes(),
      this.getTotalClasses()
    ])
  },

};
</script>

<style lang="scss" >

  //  @import '~simplemde/dist/simplemde.min.css';
  // @import '~simplemde/dist/simplemde.min.css';
  // @import '~github-markdown-css';


  .edit-error {
    background: red;
  }

</style>
