<template>
  <div class="content">
    <div class="container-fluid">
      <card class="shadow">
        <template slot="header">
          <h3 class="card-title">
            <i class="fa fa-plus-square show-hand mr-3" @click="addTask()"></i>Tasks
            <small>(visible to all team members)</small>
            <button class="float-left mr-3 btn btn-success btn-sm btn-fill" style="margin-top:-4px;" @click="$router.push('/app');">Done</button>
          </h3>
          <div class="" style="margin-top:0; margin-bottom:0;">
            <b-checkbox style="position:absolute;right:30px;top:20px;" v-model="isShowCompleted" @change="getTasks(currentCategory)">Show Completed</b-checkbox>
          </div>
          <!--          <b-button class="btn btn-success btn-round btn-sm btn-fill float-right mr-4" @click="addTask()"> Add New Task</b-button>-->
          <hr>
        </template>
        <template v-if="taskCategories.length>0">

          <div style="margin:-1em 0 0 1em;">
            <h4 class="card-title" style="margin-top:0;margin-bottom:0;">Categories</h4>
            <b-button class="btn btn-sm btn-fill hover ml-1" :style="(category===currentCategory) ? 'background:#58aa00;font-weight:600;color:white;' : ''" style="background:#bbb;border-radius:1em;margin-right:.5em;font-weight:100;" v-for="(category,index) in taskCategories" :key="index" @click="getTasks( category )">
              {{category}}
            </b-button>
          </div>
        </template>
        <div style="margin-top:1em;" >
<!--          <h4>Tasks for: {{currentCategory}}</h4>-->
          <!-- <task-list class="mt-2"
            :tasks="tasks"
            @complete="complete($event)"
            @incomplete="incomplete($event)"
            @edit="editTask( $event )"
          /> -->
           <b-table sticky-header	striped hover head-variant="dark" sort-by="title" small 
                    id="task-list-table"
                    :tbody-transition-props="transProps"
                    primary-key="_id"
                    :busy="loading"
                    :items="tasks"
                    :fields="fieldList"
                    @row-clicked="editTask( $event )">
            
            <template #table-busy>
              <div class="w-100 text-center text-danger my-5" >
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
            <template v-slot:cell(actions)="row">
              <div style="width:30px;" v-if="!row.item.isComplete" @click.stop="complete(row.item)"><i class="fa fa-square-o"></i></div><div v-else @click.stop="incomplete(row.item)"><i class="fa fa-check-square-o" style="color:green;"></i></div>
            </template>
            <template v-slot:cell(dueDate)="row">
              <div :style="dueDateStyle(row.item)">
              {{(row.item.dueDate) ? dateOnly(row.item.dueDate) : '' }}
              </div>
            </template>
          </b-table>
        </div>
      </card>

      <b-modal
        ref="taskDialog"
        :title="taskTitle"
        size="lg"
        :header-bg-variant="headerBgVariant"
        :header-text-variant="headerTextVariant"
        :body-bg-variant="bodyBgVariant"
        :body-text-variant="bodyTextVariant"
        :footer-bg-variant="footerBgVariant"
        :footer-text-variant="footerTextVariant"
      >
        <b-container fluid>
          <div style="position:relative;display:block;padding-left:2em;">
            <b-checkbox  v-model="task.isComplete"> Complete</b-checkbox>
          </div>
          <!-- <sf-input type="checkbox" cstyle="position:absolute;right:50px;" v-model="task.isComplete"> Complete</sf-input> -->
          <div>
            <b-checkbox style="position:relative;top:34px;left:600px;" v-model="isAddCategory"> Add Category</b-checkbox>
            <sf-input :disabled="task.isComplete" v-if="!isAddCategory" :labelCols="2" cstyle="width:15em;" v-model="task.category" type="list" :options="taskCategories" label="Category" />
            <sf-input :readonly="task.isComplete" v-if="isAddCategory" :labelCols="2" cstyle="width:15em;background:#bbffbb;" v-model="task.category" type="text" label="Category" />
          </div>
          <sf-input type="date" cstyle="width:15em;" :readonly="task.isComplete" v-model="task.dueDate" :labelCols="2" label="Required By" />
          <sf-input :readonly="task.isComplete" v-model="task.title" :labelCols="2" type="text" label="Title" />
          <sf-input :readonly="task.isComplete" id="textarea" :labelCols="2" v-model="task.description" type="textarea" placeholder="Details..." :rows="10" label="Details" />

        </b-container>
        <div slot="modal-footer" class="mt-4 w-100">
          <b-btn variant="warning" size="sm" class="btn btn-fill mr-2" style="border-radius:.75em;" @click="hideDialog()">Cancel</b-btn>
          <b-btn variant="success" size="sm" class="btn btn-fill mr-2" style="border-radius:.75em;" @click="saveTask()">Save/Done</b-btn>
          <div style="float:right;">
            <b-btn variant="error" size="sm" class="btn btn-fill mr-2" style="border-radius:.75em;" @click="deleteTask(task)">Delete</b-btn>
          </div>
        </div>
      </b-modal>

    </div>
  </div>
</template>
<script>
  import TaskService from "/src/Services/TaskService";
  // import Lib         from "/src/Lib/rootLib";
  import Moment      from "moment-timezone";
  import Card        from "/src/components/UIComponents/Cards/Card.vue";
  import TaskList    from "/src/components/UIComponents/Cards/TaskList.vue";
  import SfInput     from "/src/components/UIComponents/Inputs/SFormInput.vue";

  export default {
    components: {
      Card,
      SfInput,
      TaskList
    },
    data() {
      return {
        variants         : [ 'primary', 'secondary', 'success', 'warning', 'danger', 'info', 'light', 'dark' ],
        headerBgVariant  : 'dark',
        headerTextVariant: 'light',
        bodyBgVariant    : 'light',
        bodyTextVariant  : 'dark',
        footerBgVariant  : 'warning',
        footerTextVariant: 'dark',
        isAddTask        : false,
        taskErrorMessage : "",
        tasks            : [],
        taskTemplate     : {
          category   : "TODO",
          title      : "",
          description: ""
        },
        newTask          : {},
        task             : {category: "", title: "", dueDate: "", description: "", isComplete: false},
        transProps: {
          name: 'flip-list'
        },
        fieldList           : [
          {key:"actions",label: "Complete", sortable: true },
          {key: 'dueDate', label: "Required By", sortable: true},
          {key: 'title', label: "Title", sortable: true}
        ],
        isShowCompleted: false,
        isAddCategory  : false,
        taskCategories : [],
        currentCategory: "",
        loading        : false,
        isLoading      : "fa fa-spinner  fa-refresh-animate mr-3",
        type           : [ '', 'info', 'success', 'warning', 'danger' ],
        notifications  : {
          topCenter: false
        }
      }
    },
    async created() {
      // await Promise.all([
                          await this.getTaskCategories();
                          await this.getTasks( this.taskCategories[0]|| "TODO" );
                        // ]);

    },
    computed  : {
      taskTitle() {
        return ((this.isAddTask) ? "Adding Task" : "Editing Task");
      }
    },
    methods   : {
      dateOnly( d ) {
        if( d.indexOf( "Z" ) > 0 )  {
          return Moment( d.substring(0,10)).tz(this.$root.TZ).format("YYYY-MM-DD")
        }
        return d;
      },
      moment(d) {
        return Moment(d);
      },
      dueDateStyle( t) {
        if( t.isComplete ) {
          return "width:100%;background:black;color:white;border-radius:1em;font-weight:100;padding:.1em .75em 0 .75em;";
        }
        let d = t.dueDate;
        if(!d ||  Moment(d).tz(this.$root.TZ).startOf('day') >= Moment().subtract(0,'days')) {
          return "width:100%;border-radius:1em;padding:0 .75em 0 .75em;";
        }
        let days = Moment().day() - Moment(d).day();
        if( days < 2 ) {
          return "width:100%;border-radius:1em;background:orange;padding:.1em .75em 0 .75em;";          
        } 
        return "width:100%;border-radius:1em;background:red;padding:.1em .75em 0 .75em;";
      },
      taskRowClass(item, type) {
        if(!item) {
          return;
        }
        if(item.active === false) {
          return "table-warning";
        }
      },
      async editTask(task) {
        let response = await TaskService.getSimpleTask( task._id );
        this.task = response.data;
        this.$refs.taskDialog.show();
      },
      addTask() {
        this.isAddTask     = true;
        this.task          = Object.assign({}, this.taskTemplate);
        this.task.category = this.currentCategory;
        this.$refs.taskDialog.show();
      },
      hideDialog() {
        this.isAddTask = false;
        this.$refs.taskDialog.hide();
      },
      async complete(task) {
        task.isComplete = true;
        await TaskService.markComplete(task._id);
      },
      async incomplete(task) {
        task.isComplete = false;
        await TaskService.markIncomplete(task._id);
      },
      async getTaskCategories() {
        var t     = this;
        t.loading = true;
        try {
          let response     = await TaskService.getSimpleTaskCategories();
          t.taskCategories = response.data;
        } catch(err) {
          if(err) {
            console.error(err);
            t.$root.errorMessage("Oops", err.response.data.message);
          }
        }
        t.loading = false;
      },
      async getTasks(category) {
        var t             = this;
        t.loading         = true;
        t.currentCategory = category;
        try {
          let response = await TaskService.getSimpleTasks(category, this.isShowCompleted);
          t.tasks      = response.data;
        } catch(err) {
          if(err) {
            console.error(err);
            t.$root.errorMessage("Oops", err.response.data.message);
          }
        }
        t.loading = false;
      },
      async deleteTask(task) {
        var response;
        var t = this;

        if(!confirm("Remove " + task.title + "?")) {
          return
        }
        t.loading = true;
        try {
          response = await TaskService.deleteSimpleTask(task._id);
          await Promise.all([
                              t.getTasks(t.currentCategory),
                              t.getTaskCategories()
                            ])
          t.loading = false;
          t.hideDialog();
        } catch(err) {
          if(err) {
            console.error(err);
            t.$root.errorMessage("Oops", err.response.data.message);
          }
          t.loading = false;
        }
      },
      async saveTask() {
        let response;
        let t        = this;
        let category = t.task.category;

        t.loading = true;

        try {
          if(t.isAddTask) {
            response = await TaskService.postSimpleTask(t.task);
            await t.getTaskCategories();

            if( t.tasks.length == 0 ) {
              t.tasks.push(response.data);
            } else if( t.tasks[0].category === category ) {
              t.tasks.push(response.data);
            } else {
              t.getTasks( category );
            }
            t.task = Object.assign({}, this.taskTemplate);
          } else {
            response = await TaskService.updateSimpleTask(this.task);        // save the whole list.
            await t.getTaskCategories();
            if( t.task.category !== t.currentcategory) {
              t.getTasks( category );
            }
          }
          t.isAddCategory = false;    // always turn this off after save.
          t.loading = false;
          this.hideDialog();
        } catch(err) {
          if(err) {
            console.error(err);
            t.$root.errorMessage("Oops", err.response.data.message);
          }
          t.loading = false;
        }
      }
    }
  }

</script>
<style lang="scss" >
  table.b-table[aria-busy='true'] {
    opacity: 0.6;
  }
  table#task-list-table .flip-list-move {
    transition: transform 1s;
  }
  // tr :nth-child(1){
  //   // background:lime;
  //   width:70px;
  // }
  // tr :nth-child(2){
  //   width:30px;
  // }
</style>
