<template>
  <div class="content">
    <div class="container-fluid">

      <b-modal v-show="newItem !== null" v-model="isAddItem"
               size="lg"
               header-bg-variant="warning"
               header-text-variant="light"
               footer-bg-variant="default"
               footer-text-variant="light"
               @shown="focusOnItemTitle"
      >
        <div slot="modal-header" class="mb-4">
          <h2 class="card-title">Add Menu Item</h2>
        </div>
        <b-container fluid>
          <sf-input cstyle="width:15em;" v-model="newItem.itemNumber" type="number" label="Number" />
          <sf-input cstyle="width:20em;" ref="itemTitle" v-model="newItem.title" type="text" label="Name" placeholder="Menu Item Name" @keyup.enter.native="addMenuItem()" />
          <sf-input cstyle="width:20em;" readonly v-model="newItem.category" type="text" label="Category" placeholder="Menu Item Category" />
        </b-container>
        <div slot="modal-footer" class="w-100 mt-3">
          <b-btn size="sm" class="float-right btn-fill" variant="success" @click="addMenuItem()">
            Add Item
          </b-btn>
          <b-btn size="sm" class="float-right btn-fill" variant="danger" @click="isAddItem=false">
            Cancel
          </b-btn>

        </div>
      </b-modal>

      <b-modal v-model="upcLookup"
               size="xl"
               header-bg-variant="warning"
               header-text-variant="light"
               footer-bg-variant="default"
               footer-text-variant="light"
      >
        <div slot="modal-header" class="mb-4">
          <h2 class="card-title"><i class="fa fa-barcode mr-4"></i>UPC Lookup</h2>
        </div>
        <b-row>
          <b-col class="col-8">
            <b-container fluid>
              <b-btn size="sm" class="float-right btn-fill" variant="danger" @click="upcLookup=false">
                Cancel
              </b-btn>
              <b-btn size="sm" class="float-right btn-fill mr-2" variant="success" @click="searchUPC()">
                Search
              </b-btn>

              <sf-input  cstyle="width:15em;" :appendIcon="'fa fa-times'" v-model="upcCode" type="icon-text" label="Search UPC" @keyup.enter.native="searchUPC()" @appendClick="upcItems=[];upcCode=''"/>
              <b-table striped hover :items="upcItems" :fields="upcFieldList" @row-clicked="selectUpcItem">
              </b-table>
              <p v-if="upcItems && upcItems.length > 1" style="color:red;font-weight:600;text-align:center;">+1 item connected -- Might want to verify that this is legit.</p>
              <p style="color:red;font-weight:600;text-align:center;">{{ upcMessage }}</p>
            </b-container>
          </b-col>
          <b-col class="col-4" style="border-left:5px solid lightgray;">
            <h4>Search History</h4>
            <div style="max-height:400px;height:400px;overflow:auto;">
              <table>
                <tr v-for="(hist,index) in upcHistory" :key="index">
                  <td class="show-hand" @click="searchUPC(hist)">{{ hist }}</td>
                </tr>
              </table>
            </div>
          </b-col>
        </b-row>

        <div slot="modal-footer" class="w-100 mt-3">
          <b-btn size="sm" class="float-right btn-fill" variant="danger" @click="upcLookup=false">
            Cancel
          </b-btn>
        </div>
      </b-modal>


      <!-----------------------------------------------------------------------------------[ END MODAL ]------------->

      <div v-show="isShowList">
        <div ref="item-master-list">
          <button class="float-left mr-3 btn btn-success  btn-sm btn-fill noprint" @click="hideList">Done</button>
          <button class="float-left mr-3 btn btn-warning  btn-sm btn-fill noprint" @click="printList">Print List</button>
          <h4>Item Master List</h4>
          <div class="card-body" style="overflow:auto;">
            <b-table :items="menuItemListSearch" :fields="listFields" @row-clicked="setCurrentItem">

              <template v-slot:cell(itemNumber)="data">
                <span style="text-align:right;">{{ data.item.itemNumber }}</span>
              </template>

              <template v-slot:cell(title)="data">
                <span style="font-weight:600;">{{ data.item.title }}</span>
                <span style="display:flex;white-space:pre-line;padding-left:.5em;font-size:.9em;">{{ data.item.description }}</span>
              </template>

              <template v-slot:cell(POSName)="data">
                <div style="text-align:left;">{{ data.item.POSName }}</div>
                <div style="text-align:left;">{{ data.item.POSName2 }}</div>
              </template>

              <template v-slot:cell(price[0])="data">
                <div style="text-align:right;">{{ currency(data.item.price[ 0 ]).toString() }}</div>
              </template>

              <template v-slot:cell(price[1])="data">
                <div style="text-align:right;">{{ currency(data.item.price[ 1 ]).toString() }}</div>
              </template>

            </b-table>
          </div>
        </div>
      </div>


      <div v-show="! isShowList">
        <!-- <template slot="header"> -->
        <!-- <button class="float-left mr-3 btn btn-success  btn-sm btn-fill" @click="$router.push('/app');"><span v-show="loading" :class="isLoading"></span>Done</button> -->
        <div style="float:left;position:relative;top:0;font-size:1.5em;width:30px;">
          <i v-show="!loading" class="fa fa-list show-hand" v-b-popover.hover.top="'Print Item List'" @click="showList"></i>
          <span v-show="loading" :class="isLoading"></span>
        </div>
        <i v-if="dataChanged" class="fa fa-clock-o" style="position:relative;top:4px;margin-left:.5em;float:right;color:orange;"></i>
        <h3 class="card-title">Menu Item Master List <span v-if="isLiveEdit" class="small" style="color:red;">  (live edit)</span><span class="small float-right"><b-checkbox v-model="isLiveEdit">Live Edit</b-checkbox></span>
        </h3>
        <hr>


        <div style="display:inline-block;width:100%;">
          <b-form>
            <sf-input type="list" style="padding:0;" label="Revenue Center" :labelCols="4" cstyle="margin-bottom:.5em;width:20em;border-radius:1em;border:2px solid lightgray" :options="revenueCenterList()" v-model="revenueCenter" @change="selectRevenueCenter(revenueCenter)"></sf-input>
            <div v-if="menuItems && menuItems.length>0" style="" @click="activateUpcSearch()">
              <i class="fa fa-barcode show-hand"  style="font-size:1.5em;" v-b-popover.hover.top="'Lookup item by barcode'" ></i>
            </div>
          </b-form>
        </div>
        <div v-if="revenueCenter == 0">
          <h3>Please choose a revenue center</h3>
        </div>

        <!-- </template> -->
        <div class="row">
          <div class="col-md-12">
            <button v-if="revenueCenter>0" class="btn btn-default btn-sm float-right btn-fill" style="margin-top:-4em;" @click="addMenuItem()">Add Menu Item</button>
            <b-tabs class="tab-hover" lazy active-nav-item-class="selected-tab" v-model="tabIndex">
              <!-- <b-tab @click="tabActivate(category.category)" :title="category.title" v-for="(category,categoryIndex) in menuItemCategories.filter( c => { return c.itemType=='item' && c.revenueCenters.indexOf(parseInt(revenueCenter))>=0})" :key="categoryIndex"> -->
              <template v-for="(category,categoryIndex) in menuItemCategoriesList">
                <b-tab @click="tabActivate(category.category)" :title="category.title" :key="categoryIndex">
                  <!-- <div v-show="loading"><span :class="isLoading" style="color:green;"></span> Loading</div> -->
                  <form v-on:submit.prevent class="mt-2 mb-2" :style="(itemSearchString.length>0) ? 'background:#FF813A' : 'background:gray'" style="display:inline-block;padding:.25em .25em .25em .75em;color:white;border-radius:.5em;position:relative;">
                    <i class="fa fa-search show-hand"></i>
                    <input type="text" v-model="itemSearchString" @keyup.13="itemSearch()" />
                    <i class="fa fa-times show-hand" @click="itemSearchString=''"></i>
                  </form>

                  <b-card-group>
                    <div class="card col-md-4" style="height:60vh; overflow-y: auto;">
                      <!-- <b-table v-if="menuItems.length>0" striped hover :items="menuItems" :fields="fields" @row-clicked="setCurrentItem"> -->
                      <b-table v-if="menuItems.length>0" striped hover :items="menuItemListSearch" :fields="fields" @row-clicked="setCurrentItem">
                        <template v-slot:cell(itemNumber)="data">
                          <div :style="(currentItem && currentItem.itemNumber == data.item.itemNumber) ? 'color:orange;font-size:1.1em;font-weight:800;' : ''">
                            {{ data.item.itemNumber }}
                          </div>
                        </template>
                        <template v-slot:cell(title)="data">
                          <div :style="(currentItem && currentItem.itemNumber == data.item.itemNumber) ? 'color:orange;font-size:1.1em;font-weight:800;' : ''">{{
                              data.item.title
                            }}
                          </div>
                        </template>
                        <template v-slot:cell(action)="data">
                          <i v-if="$root.isAdmin()" class="fa fa-trash show-hand" v-b-popover.hover.top="'Remove This Menu Item'" @click="removeMenuItem(data.item)"></i>
                        </template>
                      </b-table>
                      <div v-else class="card mt-3 text-center">
                        <h4 v-if="!currentCategory" class="card-title">Select a Category</h4>
                        <h4 v-else class="card-title">No Items</h4>
                      </div>
                    </div>
                    <div v-if="currentItem" class="card col-md-8">
                      <menu-item-detail :id="'item'+currentItem.itemNumber" @change="currentItemChanged" :currentItem="currentItem" :category="category" />
                    </div>
                  </b-card-group>
                </b-tab>
              </template>
            </b-tabs>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import MenuItemService      from "/src/Services/MenuItemService";
import RevenueCenterService from "/src/Services/RevenueCenterService";
import Moment               from "moment";
import Card           from '/src/components/UIComponents/Cards/Card.vue';
import MenuItemDetail from '/src/components/UIComponents/Cards/MenuItemDetailEdit.vue';
import SimpleListOnly from '/src/components/UIComponents/Lists/SimpleListOnly.vue';
import SfInput              from "/src/components/UIComponents/Inputs/SFormInput.vue";
import Currency             from "currency.js";

export default {
  components: {
    Card,
    Moment,
    MenuItemDetail,
    SimpleListOnly,
    SfInput,
    Currency
  },
  data() {
    return {
      dataChanged         : false,
      systemInterval      : null,
      isLiveEdit          : true,
      isAddItem           : false,
      isShowList          : false,
      tabIndex            : 0,
      tabName             : "",
      currentItem         : null,                                       // {title: ""}, //menuItems[0],
      currentCategory     : "",
      currentCategoryIndex: 0,
      itemSearchString    : "",
      menuItemCategories  : [],
      revenueCenters      : [],
      revenueCenter       : 0,

      upcLookup: false,
      upcCode : "",
      upSearch: "",
      upcItems: [],
      upcHistory: [],
      upcMessage: "",

      newItem             : {itemNumber: 0, title: "", category: ""},
      menuItems           : [],
      fields              : [
        {key: 'action', label: "Action", sortable: false},
        {key: 'itemNumber', label: "Item #", sortable: true},
        {key: 'title', label: "Item Name", sortable: true},
      ],
      listFields          : [
        {key: 'itemNumber', label: "Item #", sortable: true},
        {key: 'sequence', label: "Sequence", sortable: true},
        {key: 'title', label: "Item Name", sortable: true},
        {key: 'category', label: "Category", sortable: true},
        {key: 'POSName', label: "POS Name", sortable: true},
        {key: 'cost', label: "Cost", sortable: true, formatter: (value, key, item) => { return Currency(value, { symbol: "", precision: 2 }).format() }},
        {key: 'price[0]', label: "Price 0", sortable: true},
        {key: 'price[1]', label: "Price 1", sortable: true}
      ],
      upcFieldList: [
        {key: 'itemNumber', label: "Item #", sortable: true},
        {key: 'title', label: "Item Name", sortable: true},
        {key: 'category', label: "Category", sortable: true},
        {key: 'POSName', label: "POS Name", sortable: true},
        {key: 'price[0]', label: "Price 0", sortable: true, formatter: (value, key, item) => { return Currency(value, { symbol: "", precision: 2 }).format() }},
      ],
      loading             : false,
      isLoading           : "fa fa-spinner  fa-refresh-animate",
      notificationType    : [ '', 'info', 'success', 'warning', 'danger' ],
      notifications       : {
        topCenter: false
      }
    }
  },
  computed: {
    menuItemListSearch() {
      var t = this;
      return t.menuItems.filter(function(item) {

        if(t.itemSearchString === "") {
          return true;
        } else {
          return item.title.toLowerCase().includes(t.itemSearchString.toLowerCase()) || (item.UPCS.indexOf(t.itemSearchString) >= 0);
        }

      })
    },
    menuItemCategoriesList() {
      let list;
      if(this.menuItemCategories.length > 0) {
        list = this.menuItemCategories.filter(c => { return c.itemType == 'item' && c.revenueCenters.indexOf(parseInt(this.revenueCenter)) >= 0});
      }
      return list;
      ;
    }
  },
  async mounted() {
    let t               = this;
    this.systemInterval = setInterval(t.checkForChanges, 5000);

    await Promise.all([
                        this.getMenuItemCategories(),
                        this.getRevenueCenters()
                      ])

  },
  beforeDestroy() {
    clearInterval(this.systemInterval);
  },
  methods: {
    moment(d) {
      return Moment(d);
    },
    checkIsLoading() {
      return this.loading;
    },
    checkForChanges() {
      if(this.dataChanged) {
        if(this.currentItem) {
          this.saveMenuItem(this.currentItem);
        }
        this.dataChanged = false;
      }
    },
    currency(c) {
      return Currency(c);
    },
    async showList() {
      let category = this.currentCategory;
      let id;

      if( !category || !this.revenueCenter || this.revenueCenter === 0) {
        return;
      }

      console.log( "found loading" , this.loading , this.checkIsLoading() );

      if( this.dataChanged) {   // live edit hasn't saved pending changes yet.
        this.$root.warningMessage("Please wait for your changes to commit");
        return;
      }

      if( this.currentItem ) {      // if we're on an item, lets remember it because getItems expects a category change and zaps the current item.
         id = this.currentItem._id;
      }

      this.currentCategory = "";  // force the items to load cuz getMenuItems() wont fetch if category is the same.
      await this.getMenuItems( category );

      if( id ) {
        this.currentItem = _.find( this.menuItems, {_id: id});    // let's see if we can locate our last item.
      }

      this.isShowList = true;
    },
    printList() {
      window.print();
    },
    hideList() {
      this.isShowList = false;
    },
    tabChanged() {
      alert("tab Changed");
    },
    focusOnItemTitle() {
      // this.$refs.itemTitle.focus();
    },
    setTabName( name) {
      this.tabName = name;
    },
    tabActivate(category) {
      this.checkForChanges();
      this.currentCategoryIndex = _.findIndex(this.menuItemCategories, {itemType: 'item', category: category})
      if(this.currentCategoryIndex >= 0) {
        this.setTabName( category );
        this.getMenuItems(category);
      }
    },
    selectRevenueCenter(rc) {
      this.checkForChanges();
      this.menuItems = [];

      this.currentItem     = null;
      this.currentCategory = "";

      let t = this;
      setTimeout(() => {   // give the values a chance to calc, so this is technically nextTick();
        t.tabIndex   = 0;
        let category = t.menuItemCategoriesList[ 0 ].category;
        t.setTabName(category); // this.menuItemCategoriesList[this.tabIndex].category;
        t.tabActivate(category);
      }, 1);
    },
    revenueCenterList() {
      if(this.revenueCenters) {
        let rcl = this.revenueCenters
                      .filter((r) => {return r.options.isAllowMenuMasterList })
                      .map((r) => {return {text: r.title, value: r.revenueCenterId}})

        return rcl;
      }
      return [ {text: 'Select Revenue Center', value: 0} ];
    },
    async setCurrentItem(item) {
      this.checkForChanges();
      this.hideList();
      try {
        let response     = await MenuItemService.getMenuItem(item._id);
        this.currentItem = Object.assign({}, response.data);
      } catch(error) {
        console.error(error);
      }
    },
    isItemExist(n) {
      for(var i = 0; i < this.menuItems.length; i++){
        if(this.menuItems[ i ].itemNumber === n) {
          return true;
        }
      }
      return false;
    },
    getNextItemNumber() {
      let itemNumber = this.menuItemCategories[ this.currentCategoryIndex ].minItemNumber;
      let maxItem    = this.menuItemCategories[ this.currentCategoryIndex ].maxItemNumber;
      for(var i = itemNumber; i++; i < maxItem){
        if(!this.isItemExist(i)) {
          return i;
        }
      }
      return -1;
    },
    currentItemChanged() {
      this.dataChanged = true;
      // if(this.isLiveEdit) {
      //   this.saveMenuItem(this.currentItem);
      // }
    },
    async addMenuItem() {
      this.checkForChanges();
      if(!this.isAddItem) {
        let newNumber = this.getNextItemNumber();
        if(newNumber <= 0) {
          alert("No More Room To Add Items");
          return;
        }

        this.newItem.itemNumber = newNumber;
        this.newItem.category   = this.menuItemCategories[ this.currentCategoryIndex ].category;
        this.isAddItem          = true;
        return;
      }

      let testNumber = this.menuItemCategories[ this.currentCategoryIndex ].minItemNumber;
      if(this.newItem.itemNumber < testNumber) {
        alert("Item Number Cannot Be Less Than " + testNumber.toString());
        return;
      }

      testNumber = this.menuItemCategories[ this.currentCategoryIndex ].maxItemNumber;
      if(this.newItem.itemNumber > testNumber) {
        alert("Item Number Cannot Be More Than " + testNumber.toString());
        return;
      }

      this.isAddItem = false;

      try {
        this.newItem.POSName = this.newItem.title.substring(0, 16);
        let response         = await MenuItemService.addMenuItem(this.newItem);
        this.menuItems.push(response.data);
        this.currentItem = response.data;
      } catch(error) {
        console.error(error);
      }

    },
    async getRevenueCenters() {
      let t = this;
      let response;

      try {
        response            = await RevenueCenterService.getRevenueCenters(true);
        this.revenueCenters = response.data;
      } catch(err) {
        if(err.response) {
          if(err.response.data) {
            t.$root.errorMessage("Oops", err.response.data.message);
          }
        } else {
          t.$root.errorMessage("Oops", "Had an error getting the revenue centers");
        }
        return;
      }
    },
    activateUpcSearch() {
      let t = this;
      t.upcLookup = true;
    },
    async selectUpcItem( item ) {
      this.upcLookup = false;
      // let rc = this.revenueCenters; // .find((r) => {return r.categories.indexOf(item.category) > -1}).revenueCenter;

      let ci = _.findIndex( this.menuItemCategoriesList , {category: item.category});
      let cat = this.menuItemCategoriesList[ ci ];
      if( cat.revenueCenters.indexOf( parseInt(this.revenueCenter )) < 0) {
        let rc = cat.revenueCenters[0];
        await this.selectRevenueCenter( rc );
      }

      this.tabActivate(item.category);
      this.tabIndex = ci;
      await this.setCurrentItem( item );
    },
    async searchUPC( search ) {
      let t = this;

      if( search != undefined ) {
        this.upcCode = search;
      }

      this.upcMessage = "";

      try {
        // if( ! this.menuItems || this.menuItems.length == 0) {
        //   return t.$root.alert("Please select a revenue center first" , "warning");
        // }

        // this.upcItems = this.menuItems.filter((mi) => {return mi.UPCS.indexOf(this.upcCode) > -1 });

        if( this.upcHistory.indexOf( this.upcCode ) < 0  ) {
          this.upcHistory.unshift( this.upcCode );
        }
        // } else {
        //   this.upcHistory.splice( this.upcHistory.indexOf( this.upcCode ), 1 );
        //   this.upcHistory.unshift( this.upcCode );
        // }

        let response = await MenuItemService.findMenuItemsByUpc(this.upcCode);

        if( !response || !response.data || response.data.length == 0) {
          // await t.$root.alert("UPC code not found" , "warning");
          this.upcMessage = `[${this.upcCode}] was not found`;
          this.upcItems = [];
          return;
        }

        this.upcItems = response.data;
        this.upcCode  = ""

      } catch(err) {
        if(err.response) {
          if(err.response.data) {
            t.$root.errorMessage("Oops", err.response.data.message);
          }
        } else {
          t.$root.errorMessage("Oops", "Had an error searching for UPC");
        }
        return;
      }
    },
    async getMenuItems(category) {
      var response;
      var t = this;

      if(this.currentCategory === category) {
        return;
      }

      t.loading            = true;
      this.currentCategory = category;

      try {
        response         = await MenuItemService.getMenuItems(category);
        this.menuItems   = response.data;
        this.currentItem = null; // this.menuItems[ 0 ];
        t.loading        = false;
      } catch(err) {
        if(err) {
          console.error(err);
          t.$root.errorMessage("Oops", err.response.data.message);
        }
        t.loading = false;
      }
    },
    async getMenuItemCategories() {
      var response;
      var t = this;

      t.loading = true;

      try {

        response                = await MenuItemService.getMenuItemCategories();
        this.menuItemCategories = response.data;
        if(this.menuItemCategories.length > 0) {
          this.getMenuItems(this.menuItemCategories[ 0 ].category);
        }
        t.loading = false;
      } catch(err) {
        if(err) {
          console.error(err);
          t.$root.errorMessage("Oops", err.response.data.message);
        }
        t.loading = false;
      }
    },
    async saveMenuItem(item) {
      var response;
      var t = this;

      t.loading = true;

      try {
        response = await MenuItemService.saveMenuItem(item);

        this.dataChanged = false;
        let u            = _.find(this.menuItems, {_id: response.data._id});
        if(u) {
          u.title = response.data.title;
          u.sequence = response.data.sequence;
        }
        t.loading = false;
      } catch(err) {
        if(err) {
          console.error(err);
          t.$root.errorMessage("Oops", err.response.data.message);
        }
        t.loading = false;
      }
    },
    async removeMenuItem(item) {
      var response;
      var t = this;

      if(!confirm("Remove " + item.itemNumber + "/" + item.title)) {
        return;
      }

      t.loading = true;

      try {

        let category = item.category;
        await MenuItemService.deleteMenuItem(item._id);
        response         = await MenuItemService.getMenuItems(category);
        this.menuItems   = response.data;
        this.currentItem = this.menuItems[ 0 ];
        t.loading        = false;
      } catch(err) {
        if(err) {
          console.error(err);
          t.$root.errorMessage("Oops", err.response.data.message);
        }
        t.loading = false;
      }
    },
    notifyVue(verticalAlign, horizontalAlign) {
      const notification = {
        template: `<span>Welcome to <b>Light Bootstrap Dashboard</b> - a beautiful freebie for every web developer.</span>`
      };

      const color = Math.floor((Math.random() * 4) + 1)
      this.$notifications.notify(
        {
          component      : notification,
          icon           : 'nc-icon nc-app',
          horizontalAlign: horizontalAlign,
          verticalAlign  : verticalAlign,
          type           : this.notificationType[ color ]
        })
    }
  }
}

</script>
<style lang="scss">

</style>
