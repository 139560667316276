<template>
  <div class="content container-fluid">
    <div clas="row">
    <div class="col-12">

      <div v-if="vendors && !vendor" >
        <div style="float:right;position:relative;">
          <b-checkbox v-model="isExpandedView">Expanded View</b-checkbox>
        </div>
        <list-card
          title="Vendors"
          titleHelp="Select the desired vendor to edit"
          :items="_.sortBy(vendors, ['name'])"
          :fields="fieldList"
          :enableAdd="true"
          :enableUpload="false"
          :enableExport="true"
          :enableDelete="$root.isAdmin()"
          :enableDone="false"
          :enableShowActive="true"
          :enablePaging="true"
          stickyHeader="950px"
          @click="editVendor($event)"
          @add="addVendor()"
          @delete="removeVendor($event)"
          @upload="uploadVendors()"
          @done="$router.go(-1)"
        >
          <template v-slot:action="data">
              <i v-if="data.item.isOnHold"  class="ml-2 fa fa-hand-paper show-hand" style="color:orange;"></i>
          </template>
        </list-card>
      </div>

      <div v-if="vendor">
        <vendor-edit
          :vendor="vendor"
          :categoryList="categoryList"
          :changed="isDataChanged"
          :editing="isEditing"
          @done="done($event)"
          @save="save($event)"
          @cancel="cancel($event)"
          @clone="clone($event)"
          @changed="dataChanged()"
        />
      </div>
    </div>
    </div>
  </div>
</template>
<script>

  import VendorService  from "/src/Services/VendorService";
  // import TicketsLib     from "/src/Lib/TicketsLib";

  import Currency       from "currency.js";
  import gravatar       from "gravatar";
  import Moment         from "moment-timezone";
  import Card           from "/src/components/UIComponents/Cards/Card.vue";

  import VendorEdit     from "./VendorEdit.vue";

  import SfInput        from "/src/components/UIComponents/Inputs/SFormInput.vue";

  import ClickOutside     from 'vue-click-outside'
  import VueSimpleSuggest from 'vue-simple-suggest'
  import 'vue-simple-suggest/dist/styles.css' // Optional CSS
  import VJstree          from 'vue-jstree';
  import PieWidget        from "/src/components/UIComponents/Widgets/PieWidget.vue";
  import BarWidget        from "/src/components/UIComponents/Widgets/BarWidget.vue";

  import LineChart from "/src/components/Charting/LineChart.js";
  import BarChart  from "/src/components/Charting/BarChart.js";
  import exportLib from "../../../Lib/dataExportLib";
  import ListCard           from '/src/components/UIComponents/Cards/ListCard.vue';

  import SimpleCalendar from "/src/components/UIComponents/SimpleCalendar";

  var propTicketType;
  //var propUser;
  let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  //                 0         1         2         3         4         5         6         7         8        9         10        11        12
  let colors  = ['#a7a7a7','#64609A','#933709','#14A989','#D05340','#436CB9','#3AA8C1','#B56917','#AA4069','#2D5DA1','#832A0D','#B56917','#469A84'];
  let colors2 = ['#F0EAD6','#DD4132','#9E1030','#FE840E','#FF6F61','#C62168','#8D9440','#FFD662','#00539C','#755139','#D69C2F','#E8B5CE','#343148'];

  export default {
    directives: {
      ClickOutside
    },
    components: {
      Card,
      SfInput,
      PieWidget,
      BarWidget,
      BarChart,
      LineChart,
      ListCard,
      VueSimpleSuggest,
      VJstree,
      VendorEdit,
      SimpleCalendar
    },
    props: {
      propVendorId: { type: String, default: null }
    },
    watch: {
    },
    data() {
      return {
        isDataChanged: false,
        loading      : false,
        loadingClass : "fa fa-spinner  fa-refresh-animate mr-3",
        listTitle    : "List Title",
        isEditing    : false,
        isExpandedView: false,
        categoryList : [],
        fields       : [
          { key: "action", label: "", sortable: true , fieldView: "all" },
          { key: "active", label: "Active", sortable: true, fieldView: "all"  },
          { key: "category", label: "Category", sortable: true , fieldView: "expanded" },
          { key: "accountNumber", label: "Account", sortable: true , fieldView: "all" },
          { key: "name", label: "Name", sortable: true , fieldView: "all" },
          { key: "alias", label: "Alias", sortable: true , fieldView: "all" },
          { key: "primary.city", label: "City", sortable: true, fieldView: "expanded"  },
          { key: "primary.state", label: "State", sortable: true, fieldView: "expanded"  },
          { key: "primary.phone", label: "Phone", sortable: true , fieldView: "expanded" },
          { key: "primary.email", label: "Email", sortable: true , fieldView: "expanded" },
          { key: "updatedAt", label: "Last Update", sortable: true, fieldView: "all" , formatter:(value) => { return Moment(value).fromNow() } }
        ],
        vendors: [],
        vendor : null
      }
    },
    async created() {
      this.vendor = null;
      this.vendors = null;

      // if (this.propsCategory) {
      //   this.categoryFilter = this.propsCategory.split(",");
      // }

      await Promise.all([
        this.getVendors(),
        this.getCategoryList()
        // this.getMyTicketTypes(),
        // this.getTicketStatuses(),
        // this.getTicketPriorities(),
        // this.getTicketUsers(),
        // this.getUserOptions(),
      ])

      // let ticketType = localStorage.getItem("ticketType");
      // if( !ticketType ) {
      //   ticketType = this.myTicketTypes[0] || null;
      // }
      // if( ticketType ) {
      //   this.selectedTicketType = "";
      //   this.inputTicketType    = ticketType;
      //   this.ticketType         = "";
      //   await this.selectTicketType( ticketType ) ;
      // }

    },
    async beforeRouteLeave( to , from, next ) {
      if( !this.isDataChanged ) {
        return next();
      }
      if( await this.$root.confirmYesNo('You have unsaved changes, continue leaving without saving?', "warning")) {
        return next();
      }
      next(false)
    },
    async mounted() {
      // this.popupItem = this.$el;
      // let listStyle = localStorage.getItem("ticketListStyle");
      // if (listStyle) {
      //   this.listStyle = listStyle;
      // }

    },
    computed: {
      fieldList() {
        return this.fields.filter( f => { return f.fieldView === "all" || (f.fieldView === "expanded" && this.isExpandedView) });
      }
    },
    methods: {
      done(vendor) {
        this.isDataChanged = false;
        this.vendor = null;
      },
      cancel(vendor) {
        this.done();
      },
      async clone( vendor ) {
        let t              = this;
        let newVendor      = Object.assign({},vendor );

        t.vendor       = null;
        newVendor.name = vendor.name + " (copy)";
        t.isLoading    = true;

        try {
          let response = await VendorService.addVendor( newVendor );  // get db defaults.
          t.vendor = response.data;
          t.isVendor  = true;
          t.isEditing = true;
          t.dataChanged();
        } catch( error ) {
          console.log(error);
          await this.alert(error.message , "danger");
        }
        t.isLoading = false;
      },
      dataChanged() {
        this.isDataChanged = true;
      },
      USD( value ) {
        return Currency(value, { precision: 2, symbol: ' ' }).format();
      },
      currency(c) {
        return Currency(c);
      },
      moment(d) {
        return Moment(d);
      },
      setChartYear() {
        this.makeCharts();
      },
      async calendarGotoToday() {
        let newDate = Moment().tz(this.$root.TZ);
        // await this.getTransactions( newDate );
        this.calendarDate = newDate;
      },
      async calendarPrevMonth() {
        let newDate = Moment(this.calendarDate).tz(this.$root.TZ).subtract( 1, 'month' );
        // await this.getTransactions( newDate );
        this.calendarDate = newDate;
      },
      async calendarNextMonth() {
        // if( this.calendarDate < this.moment()) {
          let newDate = Moment(this.calendarDate).tz(this.$root.TZ).add( 1, 'month' );
          // await this.getTransactions( newDate );
          this.calendarDate = newDate;
        // }
      },
      csvExportvendorSummary() {
        exportLib.csvExportAskFilename( this.ticketStatusList.filter((x) => { return x.dueDate.substring(0,4) == this.chartYear })); //, this.ticketSummaryFields );
      },
      toggleControlWindow() {
        this.isShowControlWindow = !this.isShowControlWindow;

        if( this.isShowControlWindow ) {
           this.ticketWindowClass   = "col-md-12 col-lg-8 col-xl-9";
        } else {
          this.ticketWindowClass="col-12";
        }

      },
      tagList() {
        let a = this.tickets.flatMap( t => t.tags );
        return _.uniq(a).sort();
      },
      tagValidator(tag) {
        return tag === tag.toLowerCase() && tag.length > 2 && tag.length < 15
      },
      // categoryList() {
      //   let a = this.tickets.flatMap( t => t.category );
      //   return _.uniq(a).sort();
      // },
      removeSearchQuery: function() {
        this.searchQuery = '';
        this.isResult = false;
      },
      isReadOnly() {
        if( this.ticket.closed ) {
          return true;
        }
        if( ! this.isMyTicketType( this.ticket.ticketType )) {
          if( this.ticket.assignedToId !== this.$root.user.tenantUser ) {
            return true;
          }
        }
        return false;
      },
      availableUsers() {
        // fixme: is this the "assignment" list and where is it used?
        // return this.users.filter((u) => { return u.ticketTypes.indexOf( this.ticketType ) >= 0 }) ;
        return this.assignToUsers;
      },
      async uploadVendors() {

      },
      async getUserOptions() {
        try {
          let response = await UserService.getUserOption( "vendors" , "filter" );
          let result = response.data;
          if( result ) {
            this.showClosed   = result.showClosed;
            this.showParked   = result.showParked;
            this.showStuck    = result.showStuck;
            this.showComplete = result.showComplete;
          }
        } catch( error ) {
          console.log( error.message );
        }
      },
      async saveUserOptions() {
        try {
          let data  = {
            showClosed  : this.showClosed,
            showParked  : this.showParked,
            showStuck   : this.showStuck,
            showComplete: this.showComplete
          }
          await UserService.setUserOption("vendors" , "filter" , data );
        } catch( error ) {
          console.log( "saveUserOptions()" , error.message );
        }
      },
      hasPicture() {
        if( ! this.ticket.assignedToPictureUrl) {
          return false;
        }
        if( this.ticket.assignedToPictureUrl.length>0 ) {
          return true;
        }
        return false;
      },
      myGravatar( email ) {
        return gravatar.url( email );
      },
      getImage( user ) {
        if( user.user.pictureUrl ) {
          return user.user.pictureUrl;
        }
        return this.myGravatar( user.user.email );
      },
      getAccountImage( user ) {
        if( user.pictureUrl ) {
          return user.pictureUrl;
        }
        return this.myGravatar( user.email  );
      },
      setTicketListTitle( title ) {
        this.listTitle = title;
      },
      async getCategoryList() {
        try {
          let response = await VendorService.getCategoryList();
          this.categoryList = response.data;
        } catch(error) {
          console.log(error);
          this.$root.errorMessage("Oops", error.message);
        }
      },
      async addVendor( vendor = {}) {

        if( ! await this.$root.confirmYesNo( "Add New vendor?" )) {
          return;
        }

        let t          = this;
        let newVendor = { name: "New vendor "  , active: true };

        t.isLoading = true;
        vendor      = Object.assign( {} , newVendor, vendor );  // merge a preset passed in with our defaults;

        try {
          let response    = await VendorService.addVendor( vendor );  // get db defaults.
              t.vendor    = null;
              t.vendor    = response.data;
              t.isVendor  = true;
              t.isEditing = true;

          t.dataChanged();

        } catch( error ) {
          console.log(error);
          if (error.response) {
            t.$root.errorMessage("Oops", error.response.data.message);
          }
        }
        t.isLoading = false;
      },
      async getVendors() {
        var t = this;

        t.loading   = true;
        t.isEditing = false;

        try {
          let response = await VendorService.getVendors( );
          t.vendors = response.data;
        } catch (error) {
          if (error) {
            console.error(error);
            if( error.response ) {
              t.$root.errorMessage("Oops", error.response.data.message);
            } else {
              t.$root.errorMessage( "Oops" , error.message );
            }
          }
          t.vendors = [];
        }
        t.loading = false;
      },
      async save( vendor ) {
        this.saveVendor( vendor );
      },
      async saveVendor( vendor) {
        let t = this;
        try {
          let response;
          if( vendor._id ) {
            response = await VendorService.updateVendor( vendor );
          } else {
            response = await VendorService.addVendor( vendor );
          }

          let id = response.data._id;

          let found = false;
          for( var i=0; i<t.vendors.length; i++ ) {
            if( t.vendors[i]._id === id ) {
              t.vendors[i] = response.data;
              found = true;
              break;
            }
          }
          if( ! found ) {
            t.vendors.unshift( response.data );    // plug the new ticket at the top.
          }

          this.getCategoryList();

          t.vendor        = null;
          t.isVendor      = false;  // if we get here, all went well so close the ticket edit.
          t.isDataChanged = false;
          t.isEditing     = false;

        } catch( error ) {
          console.log(error);
          if (error.response) {
            t.$root.errorMessage("Oops", error.response.data.message);
          } else {
            t.$root.errorMessage("Oops" , error.message );
          }
        }
      },
      editVendor( vendor ) {
        let t = this;
        t.getVendor(vendor._id);
      },
      async getVendor(id) {
        let t = this;

        t.loading = true;
        try {
          let response = await VendorService.getVendor(id);

          t.vendor        = response.data;
          t.isVendor      = true
          t.isDataChanged = false;
          t.isEditing     = false;

        } catch (error) {
          console.log(error);
          if (error.response) {
            t.$root.errorMessage("Oops", error.response.data.message);
          }
        }
        t.loading = false;
      },
      async removeVendor(e) {

        let vendor = e.item;    // what we get from the list component.

        if( ! await this.$root.confirmYesNo( `Delete\n[${vendor.name}]?` )) {
          return;
        }

        let t = this;
        let id = vendor._id;
        let index = _.findIndex( t.vendors , { _id: id } );

        try {

          await VendorService.deleteVendor( id );

          t.vendors.splice(index,1);
          t.vendor        = null;
          t.isVendor      = false;  // if we get here, all went well so dump the current vendor
          t.isDataChanged = false;
          t.isEditing     = false;

        }catch( error ) {
          console.log(error);
          if (error.response) {
            t.$root.errorMessage("Oops", error.response.data.message);
          }
        }
      }
    }
  }

</script>
<style lang="scss" scoped >

 .odd-even {

    tr:nth-child(odd) {
      background: #eee;
    }
    tr:nth-child(even) {
      background: #fff
    }
  }

blockquote {
    font-family: Georgia, serif;
    font-size: 18px;
    font-style: italic;
    width: 500px;
    margin: 0.25em 0;
    padding: 0.35em 40px;
    line-height: 1.45;
    position: relative;
    color: #383838;
}

blockquote:before {
    display: block;
    padding-left: 10px;
    content: "\201C";
    font-size: 80px;
    position: absolute;
    left: -20px;
    top: -20px;
    color: #7a7a7a;
}

// blockquote:after {
//   display: block;
//   padding-right: 10px;
//   content: "\201D";
//   font-size: 80px;
//   position: relative;
//   // right: 20px;
//   top: 20px;
//   color: #7a7a7a;
// }

blockquote cite {
    color: #999999;
    font-size: 14px;
    display: block;
    margin-top: 5px;
}

blockquote cite:before {
    content: "\2014 \2009";
}
  .outline-shadow {
    -webkit-box-shadow: 0px 0px 25px 5px rgba(0,0,0,0.35);
    box-shadow: 0px 0px 25px 5px rgba(0,0,0,0.35);
  }

  .collapsible {
    background-color: #eee;
    margin-top: .5em;
    border-radius: 1em;
    color: #444;
    cursor: pointer;
    padding: 10px;
    border: none;
    text-align: left;
    outline: none;
  }

  ins {
     background-color:rgba( 0,255,0,.1);
     text-decoration:none;
  }

  del {
    background-color:rgba( 255,0,0,.1);
  }

  .hover-track-light:hover{
    background: #eee;
    color:black;
  }

  .hover-track-dark:hover {
    background: #333;
    color: white;
  }


  .page-stamp {

    position: absolute;
    top: 4em;
    left: 2em;
    z-index: 1;
    font-family: Arial,sans-serif;
    -webkit-transform: rotate(-30deg); /* Safari */
    -moz-transform: rotate(-30deg); /* Firefox */
    -ms-transform: rotate(-30deg); /* IE */
    -o-transform: rotate(-30deg); /* Opera */
    transform: rotate(-30deg);
    font-size: 6em;
    color: #c00;
    background: #fff;
    border: solid 4px #c00;
    padding: 0 .5em 0 .5em;
    border-radius: .25em;
    zoom: 1;
    filter: alpha(opacity=20);
    opacity: 0.2;
    -webkit-text-shadow: 0 0 2px #c00;
    text-shadow: 0 0 2px #c00;
    box-shadow: inset 0px 0px 0px 10px #c00;
  }


  .page-stamp:after {
    content:'';
    border-radius:.25em;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url("@/assets/img/stamp-background.jpg");
    mix-blend-mode: lighten;
  }

  @media only screen and (max-device-width: 1700px) {
    .hide-too-small {
      display:none;
    }
  }


  @media print {

    @page {
      size: letter portrait;
    }

    body {
      -webkit-print-color-adjust: exact !important;
      width: 100%;
    }

    .logo {
      width: 300px !important;
      max-width: 300px !important;
    }

    .noprint {
      display: none;
    }

    .container {
      width: 100%;
      max-width: 100%;
    }
  }

</style>
