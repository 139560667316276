<template>
  <div class="content ">
    <div class="" style="">

      <div>
        <!-- <i class="fa fa-plus-square show-hand" style="float:right;font-size:1.25em;padding-right:2em;" v-b-popover.hover.top="'Add a new land holding'" @click="newLand()"></i> -->
        <b-button class="btn btn-sm btn-fill" variant="success" style="border-radius:.75em;float:left;margin-right:1em;" @click="$router.push('/marketing/marketing')">Done</b-button>
        <h3 class="card-title">Available Units: {{modelName(roomModel)}}</h3>
      </div>
      <hr />

      <div class="row" style="width:100%;">
        <unit-card  v-for="(unit,index) in units" 
          :key="index"
          :index="index"
          :title="'The title line'"
          :unit="unit"
          :model="getModel(unit.roomModel)"
          :enableControls="true"
        />

      </div>

    </div>
  </div>

</template>
<script>
  import Moment from "moment";
  import Card from '/src/components/UIComponents/Cards/Card.vue'
  import UnitCard from '/src/components/UIComponents/Cards/UnitCard.vue'
  import SfInput from "/src/components/UIComponents/Inputs/SFormInput.vue";
  import StatusWidget from '/src/components/UIComponents/Widgets/StatusWidget.vue';
  import RoomService from "/src/Services/RoomService";
  import TenantService from "/src/Services/TenantService";

  let roomModel;

  export default {
    components: {
      Card,
      SfInput,
      StatusWidget,
      UnitCard
    },
     props     : {
      roomModel: { type: String, default: 'all'}
    },
    data() {
      return {
        variants         : [ 'primary', 'secondary', 'success', 'warning', 'danger', 'info', 'light', 'dark' ],
        headerBgVariant  : 'dark',
        headerTextVariant: 'light',
        bodyBgVariant    : 'light',
        bodyTextVariant  : 'dark',
        footerBgVariant  : 'warning',
        footerTextVariant: 'dark',
        loading          : false,
        housingConfig    : null,
        occupancyItems   : [],
        units            : [],
        isLoading        : "fa fa-spinner  fa-refresh-animate mr-3",
        type             : [ '', 'info', 'success', 'warning', 'danger' ],
        notifications    : {
          topCenter: false
        }
      }
    },
    async mounted() {
      await this.getHousingConfig();
      await this.getBuildingOccupancyStats();
      await this.getRoomsByModel( this.roomModel );
    },
    computed  : {
    },
    methods   : {
      moment(d) {
        return Moment(d);
      },
      modelName( value ) {
        if(! this.housingConfig ) {
          return "*"+value;
        }
        let m = _.find(this.housingConfig.models, {value: value });
        if( m ) {
          return m.text;
        }
        return "**" + value;
      },
      selectModel( model ) {
        // console.log( "selectModel()" , model );
      },
      getModel( roomModel ) {
        let m = _.find( this.housingConfig.models , { value: roomModel });
        if( m ) {
          return m;
        }
        return { value: '', text: 'Not Found' , coverPhoto: null };
      },
      async getHousingConfig() {
        try {
          let hc =  localStorage.getItem( "housingConfig" );

          if ( hc ) {
            this.housingConfig = JSON.parse(hc);
          } else {
            let response = await TenantService.getHousingConfig();
            this.housingConfig = response.data;
          }

        } catch (error) {
          console.log(error);
        }
      },
      async getRoomsByModel( roomModel ) {

        try {
          let response = await RoomService.getRoomsByModel( roomModel );
          // this.units = response.data;

          this.units = response.data.map((u) => {
            u["coverPhoto"] = "coverphoto.jpeg";
            return u;
          });
          // console.log( "rooms" , this.units );

        } catch( error ) {
          console.log( error.message )
        }

      },
      async getBuildingOccupancyStats() {

        try {

          let response = await RoomService.getBuildingOccupancyStats( "" , this.housingConfig.unitTypes.map( (u) => { return u.value }).join(","));

          if(response.data) {
            this.occupancyItems = response.data.map((s) => {
              s[ "title" ] = this.modelName(s.roomModel);
              if(s.available <= 0) {
                s[ "itemStyle" ] = "color:gray;"
              }
              return s;
            })
          } else {
            this.occupancyItems = [];
          }

          // console.log( "getBuildingOccupancyStats" , this.occupancyItems , response.data)

        } catch(error) {
          console.log(error.message);
        }
      }
    }
  }
</script>
<style>

</style>
