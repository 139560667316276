import { render, staticRenderFns } from "./SimpleListOnly.vue?vue&type=template&id=6e3bc7fe&scoped=true"
import script from "./SimpleListOnly.vue?vue&type=script&lang=js"
export * from "./SimpleListOnly.vue?vue&type=script&lang=js"
import style0 from "./SimpleListOnly.vue?vue&type=style&index=0&id=6e3bc7fe&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e3bc7fe",
  null
  
)

export default component.exports