<template>
  <!-- "Printable" ticket display page -->
  <div>
    <div class="noprint" style="margin-bottom:1em;">
      <b-button class="btn btn-sm btn-fill btn-success mr-2" @click="cancel">Cancel</b-button>
      <b-button class="btn btn-sm btn-fill btn-warning mr-2" @click="printTicket">Print This Invoice</b-button>
<!--      <b-button class="btn btn-sm btn-fill " @click="sendInvoice()">Send This Invoice</b-button>-->
      <b-button class="btn btn-sm btn-fill " @click="makeInvoicePdf()">Generate PDF</b-button>

    </div>

    <div id="theInvoice">
      <div class="print-page-setup" >
        <div class="ticket-number">Ticket ID:<span>{{ ticket.ticketNumber }}</span></div>
        <!--      <div class="ticket-assigned">Assigned To <span>{{ticket.assignedTo}}</span></div>-->
        <img class="ticket-logo" :src="logo"  />
<!--        <img class="ticket-logo" :src="logoBase64"  />-->
        <div class="ticket-date">{{ moment(ticket.scheduledDate).format("dddd, MMMM Do, YYYY") }}</div>
        <h2 class="ticket-header">Invoice</h2>
        <div v-if="config" class="ticket-address" >
          <div>
            <table>
              <tr>
                <td>{{ config.address.line1 }}</td>
              </tr>
              <tr>
                <td>{{ config.address.line2 }}</td>
              </tr>
              <tr>
                <td>{{ config.address.line3 }}</td>
              </tr>
              <tr>
                <td>{{ config.address.line4 }}</td>
              </tr>
              <tr>
                <td>{{ config.address.line5 }}</td>
              </tr>
              <tr>
                <td>{{ config.address.line6 }}</td>
              </tr>
            </table>
          </div>
        </div>

        <div class="client-address">
          <div v-if="ticket.client.clientName">
            <div class="client-name"><span>{{ ticket.client.clientName }}</span></div>
          </div>
          <div v-if="ticket.client.clientCompany">{{ ticket.client.clientCompany }}</div>
          <div v-if="ticket.client.clientAddress1">{{ ticket.client.clientAddress1 }}</div>
          <div v-if="ticket.client.clientAddress2">{{ ticket.client.clientAddress2 }}</div>
          <div v-if="ticket.client.clientCity || ticket.client.clientState || ticket.client.clientZip">{{ ticket.client.clientCity }} {{ ticket.client.clientState }}
            {{ ticket.client.clientZip }}
          </div>
        </div>

        <div class="ticket-details-area" >
          <div>
            <div class="ticket-title"><span>{{ ticket.title }}</span></div>
            <div class="ticket-description"><span>{{ ticket.description }}</span></div>
          </div>
        </div>
        <!--      <div v-if="ticket.client.clientName" class="signature-area">-->
        <!--        <div class="signature">-->
        <!--          <span>({{ticket.client.clientName}}) Authorized Signature</span>-->
      </div>

      <div class="ticket-totals-area">
      <table class="table">

        <tbody>
          <tr v-if="ticket.actual.hours !== 0" >
            <td class="text-right" style="padding-bottom:2em;">Hours</td>
            <td class="text-right" style="padding-bottom:2em;">{{ ticket.actual.hours.toFixed(2) }}</td>
          </tr>
          <tr v-if="ticket.actual.labor !== 0">
            <td class="text-right">Labor</td>
            <td class="text-right">{{ ticket.actual.labor.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }}</td>
          </tr>
          <tr v-if="ticket.actual.parts !== 0">
            <td class="text-right">Parts</td>
            <td class="text-right">{{ ticket.actual.parts.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }}</td>
          </tr>
          <tr v-if="ticket.actual.supplies !== 0">
            <td class="text-right">Supplies</td>
            <td class="text-right">{{ ticket.actual.supplies.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }}</td>
          </tr>
          <tr v-if="ticket.actual.expenses !== 0">
            <td class="text-right">Expenses</td>
            <td class="text-right">{{ ticket.actual.expenses.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }}</td>
          </tr>
          <tr v-if="ticket.actual.outside !== 0">
            <td class="text-right">Outside</td>
            <td class="text-right">{{ ticket.actual.outside.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }}</td>
          </tr>

          <tr v-if="ticket.actual.deposits !== 0">
            <td class="text-right">Deposits</td>
            <td class="text-right">{{ ticket.actual.deposits.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }}</td>
          </tr>

          <tr style="border-top:2px solid black;">
            <td class="text-right">Invoice Total</td>
            <td class="text-right">{{ ticket.actual.total.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }}</td>
          </tr>

          <template v-if="ticket.actual.payments !== 0" >
            <tr>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td class="text-right">Payments</td>
              <td class="text-right">{{ ticket.actual.payments.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }}</td>
            </tr>
            <tr style="border:2px solid black;">
              <td class="text-right">Balance Due</td>
              <td class="text-right">{{ currency(ticket.actual.total + ticket.actual.payments).value.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }}</td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
    </div>

  </div>
</template>
<script>


  import iLib     from "@/Lib/ioihanLib";
  import JsPDF    from "jspdf";
  import Moment   from "moment";
  import Currency from "currency.js";
  import moment   from "moment/moment";

  export default {
    props: {
      ticket: { type: Object, default: () => { } },
      config: { type: Object, default: () => { } },
      logo:   { type: String, default: "" }
    },
    data() {
      return {
        logoBase64: null
      }
    },
    created() {
      // this.toDataURL(this.logo, data => {
      //   this.logoBase64 = data;
      // });
    },
    mounted() {
      // console.log( "ticketDisplayPage" , this.ticket )
    },
    methods: {
      moment(d) {
        return Moment(d);
      },
      currency(c) {
        return Currency(c);
      },
      cancel() {
        this.$emit("cancel");
      },
      sendInvoice() {
        this.$emit("send");
      },
      async makeInvoicePdf() {
        if(!await this.$root.confirmYesNo("Output PDF File?")) {
          return;
        }

        let self = this;
        let pdf = new JsPDF('portrait', 'px');

        let table  = document.querySelector('#theInvoice');
        let width  = table.getBoundingClientRect().width;
        let height = table.getBoundingClientRect().height + 30;

        // pdf.internal.pageSize.width  = (width + 200);
        pdf.internal.pageSize.width  = width + 4;
        pdf.internal.pageSize.height = height + 4;

        let title = "invoice-" + this.ticket.ticketNumber.toString() + "-" + moment().format("YYYY-MMMM-DD-HH-mm").toString();
        let filename = iLib.makeSafeFilename( title ) + ".pdf";

        pdf.html(table, {
          callback: function(pdf) {
            // saveAs(pdf, filename);
            pdf.save(filename);
          },
          margin  : [ 2, 2, 2, 2 ]
          // x: 32,
          // y: 32,
        });

      },
      toDataURL(url, callback) {
        var xhr = new XMLHttpRequest();
        xhr.onload = function() {
          var reader = new FileReader();
          reader.onloadend = function() {
            callback(reader.result);
          };
          reader.readAsDataURL(xhr.response);
        };
        xhr.open("GET", url);
        xhr.responseType = "blob";
        xhr.send();
      },
      printTicket() {
        //window.print();
        this.$emit("print");
      },
      hasAddress() {
        let c = this.ticket.client;
        if (c.clientAddress1 || c.clientAddress2 || c.clientCity || c.clientState || c.clientzip) {
          return true;
        }
        return false;
      }
    }
  }

</script>
<style lang="scss" scoped>

  .print-page-setup {
    width:8.5in;
    height:11in;
    padding:.25em;
    margin:0;
  }


  .ticket-logo {
    position: relative;
    // width:15%;
    top:20px;
    left:20px;
    height:160px;
    width:160px;
    object-fit:contain;
  }


  .ticket-date {
    position:relative;
    top:-130px;
    text-align:center;
    font-size:12pt;
  }

  .ticket-header {
    position:relative;
    top:-220px;
    text-align:center;
  }

  .ticket-assigned {
    position:relative;
    top:20px;

    // text-align:left;
    span {
      font-weight:600;
    }
  }

  .ticket-number {
    float:right;
    margin-top:1em;
    font-size:1.2em;
    span {
      padding-left:1em;
      padding-right:1em;
      font-weight:600;
    }
  }

  .ticket-address {
    position:relative;
    display:block;
    top: -90px;
    left: 20px;
    width: 50%;
    font-size:12pt;
    text-align:right;
    tr:first-child {
      font-size:24pt;
    }
    tr {
      line-height:1.1em;
    }
  }

  .ticket-details-area {
    position:absolute;
    padding:.5em;
    border:1px solid black;
    top:550px;
    left:40px;
    height:500px;
    border-radius:1em;
    width:8.40in;
    h4 {
      margin-top:0;
      margin-bottom:0;
    }
  }

  .ticket-title {
    /*font-weight:600;*/
    font-size:1.25em;
    margin-left:.75em;
    border-bottom:1px solid black;
    width:98%;
  }

  .ticket-description {
    height:450px;
    max-width:100%;
    white-space: pre-line;
    padding-left:1em;
    padding-top:.25em;
    font-size: 10pt;
    margin-left: 1em;

    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    -webkit-column-gap: 20px;
    -moz-column-gap: 20px;
    column-gap: 20px;
    column-fill:auto;

    span {
      overflow:hidden;
      text-overflow: ellipsis;
    }

  }


  .ticket-totals-area {
    position:relative;
    width:400px;
    //position:relative;
    //top:-40px;
    left:400px;

    .table {
      width: 100%;
      padding:0;
      margin:0;
      line-height:6px;
      tbody {
        tr {
          td:nth-child(2) {
            font-weight: 600;
          }
        }
      }
    }



    .text-right {
      text-align: right;
    }
  }

  .client-area {
    display:block;
    position:relative;
    background:#eee;
    // top:400px;
    top:-150px;
    padding:.5em;
    border-radius:1em;
    width: 350px; // 35%;
    h4 {
      margin-top:0;
      margin-bottom:0;
    }
    div {
      margin-top:0;
      margin-left:20px;
    }
  }

  .client-address {
    display:block;
    position:absolute;
    border-radius:1em;
    background:#eee;
    padding:.5em .5em .5em 1em;
    top:380px;
    width:400px;
/*    height:100px;*/
    left:450px;
  }

  .signature-area {
    position:relative;
    // top: 670px;
    top:540px;
    left: 400px;
    width: 325px;
    .signature {
      position:relative;
      top: 20px;
      text-align:center;
      border-top: 2px solid black;
      padding-right:1em;
      font-size:.8em;

    }
  }



  @media print {

    @page {
      width:8.5in;
      height:11in;
      margin: 0;
      padding: 0;
      size: letter;
    }

    /*.ticket-description {*/
    /*  height: 800px;*/
    /*}*/
    //

    .ticket-address {
      left:-170px;
    }
    .ticket-details-area {
      height:440px;
    }

    .ticket-description {
      height:380px;
    }

    .ticket-totals-area {
      top:-50px;
    }
    .noprint {
      display: none;
    }

  }

</style>
