<template>

  <div class="contact-us full-screen">
    <br>
    <center>
      <img src="/static/img/newcare-logo-350.png" height="150px" />
    </center>

    <nav class="navbar navbar-ct-default" role="navigation">
      <div class="container">
        <div  v-show="showAlert===false" class="navbar-header">
          <h2>{{title}}</h2>
          {{message}}
        </div>
      </div>
    </nav>
    <br>
    <div class="wrapper wrapper-full-page section content">
      <div class="container">
        <div class="row" >
          <div v-show="showAlert===false" class="col-md-8 col-md-offset-2 shadow p-3 mb-5 bg-white rounded">
            <b-form @reset="onReset">

              <b-form-group id="username-label" label="Email address" label-for="username" description="Your email address is your login ID">
                <b-form-input id="username" type="email" v-model="username" required placeholder="login email address">
                </b-form-input>
              </b-form-group>
              <b-form-group id="password-label" label="Password" label-for="password">
                <b-form-input id="password" type="password" v-model="password" required placeholder="Password (at least 1 cap, 1 special character)">
                </b-form-input>
              </b-form-group>
               <b-form-group id="domain-label" label="Your Site Identifier (e.g., mycompanyname or the BASE domain name )" label-for="domain" :validFeedback="validFeedback" :invalidFeedback="invalidFeedback">
                <b-form-input id="domain" :state="validDomain" type="text" v-model="domain" required placeholder="Company Identifier">
                </b-form-input>
              </b-form-group>
              <b-form-group>
                <b-check v-model="agree" >I agree with the <a href="" >Terms of Service</a> and <a href="">Privacy Policy</a></b-check>
              </b-form-group>
              
              <b-button :disabled="!agree" id="registerButton" @click="registerClick()" class="btn btn-fill mr-3" variant="success">Sign Me Up!</b-button>
              <b-button type="reset" class="btn btn-fill" variant="danger">Cancel</b-button>
            </b-form>
            <h4>NOTE</h4>
            <p>
              If you are registering for an existing company, your registration will be placed on-hold until the system administrator activates your account.
            </p>
          </div>
          <div>
            <b-alert show v-show="showAlert" >
              <h4 class="alert-heading">Registration Complete</h4>
              <hr />
              <p>
                You have completed your registration, and your account has been created.  If you are registering for an existing company, your account is awaiting your system administrator to activate. You will be unable to log in until the system administrator activates your login.
              </p>
              <b-button class="btn btn-fill" @click="goLogin" >I Understand, Let's Continue...</b-button>
            </b-alert>
          </div>
          <div v-show="showAlert===false" class="shadow p-3 mb-5 bg-white rounded">
            <h2>Other Options</h2>
            <ul>
              <li>
                <router-link :to="{path: '/'}">Home</router-link>
              </li>
              <li>
                <router-link :to="{path: '/forgotPasssword'}">Forgot Password</router-link>
              </li>
              <li>
                <router-link :to="{path: '/login'}">Log In</router-link>
              </li>
            </ul>
          </div>
        </div>
        
      </div>
    </div>

    <footer class="footer">
      <div class="container">
        <div class="copyright pull-right">
          &copy; Copyright 2018-{{ new Date().getFullYear() }}, Newcare International, LLC (an IOIHAN Company)
        </div>
      </div>
    </footer>

  </div>
</template>

<script>


import AuthenticationService from "/src/Services/AuthenticationService";

var t = this;

export default {
  components: {
    //  EditProfileForm,
    //  UserCard
  },
  data() {
    return {
      title   : "New Registration",
      message : "Please provide your credentials",
      username: "",
      password: "",
      domain  : "",
      agree   : false,
      errors  : null,
      message : "Please provide the following information",
      validDomain: true,
      showAlert: false
    };
  },
  watch: {
    domain: function( val) {          // fixme: make this a regular expression to only accept alpha/numeric
      var t = this;
      var allowedChars = /^[A-Za-z0-9]+$/;
      t.validDomain = (val.match(allowedChars) ) ? true : false;
    }
  },
  computed: {
    validFeedback() {
      return "";
    },
    invalidFeedback() {
      return "Alpha-Numeric characters only, please";
    }
  },
  methods: {
    onReset(e) {
      e.preventDefault();
      this.username = "";
      this.password = "";
      this.domain   = "";
      this.$router.push("/"); //  async, so "this" works just fine (instead of using the cached "t")
    },
    validateBeforeSubmit() {},
    registerClick() {
      const t = this;
      const d = {
        email   : t.username,
        password: t.password,
        domain  : t.domain
      }

      AuthenticationService
        .signup(d)
        .then(response => {
          if (response.status === 200) {
            //t.$root.logIn(response.data); 
            // t.$root.logOut();
            // t.appHome();
            this.showAlert = true;
          } else {
            t.$root.errorMessage("Oops", response.response.data.message);
          }
        })
        .catch(e => {
          console.warn("response:", JSON.stringify(e));
          t.$root.errorMessage("Oops", e.response.data.message);
        });
    },
    appHome() {
      this.$router.push("/app");
    },
    goLogin() {
      this.$router.push("/login"); // go here after registration
    },
    home() {
      this.$router.push("/");      // jump to the APP root to start his show
    },
    clearErrors() {
      this.errors.clear();
    }
  }

};
</script>
<style>
</style>
