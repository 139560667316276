<template>
  <ul class="nav nav-mobile-menu">
    <drop-down>
      <template slot="title">
        <i class="fa fa-globe"></i>
        <b class="caret"></b>
        <span class="notification">5 Notifications</span>
      </template>
      <a class="dropdown-item" href="#">Notification 1</a>
      <a class="dropdown-item" href="#">Notification 2</a>
      <a class="dropdown-item" href="#">Notification 3</a>
      <a class="dropdown-item" href="#">Notification 4</a>
      <a class="dropdown-item" href="#">Another notification</a>
    </drop-down>
    <li class="nav-item">
      <a href="#" class="nav-link">
        <i class="nc-icon nc-zoom-split hidden-lg-up"></i>
        <span class="d-lg-none">Search</span>
      </a>
    </li>
    <drop-down title="Settings">
      <router-link class="dropdown-item" to="/app/user">My Profile</router-link>
      <div class="divider"></div>
      <router-link class="dropdown-item" to="/app/user">Tenant Settings</router-link>
      <div class="divider"></div>
      <router-link class="dropdown-item" to="/app/user">Connections</router-link>
      <router-link class="dropdown-item" to="/app/user">Interfaces</router-link>
      <div class="divider"></div>
      <router-link class="dropdown-item" to="/app/user">Locations (Places)</router-link>
      <router-link class="dropdown-item" to="/app/user">Outlets</router-link>
      <router-link class="dropdown-item" to="/app/user">Tendering Keys</router-link>
    </drop-down>

    <li class="nav-item">
      <a class="nav-link" href="#pablo">
        <span class="no-icon">Log out</span>
      </a>
    </li>
  </ul>
</template>
<script>
  import DropDown from '/src/components/UIComponents/Dropdown.vue'

  export default {
    components: {
      DropDown
    }
  }
</script>
<style>
</style>
