<template>
  <div class="content container-fluid">

    <div v-if="!logEntry">

      <b-row>
        <b-col class="main-title col-12">

          <div>
            <b-button class="btn btn-sm btn-fill btn-success float-left mr-2" @click="$router.go(-1)">Done</b-button>
            <h3>{{title}} <small> ({{ logList.length }}) Records </small></h3>
          </div>
          <b-row>
            <b-col>
              <label>Events To Find</label>
              <sf-input id="listFilterString" type="list" label="Filter" :labelCols="2" cstyle="width:25em;" :options="['','add','delete','error','export','fail','general','import','login','portal','student','success','trans','update','user']" v-model="eventFilter" ></sf-input>
              <sf-input id="searchFind" type="list" label="Search" :labelCols="2" cstyle="width:25em;" @change="getLast(searchNumRecords,searchEventId)" v-model="searchEventId" :options="eventIds.filter((e)=>{return e.description.toLowerCase().indexOf(eventFilter)>=0 || e.eventId==eventFilter}).map((e) => { return {text: e.eventId +': ' + e.description, value: e.eventId }})"></sf-input>
              <p style="font-size:.8em;padding:.5em;">Select an event from the dropdown above.  If you need help refining the selection list, use the Events List Filter to help narrow the list.</p>
            </b-col>

            <b-col class="main-title  mb-4 ">
              <label><i class="fa fa-arrow-down" style="font-size:1.25em;margin-right:.5em;" ></i>Results Filter</label>

              <b-input-group>
                <b-form-input id="listFilterString" type="search" v-model="listFilter" ></b-form-input>
                <b-input-group-append @click="listFilter=''">
                  <b-input-group-text>
                    <i class="fa fa-times" style="font-size:1.25em;"/>
                  </b-input-group-text>
                </b-input-group-append>
              </b-input-group>
              <b-checkbox style="margin-top:1em;" v-model="showDetails">Show More Detail</b-checkbox>
            </b-col>
          </b-row>
          <b-form-radio-group id="radios2" v-model="searchNumRecords" name="radioSubComponent" @change="getLast(searchNumRecords,searchEventId)">
            <b-form-radio value=10>10 Entries</b-form-radio>
            <b-form-radio value=100>100 Entries</b-form-radio>
            <b-form-radio value=1000>1000 Entries</b-form-radio>
            <b-form-radio value=2000>2000 Entries</b-form-radio>
          </b-form-radio-group>
          <div v-if="canGetRecords">
            <b-button-group>
              <b-button class="btn btn-fill btn-success btn-sm" @click="getLast(searchNumRecords,searchEventId);canGetRecords=false;">Get Records</b-button>
            </b-button-group>
          </div>
        </b-col>
      </b-row>

      <div class="row" v-if="logList.length > 0">
        <div class="col-12 p-3 mb-4">
          <h4 class="card-title">Log Details</h4>
          <p class="card-category">filter: {{listFilter}}</p>

          <b-table style="line-height:16px;" striped  hover head-variant="dark" :filter="listFilter" stacked="sm" sort-by="eventDate desc" :items="logList" :fields="logColumns" @row-clicked="rowClicked($event)">

          <template v-slot:cell(eventCategory)="row">
              <div class="show-hand" style="width:60px;max-width:60px;">{{row.item.eventCategory}}</div>
            </template>

            <template v-slot:cell(eventDate)="row">
              <!-- <div v-if="!showDetails" class="show-hand">{{tzDateShort(row.item.eventDate)}}</div> -->
              <div class="show-hand">{{tzDate(row.item.eventDate)}}</div>
            </template>

            <template v-slot:cell(eventTitle)="row">
              <div class="show-hand" style="width:220px;overflow:hidden;white-space:pre-line;text-overflow:ellipsis;" v-b-popover.hover.top="{delay:1100,content:row.item.eventValue || '<none>',title:'Event Value'}">{{row.item.eventTitle}}</div>
              <div v-if="showDetails" style="color:darkgray;white-space:pre-line;text-overflow:ellipsis;">{{row.item.eventValue}}</div>
              <div class="show-hand" v-if="showDetails" style="font-weight:600;" @click="listFilter=row.item.sourceIp;">{{row.item.sourceIp}}</div>
            </template>

            <template v-slot:cell(description)="row">
              <div class="show-hand" v-if="showDetails" style="width:700px;overflow:hidden;white-space:pre;text-overflow:ellipsis;">{{row.item.description }}</div>
              <div class="show-hand" v-else style="width:500px;overflow:hidden;white-space:pre;text-overflow:ellipsis;">{{row.item.description.substring(0,100)}}</div>
            </template>

          </b-table>

        </div>
      </div>
      <div class="table-responsive" v-else>
        <hr />
        No Entries
      </div>
    </div>

    <div  v-if="logEntry" ref="logEntryScreen">

      <div>
        <b-btn class="btn btn-warning btn-fill btn-sm float-left mr-2" @click="hideLogEntryScreen">Close</b-btn>
        <h3>Log Detail</h3>
      </div>
      <table  class=" minimalistBlack">
        <thead>
          <tr>
          <th></th>
          <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Date</td><td>{{moment(logEntry.eventDate).format("LLLL")}} <span style="font-weight:800;"> ({{moment(logEntry.eventDate).fromNow()}})</span></td>
          </tr><tr>
            <td>Cateogry</td><td>{{logEntry.eventCategory}}</td>
          </tr><tr>
            <td>Event ID</td><td>{{logEntry.eventId}}</td>
          </tr><tr>
            <td>Title</td><td>{{logEntry.eventTitle}}</td>
          </tr><tr>
            <td>Details</td><td style="font-family:Consolas,Monaco,Lucida Console;white-space:pre;word-wrap:break-word;line-height:1em;">{{logEntry.eventValue}}</td>
          </tr><tr>
            <td>Extended</td><td style="font-family:Consolas,Monaco,Lucida Console;white-space:pre;word-wrap:break-word;line-height:1em;">{{logEntry.description}}</td>
          </tr><tr>
            <td>Site</td><td>{{logEntry.siteId}}</td>
          </tr><tr>
            <td>Source</td><td>{{logEntry.sourceIp}}</td>
          </tr><tr v-if="logEntry.user">
            <td>User</td><td>{{logEntry.user.alias || "" }} / {{logEntry.user.email ||""}}</td>
          </tr>
        </tbody>
      </table>
      <div class="float-left mt-2">
        <b-btn class="btn btn-warning btn-fill btn-sm" @click="hideLogEntryScreen">Close</b-btn>
      </div>
    </div>
  </div>
</template>

<script>

// import router   from "/src/routes";
import LTable   from "/src/components/UIComponents/Table.vue";
import MomentTZ from "moment-timezone";
import Card     from "/src/components/UIComponents/Cards/Card.vue";
import SfInput from "/src/components/UIComponents/Inputs/SFormInput.vue";
import LogService from "/src/Services/LogService";

export default {
  components: {
    LTable,
    Card,
    SfInput
  },
  data() {
    return {
      title           : "System Log",
      searchString    : "0",
      searchEventId   : "all",
      searchNumRecords: 10,
      showDetails     : false,
      autoRefresh     : false,
      canGetRecords   : false,
      eventFilter     : "",
      listFilter      : null,
      logEntry        : null,
      logList         : [],
      logColumns      : [{
          key: "eventId",
          label: "Event ID",
          sortable: true
        },
        {
          key: "eventDate",
          label:"Date",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "eventCategory",
          label: "Category",
          sortable: true
        },
        {
          key: "eventTitle",
          label: "Title",
          sortable: true
        },
        {
          key: "description",
          label: "Info",
          sortable: true
        }

      ],
      logHeaders: [
        "Event ID",
        "Date",
        "Category",
        "Title",
        "Description",
      ],
      eventIds: [{
        eventId: 0,
        description: ""
      }]
    };
  },
  async created() {

    // this.$options.interval = setInterval(this.refreshLog, 10000);

    try {
      await Promise.all([
        this.getEventIds(),
        this.getLast(this.searchNumRecords, "all")
      ]);
    } catch (err) {
//      console.log("getLog() Mounted error: ", err);
      if (err.response) {
        if (err.response.data) {
          t.$root.errorMessage("Oops", err.response.data.message);
        }
      } else {
        t.$root.errorMessage("Oops", "Had an error getting initial settings");
      }
    }

  },
  beforeDestroy() {
    clearInterval(this.$options.interval)
  },
  methods: {
    moment(d) {
      return MomentTZ(d);
    },
    tzDate(d) {
      return MomentTZ.tz(d, this.$root.TZ).format('ddd DD-MMM-YY hh:mm a');
    },
    tzDateShort(d) {
      return MomentTZ.tz(d, this.$root.TZ).format('ddd DD-MMM hh:mm a');
    },
    doFilter(item) {
      return true;
    },
    refreshLog() {
      let t = this;
      if (t.autoRefresh) {
        t.searchNumRecords = 10;
        this.getLast(10, t.searchEventId);
      }
    },
    hideLogEntryScreen() {
      let t = this;
      this.logEntry = null;
      // t.$refs.logEntryScreen.hide();
    },
    showLogEntryScreen() {
      // let t = this;
      // t.$refs.logEntryScreen.show();
    },
    doNothing() {},
    onReset(e) {
      e.preventDefault();
    },
    async rowClicked(item) {
      this.getLogEntry( item._id );
    },
    async getLogEntry( id ) {
      let response = await LogService.getEvent(id);
      this.logEntry = response.data;
    },
    async getEventIds() {
      var response;
      var t = this;
      try {
        response = await LogService.getEventIds();
        this.eventIds = response.data;
      } catch (err) {
        if (err) {
          alert(
            "oops hit error in getEventIds",
            err
          );
          t.$root.errorMessage("Oops", err.response.data.message);
        }
        return;
      }

    },
    async getLast(numEntries, eventId) {
      var response;
      var t = this;
      try {
        response = await LogService.getLast(numEntries, eventId);
        this.logList = response.data;
      } catch (err) {
        if (err) {
          alert("oops hit error in getByID)", err);
          t.$root.errorMessage("Oops", err.response.data.message);
        }
        return;
      }
    },
    async getLogs() {
      var response;
      var t = this;

      if (this.searchString === "") {
        t.$root.errorMessage(
          "Oops",
          "you forgot to type your search information"
        );
        return;
      }

      try {
        response = await LogService.getById(this.searchString);
        this.badgeList = response.data;
      } catch (err) {
        if (err) {
          alert(
            "oops hit error in Badges.vue (might need to fix this error message)",
            err
          );
          t.$root.errorMessage("Oops", err.response.data.message);
        }
        return;
      }

    }
  }
};

</script>
<style language="scss" scoped>
  .main-title {
    margin-left: 6px;
  }

  .button {
    margin-right: 6px;
  }


  .float-right {
    float: right;
  }

table.minimalistBlack {
  border: 3px solid #000000;
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}
table.minimalistBlack td, table.minimalistBlack th {
  border: 1px solid #000000;
  padding: 5px 4px;
}
table.minimalistBlack tbody td {
  font-size: 13px;
}
table.minimalistBlack tbody td:first-child {
  text-align:right;
  font-weight: bold;
}
table.minimalistBlack thead {
  background: #CFCFCF;
  background: -moz-linear-gradient(top, #dbdbdb 0%, #d3d3d3 66%, #CFCFCF 100%);
  background: -webkit-linear-gradient(top, #dbdbdb 0%, #d3d3d3 66%, #CFCFCF 100%);
  background: linear-gradient(to bottom, #dbdbdb 0%, #d3d3d3 66%, #CFCFCF 100%);
  border-bottom: 3px solid #000000;
}
table.minimalistBlack thead th {
  font-size: 15px;
  font-weight: bold;
  color: #000000;
  text-align: left;
}
table.minimalistBlack tfoot {
  font-size: 14px;
  font-weight: bold;
  color: #000000;
  border-top: 3px solid #000000;
}
table.minimalistBlack tfoot td {
  font-size: 14px;
}


</style>
