<template>
  <div class="content">
    <div>
      <b-button class="btn btn-success btn-fill btn-sm float-left mr-2" @click="$router.go(-1)">Done</b-button>
      <h4 slot="header" class="card-title">
        Users<span style="font-size:.8em;font-weight:100;margin-left:1em;">({{
          users.length
        }})</span><i class="fa fa-plus-square show-hand float-right" @click="registerTenantUser()" v-b-popover.hover.top="'Add a new user'"></i>
      </h4>
    </div>
    <div>
      <hr>
      <div>
        <div v-if="featureFilter!=='tickets'" style="display:inline-flex">
          <label>User Filter/Search </label>
          <b-form-input id="filterString" type="search" style="margin-left:1em;" class="filter-field" v-model="filterString" placeholder="Filter Users" />
        </div>
        <div v-if="!isShowAllUsers" style="display:inline-flex;margin-left:2em;">
          <span v-if="featureFilter===''" style="text-align:right;position:relative;width:12em;margin-right:1em;top:.5em;">Feature Filter</span>
          <i v-if="featureFilter!==''" class="fa fa-eraser mt-2 mb-0 mr-2 ml-5" @click="featureFilter=''" v-b-popover.hover.top="'Clear feature filter'"></i>
          <b-form-select id="featureList" type="list" class="mt-0" style="border-radius:1em;" :options="featureOptions" v-model="featureFilter" placeholder="Feature Filter" />
        </div>
        <!--        <sf-input v-if="featureFilter==='tickets'" id="workorderList"  type="list" class="" cstyle="float:right;width:15em;" :options="featureOptions" v-model="featureFilter" />-->
      </div>

      <div v-if="featureFilter==='tickets'">
        <h4>Helpdesk Ticket Type Access</h4>
        <div style="width:100%;overflow:auto;margin-top:1em;">
          <table class="table table-hover" style="cursor:default;" :items="userList()" :fields="workorderColumns" :filter="filterString">
            <thead class="thead-dark">
            <tr style="font-weight:700;height:180px;">
              <!-- <th style="vertical-align:bottom;">Image</th> -->
              <!-- <th style="vertical-align:bottom;">User</th> -->
              <th style="vertical-align:bottom;" colspan="2">
                <div style="width:100%;text-align:center;">User</div>
              </th>
              <th v-for="(wc,index) in workorderColumns" style="" :key="index">
                <div class="status-rotate30">{{ wc.label }}</div>
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(u,index) in userList()" :key="index">
              <td>
                <b-avatar :src="(u.user.pictureUrl>'') ? u.user.pictureUrl : myGravatar(u.user.email)" />
              </td>
              <td @click="editUser(u._id)" style="cursor:pointer;">{{ u.user.alias }}</td>
              <td v-for="(wc,wcIndex) in workorderColumns" style="text-align:center;border-left:1px solid black;" :key="wcIndex">
                <i class="fa fa-check" style="color:green;" v-if="u.ticketTypes.indexOf(wc.key)>=0"></i><i v-else class="fa fa-times" style="color:red;"></i></td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div v-if="featureFilter==='account-profiles'">
        <h4>Accounts & Profile Access</h4>
        <!-- <div style="float:right;margin-top:-4em;margin-bottom:1em;" >
          <b-checkbox v-model="isShowAllUsers">Show All</b-checkbox>
        </div> -->
        <div style="width:100%;overflow:auto;margin-top:1em;">
          <table class="table table-hover" style="cursor:default;" :items="userList('accounts')" :fields="accountClasses" :filter="filterString">
            <thead class="thead-dark">
            <tr style="font-weight:700;height:180px;">
              <!-- <th style="vertical-align:bottom;">Image</th> -->
              <th style="vertical-align:bottom;" colspan="2">
                <div style="width:100%;text-align:center;">User</div>
              </th>
              <th v-for="(ap,index) in accountClasses" style="" :key="index">
                <div class="status-rotate30">{{ ap.title }} <span style="font-size:.8em;">({{ ap.accountClassId }})</span></div>
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(u,index) in userList('accounts')" :key="index">
              <td>
                <b-avatar :src="(u.user.pictureUrl>'') ? u.user.pictureUrl : myGravatar(u.user.email)" />
              </td>
              <td @click="editUser(u._id)" style="cursor:pointer;">{{ u.user.alias }}</td>
              <td v-for="(ap,apIndex) in accountClasses" style="text-align:center;border-left:1px solid black;" :key="apIndex">
                <i class="fa fa-check" style="color:green;" v-if="u.accountClasses.indexOf(parseInt(ap.accountClassId))>=0"></i>
                <i v-else class="fa fa-times" style="color:red;"></i>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div v-if="featureFilter!=='tickets' && featureFilter!='account-profiles'">
        <div style="float:right;margin-top:-3em;margin-bottom:1em;">
          <b-checkbox v-model="isShowAllUsers">Show All</b-checkbox>
          <b-checkbox v-model="isShowUnlinkedUsers">Show Unlinked Users</b-checkbox>
        </div>
        <b-table striped bordered hover small head-variant="dark" stacked="md" sort-by="email" :items="userList()" :fields="columns" :filter="filterString">

          <template v-slot:cell(actions)="row">
            <i class="fa fa-trash show-hand float-left mr-2" v-b-popover.hover.top="'Remove User'" @click.stop="removeTenantUser(row.item._id, row.item.user.alias )"></i>
            <i class="fa fa-exclamation-triangle show-hand float-left mr-2" :style="((row.item.user && row.item.user.whiteListIP.length>0) || (row.item.user && row.item.user.blackListIP.length>0)) ? 'color:orange;' : ''" v-b-popover.hover.top="'Restrict/Manage IP Access'" @click.stop="manageAccess(row.item._id )"></i>
            <i class="fa fa-key show-hand" v-b-popover.hover.top="'Change Password'" @click.stop="modalTitle='For: '+row.item.user.alias; userId=row.item.user._id;userAlias=row.item.user.alias;newPassword='';confirmPassword='';isShowChangePassword=true;"></i>
          </template>

          <template v-slot:cell(image)="row">
            <b-avatar :src="(row.item.user.pictureUrl>'') ? row.item.user.pictureUrl : myGravatar(row.item.user.email)" />
          </template>

          <template v-slot:cell(isAdmin)="row">
            <div class="show-hand" @click="editUser(row.item._id)"><i v-if="row.item.isAdmin" class="fa fa-check-circle" style="color:green;"></i></div>
          </template>

          <template v-slot:cell(active)="row">
            <div class="show-hand" @click="editUser(row.item._id)"><i v-if="row.item.active" class="fa fa-check-circle" style="color:green;"></i></div>
          </template>

          <template v-slot:cell(user.alias)="row">
            <div class="show-hand" style="font-weight:600;" @click="editUser(row.item._id)">{{ row.item.user.alias }}</div>
            <div class="show-hand" style="font-weight:400;font-size:.9em;" @click="editUser(row.item._id)">{{ row.item.user.local.email }}</div>
          </template>

          <template v-slot:cell(user.accountType)="row">
            <div>{{ accountServiceTypes[ row.item.user.accountType - 1 ].text }}</div>
          </template>
          <template v-slot:ce
                    l(user.email)="row">
            <div class="show-hand" @click="editUser(row.item._id)">{{ row.item.user.local.email }}</div>
          </template>

          <template v-slot:cell(lastLogon)="row">
            <div class="show-hand">{{ row.item.lastLogon | fromNow }}</div>
          </template>

        </b-table>

        <template v-if="isShowUnlinkedUsers">
          <h3 style="margin-top:2em;">Unlinked Users </h3>
          <ul>
            <li v-for="(user,index) in unlinkedUsers" :key="'u-'+index">
              <div @click="removeTenantUser(user._id,user.title)"><span style="text-decoration:underline;">{{ user._id }}</span> :: {{ user.title }} ::
                {{ user.createDate | fromNow }}
              </div>
            </li>
          </ul>
        </template>

      </div>

      <b-modal v-model="isShowChangePassword" :title="modalTitle" size="lg" header-bg-variant="info" header-text-variant="light" footer-bg-variant="info" footer-text-variant="light">
        <b-container fluid>
          <h4 style="margin-top:0;">Change Password</h4>
          <hr />
          <form autocomplete="new-password">
            <sf-input id="newPassword" label="New Password" type="text" cstyle="font-family:symbol;" autocomplete="new-password" v-model="newPassword"></sf-input>
            <sf-input id="confirmPassword" label="Confirm Pasword" type="text" cstyle="font-family:symbol;" autocomplete="new-password" v-model="confirmPassword" />
          </form>
          <p style="margin-left:19em;font-size:.8em;color:red;">For your system protection, passwords should be at least 7 characters long, contain a capital letter, and a special
            character. When both fields match, the "Change Password" button will appear.</p>
        </b-container>
        <div slot="modal-footer" class="w-100 mt-3">
          <b-btn class="float-right btn-fill btn-sm" v-if="newPassword===confirmPassword && newPassword.length>6" variant="success" @click="changePassword();">
            Change Password
          </b-btn>
          <b-btn class="float-right btn-fill btn-sm mr-3" variant="danger" @click="isShowChangePassword=false">
            Cancel
          </b-btn>
        </div>
      </b-modal>

      <b-modal v-model="isEditing" :title="modalTitle" size="xl" scrollable header-bg-variant="info" header-text-variant="light" footer-bg-variant="info" footer-text-variant="light">
        <b-tabs class="tab-hover" style="height:500px;" active-nav-item-class="selected-tab">
          <b-tab title="Overhead" style="margin-top:2em;">
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                <b-form-group style="margin-left:3em;" horizontal breakpoint="lg" label="User" label-size="lg" label-class="font-weight-bold pt-0" class="mb-0">
                  <b-form-checkbox id="active" v-model="user.active">Active</b-form-checkbox>
                  <b-form-checkbox id="isAdmin" v-model="user.isAdmin">Admin</b-form-checkbox>
                  <b-form-checkbox v-if="user.user" id="isLockedOut" disabled v-model="user.user.lockedOut">Locked Out {{ user.user.l }}</b-form-checkbox>
                </b-form-group>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                <h4>NOTICE</h4>
                <p style="color:maroon;width:80%;margin:auto;font-weight:700;">NOTE: Some user options are cached. This impacts mostly when Tenant features and user options are
                  being updated at the same time. If you are expecting a feature and not seeing it, give it a few mins of inactivity and the cache will clear.</p>
              </div>
            </div>

          </b-tab>

          <b-tab v-if="hasFeature(user, 'ipos')" title="iPOS" style="margin-top:2em;">
            <b-form>
              <b-form-group style="margin-left:3em;" horizontal breakpoint="lg" label="Transaction Related" label-size="lg" label-class="font-weight-bold pt-0" class="mb-0">
                <div style="font-size:.8em;font-style:italic;margin-top:0;margin-left:2em;">Config to support on-line transactions, Portal Printing, and more - this is NOT MICROS
                  configuration
                </div>
                <sf-input label="Printer Name" v-model="transactionConfig.printerName"></sf-input>
                <sf-input label="Sign-In ID" v-model="transactionConfig.signInId"></sf-input>
                <sf-input label="Check Name" v-model="transactionConfig.checkName"></sf-input>
                <sf-input label="Employee ID" v-model="transactionConfig.employeeId"></sf-input>
                <sf-input label="Revenue Center" type="number" v-model="transactionConfig.revenueCenter"></sf-input>
                <sf-input label="Workstation Assignment" v-model="transactionConfig.iPos[0].configurationId"></sf-input>
                <!-- <b-checkbox  v-model="tenantUser.ipos.isAdmin">Is Admin</b-checkbox> -->
              </b-form-group>
            </b-form>
          </b-tab>

          <b-tab v-if="hasFeature(user, 'portal')" title="Portal" style="margin-top:2em;">
            <b-form>
              <b-form-group style="margin-left:3em;" horizontal breakpoint="lg" label="Portal" label-size="lg" label-class="font-weight-bold pt-0" class="mb-0">
                <sf-input label="Ticket Printer" v-model="transactionConfig.portal.ticketPrinter"></sf-input>
                <sf-input label="No Summary Ticket" v-model="transactionConfig.portal.noSummary"></sf-input>
              </b-form-group>
            </b-form>
          </b-tab>

          <b-tab title="Features">
            <div>
              <div style="font-size:.8em;font-style:italic;margin:1em 0 0 1em;">Only these features will be available to the selected user.</div>
              <!--              <b-form-group label="User Enabled Features" style="margin-left:3em;margin-top:0;" @input="dataChanged()">-->
              <!--                <b-checkbox-group  style="overflow:auto;" v-model="user.features"  inline  :options="availableFeatureOptions()"></b-checkbox-group>-->
              <!--              </b-form-group>-->

              <div class="checkbox-group">
                <h4>Features</h4>
                <b-checkbox-group v-model="user.features" lg>
                  <b-row>
                    <b-col v-for="option in availableFeatureOptions()" :key="option.value" class="col-sm-6 col-md-6 col-lg-4 col-xl-4">
                      <b-form-checkbox :value="option.value">{{ option.text }}</b-form-checkbox>
                    </b-col>
                  </b-row>
                </b-checkbox-group>
              </div>

            </div>
          </b-tab>

          <b-tab title="Account Profiles">
            <div style="margin:auto;">
              <h4>Account Profiles</h4>
              <b-form-group @input="dataChanged()" style="margin-left:3em;margin-top:2em;" label-size="lg" label-class="font-weight-bold pt-0" class="mb-0 ml-4">
                <b-form-checkbox-group id="accountclasses" name="accountprofiles" v-model="user.accountClasses">
                  <b-form-checkbox v-for="(a,index) in accountClasses" class="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-4" :id="'ac'+a.accountClassId" :value="a.accountClassId" :key="index">
                    {{ a.title }}
                  </b-form-checkbox>
                </b-form-checkbox-group>
              </b-form-group>
            </div>
          </b-tab>

          <b-tab title="Roles">
            <div style="height:425px;overflow:auto;">
              <b-row style="width:100%;">
                <b-col>
                  <b-form-group label="Security Roles" style="margin-left:3em;margin-top:2em;" label-size="lg" label-class="font-weight-bold pt-0" class="mb-0 ml-4">
                    <div style="font-size:.8em;font-style:italic;margin-top:0;margin-left:2em;">This user is a member of these roles checked.</div>
                    <b-checkbox-group class="ml-5" v-model="user.roles" stacked :options="availableRoleOptions()"></b-checkbox-group>
                  </b-form-group>
                </b-col>
                <!--              <b-col>-->
                <!--                <b-form-group label="Account Types" style="margin-left:3em;margin-top:2em;" label-size="lg" label-class="font-weight-bold pt-0" class="mb-0 ml-4">-->
                <!--                <div style="font-size:.8em;font-style:italic;margin-top:0;margin-left:2em;">This user can see these ticket types.</div>-->
                <!--                <b-checkbox-group class="ml-5" v-model="user.ticketTypes" stacked :options="availableAccountTypes()"></b-checkbox-group>-->
                <!--              </b-form-group>-->
                <!-- <b-form-group label="Total Types" style="margin-left:3em;margin-top:2em;" label-size="lg" label-class="font-weight-bold pt-0" class="mb-0 ml-4">
                  <div style="font-size:.8em;font-style:italic;margin-top:0;margin-left:2em;">This user can see these ticket types.</div>
                  <b-checkbox-group class="ml-5" v-model="user.ticketTypes" stacked :options="availableTotalTypes()"></b-checkbox-group>
                </b-form-group> -->
                <!--              </b-col>-->
              </b-row>
            </div>
          </b-tab>

          <b-tab title="Dashboard" v-if="user.dashboardOptions &&  hasFeature(user, 'dashboard')">
            <div style="height:425px;overflow:auto;">
              <h4>Available Dashboards</h4>
              <b-form style="margin-left:10em;" @input="dataChanged()">
                <b-checkbox-group v-model="user.dashboardOptions.dashboards" stacked size="sm" switches :options="dashboardOptions"></b-checkbox-group>
              </b-form>
            </div>
          </b-tab>

          <b-tab v-if="user && hasFeature(user, 'tickets')" title="Helpdesk">
            <div style="height:425px;overflow:auto;">
              <b-form-group label="Ticket Types" style="margin-left:3em;margin-top:2em;" label-size="lg" label-class="font-weight-bold pt-0" class="mb-0 ml-4">
                <div style="font-size:.8em;font-style:italic;margin-top:0;margin-left:2em;">This user can see these ticket types.</div>
                <b-checkbox-group class="ml-5" v-model="user.ticketTypes" stacked :options="availableTicketTypes()"></b-checkbox-group>
              </b-form-group>
            </div>
          </b-tab>

        </b-tabs>
        <!--    </b-container>-->

        <div slot="modal-footer" class="w-100 mt-3">
          <b-btn class="float-right btn-fill btn-sm" variant="success" @click="updateTenantUser( user );">
            Update User
          </b-btn>
          <b-btn class="float-right btn-fill btn-sm mr-2" variant="danger" @click="isEditing=false">
            Cancel
          </b-btn>
          <b-btn v-if="$root.isAdmin()" class="float-left btn-fill btn-sm mr-2" variant="warning" @click="cloneUser()">
            Clone This User
          </b-btn>
        </div>
      </b-modal>

      <b-modal v-model="showUserAccessModal" :title="modalTitle" size="lg" header-bg-variant="info" header-text-variant="light" footer-bg-variant="info" footer-text-variant="light">
        <b-container fluid v-if="userAccess">
          <b-tabs>

            <b-tab title="Blacklist">
              <h4>Blacklist</h4>
              <p>Placing an IP address in this list will disallow logins from that address. </p>
              <hr>
              <b-row>
                <b-col sm="2" class="float-right">
                  <b-button @click="addBlacklist" class="btn btn-sm btn-fill btn-warning">Add</b-button>
                </b-col>
                <b-col sm="6">
                  <b-input class="float-right" id="blacklist" v-model="blacklist" @keyup.enter.native="addBlacklist()"></b-input>
                </b-col>
              </b-row>
              <hr>
              <ol>
                <li v-for="(s) in userAccess.blackListIP" :key="s">
                  <i class="nc-icon nc-scissors show-hand float-left" style="margin-top:.4em;" v-b-popover.hover.top="'Remove this item?'" @click="blacklist=s;removeBlacklist(s)"></i>
                  &nbsp;&nbsp;{{ s }}
                </li>
              </ol>
            </b-tab>

            <b-tab title="Whitelist">
              <h4>Whitelist</h4>
              <p>Warning: Enabling this feature will force the IP address EXIST in this list before a login is allowed. Having a BLANK IP list will effectively block all logins</p>
              <hr>
              <b-checkbox class="ml-5" v-model="userAccess.mustWhiteList">Enable Whitelist</b-checkbox>
              <div>
                <b-row v-if="userAccess.mustWhiteList">
                  <b-col sm="2" class="float-right">
                    <b-button @click="addWhitelist" class="btn btn-sm btn-fill btn-warning">Add</b-button>
                  </b-col>
                  <b-col sm="6">
                    <b-input class="float-right" id="whitelist" v-model="whitelist" @keyup.enter.native="addWhitelist()"></b-input>
                  </b-col>
                </b-row>
                <hr>
                <ol>
                  <li v-for="(s) in userAccess.whiteListIP" :key="s">
                    <i class="nc-icon nc-scissors show-hand float-left" style="margin-top:.4em;" v-b-popover.hover.top="'Remove this item?'" @click="whitelist=s;removeWhitelist(s)"></i>
                    &nbsp;&nbsp;{{ s }}
                  </li>
                </ol>
              </div>

            </b-tab>

          </b-tabs>
        </b-container>

        <div slot="modal-footer" class="w-100" style="padding-top:1em;">
          <b-btn size="sm" class="float-right btn-fill" :disabled="!isChanged" variant="success" @click="updateUserAccess()">
            Update
          </b-btn>
          <b-btn size="sm" class="float-right btn-fill mr-3" variant="danger" @click="showUserAccessModal=false;">
            Cancel
          </b-btn>
        </div>

      </b-modal>

      <b-modal v-model="isAdding" :title="modalTitle" size="xl" header-bg-variant="info" header-text-variant="light" footer-bg-variant="info" footer-text-variant="light">
        <b-container fluid>
          <h4 style="margin-top:0;">User Information</h4>
          <b-form-group horizontal label-cols-sm="4" label-cols-lg="4" label-class="text-sm-right" label="Account Type">
            <b-form-select v-model="tenantUser.accountType" :options="accountServiceTypes" />
          </b-form-group>


          <sf-input v-model="tenantUser.alias" v-if="tenantUser.accountType===1" autocomplete="off" type="text" label="Alias" placeholder="How you refer to this person" />
          <sf-input v-model="tenantUser.email" type="email" label="eMail" autocomplete="off" helpText="We will send an email to this email address and this is the users login ID" placeholder="Users email address" />
          <sf-input v-model="tenantUser.password" readonly type="password" label="Password" autocomplete="new-password" :helpText="(tenantUser.password.length===0) ?'' : tenantUser.password.length<7 ? 'Must be at least 7 characters' : ''" placeholder="User Password" />
          <p style="font-size:small;font-style:italic;padding-left:19em;">NOTE: The password has been randomized and the user should perform a <strong>Forgot Password</strong>
            operation to set a new password when logging in</p>

          <b-form-group horizontal v-if="tenantUser.accountType===1" label-cols-sm="4" label-cols-lg="3" label-class="text-sm-right" label="" label-for="active2">
            <b-form-checkbox id="active2" style="vertical-align:middle;" v-model="tenantUser.active">Active</b-form-checkbox>
            <b-form-checkbox id="isAdmin2" v-model="tenantUser.isAdmin">Admin</b-form-checkbox>
            <p style="font-size:small;font-style:italic;">NOTE: Only enable Admin for <strong>SITE</strong> Administrators - This option enables site configuration and other
              administrative rights</p>
          </b-form-group>

        </b-container>
        <div slot="modal-footer" class="w-100" style="padding-top:1em;">
          <b-btn size="sm" class="float-right btn-fill" :disabled="tenantUser.email.length===0" variant="success" @click="addTenantUser( tenantUser );">
            Register This User
          </b-btn>
          <b-btn size="sm" class="float-right btn-fill mr-3" variant="danger" @click="isAdding=false">
            Cancel
          </b-btn>
        </div>
      </b-modal>

      <!-- <b-button class="btn btn-danger btn-fill float-right" @click="$router.go(-1)">
      Cancel
    </b-button> -->
    </div>
    <div class="clearfix"></div>
    <br />
  </div>
</template>

<script>

import gravatar       from "gravatar";
import LTable         from "/src/components/UIComponents/Table.vue";
import TenantService  from "/src/Services/TenantService";
import UserService    from "/src/Services/UserService";
import AccountService from "/src/Services/AccountService";
import TotalService   from "/src/Services/TotalService";
import moment         from "moment-timezone";
import Card           from "/src/components/UIComponents/Cards/Card.vue";
import SfInput        from "/src/components/UIComponents/Inputs/SFormInput.vue";

export default {
  components: {
    LTable,
    Card,
    SfInput
  },
  filters   : {
    fromNow(d) {
      if(!d) {
        return "";
      }
      return moment(d).fromNow();
    }
  },
  data() {
    return {
      isChanged           : false,
      saveDisabled        : true,
      ticketConfig        : null,
      accountTypes        : [],
      accountClasses      : [],
      totalTypes          : [],
      filterString        : "",
      featureFilter       : "",
      whitelist           : "",
      blacklist           : "",
      transactionConfig   : {
        "options"    : {
          "canVoid"      : true,
          "canSignIn"    : true,
          "canRunReports": true
        },
        "portal"     : {
          ticketPrinter: "TM-Students",
          noSummary    : "3,4,5,6,7,8,12,"
        },
        "iPos"       : [ {
          isAdmin        : false,
          configurationId: ""
        } ],
        "printerName": "",
        "employeeId" : "",
        "signInId"   : "",
        "checkName"  : ""
      },
      dashboardOptions    : [
        {text: "Occupancy", value: {dashboard: "occupancy"}},
        {text: "Sales", value: {dashboard: "daily-sales"}},
        {text: "Hourly Sales", value: {dashboard: "hourly-sales"}},
        {text: "Monthly Sales Summary", value: {dashboard: "monthly-sales-summary"}},
        {text: "Helpdesk: New Tickets", value: {dashboard: "new-tickets"}},
        {text: "Helpdesk: Closed Tickets", value: {dashboard: "closed-tickets"}},
        {text: "Helpdesk: Oldest Ticket", value: {dashboard: "oldest-ticket"}},
        {text: "Helpdesk: Ticket Standings", value: {dashboard: "ticket-standings"}},
        {text: "Helpdesk: Ticket Summary", value: {dashboard: "ticket-summary"}},
        {text: "Storage Used", value: {dashboard: "storage-used"}},

        {text: "Housing Model Availability", value: {dashboard: "housing-model-available"}},
        {text: "Housing Model Bookable", value: {dashboard: "housing-model-bookable"}},
        {text: "Housing Model Summary", value: {dashboard: "housing-model-summary"}},

        {text: "Marketing Contracts", value: {dashboard: "marketing-contracts"}},
        {text: "Marketing Contacts", value: {dashboard: "marketing-contacts"}}
      ],
      changeCount         : 0,
      userId              : 0,
      userAlias           : "",
      newPassword         : "",
      confirmPassword     : "",
      modalTitle          : "Register New User",
      showUserAccessModal : false,
      userAccess          : null,
      isShowAllUsers      : false,
      isShowUnlinkedUsers : false,
      isAdding            : false,
      isEditing           : false,
      isShowChangePassword: false,
      //"undefined", "user", "kiosk", "interface", "printer", "device", "server"
      accountServiceTypes: [ {
        value: 1,
        text : "user"
      }, {
        value: 2,
        text : "kiosk"
      }, {
        value: 3,
        text : "interface"
      } ],
      tenantUser         : {
        email           : "",
        password        : "",
        alias           : "",
        active          : true,
        isAdmin         : false,
        accountType     : 1,
        dashboardOptions: {
          title     : "",
          dashboards: []
        }
      },

      tu:
          {
            "_id"           : "64adb26bfbe1daf70cae7782",
            "user"          : {
              "local"        : {
                "email": "wscott@ioihan.com"
              },
              "_id"          : "64adb26bfbe1daf70cae7780",
              "accountType"  : 1,
              "alias"        : "Wolfie",
              "firstName"    : "Wolfie",
              "lastName"     : "Scott",
              "email"        : "wscott@ioihan.com",
              "tags"         : [],
              "logonCount"   : 5,
              "isOnline"     : true,
              "active"       : true,
              "whiteListIP"  : [],
              "blackListIP"  : [],
              "mustWhiteList": false,
              "lastLogon"    : "2023-07-11T19:59:23.181Z",
              "isSA"         : true
            },
            "title"         : "Team Member",
            "roles"         : [ "accounts", "account-delete", "account-fix", "transactions", "transactions-delete", "view-reports", "cc-manual", "transactions-manual", "tickets", "tickets-create", "tickets-update", "tickets-close", "tickets-reopen", "tickets-wizard-assign", "tickets-enable-billing", "events", "events-add", "events-edit", "events-delete", "events-add-attendee", "events-edit-attendee", "events-delete-attendee", "events-add-milestone", "events-edit-milestone", "certifications-manage", "events-delete-milestone", "certifications-approve" ],
            "active"        : true,
            "isAdmin"       : true,
            "currentStatus" : "",
            "accountClasses": [],
            "features"      : [],
            "ticketTypes"   : [],
            "accountTypes"  : [],
            "totalTypes"    : [],
            "tags"          : [],
            "isOnline"      : true,
            "logonCount"    : 5,
            "joinDate"      : "2023-07-11T19:50:03.788Z",
            "createDate"    : "2023-07-11T19:50:03.788Z",
            "lastLogon"     : "2023-07-11T19:59:23.181Z"
          },

      user            : {
        user: {
          active  : true,
          isAdmin : false,
          email   : "",
          alias   : "",
          password: ""
        }
      }, // used for editing & adding
      users           : [],
      unlinkedUsers   : [],
      workorderColumns: [],
      columns         : [
        "actions",
        "image",
        {key: "active", sortable: true, label: "Active"},
        {key: "isAdmin", sortable: true, label: "Admin"},
        {key: "user.accountType", sortable: true},
        {key: "user.alias", sortable: true, label: "Alias"},
        // { key: "user.email", sortable: true, label: "User ID" },
        {key: "logonCount", sortable: true, label: "# Logon"},
        {key: "lastLogon", sortable: true, label: "last On"}
      ],
      featureOptions  : [
        {text: "Account Management", value: "accounts"},
        {text: "Account Profiles", value: "account-profiles"},
        {text: "Asset Module", value: "assets"},
        {text: "Catering Module", value: "catering"},
        {text: "Calendar Module", value: "calendar"},
        {text: "Certifications Module", value: "certifications"},
        {text: "Dashboard", value: "dashboard"},
        {text: "Display Dog", value: "displaydog"},
        {text: "Donation Module", value: "donations"},
        {text: "Environmental Module", value: "environmental"},
        {text: "Events Module", value: "events"},
        {text: "Functions", value: "functions"},
        {text: "Help Module", value: "help"},
        {text: "Helpdesk", value: "tickets"},
        {text: "Housing Module", value: "housing"},
        {text: "iPOS Cafe", value: "ipos"},
        {text: "Kiosk Module", value: "kiosks"},
        {text: "Links Module", value: "links"},
        {text: "Marketing Module", value: "marketing"},
        {text: "Menu Board", value: "menuboard"},
        {text: "Policies & Procedures Module", value: "procedures"},
        {text: "Portal (mySchool)", value: "portal"},
        {text: "Project Management", value: "projects"},
        {text: "Reports & Queries", value: "reports"},
        {text: "Team Tasks", value: "teamtasks"},
        {text: "Transaction Management", value: "transactions"},
        {text: "Vendor Management", value: "vendors"}
      ],
      roleOptions     : [
        {feature: "accounts", text: "Account Management", value: "accounts"},
        {feature: "accounts", text: "Account Delete", value: "account-delete"},
        {feature: "accounts", text: "Account Fix Utility", value: "account-fix"},
        {feature: "accounts", text: "Account Manual Transaction", value: "account-manual-transaction"},
        {feature: "accounts", text: "Account Manual Payment", value: "account-manual-payment"},
        {feature: "accounts", text: "Account Manage AR", value: "account-ar"},

        {feature: "transactions", text: "Transaction Management", value: "transactions"},
        {feature: "transactions", text: "Transaction Delete", value: "transactions-delete"},
        {feature: "transactions", text: "Transaction Add Manual", value: "transactions-manual"},
        {feature: "transactions", text: "Credit Card Transactions Manual", value: "cc-manual"},

        {feature: "reports", text: "View Reports", value: "view-reports"},

        {feature: "tickets", text: "Access Helpdesk Tickets", value: "tickets"},
        {feature: "tickets", text: "Create Helpdesk Tickets", value: "tickets-create"},
        {feature: "tickets", text: "Update Helpdesk Tickets", value: "tickets-update"},
        {feature: "tickets", text: "Close Helpdesk Tickets", value: "tickets-close"},
        {feature: "tickets", text: "Re-Open Helpdesk Tickets", value: "tickets-reopen"},
        {feature: "tickets", text: "Issue Helpdesk Credits", value: "tickets-credits"},
        {feature: "tickets", text: "Enable Billing on Tickets", value: "tickets-enable-billing"},
        {feature: "tickets", text: "Assign Tickets in Wizard", value: "tickets-wizard-assign"},

        {feature: "catering", text: "Catering", value: "catering"},
        {feature: "catering", text: "Catering Add Events", value: "catering-add"},
        {feature: "catering", text: "Catering Edit Events", value: "catering-edit"},
        {feature: "catering", text: "Catering Delete Events", value: "catering-delete"},
        {feature: "catering", text: "Catering Post Transaction", value: "catering-post"},
        {feature: "catering", text: "Catering Credit Transaction", value: "catering-credit"},

        {feature: "events", text: "Events", value: "events"},
        {feature: "events", text: "Events Add", value: "events-create"},
        {feature: "events", text: "Events Edit", value: "events-edit"},
        {feature: "events", text: "Events Delete", value: "events-delete"},
        {feature: "events", text: "Event Attendee Add", value: "events-add-attendee"},
        {feature: "events", text: "Event Attendee Edit", value: "events-edit-attendee"},
        {feature: "events", text: "Event Attendee Delete", value: "events-delete-attendee"},
        {feature: "events", text: "Event Milestone Add", value: "events-add-milestone"},
        {feature: "events", text: "Event Milestone Edit", value: "events-edit-milestone"},
        {feature: "events", text: "Event Milestone Delete", value: "events-delete-milestone"},

        {feature: "certifications", text: "Certifications Manage", value: "certifications-manage"},
        {feature: "certifications", text: "Certifications Approve", value: "certifications-approve"},
      ]
    };
  },
  watch: {
    users: {
      handler(old) {
        this.changeCount++; // fixme: there are some internal things going on in the watcher that I prob need to fix.
        if(this.changeCount < 2) {
          return;
        }
        this.saveDisabled = false;
      }
    }
  },
  async mounted() {
    await Promise.all([
                        this.getTicketConfig(),
                        this.getAccountClasses(),
                        this.getTenantUsers()
                      ])

  },
  methods: {
    hasFeature(u, feature) {
      if(!feature) {
        return true;
      }
      if(!u.features) {
        return false;
      }
      return u.features.indexOf(feature) >= 0;
    },
    dataChanged() {
      this.isChanged = true;
    },
    userList(featureFilter = this.featureFilter) {
      if(this.isShowAllUsers) {
        return this.users;
      }
      return this.users.filter(u => {
        if(u.user && u.user.accountType && u.user.accountType <= 1) {
          let e = u.user.email;
          if(!this.hasFeature(u, featureFilter) || u.active === false || u.user.active === false || e.toLowerCase().indexOf("newcare.io") > 0 || e.toLowerCase().indexOf(
              "ioihan.com") > 0) {
            return false;
          }
          return true;
        }
      });
    },
    ticketTitle(key) {
      let t = _.find(this.ticketConfig.ticketType, {key: key});
      if(t) {
        return t.title;
      }
      return key;
    },
    availableTicketTypes() {
      if(!this.ticketConfig) {
        return [];
      }
      return this.ticketConfig.ticketType.map((t) => { return {text: t.title, value: t.key}});
    },
    availableAccountTypes() {
      if(!this.ticketConfig) {
        return [];
      }
      return this.ticketConfig.ticketType.map((t) => { return {text: t.title, value: t.key}});
    },
    availableTotalTypes() {
      if(!this.ticketConfig) {
        return [];
      }
      return this.ticketConfig.ticketType.map((t) => { return {text: t.title, value: t.key}});
    },
    availableFeatureOptions() {
      return this.featureOptions.filter((f) => { return this.$root.isTenantFeature(f.value)});
    },
    availableRoleOptions() {
      return this.roleOptions;
    },
    myGravatar(email) {
      return gravatar.url(email);
    },
    addBlacklist() {
      var t = this;
      if(t.blacklist.length > 0) {
        if(t.userAccess.blackListIP.indexOf(t.blacklist) < 0) {
          t.userAccess.blackListIP.push(t.blacklist);
          t.dataChanged();
        }
      }
      t.blacklist = "";
    },
    removeBlacklist(s) {
      var i = this.userAccess.blackListIP.indexOf(s);
      if(i >= 0) {
        this.userAccess.blackListIP.splice(i, 1);
        this.dataChanged();
      }
    },
    addWhitelist() {
      var t = this;
      if(t.whitelist.length > 0) {
        if(t.userAccess.whiteListIP.indexOf(t.whitelist) < 0) {
          t.userAccess.whiteListIP.push(t.whitelist);
          t.dataChanged();
        }
      }
      t.whitelist = "";
    },
    removeWhitelist(s) {
      var i = this.userAccess.whiteListIP.indexOf(s);
      if(i >= 0) {
        this.userAccess.whiteListIP.splice(i, 1);
        this.dataChanged();
      }
    },
    registerTenantUser(title = "Register New User") {
      // let tenantUser = {};

      // alert("Function not yet implemented");
      // return;

      this.tenantUser.email       = "";     // this.user.user.email;
      this.tenantUser.password    = Math.random() * 1000000000;     // this.user.user.password; (only valid when adding the user too, and not just the tenant user)
      this.tenantUser.alias       = "";     // this.user.alias;
      this.tenantUser.active      = true;
      this.tenantUser.isAdmin     = false;
      this.tenantUser.accountType = 1;
      //this.tenantUser     = tenantUser;

      this.isAdding   = true;
      //this.isEditing = true;
      this.modalTitle = title;

    },
    async cloneUser() {

      if(!await this.$root.confirm("Clone User: " + this.user.user.alias + "?")) {
        return;
      }

      this.isEditing = false;

      this.tenantUser.features         = this.user.features;
      this.tenantUser.dashboardOptions = this.user.dashboardOptions;
      this.tenantUser.roles            = this.user.roles;
      this.tenantUser.ticketTypes      = this.user.ticketTypes;

      this.registerTenantUser("Cloning User: " + this.user.user.alias);
    },
    async transactionConfigChanged() {
      alert("tc changed");
    },
    async addTenantUser(tenantUser) {
      let t = this;
      let response;

      this.isEditing = false;
      this.isAdding  = false;

      try {
        response = await UserService.registerTenantUser(tenantUser);
        await this.getTenantUsers();
      } catch(err) {
        if(err.response) {
          console.log("error response ", err.response);
          t.$root.errorMessage("Oops", err.response.data.message);
        } else if(err.request) {
          console.log("Error Request ", err.request);
        } else {
          console.log("message only ", err.message);
        }
        t.$root.errorMessage("Oops", "Unable to Add User");
      }
    },
    async updateUserAccess() {
      if(!this.userAccess) {
        return;
      }

      try {
        let response             = await UserService.saveUserAccess(this.userAccess._id, this.userAccess);
        this.userAccess          = null
        this.showUserAccessModal = false;
      } catch(error) {
        console.log(error.message);
        this.$root.errorMessage("Oops", error.message);
      }
    },
    async manageAccess(id) {

      let user = _.find(this.users, {_id: id});

      if(!user) {
        await this.$root.alert("Unable to locate user");
        return;
      }

      try {
        let userId   = user.user._id;
        let response = await UserService.getUserAccess(userId);
        let result   = response.data;

        this.userAccess = {
          _id          : userId,
          mustWhiteList: result.mustWhiteList,
          blackListIP  : result.blackListIP,
          whiteListIP  : result.whiteListIP
        }

        this.modalTitle          = "IP Access Management ";
        this.showUserAccessModal = true;

      } catch(error) {
        console.log(error.message)
        this.$root.errorMessage("Oops", error.message);
      }
    },
    async editUser(id) {
      let t     = this;
      let found = false;

      this.users.forEach(function(item, index) {
        if(item._id === id) {
          t.user = item;
          found  = true;
        }
      });

      if(found) {

        if(t.user.user.accountType !== 1 && !this.$root.user.isSA) {
          return await this.$root.alert("You cannot edit this user type");
        }

        this.modalTitle = "Editing " + t.user.user.alias + " (" + (t.accountServiceTypes[ t.user.user.accountType - 1 ].text || "") + ")";
        this.isAdding   = false;
        this.isEditing  = true;

        try {
          let [ trans, tenantUser ] = await Promise.all([
                                                          UserService.getTransactionConfig(id),
                                                          UserService.getTenantUser(id)
                                                        ]);

          this.transactionConfig = trans.data;
          this.user              = tenantUser.data;

          if(!this.transactionConfig.iPos || this.transactionConfig.iPos.length === 0) {

            this.transactionConfig[ "iPos" ] = [ {
              isAdmin        : false,
              configurationId: ""
            } ]
          }

        } catch(error) {
          this.transactionConfig = null;
          this.tenantUser        = null;
          console.log(error.message)
        }
      }

    },
    async changePassword() {
      var t = this;
      var response;

      if(t.newPassword !== t.confirmPassword) {
        return alert("Passwords do not match");
      }

      if(t.newPassword.length < 6) {
        return alert("Passwords must be at least 6 characters long");
      }

      try {
        response               = await UserService.changePassword(t.userId, "**********", t.newPassword);
        t.$root.successMessage = response.data.message;
        t.isShowChangePassword = false;
      } catch(err) {
        console.log("change password error: ", err);
        if(err.response) {
          if(err.response.data) {
            await t.$root.alert(err.response.data.message);
          }
        } else {
          t.$root.errorMessage("Oops", "Had an error changing the password");
        }
      }
    },
    async getTotalTypes() {

    },
    async getAccountTypes() {
      try {
        let response      = await AccountService.getAccountTypes();
        this.accountTypes = response.data;
      } catch(error) {
        console.log(error)
      }
    },
    async getAccountClasses() {
      let response        = await AccountService.getAccountClasses();
      this.accountClasses = response.data;
    },
    async getTenantUsers() {
      let t = this;
      let response;

      try {
        let t    = this;
        response = await UserService.getTenantUsers();
        t.users  = [];

        if(!t.$root.user.isSA) {
          response.data.map(function(user) {
            if((user.user && user.user.accountType && user.user.accountType === 1) && !user.user.isSA) { // 1 = interface Only SA's can see these
              t.users.push(user);
            }
            if(!user.user) {
              t.unlinkedUsers.push(user);
              console.log("Invalid user:", user);
            }
          });
        } else {
          t.users         = response.data.filter(u => u.user);
          t.unlinkedUsers = response.data.filter((u) => !u.user);
        }

      } catch(err) {
        console.log("getUsers error: ", err);
        if(err.response) {
          if(err.response.data) {
            t.$root.errorMessage("Oops", err.response.data.message);
          }
        } else {
          t.$root.errorMessage("Oops", "Had an error getting the users");
        }
      }
    },
    async getTicketConfig() {

      try {
        let response      = await TenantService.getTicketConfig();
        this.ticketConfig = response.data;
        this.ticketConfig.ticketType.forEach(t => {
          this.workorderColumns.push({key: t.key, sortable: true, label: t.title});
        });
      } catch(error) {
        console.log(error.message);
      }
    },
    async updateTenantUser(user) {
      let t = this;
      let response;

      this.isEditing = false;
      this.isAdding  = false;
      try {
        response = await UserService.saveTenantUser(user)
        if(this.transactionConfig !== null) {
          await UserService.updateTransactionConfig(user._id, this.transactionConfig);
        }
        await this.getTenantUsers();
      } catch(err) {
        if(err.response) {
          console.log("error response ", err.response);
          t.$root.errorMessage("Oops", err.response.data.message);
        } else if(err.request) {
          console.log("Error Request ", err.request);
        } else {
          console.log("message only ", err.message);
        }
        t.$root.errorMessage("Oops", "Unable to Update User");
      }
    },
    async removeTenantUser(id, alias = "Team Member") {
      let t = this;
      let response;

      if(!confirm(`Remove ${alias}?`)) {
        return;
      }
      ;

      try {
        response = await UserService.removeTenantUser(id);
        await this.getTenantUsers();
      } catch(err) {
        if(err.response) {
          console.log("error response ", err.response);
          t.$root.errorMessage("Oops", err.response.data.message);
        } else if(err.request) {
          console.log("Error Request ", err.request);
        } else {
          console.log("message only ", err.message);
        }
        t.$root.errorMessage("Oops", "Unable to Update User");
      }
    }
  }
};

</script>

<style lang="scss">

.filter-field {
  padding: 0 1.25em 0 .75em;
  height: 2.5em;
  border: solid 2px #2f2f2f;
  border-radius: 2em;
  background: #f0f0f0;
  width: 250px;
  float: left;
}

.filter-field:focus {
  outline: 0;
  border-color: #007593;
}

.status-rotate30 {
  text-transform: capitalize;
  font-size: 1.1em;
  width: 3em;
  margin: 0;
  padding: 0;

  position: relative;

  -webkit-transform: rotate(-60deg);
  -webkit-transform-origin: left bottom;
  -moz-transform: rotate(-60deg);
  -moz-transform-origin: left bottom;
  -ms-transform: rotate(-60deg);
  -ms-transform-origin: left bottom;
  -o-transform: rotate(-60deg);
  -o-transform-origin: left bottom;
  transform: rotate(-60deg);
  transform-origin: left bottom;
  bottom: -5em;
  left: 50%;

}


</style>
