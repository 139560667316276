<template>
  <div class="menu-item" :style="backgroundColor" >
    <div class="menu-item-qty" >
      {{item.qty}}
    </div>
    <div class="menu-item-title" >
      <span>{{itemPrefix}}</span>{{item.title}} <span>{{item.itemNumber}}</span>
    </div>
    <div class="menu-item-description">
      {{item.description}}
    </div>

  </div>
</template>

<script>

// itemCategory: "meal"
// itemNumber: 1114
// priceLevel: 0
// qty: 5
// scheduleId: "1"
// scheduleTitle: "Preschool (P1-5)"
// title: "Chicken Tenders"

   // import SfInput from "/src/components/UIComponents/Inputs/SFormInput.vue";
   //import TaskListItem from "/src/components/UIComponents/Cards/TaskListItem.vue";

  export default {
    props     : {
      item      : {type: Object, default: () => {}},
      background: { type: String, default: "#eee" },
      itemPrefix: { type: String, default: "" }
    },
    components: {
    },
    computed: {
      backgroundColor() {
          return "background-color:" + this.background + ";";
      }
    },
    methods   : {
    }
  };
</script>
<style lang="scss" scoped>

  .menu-item {
    display: grid;
    grid-template-columns: 64px 1.5fr ;
    grid-template-rows: .1fr .9fr ;
    grid-template-areas: "qty title" "qty description";
    grid-column-gap: 0px;
    border-bottom: 1px solid #777;
    line-height:1.5em;
    border-radius:1em;
    margin-bottom:.5em;

     .menu-item-qty {
      grid-area:qty;
      height:48px;
      width:48px;
      font-family:Arial, Helvetica, sans-serif;
      font-size:2.2em;
      padding-top:.35em;
      text-align:center;
      vertical-align:middle;
      justify-self:center;
      align-self:center;
    }

     .menu-item-title {
      grid-area:title;
      padding-top:.25em;
      font-size:1.1em;
      font-weight:600;
      text-align:left;

      span {
        font-size:.7em;
        font-style:italic;
        padding-right:5px;
      }
      span:nth-child(1):before {
        content: "-";
        padding-right:4px;
      }
      span:nth-child(2):before {
        padding-left:10px;
        content: "(";
      }

      span:nth-child(2):after {
        content: ")";
      }
    }

    .menu-item-description {
      grid-area:description;
      /*background-color:orange;*/
      margin-top:-.25em;
      padding-left:30px;
      font-size:.8em;
      font-weight:200;
      text-align:left;
      /*white-space:pre-line;*/
    }
  }

</style>
