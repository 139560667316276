<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
       <schedules-form>
       </schedules-form>
      </div>
    </div>
  </div>
</template>

<script>
import SchedulesForm from "./SchedulesForm.vue";

export default {
  components: {
    SchedulesForm
  }
};
</script>

<style>
</style>
