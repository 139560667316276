<template>
  <b-row class="TeacherSummaryComponent printable">

    <b-col class="col-12 w-100" >
      <hr class="noprint">
      <h2 style="margin:0;">Teacher Summary <span style="margin-left:1.5em;font-weight:600;">{{data.teacher}}</span><span style="font-weight:400;margin-left:1em;">({{data.homeRoom}})</span><span style="float:right;font-size:.5em;padding-right:1em;">{{data.email}}</span></h2>
      <span style="margin:0;">{{productionDate}}</span><span style="margin-left:2em;">Orders: </span><span style="font-weight:700;font-size:1.1em;">{{data.orders.length}} </span>
      <div style="float:right;">
        <span style="font-weight:400;font-style:italic;padding-right:2em;">({{data.classroomDescription}})</span>
        <span style="margin-right:2em;font-size:1.25em;font-weight:600;">{{ rotationLocation( data.homeRoom )}}</span>
      </div>
      <b-row class="w-100" style="font-size:9.5pt;margin-top:1em;" v-if="isShowOrderSheet">
        <b-col class="col-12" >
          <table  v-if="data.students.length>0"  style="width:100%;">
            <thead style="border:1px solid black;font-size:1.1em;">
              <tr style="background: #023e3b;color:white;text-align:center;padding:.25em;font-weight:600;border: 1px solid black;">
                <td style="text-align:right;padding-right:3em;">Name/ID</td>
                <template v-for="(mi,miIndex) in menuItems">
                  <td :key="miIndex">{{mi.title}}</td>
                </template>
              </tr>
            </thead>
            <tr v-for="(s,studentIndex) in data.students" :key="studentIndex" style="font-size:1.1em;border: 1px solid black;">
              <td style="text-align:right;padding-right:1em;border: 1px solid black;"><span >{{s.lastName}}, {{s.firstName}}</span><span style="padding-left:1em;font-weight:600;">{{s.idNumber}}</span></td>
              <template v-for="(mi,miIndex) in menuItems" >
                <td style="width:75px;border: 1px solid black;" :key="miIndex"></td>
              </template>
            </tr>
          </table>
        </b-col>
      </b-row>

      <div style="width:100%;margin-top:2em;" class="order-window">
<!--        <b-col class="col-md-3 col-sm-6 col-xs-12 w-100 class-order" style="font-size:9.5pt;padding:.25em;" v-for="(o,orderIndex) in data.orders"  :key="orderIndex">-->
        <div class="" style="width:270px;font-size:9.5pt;margin:.25em;padding:1em 1em 1em 0;" v-for="(o,orderIndex) in data.orders"  :key="orderIndex">
          <div class="" style="margin-left:1em;text-align:center;width:95%;border:1px solid gray;border-radius:.5em;background:#eee;">
            <div style="font-size:1.1em;font-weight:400;margin:0;"><span>Ticket #</span><span style="font-weight:700;">{{o.ticketNumber}}</span></div>
            <div style="font-weight:700;font-size:1.1em;margin:0;">{{o.alias}}<span style="padding-left:2em;font-size:.9em;font-weight:400;">({{ o.badgeNumber }})</span></div>
          </div>
          <div class="" style="width:95%;margin-left:1em;padding:.5em;border:1px solid gray;border-radius:.5em;">
            <table style="font-size:9.5pt;width:95%;">
              <tr v-for="(item,index) in o.items" :key="index">
                <td style="width:15%;vertical-align:top;font-weight:600;">{{item.qty}}</td>
                <td style="width:180px;text-align:left;">
                  <div>
                    <span style="font-weight:600;">{{item.title}}</span>
                    <div v-if="item.description && isShowDescription" style="padding-left:1em;display:block;white-space:pre-line;font-size:10px;line-height:1em;">{{item.description}}</div>
                  </div>
                </td>
                <td style="width:20%;text-align:right;vertical-align:top;">{{currency( item.qty * item.price)}}</td>
              </tr>
              <tr>
                <td colspan=2 style="text-align:right;">Total</td>
                <td style="width:20%;text-align:right;">{{currency( o.total )}}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>

    </b-col>


  </b-row>
</template>

<script>

  import Currency     from "currency.js";

  export default {
    props: {
      data             : { type: Object, default: () => {} },
      rotationLocation : { type: Function, default: () => {} },
      menuItems        : { type: Array, default: () => [] },
      isShowDescription: { type: Boolean, default: true },
      isShowOrderSheet : { type: Boolean, default: false },
      isShowAllTeachers: { type: Boolean, default: true },
      productionDate   : { type: String, default: "" }
    },
    data() {
      return {

      }
    },
    methods: {
      currency(c) {
        return Currency(c);
      }

    }
  };
</script>

<style lang="scss" >

@media screen {
  .order-window {
    display:inline-flex;
    flex-wrap: wrap;
  }

}

//.student-table {
//  width:100%;
//  thead, th {
//    bgcolor:#023e3b;
//    background:#023e3b;
//    text-align:center;
//    color:white;
//    padding:.25em;
//    font-weight:600;
//  }
//  table, th, td {
//    border: 1px solid black;
//    padding:.25em;
//  }
//  tr:nth-child(even) {
//    background:#ddd;
//  }
//}

.table-data {
  font-size:12pt;
}

</style>
