/**
 * Created by wscott on 10/2/2017.  Machine: {wolfdev2}
 *
 *  Revision History for Api ::  
 *
 *      Initial Writing: 10/2/2017        wjscott
 *      ------------------------------------------------------------------------
 *
 *       1.
 *
 */

import axios from 'axios';
import constants from '@/constants';

export default () => {
  
// console.log( "environment = " , process.env )

  return axios
    .create({
      baseURL:  constants.APIURL , // process.env is injected by Webpack (check build folder)
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'applicationKey':'00000-00000-00000-00000-00001'    // fixme: put this along side the APIRUL config
      }
      //,validateStatus: function (status) {            // enable this to have these status return codes NOT throw an error
      //  return status >= 200 && status <= 503;        // this may be a good thing, but WILL break just about every call we're
      //}                                               // currently making in the app -- think carefully before enabling -- I added this here so I would remember how.

    })

}
